.unfoldable-menu {
	position: relative;
	display: flex;
	flex-flow: column;
	background: $medlightGrey;
	@include breakMin($breakNavigation) {
		background: $lightGrey;
		flex-flow: row;
	}
	> li {
		display: flex;
		flex-flow: column;
		position: relative;
		@include breakMin($breakNavigation) {
			flex-flow: row;
		}
	}

	&--unfold-button {
		background: none;
		border: none;
		padding: 0;
		width: 100%;
		&:focus {
			p {
				color: $colorPrimary;
				font-weight: bold;
			}
			outline: none;
		}
		@include breakMin($breakNavigation) {
			@include sr-only();
		}
	}
	&--dropper {
		display: none;
		align-items: center;
		padding: 0 20px;
		position: relative;
		border-bottom: 2px solid transparent;
		border-top: 2px solid transparent;
		transition: $mediumDelay box-shadow ease-in-out, $mediumDelay background ease-in-out;
		@include breakMin($breakNavigation) {
			display: flex;
		}
		&.active {
			z-index: 1001;
			box-shadow: $menuShadow;
			background: white;
		}
		&-mobile {
			width: 100%;
			@include breakMin($breakNavigation) {
				display: none;
			}
		}
		&:focus {
			outline: none;
			.unfoldable-menu--title {
				color: $colorPrimary;
				&::after {
					color: $textBlack;
				}
			}
		}
	}
	&--title {
		position: relative;
		font-weight: normal;
		display: flex;
		align-items: center;
		font-size: 16px;
		white-space: nowrap;
		color: $textBlack;
		justify-content: space-between;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $buttonGrey;
		transition: $mediumDelay border-color ease-in-out, $mediumDelay margin ease-in-out;

		@include breakMin($breakNavigation) {
			justify-content: flex-start;
			font-size: 14px;
			letter-spacing: 0.04em;
			margin: 0;
			padding: 0;
			border: none;
		}
		&.current {
			font-weight: bold;
		}
		&.droppable::after {
			content: $icon-droparrow;
			font-family: $iconFont;
			font-size: 6px;
			transform: rotate(180deg);
			margin-left: 10px;
			display: block;
			transition: $mediumDelay transform ease-in-out;
			button:focus + * & {
				color: $colorPrimary;
			}
		}
		[dir="rtl"] & {
			&.droppable::after {
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
	&--container {
		overflow: hidden;
		height: 0;
		transition: $mediumDelay height ease-in-out;
		@include breakMin($breakNavigation) {
			position: absolute;
			top: 60px;
			left: 0;
			z-index: 1001;
			transition: $mediumDelay box-shadow ease-in-out, $mediumDelay height ease-in-out;
			min-width: 100%;
		}
		&.active {
			border-bottom: 1px solid $buttonGrey;
			margin-bottom: 20px;
			@include breakMin($breakNavigation) {
				box-shadow: $menuShadow;
				border: none;
				margin: 0;
			}
		}
	}
	&--content {
		display: flex;
		flex-flow: column;
		padding: 10px 0;
		@include breakMin($breakNavigation) {
			background: white;
			padding: 0;
		}
		> li {
			display: flex;
			a {
				width: 100%;
			}
		}
	}
	&--link {
		color: $textBlack;
		white-space: nowrap;
		font-size: 14px;
		padding-left: 20px;
		margin-bottom: 20px;
		&.current {
			font-weight: bold;
		}
		&:focus {
			color: $colorPrimary;
			font-weight: bold;
			outline: none;
		}
		@include breakMin($breakNavigation) {
			margin: 0;
			padding: 10px 20px;
			font-size: 12px;
			transition: $fastDelay color ease-in-out, $fastDelay background ease-in-out;
			&:hover {
				background: $colorPrimary;
				color: white;
			}
			&.mobile {
				display: none;
			}
		}
	}
}

.unfoldable-menu--unfold-button:focus + .unfoldable-menu--dropper {
	.unfoldable-menu--title::after {
		color: $colorPrimary;
	}
}
.unfoldable-menu--unfold-button:focus:not([aria-expanded="true"]) + .unfoldable-menu--dropper {
	border-bottom-color: $colorPrimary;
}
.active .unfoldable-menu--title {
	margin-bottom: -10px;
	border-color: transparent;
	@include breakMin($breakNavigation) {
		margin: 0;
	}
	&::after {
		transform: rotate(0);
	}
}
