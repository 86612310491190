.small-written-by {
	width: 100%;
	background: $lightGrey;
	padding: 40px;
	display: block;
	color: $textBlack;
	outline: none;
	&:hover {
		color: $textBlack;
	}

	&:focus:not(:hover) {
		color: $colorPrimary;
		outline: $blockFocusOutline;
	}

	&--title {
		margin-bottom: 7px;
	}

	&--time {
		margin-bottom: 20px;
	}
}
