// this is an extension of V3-Homepage-Header-V1

.V4-modifier.V3-Homepage-Header-V1 {
	grid-template-rows: minmax(100vw, auto);
	margin-left: #{0 - $outlineWidth};
	margin-right: #{0 - $outlineWidth};
	--paddingCompensation: #{$outlineWidth};
	@include breakMin($breakSmall) {
		grid-gap: #{5 - $outlineWidth};
		width: calc(100% + #{$pagePadding + $outlineWidth});
		grid-template: repeat(3, 1fr) / repeat(3, 1fr);
		grid-template-areas:
			'big big small1'
			'big big small2'
			'big big medium';
	}
	@include breakMin($breakMedium) {
		margin: 0 #{-$pagePadding - 2 * $outlineWidth} $subWidgetSpacing;
		width: calc(100% + #{2 * ($pagePadding + $outlineWidth)});
		grid-template: repeat(2, 1fr) / repeat(5, 1fr);
		grid-template-areas:
			'big big big small1 small2'
			'big big big medium medium';
	}
	@include breakMin($breakLarge) {
		--paddingCompensation: 0;
		margin: 0 0 $subWidgetSpacing;
		width: 100%;
		min-height: 40vw;
	}
	@include breakMin($breakMegaLarge) {
		min-height: #{$wrapperMax * 0.4};
		margin: 0 auto $subWidgetSpacing;
		max-width: $wrapperMax;
	}
	@include breakMax($breakSmallBefore) {
		position: relative;
		padding: 0;
		padding-bottom: $pagePadding;
		&::before {
			content: '';
			position: absolute;
			background: $lightGrey;
			top: 0;
			left: -$pagePadding;
			width: calc(100% + #{2 * $pagePadding});
			height: 100%;
		}
		.small-text-on-image {
			flex-flow: row;
			justify-content: flex-start;
			padding: 0;
			.back-picture {
				position: relative;
				margin-right: 25px;
				width: 100px;
				height: 100px;
				img {
					transform: none;
				}
				[dir='rtl'] & {
					margin-left: 25px;
					margin-right: 0;
				}
			}
			.box-article-content {
				&--category,
				&--reading,
				&--link {
					color: $grey;
				}
				&--title {
					color: $darkGrey;
				}
			}
		}
		.medium-text-on-image {
			min-height: 166px;
		}
	}
	.sf_colsIn > * {
		outline: none;
		border: $outlineWidth solid transparent;
		&:focus {
			border-color: $outlineColor;
		}
	}
	.V3-Homepage-Header-V1--big {
		margin: 0 -#{$pagePadding};
		&.sf_colsIn > * {
			border-width: $outlineWidth 0 $outlineWidth 0;
		}
		@include breakMin($breakSmall) {
			margin-right: 0;
			[dir='rtl'] & {
				margin: 0 -#{$pagePadding};
				margin-left: 0;
			}
			&.sf_colsIn > * {
				border-width: $outlineWidth $outlineWidth $outlineWidth 0;
				[dir='rtl'] & {
					border-width: $outlineWidth $outlineWidth $outlineWidth
						$outlineWidth;
				}
			}
		}
		@include breakMin($breakMedium) {
			margin: 0;
			.large-image-text-on-image {
				padding-bottom: 50px;
			}
			.box-article-content--title {
				font-size: 42px;
				line-height: 46px;
			}
		}
		@include breakMin($breakContent) {
			.large-text-on-image {
				padding-bottom: 50px;
			}
		}
		@include breakBetween($breakXtraLarge, $breakMegaLargeBefore) {
			.large-image-text-on-image {
				padding-left: calc((100vw - #{$wrapperMax}) / 4);
			}

			.box-article-content--title {
				font-size: 44px;
				line-height: 48px;
			}
		}
		@include breakMin($breakLarge) {
			[dir='rtl'] & {
				.large-image-text-on-image {
					padding-left: 0;
					padding-right: calc((100vw - #{$wrapperMax}) / 4);
				}
			}
		}
		@include breakBetween($breakMedium, $breakNavigation) {
			[dir='rtl'] & {
				.large-image-text-on-image {
					padding-right: 50px;
				}
			}
		}
		@include breakBetween($breakSuperLarge, $breakMegaLargeBefore) {
			.box-article-content--title {
				font-size: 64px;
				line-height: 68px;

				.full-width & {
					font-size: 44px;
					line-height: 48px;
				}
				
			}
			.box-article-content--description {
				font-size: 18px;
				line-height: 24px;
			}
		}
		@include breakMin($breakMegaLarge) {
			.large-image-text-on-image {
				padding-left: 20px;
			}
		}
	}
	.V3-Homepage-Header-V1--medium {
		@include breakBetween($breakSuperLarge, $breakMegaLargeBefore) {
			.box-article-content--title {
				font-size: 44px;
				line-height: 48px;

				.full-width & {
					font-size: 28px;
					line-height: 34px;
				}
			}
			.box-article-content--description {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
	.V3-Homepage-Header-V1--small1,
	.V3-Homepage-Header-V1--small2 {
		@include breakBetween($breakSuperLarge, $breakMegaLargeBefore) {
			.box-article-content--title {
				font-size: 28px;
				line-height: 34px;
			}
		}
	}
	.V3-Homepage-Header-V1--medium,
	.V3-Homepage-Header-V1--small1,
	.V3-Homepage-Header-V1--small2 {
		@include breakMin($breakSmall) {
			&.sf_colsIn > * {
				border-width: $outlineWidth 0 $outlineWidth $outlineWidth;
			}
			[dir='rtl'] & {
				&.sf_colsIn > * {
					border-width: $outlineWidth;
					border-left-width: 0;
				}
			}
		}
	}
	.V3-Homepage-Header-V1--small1 {
		@include breakMin($breakMedium) {
			&.sf_colsIn > * {
				border-width: $outlineWidth $outlineWidth $outlineWidth
					$outlineWidth;
			}
			[dir='rtl'] & {
				&.sf_colsIn > * {
					border-width: $outlineWidth;
				}
			}
		}
	}
}
