.quote-square {
	display: flex;
	flex-flow: column;
	position: relative;
	margin-bottom: 10px;
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: $lightGrey;
		@include breakMin($breakXtraSmall) {
			height: 100%;
		}
	}
	&.reduce-background::before {
		height: calc(100% - 105px);
		@include breakMin($breakMediumSmall) {
			height: 100%;
		}
	}

	@include breakMin($breakMediumSmall) {
		flex-flow: row;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: calc(100% - 105px);
			height: 100%;
		}
	}
	&--image {
		display: none;
		position: relative;
		align-self: center;
		max-height: 210px;
		max-width: 210px;
		overflow: hidden;
		&.is-mobile-show {
			display: block;
		}
		@include breakMin($breakMediumSmall) {
			display: block;
			min-height: 210px;
			min-width: 210px;
		}
	}
	&--content {
		position: relative;
		align-self: center;
		padding: 40px 40px;
		@include breakMin($breakMediumSmall) {
			margin-left: 80px;
			padding: 40px 40px 40px 0;
		}
		&:before {
			content: $icon-quote;
			font-family: $iconFont;
			font-size: 20px;
			display: block;
			font-style: normal;
			margin-bottom: 25px;
		}
	}
	&--quote {
		font-size: 24px;
		line-height: 34px;
		margin-bottom: 30px;
	}
	.quote-author {
		display: block;
		font-size: 12px;
	}
	&.right-aligned,
	&.is-mobile-show {
		@include breakMin($breakMediumSmall) {
			flex-flow: row-reverse;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 105px);
				height: 100%;
				background-color: $lightGrey;
			}
		}
	}
}
