.quote-widget {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: $subWidgetSpacingMobile;
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }
  &:before {
    content: $icon-quote;
    font-family: $iconFont;
    font-size: 20px;
    color: $buttonGrey;
    display: block;
    font-style: normal;
  }
  &.small-text {
    align-items: flex-start;
    padding: 0 $pagePadding;
    &:before {
      color: $darkGrey;
      margin-bottom: 20px;
    }
    @include breakMin($breakMedium) {
      padding: 0 80px;
    }
    .quote-author {
      display: inline;
      &--name {
        display: inline-flex;
      }
      &--comma {
        display: inline;
      }
    }
  }
  &--quote {
    font-size: 28px;
    line-height: 34px;
    font-style: normal;
    margin-bottom: 20px;
    text-align: center;
    @include breakMin($breakMedium) {
      font-size: 34px;
    }
    .small-text & {
      color: $darkGrey;
      text-align: left;
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
    }
  }
}
