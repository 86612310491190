.unfoldable-list {
	margin: 0 auto;
	display: flex;
	flex-flow: column;

	@include breakMin($breakSmall) {
		display: grid;
		grid-column-gap: 24px;
		grid-row-gap: 25px;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
	}

	@include breakMin($breakMedium) {
		grid-template-columns: repeat(3, 1fr);
	}

	&--title {
		font-size: 30px;
		line-height: 40px;
		color: $textBlack;
		font-weight: normal;
	}

	&--note {
		font-size: 16px;
		font-weight: 300;
		color: $grey;
		margin-bottom: 20px;
	}

	&--more {
		background: $lightGrey;
		border: none;
		color: $colorPrimary;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90px;
		font-family: $mainFont;
		cursor: pointer;
		transition: $mediumDelay color ease-in-out,
			$mediumDelay background ease-in-out,
			$mediumDelay opacity ease-in-out, $slowDelay height ease-in-out;
		position: relative;
		z-index: 10;

		&:hover,
		&:focus,
		&.adding {
			background: $colorPrimary;
			color: white;
		}
		&:focus {
			outline: none;
		}

		&.adding {
			pointer-events: none;
		}
		&.deactivate {
			opacity: 0;
			height: 0;
		}
		&.no-display {
			display: none;
		}
	}

	.large {
		grid-column-end: span 2;

		@include breakMin($breakMedium) {
			grid-column-end: span 3;
		}
	}

	.large-image-text-on-image {
		padding-bottom: 35px;
		margin-left: -$pagePadding;
		width: calc(100% + (2 * #{$pagePadding}));

		@include breakMin($breakMediumSmall) {
			display: flex;
			grid-column-end: span 2;
		}

		@include breakMin($breakMedium) {
			grid-column-end: span 2;
			grid-row-end: span 2;
			width: 100%;
			margin: 0;
		}
		@include breakMax($breakSmallBefore) {
			flex-flow: row;
			justify-content: flex-start;
			margin: 0 0 25px 0;
			padding: 0;
			&--content {
				position: relative;
				display: flex;
				flex-flow: column;
				padding-left: 25px;
			}
			.back-picture {
				position: relative;
				width: 100px;
				min-width: 100px;
				height: auto;
				max-height: 100px;
				&:after {
					content: none;
				}
			}
			.box-article-content {
				&--title {
					color: $textBlack;
					text-align: left;
					background: transparent;
					border: none;
					padding: 0 0 7px 0;
					font-size: 16px;
					margin-bottom: 5px;
				}
				&--category {
					color: RGB(var(--color-primary));
					font-size: 11px;
					line-height: 14px;
					letter-spacing: 0.15em;
					font-weight: normal;
				}
				&--reading {
					color: $grey;
					margin: 0;
				}
				&--link {
					color: $grey;
					padding-top: 10px;
					&:after {
						content: none;
					}
				}
			}
		}
	}
	.small-image-above-title {
		margin-bottom: 25px;

		@include breakMin($breakMediumSmall) {
			margin: 0;
		}
	}
	&.four-columns {
		@include breakMin($breakLarge) {
			grid-template-columns: repeat(4, 1fr);
		}
		.large {
			@include breakMin($breakLarge) {
				grid-column-end: span 4;
			}
		}
		.large-image-text-on-image {
			@include breakMin($breakLarge) {
				grid-column-end: span 3;
				grid-row-end: span 2;
			}
		}
	}
}
