.V3-Article-collage-V2 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto $subWidgetSpacingMobile;
  @include breakMin($breakSmall) {
    grid-template: 1fr / repeat(3, 1fr);
    grid-template-areas:
      "big     big    small1"
      "big     big    small2"
      "small3  small4  medium";
  }
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
    grid-template: 1fr 1fr / repeat(5, 1fr);
    grid-template-areas:
      "big big small1 small3 small4"
      "big big small2 medium medium";
  }
  &--big {
    @include breakMin($breakSmall) {
      grid-area: big;
    }
  }
  &--small1 {
    @include breakMin($breakSmall) {
      grid-area: small1;
    }
  }
  &--small2 {
    @include breakMin($breakSmall) {
      grid-area: small2;
    }
  }
  &--small3 {
    @include breakMin($breakSmall) {
      grid-area: small3;
    }
  }
  &--small4 {
    @include breakMin($breakSmall) {
      grid-area: small4;
    }
  }
  &--medium {
    display: none;
    @include breakMin($breakSmall) {
      display: flex;
      grid-area: medium;
    }
  }
}
