@import '../../../../../node_modules/tiny-slider/src/tiny-slider.scss';

.ir-annual-reports {
	&--card {
		user-select: none;
	}
	&--year {
		margin-bottom: 10px;
		font-size: 34px;
		font-weight: lighter;
		text-align: right;
	}
	&--card-inner {
		border: 1px solid $buttonGrey;
		min-height: 140px;
	}
	&--link-wrapper {
		padding: 40px 50px;
	}
	&--link {
		a {
			letter-spacing: 1.5px;
			color: $textBlack;
			font-size: 12px;
			text-transform: uppercase;
			&:before {
				font-family: $iconFont;
				content: $icon-tailarrowright;
				margin-right: 24px;
				color: $colorPrimary;
			}
		}
		&:first-child {
			margin-bottom: 18px;
		}
	}
	&--controls {
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		outline: none;
		.next,
		.previous {
			color: $colorPrimary;
			background: none;
			border: none;
			&:disabled {
				color: $grey;
				pointer-events: none;
			}
			display: none;
			@include breakMin($breakLarge) {
				display: block;
			}
		}
		.next {
			&:before {
				margin-right: 10px;
				content: $icon-longarrowleft;
				font-family: $iconFont;
			}
		}
		.previous {
			&:after {
				margin-left: 10px;
				content: $icon-longarrowright;
				font-family: $iconFont;
			}
		}
	}
	.tns-nav {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include breakMin($breakLarge) {
			position: absolute;
			left: 50%;
			margin-left: -50px;
		}
		button {
			border: none;
			margin-right: 10px;
			padding: 5px;
			width: 7px;
			height: 7px;
			background-color: $textGrey;
			@include breakMin($breakLarge) {
				padding: 4px;
			}
			&.tns-nav-active {
				padding: 6px;
				width: 10px;
				height: 10px;
				background-color: $colorPrimary;
				@include breakMin($breakLarge) {
					padding: 5px;
				}
			}
		}
	}
}
