$cellVerticalPadding: 20px;

.table-widget {
	display: flex;
	flex-flow: column;
	$self: &;
	&--download {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 12px;
		color: $colorPrimary;
		text-transform: uppercase;
		margin-bottom: 15px;
		&::after {
			content: $icon-download;
			font-family: $iconFont;
			font-size: 12px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			min-width: 30px;
			height: 30px;
			border-radius: 50%;
			background: $colorPrimary;
			margin-left: 15px;
			margin-right: 30px;
			transition: $mediumDelay transform ease-in-out;
		}
		&:focus {
			outline: none;
		}
		&:hover::after,
		&:focus::after {
			transform: scale(1.2);
		}
	}
	&--title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 75px;
		background: $colorPrimary;
		color: white;
		padding: 0 35px 0 20px;
		width: 100%;
		margin-bottom: 1px;
		border: none;
		font-size: 16px;
		@include breakMin($breakMedium) {
			font-size: 24px;
		}
	}
	&--container {
		height: 0;
		overflow: hidden;
		transition: $mediumDelay height ease-in-out;
	}
	&--table-container {
		overflow-x: auto;
		margin-bottom: 40px;
		@include breakMin($breakMedium) {
			margin-bottom: 55px;
		}
		@include breakMin($breakLarge) {
			margin-bottom: 70px;
		}
	}
	&--table {
		min-width: 100%;
		th {
			background: $lightGrey;
			border-color: white;
			font-size: 20px;
			font-weight: bold;
			padding: #{$cellVerticalPadding} 25px;
			text-align: right;
			white-space: nowrap;
			&:first-child {
				text-align: left;
			}
			sup {
				font-size: 14px;
				opacity: 0.8;
				margin-left: 4px;
			}
		}
		td {
			border-bottom: 1px solid $lightGrey;
			font-size: 18px;
			text-align: right;
			padding: 20px 25px;
			&:first-child {
				color: $grey;
				text-align: left;
			}
			&.highlighted {
				font-weight: bold;
				background: $lightGrey;
				border-color: white;
			}
		}
		th,
		td {
			&:last-child {
				padding-right: 35px;
			}
		}
		tr {
			&:last-child td {
				border: none;
			}
			&.footnoted {
				cursor: pointer;
				&:hover td:first-child {
					color: $colorPrimary;
				}
			}
			&.is-title td {
				padding-bottom: 10px;
				border-color: white;
			}
			&.is-bullet td {
				padding-bottom: 5px;
				padding-top: 5px;
				border-color: white;
				div {
					display: flex;
					align-items: baseline;
					&::before {
						content: '';
						background: $colorPrimary;
						min-width: 6px;
						height: 6px;
						border-radius: 50%;
						margin-right: 10px;
						margin-left: 5px;
					}
				}
			}
			&.is-last-bullet td {
				padding-bottom: $cellVerticalPadding;
				padding-top: 5px;
				border-bottom: 1px solid $lightGrey;
			}
			&:hover,
			&.highlighted {
				td {
					background: $lightGrey;
					border-color: white;
				}
			}
		}
	}
	&--footnotes {
		padding-left: 25px;
		padding-right: 35px;
	}
	&--footnote {
		font-size: 14px;
		font-weight: light;
		color: $darkGrey;
		cursor: pointer;
		margin-bottom: 15px;
		&:hover,
		&.highlighted {
			color: $colorPrimary;
		}
		a {
			text-decoration: underline;
		}
	}
	&--file {
		position: relative;
		text-align: center;
		z-index: 100;
		&::before {
			content: $icon-pdffile;
			font-family: $iconFont;
			font-size: 20px;
			color: $colorPrimary;
		}
	}
	td#{$self}--centered-cell {
		text-align: center;
	}
	&--more-button {
		position: relative;
		padding: 25px 40px;
		text-align: center;
		width: 100%;
		background: white;
		border: 1px solid $colorPrimary;
		overflow: hidden;
		cursor: pointer;
		outline: none;
	}
	&--button-label {
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.15;
		color: $colorPrimary;
		white-space: nowrap;
		transform: translateY(-50px);
		transition: $mediumDelay transform ease-in-out;
		&:after {
			content: $icon-down;
			display: inline-block;
			font-family: $iconFont;
			font-size: 8px;
			margin-left: 10px;
			transform: translateX(0);
			transition: $fastDelay transform ease-in-out;
		}
		&.active {
			transform: none;
		}
		&:last-child {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, calc(-50% + 50px));
			&:after {
				transform: scaleY(-1);
			}
			&.active {
				transform: translate(-50%, -50%);
			}
		}
	}
}
