.investor-events-overview {
	$self: &;
	&--title {
		font-size: 30px;
		font-weight: 400;
	}
	&--content {
		display: block;
		width: 100%;
		&:focus {
			outline: none;
			#{$self}--content-title {
				color: $colorPrimary;
			}
		}
	}
	&--content-date {
		color: $grey;
	}
	&--content-title {
		color: black;
	}
	&--no-events-found {
		margin-top: 40px;
		padding: 70px;
		text-align: center;
		font-size: 20px;
		color: $grey;
		background-color: $lightGrey;
		@include breakMin($breakMedium) {
			font-size: 28px;
		}
	}
	&--page {
		width: 100%;
		min-width: 100%;
		display: none;
		.changing-page & {
			transform: translateX(calc(var(--offset) * -100% * var(--factor)));
		}
		&.active {
			display: block;
		}
	}
	&--pages {
		--offset: 0;
		--factor: 1;
		display: flex;
		align-items: flex-start;
		transition: $mediumDelay height ease-in-out;
		[dir="rtl"] & {
			--factor: -1;
		}
		&.changing-page {
			overflow: hidden;
			#{$self}--page {
				display: block;
			}
		}
	}
	.arrow-link {
		display: block;
		padding-top: 40px;
		text-align: right;
		font-size: 12px;
		[dir="rtl"] & {
			text-align: left;
			&:after {
				transform: rotate(180deg);
				display: inline-block;
			}
		}
	}
	&:not(.is-upcoming) {
		@include breakMin($breakSmall) {
			max-width: calc(90% - 30px);
		}
		@include breakMin($breakMedium) {
			max-width: calc(90% - 110px);
		}
		#{$self}--title {
			margin-bottom: 20px;
		}
		#{$self}--container {
			margin: 0 -20px;
			padding: 40px 50px 40px 20px;
			background-color: $lightGrey;
			@include breakMin($breakSmall) {
				margin: 0;
			}
		}
		#{$self}--content {
			margin-bottom: 30px;
			line-height: 1.5rem;
		}
		#{$self}--content-date {
			font-size: 11px;
		}
		#{$self}--content-title {
			font-weight: 600;
		}
	}
}
