.button-widget {
	margin: 0 auto 30px;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	position: relative;
	@include breakMin($breakMedium) {
		flex-flow: row;
		align-items: center;
	}
	&.clickable {
		cursor: pointer;
	}
	&--button {
		position: relative;
		padding: 10px 30px;
		border: 1px solid $colorPrimary;
		background-color: $colorPrimary;

		&:focus {
			outline: none;
		}

		&.is-link {
			color: white;
		}

		@include breakMin($breakMedium) {
			background-color: initial;
			border-color: $buttonGrey;
			&.is-link {
				color: $textBlack;
				transition: $mediumDelay color ease-in-out, $mediumDelay border-color ease-in-out;
			}
		}
	}

	&--blue-content {
		position: absolute;
		top: -1px;
		left: -1px;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		padding: 10px 30px;
		background: $colorPrimary;
		border: 1px solid $colorPrimary;
		color: white;
		opacity: 0;
		transition: $mediumDelay opacity ease-in-out;
		@supports (clip-path: polygon(0 0, 0 100%, 0 100%, 0 0)) {
			opacity: 1;
			transition: $mediumDelay clip-path ease-in-out;
			clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
		}
		&::before {
			content: attr(data-content);
			white-space: nowrap;
		}
		@include breakMin($breakMedium) {
			.button-widget:hover &,
			*:focus & {
				opacity: 1;
				@supports (clip-path: polygon(0 0, 0 100%, 0 100%, 0 0)) {
					clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
				}
			}
		}
	}
	&--description {
		display: flex;
		align-items: center;
		color: $grey;
		font-size: 20px;
		line-height: 24px;
		flex-grow: 1;
		opacity: 0;
		margin-top: 20px;
		transform: translateX(-100%);
		transition: $slowerDelay opacity ease-in-out, $slowerDelay transform ease-in-out;
		@include breakMin($breakMedium) {
			transform: none;
			flex-flow: row;
			margin: 0 0 3px;
			&::before {
				content: "";
				height: 0;
				border-bottom: 1px solid $strokeGrey;
				margin-left: 20px;
				margin-right: 10px;
				flex-grow: 0;
				margin-top: 3px;
				transition: $evenSlowerDelay flex-grow ease-in-out;
			}
		}
		&.active {
			opacity: 1;
			transform: translateX(0);
			&::before {
				flex-grow: 1;
			}
		}
		.button-widget:hover &,
		*:focus + & {
			opacity: 0;
		}
	}

	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10000;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 18px;
    	top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
}
