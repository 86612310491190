.video-carousel-pop-up {
    .carousel-highlight {
        &--title {
            @include breakMin($breakSmall) {
                width: auto;
            }
        }

        &--description {
            display: none;
            @include breakMin($breakMedium) {
                display: block;
            }
        }
    }
}


