.main-nav--left {
	.main-nav--logo {
		img {
			width: auto;
			height: auto;
		}
	}
}
.market-nav--right {
	align-items: flex-start;

	.drop-down {
		display: flex;
		align-items: flex-start;

		&--current {
			padding: 10px 20px;

			@include breakMin($breakNavigation) {
				padding: 15px 20px;
			}
		}

		&--dot {
			display: none;
		}

		&--content {
			margin-top: 40px !important;
		}

		li {
			&:first-child {
				margin-top: 10px;
			}
		}
	}
}
