.video-cover {
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	pointer-events: none;

	.video-icon {
		position: absolute;
		bottom: 20px;
		left: 20px;
		display: flex;
		align-items: flex-end;
		z-index: 10;

		[dir='rtl'] & {
			left: 0;
			right: 20px;
		}
		&--disc {
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			background: $colorPrimary;
			border-radius: 50%;
			transition: $mediumDelay background ease-in-out;
			align-self: flex-end;
			@include breakMin($breakSmall) {
				width: 50px;
				height: 50px;
			}

			&:after {
				content: $icon-play;
				display: block;
				font-family: $iconFont;
				color: white;
				font-size: 8px;
				transform: scale(1);
				transition: $mediumDelay font-size ease-in-out;

				@include breakMin($breakSmall) {
					font-size: 14px;
					margin-left: 1px;
				}
			}
		}
		&--labels {
			display: flex;
			flex-flow: column;
			align-self: center;
			margin-left: 20px;
			margin-right: 20px;

			[dir='rtl'] & {
				margin-left: 0;
			}
		}
		&--title,
		&--length {
			width: 100%;
			color: #fff;
			position: relative;
			z-index: 10;
		}
		&--title {
			font-size: 16px;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		&--length {
			font-size: 11px;
		}
	}
	*:hover > &,
	*:focus > & {
		.video-icon--disc {
			background: $colorPrimaryHover;
			&::after {
				font-size: 20px;
			}
		}
	}
}
