.search-field-drop {
	position: relative;
	$self: &;
	&--field {
		padding-bottom: 10px;
		border-bottom: 1px solid $colorPrimary;
		position: relative;
		width: 100%;

		input {
			outline: none;
			position: relative;
			z-index: 1;
			width: calc(100% - 30px);
			font-size: 18px;
			color: $textBlack;
			border: none;
			background: none;
			padding: 0;

			&::placeholder {
				opacity: 1;
				transition: $mediumDelay opacity ease-in-out;
			}

			&:focus::placeholder {
				opacity: 0.3;
			}
		}

		button {
			position: absolute;
			right: 0;
			top: calc(50% - 5px);
			background: $mediumGrey;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			font-size: 10px;
			border: none;
			padding: 0;
			cursor: pointer;
			opacity: 0.7;
			z-index: 2;
			transform: translateY(-50%);

			&:before {
				content: $icon-cross;
				font-family: $iconFont;
				color: white;
			}

			@include breakMin($breakNavigation) {
				opacity: 1;
				font-size: 14px;
				background: none;

				&:before {
					content: $icon-search;
					font-family: $iconFont;
					color: $grey;
				}
			}
		}
	}
	&--close {
		display: none;
		@include breakMin($breakNavigation) {
			border: none;
			display: flex;
			background-color: transparent;
			position: absolute;
			right: 0;
			opacity: 0;
			visibility: hidden;
			z-index: 1;
			&:after {
				content: '\d7';
				color: $darkGrey;
				font-size: 28px;
				margin-right: 10px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}

	&--back-suggestion {
		position: absolute;
		top: 4px;
		left: 0;
		width: calc(100% - 30px);
		height: calc(100% - 10px);
		opacity: 0.3;
		font-size: 18px;
		white-space: nowrap;
		overflow: hidden;
		margin-top: -4px;

		.blank {
			color: transparent;
		}
	}

	&--drop {
		height: 0;
		transition: $mediumDelay box-shadow ease-in-out;
		position: absolute;
		top: 100%;
		overflow: hidden;
		z-index: 1000;
		background: white;
		left: -#{$pagePadding};
		width: calc(100% + #{2 * $pagePadding});

		@include breakMin($breakMediumSmall) {
			left: 0;
			width: 100%;
		}

		.open & {
			box-shadow: $menuShadow;
		}
	}

	&--search {
		display: none;

		@include breakMax($breakNavigation) {
			display: block;
			position: absolute;
			bottom: 30px;
			left: 30px;
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			border: none;
			cursor: pointer;
			transition: $mediumDelay background ease-in-out,
				$mediumDelay opacity ease-in-out, $mediumDelay color ease-in-out;

			width: calc(100% - 60px);
			height: 60px;
			background: $colorPrimary;
			color: white;

			&.deactivate {
				pointer-events: none;
				opacity: 0.2;
			}
		}
	}

	&--suggestions {
		button {
			border: none;
			background: none;
			font-size: 16px;
			letter-spacing: 0.025em;
			padding: 10px $pagePadding;
			text-align: left;
			width: 100%;
			cursor: pointer;

			@include breakMin($breakNavigation) {
				padding: 10px 30px;
			}
		}

		& button:hover,
		& .active button {
			color: $colorPrimary;
			background: $lightGrey;
		}
	}
}
// full names to avoid nesting selector weight
.glass-style {
	.search-field-drop--field {
		margin: 0;
		height: 30px;
		border-left: 1px solid $grey;

		padding: 0;

		transition: $mediumDelay width ease-in-out,
			$fastDelay border-color ease-in-out;
		outline: none;

		[dir="rtl"] & {
			border-left: 0;
			border-right: 1px solid $grey;
			@include breakMax($breakNavigation) {
				border-right: none;
			}
		}

		&.dropped {
			border-color: transparent;
		}
		input::placeholder {
			opacity: 1;
		}
	}

	.search-field-drop--back-suggestion,
	.search-field-drop--field input {
		position: absolute;
		width: 100%;
		height: 100%;
		font-size: 16px;
		margin: 0;

		&::placeholder {
			color: $grey;
		}

		@include breakMin($breakNavigation) {
			top: -2px;
			left: 8px;

			[dir="rtl"] & {
				left: -8px;
			}
		}
	}

	.search-field-drop--back-suggestion {
		top: 0;
		@include breakMin($breakNavigation) {
			top: -2px;
		}
	}

	.search-field-drop--drop button {
		font-size: 16px;
	}

	@include breakMax($breakNavigationBefore) {
		position: fixed;
		left: 0;
		background: white;
		transition: $mediumDelay transform ease-in-out,
			$mediumDelay opacity ease-in-out;
		height: 100vh;
		top: 0;
		align-items: flex-start;
		margin: 0;
		padding: 0;
		z-index: -1;
		.search-field-drop--field {
			height: 60px;
			background: $lightGrey;
			border: none;
			width: 100%;
			transition: $fast;

			&:before {
				content: $icon-search;
				font-family: $iconFont;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 22px;
				font-size: 18px;
				color: $darkGrey;

				[dir="rtl"] & {
					right: 15px;
					left: unset;
				}
			}
		}

		.search-field-drop--drop {
			position: static;
			width: 100%;

			button {
				padding: 20px 20px 20px 50px;
			}

			li:first-child button {
				margin-top: 20px;
			}
		}

		.search-field-drop--back-suggestion,
		input {
			left: 0;
			padding-left: 50px;
			display: flex;
			align-items: center;
			[dir='rtl'] & {
				padding-right: 50px;
				padding-left: 0;
			}
		}
	}
}
.boxed-style {
	min-height: 45px;
	.search-field-drop {
		width: 100%;
		@include breakMin($breakNavigation) {
			align-items: center;
			width: auto;
			display: flex;
			justify-content: center;
		}
	}
	.search--glass {
		margin-right: 10px !important;
	}
	.search-field-drop--field {
		flex: 1 0 100%;
		border: none;
		input {
			border-bottom: 1px solid #8c8c8c;
			@include breakMin($breakNavigation) {
				width: 170px;
				height: 25px;
				padding-bottom: 5px;
			}
		}
	}
	.search-field-drop--back-suggestion {
		@include breakMin($breakNavigation) {
			max-width: 170px;
		}
	}
}

.mobile-nav--burger {
	@include breakMax($breakNavigation) {
		&.close {
			[dir="rtl"] & {
				margin-left: -24px;
			}
		}
	}
}