.investor-cta {
	&--title {
		font-size: 12px;
		.investor-cta-newsletter & {
			font-size: 28px;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 10px;
		}
	}
	&--description {
		font-size: 18px;
		font-weight: 600;
		.investor-cta-newsletter & {
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			padding-right: 0;

			@include breakMin($breakMediumSmall) {
				margin-right: inherit;
				margin-top: 0;
				padding-right: 30px;
			}
		}
	}
	&--cta-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 12px 0 4px;
		&.align-to-end {
			justify-content: flex-end;
		}
	}
	&--label {
		text-transform: uppercase;
		font-size: 11px;
		letter-spacing: 1.2px;
	}
	&--arrowed-link,
	&--download-link {
		&:before {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $iconFont;
			font-size: 14px;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 2px solid $colorPrimary;
			transition: $fastDelay all ease-in-out;
			.investor-cta-widget-blue & {
				border-color: transparent;
			}
		}
	}
	&--arrowed-link {
		&:before {
			content: $icon-tailarrowright;
		}
	}
	&--download-link {
		&:before {
			content: $icon-download;
		}
	}
	a:focus &,
	&:focus,
	a:hover &,
	&:hover {
		.investor-cta--arrowed-link,
		.investor-cta--download-link {
			&:before {
				transform: scale(1.2);
			}
		}
	}
}

//necessary code for "investor-cta-newsletter" widget

.investor-cta-newsletter {
	display: block;
	padding: 40px 30px;
	margin-bottom: 16px;
	box-shadow: $menuShadow;
	background-color: $colorPrimary;
	border: 1px solid $colorPrimary;

	@include breakMin($breakMediumSmall) {
		padding: 50px 30px;
	}

	&:focus {
		outline: none;
		border-color: white;
	}
	.investor-cta {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include breakMin($breakMediumSmall) {
			flex-direction: row;
			justify-content: space-between;
		}

		&--title,
		&--description,
		&--label {
			color: white;
		}
		&--newsletter-button {
			margin-right: auto;
			background-color: white;
			color: $textBlack;
			text-transform: uppercase;
			align-self: center;
			padding: 10px 30px;
			margin-top: 70px;
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;
			border: none;

			@include breakMin($breakMediumSmall) {
				margin-right: inherit;
				margin-top: 0;
			}
		}
	}
}
