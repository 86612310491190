@charset "UTF-8";
:root {
  --color-primary: 0, 116, 192;
  --color-primary-hover: #0062a3;
  --gradient-color: rgba(0, 0, 0, 0.8);
  --main-font: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  --paddingCompensation: 0px;
  --background-white: white;
  --background-grey: #6e6e6e;
  --background-primary: RGB(var(--color-primary));
  --text-white: white;
  --text-black: #262626;
  --text-lighter-black: #393939;
  --text-grey: #6e6e6e;
  --text-primary: RGB(var(--color-primary));
}

.font-secondary {
  --main-font: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.error-500-nav {
  height: 100px;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
}

.error-500-nav--logo {
  margin-left: 25px;
  height: 50px;
}

.error-500 {
  min-height: calc(100vh - 100px);
  background: linear-gradient(110deg, RGB(var(--color-primary)) 40%, #1d9bf0);
  color: white;
  padding: 100px 0 0 20px;
}

@media screen and (min-width: 1200px) {
  .error-500 {
    padding: 150px 0 0 100px;
  }
}

.error-500--title {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 10px;
}

.error-500--subtitle {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 35px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

a:active, a:hover {
  outline: none;
}

canvas,
img,
video {
  max-width: 100%;
}

:lang(cs),
:lang(hu),
:lang(kk),
:lang(lt),
:lang(lv),
:lang(ro),
:lang(ru),
:lang(sk),
:lang(sl),
:lang(sr),
:lang(tr),
:lang(uk),
:lang(vi) {
  --main-font: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}

:lang(iw) {
  --main-font: Rubik, Helvetica Neue, Helvetica, Arial, sans-serif;
}

:lang(ja) {
  --main-font: ヒラギノ角ゴ ProN, Hiragino Kaku Gothic ProN, 游ゴシック, 游ゴシック体, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ ゴシック, MS Gothic, HiraKakuProN-W3, TakaoPゴシック, TakaoPGothic, MotoyaLCedar, Droid Sans Japanese, Helvetica Neue, Helvetica, Arial, sans-serif;
}

:lang(ko) {
  --main-font: Apple SD Gothic Neo, NanumBarunGothic, 맑은 고딕, Malgun Gothic, 굴림, Gulim, 돋움, Dotum, Helvetica Neue, Helvetica, Arial, sans-serif;
}

:lang(th) {
  --main-font: Sarabun, Helvetica Neue, Helvetica, Arial, sans-serif;
}

:lang(zh) {
  --main-font: 华文细黑, STXihei, PingFang TC, 微软雅黑体, Microsoft YaHei New, 微软雅黑, Microsoft Yahei, 宋体, SimSun, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("fonts/lato-v16-latin-300italic.woff2") format("woff2"), url("fonts/lato-v16-300italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url("fonts/lato-v16-latin-300normal.woff2") format("woff2"), url("fonts/lato-v16-300normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular Italic"), local("Lato-RegularItalic"), url("fonts/lato-v16-latin-400italic.woff2") format("woff2"), url("fonts/lato-v16-400italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular"), local("Lato-Regular"), url("fonts/lato-v16-latin-400normal.woff2") format("woff2"), url("fonts/lato-v16-400normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("fonts/lato-v16-latin-700italic.woff2") format("woff2"), url("fonts/lato-v16-700italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold"), local("Lato-Bold"), url("fonts/lato-v16-latin-700normal.woff2") format("woff2"), url("fonts/lato-v16-700normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("fonts/lato-v16-latin-ext-300italic.woff2") format("woff2"), url("fonts/lato-v16-300italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url("fonts/lato-v16-latin-ext-300normal.woff2") format("woff2"), url("fonts/lato-v16-300normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular Italic"), local("Lato-RegularItalic"), url("fonts/lato-v16-latin-ext-400italic.woff2") format("woff2"), url("fonts/lato-v16-400italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular"), local("Lato-Regular"), url("fonts/lato-v16-latin-ext-400normal.woff2") format("woff2"), url("fonts/lato-v16-400normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("fonts/lato-v16-latin-ext-700italic.woff2") format("woff2"), url("fonts/lato-v16-700italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold"), local("Lato-Bold"), url("fonts/lato-v16-latin-ext-700normal.woff2") format("woff2"), url("fonts/lato-v16-700normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-cyrillic-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-cyrillic-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-cyrillic-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-cyrillic-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-cyrillic-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-cyrillic-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-cyrillic-ext-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-cyrillic-ext-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-cyrillic-ext-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-cyrillic-ext-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-cyrillic-ext-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-cyrillic-ext-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-greek-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-greek-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-greek-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-greek-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-greek-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-greek-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-greekExt-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-greekExt-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-greekExt-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-greekExt-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-greekExt-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-greekExt-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-vietnamese-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-vietnamese-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-vietnamese-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-vietnamese-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-vietnamese-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-vietnamese-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-latin-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-latin-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-latin-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-latin-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-latin-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-latin-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("fonts/roboto-v20-latin-ext-300italic.woff2") format("woff2"), url("fonts/roboto-v20-300italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("fonts/roboto-v20-latin-ext-300normal.woff2") format("woff2"), url("fonts/roboto-v20-300normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular Italic"), local("Roboto-RegularItalic"), url("fonts/roboto-v20-latin-ext-400italic.woff2") format("woff2"), url("fonts/roboto-v20-400italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("fonts/roboto-v20-latin-ext-400normal.woff2") format("woff2"), url("fonts/roboto-v20-400normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("fonts/roboto-v20-latin-ext-700italic.woff2") format("woff2"), url("fonts/roboto-v20-700italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("fonts/roboto-v20-latin-ext-700normal.woff2") format("woff2"), url("fonts/roboto-v20-700normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("fonts/rubik-v10-cyrillic-300italic.woff2") format("woff2"), url("fonts/rubik-v10-300italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url("fonts/rubik-v10-cyrillic-300normal.woff2") format("woff2"), url("fonts/rubik-v10-300normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular Italic"), local("Rubik-RegularItalic"), url("fonts/rubik-v10-cyrillic-400italic.woff2") format("woff2"), url("fonts/rubik-v10-400italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("fonts/rubik-v10-cyrillic-400normal.woff2") format("woff2"), url("fonts/rubik-v10-400normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("fonts/rubik-v10-cyrillic-700italic.woff2") format("woff2"), url("fonts/rubik-v10-700italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("fonts/rubik-v10-cyrillic-700normal.woff2") format("woff2"), url("fonts/rubik-v10-700normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("fonts/rubik-v10-cyrillic-ext-300italic.woff2") format("woff2"), url("fonts/rubik-v10-300italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url("fonts/rubik-v10-cyrillic-ext-300normal.woff2") format("woff2"), url("fonts/rubik-v10-300normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular Italic"), local("Rubik-RegularItalic"), url("fonts/rubik-v10-cyrillic-ext-400italic.woff2") format("woff2"), url("fonts/rubik-v10-400italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("fonts/rubik-v10-cyrillic-ext-400normal.woff2") format("woff2"), url("fonts/rubik-v10-400normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("fonts/rubik-v10-cyrillic-ext-700italic.woff2") format("woff2"), url("fonts/rubik-v10-700italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("fonts/rubik-v10-cyrillic-ext-700normal.woff2") format("woff2"), url("fonts/rubik-v10-700normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("fonts/rubik-v10-hebrew-300italic.woff2") format("woff2"), url("fonts/rubik-v10-300italic.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url("fonts/rubik-v10-hebrew-300normal.woff2") format("woff2"), url("fonts/rubik-v10-300normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular Italic"), local("Rubik-RegularItalic"), url("fonts/rubik-v10-hebrew-400italic.woff2") format("woff2"), url("fonts/rubik-v10-400italic.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("fonts/rubik-v10-hebrew-400normal.woff2") format("woff2"), url("fonts/rubik-v10-400normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("fonts/rubik-v10-hebrew-700italic.woff2") format("woff2"), url("fonts/rubik-v10-700italic.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("fonts/rubik-v10-hebrew-700normal.woff2") format("woff2"), url("fonts/rubik-v10-700normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("fonts/rubik-v10-latin-300italic.woff2") format("woff2"), url("fonts/rubik-v10-300italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url("fonts/rubik-v10-latin-300normal.woff2") format("woff2"), url("fonts/rubik-v10-300normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular Italic"), local("Rubik-RegularItalic"), url("fonts/rubik-v10-latin-400italic.woff2") format("woff2"), url("fonts/rubik-v10-400italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("fonts/rubik-v10-latin-400normal.woff2") format("woff2"), url("fonts/rubik-v10-400normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("fonts/rubik-v10-latin-700italic.woff2") format("woff2"), url("fonts/rubik-v10-700italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("fonts/rubik-v10-latin-700normal.woff2") format("woff2"), url("fonts/rubik-v10-700normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("fonts/rubik-v10-latin-ext-300italic.woff2") format("woff2"), url("fonts/rubik-v10-300italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url("fonts/rubik-v10-latin-ext-300normal.woff2") format("woff2"), url("fonts/rubik-v10-300normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular Italic"), local("Rubik-RegularItalic"), url("fonts/rubik-v10-latin-ext-400italic.woff2") format("woff2"), url("fonts/rubik-v10-400italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("fonts/rubik-v10-latin-ext-400normal.woff2") format("woff2"), url("fonts/rubik-v10-400normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("fonts/rubik-v10-latin-ext-700italic.woff2") format("woff2"), url("fonts/rubik-v10-700italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("fonts/rubik-v10-latin-ext-700normal.woff2") format("woff2"), url("fonts/rubik-v10-700normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light Italic"), local("Sarabun-LightItalic"), url("fonts/sarabun-v7-latin-300italic.woff2") format("woff2"), url("fonts/sarabun-v7-300italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light"), local("Sarabun-Light"), url("fonts/sarabun-v7-latin-300normal.woff2") format("woff2"), url("fonts/sarabun-v7-300normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular Italic"), local("Sarabun-RegularItalic"), url("fonts/sarabun-v7-latin-400italic.woff2") format("woff2"), url("fonts/sarabun-v7-400italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular"), local("Sarabun-Regular"), url("fonts/sarabun-v7-latin-400normal.woff2") format("woff2"), url("fonts/sarabun-v7-400normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold Italic"), local("Sarabun-BoldItalic"), url("fonts/sarabun-v7-latin-700italic.woff2") format("woff2"), url("fonts/sarabun-v7-700italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold"), local("Sarabun-Bold"), url("fonts/sarabun-v7-latin-700normal.woff2") format("woff2"), url("fonts/sarabun-v7-700normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light Italic"), local("Sarabun-LightItalic"), url("fonts/sarabun-v7-latin-ext-300italic.woff2") format("woff2"), url("fonts/sarabun-v7-300italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light"), local("Sarabun-Light"), url("fonts/sarabun-v7-latin-ext-300normal.woff2") format("woff2"), url("fonts/sarabun-v7-300normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular Italic"), local("Sarabun-RegularItalic"), url("fonts/sarabun-v7-latin-ext-400italic.woff2") format("woff2"), url("fonts/sarabun-v7-400italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular"), local("Sarabun-Regular"), url("fonts/sarabun-v7-latin-ext-400normal.woff2") format("woff2"), url("fonts/sarabun-v7-400normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold Italic"), local("Sarabun-BoldItalic"), url("fonts/sarabun-v7-latin-ext-700italic.woff2") format("woff2"), url("fonts/sarabun-v7-700italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold"), local("Sarabun-Bold"), url("fonts/sarabun-v7-latin-ext-700normal.woff2") format("woff2"), url("fonts/sarabun-v7-700normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light Italic"), local("Sarabun-LightItalic"), url("fonts/sarabun-v7-vietnamese-300italic.woff2") format("woff2"), url("fonts/sarabun-v7-300italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light"), local("Sarabun-Light"), url("fonts/sarabun-v7-vietnamese-300normal.woff2") format("woff2"), url("fonts/sarabun-v7-300normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular Italic"), local("Sarabun-RegularItalic"), url("fonts/sarabun-v7-vietnamese-400italic.woff2") format("woff2"), url("fonts/sarabun-v7-400italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular"), local("Sarabun-Regular"), url("fonts/sarabun-v7-vietnamese-400normal.woff2") format("woff2"), url("fonts/sarabun-v7-400normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold Italic"), local("Sarabun-BoldItalic"), url("fonts/sarabun-v7-vietnamese-700italic.woff2") format("woff2"), url("fonts/sarabun-v7-700italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold"), local("Sarabun-Bold"), url("fonts/sarabun-v7-vietnamese-700normal.woff2") format("woff2"), url("fonts/sarabun-v7-700normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light Italic"), local("Sarabun-LightItalic"), url("fonts/sarabun-v7-thai-300italic.woff2") format("woff2"), url("fonts/sarabun-v7-300italic.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Sarabun Light"), local("Sarabun-Light"), url("fonts/sarabun-v7-thai-300normal.woff2") format("woff2"), url("fonts/sarabun-v7-300normal.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular Italic"), local("Sarabun-RegularItalic"), url("fonts/sarabun-v7-thai-400italic.woff2") format("woff2"), url("fonts/sarabun-v7-400italic.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Sarabun Regular"), local("Sarabun-Regular"), url("fonts/sarabun-v7-thai-400normal.woff2") format("woff2"), url("fonts/sarabun-v7-400normal.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold Italic"), local("Sarabun-BoldItalic"), url("fonts/sarabun-v7-thai-700italic.woff2") format("woff2"), url("fonts/sarabun-v7-700italic.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Sarabun Bold"), local("Sarabun-Bold"), url("fonts/sarabun-v7-thai-700normal.woff2") format("woff2"), url("fonts/sarabun-v7-700normal.woff") format("woff");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.woff2") format("woff2"), url("fonts/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.color--innovating {
  --color-primary: 0, 61, 165;
}

.color--operating {
  --color-primary: 165, 24, 144;
}

.color--caring {
  --color-primary: 0, 116, 208;
}

.color--protecting {
  --color-primary: 2, 131, 110;
}

.color--pmiBlue {
  --color-primary: 0, 116, 192;
  --color-primary-hover: #0062a3;
}

.color--careersBlue {
  --color-primary: 31, 60, 132;
  --color-primary-hover: #172c61;
}

.color--sampoernaRed {
  --color-primary: 191, 13, 62;
  --color-secondary: 174, 139, 57;
  --color-primary-hover: #801027;
}

.color--unsmokeYellow {
  --color-primary: 248, 217, 0;
  --color-primary-hover: black;
}

.color--tarLightPink {
  --color-primary: 246, 240, 234;
  --color-primary-hover: #d0cac4;
}

.color--tarPink {
  --color-primary: 242, 233, 226;
  --color-primary-hover: #d0cac4;
}

.color--nicotineGreen {
  --color-primary: 77, 143, 47;
  --color-primary-hover: #276909;
}

.color--tarGrey {
  --color-primary: 124, 120, 118;
  --color-primary-hover: #565350;
}

.color--combustionGrey {
  --color-primary: 124, 121, 118;
  --color-primary-hover: #565350;
}

.color--nicotineLightGreen {
  --color-primary: 38, 171, 128;
  --color-primary-hover: #00855a;
}

.color--combustionGold {
  --color-primary: 239, 222, 220;
  --color-primary-hover: #c9b8b6;
}

.color--combustionKhaki {
  --color-primary: 195, 159, 136;
  --color-primary-hover: #9d7962;
}

.color--combustionCararra {
  --color-primary: 233, 232, 225;
  --color-primary-hover: #c3c2bb;
}

.color--combustionLightGreen {
  --color-primary: 38, 163, 155;
  --color-primary-hover: #007d75;
}

.color--combustionOrange {
  --color-primary: 246, 169, 27;
  --color-primary-hover: #d08300;
}

.color--tarBlack {
  --color-primary: 210, 121, 64;
  --color-primary-hover: #ac531a;
}

.color--tarDarkGrey {
  --color-primary: 84, 84, 82;
  --color-primary-hover: #2e2e2c;
}

.color--pmiWhite {
  --color-primary: 255, 255, 255;
}

.color--pmiGrey {
  --color-primary: 246, 246, 246;
}

.color--christineOrange {
  --color-primary: 235, 105, 11;
  --color-primary-hover: #d15d09;
}

.color--social {
  --color-primary: 179, 37, 114;
}

.color--socialLight {
  --color-primary: 209, 124, 171;
}

.color--socialLightSecondary {
  --color-primary: 217 138 168;
}

.color--environment {
  --color-primary: 2 131 110;
}

.color--environmentLight {
  --color-primary: 103 181 168;
}

.color--environmentLightSecondary {
  --color-primary: 126 166 159;
}

.color--governance {
  --color-primary: 0 77 113;
}

.color--transparent {
  --color-primary: 255, 0, 0, 0;
}

[class*='color--'] {
  --background-primary: rgb(var(--color-primary));
  --text-primary: rgb(var(--color-primary));
}

[class*='bg--'] {
  --background-primary: rgb(var(--color-primary));
  background-color: var(--background-primary);
}

.title1,
.title2,
.title3,
.title4,
.title5,
.title6,
.title7,
.title8 {
  font-weight: 700;
  font-family: var(--main-font);
}

.title1 {
  font-size: 38px;
  line-height: 46px;
}

@media screen and (min-width: 700px) {
  .title1 {
    font-size: 48px;
    line-height: 52px;
  }
}

.title2 {
  font-size: 30px;
  line-height: 38px;
}

@media screen and (min-width: 700px) {
  .title2 {
    font-size: 36px;
    line-height: 40px;
  }
}

.title3 {
  font-size: 30px;
  line-height: 34px;
}

@media screen and (min-width: 700px) {
  .title3 {
    line-height: 38px;
  }
}

.title4 {
  font-size: 20px;
  line-height: 24px;
}

@media screen and (min-width: 700px) {
  .title4 {
    font-size: 28px;
    line-height: 34px;
  }
}

.title5 {
  font-size: 18px;
  line-height: 22px;
}

@media screen and (min-width: 700px) {
  .title5 {
    font-size: 22px;
    line-height: 26px;
  }
}

.title6 {
  font-size: 16px;
  line-height: 20px;
}

@media screen and (min-width: 700px) {
  .title6 {
    font-size: 20px;
    line-height: 24px;
  }
}

.title7 {
  font-size: 18px;
  line-height: 22px;
}

.title8 {
  font-size: 16px;
  line-height: 20px;
}

.paragraph1 {
  font-size: 24px;
  line-height: 30px;
}

@media screen and (min-width: 700px) {
  .paragraph1 {
    line-height: 34px;
    font-weight: 700;
  }
}

.paragraph2 {
  font-size: 18px;
  line-height: 30px;
}

.paragraph3 {
  font-size: 16px;
  line-height: 24px;
}

.paragraph4 {
  font-size: 14px;
  line-height: 24px;
}

.paragraph5 {
  font-size: 12px;
  line-height: auto;
}

@media screen and (min-width: 700px) {
  .paragraph5 {
    font-size: 11px;
    line-height: 14px;
  }
}

.category-title {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: RGB(var(--color-primary));
}

[dir="rtl"] .arrow-link {
  display: flex;
}

.arrow-link:after {
  --arrow-spacing: 18px;
  content: "";
  font-family: "icomoon";
  margin-left: var(--arrow-spacing);
  font-size: 9px;
  transition: 0.4s margin-left ease-in-out, 0.4s margin-right ease-in-out;
}

[dir="rtl"] .arrow-link:after {
  margin-right: var(--arrow-spacing);
  margin-left: 0;
  transform: rotate(180deg);
}

.arrow-link:focus {
  outline: none;
}

.arrow-link:hover:after, .arrow-link:focus:after {
  --arrow-spacing: 25px;
}

.small-style {
  font-size: 11px;
  line-height: 14px;
  color: #6e6e6e;
}

.above-widget-title {
  font-size: 30px;
  line-height: 40px;
  color: #262626;
  margin-bottom: 32px;
  font-weight: 400;
}

@media screen and (min-width: 960px) {
  .above-widget-title {
    margin-bottom: -8px;
  }
}

.big-description-style {
  font-size: 20px;
  line-height: 26px;
  color: #393939;
}

.dot-before:before {
  content: "";
  width: 3px;
  height: 3px;
  display: inline-block;
  background: #959595;
  border-radius: 50%;
  margin: 0 6px 2px 6px;
}

.arrowed-link {
  --spacing: 10px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #262626;
  cursor: pointer;
}

.arrowed-link:before {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  letter-spacing: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: var(--spacing);
  transition: 0.4s background ease-in-out, 0.4s margin-right ease-in-out, 0.4s margin-left ease-in-out;
}

[dir="rtl"] .arrowed-link:before {
  margin-left: var(--spacing);
  margin-right: 0;
  transform: rotate(0.5turn);
}

.arrowed-link:hover, .arrowed-link:focus,
a:hover .arrowed-link,
a:focus .arrowed-link {
  outline: none;
  color: #262626;
}

.arrowed-link:hover:before, .arrowed-link:focus:before,
a:hover .arrowed-link:before,
a:focus .arrowed-link:before {
  background: var(--color-primary-hover);
  --spacing: 25px;
}

.search-field {
  border: none;
  border-bottom: 1px solid RGB(var(--color-primary));
  font-size: 18px;
  width: 100%;
  padding-bottom: 10px;
  text-align: left;
  position: relative;
  z-index: 1;
  cursor: pointer;
  outline: none;
}

[dir="rtl"] .search-field {
  text-align: right;
}

.search-field:focus {
  border-color: #262626;
}

.search-field::placeholder {
  opacity: 1;
}

a {
  transition: 0.4s color ease-in-out;
}

a:hover, a:focus {
  transition: 0.2s color ease-in-out;
  text-decoration: none;
}

.no-display {
  display: none;
}

.sr-only {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

@media screen and (max-width: 699px) {
  .sr-only-mobile {
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.fade-in {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: fade-in;
}

.is-background {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.is-hidden-accessible {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
}

.is-invisible {
  visibility: hidden;
}

.is-content {
  position: relative;
  z-index: 1;
}

.still-picture {
  user-select: none;
}

.overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  background: none;
  color: transparent;
  cursor: pointer;
}

.stretch-link-after:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.stretch-link-before:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.download-disabled {
  pointer-events: none;
}

.download-disabled:after, .download-disabled:before {
  animation: fadeIn 2s;
  animation-duration: var(--animation-duration);
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.page-main {
  margin-top: var(--page-main-margin-top-nav);
  padding: 0 20px;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .page-main {
    padding: 0;
  }
}

@media screen and (min-width: 1265px) {
  body.scrolled .page-main {
    margin-top: 0;
  }
}

.shorthand-embed-body .page-main {
  overflow: visible;
}

.page-main.initialized {
  transition: 0.6s margin-top ease-in-out;
}

.wrapper {
  max-width: 1170px;
  width: 100%;
}

.small-wrapper {
  max-width: 690px;
  width: 100%;
}

.partial-container {
  display: flex;
  flex-grow: 1;
}

.partial-container > a,
.partial-container > div,
.partial-container > section {
  flex-grow: 1;
  width: 100%;
}

.column-content-slot > a,
.column-content-slot > div,
.column-content-slot > section,
.column-content-slot > blockquote,
.column-content-slot > img {
  margin-bottom: 50px;
}

.column-content-slot > a:last-child,
.column-content-slot > div:last-child,
.column-content-slot > section:last-child,
.column-content-slot > blockquote:last-child,
.column-content-slot > img:last-child {
  margin-bottom: 0;
}

.widget-container {
  display: flex;
}

.widget-container > div,
.widget-container > section,
.widget-container > a {
  flex-grow: 1;
}

.square-layout {
  position: relative;
  padding-bottom: 100%;
}

.square-layout > a,
.square-layout > section,
.square-layout > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobile-modal-title {
  width: 100%;
  padding: 25px 0;
  background: #f6f6f6;
  text-align: center;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  color: #393939;
  letter-spacing: 0.15rem;
  border: none;
  cursor: pointer;
}

.mobile-modal-title::before, .mobile-modal-title::after {
  content: '';
  background: #393939;
  width: 2px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 30px;
}

.mobile-modal-title::before {
  transform: translateY(-50%) rotate(45deg);
}

.mobile-modal-title::after {
  transform: translateY(-50%) rotate(-45deg);
}

@media screen and (min-width: 700px) {
  .mobile-modal-title {
    display: none;
  }
}

.carousel-dot {
  width: 6px;
  height: 6px;
  opacity: 0.5;
  margin: 0 5px;
  transition: 0.2s opacity ease-in-out, 0.2s width ease-in-out, 0.2s height ease-in-out, 0.2s margin ease-in-out;
}

.carousel-dot.active {
  opacity: 1;
  width: 10px;
  height: 10px;
}

.basic-layout .sf_colsIn {
  margin-bottom: 24px;
}

.basic-layout .sf_colsIn:last-child {
  margin-bottom: 0;
}

.cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid RGB(var(--color-primary));
  margin-bottom: 20px;
  background: RGB(var(--color-primary));
  color: white;
  transition: 0.4s color ease-in-out, 0.4s background ease-in-out;
}

.cta::after {
  content: "";
  font-family: "icomoon";
  margin-left: 5px;
  font-size: 10px;
  display: block;
  transition: 0.4s transform ease-in-out;
}

.cta:hover {
  color: white;
  background: var(--color-primary-hover);
}

.cta:hover::after {
  transform: translateX(5px);
}

.tag-element {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 11px;
  background: #f6f6f6;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
}

.tag-element button {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
}

.tag-element button:before {
  content: "";
  font-family: "icomoon";
  font-size: 7px;
  color: #6e6e6e;
}

.tag-element button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tag-element button:focus {
  outline: none;
}

.tag-element button:focus::after {
  outline: 4px solid #262626;
}

.checkbox [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.checkbox [type='checkbox']:focus + label {
  background: RGB(var(--color-primary));
  color: white;
}

.checkbox [type='checkbox']:focus + label:before {
  border-color: white;
}

.checkbox [type='checkbox']:checked:focus + label:after {
  background-color: white;
}

.checkbox [type='checkbox'] + label {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: #262626;
  cursor: pointer;
  padding: 15px 30px;
  flex-grow: 1;
}

@media screen and (min-width: 700px) {
  .checkbox [type='checkbox'] + label {
    padding: 12px 20px;
  }
}

.checkbox [type='checkbox'] + label:before {
  --size: 20px;
  content: '';
  display: block;
  border: 2px RGB(var(--color-primary)) solid;
  margin-right: var(--size);
  height: var(--size);
  width: var(--size);
  min-width: var(--size);
}

[dir='rtl'] .checkbox [type='checkbox'] + label:before {
  margin-right: 0;
  margin-left: var(--size);
}

@media screen and (min-width: 700px) {
  .checkbox [type='checkbox'] + label:before {
    --size: 10px;
  }
}

.checkbox [type='checkbox'] + label:after {
  --size: 12px;
  content: '';
  display: block;
  width: var(--size);
  height: var(--size);
  position: absolute;
  left: 36px;
}

@media screen and (min-width: 700px) {
  .checkbox [type='checkbox'] + label:after {
    --size: 6px;
    left: 24px;
  }
}

.checkbox [type='checkbox'] + label:hover::before {
  border-color: white;
}

.checkbox [type='checkbox']:checked + label {
  font-weight: 700;
}

.checkbox [type='checkbox']:checked + label:after {
  background: RGB(var(--color-primary));
}

.checkbox [type='checkbox'] + label:hover {
  background: RGB(var(--color-primary));
  color: white;
}

.checkbox [type='checkbox']:checked + label:hover:before {
  background: RGB(var(--color-primary));
}

.checkbox [type='checkbox']:checked + label:hover:after {
  background-color: white;
}

[data-sf-role='form-container'] form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}

[data-sf-role='form-container'] form > div {
  margin-bottom: 50px;
}

[data-sf-role='form-container'] form > div:first-child {
  width: 100%;
}

[data-sf-role='form-container'] [for*='Dropdown'] {
  position: absolute !important;
  left: -300vw !important;
}

[data-sf-role='form-container'] input,
[data-sf-role='form-container'] select {
  border: none;
  border-bottom: 1px solid RGB(var(--color-primary));
  padding: 0 0 8px;
  font-size: 18px;
  width: 100%;
  color: #262626;
}

[data-sf-role='form-container'] textarea {
  border: 1px solid RGB(var(--color-primary));
  padding: 15px 10px;
  min-width: 100%;
  max-width: 100%;
  min-height: 225px;
  font-family: var(--main-font);
  font-size: 16px;
}

[data-sf-role='form-container'] input,
[data-sf-role='form-container'] select,
[data-sf-role='form-container'] textarea {
  outline: none;
  transition: 0.2s border-color ease-in-out;
}

[data-sf-role='form-container'] input:focus,
[data-sf-role='form-container'] select:focus,
[data-sf-role='form-container'] textarea:focus {
  border-color: black;
}

[data-sf-role='form-container'] option {
  padding: 0 8px;
  appearance: none;
}

[data-sf-role='form-container'] .sf-fieldWrp {
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 640px) {
  [data-sf-role='form-container'] .sf-fieldWrp {
    width: calc(50% - 25px);
  }
}

[data-sf-role='form-container'] .sf-fieldWrp.input-full-width {
  width: 100%;
}

[data-sf-role='form-container'] .sf-fieldWrp label {
  position: absolute;
  bottom: 9px;
  left: 8px;
  pointer-events: none;
  top: 0;
  font-size: 19px;
  transition: 0.4s opacity ease-in-out, 0.4s transform ease-in-out;
}

[data-sf-role='form-container'] .sf-fieldWrp label.inactive {
  opacity: 0;
  transform: translateY(-50px);
}

[data-sf-role='form-container'] [data-sf-role='invalid-captcha-input'][style='visibility: hidden;'] {
  display: none;
}

[data-sf-role='form-container'] [data-sf-role='captcha-refresh-button'] {
  margin: 20px 0 10px;
}

[data-sf-role='form-container'] [data-sf-role='dropdown-list-field-container'] {
  position: relative;
}

[data-sf-role='form-container'] [data-sf-role='dropdown-list-field-container']:after {
  content: "";
  font-family: "icomoon";
  font-size: 7px;
  color: RGB(var(--color-primary));
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 1px);
  padding: 0 8px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

[data-sf-role='form-container'] [data-sf-role='paragraph-text-field-container'] {
  width: 100%;
}

[data-sf-role='form-container'] [data-sf-role='paragraph-text-field-container'] label {
  top: 15px;
  bottom: auto;
  left: 10px;
}

[data-sf-role='form-container'] .requiredLegend {
  text-align: right;
  font-size: 12px;
  color: #959595;
}

[data-sf-role='form-container'] button {
  color: white;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background: RGB(var(--color-primary));
  padding: 25px 90px;
  border: none;
}

[data-sf-role='form-container'] button:after {
  content: "";
  font-family: "icomoon";
  margin-left: 15px;
  font-size: 9px;
}

[data-sf-role='form-container'] .input-full-width {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 640px) {
  [data-sf-role='form-container'] .input-full-width {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 639px) {
  [data-sf-role='form-container'] .g-recaptcha {
    margin-left: auto;
    margin-right: auto;
  }
}

.scrollbar-style {
  scrollbar-width: thin;
}

.scrollbar-style::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f6f6f6;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background: #cccccc;
}

.file-icon::before {
  content: "";
}

.file-icon.doc::before {
  content: "";
}

.file-icon.pdf::before {
  content: "";
}

.file-icon.xls::before {
  content: "";
}

.file-icon.xbrl-file::before {
  content: "";
}

.file-icon.link::before {
  content: "";
}

.skiplink {
  position: absolute;
  margin: -1px;
  border: 0;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding: 15px;
  top: -100%;
  left: 0;
  right: 0;
  transition: top .5s ease-in-out;
  color: RGB(var(--color-primary));
  background-color: white;
  z-index: 9999;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.skiplink:focus {
  text-decoration: none;
  top: 0;
  color: RGB(var(--color-primary));
  overflow: visible;
  outline: transparent;
}

.wysiwyg,
.kendo-content-block {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #393939;
}

.wysiwyg .wysiwyg-border,
.kendo-content-block .wysiwyg-border {
  padding: 0 6px;
  border: 2px solid RGB(var(--color-primary));
}

.wysiwyg a,
.kendo-content-block a {
  font-weight: bold;
  text-decoration: underline;
}

.wysiwyg li,
.kendo-content-block li {
  margin-bottom: 24px;
  padding-left: 30px;
  position: relative;
}

.wysiwyg li::before,
.kendo-content-block li::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
}

.wysiwyg ul > li::before,
.kendo-content-block ul > li::before {
  content: '\2022';
  color: RGB(var(--color-primary));
  top: 2px;
}

.wysiwyg ol > li,
.kendo-content-block ol > li {
  counter-increment: list;
}

.wysiwyg ol > li::before,
.kendo-content-block ol > li::before {
  content: counter(list) ".";
  font-size: 12px;
  font-weight: bold;
  top: 4px;
}

.wysiwyg p,
.wysiwyg h2,
.wysiwyg h3,
.kendo-content-block p,
.kendo-content-block h2,
.kendo-content-block h3 {
  line-height: 24px;
}

.wysiwyg p,
.kendo-content-block p {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #393939;
  margin: 0 0 36px;
}

.wysiwyg p:last-child,
.kendo-content-block p:last-child {
  margin-bottom: 0;
}

.wysiwyg h2,
.wysiwyg h3,
.kendo-content-block h2,
.kendo-content-block h3 {
  margin: 0 0 18px;
}

.wysiwyg h2,
.kendo-content-block h2 {
  font-size: 20px;
}

.wysiwyg h3,
.kendo-content-block h3 {
  font-size: 16px;
}

.wysiwyg table,
.kendo-content-block table {
  max-width: 100%;
}

.wysiwyg table td,
.kendo-content-block table td {
  border: 1px black solid;
  padding: 10px;
  text-align: center;
}

.wysiwyg table td:first-child,
.kendo-content-block table td:first-child {
  text-align: left;
}

.wysiwyg table.no-border td,
.kendo-content-block table.no-border td {
  border-color: transparent;
}

.wysiwyg sup,
.kendo-content-block sup {
  font-size: 8px;
}

.kendo-content-block li {
  font-size: 18px;
  line-height: 30px;
}

.kendo-content-block ol > li:before {
  top: 3px;
}

@media (max-width: 768px) {
  .wysiwyg.table-responsive table {
    width: 100%;
  }
  .wysiwyg.table-responsive table p {
    font-size: 12px;
    line-height: 1.2;
    border-collapse: separate;
  }
  .wysiwyg.table-responsive table td {
    padding: 0 4px;
  }
}

.big-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0;
  transform: translate(-50%, 50%);
  padding: 0;
  font-size: 0;
  background: RGB(var(--color-primary));
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: transparent;
  border: none;
  pointer-events: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.4s height ease-in-out, 0.2s font-size ease-in-out, 0.2s color ease-in-out, 0.4s border-radius ease-in-out, 0.4s padding ease-in-out;
}

.big-search-button:before {
  content: "";
  font-family: "icomoon";
  margin: 0;
  display: flex;
  transition: 0.2s margin ease-in-out, 0.4s opacity ease-in-out, 0.4s font-size ease-in-out;
  font-size: 0;
  opacity: 0;
}

.big-search-button.active {
  height: 60px;
  font-size: 14px;
  border-radius: 30px;
  padding: 0 50px;
  pointer-events: all;
  color: white;
  transition: 0.2s height ease-in-out, 0.6s font-size ease-in-out, 0.6s color ease-in-out, 0.2s border-radius ease-in-out, 0.2s padding ease-in-out;
}

.big-search-button.active:before {
  margin-top: 3px;
  margin-right: 20px;
  font-size: 20px;
  opacity: 1;
  transition: 0.4s margin ease-in-out, 0.2s opacity ease-in-out, 0.2s font-size ease-in-out;
}

[dir="rtl"] .big-search-button.active:before {
  margin-left: 20px;
  margin-right: 0;
}

.big-search-button:hover, .big-search-button:focus {
  background: var(--color-primary-hover);
  outline: none;
}

.big-search-button:hover:before, .big-search-button:focus:before {
  margin-right: 30px;
}

* {
  box-sizing: border-box;
}

body {
  color: #262626;
  font-family: var(--main-font);
  -webkit-text-size-adjust: 100%;
}

body.no-scroll {
  overflow-y: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

@media (hover: none) and (pointer: coarse) {
  body.no-scroll {
    overflow: hidden;
  }
}

body.no-scroll-ios {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
}

button {
  cursor: pointer;
}

button,
input {
  font-family: var(--main-font);
}

a {
  color: RGB(var(--color-primary));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--main-font);
}

table caption {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.customRtlIcon {
  position: relative;
}

.customRtlIcon:before {
  content: 'RTL';
  display: block;
  position: absolute;
  padding: 2px 4.6px;
  left: 26px;
  bottom: 22px;
  background: #ff404e;
  color: #fff;
  font-size: 8px;
}

/* stylelint-disable */
@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir, 'Avenir Next', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
}

.plyr audio,
.plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: none;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui ::after,
.plyr--full-ui ::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
}

.plyr__control:focus {
  outline: none;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: none;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control::after,
a.plyr__control::before {
  display: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded='true'] {
  background: #1aafff;
  color: #fff;
}

.plyr--video .plyr__control svg {
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: #1aafff;
  color: #fff;
}

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  background: #1aafff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__menu,
.plyr__controls .plyr__progress,
.plyr__controls .plyr__time,
.plyr__controls .plyr__volume,
.plyr__controls > .plyr__control {
  margin-left: 5px;
}

.plyr__controls .plyr__menu + .plyr__control,
.plyr__controls .plyr__progress + .plyr__control,
.plyr__controls > .plyr__control + .plyr__control,
.plyr__controls > .plyr__control + .plyr__menu {
  margin-left: 2px;
}

.plyr__controls > .plyr__control:first-child,
.plyr__controls > .plyr__control:first-child + [data-plyr='pause'] {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__controls .plyr__menu,
  .plyr__controls .plyr__progress,
  .plyr__controls .plyr__time,
  .plyr__controls .plyr__volume,
  .plyr__controls > .plyr__control {
    margin-left: 10px;
  }
}

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px;
}

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), var(--gradient-color));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr [data-plyr='airplay'],
.plyr [data-plyr='captions'],
.plyr [data-plyr='fullscreen'],
.plyr [data-plyr='pip'] {
  display: none;
}

.plyr--airplay-supported [data-plyr='airplay'],
.plyr--captions-enabled [data-plyr='captions'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'],
.plyr--pip-supported [data-plyr='pip'] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded='true'] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded='true'] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role='menu'] {
  padding: 7px;
}

.plyr__menu__container [role='menuitem'],
.plyr__menu__container [role='menuitemradio'] {
  margin-top: 2px;
}

.plyr__menu__container [role='menuitem']:first-child,
.plyr__menu__container [role='menuitemradio']:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after,
.plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px;
}

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after,
.plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role='menuitemradio'] {
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::after,
.plyr__menu__container .plyr__control[role='menuitemradio']::before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container
.plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #1aafff;
}

.plyr__menu__container
.plyr__control[role='menuitemradio'][aria-checked='true']::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container
.plyr__control[role='menuitemradio'].plyr__tab-focus::before,
.plyr__menu__container .plyr__control[role='menuitemradio']:hover::before {
  background: rgba(0, 0, 0, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none;
}

.plyr--full-ui input[type='range'] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #1aafff;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type='range']::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
}

.plyr--full-ui input[type='range']::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px;
}

.plyr--full-ui input[type='range']::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  -moz-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type='range']::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
}

.plyr--full-ui input[type='range']::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px;
}

.plyr--full-ui input[type='range']::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type='range']::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type='range']::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type='range']::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
  margin-top: 0;
}

.plyr--full-ui input[type='range']::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type='range']:focus {
  outline: none;
}

.plyr--full-ui input[type='range']::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui
input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: none;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: none;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: none;
}

.plyr--full-ui.plyr--video input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--audio input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']::-ms-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 1);
  pointer-events: none;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
.plyr__controls
> .plyr__control:first-child
+ .plyr__control
.plyr__tooltip::before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls
> .plyr__control:first-child
+ .plyr__control
.plyr__tooltip--visible,
.plyr__controls
> .plyr__control:first-child
+ .plyr__control.plyr__tab-focus
.plyr__tooltip,
.plyr__controls
> .plyr__control:first-child
+ .plyr__control:hover
.plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 2);
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr__progress {
  flex: 1;
  left: 6.5px;
  margin-right: 13px;
  position: relative;
}

.plyr__progress input[type='range'],
.plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px);
}

.plyr__progress input[type='range'] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66);
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.plyr__volume input[type='range'] {
  margin-left: 5px;
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px;
  }
}

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px;
  }
}

.plyr--is-ios .plyr__volume {
  display: none !important;
}

.plyr--is-ios.plyr--vimeo [data-plyr='mute'] {
  display: none !important;
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

/* stylelint-enable */
.pika-single {
  display: block;
  transition: 0.2s opacity ease-in-out;
}

.pika-single.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.pika-single .pika-lendar {
  display: flex;
  flex-flow: column;
}

.pika-single .pika-title {
  display: flex;
  align-items: center;
  background: #f6f6f6;
  padding: 15px 30px 0;
}

.pika-single .pika-prev, .pika-single .pika-next {
  flex-grow: 1;
  background: none;
  border: none;
  font-size: 0;
  text-align: right;
  padding: 0;
  --start: 0;
  --end: 0.5turn;
}

[dir="rtl"] .pika-single .pika-prev, [dir="rtl"] .pika-single .pika-next {
  --start: 0.5turn;
  --end: 0;
}

.pika-single .pika-prev::before, .pika-single .pika-next::before {
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  font-size: 10px;
  display: block;
  transform: rotate(var(--start));
}

.pika-single .pika-prev.is-disabled, .pika-single .pika-next.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pika-single .pika-prev {
  order: -1;
}

.pika-single .pika-prev::before {
  transform: rotate(var(--end));
}

.pika-single .pika-next {
  order: 1;
}

.pika-single .pika-label {
  display: flex;
  color: #393939;
}

.pika-single .pika-label:nth-of-type(2) {
  font-size: 0;
  position: relative;
  margin-left: 10px;
}

.pika-single .pika-label:nth-of-type(2)::before {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid RGB(var(--color-primary));
  bottom: -10px;
  width: 100%;
}

.pika-single .pika-label:nth-of-type(2)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-left: 15px;
  font-size: 6px;
  z-index: 100;
  pointer-events: none;
}

.pika-single .pika-select {
  background: none;
  appearance: none;
  border: none;
  font-size: 16px;
  color: #393939;
  font-family: inherit;
  padding-right: 20px;
  cursor: pointer;
}

.pika-single .pika option {
  cursor: pointer;
}

.pika-single .pika-select-month {
  display: none;
}

.pika-single table {
  background: white;
  width: 100%;
}

.pika-single tbody {
  padding: 8px 20px 15px;
  background: white;
}

.pika-single thead {
  padding: 15px 20px 8px;
  background: #f6f6f6;
}

.pika-single th abbr,
.pika-single td button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-style: normal;
  color: #393939;
  padding: 0;
  width: 34px;
  height: 34px;
  background: none;
  text-decoration: none;
}

.pika-single td button:hover {
  background: RGB(var(--color-primary));
  color: white;
}

.pika-single td.is-selected button {
  background: RGB(var(--color-primary));
  color: white;
}

.pika-single td.is-disabled button {
  color: #959595;
  cursor: default;
}

.pika-single td.is-disabled button:hover {
  background: none;
  color: #959595;
}

.pika-single th {
  padding: 15px 0 8px;
}

.pika-single th,
.pika-single td {
  --start: 20px;
  --end: 0;
}

[dir="rtl"] .pika-single th, [dir="rtl"]
.pika-single td {
  --end: 20px;
  --start: 0;
}

.pika-single th:first-child,
.pika-single td:first-child {
  padding-left: var(--start);
  padding-right: var(--end);
}

.pika-single th:last-child,
.pika-single td:last-child {
  padding-right: var(--start);
  padding-left: var(--end);
}

.pika-single tr:last-child td {
  padding-bottom: 15px;
}

.pika-single tr:first-child td {
  padding-top: 8px;
}

.main-nav--left {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.main-nav--copy {
  text-transform: uppercase;
  padding: 10px 10px 10px 0;
  font-size: 16px;
  color: RGB(var(--color-primary));
}

@media screen and (min-width: 1265px) {
  .main-nav--copy {
    margin-top: 3px;
  }
}

.main-nav--logo {
  margin-right: 28px;
  min-width: max-content;
}

[dir='rtl'] .main-nav--logo {
  margin-right: 0;
  margin-left: 28px;
}

.main-nav--logo img {
  height: 36px;
  width: auto;
  transition: 0.4s transform ease-in-out;
}

@media screen and (min-width: 1265px) {
  .main-nav--logo img {
    height: auto;
    width: auto;
    max-height: 60px;
  }
}

.main-nav--logo:focus {
  outline: none;
}

.main-nav--logo:focus img {
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

.main-nav--logo .desktop {
  display: none;
}

@media screen and (min-width: 1265px) {
  .main-nav--logo .desktop {
    display: block;
  }
}

@media screen and (min-width: 1265px) {
  .main-nav--logo .mobile {
    display: none;
  }
}

.main-nav--note {
  font-size: 12px;
  color: #6e6e6e;
  font-weight: normal;
}

.main-nav--center {
  flex-flow: column;
  display: none;
}

@media screen and (min-width: 1265px) {
  .main-nav--center {
    display: flex;
  }
}

.main-nav--nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.main-nav--element {
  list-style-type: none;
  width: 33%;
}

@media screen and (min-width: 1265px) {
  .main-nav--element {
    margin-right: 20px;
    width: auto;
  }
  [dir='rtl'] .main-nav--element {
    margin-right: 0;
    margin-left: 20px;
  }
}

.main-nav--element:last-child {
  margin: 0;
}

.main-nav--link, .main-nav--current {
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #262626;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
}

.main-nav--link:focus, .main-nav--current:focus {
  color: RGB(var(--color-primary));
  font-weight: bold;
  outline: none;
}

.main-nav--link:hover {
  color: var(--color-primary-hover);
  text-decoration: none;
}

.main-nav--current {
  font-weight: 700;
}

.color--sampoernaRed .main-nav--current {
  color: RGB(var(--color-primary));
}

.main-nav--right {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  min-width: max-content;
  margin-left: auto;
}

[dir='rtl'] .main-nav--right {
  margin-left: 0;
  margin-right: auto;
}

.main-nav--left .main-nav--logo img {
  width: auto;
  height: auto;
}

.market-nav--right {
  align-items: flex-start;
}

.market-nav--right .drop-down {
  display: flex;
  align-items: flex-start;
}

.market-nav--right .drop-down--current {
  padding: 10px 20px;
}

@media screen and (min-width: 1265px) {
  .market-nav--right .drop-down--current {
    padding: 15px 20px;
  }
}

.market-nav--right .drop-down--dot {
  display: none;
}

.market-nav--right .drop-down--content {
  margin-top: 40px !important;
}

.market-nav--right .drop-down li:first-child {
  margin-top: 10px;
}

.market-nav {
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin: 0 -20px;
  padding: 0 20px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 1265px) {
  .market-nav {
    height: 50px;
  }
}

.market-nav--goto {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-size: 16px;
  color: RGB(var(--color-primary));
}

@media screen and (min-width: 1265px) {
  .market-nav--goto {
    flex-wrap: nowrap;
  }
}

.market-nav--logo {
  margin: 0 auto 0 40px;
  min-width: max-content;
  display: flex;
  align-items: center;
}

[dir='rtl'] .market-nav--logo {
  margin: 0 40px 0 auto;
}

.market-nav--logo img {
  height: 36px;
  width: auto;
}

@media screen and (min-width: 1265px) {
  .market-nav--logo img {
    height: auto;
    max-height: 60px;
  }
}

.market-nav--logo .desktop {
  display: none;
}

@media screen and (min-width: 1265px) {
  .market-nav--logo .desktop {
    display: block;
  }
}

@media screen and (min-width: 1265px) {
  .market-nav--logo .mobile {
    display: none;
  }
}

.market-nav--content {
  display: flex;
  justify-content: space-between;
}

.market-nav--menus-container {
  display: flex;
  margin-left: 25px;
  flex-grow: 1;
  transition: 0.4s height ease-in-out;
}

@media screen and (max-width: 1265px) {
  .market-nav--menus-container {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100vw;
    height: 0;
    overflow: hidden;
    margin: 0;
  }
}

.market-nav--menus {
  display: flex;
  flex-flow: column;
  width: 100%;
  background: white;
  padding: 30px 20px;
}

@media screen and (min-width: 1265px) {
  .market-nav--menus {
    flex-flow: row;
    width: auto;
    align-self: stretch;
    background: none;
    padding: 0;
  }
}

.market-nav .unfoldable-menu {
  background: white;
}

.market-nav .unfoldable-menu--dropper {
  background: white;
}

.market-nav .unfoldable-menu--title.droppable::after {
  color: RGB(var(--color-primary));
}

.market-nav .unfoldable-menu--container {
  top: 50px;
}

.market-nav--right {
  display: flex;
}

.market-nav .drop-down {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
  margin-top: 10px;
}

@media screen and (min-width: 1265px) {
  .market-nav .drop-down {
    margin-top: 2px;
  }
}

@media screen and (min-width: 1265px) {
  .market-nav .drop-down--current {
    padding: 14px 22px;
  }
}

.market-nav .drop-down--dot {
  display: none;
}

.market-nav .drop-down li:first-child {
  margin-top: 10px;
}

.market-nav--burger {
  border: none;
  background: none;
  padding: 0;
  position: relative;
  align-self: center;
  width: 24px;
  height: 16px;
  z-index: 2000;
  cursor: pointer;
}

[dir='rtl'] .market-nav--burger {
  margin-left: 30px;
}

.market-nav--burger > span {
  position: absolute;
  background: #393939;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s transform ease-in-out, 0.4s background ease-in-out;
}

.market-nav--burger > span:nth-child(1) {
  top: 0;
}

.market-nav--burger > span:nth-child(2) {
  top: calc(50% - 1px);
}

.market-nav--burger > span:nth-child(3) {
  bottom: 0;
  transform: none;
  right: 0;
  width: 16px;
  left: auto;
}

.market-nav--burger:focus {
  outline: none;
}

.market-nav--burger:focus > span {
  background: RGB(var(--color-primary));
}

.open .market-nav--burger, .market-nav--burger.close {
  height: 24px;
}

.open .market-nav--burger > span:nth-child(1), .market-nav--burger.close > span:nth-child(1) {
  top: calc(50% - 1px);
  transform: translateX(-50%) rotate(45deg);
}

.open .market-nav--burger > span:nth-child(2), .market-nav--burger.close > span:nth-child(2) {
  transform: translateX(-50%) rotate(-45deg);
}

.open .market-nav--burger > span:nth-child(3), .market-nav--burger.close > span:nth-child(3) {
  width: 0;
}

@media screen and (min-width: 1265px) {
  .market-nav--burger {
    display: none;
  }
}

.search {
  margin-left: 30px;
  height: 100%;
  position: relative;
  align-self: center;
  color: #6e6e6e;
  transition: 0.4s margin ease-in-out, 0.4s width ease-in-out;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  [dir='rtl'] .search {
    margin-left: 0;
  }
}

@media screen and (min-width: 1265px) {
  .search {
    margin-left: 40px;
  }
}

.search--glass {
  --transitionSpeed: 0.4s;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 20px;
  color: #393939;
  transition: var(--transitionSpeed) color ease-in-out, var(--transitionSpeed) opacity ease-in-out, var(--transitionSpeed) width ease-in-out;
  overflow: hidden;
  z-index: 2;
}

.search--glass:hover, .search--glass:focus {
  color: RGB(var(--color-primary));
  outline: none;
  --transitionSpeed: 0.2s;
}

.search--glass:before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
}

@media screen and (min-width: 1265px) {
  .search--glass:before {
    font-size: 16px;
  }
}

.search--form {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2000;
  background: white;
  border-radius: 15px;
}

@media screen and (min-width: 1265px) {
  .search--form {
    position: absolute;
    right: 0;
    top: 50%;
    top: 0;
    padding: 20px 0 40px 10px;
    transform: none;
  }
}

[dir='rtl'] .search--form {
  left: -40px;
  right: unset;
}

.search .search-field-drop {
  transform: translateX(-100vw);
  opacity: 0;
}

.search .search-field-drop--field {
  width: 0;
  height: 18px;
  border-width: 0;
}

.search.open {
  width: 0;
}

@media screen and (max-width: 1265px) {
  .search.open {
    margin: 0;
  }
  .search.open .search--glass {
    width: 0;
    opacity: 0;
  }
  .search.open .search-field-drop {
    z-index: 1;
    width: 100vw;
  }
}

@media screen and (min-width: 1265px) {
  .search.open--form {
    padding: 20px 20px;
  }
  .search.open .search--glass {
    margin: 0 20px;
  }
  .search.open .search-field-drop--field {
    border-width: 0 0 0 1px;
    width: 270px;
    height: 30px;
  }
  [dir="rtl"] .search.open .search-field-drop--field {
    border-width: 0 1px 0 0;
  }
}

.search.open .search-field-drop {
  transform: translateX(0);
  opacity: 1;
}

@media screen and (max-width: 1265px) {
  .search.open .search-field-drop--drop {
    box-shadow: none;
  }
}

.search.open .boxed-style {
  background-color: #f6f6f6;
  align-items: center;
}

@media screen and (min-width: 1265px) {
  .search.open .boxed-style .search-field-drop--field {
    height: 18px;
    width: 220px;
  }
}

.search.open .search-field-drop--close {
  transition: 0.4s all ease-in-out;
  visibility: visible;
  opacity: 1;
}

.boxed-style {
  border-radius: 0;
  padding: 0;
  transition: 0.4s background-color ease-in-out;
}

.medium-text-on-image {
  display: flex;
  flex-flow: column;
  position: relative;
  justify-content: flex-end;
  padding: 20px 40px 20px 20px;
}

@media screen and (min-width: 960px) {
  .medium-text-on-image {
    padding: 40px;
  }
}

.medium-text-on-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  z-index: 1;
  transition: 0.4s opacity ease-in-out;
}

.medium-text-on-image:hover:before, .medium-text-on-image:focus:before {
  opacity: 0.2;
}

.medium-text-on-image:hover:after, .medium-text-on-image:focus:after {
  right: 10px;
}

.medium-text-on-image:focus:not(:hover) {
  outline: 4px solid #262626;
}

.medium-text-on-image--content {
  z-index: 2;
}

.medium-text-on-image .box-article-content--description {
  max-width: 80%;
  display: none;
}

@media screen and (min-width: 960px) {
  .medium-text-on-image .box-article-content--description {
    display: block;
  }
}

.medium-text-on-image .box-article-content--link {
  margin-top: 10px;
}

.small-text-on-image {
  display: flex;
  flex-flow: column;
  position: relative;
  justify-content: flex-end;
  padding: 40px;
}

.small-text-on-image--content {
  z-index: 2;
}

@media screen and (max-width: 640px) {
  .small-text-on-image--content {
    width: calc(100% - 125px);
  }
}

.small-text-on-image .box-article-content--link {
  margin-top: 10px;
}

.unsmoke-small-text-on-image {
  position: relative;
  display: grid;
  grid-template-areas: 'bubble content' '. content';
  grid-template-columns: 100px 1fr;
  grid-template-rows: 100px auto;
  grid-gap: 0 25px;
}

@media screen and (min-width: 640px) {
  .unsmoke-small-text-on-image {
    grid-template-areas: 'bubble';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
  }
}

.unsmoke-small-text-on-image .unsmoke-bubble-wrapper {
  grid-area: bubble;
}

.unsmoke-small-text-on-image .small-text-on-image--content {
  grid-area: content;
  position: static;
}

@media screen and (min-width: 640px) {
  .unsmoke-small-text-on-image .small-text-on-image--content {
    grid-area: bubble;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 640px) {
  .unsmoke-small-text-on-image .small-text-on-image--content {
    width: auto;
  }
}

.unsmoke-small-text-on-image .box-article-content--title {
  color: #262626;
  min-width: 0;
  width: auto;
}

@media screen and (min-width: 640px) {
  .unsmoke-small-text-on-image .box-article-content--title {
    color: white;
  }
}

.unsmoke-small-text-on-image .box-article-content--reading {
  color: #6e6e6e;
}

@media screen and (min-width: 640px) {
  .unsmoke-small-text-on-image .box-article-content--reading {
    color: white;
  }
}

@media screen and (max-width: 640px) {
  .unsmoke-small-text-on-image .box-article-content--link {
    color: var(--text-black);
  }
}

@media screen and (min-width: 640px) and (max-width: 640px) {
  .unsmoke-small-text-on-image .box-article-content--link {
    color: white;
  }
}

.footer-list {
  display: flex;
  flex-flow: column;
}

.footer-list--title {
  margin-bottom: 10px;
  color: #393939;
}

.footer-list--link {
  font-size: 14px;
  line-height: 24px;
  color: #6e6e6e;
  transition: 0.2s all ease-in-out;
}

.footer-list--link:hover, .footer-list--link:focus {
  outline: none;
  color: var(--color-primary-hover);
  font-weight: bold;
}

.footer-link {
  font-size: 14px;
  line-height: 24px;
  color: #6e6e6e;
  transition: 0.2s all ease-in-out;
}

.footer-link:hover, .footer-link:focus {
  outline: none;
  color: var(--color-primary-hover);
  font-weight: bold;
}

.footer-nav {
  width: 100%;
  color: #6e6e6e;
  display: grid;
  grid-gap: 40px;
  padding-top: 40px;
  border-top: 1px solid #cccccc;
}

@media screen and (min-width: 1210px) {
  .footer-nav {
    grid-template-columns: 2.5fr 1fr;
  }
}

.footer-nav--content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (min-width: 640px) {
  .footer-nav--content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 960px) {
  .footer-nav--content {
    display: flex;
  }
}

.footer-nav--content a {
  --side-margin: 0;
  display: block;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
  color: #393939;
  transition: 0.2s all ease-in-out;
  margin-right: var(--side-margin);
}

[dir="rtl"] .footer-nav--content a {
  margin-left: var(--side-margin);
  margin-right: 0;
}

.footer-nav--content a:hover, .footer-nav--content a:focus {
  color: var(--color-primary-hover);
  outline: none;
}

.footer-nav--content a:focus {
  font-weight: bold;
}

@media screen and (min-width: 960px) {
  .footer-nav--content a {
    --side-margin: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .footer-nav--content a {
    --side-margin: 60px;
  }
}

.footer-nav--copyright {
  font-size: 11px;
}

@media screen and (min-width: 1210px) {
  .footer-nav--copyright {
    text-align: right;
  }
  [dir="rtl"] .footer-nav--copyright {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .footer-nav--copyright {
    margin: 0;
  }
}

.button-widget {
  margin: 0 auto 30px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
}

@media screen and (min-width: 960px) {
  .button-widget {
    flex-flow: row;
    align-items: center;
  }
}

.button-widget.clickable {
  cursor: pointer;
}

.button-widget--button {
  position: relative;
  padding: 10px 30px;
  border: 1px solid RGB(var(--color-primary));
  background-color: RGB(var(--color-primary));
}

.button-widget--button:focus {
  outline: none;
}

.button-widget--button.is-link {
  color: white;
}

@media screen and (min-width: 960px) {
  .button-widget--button {
    background-color: initial;
    border-color: #cccccc;
  }
  .button-widget--button.is-link {
    color: #262626;
    transition: 0.4s color ease-in-out, 0.4s border-color ease-in-out;
  }
}

.button-widget--blue-content {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  padding: 10px 30px;
  background: RGB(var(--color-primary));
  border: 1px solid RGB(var(--color-primary));
  color: white;
  opacity: 0;
  transition: 0.4s opacity ease-in-out;
}

@supports (clip-path: polygon(0 0, 0 100%, 0 100%, 0 0)) {
  .button-widget--blue-content {
    opacity: 1;
    transition: 0.4s clip-path ease-in-out;
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
}

.button-widget--blue-content::before {
  content: attr(data-content);
  white-space: nowrap;
}

@media screen and (min-width: 960px) {
  .button-widget:hover .button-widget--blue-content,
  *:focus .button-widget--blue-content {
    opacity: 1;
  }
  @supports (clip-path: polygon(0 0, 0 100%, 0 100%, 0 0)) {
    .button-widget:hover .button-widget--blue-content,
    *:focus .button-widget--blue-content {
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    }
  }
}

.button-widget--description {
  display: flex;
  align-items: center;
  color: #6e6e6e;
  font-size: 20px;
  line-height: 24px;
  flex-grow: 1;
  opacity: 0;
  margin-top: 20px;
  transform: translateX(-100%);
  transition: 0.8s opacity ease-in-out, 0.8s transform ease-in-out;
}

@media screen and (min-width: 960px) {
  .button-widget--description {
    transform: none;
    flex-flow: row;
    margin: 0 0 3px;
  }
  .button-widget--description::before {
    content: "";
    height: 0;
    border-bottom: 1px solid #e5e5e5;
    margin-left: 20px;
    margin-right: 10px;
    flex-grow: 0;
    margin-top: 3px;
    transition: 1s flex-grow ease-in-out;
  }
}

.button-widget--description.active {
  opacity: 1;
  transform: translateX(0);
}

.button-widget--description.active::before {
  flex-grow: 1;
}

.button-widget:hover .button-widget--description,
*:focus + .button-widget--description {
  opacity: 0;
}

.button-widget--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.button-widget--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.button-widget--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.button-widget--close::after {
  content: "";
  font-family: "icomoon";
}

.quote-widget {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 960px) {
  .quote-widget {
    margin-bottom: 90px;
  }
}

.quote-widget:before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  color: #cccccc;
  display: block;
  font-style: normal;
}

.quote-widget.small-text {
  align-items: flex-start;
  padding: 0 20px;
}

.quote-widget.small-text:before {
  color: #393939;
  margin-bottom: 20px;
}

@media screen and (min-width: 960px) {
  .quote-widget.small-text {
    padding: 0 80px;
  }
}

.quote-widget.small-text .quote-author {
  display: inline;
}

.quote-widget.small-text .quote-author--name {
  display: inline-flex;
}

.quote-widget.small-text .quote-author--comma {
  display: inline;
}

.quote-widget--quote {
  font-size: 28px;
  line-height: 34px;
  font-style: normal;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .quote-widget--quote {
    font-size: 34px;
  }
}

.small-text .quote-widget--quote {
  color: #393939;
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}

.picture-quote-widget {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 23px;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .picture-quote-widget {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 700px) {
  .picture-quote-widget {
    flex-flow: row;
    padding: 0 50px;
  }
}

.picture-quote-widget--text {
  display: flex;
  flex-flow: column;
}

.picture-quote-widget--avatar {
  width: 110px;
  min-width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-bottom: 22px;
  overflow: hidden;
  display: none;
  position: relative;
}

.picture-quote-widget--avatar.is-mobile-show {
  display: block;
}

@media screen and (min-width: 700px) {
  .picture-quote-widget--avatar {
    display: block;
    margin: 43px 40px 0 0;
  }
  .picture-quote-widget--avatar.is-right {
    margin: 43px 0 0 40px;
    order: 1;
  }
}

.picture-quote-widget--quote {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 24px;
}

.picture-quote-widget--quote:before {
  content: "";
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "icomoon";
}

.picture-quote-widget .quote-author {
  align-items: flex-start;
}

.large-image-quote-widget {
  background: #f6f6f6;
  width: 100vw;
  position: relative;
  margin: 0 -20px 60px;
}

@media screen and (min-width: 640px) {
  .large-image-quote-widget {
    padding-left: 50%;
    min-height: 450px;
  }
}

@media screen and (min-width: 1200px) {
  .large-image-quote-widget {
    margin: 0 0 60px;
  }
}

.large-image-quote-widget--picture {
  position: absolute;
  bottom: 0;
  right: -40px;
  width: 110%;
  height: 510px;
}

@media screen and (min-width: 640px) {
  .large-image-quote-widget--picture {
    right: calc(50% + 40px);
    height: calc(100% + 50px);
    width: 50%;
  }
}

.large-image-quote-widget--picture img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
}

@media screen and (min-width: 640px) {
  .large-image-quote-widget--picture img {
    object-position: bottom right;
  }
}

.large-image-quote-widget--text {
  padding: 50px 0 510px;
  text-align: center;
  width: calc(100% - 40px);
  margin: 0 auto 20px;
}

@media screen and (min-width: 640px) {
  .large-image-quote-widget--text {
    width: calc(100% - 80px);
    text-align: left;
    padding: 100px 0;
  }
}

.large-image-quote-widget--text:before {
  content: "";
  display: block;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "icomoon";
  color: #cccccc;
  font-style: normal;
}

.large-image-quote-widget--quote {
  display: block;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .large-image-quote-widget--quote {
    max-width: calc(100% - 40px);
  }
}

@media screen and (min-width: 960px) {
  .large-image-quote-widget--quote {
    max-width: 450px;
  }
}

.small-written-by {
  width: 100%;
  background: #f6f6f6;
  padding: 40px;
  display: block;
  color: #262626;
  outline: none;
}

.small-written-by:hover {
  color: #262626;
}

.small-written-by:focus:not(:hover) {
  color: RGB(var(--color-primary));
  outline: 4px solid #262626;
}

.small-written-by--title {
  margin-bottom: 7px;
}

.small-written-by--time {
  margin-bottom: 20px;
}

.content-block {
  position: relative;
}

.content-block--parallax {
  clip-path: inset(0);
}

@media screen and (max-width: 960px) {
  .content-block--parallax {
    display: flex;
    flex-flow: column;
  }
}

.content-block--parallax .back-picture {
  object-fit: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  order: 1;
}

.content-block--parallax-mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .content-block--parallax-mobile {
    display: block;
    order: 0;
  }
}

@media screen and (max-width: 960px) {
  .content-block--parallax-desktop {
    display: none;
  }
}

.content-block > * {
  opacity: 1;
  transition: all 0.3s ease-in;
  visibility: visible;
}

.content-block.hidden > * {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.content-block--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.content-block--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.content-block--close {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.content-block--close::after {
  content: "";
  font-family: "icomoon";
}

.content-block .wysiwyg {
  position: relative;
}

.banner-widget {
  position: relative;
}

.banner-widget--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.banner-widget--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.banner-widget--close {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.banner-widget--close::after {
  content: "";
  font-family: "icomoon";
}

@media screen and (min-width: 960px) {
  .banner-widget {
    margin: 0 -20px 55px;
  }
}

@media screen and (min-width: 1200px) {
  .banner-widget {
    margin: 0;
  }
}

.banner-widget .topic-highlight {
  max-width: none;
  flex-wrap: wrap;
  padding: 0 20px 20px 20px;
}

@media screen and (min-width: 700px) {
  .banner-widget .topic-highlight {
    padding: 30px 20px 60px 20px;
  }
}

@media screen and (min-width: 1200px) {
  .banner-widget .topic-highlight {
    padding: 0 calc((100vw - 1170px) / 2) 60px calc((100vw - 1170px) / 2);
  }
}

.banner-widget .topic-highlight--highlights {
  margin-top: 0;
}

@media screen and (min-width: 1200px) {
  .banner-widget .topic-highlight--highlights {
    margin-left: auto;
  }
  [dir="rtl"] .banner-widget .topic-highlight--highlights {
    margin-left: 0;
    margin-right: auto;
  }
}

.banner-widget .topic-highlight--content {
  align-self: center;
  padding-right: 20px;
  flex-grow: 0;
}

@media screen and (min-width: 640px) {
  .banner-widget .topic-highlight--content {
    align-self: flex-start;
    padding-right: 20px;
  }
  [dir="rtl"] .banner-widget .topic-highlight--content {
    padding-left: 20px;
    padding-right: 0;
  }
}

@media screen and (min-width: 700px) {
  .banner-widget .topic-highlight--content {
    align-self: center;
    width: 50%;
    flex-grow: 1;
  }
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight--content {
    flex-grow: 0;
  }
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight--content .title2 {
    font-size: 48px;
    line-height: 52px;
  }
}

.banner-widget .topic-highlight--content .box-article-content--category, .banner-widget .topic-highlight--content .box-article-content--reading, .banner-widget .topic-highlight--content .box-article-content--link {
  display: none;
}

.banner-widget .topic-highlight--content .box-article-content--title {
  margin-bottom: 20px;
}

.banner-widget .topic-highlight--content .box-article-content--description {
  font-size: 20px;
  line-height: 26px;
  display: block;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) {
  .banner-widget .topic-highlight--content .box-article-content--description {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight--content .box-article-content--description, .banner-widget .topic-highlight--content .box-article-content--title {
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight--background {
    height: 100%;
  }
  .banner-widget .topic-highlight--background .back-picture.has-gradient:after {
    height: 100%;
    top: 0;
    background: linear-gradient(to left, transparent, var(--gradient-color));
  }
  html[dir='rtl'] .banner-widget .topic-highlight--background .back-picture.has-gradient:after {
    background: linear-gradient(to right, transparent, var(--gradient-color));
  }
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight.fix-height {
    min-height: 600px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .banner-widget .topic-highlight.fix-height .box-article-content--title, .banner-widget .topic-highlight.fix-height .box-article-content--description {
    width: 100%;
  }
}

.banner-widget .topic-highlight--logo-container {
  position: relative;
  align-self: center;
  margin: -30px 0 10px;
}

@media screen and (min-width: 960px) {
  .banner-widget .topic-highlight--logo-container {
    align-self: flex-start;
  }
}

@media screen and (min-width: 700px) {
  .banner-widget .topic-highlight--logo-container {
    margin: 0;
    margin-left: auto;
    align-self: flex-end;
  }
}

.banner-widget nav {
  width: 100%;
  margin-bottom: 70px;
}

.banner-widget nav p {
  color: #fff;
}

.marketpage-news-detail-articles-vertical .related-articles-partial--title {
  padding-left: 10px;
  margin-bottom: 18px;
}

@media screen and (min-width: 640px) {
  .marketpage-news-detail-articles-vertical .related-articles-partial--content {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 640px) {
  .marketpage-news-detail-articles-vertical {
    display: flex;
  }
}

.page-info-widget-default {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .page-info-widget-default {
    margin-bottom: 90px;
  }
}

.page-info-widget-default--content {
  margin: 0 auto;
}

.investor-highlight {
  max-width: 580px;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .investor-highlight {
    padding: 100px 45px 0 0;
  }
}

.investor-highlight .title3 {
  margin-bottom: 20px;
  font-weight: 400;
  display: block;
}

@media screen and (min-width: 700px) {
  .investor-highlight .title3 {
    margin-bottom: 25px;
  }
}

.investor-highlight--illu {
  width: 60px;
  display: block;
  margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .investor-highlight--illu {
    margin-bottom: 35px;
  }
}

.investor-highlight--infos {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
  max-width: 370px;
}

@media screen and (min-width: 700px) {
  .investor-highlight--infos {
    margin-bottom: 60px;
  }
}

.investor-highlight--results {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.investor-highlight--share {
  font-size: 20px;
  font-weight: normal;
}

.investor-highlight--share small {
  display: block;
  font-size: 12px;
}

.investor-highlight--values {
  display: flex;
  flex-flow: column;
}

.investor-highlight--price {
  font-size: 40px;
  display: flex;
  align-items: flex-start;
  align-self: flex-end;
  color: #393939;
}

.investor-highlight--price span {
  font-size: 14px;
}

.investor-highlight--value.fail {
  color: #dd1414;
}

.investor-highlight--value.fail:before {
  content: '\e80b';
  display: inline-block;
  width: 15px;
  height: 15px;
  font-family: "icomoon";
  font-size: 12px;
  position: relative;
  transform: scaleX(-1);
  bottom: 2px;
  margin-right: 10px;
}

.investor-highlight--value.succeed {
  color: #0c8925;
}

.investor-highlight--value.succeed:before {
  content: '\e80a';
  display: inline-block;
  width: 15px;
  height: 15px;
  font-family: "icomoon";
  font-size: 12px;
  position: relative;
  transform: scaleX(-1);
  margin-right: 7px;
}

.investor-highlight a {
  padding-top: 10px;
  display: block;
  border-top: 1px solid RGB(var(--color-primary));
  font-size: 12px;
  color: RGB(var(--color-primary));
}

.investor-highlight a::after {
  font-size: 11px;
}

.basic-picture-widget .still-picture {
  width: 100%;
  height: auto;
}

.editors-pick-widget--title {
  margin-bottom: 17px;
}

.editors-pick-widget .category-title {
  margin-bottom: 6px;
}

.editors-pick-widget--content {
  background: #f6f6f6;
  margin: 0 -20px;
  padding: 30px 20px;
}

@media screen and (min-width: 640px) {
  .editors-pick-widget--content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }
}

@media screen and (min-width: 700px) {
  .editors-pick-widget--content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .editors-pick-widget--content {
    display: block;
    padding: 40px 20px 50px;
    margin: 0;
  }
}

.pick-widget {
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
}

.pick-widget .category-title:focus {
  outline: none;
  color: #262626;
  font-weight: bold;
}

.pick-widget--title {
  display: block;
  color: #262626;
  margin-bottom: 15px;
}

.pick-widget--title:focus {
  outline: none;
  color: RGB(var(--color-primary));
}

.latest-press-releases {
  display: flex;
  flex-flow: column;
  background: #f6f6f6;
  margin: 0 -20px;
  padding: 30px 20px 0;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .latest-press-releases {
    margin-right: calc((-100vw + 1170px) / 2);
    margin-left: 0;
    padding: 85px calc((100vw - 1170px) / 2) 0 0;
  }
}

.latest-press-releases--content {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .latest-press-releases--content {
    width: 100%;
    margin: 0 0 0 auto;
  }
}

.latest-press-releases--head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 40px;
  flex-flow: column;
}

@media screen and (min-width: 1200px) {
  .latest-press-releases--head {
    flex-flow: row;
  }
}

.latest-press-releases--title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #6e6e6e;
}

.latest-press-releases--releases {
  display: flex;
  flex-flow: column;
}

.latest-press-releases--link {
  font-size: 12px;
}

.news-item {
  background: white;
  border-radius: 4px;
  border: 1px solid #efefef;
  margin-bottom: -8px;
  padding: 20px 35px 28px;
  width: 100%;
  cursor: pointer;
  transition: 0.4s box-shadow ease-in-out, 0.4s padding ease-in-out, 0.4s margin ease-in-out, 0.4s width ease-in-out;
}

.news-item--head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.news-item--date {
  color: #6e6e6e;
  text-transform: none;
  font-weight: 400;
}

.news-item--category {
  color: #262626;
}

.news-item--top-link {
  display: inline-block;
  color: #959595;
  font-size: 23px;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s color ease-in-out, 0.4s opacity ease-in-out, 0.4s transform ease-in-out;
}

.news-item--top-link:before {
  content: "";
  font-family: "icomoon";
}

.news-item--top-link.download:before {
  content: "";
}

.active .news-item--top-link {
  color: RGB(var(--color-primary));
  transform: scale(1.3) translateX(-2px);
  opacity: 1;
  pointer-events: all;
}

.news-item--title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 25px;
  transition: 0.4s color ease-in-out;
  color: #393939;
}

.news-item:hover .news-item--title {
  color: #262626;
}

.news-item--description {
  color: #6e6e6e;
}

.news-item--format {
  padding-top: 40px;
  color: #6e6e6e;
}

.news-item.active {
  position: relative;
  z-index: 1;
  margin: -8px -5px;
  width: calc(100% + 10px);
  box-shadow: 0 -5px 30px rgba(0, 0, 0, 0.16);
  padding: 20px 40px 28px;
}

.section-highlights {
  background: RGB(var(--color-secondary, var(--color-primary)));
  padding: 30px 40px 40px;
  margin: 0 -20px;
}

@media screen and (min-width: 640px) {
  .section-highlights {
    background: none;
    margin: 0 auto 50px;
    padding: 0;
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .section-highlights {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 640px) {
  .section-highlights .wrapper {
    margin: 0 auto;
  }
}

.section-highlights--title {
  color: white;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 15px;
}

@media screen and (min-width: 640px) {
  .section-highlights--title {
    color: #393939;
    margin-bottom: 10px;
    font-weight: normal;
  }
}

.section-highlights--description {
  color: white;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .section-highlights--description {
    color: #393939;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 640px) {
  .section-highlights--title, .section-highlights--description {
    max-width: 70%;
  }
}

@media screen and (min-width: 1200px) {
  .section-highlights--title, .section-highlights--description {
    max-width: 50%;
  }
}

.section-highlights--carrousel {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media screen and (min-width: 640px) {
  .section-highlights--carrousel {
    width: 100%;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-auto-flow: row;
    align-items: stretch;
  }
  .section-highlights--carrousel.has-one {
    grid-template-columns: repeat(1, 1fr);
  }
  .section-highlights--carrousel.has-one .section-highlight--content {
    max-width: 60%;
  }
  .section-highlights--carrousel.has-two {
    grid-template-columns: repeat(2, 1fr);
  }
  .section-highlights--carrousel.has-three {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  .section-highlights--carrousel.has-three .section-highlight:first-of-type {
    grid-area: 1 / 1 / 1 / span 2;
  }
  .section-highlights--carrousel.has-three .section-highlight:first-of-type .section-highlight--content {
    max-width: 80%;
  }
}

@media screen and (min-width: 640px) and (min-width: 960px) {
  .section-highlights--carrousel.has-three {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
  .section-highlights--carrousel.has-three .section-highlight:first-of-type {
    grid-area: auto;
  }
  .section-highlights--carrousel.has-three .section-highlight:first-of-type .section-highlight--content {
    max-width: none;
  }
}

.section-highlights--dots {
  padding-top: 25px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 640px) {
  .section-highlights--dots {
    display: none;
  }
}

.section-highlights .carousel-dot {
  background: white;
}

.section-highlights--counter {
  position: absolute;
  top: 7px;
  right: 8px;
  font-size: 12px;
  color: white;
  z-index: 10;
}

@media screen and (min-width: 640px) {
  .section-highlights--counter {
    display: none;
  }
}

.section-highlights--picture-slot {
  position: relative;
  width: 100%;
  z-index: 3;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.5);
  background: black;
}

.section-highlights--picture-slot:before {
  content: '';
  padding-bottom: 60%;
  display: block;
}

@media screen and (min-width: 640px) {
  .section-highlights--picture-slot {
    display: none;
  }
}

.section-highlights--content-slot {
  background: white;
  margin: -30px 0 0;
  width: calc(100% + 40px);
}

@media screen and (min-width: 640px) {
  .section-highlights--content-slot {
    display: none;
  }
}

.section-highlight {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
  padding: 0 20px;
  margin: 0 -20px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
}

@media screen and (min-width: 640px) {
  .section-highlight {
    width: auto;
    height: auto;
    min-height: 380px;
    position: relative;
    opacity: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
  .section-highlight:focus:not(:hover) {
    outline: 4px solid #262626;
  }
}

.section-highlight.not-current {
  pointer-events: none;
}

.section-highlight.current {
  pointer-events: auto;
}

.section-highlight--picture {
  position: relative;
  width: 100%;
  height: auto;
  opacity: 0;
}

@media screen and (min-width: 640px) {
  .section-highlight--picture {
    position: absolute;
    height: 100%;
    z-index: 0;
    box-shadow: none;
    opacity: 1;
  }
}

.section-highlight--picture:before {
  content: '';
  padding-bottom: 60%;
  display: block;
}

.section-highlight--content {
  padding: 55px 30px 25px;
  margin: -30px -20px 0;
  opacity: 0;
}

@media screen and (min-width: 640px) {
  .section-highlight--content {
    padding: 25px 30px;
    margin: 0;
    z-index: 1;
    background: none;
    opacity: 1;
  }
}

.section-highlight--title {
  margin-bottom: 15px;
}

@media screen and (min-width: 640px) {
  .section-highlight--title {
    color: white;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }
}

.section-highlight--description {
  color: #393939;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (min-width: 640px) {
  .section-highlight--description {
    color: white;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 640px) {
  .section-highlight--link {
    color: white;
  }
  .section-highlight--link:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .section-highlight--link::before {
    background: white;
    color: RGB(var(--color-primary));
  }
  .section-highlight--link:hover, .section-highlight--link:focus {
    color: white;
  }
  .section-highlight--link:hover::before, .section-highlight--link:hover::after, .section-highlight--link:focus::before, .section-highlight--link:focus::after {
    color: white !important;
  }
}

.section-highlight .is-dark:after {
  display: none;
}

@media screen and (min-width: 640px) {
  .section-highlight .is-dark:after {
    display: block;
  }
}

.large-image-text-on-image-widget {
  display: flex;
}

.large-image-text-on-image-widget > a {
  flex-grow: 1;
}

.blog-intro {
  display: flex;
  flex-flow: column;
  margin: 0 -20px 45px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 700px) {
  .blog-intro {
    flex-flow: row;
    margin: 0 auto 80px;
    width: 100%;
  }
}

.blog-intro--content {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: #f6f6f6;
  padding: 20px 50px 90px 20px;
}

@media screen and (min-width: 700px) {
  .blog-intro--content {
    flex-basis: 50%;
    padding: 55px 70px 90px 45px;
  }
}

@media screen and (min-width: 960px) {
  .blog-intro--content {
    flex-basis: 40%;
  }
}

.blog-intro--title {
  font-size: 24px;
  color: var(--text-black);
  margin-bottom: 15px;
}

@media screen and (min-width: 700px) {
  .blog-intro--title {
    font-size: 27px;
  }
}

.blog-intro--notes {
  display: flex;
  align-items: baseline;
  color: RGB(var(--color-primary));
}

.blog-intro--date {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.blog-intro--time-read {
  font-size: 10px;
}

.blog-intro .logo-disc {
  width: auto;
  height: auto;
  position: absolute;
  left: 20px;
  bottom: 0;
  transform: translateY(50%);
  z-index: 1;
}

@media screen and (min-width: 700px) {
  .blog-intro .logo-disc {
    top: 45px;
    right: 0;
    left: auto;
    bottom: auto;
    transform: translateX(50%);
  }
}

.blog-intro--picture {
  width: auto;
  height: auto;
  position: relative;
  flex-grow: 1;
}

.blog-intro--picture::before {
  content: '';
  display: block;
  padding-bottom: 60%;
}

.videos-highlight {
  position: relative;
}

.videos-highlight--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.videos-highlight--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.videos-highlight--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.videos-highlight--close::after {
  content: "";
  font-family: "icomoon";
}

.key-figures-number .key-figure--title {
  height: 150px;
  width: 150px;
  margin: 0 auto 20px;
  color: #fff;
  background-color: RGB(var(--color-primary));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.key-figures-number a:focus .key-figure--title {
  background-color: #262626;
}

@media screen and (min-width: 640px) {
  .video-carousel-pop-up .carousel-highlight--title {
    width: auto;
  }
}

.video-carousel-pop-up .carousel-highlight--description {
  display: none;
}

@media screen and (min-width: 960px) {
  .video-carousel-pop-up .carousel-highlight--description {
    display: block;
  }
}

.key-figures-icon {
  text-align: center;
  position: relative;
}

.key-figures-icon.no-slider .key-figure--controls {
  display: none;
}

.key-figures-icon.no-slider .key-figure--element {
  margin: 0 20px 0 0;
}

@media screen and (max-width: 960px) {
  .key-figures-icon.no-slider .key-figure--element {
    margin: 20px 0;
  }
}

.key-figures-icon .tns-nav {
  display: flex;
}

.key-figures-icon .tns-nav button {
  border: none;
  margin: 0 5px;
  width: 7px;
  height: 7px;
  padding: 0;
  background-color: var(--text-grey);
}

.key-figures-icon .tns-nav button.tns-nav-active {
  background-color: RGB(var(--color-primary));
  transform: scale(1.43);
}

@media screen and (max-width: 960px) {
  .key-figures-icon .tns-inner {
    overflow: visible;
  }
}

.key-figures-icon h3 {
  font-size: 30px;
}

.key-figures-icon img {
  height: 80px;
  width: auto;
  margin: 0 auto 20px;
  display: block;
}

.key-figures-icon .key-figure--element {
  margin: 0;
}

@media screen and (max-width: 960px) {
  .key-figures-icon .key-figure--element {
    max-width: 100%;
  }
}

.key-figures-icon .key-figure--element-container {
  height: 100%;
  transition: 0.4s border ease-in-out;
  border: 1px solid #d7d7d7;
  padding: 15px 10px;
  display: block;
}

.key-figures-icon .key-figure--element-container:not(.no-link):hover {
  border: 1px solid RGB(var(--color-primary));
}

.key-figures-icon .key-figure--element-container:not(.no-link):hover .key-figure--description {
  color: RGB(var(--color-primary));
}

.key-figures-icon .key-figure--description {
  font-size: 15px;
  transition: 0.4s all ease-in-out;
}

.tag-results {
  margin: 30px 0 60px;
}

.tag-results--tagged-in {
  font-size: 18px;
  color: #6e6e6e;
}

.tag-results--title {
  color: #393939;
  margin-bottom: 55px;
}

.tag-results--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.tag-results--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.tag-results--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.tag-results--close::after {
  content: "";
  font-family: "icomoon";
}

.social-media-links--title {
  margin-bottom: 20px;
  color: #393939;
}

@media screen and (max-width: 699px) {
  .social-media-links--title {
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.social-media-links--content {
  display: flex;
  align-items: baseline;
  line-height: 16px;
}

.social-media-links--link {
  margin: 0 20px;
  color: RGB(var(--color-primary));
  transition: 0.4s transform ease-in-out;
  position: relative;
}

.social-media-links--link:before {
  font-family: "icomoon";
  font-size: 16px;
  transition: 0.4s color ease-in-out;
}

.social-media-links--link:hover {
  color: var(--color-primary-hover);
}

.social-media-links--link:focus {
  outline: none;
  color: #262626;
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

.social-media-links--link:focus:before {
  transition: 0.2s color ease-in-out;
}

.social-media-links--link:first-child {
  margin-left: 0;
}

[dir="rtl"] .social-media-links--link:first-child {
  margin-left: 20px;
  margin-right: 0;
}

.social-media-links--link:last-child {
  margin-right: 0;
}

[dir="rtl"] .social-media-links--link:last-child {
  margin-right: 20px;
  margin-left: 0;
}

.color--sampoernaRed .social-media-links--link svg path {
  fill: #801027;
}

.social-media-links--facebook {
  bottom: 1px;
}

.social-media-links--facebook:before {
  content: "";
}

.social-media-links--linkedin {
  bottom: 1px;
  margin-bottom: 2px;
}

.social-media-links--linkedin:before {
  content: "";
}

.social-media-links--x {
  top: 4px;
}

.social-media-links--youtube:before {
  content: "";
}

.social-media-links--glassdoor:before {
  content: "";
}

.social-media-links--instagram {
  top: 3px;
}

.social-media-links--instagram:before {
  content: "";
  font-size: 22px;
}

.social-media-links--vk:before {
  content: "";
  font-size: 14px;
}

.related-category {
  background: #f6f6f6;
  padding: 15px 20px;
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 1200px) {
  .related-category {
    padding: 0 0 0 70px;
    background: none;
  }
}

.related-category--category {
  margin-bottom: 20px;
  font-size: 12px;
  color: var(--color-primary-hover);
}

.related-category--title {
  font-size: 16px;
  color: #262626;
  font-weight: 700;
  margin-bottom: 15px;
}

.related-category--article {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}

@media screen and (min-width: 1200px) {
  .related-category--article {
    margin-bottom: 30px;
  }
}

.related-category--reading {
  display: none;
}

@media screen and (min-width: 1200px) {
  .related-category--reading {
    display: block;
  }
}

.related-category--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.related-category--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.related-category--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.related-category--close::after {
  content: "";
  font-family: "icomoon";
}

.search-results-page {
  margin: 40px 0 50px;
}

@media screen and (min-width: 960px) {
  .search-results-page {
    margin-bottom: 90px;
  }
}

.search-results-page--filters, .search-results-page--tags {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.search-results-page--filters {
  margin-bottom: 20px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 700px) {
  .search-results-page--filters {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.search-results-page--tags {
  flex-wrap: wrap;
}

.search-results-page--row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.search-results-page--clear {
  display: none;
  background: none;
  border: none;
  padding: 0;
  color: RGB(var(--color-primary));
  padding-bottom: 20px;
  white-space: nowrap;
  cursor: pointer;
}

.search-results-page--clear.visible {
  display: block;
}

.search-results-page--clear:focus {
  outline: none;
  color: #262626;
  font-weight: bold;
}

.search-results-page .search-field-drop {
  width: 100%;
  margin-bottom: 30px;
}

.search-results-page .search-field-drop--field {
  border-width: 2px;
}

.search-results-page .search-field-drop--field button:focus {
  outline: none;
}

@media screen and (max-width: 1264px) {
  .search-results-page .search-field-drop--field button:focus {
    background-color: RGB(var(--color-primary));
  }
}

@media screen and (min-width: 1265px) {
  .search-results-page .search-field-drop--field button:focus::before {
    color: RGB(var(--color-primary));
  }
}

.search-results-page .search-field-drop--back-suggestion,
.search-results-page .search-field-drop input {
  color: #393939;
  font-size: 24px;
}

@media screen and (min-width: 640px) {
  .search-results-page .search-field-drop--back-suggestion,
  .search-results-page .search-field-drop input {
    font-size: 28px;
  }
}

@media screen and (max-width: 699px) {
  .search-results-page .check-drop-down > button {
    font-size: 0;
    margin-right: 10px;
    border: none;
    padding: 0;
  }
  .search-results-page .check-drop-down > button:after {
    display: none;
  }
  .search-results-page .check-drop-down > button:before {
    content: "";
    font-family: "icomoon";
    color: RGB(var(--color-primary));
    font-size: 29px;
  }
}

.search-results-page--filter-title {
  display: block;
  color: #393939;
  margin-left: 0;
  margin-right: 20px;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 700px) {
  .search-results-page--filter-title {
    order: 1;
  }
}

.search-results-page--filter-button {
  display: none;
}

.search-results-page--tag button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 13px 18px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 11px;
  background: #f6f6f6;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 2px solid transparent;
}

.search-results-page--tag button:after {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  content: "";
  font-family: "icomoon";
  font-size: 7px;
  color: #6e6e6e;
}

.search-results-page--tag button:focus:not(:hover) {
  border-color: RGB(var(--color-primary));
}

.search-results-page--tag button:focus:not(:hover):after {
  color: RGB(var(--color-primary));
}

.social-share {
  z-index: 10;
  width: 200px;
}

.social-share--title {
  font-size: 20px;
  margin-bottom: 20px;
}

.social-share.not-working .social-list-element--addthis {
  display: none;
}

.social-share .social-list {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {
  .social-share .social-list {
    display: block;
  }
}

.social-share .social-list--element:last-child {
  margin-bottom: 0;
}

.social-share .social-list--element a,
.social-share .social-list--element button {
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  font-size: 0;
  color: transparent;
}

@media screen and (min-width: 1200px) {
  .social-share .social-list--element a.twitter-btn:hover svg,
  .social-share .social-list--element button.twitter-btn:hover svg {
    fill: white;
  }
  .social-share .social-list--element a:hover:before,
  .social-share .social-list--element button:hover:before {
    color: #fff;
    background-color: RGB(var(--color-primary));
  }
}

.social-share .social-list--element a:before,
.social-share .social-list--element button:before {
  width: 40px;
  height: 40px;
  display: flex;
  margin-right: 10px;
  border-radius: 100%;
  font-family: "icomoon";
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  color: #fff;
  background-color: RGB(var(--color-primary));
}

@media screen and (min-width: 1200px) {
  .social-share .social-list--element a:before,
  .social-share .social-list--element button:before {
    background-color: #f6f6f6;
    color: #6e6e6e;
    margin-bottom: 20px;
  }
}

.social-share .social-list--element a.twitter,
.social-share .social-list--element button.twitter {
  position: relative;
}

.social-share .social-list--element a.twitter::before,
.social-share .social-list--element button.twitter::before {
  content: '';
}

.social-share .social-list--element a.twitter svg,
.social-share .social-list--element button.twitter svg {
  width: 18px;
  position: absolute;
  fill: white;
  left: 11px;
  right: 0;
}

:root[dir='rtl'] .social-share .social-list--element a.twitter svg,
.rdRtl .social-share .social-list--element a.twitter svg, :root[dir='rtl']
.social-share .social-list--element button.twitter svg,
.rdRtl
.social-share .social-list--element button.twitter svg {
  left: 0;
  right: 21px;
}

@media screen and (min-width: 1200px) {
  .social-share .social-list--element a.twitter svg,
  .social-share .social-list--element button.twitter svg {
    top: 11px;
    fill: #6e6e6e;
  }
}

.social-share .social-list--element a.facebook:before,
.social-share .social-list--element button.facebook:before {
  content: "";
  font-size: 16px;
}

.social-share .social-list--element a.linkedin:before,
.social-share .social-list--element button.linkedin:before {
  content: "";
}

.social-share .social-list--element a.vk:before,
.social-share .social-list--element button.vk:before {
  content: "";
  font-size: 10px;
}

.social-share .social-list--element a.instagram:before,
.social-share .social-list--element button.instagram:before {
  font-size: 22px;
  content: "";
}

.social-share .social-list--element a.link:before,
.social-share .social-list--element button.link:before {
  content: "";
}

.social-share .social-list--element a.mail,
.social-share .social-list--element button.mail {
  display: inline-flex;
}

.social-share .social-list--element a.mail:before,
.social-share .social-list--element button.mail:before {
  content: "";
}

.social-share .social-list--element.copyLink {
  position: relative;
}

.social-share .social-list--element.copyLink input {
  opacity: 0;
  height: 0;
  border: 0;
  padding: 0;
  position: absolute;
}

.social-share .social-list--element.copyLink p {
  position: absolute;
  top: 0;
  left: 60px;
  background: #6e6e6e;
  padding: 10px;
  opacity: 0;
  color: #fff;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  border-radius: 10px;
  width: auto;
  white-space: nowrap;
  pointer-events: none;
}

.social-share .social-list--element.copyLink p:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #6e6e6e;
  transform: translateY(-50%);
}

.social-share .social-list--element.copyLink button:hover + p {
  opacity: 1;
  transform: translateY(0);
}

.social-share-collapse {
  width: 200px;
  position: relative;
}

.social-share-collapse.not-working .social-list-element--addthis {
  display: none;
}

.social-share-collapse .share-button {
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.15em;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.social-share-collapse .share-button:before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "icomoon";
  color: #959595;
  width: 40px;
  height: 40px;
  background-color: #f6f6f6;
  border-radius: 100%;
  margin-right: 10px;
}

.social-share-collapse .social-list {
  background-color: #fff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  padding: 20px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s, visibility 0.6s;
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
  z-index: 99;
}

.social-share-collapse .social-list.show {
  visibility: visible;
  opacity: 1;
}

.social-share-collapse .social-list--element {
  margin-bottom: 20px;
}

.social-share-collapse .social-list--element:last-child {
  margin-bottom: 0;
}

.social-share-collapse .social-list--element a,
.social-share-collapse .social-list--element button {
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: RGB(var(--color-primary));
  background-color: transparent;
  padding: 0;
}

.social-share-collapse .social-list--element a:before,
.social-share-collapse .social-list--element button:before {
  width: 33px;
  height: 33px;
  display: flex;
  background: red;
  color: #fff;
  margin-right: 15px;
  border-radius: 100%;
  font-family: "icomoon";
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.social-share-collapse .social-list--element a.facebook:before,
.social-share-collapse .social-list--element button.facebook:before {
  content: "";
  background-color: #0074c2;
}

.social-share-collapse .social-list--element a.twitter,
.social-share-collapse .social-list--element button.twitter {
  position: relative;
}

.social-share-collapse .social-list--element a.twitter svg,
.social-share-collapse .social-list--element button.twitter svg {
  fill: white;
  position: absolute;
  left: 7px;
  right: 0;
}

:root[dir='rtl'] .social-share-collapse .social-list--element a.twitter svg,
.rdRtl .social-share-collapse .social-list--element a.twitter svg, :root[dir='rtl']
.social-share-collapse .social-list--element button.twitter svg,
.rdRtl
.social-share-collapse .social-list--element button.twitter svg {
  left: 0;
  right: 21px;
}

.social-share-collapse .social-list--element a.twitter:before,
.social-share-collapse .social-list--element button.twitter:before {
  content: '';
  background-color: #1ea1f2;
}

.social-share-collapse .social-list--element a.linkedin:before,
.social-share-collapse .social-list--element button.linkedin:before {
  content: "";
  background-color: #0077b5;
}

.social-share-collapse .social-list--element a.vk:before,
.social-share-collapse .social-list--element button.vk:before {
  content: "";
  background-color: #5182b9;
}

.social-share-collapse .social-list--element a.instagram:before,
.social-share-collapse .social-list--element button.instagram:before {
  content: "";
  background-color: #e13866;
  font-size: 22px;
}

.social-share-collapse .social-list--element a.link:before,
.social-share-collapse .social-list--element button.link:before {
  content: "";
  background-color: #f8694d;
}

.social-share-collapse .social-list--element a.mail:before,
.social-share-collapse .social-list--element button.mail:before {
  content: "";
  background-color: #535353;
  font-size: 12px;
}

.social-share-collapse .social-list--element.copyLink {
  position: relative;
}

.social-share-collapse .social-list--element.copyLink input {
  opacity: 0;
  height: 0;
  border: 0;
  position: absolute;
  top: -10px;
}

.social-share-collapse .social-list--element.copyLink p {
  position: absolute;
  top: 0;
  left: 100px;
  background: #6e6e6e;
  padding: 10px;
  opacity: 0;
  color: #fff;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  border-radius: 10px;
  width: max-content;
}

.social-share-collapse .social-list--element.copyLink p:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #6e6e6e;
  transform: translateY(-50%);
}

.social-share-collapse .social-list--element.copyLink button:hover + p {
  opacity: 1;
  transform: translateY(0);
}

.blue-circles {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.blue-circles--title {
  font-size: 30px;
  line-height: 40px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 45px;
}

.blue-circles .social-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@supports (-webkit-touch-callout: none) {
  .shorthand-embed-body .blue-circles .social-list {
    position: relative;
    z-index: 100;
  }
}

.blue-circles .social-list--element button,
.blue-circles .social-list--element a {
  width: 55px;
  height: 55px;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  color: transparent;
  transition: 0.4s background ease-in-out;
}

.blue-circles .social-list--element button:before,
.blue-circles .social-list--element a:before {
  font-family: "icomoon";
  color: white;
  font-size: 16px;
}

.blue-circles .social-list--element button:hover,
.blue-circles .social-list--element a:hover {
  background: var(--color-primary-hover);
}

.blue-circles .social-list--element button.facebook:before,
.blue-circles .social-list--element a.facebook:before {
  font-size: 20px;
  content: "";
}

.blue-circles .social-list--element button.twitter:before,
.blue-circles .social-list--element a.twitter:before {
  content: '';
}

.blue-circles .social-list--element button.twitter svg,
.blue-circles .social-list--element a.twitter svg {
  fill: white;
}

.blue-circles .social-list--element button.linkedin:before,
.blue-circles .social-list--element a.linkedin:before {
  font-size: 18px;
  content: "";
}

.blue-circles .social-list--element button.vk:before,
.blue-circles .social-list--element a.vk:before {
  font-size: 14px;
  content: "";
}

.blue-circles .social-list--element button.instagram:before,
.blue-circles .social-list--element a.instagram:before {
  font-size: 22px;
  content: "";
}

.blue-circles .social-list--element button.mail:before,
.blue-circles .social-list--element a.mail:before {
  content: "";
}

.blue-circles .social-list.not-working .social-list {
  justify-content: center;
}

.blue-circles .social-list.not-working .social-list-element {
  display: none;
}

.contact-highlight {
  padding: 30px 30px 50px;
  background: #f6f6f6;
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .contact-highlight {
    width: auto;
    min-width: 340px;
  }
}

.contact-highlight--title {
  font-size: 30px;
  font-weight: normal;
  color: #393939;
  margin-bottom: 30px;
}

.contact-highlight--subtitle {
  color: #6e6e6e;
  margin: 10px 0;
  font-size: 16px;
}

.contact-highlight--phone, .contact-highlight--email {
  font-size: 14px;
  color: #262626;
}

.contact-highlight--phone:before, .contact-highlight--email:before {
  color: RGB(var(--color-primary));
  margin-right: 10px;
  font-size: 18px;
  font-family: "icomoon";
}

.contact-highlight--phone::before {
  content: "";
  font-size: 20px;
}

.contact-highlight--email::before {
  content: "";
  font-size: 15px;
}

.contact-highlight--description {
  margin-top: 5px;
  margin-left: 15px;
  font-size: 12px;
  color: #6e6e6e;
}

.initiative {
  position: relative;
  padding: 20px 20px 40px 15%;
  display: flex;
}

.initiative:focus {
  outline: none;
}

.initiative--logo {
  margin-bottom: 20px;
  max-width: 200px;
  width: auto;
  height: auto;
}

.initiative .initiative-infos-container {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  flex-grow: 1;
}

.initiative--infos {
  position: relative;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .initiative--infos {
    max-width: 65%;
  }
}

.initiative--infos.beginning {
  opacity: 0;
}

.initiative--infos .title3 {
  font-size: 28px;
  font-weight: 500;
}

.initiative--infos--copy {
  font-size: 14px;
  margin-bottom: 30px;
}

.initiative--projet {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.initiative .title3 {
  color: #fff;
  margin-bottom: 20px;
}

.initiative .arrowed-link,
.initiative:hover .arrowed-link,
.initiative:focus .arrowed-link {
  color: white;
}

.initiative .arrowed-link::before,
.initiative:hover .arrowed-link::before,
.initiative:focus .arrowed-link::before {
  background: white;
  color: RGB(var(--color-primary));
  --spacing: 10px;
}

.article-carousel {
  --color-black: 38, 38, 38;
  --white: 255, 255, 255;
}

.article-carousel .small-image-above-title--content {
  flex-grow: 1;
}

.article-carousel .small-image-above-title--description {
  padding-bottom: 25px;
  color: var(--text-black);
}

.article-carousel .small-image-above-title--link {
  flex-grow: 1;
}

.article-carousel .small-image-above-title .arrowed-link {
  margin-top: auto;
  color: var(--text-black);
}

.article-carousel .small-image-above-title .arrowed-link:before {
  color: var(--text-white);
  background: var(--text-primary);
}

.timeline-widget {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 959px) {
  .timeline-widget {
    margin: 0 calc(-50vw + 50%);
    width: 100vw;
  }
}

.timeline-widget--title, .timeline-widget--description {
  max-width: calc(100% - 40px);
}

@media screen and (min-width: 960px) {
  .timeline-widget--title, .timeline-widget--description {
    max-width: 690px;
  }
}

.timeline-widget--title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 25px;
  color: black;
  text-align: center;
}

.timeline-widget--description {
  color: #393939;
  text-align: center;
}

.timeline-widget--steps {
  width: 100%;
  border-bottom: 1px solid RGB(var(--color-primary));
  margin-top: 185px;
  display: flex;
  margin-bottom: 46px;
}

.timeline-widget .step {
  margin: 0 100%;
  position: relative;
  transition: 0.6s margin ease-out;
  cursor: pointer;
}

.timeline-widget .step::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 7px;
  height: 7px;
  transform: translate(-50%, -50%);
  background: RGB(var(--color-primary));
  border-radius: 50%;
  transition: 0.4s width ease-in-out, 0.4s height ease-in-out;
}

.timeline-widget .step--content {
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: center;
  left: 0;
  transform: translateX(-50%);
  bottom: -13px;
  padding-bottom: 30px;
}

.timeline-widget .step--title, .timeline-widget .step--subtitle {
  text-align: center;
  width: 70vw;
  color: #959595;
  transition: 0.4s color ease-in-out;
  margin-bottom: 10px;
}

@media screen and (min-width: 960px) {
  .timeline-widget .step--title, .timeline-widget .step--subtitle {
    width: 30vw;
  }
}

@supports (width: max-content) {
  .timeline-widget .step--title, .timeline-widget .step--subtitle {
    width: max-content;
    max-width: 70vw;
  }
  @media screen and (min-width: 960px) {
    .timeline-widget .step--title, .timeline-widget .step--subtitle {
      max-width: 30vw;
    }
  }
}

.timeline-widget .step--title {
  font-size: 34px;
  font-weight: 300;
}

.timeline-widget .step--title.bottom-margin {
  margin-bottom: 37px;
}

.timeline-widget .step--subtitle {
  font-size: 14px;
}

.timeline-widget .step.active {
  margin: 0 100%;
  cursor: normal;
  pointer-events: none;
}

.timeline-widget .step.active::before {
  width: 25px;
  height: 25px;
}

.timeline-widget .step.active .step--title {
  color: RGB(var(--color-primary));
}

.timeline-widget .step.active .step--subtitle {
  color: #262626;
}

.timeline-widget .step:first-child.active {
  margin-left: 50%;
}

.timeline-widget--bottom {
  display: flex;
  width: 100%;
}

.timeline-widget--carousel {
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .timeline-widget--carousel {
    width: 60%;
  }
}

.timeline-widget--content {
  display: flex;
  width: 100%;
  align-items: center;
}

.timeline-widget--step-description {
  transition: 0.6s margin ease-out;
  width: 100%;
  min-width: 100%;
  text-align: center;
  font-weight: 300;
  margin-left: 0;
  margin-right: 100%;
  font-size: 16px;
  line-height: 24px;
}

.timeline-widget--previous, .timeline-widget--next {
  display: none;
}

@media screen and (min-width: 960px) {
  .timeline-widget--previous, .timeline-widget--next {
    display: block;
    width: 20%;
    cursor: pointer;
    border: none;
    color: #393939;
    transition: 0.4s padding ease-in-out;
    position: relative;
    z-index: 1;
  }
  .timeline-widget--previous::before, .timeline-widget--next::before {
    font-family: "icomoon";
    font-size: 13px;
    transition: 0.4s color ease-in-out;
  }
  .timeline-widget--previous:hover, .timeline-widget--previous:focus, .timeline-widget--next:hover, .timeline-widget--next:focus {
    color: RGB(var(--color-primary));
    outline: none;
  }
  .timeline-widget--previous.inactive, .timeline-widget--next.inactive {
    cursor: normal;
    pointer-events: none;
    color: #cccccc;
  }
  .timeline-widget--previous {
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
    padding-right: 40px;
  }
  .timeline-widget--previous:hover {
    padding-right: 60px;
  }
  .timeline-widget--previous::before {
    content: "";
  }
  .timeline-widget--previous.inactive {
    padding-right: 40px;
  }
  .timeline-widget--next {
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
    padding-left: 40px;
  }
  .timeline-widget--next:hover {
    padding-left: 60px;
  }
  .timeline-widget--next::before {
    content: "";
  }
  .timeline-widget--next.inactive {
    padding-left: 40px;
  }
}

.timeline-widget--dots {
  display: flex;
  margin-top: 40px;
  height: 10px;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .timeline-widget--dots {
    display: none;
  }
}

.timeline-widget .carousel-dot {
  background: RGB(var(--color-primary));
}

.job-details {
  margin-bottom: 40px;
  /* stylelint-disable no-descending-specificity  */
  /* stylelint-enable */
}

@media screen and (min-width: 960px) {
  .job-details {
    margin-bottom: 160px;
  }
}

.job-details--grid {
  display: flex;
  margin: 0 auto;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .job-details--grid {
    display: grid;
    grid-template: auto auto / 340px 1fr;
    grid-template-areas: 'details content' 'jobs content';
    grid-row-gap: 60px;
    grid-column-gap: 150px;
  }
}

.job-details--apply, .job-details--share {
  display: flex;
  margin: 10px 0 0;
  min-width: 280px;
  width: 100%;
}

@media screen and (min-width: 480px) {
  .job-details--apply, .job-details--share {
    width: 280px;
  }
}

.job-details--apply {
  background: RGB(var(--color-primary));
  color: #f6f6f6;
}

.job-details--share {
  background: #f6f6f6;
  color: RGB(var(--color-primary));
}

.job-details--share::before {
  content: "";
  font-family: "icomoon";
  margin-right: 5px;
  font-size: 14px;
}

.job-details--content {
  grid-area: content;
  white-space: pre-line;
  line-height: 24px;
}

.job-details .breadcrumbs {
  color: white;
  margin: 0;
}

.job-details .banner {
  position: relative;
  display: flex;
  margin: 0 -20px;
  padding: 30px 20px;
  min-height: 315px;
}

@media screen and (min-width: 960px) {
  .job-details .banner {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1265px) {
  .job-details .banner {
    margin: 0 0 60px 0;
    padding: 30px;
  }
}

.job-details .banner--content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  margin: 0 auto;
}

.job-details .banner--description {
  color: white;
}

.job-details .banner--title {
  word-break: break-word;
  color: white;
  max-width: 460px;
}

.job-details .banner .back-picture {
  width: auto;
  height: auto;
}

.job-details--left-side {
  margin: 0 -20px;
  padding: 30px 20px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 960px) {
  .job-details--left-side {
    padding: 30px;
    margin: 0 50px 0 0;
    width: auto;
  }
}

@media screen and (min-width: 1200px) {
  .job-details--left-side {
    margin-right: 150px;
  }
}

.job-details .details {
  grid-area: details;
  display: flex;
  flex-flow: column;
  background: #f6f6f6;
  padding: 30px 20px;
  margin-left: -20px;
  margin-right: -20px;
}

@media screen and (min-width: 960px) {
  .job-details .details {
    margin: 0;
    padding: 30px;
    width: auto;
  }
}

.job-details .details--content {
  display: flex;
  flex-flow: row wrap;
}

@media screen and (min-width: 960px) {
  .job-details .details--content {
    flex-flow: column;
  }
}

@media screen and (min-width: 480px) {
  .job-details .details .job-details--apply,
  .job-details .details .job-details--share {
    margin-right: 20px;
    display: inline-flex;
  }
}

@media screen and (min-width: 960px) {
  .job-details .details .job-details--apply,
  .job-details .details .job-details--share {
    margin-right: 0;
  }
}

.job-details .details--buttons {
  display: flex;
  flex-flow: row wrap;
}

@media screen and (min-width: 960px) {
  .job-details .details--buttons {
    flex-flow: column;
  }
}

.job-details .details--topic {
  margin-bottom: 30px;
  width: 100%;
}

@media screen and (min-width: 480px) {
  .job-details .details--topic {
    width: 50%;
  }
}

@media screen and (min-width: 640px) {
  .job-details .details--topic {
    width: 33%;
  }
}

@media screen and (min-width: 960px) {
  .job-details .details--topic {
    width: 100%;
  }
}

.job-details .details--title {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: normal;
  width: 100%;
}

.job-details .details--label {
  font-size: 16px;
  color: #6e6e6e;
  font-weight: bold;
  margin-bottom: 5px;
}

.job-details .details--note {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.job-details .details--note::before {
  font-family: "icomoon";
  margin-right: 4px;
  font-size: 18px;
  color: RGB(var(--color-primary));
}

.job-details .details--clockIcon::before {
  content: "";
}

.job-details .details--positionIcon::before {
  content: "";
}

.job-details .details--suitcaseIcon::before {
  content: "";
}

.job-details .details--labelIcon::before {
  content: "";
}

.job-details .relevant-jobs {
  grid-area: jobs;
  display: flex;
  flex-flow: column;
  background: white;
}

.job-details .relevant-jobs--title {
  font-size: 30px;
  font-weight: normal;
  padding-left: 0;
  margin-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .job-details .relevant-jobs--title {
    padding-left: 30px;
  }
}

.job-details .relevant-jobs--heading {
  width: 0 auto;
  background-color: RGB(var(--color-primary));
  padding: 20px 0 20px 30px;
  color: white;
  font-size: 14px;
}

@media screen and (min-width: 960px) {
  .job-details .relevant-jobs--heading {
    width: auto;
  }
}

.job-details .relevant-jobs--entry {
  color: inherit;
  margin-top: 20px;
  padding-left: 30px;
}

.job-details .relevant-jobs--entry::after {
  content: '';
  display: block;
  border-bottom: 1px solid #cccccc;
  margin-top: 20px;
}

.job-details .relevant-jobs--job-title {
  font-size: 16px;
}

.job-details .relevant-jobs--details > li {
  display: inline;
  color: #6e6e6e;
  font-size: 11px;
  padding-right: 25px;
}

.sub-nav {
  position: relative;
  display: flex;
  background: #f6f6f6;
  height: 60px;
  margin: 0 -20px;
  padding: 0 20px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 1265px) {
  .sub-nav {
    margin: 20px 0 0;
    padding: 0;
    width: 100%;
  }
}

.sub-nav--content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.sub-nav--title, .sub-nav--unfold {
  font-size: 20px;
  font-weight: normal;
  display: flex;
  align-self: stretch;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
}

.sub-nav--title {
  display: none;
}

@media screen and (min-width: 1265px) {
  .sub-nav--title {
    display: flex;
  }
}

[dir='rtl'] .sub-nav--unfold:after {
  margin-right: 20px;
  margin-left: 0;
}

.sub-nav--unfold::after {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  color: RGB(var(--color-primary));
  transform: rotate(90deg);
  margin-left: 20px;
  display: block;
  transition: 0.4s transform ease-in-out;
}

.sub-nav--unfold:focus {
  color: RGB(var(--color-primary));
  font-weight: bold;
  outline: none;
}

@media screen and (min-width: 1265px) {
  .sub-nav--unfold {
    display: none;
  }
}

.sub-nav--menus-container {
  display: flex;
  transition: 0.6s height ease-in-out;
}

@media screen and (max-width: 1264px) {
  .sub-nav--menus-container {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
  }
}

.sub-nav--menus {
  display: flex;
  flex-flow: column;
  width: 100%;
  background: #ebebeb;
  padding: 30px 20px;
}

@media screen and (min-width: 1265px) {
  .sub-nav--menus {
    flex-flow: row;
    width: auto;
    align-self: stretch;
    background: none;
    padding: 0;
  }
}

@media screen and (max-width: 1265px) {
  .sub-nav--menus {
    height: 100%;
    overflow-y: auto;
    display: block;
    padding-bottom: 100px;
  }
}

.job-list--title {
  font-size: 30px;
  line-height: 48px;
  font-weight: normal;
  margin-bottom: 30px;
}

.job-list--content {
  margin: 0 auto;
}

.job-list--form {
  position: relative;
  padding: 40px 0 75px;
  background: #f6f6f6;
  margin: 0 -20px;
}

@media screen and (min-width: 1200px) {
  .job-list--form {
    margin: 0;
  }
}

.job-list--form .job-list--content {
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .job-list--form .job-list--content {
    padding: 0;
  }
}

.job-list--list {
  padding: 70px 0 110px;
}

.job-list--search-row, .job-list--tags-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.job-list--search-row {
  margin-bottom: 25px;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .job-list--search-row {
    flex-wrap: nowrap;
  }
}

.job-list--tags-row {
  align-items: flex-end;
}

.job-list--tags {
  display: flex;
  flex-wrap: wrap;
}

.job-list--clear {
  color: RGB(var(--color-primary));
  border: none;
  background: none;
  padding: 0;
  align-self: center;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
}

.job-list .search-field-drop {
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) {
  .job-list .search-field-drop {
    width: calc(50% - 12px);
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .job-list .search-field-drop {
    flex-grow: 1;
    width: auto;
  }
}

.job-list .search-field-drop--field {
  border: none;
  padding: 0;
  width: 100%;
  background: white;
}

.job-list .search-field-drop--field button {
  display: none;
}

.job-list .search-field-drop--drop {
  left: 1px;
  width: calc(100% - 2px);
  z-index: 10;
}

.job-list .search-field-drop--back-suggestion,
.job-list .search-field-drop input {
  font-size: 14px;
  height: 100%;
  padding: 14px 20px;
  border: 1px solid #e5e5e5;
  width: 100%;
  transition: 0.2s border-color ease-in-out;
}

@media screen and (max-width: 700px) {
  .job-list .search-field-drop--back-suggestion,
  .job-list .search-field-drop input {
    font-size: 16px;
  }
}

.job-list .search-field-drop--back-suggestion {
  top: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.job-list .search-field-drop.open input {
  border-bottom: 1px solid transparent;
}

.job-list .check-drop-down {
  margin-bottom: 12px;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .job-list .check-drop-down {
    width: calc(50% - 12px);
  }
}

@media screen and (min-width: 1200px) {
  .job-list .check-drop-down {
    margin-left: 25px;
    width: auto;
  }
}

.job-list .check-drop-down > button {
  width: 100%;
}

.job-list .check-drop-down input {
  color: #262626;
}

.job-list .check-drop-down input::placeholder {
  font-size: 14px;
  opacity: 1;
  color: #262626;
}

.job-list .check-drop-down input:focus::placeholder {
  opacity: 0.3;
}

.glossary {
  position: relative;
}

.glossary .wrapper {
  margin: 0 auto;
}

.glossary .index {
  padding-top: 40px;
  background-color: RGB(var(--color-primary));
  color: white;
  position: absolute;
  width: 100vw;
  left: -20px;
  z-index: 2000;
}

.glossary .index .wrapper {
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .glossary .index {
    left: 0;
  }
  .glossary .index .wrapper {
    padding: 0;
  }
}

.glossary .index--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 10px;
}

.glossary .index .slider, .glossary .index--letters {
  display: flex;
}

.glossary .index .slider-container {
  background-color: RGB(var(--color-primary));
  padding: 30px 0;
}

.glossary .index .slider--button {
  display: flex;
  justify-content: center;
  color: white;
  background: none;
  border: none;
  align-items: center;
  margin: 0;
}

.glossary .index .slider--button:before {
  display: block;
  font-size: 20px;
  font-family: "icomoon";
}

@media screen and (min-width: 1265px) {
  .glossary .index .slider--button:before {
    font-size: 10px;
  }
}

.glossary .index .slider--prev {
  padding-left: 0;
}

.glossary .index .slider--prev:before {
  content: "";
  padding-right: 10px;
}

@media screen and (min-width: 1265px) {
  .glossary .index .slider--prev:before {
    content: "";
  }
}

.glossary .index .slider--next {
  padding-right: 0;
}

.glossary .index .slider--next:before {
  content: "";
  padding-left: 10px;
}

@media screen and (min-width: 1265px) {
  .glossary .index .slider--next:before {
    content: "";
  }
}

.glossary .index--letters--container {
  overflow: hidden;
  padding-left: calc(50% - 60px);
  position: relative;
}

.glossary .index--letters--container:before, .glossary .index--letters--container:after {
  content: '';
  display: block;
  width: 70px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 1265px) {
  .glossary .index--letters--container:before, .glossary .index--letters--container:after {
    width: 190px;
  }
}

.glossary .index--letters--container:before {
  left: 0;
  background: black;
  background: linear-gradient(to left, rgba(0, 116, 192, 0), #0074c0);
}

.glossary .index--letters--container:after {
  right: 0;
  background: linear-gradient(to right, rgba(0, 116, 192, 0), #0074c0);
}

.glossary .index--letters {
  transition: transform 0.3s ease-in;
}

.glossary .index--letters li {
  display: flex;
  align-items: center;
}

.glossary .index--letters button {
  background: none;
  border: 0;
  font-size: 24px;
  color: white;
  font-weight: 300;
  border-radius: 100%;
  width: 47px;
  height: 47px;
  padding: 0;
  transition: font-size 0.3s ease-in, color 0.3s ease-in;
}

.glossary .index--letters button.current {
  font-size: 38px;
  width: 60px;
  height: 60px;
  color: RGB(var(--color-primary));
  background-color: white;
  font-weight: normal;
}

.glossary .results {
  padding-top: 300px;
}

.glossary .results .result {
  padding-bottom: 30px;
}

@media screen and (min-width: 1265px) {
  .glossary .results .result {
    display: flex;
    align-items: flex-start;
  }
}

.glossary .results .result--key {
  font-size: 38px;
  color: RGB(var(--color-primary));
  flex-basis: 215px;
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
}

@media screen and (min-width: 1265px) {
  .glossary .results .result--key {
    min-width: 220px;
    margin: 15px 20px 30px 0;
  }
}

.glossary .results .result--key:after {
  content: '';
  display: block;
  width: 60px;
  background-color: RGB(var(--color-primary));
  height: 1px;
  margin-left: 10px;
}

@media screen and (min-width: 1265px) {
  .glossary .results .result--key:after {
    width: 180px;
  }
}

.glossary .results .result--articles {
  flex-grow: 1;
}

.glossary .results .result--articles li {
  background-color: #f6f6f6;
  padding: 25px 50px 25px 25px;
  margin-bottom: 30px;
}

.glossary .results .result--articles li:last-child {
  margin-bottom: 0;
}

.glossary .results .result--articles h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.glossary .results .result--articles .wysiwyg,
.glossary .results .result--articles .wysiwyg p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  display: inline-block;
}

.glossary .results .result--articles .wysiwyg em,
.glossary .results .result--articles .wysiwyg a,
.glossary .results .result--articles .wysiwyg p em,
.glossary .results .result--articles .wysiwyg p a {
  display: inline-block;
}

.glossary .results .result--articles a {
  display: flex;
  align-items: center;
}

.glossary .results .result--articles .read-more {
  display: flex;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}

.glossary .results .result--articles .read-more:after {
  content: "";
  font-size: 7px;
  font-family: "icomoon";
  margin-left: 10px;
}

.error-404 {
  padding: 40px 20px 50px;
  background: linear-gradient(110deg, RGB(var(--color-primary)) 40%, #1d9bf0);
  min-height: calc(100vh - 400px);
  display: flex;
  margin: 0 -20px;
}

@media screen and (min-width: 1200px) {
  .error-404 {
    margin: 0;
    padding: 45px 0 100px;
  }
}

.error-404--main {
  margin: auto;
}

@media screen and (min-width: 960px) {
  .error-404--main {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.error-404--content {
  display: flex;
  flex-flow: column;
  color: white;
  margin-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .error-404--content {
    max-width: 450px;
    margin-bottom: 0;
  }
}

.error-404--title {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 15px;
}

.error-404--title strong {
  font-size: 64px;
}

.error-404--subtitle {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 35px;
  max-width: 305px;
}

.error-404--homepage {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.error-404--homepage:after {
  content: "";
  font-family: "icomoon";
  margin-left: 20px;
  font-size: 9px;
  transition: 0.4s margin ease-in-out;
}

.error-404--homepage:hover:after {
  margin-left: 25px;
}

.error-404--search-block {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 640px) {
  .error-404--search-block {
    flex-flow: row;
  }
}

@media screen and (min-width: 960px) {
  .error-404--search-block {
    flex-flow: column;
  }
}

.error-404--submit {
  background: none;
  border: none;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  padding: 0;
}

.error-404--submit::before {
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  font-size: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  margin-right: 15px;
  border: 1px solid white;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out;
}

.error-404--submit:hover::before {
  background: RGB(var(--color-primary));
  color: white;
}

@media screen and (min-width: 960px) {
  .error-404--right {
    width: 450px;
  }
}

.error-404--suggested {
  color: white;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.error-404--highlights {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .error-404--highlights {
    flex-flow: row;
    justify-content: space-between;
  }
}

.error-404 .highlight {
  margin-bottom: 20px;
}

@media screen and (min-width: 960px) {
  .error-404 .highlight {
    max-width: 213px;
    height: 310px;
    margin-bottom: 0;
  }
}

.error-404 .highlight .box-article-content--title {
  font-size: 16px;
  line-height: 20px;
}

.error-404 .search-field-drop {
  flex-grow: 1;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .error-404 .search-field-drop {
    margin-right: 80px;
  }
}

@media screen and (min-width: 960px) {
  .error-404 .search-field-drop {
    margin: 0;
  }
}

.error-404 .search-field-drop--field {
  border-color: white;
  margin-bottom: 35px;
}

@media screen and (min-width: 640px) {
  .error-404 .search-field-drop--field {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 960px) {
  .error-404 .search-field-drop--field {
    margin-bottom: 35px;
  }
}

.error-404 .search-field-drop--field input::placeholder {
  color: white;
}

.error-404 .search-field-drop--field input, .error-404 .search-field-drop--back-suggestion {
  color: white;
  font-size: 14px;
}

.error-404 .search-field-drop--back-suggestion {
  padding-top: 2px;
}

@media screen and (min-width: 960px) {
  .error-404 .search-field-drop--drop {
    top: calc(100% - 35px);
  }
}

.anchor {
  position: relative;
  visibility: hidden;
  top: -60px;
}

@media screen and (min-width: 1265px) {
  .anchor {
    top: -100px;
  }
}

.has-sub + * + .page-main .anchor {
  top: -120px;
}

@media screen and (min-width: 1265px) {
  .has-sub + * + .page-main .anchor {
    top: -180px;
  }
}

.show-more p {
  line-height: 30px;
  font-size: 18px;
}

.show-more--intro {
  position: relative;
}

.show-more--intro:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  opacity: 0;
  transition: 0.4s opacity ease-in-out;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}

.show-more--intro.gradient:after {
  opacity: 1;
}

.show-more--container {
  height: 0;
  overflow: hidden;
  transition: 0.4s height ease-in-out;
}

.show-more--button {
  margin-top: 10px;
  display: flex;
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  letter-spacing: 0.15em;
  opacity: 1;
  transition: 0.6s opacity ease-in-out;
}

.show-more--button.hide {
  opacity: 0;
  cursor: default;
  transition: 0.4s opacity ease-in-out;
}

.show-more--button:after {
  font-family: "icomoon";
  content: "";
  color: RGB(var(--color-primary));
  font-size: 5px;
  margin-left: 14px;
}

.show-more--button:focus {
  outline: none;
  font-weight: bold;
  color: #262626;
}

.show-more--button.open:after {
  transform: rotate(-180deg);
  transition: 0.4s;
}

.show-more--less-button {
  display: flex;
  margin-top: 10px;
  border: none;
  padding: 0;
  font-size: 12px;
  letter-spacing: 0.15em;
  background: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s opacity ease-in-out;
}

.show-more--less-button.show {
  visibility: visible;
  opacity: 1;
}

.show-more--less-button.hide {
  opacity: 0;
}

.show-more--less-button:after {
  font-family: "icomoon";
  content: "";
  transform: rotate(-180deg);
  color: RGB(var(--color-primary));
  font-size: 5px;
  margin-left: 14px;
}

.tbe-main {
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .tbe-main {
    flex-flow: row;
  }
}

.tbe-main--filters {
  width: 100%;
  margin-right: 50px;
}

@media screen and (min-width: 960px) {
  .tbe-main--filters {
    width: 275px;
    min-width: 275px;
  }
}

.tbe-drop {
  position: relative;
}

.tbe-drop--field {
  position: relative;
  padding: 0 0 10px;
  border: none;
  border-bottom: 1px solid RGB(var(--color-primary));
  width: 100%;
  background: none;
  text-align: left;
  min-height: 33px;
}

.tbe-drop--field.has-arrow:after {
  content: "";
  font-family: "icomoon";
  font-size: 6px;
  color: RGB(var(--color-primary));
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(180deg);
  transition: 0.2s transform ease-in-out;
}

.tbe-drop--field.has-arrow.open:after {
  transform: translateY(-50%);
}

.tbe-drop--title {
  color: #262626;
  font-size: 18px;
  padding-right: 25px;
}

.smaller .tbe-drop--title {
  font-size: 14px;
}

.tbe-drop--input, .tbe-drop--back-suggestion {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 16px;
}

.tbe-drop--input {
  z-index: 1;
  border: none;
  background: none;
}

.tbe-drop--input::placeholder {
  font-size: 16px;
  color: #959595;
}

.tbe-drop--back-suggestion {
  display: flex;
  align-items: center;
  opacity: 0.3;
}

.tbe-drop--back-suggestion span {
  opacity: 0;
}

.tbe-drop--container {
  position: absolute;
  z-index: 2;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  overflow: hidden;
  transition: 0.2s height ease-in-out, 0.2s background ease-in-out, 0.2s box-shadow ease-in-out;
  height: 0;
}

.tbe-drop--container.open {
  background: white;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.tbe-drop--choice {
  border: none;
  background: none;
  font-size: 16px;
  letter-spacing: 0.025em;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.tbe-drop--choice:hover, .tbe-drop--choice:focus {
  color: RGB(var(--color-primary));
  background: #f6f6f6;
  outline: none;
}

.tbe-selectables {
  overflow-y: scroll;
  width: 100%;
  max-height: 250px;
}

.tbe-selectables--element {
  text-align: left;
  background: #f6f6f6;
  width: 100%;
  padding: 10px 40px 10px 20px;
  margin-bottom: 1px;
  border: none;
}

.tbe-selectables--element.checked {
  position: relative;
  background: RGB(var(--color-primary));
  color: white;
}

.tbe-selectables--element.checked:after {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.tbe-app {
  display: flex;
  flex-flow: column;
  min-height: 300px;
}

.tbe-loading {
  position: relative;
  width: 100%;
}

.tbe-loading--overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: 0.4s opacity ease-in-out;
}

.tbe-loading--overlay svg {
  max-width: 90px;
  animation-name: video-rotate;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-bottom: 10px;
}

.tbe-loading--overlay circle {
  fill: none;
  stroke-width: 10px;
  transition: 0.4s stroke-dasharray ease-in-out;
}

.tbe-loading--overlay circle:nth-child(1) {
  stroke: #cccccc;
}

.tbe-loading--overlay circle:nth-child(2) {
  stroke: RGB(var(--color-primary));
  stroke-dasharray: 235px;
  stroke-dashoffset: 0;
}

.stateFinishing .tbe-loading--overlay {
  pointer-events: none;
}

.stateFinishing .tbe-loading--overlay circle:nth-child(2) {
  stroke-dasharray: 315px;
}

.stateBeforeLoading .tbe-loading--overlay circle:nth-child(2) {
  stroke-dasharray: 105px;
}

.stateBeforeLoading .tbe-loading--overlay,
.stateFinishing .tbe-loading--overlay {
  opacity: 0;
}

.tbe-filters--section-title {
  margin-bottom: 15px;
}

.tbe-filters--section {
  margin-bottom: 45px;
  opacity: 0.3;
  pointer-events: none;
  transition: 0.2s opacity ease-in-out;
}

.tbe-filters--section.active {
  opacity: 1;
  pointer-events: all;
}

.tbe-filters--section .tbe-drop--field,
.tbe-filters--section .tbe-variables {
  margin-bottom: 20px;
}

.tbe-filters--button {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 10px 25px;
  background: white;
  border: 1px solid #cccccc;
}

.tbe-tags {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.tbe-tags--tag {
  background: RGB(var(--color-primary));
  border: none;
  color: white;
  display: flex;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 5px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tbe-tags--tag::after {
  content: "";
  font-family: "icomoon";
  font-size: 6px;
  margin-left: 5px;
}

.tbe-phases {
  position: relative;
}

.tbe-phases--header, .tbe-phases--dropped {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  border: none;
}

.tbe-phases--header {
  background: RGB(var(--color-primary));
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 25px;
  padding-right: 10px;
}

.tbe-phases--header:before {
  content: "";
  font-family: "icomoon";
  color: white;
  font-size: 6px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: 0.2s transform ease-in-out;
}

.tbe-phases--header.open:before {
  transform: translateY(-50%) rotate(180deg);
}

.tbe-phases--header-wrapper {
  width: 85%;
  display: flex;
  text-align: left;
  align-items: center;
}

.tbe-phases--header-text, .tbe-phases--header-icon {
  display: inline-block;
}

.tbe-phases--header-text {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbe-phases--header-icon:after {
  font-family: "icomoon";
  margin-left: 10px;
  font-weight: normal;
  color: white;
}

.tbe-phases--container {
  top: 100%;
}

.tbe-phases--row {
  border-bottom: 1px solid #cccccc;
}

.tbe-phases--row:last-child {
  border: none;
}

.tbe-phases--dropped {
  padding-left: 60px;
  padding-right: 25px;
  background: white;
  font-size: 12px;
  line-height: 20px;
  color: black;
  text-align: left;
}

.tbe-phases--dropped:after {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "icomoon";
  margin-left: 10px;
  color: RGB(var(--color-primary));
}

.tbe-phases--dropped:hover, .tbe-phases--dropped:focus {
  background: #f6f6f6;
  outline: none;
}

.tbe-phases .ico-all:after {
  content: "";
  font-size: 20px;
}

.tbe-phases .ico-growing:after {
  content: "";
  font-size: 20px;
}

.tbe-phases .ico-processing:after {
  content: "";
  font-size: 29px;
}

.tbe-phases .ico-product:after {
  content: "";
  font-size: 27px;
  margin-right: 9px;
}

.tbe-phases .ico-suppliers:after {
  content: "";
  font-size: 27px;
  margin-right: 3px;
}

.tbe-phases .ico-distributive:after {
  content: "";
  font-size: 18px;
}

.tbe-phases .ico-taxes:after {
  content: "";
  font-size: 18px;
}

.tbe-phases .ico-illicit:after {
  content: "";
  font-size: 26px;
}

.tbe-phases .ico-consumers:after {
  content: "";
  font-size: 29px;
  padding-bottom: 2px;
}

.tbe-variables {
  overflow-y: scroll;
  width: 100%;
  max-height: 250px;
}

.tbe-variables [type='checkbox'] + label {
  padding: 10px;
  align-items: flex-start;
}

@media screen and (min-width: 700px) {
  .tbe-variables [type='checkbox'] + label {
    font-size: 12px;
  }
}

.tbe-data-view {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .tbe-data-view {
    width: calc(100% - 335px);
  }
}

.tbe-data-view--title {
  font-size: 11px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: normal;
}

.tbe-data-view--row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .tbe-data-view--row {
    margin: 0;
    width: auto;
  }
}

.tbe-data-view--top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
  flex-flow: column;
}

.tbe-data-view--top.deactivated {
  opacity: 0.3;
  pointer-events: none;
}

@media screen and (min-width: 1200px) {
  .tbe-data-view--top {
    flex-flow: row;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.tbe-data-view--download {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 2px;
  background-color: #f6f6f6;
  height: 60px;
}

@media screen and (max-width: 960px) {
  .tbe-data-view--download {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.tbe-data-view--note {
  width: calc(50% - 15px);
  margin-top: 30px;
}

@media screen and (min-width: 1200px) {
  .tbe-data-view--note {
    margin-right: 45px;
    width: auto;
  }
}

.tbe-data-view--note span {
  color: RGB(var(--color-primary));
}

.tbe-data-view--notes {
  display: flex;
}

.tbe-data-filters {
  display: flex;
  justify-content: space-between;
}

.tbe-data-filters--filter {
  width: calc(50% - 15px);
}

@media screen and (min-width: 1200px) {
  .tbe-data-filters--filter {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 200px;
    margin-right: 40px;
  }
  .tbe-data-filters--filter:nth-child(2) {
    width: 100px;
    margin-right: 0;
  }
  .tbe-data-filters--filter .tbe-drop {
    width: 100%;
  }
}

.tbe-data-filters--filter .tbe-data-view--title {
  margin-bottom: 11px;
}

.tbe-data-view-table {
  overflow: auto;
  display: flex;
  flex-flow: column;
  margin: 0 -20px;
  max-height: 70vh;
}

@media screen and (min-width: 960px) {
  .tbe-data-view-table {
    margin: 0;
    max-height: 600px;
  }
}

.tbe-data-view-table.deactivated {
  overflow: hidden;
  pointer-events: none;
  align-items: flex-start;
}

.tbe-data-view-table--message {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tbe-data-view-table--message.grey-back {
  background: #f6f6f6;
}

.tbe-data-view-table--message p {
  width: 52.02864%;
  text-align: center;
  color: #6e6e6e;
  font-size: 18px;
  line-height: 22px;
}

.tbe-data-view-table--message p strong {
  color: #262626;
  font-weight: normal;
}

.tbe-data-view-table--column {
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.15em;
  color: #393939;
  padding: 20px;
  white-space: nowrap;
  background: none;
  border: none;
  height: 60px;
}

.deactivated .tbe-data-view-table--column {
  color: #959595;
}

.tbe-data-view-table--column.ascending:after, .tbe-data-view-table--column.descending:after {
  content: "";
  font-family: "icomoon";
  font-size: 6px;
  margin-left: 10px;
  transition: 0.2s transform ease-in-out;
}

.tbe-data-view-table--column.descending:after {
  transform: rotate(180deg);
}

.tbe-data-view-table th {
  position: sticky;
  top: 0;
  background: #f6f6f6;
  height: 70px;
}

.tbe-data-view-table td {
  font-size: 11px;
  color: #393939;
  padding: 20px;
}

.tbe-data-view-table .right-aligned {
  text-align: right;
}

.tbe-data-view-table tbody tr {
  border-bottom: 1px solid #cccccc;
  transition: 0.2s background ease-in-out;
}

.tbe-data-view-table tbody tr:hover {
  background: rgba(246, 246, 246, 0.4);
  transition: none;
}

.tbe-data-view-table tbody tr:last-child {
  border: none;
}

.tobacco-economics--download {
  padding-left: 30px;
  padding-right: 30px;
  height: 60px;
  background: #cccccc;
  color: white;
  border: none;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 15px;
  transition: 0.4s background ease-in-out;
  font-weight: bold;
  cursor: default;
}

.tobacco-economics--download.active {
  background: RGB(var(--color-primary));
  cursor: pointer;
}

.tobacco-economics--download.active:hover {
  background: var(--color-primary-hover);
}

.tobacco-economics--download-all {
  margin-left: auto;
  color: RGB(var(--color-primary));
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 15px;
  text-align: center;
}

.tobacco-economics--download-all::after {
  content: "";
  background-color: RGB(var(--color-primary));
  color: #f6f6f6;
  font-family: "icomoon";
  font-size: 12px;
  letter-spacing: normal;
  font-weight: normal;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

@media screen and (max-width: 480px) {
  .tobacco-economics--download-all::after {
    margin: 5px 5px 0 0;
  }
}

.tobacco-economics--loading {
  animation-name: loading-effect;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.tobacco-economics--loading::after {
  content: '\00a0\00a0\00a0';
  animation-name: loading-dots;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-left: 5px;
}

@keyframes loading-effect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading-dots {
  33% {
    content: '.\00a0\00a0';
  }
  66% {
    content: '\00a0.\00a0';
  }
  100% {
    content: '\00a0\00a0.';
  }
}

.ingredient-finder {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 80px;
}

.ingredient-finder--title {
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 25px;
  text-align: center;
}

.ingredient-finder--subtitle {
  font-size: 18px;
  line-height: 30px;
  color: #393939;
  padding-bottom: 65px;
  text-align: center;
}

.ingredient-finder--disclaimer {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #6e6e6e;
  text-align: center;
}

.ingredient-finder--disclaimer p {
  margin-bottom: 18px;
}

.ingredient-finder--menu {
  position: relative;
  margin-bottom: 65px;
  opacity: 0.3;
  transition: 0.2s opacity ease-in-out;
  pointer-events: none;
  min-width: 100%;
}

@media screen and (min-width: 480px) {
  .ingredient-finder--menu {
    min-width: 340px;
  }
}

.ingredient-finder--menu.active {
  opacity: 1;
  pointer-events: all;
}

.ingredient-finder--drop-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: RGB(var(--color-primary));
  height: 75px;
  padding-left: 40px;
  padding-right: 35px;
  border: none;
  color: white;
  font-size: 18px;
  width: 100%;
}

.ingredient-finder--drop-button:after {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  padding-top: 3px;
  margin-left: 20px;
  transition: 0.4s transform ease-in-out;
}

.open .ingredient-finder--drop-button:after {
  transform: rotate(180deg);
}

.ingredient-finder--list {
  margin: 30px 0;
}

.ingredient-finder--choice {
  font-size: 18px;
  background: none;
  border: none;
  padding: 10px 40px;
  width: 100%;
  text-align: left;
}

.ingredient-finder--choice:hover {
  background: RGB(var(--color-primary));
  color: white;
}

.ingredient-finder--dropdown {
  z-index: 100;
  position: absolute;
  top: 75px;
  height: 0;
  width: 100%;
  overflow: auto;
  background: #f6f6f6;
  transition: 0.4s height ease-in-out;
}

.ingredient-finder--ingredients {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: column;
}

@media screen and (min-width: 640px) {
  .ingredient-finder--ingredients {
    flex-flow: row;
  }
}

.ingredient-finder--container {
  height: 0;
  overflow: hidden;
  width: 100%;
  transition: 0.4s height ease-in-out;
}

.ingredient-finder--section {
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media screen and (min-width: 640px) {
  .ingredient-finder--section {
    width: calc(50% - 12px);
  }
}

.ingredient-finder--section-title {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 20px;
}

.ingredient-finder--documents {
  background: #f6f6f6;
  padding: 25px 20px;
  flex-grow: 1;
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .ingredient-finder--documents {
    margin-bottom: 80px;
  }
}

.ingredient-finder--documents li {
  margin-bottom: 20px;
}

.ingredient-finder--documents li:last-child {
  margin-bottom: 0;
}

.ingredient-finder--document {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #393939;
}

.ingredient-finder--document:before {
  font-family: "icomoon";
  font-size: 28px;
  color: RGB(var(--color-primary));
  margin-right: 15px;
}

.ingredient-finder--document.pdf:before {
  content: "";
}

.ingredient-finder--document.xls:before {
  content: "";
}

.ingredient-finder--document.doc:before {
  content: "";
}

.ingredient-finder--document-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ingredient-finder--scheme {
  position: relative;
  margin-bottom: 80px;
  display: none;
}

@media screen and (min-width: 960px) {
  .ingredient-finder--scheme {
    display: block;
  }
}

.ingredient-finder--scheme p {
  font-size: 14px;
  color: RGB(var(--color-primary));
}

.ingredient-finder--combustible img {
  width: 604.5px;
  height: auto;
}

.ingredient-finder--combustible .label--monogram-inks {
  position: absolute;
  top: -11px;
  left: 285px;
  transform: translateX(-100%);
}

.ingredient-finder--combustible .label--slide-seam-adhesives {
  position: absolute;
  top: -4px;
  left: 612px;
  min-width: 120px;
}

.ingredient-finder--combustible .label--cigarette-papers {
  position: absolute;
  top: 147px;
  left: 572px;
  min-width: 200px;
}

.ingredient-finder--combustible .label--ingredients-added {
  position: absolute;
  top: 190px;
  left: 455px;
}

.ingredient-finder--combustible .label--tipping-papers {
  position: absolute;
  top: 208px;
  left: 206px;
}

.ingredient-finder--combustible .label--filter {
  position: absolute;
  top: 143px;
  left: -35px;
}

.ingredient-finder--combustible .label--filter-adhesives {
  position: absolute;
  top: 97px;
  left: -9px;
  max-width: 120px;
  text-align: right;
  transform: translateX(-100%);
}

.ingredient-finder--combustible .label--filter-wrap {
  position: absolute;
  top: 21px;
  left: 122px;
  transform: translateX(-100%);
}

.ingredient-finder--rrp img {
  width: 795px;
  max-width: 795px;
  height: auto;
}

.ingredient-finder--rrp .label--heatstick-paper {
  position: absolute;
  top: 38px;
  left: 88px;
  transform: translateX(-100%);
}

.ingredient-finder--rrp .label--hollow-tube {
  position: absolute;
  top: 25px;
  left: 300px;
  transform: translateX(-100%);
}

.ingredient-finder--rrp .label--pla-crimped-filter {
  position: absolute;
  top: -11px;
  left: 489px;
  transform: translateX(-100%);
}

.ingredient-finder--rrp .label--pla-crimped-filter-bis {
  position: absolute;
  top: 285px;
  left: 595px;
}

.ingredient-finder--rrp .label--tipping-paper {
  position: absolute;
  top: -21px;
  left: 538px;
}

.ingredient-finder--rrp .label--filter {
  position: absolute;
  top: 88px;
  left: 766px;
}

.ingredient-finder--rrp .label--filter-plug-wrapper {
  position: absolute;
  top: 172px;
  left: 798px;
  min-width: 120px;
}

.ingredient-finder--rrp .label--hat-plug-wrapper {
  position: absolute;
  top: 321px;
  left: 317px;
}

.ingredient-finder--rrp .label--tobacco-plug-wrapper {
  position: absolute;
  top: 368px;
  left: 177px;
}

.ingredient-finder--rrp .label--ingredients-added {
  position: absolute;
  top: 185px;
  left: 11px;
  max-width: 80px;
  transform: translateX(-100%);
  text-align: right;
}

@media screen and (min-width: 1050px) {
  .ingredient-finder--rrp .label--ingredients-added {
    top: 167px;
    left: -6px;
    max-width: 120px;
  }
}

.social-highlight {
  position: relative;
  background: black;
  margin: 0 -20px 100px;
}

@media screen and (min-width: 1200px) {
  .social-highlight {
    margin-left: 0;
    margin-right: 0;
  }
}

.social-highlight--inner {
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .social-highlight--inner {
    width: calc(100% - 40px);
  }
}

@media screen and (min-width: 1200px) {
  .social-highlight--inner {
    width: auto;
  }
}

.social-highlight--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social-highlight--background img {
  width: auto;
  height: auto;
}

.social-highlight .social-highlight-title {
  position: relative;
  padding: 70px 0 20px 20px;
  font-size: 30px;
  color: white;
}

@media screen and (min-width: 960px) {
  .social-highlight .social-highlight-title {
    padding: 100px 0 40px 0;
  }
}

.social-card-container {
  position: relative;
  margin: 0 20px;
  padding-bottom: 90px;
}

@media screen and (min-width: 640px) {
  .social-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (min-width: 960px) {
  .social-card-container {
    flex-wrap: nowrap;
    margin: 0;
    padding-bottom: 150px;
  }
}

.social-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background: white;
}

@media screen and (min-width: 640px) {
  .social-card {
    flex-basis: 48%;
    height: 330px;
  }
}

@media screen and (min-width: 960px) {
  .social-card {
    position: relative;
    margin-bottom: 0;
    flex-basis: 22%;
    height: 380px;
  }
  .social-card:hover {
    cursor: default;
  }
  .social-card:hover .social-card--next,
  .social-card:hover .social-card--nav {
    pointer-events: auto;
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  .social-card {
    height: 360px;
  }
}

.social-card--next {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  min-width: 52px;
  height: 52px;
  transform: translate(50%, -50%);
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background: RGB(var(--color-primary));
}

@media screen and (min-width: 960px) {
  .social-card--next {
    display: flex;
    opacity: 0;
    transition: 0.4s all ease-in-out;
    pointer-events: none;
  }
}

.social-card--next--text {
  text-transform: uppercase;
  font-size: 11px;
  color: white;
  letter-spacing: 0.15em;
}

.social-card--header--link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px 20px 0;
}

.social-card--header .pmi-logo {
  width: 38px;
}

.social-card--header .social-media-title {
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 2px;
  flex-basis: 29.09091%;
  color: #262626;
}

.social-card--header .post-time {
  font-size: 12px;
  padding-bottom: 2px;
  line-height: 16px;
  color: #6e6e6e;
  flex-basis: 36.36364%;
}

.social-card--header .social-icon {
  font-family: "icomoon";
  font-size: 13px;
  align-self: center;
}

.social-card--header .social-icon--twitter:before {
  content: "";
}

.social-card--header .social-icon--facebook:before {
  font-size: 15px;
  content: "";
}

.social-card--header .social-icon--linkedin:before {
  content: "";
}

.social-card--header .social-icon--instagram:before {
  font-size: 20px;
  content: "";
}

.social-card--body {
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
}

.social-card--body img {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .social-card--body img {
    max-height: 130px;
    object-fit: cover;
  }
}

.social-card--body p {
  margin: 14px 20px 0 20px;
}

.social-card--slide {
  display: none;
}

.social-card--slide.active {
  display: block;
}

.social-card--footer {
  display: flex;
  margin-top: auto;
  padding: 20px;
}

.social-card--footer--link {
  display: flex;
}

.social-card--footer--link + .social-card--footer--link {
  margin-left: auto;
}

@media screen and (min-width: 1200px) {
  .social-card--footer--link + .social-card--footer--link {
    margin-left: 70px;
  }
}

.social-card--footer .footer-icon {
  width: 20%;
  fill: RGB(var(--color-primary));
}

.social-card--footer .footer-text {
  padding-left: 10px;
  color: black;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.social-card--nav {
  display: none;
  position: absolute;
  bottom: -42px;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .social-card--nav {
    display: flex;
    opacity: 0;
    transition: 0.4s all ease-in-out;
    pointer-events: none;
  }
}

.social-card--nav--item {
  width: 8px;
  height: 8px;
  border: none;
  margin: 0 5px;
  padding: 0;
  background-color: white;
}

.social-card--nav--item.active {
  width: 12px;
  height: 12px;
  pointer-events: none;
  background-color: RGB(var(--color-primary));
}

.job-banner {
  --input-color: white;
}

@media screen and (min-width: 960px) {
  .job-banner {
    margin: 0 -20px 55px;
  }
}

@media screen and (min-width: 1200px) {
  .job-banner {
    margin: 0;
  }
}

.job-banner .breadcrumbs {
  width: 100%;
  margin-bottom: 70px;
}

@media screen and (min-width: 700px) {
  .job-banner .breadcrumbs {
    margin-top: 10px;
  }
}

.job-banner .job-search--title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  position: relative;
  color: #fff;
}

.job-banner .search-field {
  background: none;
}

.job-banner .search-field, .job-banner .search-field::placeholder,
.job-banner .search-field input,
.job-banner .search-field input::placeholder {
  color: var(--input-color);
  transition: 0.4s color ease-in-out;
}

.job-banner .check-drop-down.open {
  --input-color: $darkGrey;
}

.job-banner .check-drop-down--back {
  background: none;
}

.job-banner .arrowed-link {
  position: relative;
  color: white;
}

.job-banner--inner {
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px 50px;
  padding: 0 20px 20px 20px;
  width: calc(100% + 2 * 20px);
  min-height: 740px;
  max-width: none;
}

@media screen and (min-width: 700px) {
  .job-banner--inner {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 20px 60px 20px;
  }
}

@media screen and (min-width: 960px) {
  .job-banner--inner {
    margin: 0 auto 90px;
    padding: 0 40px 50px 60px;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .job-banner--inner {
    padding: 0 calc((100% - 1170px) / 2) 60px calc((100% - 1170px) / 2);
  }
}

.job-banner--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 740px;
  overflow: hidden;
}

@media screen and (min-width: 700px) {
  .job-banner--background {
    min-height: 0;
  }
}

.job-banner--background .back-picture.has-gradient:after {
  height: calc(100% - 20px);
  background: linear-gradient(transparent, var(--gradient-color));
}

@media screen and (min-width: 700px) {
  .job-banner--background .back-picture.has-gradient:after {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .job-banner--content {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

@media screen and (min-width: 960px) {
  .job-banner--content {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .job-banner--content {
    width: 35%;
  }
}

.job-banner--content .still-picture {
  position: relative;
  margin: -20px 0 30px 0;
  width: auto;
  height: auto;
}

@media screen and (min-width: 700px) {
  .job-banner--content .still-picture {
    align-self: flex-end;
  }
}

.video-full-width {
  position: relative;
  margin: 0 -20px;
  min-height: 700px;
}

@media screen and (min-width: 700px) {
  .video-full-width {
    min-height: 0;
  }
}

.video-full-width--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-full-width--background .back-picture.has-gradient:after {
  background: linear-gradient(transparent, var(--gradient-color));
}

.video-full-width--inner {
  margin: 0 auto;
}

@media screen and (min-width: 700px) {
  .video-full-width--inner {
    width: calc(100% - 80px);
  }
}

.video-full-width--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 700px;
  padding: 0 50px;
}

@media screen and (min-width: 700px) {
  .video-full-width--content {
    flex-direction: column;
    align-items: flex-start;
    min-height: 0;
    width: 100%;
    padding: 250px 0 0 0;
  }
}

.video-full-width--content .still-picture {
  position: relative;
  margin-bottom: 50px;
}

@media screen and (min-width: 700px) {
  .video-full-width--content .still-picture {
    align-self: flex-end;
    margin: -60px 0 60px 0;
  }
}

.video-full-width--title {
  position: relative;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .video-full-width--title {
    width: 50%;
    text-align: left;
  }
}

.video-full-width--subtitle {
  position: relative;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
}

.video-full-width--cta {
  position: relative;
  border: none;
  background-color: RGB(var(--color-primary));
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #fff;
  transition: 0.4s background-color ease-in-out;
}

.video-full-width--cta:after {
  font-family: "icomoon";
  content: "";
  padding-left: 10px;
  font-size: 12px;
}

.video-full-width--cta:hover {
  background-color: var(--color-primary-hover);
  padding-left: 20px;
}

@media screen and (min-width: 700px) {
  .video-full-width--cta {
    margin-bottom: 0;
  }
}

.video-full-width--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.video-full-width--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.video-full-width--close {
  position: absolute;
  left: 95%;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.video-full-width--close::after {
  content: "";
  font-family: "icomoon";
}

.top-employer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  padding: 20px 20px 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.top-employer--title {
  margin-top: 25px;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
  color: RGB(var(--color-primary));
}

.top-employer--subtitle {
  font-size: 16px;
  text-align: center;
  color: RGB(var(--color-primary));
}

.market-contact {
  display: flex;
  flex-flow: column;
  margin: 0 -20px;
  padding: 60px 50px 50px;
  background: #f6f6f6;
}

@media screen and (min-width: 700px) {
  .market-contact {
    margin: 0;
    width: 50%;
  }
}

.market-contact--office {
  display: flex;
  flex-flow: column;
}

.market-contact--office + .market-contact--office {
  margin-top: 40px;
}

.market-contact--title {
  font-size: 20px;
  margin: 0 0 20px;
}

.market-contact--address, .market-contact--email, .market-contact--telephone1, .market-contact--telephone2, .market-contact--fax {
  font-size: 14px;
  line-height: 28px;
  color: #262626;
}

.market-contact--address:before, .market-contact--email:before, .market-contact--telephone1:before, .market-contact--telephone2:before, .market-contact--fax:before {
  color: RGB(var(--color-primary));
  margin-right: 15px;
  font-size: 18px;
  line-height: 28px;
  font-family: "icomoon";
}

[dir="rtl"] .market-contact--address:before, [dir="rtl"] .market-contact--email:before, [dir="rtl"] .market-contact--telephone1:before, [dir="rtl"] .market-contact--telephone2:before, [dir="rtl"] .market-contact--fax:before {
  display: inline-block;
  margin-left: 15px;
  margin-right: 0;
}

.market-contact--email::before {
  content: "";
  font-size: 15px;
}

.market-contact--telephone1::before {
  content: "";
  font-size: 18px;
}

.market-contact--telephone2::before {
  content: "";
  font-size: 18px;
}

.market-contact--fax::before {
  content: "";
  font-size: 18px;
}

.board-of-directors {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .board-of-directors {
    flex-flow: row-reverse;
  }
  .board-of-directors.is-image-left-aligned {
    flex-flow: row;
  }
}

@media screen and (min-width: 700px) and (min-width: 700px) {
  .board-of-directors.is-image-left-aligned .board-of-directors--picture {
    margin-left: 0;
    margin-right: 40px;
  }
}

@media screen and (min-width: 700px) and (min-width: 1200px) {
  .board-of-directors.is-image-left-aligned .board-of-directors--picture {
    margin-right: 80px;
  }
}

@media screen and (min-width: 700px) and (min-width: 960px) {
  .board-of-directors.is-image-left-aligned .board-of-directors--content {
    margin-left: 0;
  }
}

.board-of-directors.is-colored {
  background-color: #f6f6f6;
}

.board-of-directors--picture {
  margin: -50px -20px 0;
  min-height: 250px;
  min-width: 250px;
  width: auto;
  height: auto;
}

.board-of-directors--picture img {
  width: 100%;
}

@media screen and (min-width: 700px) {
  .board-of-directors--picture {
    margin: 0 40px;
    align-self: center;
    max-height: 316px;
    max-width: 316px;
  }
  .board-of-directors--picture img {
    border-radius: 50%;
  }
}

@media screen and (min-width: 700px) and (min-width: 1200px) {
  .board-of-directors--picture {
    margin: 0 80px;
  }
}

.board-of-directors--content {
  padding: 40px 5px;
}

@media screen and (min-width: 700px) {
  .board-of-directors--content {
    width: 60%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .board-of-directors--content {
    margin-left: 40px;
  }
}

.board-of-directors--name {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 8px;
}

.board-of-directors--title {
  font-size: 11px;
  font-weight: medium;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.board-of-directors--description {
  font-size: 18px;
  font-weight: lighter;
  line-height: 30px;
}

.xbrl {
  position: relative;
}

.xbrl--modal {
  z-index: 10;
  position: absolute;
  top: calc(-100% - 20px);
  left: 20px;
  background: white;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  width: calc(100vw - 40px);
  overflow: hidden;
  height: 0;
  transition: 0.4s height ease-in-out;
}

@media screen and (min-width: 700px) {
  .xbrl--modal {
    top: -20px;
    left: calc(100% + 10px);
    width: auto;
  }
}

@media screen and (min-width: 960px) {
  .xbrl--modal {
    right: calc(100% + 10px);
    left: auto;
    width: auto;
  }
}

.up-modal .xbrl--modal {
  bottom: -20px;
  top: auto;
}

.xbrl--content {
  padding: 25px 0 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.xbrl--title {
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 30px;
  color: #393939;
}

.xbrl--file {
  display: flex;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  line-height: 18px;
  color: #262626;
  padding: 10px 30px;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out;
}

.xbrl--file::before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  color: RGB(var(--color-primary));
  margin-right: 15px;
  transition: 0.4s color ease-in-out;
}

.xbrl--file:hover, .xbrl--file:focus {
  outline: none;
  background: RGB(var(--color-primary));
  color: white;
  transition: 0.2s background ease-in-out, 0.2s color ease-in-out;
}

.xbrl--file:hover::before, .xbrl--file:focus::before {
  transition: 0.2s color ease-in-out;
  color: white;
}

.sec-filing {
  transition: 0.2s opacity ease-in-out;
}

.sec-filing.deactivated {
  opacity: 0.5;
  pointer-events: none;
  transition: 0.6s opacity ease-in-out;
}

.sec-filing--title {
  margin-bottom: 30px;
}

.sec-filing--menu {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
}

@media screen and (min-width: 700px) {
  .sec-filing--menu {
    flex-flow: row;
    margin-bottom: 30px;
  }
}

.sec-filing--input-box,
.sec-filing .check-drop-down {
  margin-bottom: 15px;
}

@media screen and (min-width: 700px) {
  .sec-filing--input-box,
  .sec-filing .check-drop-down {
    margin-bottom: 0;
    margin-right: 25px;
  }
  .sec-filing--input-box:last-child,
  .sec-filing .check-drop-down:last-child {
    margin: 0;
  }
}

.sec-filing .check-drop-down--main-button {
  width: 100%;
}

.sec-filing .check-drop-down--content {
  justify-content: flex-start;
}

.sec-filing--input-box {
  position: relative;
  display: flex;
  height: 39px;
  flex-grow: 1;
  border: 1px solid #cccccc;
}

.sec-filing--input {
  border: none;
  flex-grow: 1;
  padding-right: 35px;
  padding-left: 20px;
}

.sec-filing--input:focus {
  outline: none;
}

.sec-filing--input:focus::placeholder {
  color: RGB(var(--color-primary));
}

.sec-filing--search {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.sec-filing--search::before {
  content: "";
  font-family: "icomoon";
  color: #6e6e6e;
}

.sec-filing--search:focus {
  outline: none;
}

.sec-filing--search:focus::before {
  color: RGB(var(--color-primary));
}

.sec-filing--content, .sec-filing--table {
  width: 100%;
}

.sec-filing--head, .sec-filing--data {
  padding: 25px 40px;
  text-align: center;
}

.sec-filing--head.left-aligned, .sec-filing--data.left-aligned {
  text-align: left;
}

.sec-filing--head.date-content, .sec-filing--data.date-content {
  background: RGB(var(--color-primary));
  color: white;
}

.sec-filing--head.head-content, .sec-filing--data.head-content {
  background: #f6f6f6;
}

.sec-filing--head:nth-child(2), .sec-filing--data:nth-child(2) {
  position: relative;
}

.sec-filing--head:nth-child(2)::before, .sec-filing--data:nth-child(2)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 1px);
  width: 8px;
  background: white;
}

.sec-filing--head {
  font-size: 14px;
  font-weight: bold;
  color: #393939;
}

.sec-filing--data {
  color: #6e6e6e;
}

.sec-filing--data.description {
  color: #393939;
}

.sec-filing--data:not(.date-content) {
  border-bottom: 1px solid #f6f6f6;
}

.sec-filing--data:nth-child(5) {
  white-space: nowrap;
  font-size: 0;
}

.sec-filing--download {
  margin-right: 8px;
  padding: 0;
  background: none;
  border: none;
}

.sec-filing--download:last-child, .sec-filing--download.xbrl-file {
  margin: 0;
}

.sec-filing--download::before {
  display: inline-block;
  font-family: "icomoon";
  font-size: 20px;
  color: RGB(var(--color-primary));
  transition: 0.4s transform ease-in-out;
}

.sec-filing--download:focus {
  outline: none;
}

.sec-filing--download:focus::before {
  color: #262626;
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

.sec-filing--filing {
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.sec-filing--date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: RGB(var(--color-primary));
  height: 70px;
  padding: 0 55px;
  margin-bottom: 20px;
}

.sec-filing--date-content {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.sec-filing--description, .sec-filing--notes, .sec-filing--downloads {
  padding: 0 15px;
}

.sec-filing--description {
  font-size: 16px;
  color: #393939;
  margin-bottom: 15px;
}

.sec-filing--notes {
  display: flex;
  margin-bottom: 30px;
}

.sec-filing--note {
  color: #6e6e6e;
  margin-right: 70px;
}

.sec-filing--downloads {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sec-filing--downloads-title {
  font-size: 14px;
  color: #393939;
}

.sec-filing--downloads > * {
  margin-right: 25px;
}

.press-release-contact-highlight {
  background-color: #f6f6f6;
  padding: 30px 60px 45px 35px;
}

.press-release-contact-highlight--title {
  font-size: 25px;
}

.contact-item {
  margin-top: 20px;
}

.contact-item--subtitle {
  margin-bottom: 10px;
  line-height: 1.5rem;
  font-size: 15px;
}

.contact-item--row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.contact-item--phone-icon:before, .contact-item--email-icon:before {
  font-family: "icomoon";
  color: RGB(var(--color-primary));
}

.contact-item--phone-icon {
  margin-right: 13px;
  font-size: 20px;
  width: auto;
  height: auto;
}

.contact-item--phone-icon:before {
  content: "";
}

.contact-item--email-icon {
  margin-right: 10px;
  font-size: 14px;
}

.contact-item--email-icon:before {
  content: "";
}

.contact-item--phone-number, .contact-item--email {
  color: #6e6e6e;
  font-size: 18px;
}

.press-release-details-widget {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 640px) {
  .press-release-details-widget {
    display: block;
  }
}

.press-release-details-widget--date {
  font-size: 10px;
  margin-bottom: 5px;
  color: #6e6e6e;
}

.press-release-details-widget--title {
  font-size: 40px;
}

.press-release-details-widget--button {
  display: inline-block;
  margin-top: 50px;
  padding: 20px 40px;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid RGB(var(--color-primary));
  background-color: RGB(var(--color-primary));
  color: white;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.15rem;
}

.press-release-details-widget--download:before {
  content: "";
  font-family: "icomoon";
}

.press-release-details-widget--description {
  margin-top: 40px;
}

.tab-widget-image .tab-widget--content-inner {
  padding-top: 50px;
}

.tab-widget-image .tab-widget--content-title {
  margin-top: 85px;
}

.specific-highlight {
  position: relative;
  min-height: 250px;
  margin-bottom: 24px;
  background-color: RGB(var(--color-primary));
  display: flex;
}

@media screen and (min-width: 960px) {
  .specific-highlight {
    min-width: 50%;
  }
}

.specific-highlight--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.specific-highlight--content {
  position: relative;
  padding: 40px 100px 0 40px;
  color: white;
}

.specific-highlight--event-type {
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.specific-highlight--description {
  font-weight: normal;
  margin-bottom: 25px;
}

.specific-highlight--title {
  font-size: 28px;
  color: white;
  line-height: 34px;
  font-weight: normal;
}

.specific-highlight--link {
  color: inherit;
}

.investor-news {
  position: relative;
  display: flex;
  margin: 0 -20px 90px;
  padding: 0 20px;
  background-color: #f6f6f6;
}

.investor-news--content {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .investor-news--content {
    width: 100%;
  }
}

.investor-news--title {
  font-size: 30px;
}

.investor-news .carousel-highlight {
  margin-bottom: 50px;
}

.investor-news .faq-block--title {
  font-size: 14px;
  line-height: 18px;
}

@media screen and (min-width: 700px) {
  .investor-news .faq-block--title {
    font-size: 16px;
  }
}

.table-widget {
  display: flex;
  flex-flow: column;
}

.table-widget--download {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: RGB(var(--color-primary));
  text-transform: uppercase;
  margin-bottom: 15px;
}

.table-widget--download::after {
  content: "";
  font-family: "icomoon";
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background: RGB(var(--color-primary));
  margin-left: 15px;
  margin-right: 30px;
  transition: 0.4s transform ease-in-out;
}

.table-widget--download:focus {
  outline: none;
}

.table-widget--download:hover::after, .table-widget--download:focus::after {
  transform: scale(1.2);
}

.table-widget--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  background: RGB(var(--color-primary));
  color: white;
  padding: 0 35px 0 20px;
  width: 100%;
  margin-bottom: 1px;
  border: none;
  font-size: 16px;
}

@media screen and (min-width: 960px) {
  .table-widget--title {
    font-size: 24px;
  }
}

.table-widget--container {
  height: 0;
  overflow: hidden;
  transition: 0.4s height ease-in-out;
}

.table-widget--table-container {
  overflow-x: auto;
  margin-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .table-widget--table-container {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 1200px) {
  .table-widget--table-container {
    margin-bottom: 70px;
  }
}

.table-widget--table {
  min-width: 100%;
}

.table-widget--table th {
  background: #f6f6f6;
  border-color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 25px;
  text-align: right;
  white-space: nowrap;
}

.table-widget--table th:first-child {
  text-align: left;
}

.table-widget--table th sup {
  font-size: 14px;
  opacity: 0.8;
  margin-left: 4px;
}

.table-widget--table td {
  border-bottom: 1px solid #f6f6f6;
  font-size: 18px;
  text-align: right;
  padding: 20px 25px;
}

.table-widget--table td:first-child {
  color: #6e6e6e;
  text-align: left;
}

.table-widget--table td.highlighted {
  font-weight: bold;
  background: #f6f6f6;
  border-color: white;
}

.table-widget--table th:last-child,
.table-widget--table td:last-child {
  padding-right: 35px;
}

.table-widget--table tr:last-child td {
  border: none;
}

.table-widget--table tr.footnoted {
  cursor: pointer;
}

.table-widget--table tr.footnoted:hover td:first-child {
  color: RGB(var(--color-primary));
}

.table-widget--table tr.is-title td {
  padding-bottom: 10px;
  border-color: white;
}

.table-widget--table tr.is-bullet td {
  padding-bottom: 5px;
  padding-top: 5px;
  border-color: white;
}

.table-widget--table tr.is-bullet td div {
  display: flex;
  align-items: baseline;
}

.table-widget--table tr.is-bullet td div::before {
  content: '';
  background: RGB(var(--color-primary));
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 5px;
}

.table-widget--table tr.is-last-bullet td {
  padding-bottom: 20px;
  padding-top: 5px;
  border-bottom: 1px solid #f6f6f6;
}

.table-widget--table tr:hover td, .table-widget--table tr.highlighted td {
  background: #f6f6f6;
  border-color: white;
}

.table-widget--footnotes {
  padding-left: 25px;
  padding-right: 35px;
}

.table-widget--footnote {
  font-size: 14px;
  font-weight: light;
  color: #393939;
  cursor: pointer;
  margin-bottom: 15px;
}

.table-widget--footnote:hover, .table-widget--footnote.highlighted {
  color: RGB(var(--color-primary));
}

.table-widget--footnote a {
  text-decoration: underline;
}

.table-widget--file {
  position: relative;
  text-align: center;
  z-index: 100;
}

.table-widget--file::before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  color: RGB(var(--color-primary));
}

.table-widget td.table-widget--centered-cell {
  text-align: center;
}

.table-widget--more-button {
  position: relative;
  padding: 25px 40px;
  text-align: center;
  width: 100%;
  background: white;
  border: 1px solid RGB(var(--color-primary));
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.table-widget--button-label {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15;
  color: RGB(var(--color-primary));
  white-space: nowrap;
  transform: translateY(-50px);
  transition: 0.4s transform ease-in-out;
}

.table-widget--button-label:after {
  content: "";
  display: inline-block;
  font-family: "icomoon";
  font-size: 8px;
  margin-left: 10px;
  transform: translateX(0);
  transition: 0.2s transform ease-in-out;
}

.table-widget--button-label.active {
  transform: none;
}

.table-widget--button-label:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 50px));
}

.table-widget--button-label:last-child:after {
  transform: scaleY(-1);
}

.table-widget--button-label:last-child.active {
  transform: translate(-50%, -50%);
}

.table-widget-ir--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 30px;
}

.table-widget-ir--description {
  font-size: 16px;
  line-height: 24px;
  color: #393939;
  margin-bottom: 80px;
  max-width: 80%;
}

.table-widget-ir--container {
  overflow-x: auto;
  padding-bottom: 25px;
}

.table-widget-ir--table {
  min-width: 100%;
}

.table-widget-ir--header-cell, .table-widget-ir--cell {
  padding: 25px 35px;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .table-widget-ir--header-cell, .table-widget-ir--cell {
    padding: 25px 60px;
  }
}

.table-widget-ir .footnoted {
  cursor: pointer;
}

.date-content .table-widget-ir--cell {
  color: white;
}

.table-widget-ir--header-cell.date-content {
  color: white;
  background: RGB(var(--color-primary));
  white-space: nowrap;
}

.table-widget-ir th:nth-child(2),
.table-widget-ir td:nth-child(2) {
  position: relative;
}

.table-widget-ir th:nth-child(2)::after,
.table-widget-ir td:nth-child(2)::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 1px);
  width: 8px;
  background: white;
}

.table-widget-ir--header-cell {
  font-size: 14px;
  font-weight: bold;
  color: #393939;
  background: #f6f6f6;
}

.table-widget-ir tbody td,
.table-widget-ir tbody th {
  position: relative;
}

.table-widget-ir tbody td::before,
.table-widget-ir tbody th::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #f6f6f6;
}

.table-widget-ir tbody td.date-content::before,
.table-widget-ir tbody th.date-content::before {
  background: RGB(var(--color-primary));
  border-color: RGB(var(--color-primary));
}

.table-widget-ir tbody tr:hover td:not(.date-content)::before,
.table-widget-ir tbody tr.highlighted td:not(.date-content)::before {
  background: #f6f6f6;
}

.table-widget-ir--cell {
  color: #6e6e6e;
  position: relative;
}

.table-widget-ir tfoot td {
  border: none;
  padding: 0;
}

.table-widget-ir--more-button {
  position: relative;
  padding: 25px 40px;
  text-align: center;
  width: 100%;
  background: white;
  border: 1px solid RGB(var(--color-primary));
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.table-widget-ir--button-label {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15;
  color: RGB(var(--color-primary));
  white-space: nowrap;
  transform: translateY(-50px);
  transition: 0.4s transform ease-in-out;
}

.table-widget-ir--button-label:after {
  content: "";
  display: inline-block;
  font-family: "icomoon";
  font-size: 8px;
  margin-left: 10px;
  transform: translateX(0);
  transition: 0.2s transform ease-in-out;
}

button:hover .table-widget-ir--button-label:after,
button:focus .table-widget-ir--button-label:after {
  transform: translateX(5px);
}

.table-widget-ir--button-label.active {
  transform: none;
}

.table-widget-ir--button-label:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 50px));
}

.table-widget-ir--button-label:last-child:after {
  transform: scaleY(-1);
}

.table-widget-ir--button-label:last-child:hover:after,
button:focus .table-widget-ir--button-label:last-child:after {
  transform: translateX(5px) scaleY(-1);
}

.table-widget-ir--button-label:last-child.active {
  transform: translate(-50%, -50%);
}

.table-widget-ir--collapsing-rows tr {
  --row-height: 0;
}

.table-widget-ir--collapsing-rows .table-widget-ir--cell-container {
  overflow: hidden;
  height: var(--row-height);
  transition: 0.4s height ease-in-out;
}

.table-widget-ir--collapsing-rows:not(.open) .table-widget-ir--cell {
  color: transparent;
  border-color: transparent;
  transition: 0.4s color ease-in-out, 0.4s border-color ease-in-out;
}

.table-widget-ir--collapsing-rows.open .table-widget-ir--cell {
  transition: 0.4s color ease-in-out 0.2s all ease-in-out, 0.4s border-color ease-in-out 0.2s all ease-in-out;
}

.table-widget-ir--footnotes {
  margin-top: 20px;
}

.table-widget-ir--footnote {
  display: block;
  font-size: 14px;
  font-weight: lighter;
  color: #393939;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

.table-widget-ir--footnote:hover, .table-widget-ir--footnote:focus, .table-widget-ir--footnote.highlighted {
  color: RGB(var(--color-primary));
}

.table-widget-ir--footnote a {
  text-decoration: underline;
}

.table-widget-ir--sup {
  vertical-align: super;
  font-size: smaller;
}

.table-widget-ir--file {
  position: relative;
  text-align: center;
  z-index: 100;
}

.table-widget-ir--file::before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  color: RGB(var(--color-primary));
}

.table-widget-ir--file:focus {
  outline: none;
}

.table-widget-ir--file:focus::before {
  font-size: 25px;
  color: #262626;
}

.long-quote {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .long-quote {
    flex-flow: row;
  }
}

.long-quote--image {
  border-radius: 50%;
  width: 210px;
  min-width: 210px;
  height: 210px;
  margin-bottom: 25px;
  align-self: center;
  overflow: hidden;
  position: relative;
  display: none;
}

.long-quote--image.is-mobile-show {
  display: block;
}

@media screen and (min-width: 700px) {
  .long-quote--image {
    display: block;
    margin: 0 100px 0 0;
  }
}

.long-quote--content {
  padding-bottom: 65px;
}

.long-quote--content:before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  display: block;
  font-style: normal;
  margin-bottom: 25px;
}

@media screen and (min-width: 700px) {
  .long-quote--content {
    padding: 70px 0;
  }
}

.long-quote--quote {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
}

.long-quote .quote-author {
  display: block;
  font-size: 12px;
}

@media screen and (min-width: 700px) {
  .long-quote.right-aligned {
    flex-flow: row-reverse;
  }
  .long-quote.right-aligned .long-quote--image {
    margin: 0 0 0 100px;
  }
}

.long-quote.grey-background {
  background-color: #f6f6f6;
}

.latest-events-widget.width-by-layout .investor-events-overview {
  max-width: none;
}

.upcoming-events-widget .investor-events-overview--item {
  padding: 30px 0px;
  border-bottom: 1px solid #cccccc;
}

.upcoming-events-widget .investor-events-overview--content {
  line-height: 2rem;
}

.upcoming-events-widget .investor-events-overview--content-date {
  font-size: 18px;
}

.upcoming-events-widget .investor-events-overview--content-date::before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
  font-size: 20px;
}

.upcoming-events-widget .investor-events-overview--content-title {
  margin-top: 10px;
  font-size: 28px;
}

@media screen and (min-width: 960px) {
  .upcoming-events-widget .investor-events-overview--content-title {
    margin-left: 34px;
  }
}

.upcoming-events-widget .investor-events-overview--calendar-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.upcoming-events-widget .investor-events-overview--menu {
  width: 160px;
  position: absolute;
  bottom: 15px;
  right: 0;
  padding: 15px;
  margin-bottom: 25px;
  display: none;
  opacity: 0;
  background-color: white;
  animation: fade-in 0.6s;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.upcoming-events-widget .investor-events-overview--menu::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 25%;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.upcoming-events-widget .investor-events-overview--menu.active {
  display: flex;
  flex-direction: column;
  opacity: 1;
}

.upcoming-events-widget .investor-events-overview--menu-title {
  margin-bottom: 10px;
  font-size: 14px;
}

.upcoming-events-widget .investor-events-overview--menu-title .close-btn {
  float: right;
  margin-top: 4px;
  font-size: 8px;
  color: #cccccc;
  cursor: pointer;
}

.upcoming-events-widget .investor-events-overview--menu-title .close-btn::after {
  content: "";
  font-family: "icomoon";
}

.upcoming-events-widget .investor-events-overview--menu-item {
  padding-bottom: 10px;
  font-size: 11px;
  color: #6e6e6e;
}

.upcoming-events-widget .investor-events-overview--menu-item::before {
  vertical-align: 20%;
  margin-right: 14px;
  content: "";
  font-family: "icomoon";
  font-size: 7px;
  color: #6e6e6e;
}

.upcoming-events-widget .investor-events-overview--menu-item:hover {
  color: RGB(var(--color-primary));
}

.upcoming-events-widget .investor-events-overview--calendar {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  border: 0;
  outline: 0;
  background: none;
  color: #6e6e6e;
}

.upcoming-events-widget .investor-events-overview--calendar::before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
}

.quote-square {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: 10px;
}

.quote-square::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

@media screen and (min-width: 480px) {
  .quote-square::before {
    height: 100%;
  }
}

.quote-square.reduce-background::before {
  height: calc(100% - 105px);
}

@media screen and (min-width: 700px) {
  .quote-square.reduce-background::before {
    height: 100%;
  }
}

@media screen and (min-width: 700px) {
  .quote-square {
    flex-flow: row;
    position: relative;
  }
  .quote-square::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 105px);
    height: 100%;
  }
}

.quote-square--image {
  display: none;
  position: relative;
  align-self: center;
  max-height: 210px;
  max-width: 210px;
  overflow: hidden;
}

.quote-square--image.is-mobile-show {
  display: block;
}

@media screen and (min-width: 700px) {
  .quote-square--image {
    display: block;
    min-height: 210px;
    min-width: 210px;
  }
}

.quote-square--content {
  position: relative;
  align-self: center;
  padding: 40px 40px;
}

@media screen and (min-width: 700px) {
  .quote-square--content {
    margin-left: 80px;
    padding: 40px 40px 40px 0;
  }
}

.quote-square--content:before {
  content: "";
  font-family: "icomoon";
  font-size: 20px;
  display: block;
  font-style: normal;
  margin-bottom: 25px;
}

.quote-square--quote {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
}

.quote-square .quote-author {
  display: block;
  font-size: 12px;
}

@media screen and (min-width: 700px) {
  .quote-square.right-aligned, .quote-square.is-mobile-show {
    flex-flow: row-reverse;
  }
  .quote-square.right-aligned::before, .quote-square.is-mobile-show::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 105px);
    height: 100%;
    background-color: #f6f6f6;
  }
}

.market-redirect-widget {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 148px;
  background-color: RGB(var(--color-primary));
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 960px) {
  .market-redirect-widget {
    height: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .market-redirect-widget {
    margin: 0;
    height: 80px;
  }
}

@media screen and (min-width: 1265px) {
  .market-redirect-widget {
    transition: 0.4s transform ease-in-out, 0.4s opacity ease-in-out;
  }
}

@media screen and (min-width: 1265px) {
  .market-redirect-widget.closed,
  body.scrolled .market-redirect-widget {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.market-redirect-widget.closed {
  display: none;
}

.market-redirect-widget--image-container {
  height: 100%;
  overflow: hidden;
}

.market-redirect-widget--image {
  width: 100%;
  height: auto;
  opacity: 0.2;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget--image {
    object-fit: cover;
    height: 300px;
  }
}

.market-redirect-widget--content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  padding: 20px;
  color: white;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget--content {
    margin: 0 auto;
    flex-flow: row;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1200px) {
  .market-redirect-widget--content {
    justify-content: space-between;
  }
}

.market-redirect-widget--description-container {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget--description-container {
    width: 50%;
  }
}

.market-redirect-widget--description-container .close-btn {
  border: none;
  font-size: 16px;
  color: white;
  background: none;
  margin-right: 30px;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .market-redirect-widget--description-container .close-btn {
    margin-right: 90px;
  }
}

.market-redirect-widget--description-container .close-btn::before {
  content: "";
  font-family: "icomoon";
}

.market-redirect-widget--description {
  font-size: 14px;
}

.market-redirect-widget--country-select {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget--country-select {
    width: 40%;
    margin-top: 5px;
    justify-content: stretch;
  }
}

.market-redirect-widget--country-select .arrowed-link {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  --spacing: 28px;
}

.market-redirect-widget--country-select .arrowed-link::before {
  color: RGB(var(--color-primary));
  background-color: white;
}

.market-redirect-widget .country-dropdown {
  position: relative;
  background: none;
  width: calc(50% - 30px);
  max-width: 276px;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget .country-dropdown {
    margin-right: 30px;
  }
}

.market-redirect-widget .country-dropdown--button {
  display: flex;
  background: none;
  color: white;
}

.market-redirect-widget .country-dropdown--button.search-field {
  border-bottom: 1px solid white;
  font-size: 16px;
}

.market-redirect-widget .country-dropdown--list-container {
  position: absolute;
  z-index: 2;
  top: -10px;
  left: 0;
  width: 100%;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  overflow: hidden;
  max-height: 0;
  transition: 0.4s max-height ease-in-out;
}

.market-redirect-widget .country-dropdown--list-container.open {
  max-height: 200px;
}

.market-redirect-widget .country-dropdown--list {
  padding: 10px 0;
  background-color: white;
}

.market-redirect-widget .country-dropdown--link {
  display: flex;
  color: #262626;
  padding: 10px 0;
  transition: none;
}

@media screen and (min-width: 960px) {
  .market-redirect-widget .country-dropdown--link {
    align-items: center;
  }
}

.market-redirect-widget .country-dropdown--link .country-dropdown--flag {
  margin-left: 10px;
}

.market-redirect-widget .country-dropdown--link:hover {
  color: white;
  background-color: RGB(var(--color-primary));
}

.market-redirect-widget .country-dropdown--flag {
  flex: 0 0 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.market-redirect-widget .country-dropdown .down-arrow::after,
.market-redirect-widget .country-dropdown .up-arrow::after {
  display: inline-block;
  content: "";
  font-family: "icomoon";
  font-size: 8px;
  transform: rotate(180deg);
}

.market-redirect-widget .country-dropdown .down-arrow {
  margin-left: auto;
}

.market-redirect-widget .country-dropdown .up-arrow::after {
  position: absolute;
  top: 25px;
  right: 10px;
  color: #262626;
  transform: none;
}

.investor-cta-widget {
  display: block;
  padding: 15px;
  margin-bottom: 16px;
  border: 1px solid #cccccc;
}

.investor-cta-widget:focus {
  border-color: RGB(var(--color-primary));
  outline: none;
}

.investor-cta-widget .investor-cta--title {
  color: #6e6e6e;
}

.investor-cta-widget .investor-cta--description {
  color: #262626;
}

.investor-cta-widget-blue {
  display: block;
  padding: 15px;
  margin-bottom: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  background-color: RGB(var(--color-primary));
  border: 1px solid RGB(var(--color-primary));
}

.investor-cta-widget-blue:focus {
  outline: none;
  border-color: white;
}

.investor-cta-widget-blue .investor-cta--title, .investor-cta-widget-blue .investor-cta--description, .investor-cta-widget-blue .investor-cta--label {
  color: white;
}

.investor-cta-newsletter {
  display: block;
  padding: 40px 30px;
  margin-bottom: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  background-color: RGB(var(--color-primary));
  border: 1px solid RGB(var(--color-primary));
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter {
    padding: 50px 30px;
  }
}

.investor-cta-newsletter:focus {
  outline: none;
  border-color: white;
}

.investor-cta-newsletter .investor-cta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter .investor-cta {
    flex-direction: row;
    justify-content: space-between;
  }
}

.investor-cta-newsletter .investor-cta--title, .investor-cta-newsletter .investor-cta--description, .investor-cta-newsletter .investor-cta--label {
  color: white;
}

.investor-cta-newsletter .investor-cta--newsletter-button {
  margin-right: auto;
  background-color: white;
  color: #262626;
  text-transform: uppercase;
  align-self: center;
  padding: 10px 30px;
  margin-top: 70px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter .investor-cta--newsletter-button {
    margin-right: inherit;
    margin-top: 0;
  }
}

.contact-details {
  --picture-aspect-ratio: 133.33%;
}

.contact-details.with-details {
  --picture-aspect-ratio: 100%;
}

.contact-details--photo {
  position: relative;
  width: 100%;
}

.contact-details--photo img {
  width: auto;
  height: auto;
}

.contact-details--photo::before {
  content: '';
  display: block;
  padding-bottom: var(--picture-aspect-ratio);
}

.with-details .contact-details--name, .with-details .contact-details--position {
  padding-left: 0;
}

@media screen and (min-width: 700px) {
  .contact-details--name, .contact-details--position {
    padding-left: 20px;
  }
}

.contact-details--name {
  margin-top: 15px;
  font-size: 28px;
}

.with-details .contact-details--name {
  margin-top: 25px;
  font-size: 20px;
}

.contact-details--position {
  max-width: 255px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
}

.with-details .contact-details--position {
  margin-top: 14px;
}

.contact-details--email, .contact-details--telephone {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #cccccc;
  font-size: 14px;
  color: #262626;
}

.contact-details--email:before, .contact-details--telephone:before {
  font-family: "icomoon";
  color: RGB(var(--color-primary));
}

.contact-details--email:before {
  content: "";
  margin-right: 15px;
}

.contact-details--telephone:before {
  content: "";
  margin: 0 15px 0 3px;
  font-size: 18px;
}

.ir-email-alert {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  color: white;
}

.ir-email-alert.is-white {
  background-color: white;
  color: #262626;
  border: 1px solid #cccccc;
  box-shadow: none;
  padding: 15px;
}

.ir-email-alert.is-white:focus {
  border-color: RGB(var(--color-primary));
  outline: none;
}

.ir-email-alert.is-white .ir-email-alert--subscribe {
  color: #262626;
}

.ir-email-alert.is-white .ir-email-alert--subscribe:before {
  border: 2px solid RGB(var(--color-primary));
}

@media screen and (min-width: 960px) {
  .ir-email-alert {
    padding-right: 100px;
  }
}

.ir-email-alert--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 20px;
}

.ir-email-alert--description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 45px;
}

@media screen and (min-width: 960px) {
  .ir-email-alert--description {
    margin-bottom: 80px;
    width: 65%;
  }
}

.ir-email-alert--subscribe:hover, .ir-email-alert--subscribe:focus, .ir-email-alert--subscribe {
  color: white;
  background: none;
  padding: 0;
  border: none;
}

.ir-email-alert--subscribe:hover::before, .ir-email-alert--subscribe:focus::before, .ir-email-alert--subscribe::before {
  background: white;
  color: RGB(var(--color-primary));
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.quarterly-reports--title {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: normal;
}

.quarterly-reports--year {
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: lighter;
  text-align: right;
}

.quarterly-reports--cards {
  user-select: none;
}

.quarterly-reports--tabs {
  display: flex;
  justify-content: space-between;
}

.quarterly-reports--tab {
  width: 100%;
  text-align: center;
  font-size: 11px;
  transition: background-color 0.2s;
}

.quarterly-reports--tab.open {
  background-color: #f6f6f6;
}

.quarterly-reports--tab.open .quarterly-reports--tab-link {
  color: #262626;
}

.quarterly-reports--tab.close {
  pointer-events: none;
}

.quarterly-reports--tab.close .quarterly-reports--tab-link {
  color: #6e6e6e;
}

.quarterly-reports--tab-link {
  display: block;
  padding: 18px 34px;
  border: 1px solid transparent;
  border-width: 1px 1px 0 1px;
}

.quarterly-reports--tab-link:focus {
  outline: none;
  border-color: RGB(var(--color-primary));
  font-weight: bold;
}

.quarterly-reports--documents {
  display: none;
  padding: 45px 60px 25px 30px;
  background-color: #f6f6f6;
}

.quarterly-reports--documents.open {
  display: block;
}

.quarterly-reports--controls {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  outline: none;
}

.quarterly-reports--controls .next,
.quarterly-reports--controls .previous {
  color: RGB(var(--color-primary));
  background: none;
  border: none;
  display: none;
}

.quarterly-reports--controls .next:disabled,
.quarterly-reports--controls .previous:disabled {
  color: #6e6e6e;
  pointer-events: none;
}

@media screen and (min-width: 1200px) {
  .quarterly-reports--controls .next,
  .quarterly-reports--controls .previous {
    display: block;
  }
}

.quarterly-reports--controls .next:before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
}

.quarterly-reports--controls .previous:after {
  margin-left: 10px;
  content: "";
  font-family: "icomoon";
}

.quarterly-reports .document-links {
  display: flex;
  padding-bottom: 20px;
  color: #262626;
}

.quarterly-reports .document-links:before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
  font-size: 20px;
}

.quarterly-reports .document-links:focus {
  color: RGB(var(--color-primary));
  outline: none;
}

.quarterly-reports .tns-nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .quarterly-reports .tns-nav {
    position: absolute;
    left: 50%;
    margin-left: -50px;
  }
}

.quarterly-reports .tns-nav button {
  border: none;
  margin-right: 10px;
  padding: 5px;
  width: 7px;
  height: 7px;
  background-color: #f6f6f6;
}

@media screen and (min-width: 1200px) {
  .quarterly-reports .tns-nav button {
    padding: 4px;
  }
}

.quarterly-reports .tns-nav button.tns-nav-active {
  padding: 6px;
  width: 10px;
  height: 10px;
  background-color: RGB(var(--color-primary));
}

@media screen and (min-width: 1200px) {
  .quarterly-reports .tns-nav button.tns-nav-active {
    padding: 5px;
  }
}

.speech-bubble-widget {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .speech-bubble-widget {
    padding: 0 45px;
  }
}

.speech-bubble-widget .speech-bubble:first-child {
  background-color: #646464;
  fill: #646464;
  bottom: 10px;
}

.speech-bubble-widget .speech-bubble:nth-child(2) {
  background-color: #646464;
  fill: #646464;
  top: auto;
  height: calc(80% - 25px);
  opacity: 0.3;
}

.speech-bubble-widget:not(.mirrored) .speech-bubble:first-child {
  right: 35px;
}

.speech-bubble-widget:not(.mirrored) .speech-bubble:nth-child(2) {
  left: 100px;
}

.speech-bubble-widget.mirrored .speech-bubble:first-child {
  left: 35px;
}

.speech-bubble-widget.mirrored .speech-bubble:nth-child(2) {
  right: 100px;
}

.speech-bubble-widget--content {
  position: relative;
  padding: 25px 75px 70px 40px;
  height: 100%;
}

.mirrored .speech-bubble-widget--content {
  padding-right: 40px;
  padding-left: 75px;
}

.speech-bubble-widget--title, .speech-bubble-widget--description {
  position: relative;
}

.speech-bubble-widget--title {
  letter-spacing: -0.06rem;
  text-transform: uppercase;
  font-size: 24px;
  color: #ffdc00;
}

.speech-bubble-widget--description {
  margin-top: 16px;
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.speech-bubble-widget--footnote {
  display: block;
  width: calc(80% - 30px);
  margin: 30px auto 0 auto;
  color: #6e6e6e;
}

.sustainability {
  position: relative;
  display: flex;
  padding: 40px;
  height: 100%;
  min-height: 200px;
}

.sustainability.no-image {
  background-color: #f6f6f6;
}

.sustainability .text-black {
  color: black;
}

.sustainability--content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 1;
  color: white;
}

.sustainability--content > a {
  color: inherit;
}

.sustainability--title {
  font-weight: 400;
}

.sustainability--link {
  margin-top: 40px;
  font-size: 12px;
  text-transform: uppercase;
}

.sustainability:hover .sustainability--link:after,
.sustainability:focus .sustainability--link:after {
  margin-left: 25px;
}

.sustainability:focus {
  outline: 4px solid #262626;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.ir-annual-reports--card {
  user-select: none;
}

.ir-annual-reports--year {
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: lighter;
  text-align: right;
}

.ir-annual-reports--card-inner {
  border: 1px solid #cccccc;
  min-height: 140px;
}

.ir-annual-reports--link-wrapper {
  padding: 40px 50px;
}

.ir-annual-reports--link a {
  letter-spacing: 1.5px;
  color: #262626;
  font-size: 12px;
  text-transform: uppercase;
}

.ir-annual-reports--link a:before {
  font-family: "icomoon";
  content: "";
  margin-right: 24px;
  color: RGB(var(--color-primary));
}

.ir-annual-reports--link:first-child {
  margin-bottom: 18px;
}

.ir-annual-reports--controls {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  outline: none;
}

.ir-annual-reports--controls .next,
.ir-annual-reports--controls .previous {
  color: RGB(var(--color-primary));
  background: none;
  border: none;
  display: none;
}

.ir-annual-reports--controls .next:disabled,
.ir-annual-reports--controls .previous:disabled {
  color: #6e6e6e;
  pointer-events: none;
}

@media screen and (min-width: 1200px) {
  .ir-annual-reports--controls .next,
  .ir-annual-reports--controls .previous {
    display: block;
  }
}

.ir-annual-reports--controls .next:before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
}

.ir-annual-reports--controls .previous:after {
  margin-left: 10px;
  content: "";
  font-family: "icomoon";
}

.ir-annual-reports .tns-nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .ir-annual-reports .tns-nav {
    position: absolute;
    left: 50%;
    margin-left: -50px;
  }
}

.ir-annual-reports .tns-nav button {
  border: none;
  margin-right: 10px;
  padding: 5px;
  width: 7px;
  height: 7px;
  background-color: var(--text-grey);
}

@media screen and (min-width: 1200px) {
  .ir-annual-reports .tns-nav button {
    padding: 4px;
  }
}

.ir-annual-reports .tns-nav button.tns-nav-active {
  padding: 6px;
  width: 10px;
  height: 10px;
  background-color: RGB(var(--color-primary));
}

@media screen and (min-width: 1200px) {
  .ir-annual-reports .tns-nav button.tns-nav-active {
    padding: 5px;
  }
}

.ir-share-calculator {
  display: flex;
  width: 100%;
  position: relative;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .ir-share-calculator {
    flex-flow: row;
  }
}

.ir-share-calculator .pika-single {
  position: absolute;
}

@media screen and (min-width: 480px) {
  .ir-share-calculator .pika-single {
    width: auto;
  }
}

.ir-share-calculator--calendar {
  position: absolute;
  top: -92px;
  left: 20px;
  z-index: 3;
  height: 0;
  width: calc(100% - 40px);
}

@media screen and (min-width: 480px) {
  .ir-share-calculator--calendar {
    top: 35px;
    right: 35px;
    left: auto;
    width: auto;
  }
}

.ir-share-calculator .loading-overlay {
  left: -20px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 640px) {
  .ir-share-calculator .loading-overlay {
    left: 0;
    width: 100%;
  }
}

.ir-share-calculator--form {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  background: RGB(var(--color-primary));
  padding: 35px 20px;
  margin: 0 -20px;
}

.ir-share-calculator--form .ir-share-calculator--title {
  color: white;
}

@media screen and (min-width: 640px) {
  .ir-share-calculator--form {
    padding: 50px 70px;
    margin: 0;
  }
}

@media screen and (min-width: 960px) {
  .ir-share-calculator--form {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .ir-share-calculator--form {
    padding-right: 215px;
  }
}

.ir-share-calculator--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.ir-share-calculator--date {
  color: white;
  margin-bottom: 60px;
  width: 100%;
  z-index: 2;
}

.ir-share-calculator--field {
  color: white;
  padding: 0 20px 15px;
  border: none;
  border-bottom: 1px solid white;
  background: none;
  width: 100%;
  appearance: textfield;
  outline: none;
}

.ir-share-calculator--field::placeholder {
  color: white;
  opacity: 0.57;
}

.ir-share-calculator--field:focus::placeholder {
  opacity: 1;
}

.ir-share-calculator--field-date {
  margin-top: 30px;
}

.ir-share-calculator--shares {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  flex-flow: column;
}

@media screen and (min-width: 640px) {
  .ir-share-calculator--shares {
    flex-flow: row;
  }
}

.ir-share-calculator--share {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin-bottom: 15px;
}

@media screen and (min-width: 640px) {
  .ir-share-calculator--share {
    margin-bottom: 0;
  }
}

.ir-share-calculator--share-radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.ir-share-calculator--radio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  width: 16px;
  height: 16px;
  border: 1px solid white;
  border-radius: 50%;
}

input:checked + .ir-share-calculator--radio::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.ir-share-calculator--results {
  position: relative;
  flex-grow: 1;
  background: #f6f6f6;
  padding: 35px 20px;
  margin: 0 -20px;
}

@media screen and (min-width: 640px) {
  .ir-share-calculator--results {
    padding: 50px 70px 50px;
    margin: 0;
  }
}

@media screen and (min-width: 960px) {
  .ir-share-calculator--results {
    position: static;
  }
}

.ir-share-calculator--results .ir-share-calculator--title {
  color: RGB(var(--color-primary));
}

.ir-share-calculator--result {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #393939;
}

.ir-share-calculator--cta {
  color: white;
  background: none;
  padding: 0;
  border: none;
  margin-top: 50px;
  opacity: 0.5;
  pointer-events: none;
  cursor: pointer;
  outline: none;
}

.ir-share-calculator--cta, .ir-share-calculator--cta:hover, .ir-share-calculator--cta:focus {
  color: white;
}

.ir-share-calculator--cta::before, .ir-share-calculator--cta:hover::before, .ir-share-calculator--cta:focus::before {
  background-color: white;
  color: RGB(var(--color-primary));
}

.ir-share-calculator--cta.active {
  opacity: 1;
  pointer-events: all;
}

.video-load-more .small-image-above-title--picture {
  border: none;
}

.article-links {
  margin-bottom: 50px;
}

.article-links--line {
  margin-bottom: 10px;
}

.article-links--link {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  line-height: 24px;
}

.article-links--link::before {
  content: "";
  display: block;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #6e6e6e;
  border-radius: 50%;
  margin-right: 20px;
  margin-bottom: 2px;
}

.ir-event-detail .breadcrumbs {
  margin: 0 0 55px;
}

.ir-event-detail--top {
  --outerMargin: 20px;
  background: #ebebeb;
  margin: 0 calc(-1 * var(--outerMargin)) 60px;
  padding: 30px var(--outerMargin) 25px;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--top {
    --outerMargin: calc(50vw - 50%);
    padding: 30px var(--outerMargin) 85px;
  }
}

.ir-event-detail--masonry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ir-event-detail--title {
  font-size: 30px;
  font-weight: normal;
  color: #393939;
  margin-bottom: 40px;
}

.ir-event-detail--document-block {
  background: white;
  padding: 30px 30px 45px;
  margin-bottom: 25px;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--document-block {
    width: calc(50% - 12.5px);
  }
}

.ir-event-detail--document-block:last-child {
  margin: 0;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--document-block.keys {
    width: calc(40% - 12.5px);
  }
  .ir-event-detail--document-block.keys:nth-last-child(2) {
    margin: 0;
  }
}

.ir-event-detail--block-title {
  font-size: 24px;
  font-weight: normal;
  color: #393939;
  margin-bottom: 40px;
}

.ir-event-detail--documents li {
  margin-bottom: 30px;
}

.ir-event-detail--documents li:last-child {
  margin: 0;
}

.ir-event-detail--document {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #393939;
  transition: 0.2s color ease-in-out;
}

.ir-event-detail--document::before {
  font-family: "icomoon";
  color: #959595;
  font-size: 26px;
  margin-right: 10px;
  transition: 0.2s color ease-in-out;
}

.ir-event-detail--document.link::before {
  font-size: 20px;
}

.ir-event-detail--document:hover, .ir-event-detail--document:focus {
  outline: none;
  color: #262626;
  transition: none;
}

.ir-event-detail--document:hover::before, .ir-event-detail--document:focus::before {
  color: RGB(var(--color-primary));
  transition: none;
}

.ir-event-detail--cta-box {
  display: flex;
  flex-flow: column;
  color: white;
  padding: 20px 20px 40px;
  background: RGB(var(--color-primary));
  margin: 0 -20px 25px;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--cta-box {
    margin: 0;
    width: calc(60% - 12.5px);
    padding: 30px 30px 50px;
  }
}

.ir-event-detail--cta-box:focus:not(:hover) {
  outline: 4px solid #262626;
}

.ir-event-detail--hour {
  display: flex;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.ir-event-detail--hour::before {
  content: "";
  font-family: "icomoon";
  font-size: 28px;
  margin-right: 10px;
}

.ir-event-detail--date {
  font-size: 18px;
  margin-bottom: 15px;
}

.ir-event-detail--cta-title {
  font-size: 28px;
  color: white;
  margin-bottom: 20px;
}

.ir-event-detail--cta-link,
.ir-event-detail--cta-box:focus .ir-event-detail--cta-link,
.ir-event-detail--cta-box:hover .ir-event-detail--cta-link {
  margin-top: 50px;
  color: white;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--cta-link,
  .ir-event-detail--cta-box:focus .ir-event-detail--cta-link,
  .ir-event-detail--cta-box:hover .ir-event-detail--cta-link {
    margin-top: auto;
  }
}

.ir-event-detail--cta-link::before,
.ir-event-detail--cta-box:focus .ir-event-detail--cta-link::before,
.ir-event-detail--cta-box:hover .ir-event-detail--cta-link::before {
  background: white;
  color: RGB(var(--color-primary));
}

.ir-event-detail--bottom-row {
  display: flex;
  flex-flow: column;
  align-items: flex;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--bottom-row {
    flex-flow: row;
    margin-bottom: 65px;
  }
}

.ir-event-detail--note {
  flex-grow: 1;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--note {
    padding-right: 50px;
    order: -1;
  }
}

@media screen and (min-width: 1200px) {
  .ir-event-detail--note {
    padding-right: 180px;
  }
}

.ir-event-detail--note-title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 20px;
}

.ir-event-detail--note-content {
  font-size: 18px;
  line-height: 28px;
  color: #393939;
}

.ir-event-detail--qr-code {
  position: relative;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
  margin: 0 auto 70px;
  min-width: 210px;
  height: 210px;
}

@media screen and (min-width: 700px) {
  .ir-event-detail--qr-code {
    margin: 0;
  }
}

@media screen and (min-width: 960px) {
  .ir-event-detail--qr-code {
    margin-right: 80px;
  }
}

.ir-event-detail--qr-code img {
  min-width: 150px;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  width: auto;
  height: auto;
}

.ir-event-detail--footnote {
  font-size: 14px;
  font-weight: lighter;
  color: #393939;
}

.ir-search {
  position: relative;
  display: flex;
  flex-flow: column;
  z-index: 1;
  background-color: #f6f6f6;
  color: #393939;
  margin: 0 -20px 50px;
  padding: 0 0 60px;
}

@media screen and (min-width: 1200px) {
  .ir-search {
    padding-bottom: 90px;
    margin: 0 0 60px 0;
  }
}

.ir-search--container {
  padding: 0 35px;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .ir-search--container {
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .ir-search--container {
    padding: 0;
  }
}

.ir-search--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .ir-search--title {
    margin-bottom: 65px;
  }
}

.ir-search--form {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .ir-search--form {
    flex-flow: row;
  }
}

.ir-search--row {
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: column;
}

@media screen and (min-width: 700px) {
  .ir-search--row {
    flex-flow: row wrap;
  }
}

.ir-search--input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: white;
  border: 1px solid #e5e5e5;
  font-size: 14px;
}

.ir-search--input:focus {
  outline: 4px solid #262626;
}

.ir-search--input-container {
  position: relative;
  width: 100%;
}

.ir-search--value-container {
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .ir-search--value-container {
    width: calc(50% - 10px);
  }
  .ir-search--value-container:nth-child(2n + 1) {
    margin-right: 20px;
  }
}

@media screen and (min-width: 960px) {
  .ir-search--value-container {
    width: auto;
    flex-grow: 1;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.ir-search--calendar-container {
  position: relative;
}

@media screen and (min-width: 960px) {
  .ir-search--calendar-container {
    max-width: 300px;
  }
}

.ir-search--calendar-container:after {
  content: "";
  font-family: "icomoon";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: RGB(var(--color-primary));
  pointer-events: none;
}

.ir-search--input-from, .ir-search--input-to {
  cursor: pointer;
}

.ir-search--calendar-from, .ir-search--calendar-to {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  height: 0;
}

@media screen and (min-width: 700px) {
  .ir-search--calendar-from .pika-single, .ir-search--calendar-to .pika-single {
    position: absolute !important;
    width: 100%;
  }
}

.ir-search--calendar-from.active, .ir-search--calendar-to.active {
  z-index: 1;
}

.ir-search--clear-all {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  color: RGB(var(--color-primary));
}

.ir-search--clear-all:hover {
  text-decoration: underline;
}

.ir-search--clear-all:focus {
  outline: 4px solid #262626;
}

.ir-search .breadcrumbs--link::after, .ir-search .breadcrumbs--greyed::after {
  opacity: 0.57;
}

.ir-search .breadcrumbs--link.is-mobile:before, .ir-search .breadcrumbs--greyed.is-mobile:before {
  content: "";
  font-family: "icomoon";
  margin-left: -10px;
}

.ir-search .breadcrumbs--greyed {
  font-weight: 700;
}

.ir-search .pika-lendar {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.ir-search .pika-title {
  display: flex;
  align-items: center;
  background: RGB(var(--color-primary));
  padding: 15px 30px 0;
  color: white;
}

.ir-search .pika-single thead {
  background: RGB(var(--color-primary));
}

.ir-search .pika-single abbr {
  color: white;
  opacity: 0.5;
}

.ir-search .pika-label, .ir-search .pika-select, .ir-search .pika-prev::before, .ir-search .pika-next::before {
  color: white;
}

.ir-search-results--item {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.ir-search-results--item .no-results-found {
  font-size: 18px;
}

.ir-search-results--date-container {
  margin-bottom: 15px;
}

.ir-search-results--date {
  font-size: 11px;
  color: #6e6e6e;
}

.ir-search-results--title {
  font-size: 16px;
  color: #262626;
}

.ir-dividend--title {
  font-size: 30px;
  margin-bottom: 35px;
  font-weight: normal;
}

.ir-dividend iframe {
  width: 100%;
  height: 530px;
}

.ir-share-price-graph {
  width: 100%;
  height: 1094px;
}

@media screen and (min-width: 885px) {
  .ir-share-price-graph {
    height: 567px;
  }
}

.ir-share-price-overview {
  /* stylelint-disable */
  container-type: inline-size;
  /* stylelint-enable */
}

.ir-share-price-overview--inner {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 20px 30px;
  border: 1px solid #cccccc;
  /* stylelint-disable */
  /* stylelint-enable */
}

@container (max-width: 768px) {
  .ir-share-price-overview--inner {
    flex-direction: column;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--inner {
    padding: 15px;
  }
}

.ir-share-price-overview--price-container {
  width: 50%;
  padding-top: 5px;
  padding-right: 50px;
  padding-bottom: 5px;
  border-right: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  /* stylelint-disable */
  /* stylelint-enable */
}

@container (max-width: 960px) {
  .ir-share-price-overview--price-container {
    padding-right: 30px;
  }
}

@container (max-width: 768px) {
  .ir-share-price-overview--price-container {
    width: 100%;
    border-right: medium none;
    border-bottom: 1px solid #cccccc;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--price-container {
    flex-direction: column;
    border-bottom: medium none;
    padding: 0;
  }
  .ir-share-price-overview--price-container > div:last-child {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 15px 0 0;
  }
}

.ir-share-price-overview--price {
  position: relative;
  font-size: 64px;
  line-height: 74px;
  font-weight: 700;
  padding-right: 25px;
}

.full-width-blue .ir-share-price-overview--price {
  color: white;
}

.ir-share-price-overview--price sup {
  position: absolute;
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  margin-left: 5px;
}

.full-width-blue .ir-share-price-overview--price sup {
  color: white;
}

.ir-share-price-overview--change {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: white;
  padding: 2px 8px 4px 16px;
  border-radius: 6px;
  text-align: center;
}

.ir-share-price-overview--change.failed {
  background-color: #dd1414;
}

.full-width-blue .ir-share-price-overview--change.failed {
  background-color: white;
  color: #dd1414;
}

.ir-share-price-overview--change.failed:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  font-family: "icomoon";
  font-size: 12px;
  position: relative;
  transform: scaleX(-1);
  bottom: 2px;
  margin-right: 10px;
}

.ir-share-price-overview--change.succeed {
  background-color: #12B76A;
}

.full-width-blue .ir-share-price-overview--change.succeed {
  background-color: white;
  color: #12B76A;
}

.ir-share-price-overview--change.succeed:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  font-family: "icomoon";
  font-size: 12px;
  position: relative;
  transform: scaleX(-1);
  margin-right: 7px;
}

.ir-share-price-overview--states {
  display: flex;
  justify-content: space-between;
  /* stylelint-disable */
  /* stylelint-enable */
}

@container (max-width: 768px) {
  .ir-share-price-overview--states {
    margin-bottom: 10px;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--states {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

.ir-share-price-overview--state {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  /* stylelint-disable */
  /* stylelint-enable */
}

.full-width-blue .ir-share-price-overview--state {
  color: white;
}

.ir-share-price-overview--state span:first-child {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
}

.ir-share-price-overview--state span:last-child {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  /* stylelint-disable */
  /* stylelint-enable */
}

@container (max-width: 1000px) {
  .ir-share-price-overview--state span:last-child {
    font-size: 26px;
    line-height: 36px;
  }
}

@container (max-width: 900px) {
  .ir-share-price-overview--state span:last-child {
    font-size: 20px;
    line-height: 30px;
  }
}

@container (max-width: 760px) {
  .ir-share-price-overview--state span:last-child {
    font-size: 26px;
    line-height: 36px;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--state span:last-child {
    line-height: 40px;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--state {
    width: 50%;
    margin-bottom: 10px;
  }
}

.ir-share-price-overview--share-container {
  display: flex;
  width: 50%;
  padding-top: 5px;
  padding-left: 40px;
  padding-bottom: 5px;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  /* stylelint-disable */
  /* stylelint-enable */
}

.ir-share-price-overview--share-container img {
  margin-bottom: 20px;
  width: auto;
  height: auto;
}

@container (max-width: 960px) {
  .ir-share-price-overview--share-container {
    padding-left: 30px;
  }
}

@container (max-width: 768px) {
  .ir-share-price-overview--share-container {
    width: 100%;
    padding: 20px 0 0;
  }
}

.ir-share-price-overview--title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

.full-width-blue .ir-share-price-overview--title {
  color: white;
}

.ir-share-price-overview--common-stock {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.full-width-blue .ir-share-price-overview--common-stock {
  color: white;
}

.ir-share-price-overview--infos {
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  /* stylelint-disable */
  /* stylelint-enable */
}

.full-width-blue .ir-share-price-overview--infos {
  color: white;
}

.ir-share-price-overview--price-container .ir-share-price-overview--infos {
  font-size: 20px;
  line-height: 30px;
  /* stylelint-disable */
  /* stylelint-enable */
}

@container (max-width: 500px) {
  .ir-share-price-overview--price-container .ir-share-price-overview--infos {
    font-size: 18px;
    line-height: 28px;
  }
}

@container (max-width: 500px) {
  .ir-share-price-overview--infos {
    align-self: center;
  }
}

.ir-share-price-overview--cta {
  display: block;
  margin-top: 15px;
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
}

.full-width-blue .ir-share-price-overview--cta {
  margin-bottom: 15px;
  color: white;
}

.sustainability-filter {
  display: flex;
  flex-flow: column;
  --loading-height: 520px;
}

.sustainability-filter--filtering {
  --page-padding-compensation: 20px;
  position: relative;
  display: flex;
  background-color: #f6f6f6;
  padding: 45px var(--page-padding-compensation) 100px;
  margin: 0 calc(0px - var(--page-padding-compensation));
}

@media screen and (min-width: 1200px) {
  .sustainability-filter--filtering {
    --page-padding-compensation: 0;
  }
}

.sustainability-filter--wrapping {
  margin: 0 auto;
}

.sustainability-filter--title, .sustainability-filter--results-title {
  color: #393939;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 40px;
}

.sustainability-filter--filters {
  display: flex;
  flex-wrap: wrap;
  --filter-columns-count: 1;
}

@media screen and (min-width: 640px) {
  .sustainability-filter--filters {
    --filter-columns-count: 2;
  }
}

@media screen and (min-width: 700px) {
  .sustainability-filter--filters {
    --filter-columns-count: 3;
  }
}

@media screen and (min-width: 960px) {
  .sustainability-filter--filters {
    --filter-columns-count: 4;
  }
}

@media screen and (min-width: 1200px) {
  .sustainability-filter--filters {
    --filter-columns-count: 5;
  }
}

.sustainability-filter--filter {
  margin-right: 25px;
  margin-bottom: 25px;
  width: calc( 100% / var(--filter-columns-count) - 25px * calc( var(--filter-columns-count) - 1 ) / var(--filter-columns-count));
}

@media screen and (max-width: 639px) {
  .sustainability-filter--filter {
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) and (max-width: 699px) {
  .sustainability-filter--filter:nth-child(2n) {
    margin: 0;
  }
}

@media screen and (min-width: 700px) and (max-width: 959px) {
  .sustainability-filter--filter:nth-child(3n) {
    margin: 0;
  }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
  .sustainability-filter--filter:nth-child(4n) {
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .sustainability-filter--filter:nth-child(5n) {
    margin: 0;
  }
}

.sustainability-filter--tags {
  display: flex;
  flex-flow: wrap;
  align-items: baseline;
}

.sustainability-filter .tag-element {
  margin: 0 20px 20px 0;
}

.sustainability-filter--clear-button button {
  color: RGB(var(--color-primary));
  border: none;
  background: none;
  padding: 0;
  align-self: center;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
}

.sustainability-filter--container {
  position: relative;
  transition: 0.6s height ease-in-out;
}

.transitionning .sustainability-filter--container {
  overflow: hidden;
}

.sustainability-filter--results {
  margin: 0 auto;
  padding: 70px 0 80px;
  --results-columns-count: 1;
}

@media screen and (min-width: 640px) {
  .sustainability-filter--results {
    --results-columns-count: 2;
  }
}

@media screen and (min-width: 960px) {
  .sustainability-filter--results {
    --results-columns-count: 3;
  }
  .sustainability-filter--results .two-results-layout {
    --results-columns-count: 2;
  }
}

.sustainability-filter--results-title {
  margin-bottom: 30px;
}

.sustainability-filter--results-container {
  display: grid;
  grid-template: auto/repeat(var(--results-columns-count), 1fr);
  grid-gap: 5px;
}

@media screen and (max-width: 639px) {
  .sustainability-filter--results-container {
    column-gap: 0;
  }
}

.social-media-highlights {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 20px 60px;
  margin: 0 -20px 50px;
}

@media screen and (min-width: 1200px) {
  .social-media-highlights {
    margin: 0 0 90px;
  }
}

.social-media-highlights > * {
  position: relative;
  z-index: 1;
}

.social-media-highlights--title {
  font-size: 30px;
  font-weight: normal;
  color: white;
  margin-bottom: 35px;
}

.social-media-highlights--title.is-black {
  color: #393939;
}

.social-media-highlights--tiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 700px) {
  .social-media-highlights--tiles {
    max-width: 700px;
  }
}

@media screen and (min-width: 1200px) {
  .social-media-highlights--tiles {
    max-width: 1170px;
  }
}

.social-media-highlights--tile {
  display: flex;
  flex-flow: column;
  width: 100%;
  background: white;
  margin-bottom: 35px;
}

.social-media-highlights--tile:focus-within {
  outline: 4px solid #262626;
}

.social-media-highlights--tile > * {
  padding: 20px;
}

.social-media-highlights--tile:last-child {
  margin-right: 0;
}

@media screen and (min-width: 700px) {
  .social-media-highlights--tile {
    width: calc(50% - 17.5px);
    margin-right: 35px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 700px) and (max-width: 1199px) {
  .social-media-highlights--tile:nth-child(2n) {
    margin-right: 0;
  }
  .social-media-highlights--tile:nth-child(3), .social-media-highlights--tile:nth-child(4) {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1200px) {
  .social-media-highlights--tile {
    width: calc(25% - 26.25px);
    margin-top: 0;
  }
}

.social-media-highlights--tile-head {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f6f6f6;
}

.social-media-highlights--tile-head::after {
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  color: RGB(var(--color-primary));
  margin-left: auto;
}

.social-media-highlights--logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid RGB(var(--color-primary));
  margin-right: 7px;
}

.social-media-highlights--logo img {
  width: 23px;
  height: auto;
}

.social-media-highlights--brand {
  font-size: 12px;
  color: #393939;
  margin-right: 20px;
}

.social-media-highlights--time {
  font-size: 12px;
  color: #6e6e6e;
  margin-left: 10px;
}

.social-media-highlights--link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.social-media-highlights--tile-picture {
  position: relative;
  width: auto;
  height: 100px;
  background: var(--back-picture) center;
  background-size: cover;
}

.social-media-highlights--tile-content {
  color: #6e6e6e;
  padding-bottom: 0;
}

.social-media-highlights--tile-content a:focus {
  outline: 4px solid #262626;
}

.social-media-highlights--tile-footer {
  display: flex;
  margin-top: auto;
}

.social-media-highlights--follow, .social-media-highlights--share {
  display: flex;
  align-items: center;
  width: 50%;
  font-size: 11px;
  text-transform: uppercase;
  color: #393939;
}

.social-media-highlights--follow::before, .social-media-highlights--share::before {
  font-size: 12px;
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-right: 10px;
  transition: 0.4s transform ease-in-out;
}

.social-media-highlights--follow:focus, .social-media-highlights--follow:hover, .social-media-highlights--share:focus, .social-media-highlights--share:hover {
  color: RGB(var(--color-primary));
}

.social-media-highlights--follow:focus::before, .social-media-highlights--follow:hover::before, .social-media-highlights--share:focus::before, .social-media-highlights--share:hover::before {
  transform: rotate(360deg);
}

.social-media-highlights--follow:focus, .social-media-highlights--share:focus {
  outline: 4px solid #262626;
}

.social-media-highlights--follow::before {
  content: "";
}

.social-media-highlights--share::before {
  content: "";
  font-size: 14px;
}

.content-button {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 60px 25px 65px;
  text-align: center;
}

.content-button.is-left-aligned {
  text-align: left;
  align-items: flex-start;
}

.content-button.is-right-aligned {
  text-align: right;
  align-items: flex-end;
}

.content-button:focus-within {
  outline: 4px solid #262626;
}

.content-button.gray-background {
  background-color: #f6f6f6;
}

.content-button--title {
  margin-bottom: 20px;
}

.content-button--title.wysiwyg {
  font-size: 20px;
  font-weight: bold;
}

.content-button--description {
  margin-bottom: 40px;
}

.content-button--description.wysiwyg {
  font-size: 20px;
  font-weight: normal;
}

.content-button--cta {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  padding: 25px 65px;
  text-transform: uppercase;
  font-size: 12px;
  background-color: RGB(var(--color-primary));
  color: white;
  transition: 0.4s background-color ease-in-out;
}

.content-button--cta.color--unsmokeYellow {
  color: black;
}

.content-button--cta.color--unsmokeYellow:hover, .content-button--cta.color--unsmokeYellow:focus {
  color: white;
}

.content-button--cta.color--tarLightPink, .content-button--cta.color--tarPink, .content-button--cta.color--combustionCararra, .content-button--cta.color--combustionGold {
  color: black;
}

.content-button--cta:focus, .content-button--cta:hover {
  outline: none;
  background-color: var(--color-primary-hover);
}

.interactive-wheel {
  --rotation-speed-factor: 3;
  --additional-full-turn: 0;
  --container-margin: 0px;
  --wheel-size: 450px;
  --button-size: calc(var(--wheel-size) * 0.2 - 5px);
  background: #f6f6f6;
  padding: 30px 20px 50px;
  margin: 0 -20px;
  border-top: 10px solid RGB(var(--color-primary));
}

.interactive-wheel--row {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .interactive-wheel--row {
    --container-margin: 40px;
    flex-flow: row;
  }
  .interactive-wheel--row.no-breadcrumbs {
    margin-top: 100px;
  }
}

.interactive-wheel--left {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 60px;
}

@media screen and (min-width: 640px) {
  .interactive-wheel--left {
    width: 80%;
    align-items: flex-start;
  }
}

@media screen and (min-width: 960px) {
  .interactive-wheel--left {
    max-width: 500px;
    margin-bottom: 0;
  }
}

.interactive-wheel--title, .interactive-wheel--description {
  text-align: center;
}

@media screen and (min-width: 640px) {
  .interactive-wheel--title, .interactive-wheel--description {
    text-align: left;
  }
}

.interactive-wheel--title {
  font-size: 48px;
  margin-bottom: 25px;
  color: #393939;
}

.interactive-wheel--description {
  font-size: 20px;
  line-height: 26px;
  color: #393939;
}

.interactive-wheel--right {
  position: relative;
  height: var(--wheel-size);
  width: 100%;
}

.interactive-wheel--wheel {
  position: absolute;
  height: var(--wheel-size);
  width: var(--wheel-size);
  margin-left: auto;
}

@media screen and (min-width: 960px) {
  .interactive-wheel--wheel {
    position: relative;
    align-self: center;
    transform: none;
  }
}

.interactive-wheel--wheel-set {
  position: absolute;
  right: 0;
}

.interactive-wheel--wheel-set::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(var(--wheel-size) * 0.66);
  height: 15px;
  background: #ebebeb;
  border-radius: 50%;
}

@media screen and (max-width: 639px) {
  .interactive-wheel--wheel-set {
    height: var(--wheel-size);
    transform: translateX(calc( var(--wheel-size) / 2 - var(--button-size) / 2 + var(--container-margin)));
  }
}

@media screen and (max-width: 959px) {
  .interactive-wheel--wheel-set:before {
    bottom: -25px;
  }
}

@media screen and (min-width: 640px) {
  .interactive-wheel--wheel-set {
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (min-width: 959px) {
  .interactive-wheel--wheel-set {
    top: -100px;
  }
}

.interactive-wheel--wheel-container {
  position: relative;
  margin: calc(0 - var(--container-margin));
  margin-left: auto;
  padding: var(--container-margin);
  min-width: calc(var(--wheel-size) + 2 * var(--container-margin));
  min-height: calc(var(--wheel-size) + 2 * var(--container-margin));
  overflow: hidden;
  border-radius: 50%;
}

.interactive-wheel--wheel-container:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--button-size);
  height: var(--button-size);
  background: none;
  border-radius: 50%;
  z-index: 2;
}

.interactive-wheel--touch {
  position: absolute;
  top: 50%;
  left: -50vw;
  width: 100vw;
  height: 100%;
  max-height: 50vh;
  transform: translateY(-50%);
}

.interactive-wheel--rotation {
  position: absolute;
  top: 50%;
  left: -55px;
  transform: translateY(-50%);
  height: calc(var(--wheel-size) * 0.25);
}

@media screen and (min-width: 640px) {
  .interactive-wheel--rotation {
    left: -85px;
  }
}

.interactive-wheel--rotation-back {
  fill: none;
  stroke: #ffffff;
  stroke-width: 30;
  stroke-linecap: round;
}

.interactive-wheel--rotation-arrow {
  fill: none;
  stroke: #262626;
  stroke-width: 1.3;
  stroke-miterlimit: 4;
  transform: translateY(0);
  transition: 0.8s transform ease-in-out;
}

.interactive-wheel--rotation-arrow-top.active {
  transform: translateY(-5px);
}

.interactive-wheel--rotation-arrow-bottom.active {
  transform: translateY(5px);
}

@media screen and (min-width: 960px) {
  .interactive-wheel--rotation {
    display: none;
  }
}

.interactive-wheel--back-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--button-size);
  height: var(--button-size);
  font-size: 0;
  background: none;
  border: 2px solid transparent;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  z-index: 3;
}

.interactive-wheel--back-button::before, .interactive-wheel--back-button::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.6s transform ease-in-out, 0.6s color ease-in-out, 0.6s background ease-in-out, 0.6s border-color ease-in-out;
}

.interactive-wheel--back-button:focus::before, .interactive-wheel--back-button:focus::after {
  transition: 0.2s transform ease-in-out, 0.2s color ease-in-out, 0.2s background ease-in-out, 0.2s border-color ease-in-out;
}

.interactive-wheel--back-button::before {
  content: '';
  width: 55px;
  height: 55px;
  border: 1px solid RGB(var(--color-primary));
  font-size: 35px;
  font-family: "icomoon";
  border-radius: 50%;
  top: calc(50% - 5px);
  transform: translate(-50%, -50%);
  color: RGB(var(--color-primary));
}

.interactive-wheel--back-button:focus::before {
  transform: translate(-50%, -60%);
}

.interactive-wheel--back-button.color--innovating::before {
  content: "";
}

.interactive-wheel--back-button.color--operating::before {
  content: "";
  padding-bottom: 1px;
}

.interactive-wheel--back-button.color--caring::before {
  content: "";
}

.interactive-wheel--back-button.color--protecting::before {
  content: "";
}

.interactive-wheel--back-button::after {
  content: "";
  width: 32px;
  height: 32px;
  font-family: "icomoon";
  font-size: 12px;
  color: white;
  background: RGB(var(--color-primary));
  border: 3px solid white;
  border-radius: 50%;
  top: calc(50% + 25px);
}

.interactive-wheel--back-button:focus::after {
  transform: translate(-50%, -40%);
}

.interactive-wheel--back-button:disabled {
  pointer-events: none;
  outline: none;
}

.interactive-wheel--back-button:disabled::before {
  transform: translate(-50%, -200%);
}

.interactive-wheel--back-button:disabled::after {
  transform: translate(-50%, 100%);
}

.interactive-wheel--back-button:focus:not(:disabled) {
  outline: none;
}

.shorthand {
  margin: 0 -20px;
}

@media screen and (min-width: 1200px) {
  .shorthand {
    margin: 0;
  }
}

.blog-carousel {
  --offset: 0;
  --direction-factor: 1;
  --mobile-half-pic-height: min(
		calc(0.3 * calc(100vw - 2 * 20px)),
		15vh
	);
  display: flex;
  margin-bottom: 80px;
}

[dir='rtl'] .blog-carousel {
  --direction-factor: -1;
}

.blog-carousel--wrapper {
  display: flex;
  flex-flow: column;
  margin: auto;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .blog-carousel--wrapper {
    max-width: 828px;
    width: 80%;
  }
}

.blog-carousel--top {
  display: flex;
  align-items: center;
  margin: 0 15px 35px;
}

@media screen and (min-width: 960px) {
  .blog-carousel--top {
    margin: 0 0 35px;
  }
}

.blog-carousel--top-right {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

@media screen and (min-width: 700px) {
  .blog-carousel--top-right {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
}

.blog-carousel--articles-link {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
}

@media screen and (min-width: 960px) {
  .blog-carousel--articles-link {
    margin-left: auto;
  }
  [dir='rtl'] .blog-carousel--articles-link {
    margin-left: 0;
    margin-right: auto;
  }
}

.blog-carousel--articles-link::after {
  content: "";
  font-family: "icomoon";
  font-size: 8px;
  margin-left: 10px;
}

.blog-carousel .logo-disc {
  margin-right: 20px;
}

.blog-carousel--title {
  font-size: 24px;
  font-weight: bold;
  color: RGB(var(--color-primary));
  margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .blog-carousel--title {
    font-size: 39px;
    margin-bottom: 0;
  }
}

.blog-carousel--posts-container {
  position: relative;
  margin-bottom: 50px;
}

@media screen and (max-width: 959px) {
  .blog-carousel--posts-container {
    margin: 0 -20px 50px;
  }
}

.blog-carousel--previous, .blog-carousel--next {
  --position-offset: 35px;
  position: absolute;
  top: var(--mobile-half-pic-height);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  border: none;
  font-size: 0;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: 0.4s width ease-in-out, 0.4s height ease-in-out, 0.4s opacity ease-in-out;
}

@media screen and (min-width: 960px) {
  .blog-carousel--previous, .blog-carousel--next {
    top: 50%;
    --position-offset: 0;
  }
}

.blog-carousel--previous::before, .blog-carousel--next::before {
  font-family: "icomoon";
  font-size: 8px;
  color: RGB(var(--color-primary));
  transition: 0.4s font-size ease-in-out;
}

.blog-carousel--previous:disabled, .blog-carousel--next:disabled {
  width: 0;
  height: 0;
  opacity: 0;
}

.blog-carousel--previous:disabled::before, .blog-carousel--next:disabled::before {
  font-size: 0;
}

.blog-carousel--previous {
  left: var(--position-offset);
  box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.15);
}

.blog-carousel--previous::before {
  content: "";
}

.blog-carousel--next {
  right: var(--position-offset);
  transform: translate(50%, -50%);
}

.blog-carousel--next::before {
  content: "";
}

.blog-carousel--posts {
  position: relative;
  display: flex;
  overflow: visible;
}

@media screen and (min-width: 960px) {
  .blog-carousel--posts {
    gap: 70px;
  }
}

.blog-carousel--post {
  position: relative;
  display: grid;
  min-width: 100%;
  transform: translateX(calc(var(--offset) * var(--direction-factor) * 1px));
  opacity: 0.5;
  grid-template-areas: '.pic .' '.pic .' '. . .' '. title .' '. description .' '. . .';
  grid-template-columns: 35px auto 35px;
  grid-template-rows: var(--mobile-half-pic-height) var(--mobile-half-pic-height) 40px min-content auto 50px;
  transition: 0.4s opacity ease-in-out;
}

@media screen and (min-width: 960px) {
  .blog-carousel--post {
    grid-template-areas: '. . . . .' 'pic pic . title .' 'pic pic . description .' '. . . . .';
    grid-template-columns: 150px 140px 65px auto 65px;
    grid-template-rows: 75px min-content auto 75px;
  }
  .blog-carousel--post.no-title {
    grid-template-rows: 75px 0 auto 75px;
  }
}

.blog-carousel--post::before, .blog-carousel--post-image {
  position: relative;
  overflow: hidden;
}

.blog-carousel--post::before {
  content: '';
  background: #f6f6f6;
  grid-area: 2 / 1 / 7 / 4;
  transition: 0.4s box-shadow ease-in-out;
}

@media screen and (min-width: 960px) {
  .blog-carousel--post::before {
    grid-area: 1 / 2 / 5 / 6;
    border-radius: 10px;
  }
}

.blog-carousel--post.active {
  opacity: 1;
}

@media screen and (min-width: 960px) {
  .blog-carousel--post.active::before {
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
  }
}

.blog-carousel--post-image {
  grid-area: pic;
  border-radius: 10px;
  width: auto;
  height: auto;
}

@media screen and (min-width: 960px) {
  .blog-carousel--post-image {
    align-self: center;
  }
}

.blog-carousel--post-image .back-picture {
  position: relative;
}

@media screen and (min-width: 960px) {
  .blog-carousel--post-image .back-picture {
    height: auto;
  }
  .blog-carousel--post-image .back-picture::before {
    content: '';
    display: block;
    padding-bottom: 66.66%;
  }
  .blog-carousel--post-image .back-picture img {
    position: absolute;
    top: 0;
  }
}

.blog-carousel--post-image .video-cover .video-icon {
  right: 20px;
  left: auto;
  width: auto;
  height: auto;
}

@media screen and (max-width: 959px) {
  .blog-carousel--post-image .video-cover .video-icon {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.blog-carousel--post-image .video-cover .video-icon .video-icon--disc {
  width: 50px;
  height: 50px;
}

.blog-carousel--post-image .video-cover .video-icon .video-icon--disc::after {
  font-size: 14px;
  margin-left: 1px;
}

.blog-carousel--post-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.blog-carousel--post-title {
  position: relative;
  z-index: 1;
  grid-area: title;
  font-size: 20px;
  line-height: 28px;
}

.blog-carousel--post-title.has-after::after {
  content: '';
  display: block;
  width: 20px;
  height: 1px;
  background: RGB(var(--color-primary));
  margin: 15px 0;
}

.blog-carousel--post-description {
  position: relative;
  z-index: 1;
  grid-area: description;
  font-size: 14px;
  line-height: 21px;
}

.blog-carousel .carousel-highlight-nav {
  display: flex;
}

.blog-carousel button.carousel-highlight-nav--dot {
  display: block;
  border-radius: 50%;
}

.blog-carousel button.carousel-highlight-nav--dot:not(:disabled) {
  background: #cccccc;
}

.sustainability-qa {
  display: flex;
  min-height: 250px;
  height: 100%;
}

.sustainability-qa:focus {
  outline: none;
}

.sustainability-qa--block {
  color: #fff;
  position: relative;
  width: 100%;
  padding: 40px;
}

.sustainability-qa--title {
  font-size: 28px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

@media screen and (max-width: 959px) {
  .sustainability-qa--title {
    word-break: break-word;
  }
}

.sustainability-qa--description {
  flex-grow: 1;
  min-height: 100px;
  margin-bottom: 20px;
}

.sustainability-qa--background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: RGB(var(--color-primary));
}

.sustainability-qa--cta:not(.text-black) {
  color: #fff;
}

.sustainability-qa--cta:not(.text-black):before {
  background: #fff;
  color: RGB(var(--color-primary));
}

.sustainability-qa--cta.text-black {
  color: #262626;
}

.sustainability-qa--cta.text-black:before {
  background: #262626;
  color: RGB(var(--color-primary));
}

.sustainability-qa--cta {
  margin-top: auto;
}

.sustainability-qa--content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sustainability-qa .text-black {
  color: #262626;
}

.sustainability-qa--overlay::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

.sustainability-qa--overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #f6f6f6;
  border-top: 9px solid RGB(var(--color-primary));
  border-bottom: 9px solid RGB(var(--color-primary));
  transition: 0.4s ease-out;
  padding: 60px 5%;
}

.sustainability-qa--overlay-content {
  overflow: auto;
  padding-right: 12px;
}

@media screen and (min-width: 959px) {
  .sustainability-qa--overlay-content {
    scrollbar-color: RGB(var(--color-primary)) #f6f6f6;
    scrollbar-width: thin;
  }
  .sustainability-qa--overlay-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f6f6f6;
  }
  .sustainability-qa--overlay-content::-webkit-scrollbar-thumb {
    background-color: RGB(var(--color-primary));
  }
}

.sustainability-qa--overlay.active {
  visibility: visible;
  opacity: 1;
}

.sustainability-qa--overlay-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  padding: 0px 10px;
  background-color: RGB(var(--color-primary));
  border: none;
}

.sustainability-qa--overlay-close::after {
  content: '\d7';
  color: #fff;
}

.sustainability-qa--overlay-close:hover {
  cursor: pointer;
}

.sustainability-qa--overlay-close-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.sustainability-qa--overlay-title {
  margin-bottom: 20px;
}

.sustainability-qa--overlay-description {
  margin-bottom: 40px;
}

@media screen and (min-width: 640px) {
  .sustainability-qa--overlay-description {
    margin-bottom: 80px;
  }
}

.sustainability-qa--overlay-answer {
  margin: 40px 0;
}

.audio-widget {
  --progress: 0;
  display: flex;
  flex-flow: column;
  max-width: 685px;
  margin: 0 auto;
  box-shadow: 0 -5px 30px rgba(0, 0, 0, 0.16);
}

.audio-widget * {
  color: white;
  /*  stylelint-disable selector-pseudo-class-no-unknown */
}

.audio-widget *:where(button) {
  /*  stylelint-enable selector-pseudo-class-no-unknown */
  border: none;
  background: none;
  padding: 0;
}

.audio-widget--unfoldable {
  margin-bottom: -52px;
  border-radius: 4px 4px 0 0;
  background: RGB(var(--color-primary));
  overflow: hidden;
  transition: 0.8s height ease-in-out, 0.8s margin ease-in-out;
}

.activated .audio-widget--unfoldable {
  margin-bottom: -4px;
}

@media screen and (min-width: 700px) {
  .audio-widget--unfoldable {
    height: 0;
    margin-bottom: -4px;
  }
}

.audio-widget--unfoldable-content {
  padding: 20px 20px 35px;
}

@media screen and (min-width: 700px) {
  .audio-widget--unfoldable-content {
    padding: 20px 30px 45px;
  }
}

.audio-widget--unfoldable-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.audio-widget--unfoldable-title {
  max-width: 66.67%;
  font-size: 18px;
}

@media screen and (min-width: 700px) {
  .audio-widget--unfoldable-title {
    font-size: 22px;
  }
}

.audio-widget--note {
  display: flex;
  font-size: 11px;
}

@media screen and (min-width: 700px) {
  .audio-widget--note {
    opacity: 0;
  }
  .activated .audio-widget--note {
    opacity: 1;
  }
}

.audio-widget--type {
  text-transform: uppercase;
}

.audio-widget--type::after {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  margin: 0 4px 2px;
}

.audio-widget--title {
  font-size: 18px;
  transition: 0.8s opacity ease-in-out, 0.8s transform ease-in-out;
}

.activated .audio-widget--title {
  opacity: 0;
  transform: translateX(-100%);
}

.audio-widget--title-container {
  display: none;
  max-width: 45%;
  transition: 0.8s width ease-in-out;
}

@media screen and (min-width: 700px) {
  .audio-widget--title-container {
    display: block;
  }
}

.activated .audio-widget--title-container {
  pointer-events: none;
}

.audio-widget--download {
  opacity: 0;
  transition: 0.8s opacity ease-in-out;
  margin-left: 40px;
}

.activated .audio-widget--download {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .audio-widget--download {
    opacity: 1;
  }
}

.audio-widget--download::before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
  font-family: "icomoon";
  border: 2px solid white;
  transition: 0.4s color ease-in-out, 0.4s background ease-in-out;
}

.audio-widget--download:hover::before {
  background: white;
  color: RGB(var(--color-primary));
}

.audio-widget--bar {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 20px 20px 35px;
  border-radius: 4px;
  background: RGB(var(--color-primary));
  z-index: 1;
  transition: 0.8s border-radius ease-in-out, 0.8s border ease-in-out;
}

.activated .audio-widget--bar {
  border-radius: 0 0 4px 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.audio-widget--play {
  --delay: 0.2s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 2px solid white;
  border-radius: 50%;
  background: white;
  transition: var(--delay) background ease-in-out;
}

.audio-widget--play::before, .audio-widget--play::after {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "icomoon";
  font-size: 14px;
  transition: var(--delay) opacity ease-in-out, var(--delay) color ease-in-out;
}

.audio-widget--play::before {
  content: "";
  color: RGB(var(--color-primary));
  transform: translateX(2px);
  opacity: 1;
}

.audio-widget--play::after {
  content: "";
  opacity: 0;
}

.audio-widget--play:hover {
  background: RGB(var(--color-primary));
}

.audio-widget--play:hover::before {
  color: white;
}

.audio-widget--play:hover::after {
  color: RGB(var(--color-primary));
}

.is-playing .audio-widget--play {
  --delay: 0.4s;
  background: transparent;
}

.is-playing .audio-widget--play:hover {
  background: white;
}

.is-playing .audio-widget--play::before {
  opacity: 0;
}

.is-playing .audio-widget--play::after {
  opacity: 1;
}

.audio-widget--progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-left: 15px;
}

@media screen and (min-width: 700px) {
  .audio-widget--progress {
    flex-wrap: none;
    margin-left: 10px;
  }
}

.audio-widget--progress .control-bar {
  flex-grow: 1;
  width: 100%;
  --button-scale: 0;
}

@media screen and (min-width: 700px) {
  .audio-widget--progress .control-bar {
    width: auto;
  }
}

.audio-widget--progress:hover .control-bar {
  --button-scale: 1;
}

.audio-widget--current, .audio-widget--total {
  font-size: 11px;
}

.audio-widget--current {
  margin-right: 10px;
}

@media screen and (max-width: 699px) {
  .audio-widget--current {
    order: 1;
  }
}

.audio-widget--total {
  margin-left: 10px;
}

@media screen and (max-width: 699px) {
  .audio-widget--total {
    order: 2;
  }
}

.audio-widget--volume {
  display: flex;
}

.audio-widget--volume-bar {
  flex-grow: 1;
}

.audio-widget--volume-container {
  display: none;
  width: 0;
  overflow: hidden;
  transition: 0.8s width ease-in-out, 0.8s margin ease-in-out, 0.8s padding ease-in-out;
}

@media screen and (min-width: 700px) {
  .audio-widget--volume-container {
    display: block;
  }
}

.activated .audio-widget--volume-container {
  width: 80px;
  margin-right: -15px;
  padding-right: 15px;
}

@media screen and (min-width: 959px) {
  .activated .audio-widget--volume-container {
    width: 150px;
  }
}

.audio-widget--volume-button {
  background-color: transparent;
  border: none;
  position: relative;
  width: 20px;
  margin-right: 10px;
  text-align: left;
}

.audio-widget--volume-button-icon::before {
  content: "";
  font-family: "icomoon";
  font-size: 18px;
}

.audio-widget--volume-button::before, .audio-widget--volume-button::after {
  position: absolute;
  left: 16px;
  top: 50%;
  font-family: "icomoon";
  font-size: 16px;
  transform: translateY(-50%);
}

.audio-widget--volume-button::before {
  content: "";
  opacity: calc(var(--progress) * 2);
}

.audio-widget--volume-button::after {
  content: "";
  opacity: calc(var(--progress) * 2 - 1);
}

.is-muted .audio-widget--volume-button::before {
  content: "";
  font-size: 6px;
  opacity: 1;
}

.is-muted .audio-widget--volume-button::after {
  opacity: 0;
}

.audio-widget--volume.is-muted .control-bar {
  --button-scale: 0;
}

.leadership-widget {
  --column-end: calc(var(--columns) + 1);
  display: grid;
  gap: 15px 30px;
  grid-template-columns: repeat(var(--columns), 1fr);
}

.leadership-widget.four-columns {
  --columns: 4;
}

.leadership-widget.three-columns {
  --columns: 3;
}

.leadership-widget.five-columns {
  --columns: 5;
}

@media screen and (max-width: 640px) {
  .leadership-widget.four-columns, .leadership-widget.three-columns, .leadership-widget.five-columns {
    --columns: 1;
    padding: 0 40px;
    gap: 0;
  }
}

@media screen and (min-width: 639px) and (max-width: 960px) {
  .leadership-widget.four-columns, .leadership-widget.three-columns, .leadership-widget.five-columns {
    --columns: 3;
  }
}

.leadership-widget--box {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  border: 1px solid #f6f6f6;
  transition: background-color, transform 0.4s ease-out;
}

.leadership-widget--box.active {
  background-color: #f6f6f6;
  transform: scale(1.1);
}

@media screen and (max-width: 640px) {
  .leadership-widget--box {
    margin-bottom: 40px;
  }
}

.leadership-widget--box .still-picture {
  width: auto;
  height: auto;
}

.leadership-widget--description-container {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.4s ease-out;
  grid-column-start: 1;
  grid-column-end: var(--column-end);
  grid-row-start: var(--row);
  grid-row-end: var(--row);
}

.leadership-widget--description-container.active {
  opacity: 1;
  visibility: visible;
  margin: 35px 0 20px 0;
  transition: all 0.8s ease-out;
}

.leadership-widget--description-container:not(.active) {
  height: 0 !important;
  transition: all 0.6s ease-out;
}

@media screen and (max-width: 640px) {
  .leadership-widget--description-container {
    display: none;
  }
}

.leadership-widget--description {
  padding: 50px 30px;
  background-color: #f6f6f6;
  position: relative;
}

@media screen and (min-width: 640px) {
  .leadership-widget--description {
    padding: 60px;
  }
}

.leadership-widget--description:focus {
  outline: none;
}

.leadership-widget--modal-title, .leadership-widget--modal-job-title {
  display: block;
}

@media screen and (min-width: 640px) {
  .leadership-widget--modal-title, .leadership-widget--modal-job-title {
    display: none;
  }
}

.leadership-widget--modal-job-title {
  margin: 5px 0 10px;
}

.leadership-widget .modal--close-button {
  position: absolute;
  top: -40px;
  right: 0;
  margin-top: 40px;
  margin-left: auto;
}

.leadership-widget .modal--close-button:before {
  background-color: #fff;
}

.leadership-widget .modal--close-button:after {
  color: RGB(var(--color-primary));
}

.leadership-widget .modal--close-button:hover, .leadership-widget .modal--close-button:focus {
  outline: none;
}

.leadership-widget .modal--close-button:hover::before, .leadership-widget .modal--close-button:focus::before {
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .leadership-widget--description-close.modal--close-button {
    display: none;
  }
}

.leadership-widget--link {
  flex: 1 0 auto;
  background-color: #f6f6f6;
  width: 100%;
  border: none;
  padding: 15px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-top: auto;
  transition: all 0.4s ease-out;
  color: RGB(var(--color-primary));
}

.leadership-widget--link.active {
  background-color: RGB(var(--color-primary));
  color: #fff;
}

.leadership-widget--link:hover {
  cursor: pointer;
}

.leadership-widget--link:hover:after {
  margin-left: 18px;
}

.leadership-widget--details {
  padding: 10px;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.leadership-widget--image {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 150px;
}

.leadership-widget--image:hover {
  cursor: pointer;
}

.leadership-widget--image:before {
  content: '';
  width: 100%;
  padding-bottom: 59%;
  display: block;
}

.leadership-widget--image img {
  padding: 10px;
  width: auto;
  height: auto;
}

.leadership-widget--title {
  margin: 10px 0 5px 15px;
}

.leadership-widget--title:hover {
  cursor: pointer;
}

.leadership-widget--job-title {
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 20px 15px;
}

.leadership-widget--job-title:hover {
  cursor: pointer;
}

.leadership-widget--social-media {
  margin-bottom: 15px;
}

.leadership-widget .social-media-links--link {
  margin: 0 0 0 15px;
}

.leadership-widget .social-media-links--link:before {
  font-size: 14px;
}

.leadership-widget .wysiwyg,
.leadership-widget .kendo-content-block {
  font-size: 16px;
  line-height: 24px;
}

.modal--content {
  padding-bottom: 70px;
}

.modal--close-button.modal--close-button-in-content:before {
  background-color: #fff;
}

.modal--close-button.modal--close-button-in-content:after {
  color: RGB(var(--color-primary));
}

.modal--close-button.modal--close-button-in-content:hover, .modal--close-button.modal--close-button-in-content:focus {
  outline: none;
}

.modal--close-button.modal--close-button-in-content:hover::before, .modal--close-button.modal--close-button-in-content:focus::before {
  background-color: #fff;
}

@keyframes fade {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.interactive-map {
  position: relative;
  overflow: hidden;
  animation: fade 1.5s;
}

.interactive-map svg.earth {
  background-color: #f6f6f6;
  transition: 0.4s all ease-in-out;
}

@media screen and (max-width: 959px) {
  .interactive-map svg.earth {
    height: 400px;
  }
}

.interactive-map .hide {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
  animation: fade 1s;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}

.interactive-map g path {
  fill: #dddddd;
}

.interactive-map .active-country path {
  fill: RGB(var(--color-primary));
  transition: 0.4s all ease-in-out;
  opacity: 0.5;
}

.interactive-map .active-country:hover, .interactive-map .active-country.active {
  cursor: pointer;
}

.interactive-map .active-country:hover path, .interactive-map .active-country.active path {
  opacity: 1;
}

.interactive-map--content {
  position: absolute;
  top: 0;
  right: -13px;
  overflow: hidden;
  transition: 0.4s all ease-in-out;
}

@media screen and (min-width: 959px) {
  .interactive-map--content {
    max-width: 550px;
  }
}

.interactive-map--content input {
  border: none;
  border-left: 1px solid;
  border-radius: 0;
  padding-left: 5px;
  max-width: 0;
  visibility: hidden;
  transition: 0.4s all ease-in-out;
  opacity: 0;
}

.interactive-map--content input:focus {
  outline: none;
}

.interactive-map--content.show {
  background-color: #fff;
  right: 0;
  transition: 0.4s all ease-in-out;
}

.interactive-map--content.show input,
.interactive-map--content.show .interactive-map--search-drop,
.interactive-map--content.show .interactive-map--mobile-close {
  max-width: 550px;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 959px) {
  .interactive-map--content.show input,
  .interactive-map--content.show .interactive-map--search-drop,
  .interactive-map--content.show .interactive-map--mobile-close {
    max-width: 100%;
  }
}

@media screen and (max-width: 959px) {
  .interactive-map--content {
    transform: none;
    left: 0;
  }
}

.interactive-map--office-body {
  margin-bottom: 40px;
}

.interactive-map--office-title {
  margin-bottom: 20px;
}

.interactive-map--search {
  height: auto;
  margin: 0;
  width: auto;
}

@media screen and (max-width: 959px) {
  .interactive-map--search {
    height: 100%;
  }
}

.interactive-map--search-content {
  overflow: hidden;
}

.interactive-map--search-button {
  width: 45px;
  height: 40px;
  background-color: #fff;
}

.interactive-map--search-field {
  border-bottom: 1px solid #dddddd;
}

@media screen and (max-width: 959px) {
  .interactive-map--search-field {
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.interactive-map--search-drop {
  height: 0;
  overflow: hidden;
  max-height: 100%;
  overflow-y: scroll;
  max-width: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s all ease-in-out;
}

@media screen and (max-width: 959px) {
  .interactive-map--search-drop {
    border-top: 1px solid #dddddd;
  }
}

.interactive-map--search-list {
  padding-bottom: 45px;
}

.interactive-map--search-list button {
  border: none;
  background: none;
  font-size: 16px;
  letter-spacing: 0.025em;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.interactive-map--search-list button:hover {
  color: RGB(var(--color-primary));
  background: #f6f6f6;
}

@media screen and (min-width: 1265px) {
  .interactive-map--search-list button {
    padding: 10px 30px;
  }
}

.interactive-map--office-video {
  margin: 30px 0;
}

.interactive-map--office-video .basic-video--download {
  display: none;
}

.interactive-map--office-country {
  display: flex;
  border-bottom: 1px solid #dddddd;
  padding: 10px 20px 10px 40px;
  align-items: center;
}

@media screen and (max-width: 959px) {
  .interactive-map--office-country {
    padding: 10px 20px;
  }
}

.interactive-map--office-cta {
  display: inline-block;
  margin-top: 30px;
}

.interactive-map .mobile-nav--link.is-back {
  margin: 0;
  display: flex;
  align-items: center;
}

.interactive-map .mobile-nav--link.is-back::before {
  color: #393939;
  margin-top: 5px;
}

.interactive-map--office-info {
  padding: 20px 40px;
}

@media screen and (max-width: 959px) {
  .interactive-map--office-info {
    padding: 20px;
  }
}

.interactive-map--office-container {
  width: 550px;
  transition: 0.4s all ease-in-out;
}

@media screen and (max-width: 959px) {
  .interactive-map--office-container {
    overflow-y: scroll;
    transition: 0.4s all ease-in-out;
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 60px;
    z-index: 1001;
    bottom: 0;
    height: 100vh;
    left: 0;
    right: 0;
  }
  .interactive-map--office-container.hide {
    display: block;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
}

.interactive-map--icons {
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
}

.interactive-map--icons button {
  position: relative;
  border: none;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  width: 25px;
  height: 25px;
}

.interactive-map--icons-zoom-out:after {
  content: ' ';
  width: 10px;
  height: 2px;
  background-color: #646464;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.interactive-map--icons-center {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interactive-map--icons-center::after {
  content: url("/assets-v3/images/icons/centerIcon.svg");
}

.interactive-map--icons-zoom-in:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  color: #646464;
  font-size: 12px;
  font-family: "icomoon";
}

.interactive-map--office {
  background: #f6f6f6;
  padding: 20px;
  width: 90%;
  margin: 30px auto;
}

@media screen and (max-width: 959px) {
  .interactive-map--office {
    width: 100%;
  }
}

.interactive-map--mobile-close {
  display: none;
}

@media screen and (max-width: 959px) {
  .interactive-map--mobile-close {
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    margin-left: auto;
    height: 30px;
    border: none;
    background: none;
    padding: 0;
    position: relative;
    width: 24px;
    align-self: flex-start;
  }
  .interactive-map--mobile-close > span {
    position: absolute;
    background: #393939;
    width: 90%;
    height: 2px;
    transform: translateX(-50%);
  }
  .interactive-map--mobile-close > span:nth-child(1) {
    transform: translateX(-50%) rotate(45deg);
  }
  .interactive-map--mobile-close > span:nth-child(2) {
    transform: translateX(-50%) rotate(-45deg);
  }
}

.interactive-map .search--glass:hover,
.interactive-map .search--glass:focus {
  color: #393939;
}

.interactive-map .document-download-partial--download {
  word-break: break-word;
}

.dropdown-menu-widget--left {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.dropdown-menu-widget--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.dropdown-menu-widget--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.dropdown-menu-widget--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.dropdown-menu-widget--close::after {
  content: "";
  font-family: "icomoon";
}

.dropdown-menu-widget--copy {
  text-transform: uppercase;
  padding: 10px 10px 10px 0;
  font-size: 16px;
  color: RGB(var(--color-primary));
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--copy {
    margin-top: 3px;
  }
}

.dropdown-menu-widget--logo {
  width: auto;
  height: auto;
  margin-right: 28px;
  min-width: max-content;
}

[dir='rtl'] .dropdown-menu-widget--logo {
  margin-right: 0;
  margin-left: 28px;
}

.dropdown-menu-widget--logo img {
  height: 36px;
  transition: 0.4s transform ease-in-out;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--logo img {
    height: auto;
    z-index: 2001;
    max-height: 60px;
  }
}

.dropdown-menu-widget--logo:focus {
  outline: none;
}

.dropdown-menu-widget--logo:focus img {
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

.dropdown-menu-widget--logo .desktop {
  display: none;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--logo .desktop {
    display: block;
  }
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--logo .mobile {
    display: none;
  }
}

.dropdown-menu-widget--note {
  font-size: 12px;
  color: #6e6e6e;
  font-weight: normal;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--note {
    min-height: 15px;
  }
}

.dropdown-menu-widget--center {
  flex-flow: column;
  display: none;
  position: relative;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--center {
    display: flex;
  }
}

.dropdown-menu-widget--nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.dropdown-menu-widget--element {
  list-style-type: none;
  width: 33%;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--element {
    margin-right: 20px;
    width: auto;
  }
  [dir='rtl'] .dropdown-menu-widget--element {
    margin-right: 0;
    margin-left: 20px;
  }
}

.dropdown-menu-widget--element:last-child {
  margin: 0;
}

.dropdown-menu-widget--link, .dropdown-menu-widget--current {
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #262626;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
  border: none;
  background-color: transparent;
}

.dropdown-menu-widget--link:focus, .dropdown-menu-widget--current:focus {
  outline: none;
}

.dropdown-menu-widget--link:hover, .dropdown-menu-widget--current:hover {
  cursor: default;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--link {
    transition: 0.4s all ease-in-out;
    padding-left: 0;
    position: relative;
  }
  .dropdown-menu-widget--link:after {
    content: ' ';
    display: block;
    width: 0;
    transition: 0.2s all ease-in-out;
    border-bottom: 4px solid RGB(var(--color-primary));
    height: 100%;
    position: absolute;
    left: 0;
    top: 18px;
    right: 0;
    cursor: pointer;
  }
  .dropdown-menu-widget--link:hover, .dropdown-menu-widget--link.active, .dropdown-menu-widget--link.current {
    color: var(--color-primary-hover);
    cursor: pointer;
  }
  .dropdown-menu-widget--link:hover:after, .dropdown-menu-widget--link.active:after, .dropdown-menu-widget--link.current:after {
    cursor: default;
    width: 100%;
  }
}

.dropdown-menu-widget--button {
  cursor: default;
}

.dropdown-menu-widget--button:hover, .dropdown-menu-widget--button.active {
  color: #262626;
  cursor: default;
}

.color--sampoernaRed .dropdown-menu-widget--current {
  color: RGB(var(--color-primary));
}

.dropdown-menu-widget--right {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-width: max-content;
  margin-left: auto;
  align-self: stretch;
}

[dir='rtl'] .dropdown-menu-widget--right {
  margin-left: 0;
  margin-right: auto;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--right .search {
    margin-top: 15px;
  }
}

.dropdown-menu-widget--right .market-search-menu .search-field-drop--field {
  border-left: none;
}

.dropdown-menu-widget--right .market-search-menu .search-field-drop--field input {
  border-bottom: 1px solid #8c8c8c;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--right .market-search-menu .search-field-drop--field input {
    padding-bottom: 10px;
    height: 25px;
  }
}

.dropdown-menu-widget--submenu {
  opacity: 0;
  visibility: hidden;
  background: #f6f6f6;
  position: absolute;
  top: calc(100% + 20px);
  width: 100vw;
  left: -2px;
  padding-left: 2px;
  right: 0;
  height: 0;
  z-index: -1;
  transition: 0.4s all ease-in-out;
}

.dropdown-menu-widget--submenu:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 35px;
  top: -20px;
}

.dropdown-menu-widget--submenu::after {
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #f6f6f6;
  content: '';
  position: absolute;
  transform: translate(-100%, 0);
}

.dropdown-menu-widget--submenu.active {
  opacity: 1;
  visibility: visible;
  z-index: 2001;
}

.dropdown-menu-widget--submenu.active .dropdown-menu-widget--submenu-link {
  opacity: 1;
  visibility: visible;
  z-index: 2001;
}

.dropdown-menu-widget--submenu-container {
  display: flex;
  gap: 80px;
  margin-top: 30px;
}

@media screen and (min-width: 1265px) and (max-width: 1600px) {
  .dropdown-menu-widget--submenu-container {
    max-width: 960px;
  }
}

.dropdown-menu-widget--submenu-link {
  display: inline-block;
  color: #000000;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: 0.4s all ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.dropdown-menu-widget--submenu-link::after {
  transition: 0.4s all ease-in-out;
  color: transparent;
  content: "";
  font-family: "icomoon";
  margin-left: 5px;
  font-size: 9px;
}

.dropdown-menu-widget--submenu-link:hover, .dropdown-menu-widget--submenu-link:focus {
  color: RGB(var(--color-primary));
}

.dropdown-menu-widget--submenu-link:hover:after, .dropdown-menu-widget--submenu-link:focus:after {
  color: RGB(var(--color-primary));
}

.dropdown-menu-widget--submenu-list {
  display: flex;
  flex-flow: column;
}

.dropdown-menu-widget--world-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 0;
  background-color: #f6f6f6;
  transition: 0.4s all ease-in-out;
}

.dropdown-menu-widget--world-dropdown.active {
  opacity: 1;
  visibility: visible;
  z-index: 1002;
}

.dropdown-menu-widget--world-dropdown.active .country-info--background-img {
  opacity: 1;
  visibility: visible;
  transition: 1s all ease-in-out;
}

.dropdown-menu-widget--right .mobile-nav--menu {
  padding: 0;
}

.dropdown-menu-widget--right .mobile-nav--panel-title, .dropdown-menu-widget--right .mobile-nav--biglink, .dropdown-menu-widget--right .mobile-nav--link {
  background: transparent;
  border: none;
  font-weight: normal;
}

.dropdown-menu-widget--right .mobile-nav--link-button {
  position: relative;
  width: 100px;
  float: right;
}

.dropdown-menu-widget--right .mobile-nav--panel-title {
  position: relative;
  color: RGB(var(--color-primary));
  border-bottom: 3px solid RGB(var(--color-primary));
  width: fit-content;
  font-weight: 700;
  padding-bottom: 15px;
  margin: 0;
}

.dropdown-menu-widget--right .mobile-nav--panel-title:before {
  content: none;
}

.dropdown-menu-widget--right .mobile-nav--panel:not(.mobile-nav--panel[data-name='markets']) {
  height: 100vh;
}

.dropdown-menu-widget--right .mobile-nav--panel:not(.mobile-nav--submenu-panel) {
  padding: 30px 30px 80px 30px;
}

.dropdown-menu-widget--right .mobile-nav--link {
  position: relative;
  width: 100%;
  font-weight: normal;
}

.dropdown-menu-widget--right .mobile-nav--link.is-next {
  cursor: pointer;
}

.dropdown-menu-widget--right .mobile-nav--link.is-next:after {
  right: 0;
  position: absolute;
}

.dropdown-menu-widget--right .mobile-nav--link.is-back {
  transition: 0.4s all ease-in-out;
  width: fit-content;
  font-size: 16px;
  text-transform: none;
  margin-bottom: 25px;
  cursor: pointer;
  margin-left: 20px;
  top: 15px;
}

.dropdown-menu-widget--right .mobile-nav--link.is-back.hidden {
  opacity: 0;
  visibility: hidden;
}

.dropdown-menu-widget--right .mobile-nav--link.is-back:before {
  content: "";
  margin-right: 12px;
  position: relative;
}

.dropdown-menu-widget--right .mobile-nav--panels {
  margin-top: 10px;
  border-bottom: 1px solid #ededed;
}

.dropdown-menu-widget--right .mobile-nav--panel {
  height: calc(100vh - 40px);
}

.dropdown-menu-widget--right .mobile-nav--panel .market-search-menu--all-market {
  display: none;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--right .mobile-nav--panel .market-search-menu--all-market {
    margin-left: -20px;
    background: RGB(var(--color-primary));
    height: 50px;
    width: calc(100% + 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
  }
  [dir='rtl'] .dropdown-menu-widget--right .mobile-nav--panel .market-search-menu--all-market {
    margin-right: -20px;
    margin-left: 0;
  }
}

.dropdown-menu-widget--right .mobile-nav--panel .market-search-menu .search-field-drop--suggestions {
  height: calc(100vh - 200px);
}

.dropdown-menu-widget--right .mobile-nav--submenu-list {
  height: 100vh;
  background-color: #f6f6f6;
  padding: 30px 0 50px 30px;
  overflow-y: auto;
}

.dropdown-menu-widget--right .mobile-nav--submenu-panel-title {
  color: RGB(var(--color-primary));
  border-bottom: 3px solid RGB(var(--color-primary));
  width: fit-content;
  margin-left: 30px;
  padding: 15px 0 15px 0;
  font-weight: 700;
}

.dropdown-menu-widget--right .mobile-nav--link-markets {
  display: flex;
  align-items: center;
}

.dropdown-menu-widget--right .mobile-nav--link-markets:before {
  content: url("/assets-v3/images/world.svg");
  font-size: 25px;
  margin-right: 15px;
}

.dropdown-menu-widget--right .mobile-nav--link-markets:after {
  top: 50%;
  transform: translateY(-50%);
}

.has-sub .dropdown-menu-widget--submenu {
  top: calc(100% + 22px);
}

.basic-page-info-widget--block {
  margin-top: 10px;
}

.basic-page-info-widget--table {
  width: 100%;
}

.basic-page-info-widget--header {
  border-bottom: 1px solid #959595;
  background-color: RGB(var(--color-primary));
}

.basic-page-info-widget--column {
  color: #fff;
  padding: 10px;
}

.basic-page-info-widget--row {
  border-bottom: 1px solid #959595;
}

.basic-page-info-widget--cell {
  padding: 10px;
  font-size: 14px;
}

.pop-up-widget {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2002;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.pop-up-widget.hidden {
  display: none;
}

@media screen and (min-width: 960px) {
  .pop-up-widget {
    align-items: center;
  }
}

.pop-up-widget.no-display {
  display: none;
}

.pop-up-widget.closing {
  cursor: wait;
}

.pop-up-widget.closing * {
  pointer-events: none;
}

.pop-up-widget--background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.77);
  transition: 0.4s background ease-in-out;
}

.pop-up-widget--content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  max-width: 800px;
}

@media screen and (max-width: 960px) {
  .pop-up-widget--content {
    width: 100vw;
  }
}

.pop-up-widget--close {
  margin-left: auto;
}

.pop-up-widget--top {
  position: absolute;
  left: 0;
  right: 0;
  top: -45px;
}

.pop-up-widget--slot {
  padding-top: 30px;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 110px);
  border-top: 13px solid RGB(var(--color-primary));
  border-bottom: 13px solid RGB(var(--color-primary));
  scrollbar-color: RGB(var(--color-primary)) #f6f6f6;
  scrollbar-width: thin;
}

@media screen and (max-width: 960px) {
  .pop-up-widget--slot {
    height: calc(100vh - 45px);
  }
}

.pop-up-widget--slot::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #f6f6f6;
}

.pop-up-widget--slot::-webkit-scrollbar-thumb {
  background-color: RGB(var(--color-primary));
}

.pop-up-widget--slot::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

.pop-up-widget--subtitle {
  padding: 0 10px;
}

@media screen and (min-width: 960px) {
  .pop-up-widget--subtitle {
    padding: 0 100px;
  }
}

.pop-up-widget--title {
  padding: 0 10px;
  margin: 20px 0;
}

@media screen and (min-width: 960px) {
  .pop-up-widget--title {
    padding: 0 100px;
  }
}

.pop-up-widget--image img {
  width: auto;
  height: auto;
}

.investor-cta-newsletter {
  position: relative;
  border: none !important;
}

.investor-cta-newsletter .investor-cta {
  flex-direction: column !important;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  margin: 16px auto 8px 0;
}

.investor-cta-newsletter .investor-cta--newsletter-button {
  margin-top: 16px !important;
  color: RGB(var(--color-primary)) !important;
}

@media screen and (max-width: 700px) {
  .investor-cta-newsletter .investor-cta--newsletter-button {
    margin: 8px auto 100px 0 !important;
  }
}

.animations-widget {
  position: relative;
}

.animations-widget--content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.animations-widget--content-center-rive {
  margin: 100px;
  transform: none;
  text-align: center;
  font-weight: 700;
}

.animations-widget--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.animations-widget--rive {
  margin-left: -20px;
  margin-right: -20px;
}

@media screen and (min-width: 768px) {
  .animations-widget--rive {
    margin-left: 0;
    margin-right: 0;
  }
}

.animations-widget--rive #riveCanvas {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}

@media screen and (min-width: 768px) {
  .animations-widget--rive #riveCanvas {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 480px) {
  .animations-widget--rive #riveCanvas {
    width: 100vw;
    height: 100%;
  }
}

.animations-widget--rive-fallback {
  display: none;
}

.animations-widget--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.animations-widget--close {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.animations-widget--close::after {
  content: "";
  font-family: "icomoon";
}

.sfPageEditor .animations-widget--rive {
  width: 80vw;
  margin-left: calc(-1 * (80vw - 100% + 60px) / 2);
}

.newsroom--player {
  width: 100%;
  height: 100%;
}

.newsroom--container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

.newsroom--share-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  z-index: 2999;
}

.newsroom--share-overlay.active {
  display: block;
}

.newsroom--share {
  position: fixed;
  z-index: 3002;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all ease-in-out;
}

.newsroom--share.active {
  opacity: 1;
  visibility: visible;
}

.newsroom--share-buttons {
  height: 50px;
}

.newsroom--share-buttons button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  font-size: 0;
  color: transparent;
}

@media screen and (min-width: 1200px) {
  .newsroom--share-buttons button:hover:before {
    color: #fff;
    background-color: RGB(var(--color-primary));
  }
}

.newsroom--share-buttons button:before {
  width: 50px;
  height: 50px;
  display: flex;
  margin-right: 10px;
  border-radius: 100%;
  font-family: "icomoon";
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  color: #fff;
  background-color: RGB(var(--color-primary));
}

@media screen and (min-width: 1200px) {
  .newsroom--share-buttons button:before {
    background-color: #f6f6f6;
    color: #6e6e6e;
    margin-bottom: 20px;
  }
}

.newsroom--share-copy-container {
  position: relative;
  display: inline-block;
}

.newsroom--share-copy-container p {
  position: absolute;
  top: 0;
  left: 60px;
  background: #6e6e6e;
  padding: 10px;
  opacity: 0;
  color: #fff;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  border-radius: 10px;
  width: auto;
  white-space: nowrap;
  pointer-events: none;
}

.newsroom--share-copy-container p:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #6e6e6e;
  transform: translateY(-50%);
}

.newsroom--share-copy-container button:hover + p {
  opacity: 1;
  transform: translateY(0);
}

.newsroom--share-facebook:before {
  content: "";
  font-size: 16px;
}

.newsroom--share-copy:before {
  content: "";
}

.newsroom--share-twitter:before {
  content: "";
}

.newsroom--share-linkedin:before {
  content: "";
}

/*
Styles for the newsroom share element on screens with a minimum aspect ratio of 31:40:
	Calculates the height and width based on dynamic values and aspect ratio
	Uses the custom variable --i-amphtml-story-desktop-one-panel-height to determine the height
	Sets a fixed top position and border radius 
*/
@media (min-aspect-ratio: 31 / 40) {
  .newsroom--share {
    --i-amphtml-story-desktop-one-panel-height: calc(calc(100vh) - (max(74px, 8.25vh) * 2));
    width: calc(var(--i-amphtml-story-desktop-one-panel-height) * (69 / 116));
    height: calc(var(--i-amphtml-story-desktop-one-panel-height) + 2px);
    top: 75px;
    border-radius: 20px;
  }
}

.image-fade {
  margin: 0 -20px;
  position: relative;
}

.image-fade--container {
  position: relative;
  text-align: center;
}

.image-fade--item {
  height: 100vh;
  width: 100%;
}

.image-fade--wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.download-pop-up {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  background-color: RGB(var(--color-primary));
  right: 0;
  z-index: 1001;
  padding: 15px;
  width: 300px;
  transition: all 0.3s ease-in-out;
}

.download-pop-up.gray {
  background-color: #ebebeb;
}

.download-pop-up.gray .modal--close-button:before {
  background-color: #ebebeb;
}

.download-pop-up.gray .modal--close-button:after {
  color: #262626;
}

.download-pop-up.gray .modal--close-button:hover::before {
  background-color: #ebebeb;
}

.download-pop-up.gray .modal--close-button:focus-visible {
  border: 1.5px solid #262626;
  border-radius: 4px;
}

.download-pop-up.gray .modal--close-button:focus-visible::before {
  background-color: transparent;
}

.download-pop-up.hidden, .download-pop-up.closed {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

.download-pop-up--title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.gray .download-pop-up--title {
  color: #262626;
}

.download-pop-up--subtitle {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.gray .download-pop-up--subtitle {
  color: #959595;
}

.download-pop-up--download {
  color: RGB(var(--color-primary));
  background-color: #fff;
  display: flex;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
}

.gray .download-pop-up--download {
  background-color: RGB(var(--color-primary));
  color: #fff;
}

.gray .download-pop-up--download:after {
  border: 2px solid #fff;
  background-color: #fff;
  color: RGB(var(--color-primary));
}

.gray .download-pop-up--download:focus-visible {
  outline-color: #262626;
}

.download-pop-up--download:focus {
  outline: auto;
}

.download-pop-up--download:after {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "icomoon";
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid RGB(var(--color-primary));
  background-color: RGB(var(--color-primary));
  transition: 0.2s all ease-in-out;
  color: #fff;
  margin-left: 15px;
  padding-left: 1px;
}

.download-pop-up--container {
  display: flex;
  flex-flow: column;
  position: relative;
}

.download-pop-up .modal--close-button {
  align-self: flex-end;
  height: 20px;
  width: 20px;
  position: absolute;
  right: -2px;
  top: 0;
}

.download-pop-up .modal--close-button:focus-visible {
  border: 1.5px solid #fff;
  border-radius: 4px;
}

.download-pop-up .modal--close-button:focus-visible::before {
  background-color: RGB(var(--color-primary));
}

.download-pop-up .modal--close-button:hover::before {
  background-color: RGB(var(--color-primary));
}

.latest-article-pop-up {
  min-width: 350px;
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  background-color: RGB(var(--color-primary));
  right: 0;
  z-index: 1001;
  padding: 15px;
  width: 300px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.latest-article-pop-up.gray {
  background-color: #ebebeb;
}

.latest-article-pop-up.gray .latest-article-pop-up--title-wrapper--title {
  color: #262626;
}

.latest-article-pop-up.gray--category {
  color: #959595;
}

.latest-article-pop-up.gray .modal--close-button {
  align-items: self-start;
}

.latest-article-pop-up.gray .modal--close-button:before {
  background-color: #ebebeb;
}

.latest-article-pop-up.gray .modal--close-button:after {
  color: RGB(var(--color-primary));
}

.latest-article-pop-up.gray .modal--close-button:hover::before {
  background-color: #ebebeb;
}

.latest-article-pop-up.gray .modal--close-button:focus-visible {
  border: 1.5px solid #262626;
  border-radius: 4px;
}

.latest-article-pop-up.gray .modal--close-button:focus-visible::before {
  background-color: transparent;
}

.latest-article-pop-up.hidden, .latest-article-pop-up.closed {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

.latest-article-pop-up .modal--close-button:after {
  font-size: 20px;
}

.latest-article-pop-up--category {
  color: #fff;
}

.gray .latest-article-pop-up--category {
  color: #959595;
}

.latest-article-pop-up--description {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}

.latest-article-pop-up .modal--close-button:hover::before,
.latest-article-pop-up .modal--close-button:focus::before {
  background-color: RGB(var(--color-primary));
}

.latest-article-pop-up .latest-article-pop-up--title-wrapper {
  display: flex;
}

.latest-article-pop-up .latest-article-pop-up--title-wrapper--title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.gray .latest-article-pop-up .latest-article-pop-up--title-wrapper--title {
  color: #262626;
}

.latest-article-pop-up .latest-article-pop-up--title-wrapper--close {
  width: 24px;
  height: 24px;
  top: 4px;
  right: 5px;
  margin-left: auto;
}

.latest-article-pop-up .latest-article-pop-up--content {
  margin-top: 3rem;
  background: #fff;
  padding: 10px;
  line-height: 24px;
}

.latest-article-pop-up .latest-article-pop-up--content--reading-time {
  color: #959595;
}

.latest-article-pop-up .latest-article-pop-up--content-cta {
  text-align: right;
  text-transform: uppercase;
}

.latest-article-pop-up .latest-article-pop-up--content-cta--label {
  position: relative;
  top: 3px;
  right: 5px;
  letter-spacing: 0.15em;
  font-size: 15px;
  font-weight: 700;
}

.nav-layout {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2001;
  padding: 0 20px;
  background: white;
  width: 100%;
  top: 0;
  left: 0;
  height: auto;
  flex-flow: column;
}

.nav-layout.has-sub {
  justify-content: space-between;
  padding-bottom: 0;
}

.nav-layout.has-market-redirect {
  transform: translateY(148px);
}

@media screen and (min-width: 960px) {
  .nav-layout.has-market-redirect {
    transform: translateY(120px);
  }
}

@media screen and (min-width: 1200px) {
  .nav-layout.has-market-redirect {
    transform: translateY(80px);
  }
}

@media screen and (min-width: 1265px) {
  .nav-layout {
    padding: 20px 0 10px 0;
    transition: 0.6s transform ease-in-out, 0.6s opacity ease-in-out;
  }
  body.scrolled .nav-layout {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.nav-layout--container {
  align-items: center;
  position: static;
  margin: 8px auto;
  display: flex;
  width: 100%;
  z-index: 1100;
}

@media screen and (min-width: 1265px) {
  .nav-layout--container {
    width: calc(100% - 40px);
  }
}

.nav-layout--grow {
  display: flex;
  flex-grow: 1;
}

@media screen and (min-width: 1265px) {
  .nav-layout--grow {
    align-items: flex-start;
  }
}

.nav-layout--content {
  position: relative;
  display: flex;
  align-self: stretch;
}

.nav-layout--sub-nav {
  width: 100%;
}

.footer-layout {
  position: relative;
  z-index: 1000;
  background: #f6f6f6;
  padding: 40px 20px 50px;
  margin: 0 -20px;
}

@media screen and (min-width: 640px) {
  .footer-layout {
    padding: 80px 0 50px;
  }
}

@media screen and (min-width: 1200px) {
  .footer-layout {
    margin: 0;
  }
}

@supports (-webkit-touch-callout: none) {
  .shorthand-embed-body .footer-layout {
    z-index: 100;
  }
}

.footer-layout .footer-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-areas: "social" "small1" "small2" "small3" "small4" "nav";
}

@media screen and (min-width: 480px) {
  .footer-layout .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "social social" "small1 small2" "small3 small4 " "nav nav";
  }
}

@media screen and (min-width: 640px) {
  .footer-layout .footer-grid {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "small1 small2 small3" "small4 social social" "nav nav nav";
  }
}

@media screen and (min-width: 960px) {
  .footer-layout .footer-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "social social social social" "small1 small2 small3 small4" "nav    nav    nav nav";
  }
}

@media screen and (min-width: 1200px) {
  .footer-layout .footer-grid {
    margin: 0 auto;
    grid-row-gap: 70px;
    grid-template-columns: repeat(4, 1fr) auto;
    grid-template-areas: "small1 small2 small3 small4 social" "nav    nav    nav    nav        nav";
    padding: 0;
  }
}

.footer-layout--small1 {
  grid-area: small1;
}

.footer-layout--small2 {
  grid-area: small2;
}

.footer-layout--small3 {
  grid-area: small3;
}

.footer-layout--small4 {
  grid-area: small4;
}

.footer-layout--social {
  grid-area: social;
}

.footer-layout--nav {
  grid-area: nav;
}

.footer-layout .sf_colsIn > div,
.footer-layout .sf_colsIn > section,
.footer-layout .sf_colsIn > a {
  margin-top: 40px;
}

.footer-layout .sf_colsIn > div:first-child,
.footer-layout .sf_colsIn > section:first-child,
.footer-layout .sf_colsIn > a:first-child {
  margin-top: 0;
}

.footer-layout .social-media-links--content {
  justify-content: space-between;
}

@media screen and (min-width: 480px) {
  .footer-layout .social-media-links--content {
    justify-content: flex-start;
  }
}

.V3-Homepage-Header-V1 {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: minmax(515px, auto);
  margin-bottom: 50px;
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1 {
    grid-template: 20px/repeat(3, 1fr);
    grid-template-areas: "big big intro" "big big small1" "big big small2" "big big medium";
  }
}

@media screen and (min-width: 960px) {
  .V3-Homepage-Header-V1 {
    width: 100%;
    margin: 0 auto 90px auto;
    grid-template: minmax(20px, auto)/repeat(5, 1fr);
    grid-template-areas: "big big big intro  intro" "big big big small1 small2" "big big big medium medium";
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1--big {
    grid-area: big;
    margin: 0 0 0 -20px;
  }
}

@media screen and (min-width: 960px) {
  .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1210px) {
  .V3-Homepage-Header-V1--big {
    margin-left: calc((100vw - 1170px) / 2 * -1);
  }
  .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-left: calc((100vw - 1170px) / 2);
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 2000px) {
  .V3-Homepage-Header-V1--big {
    margin-left: 0;
  }
  .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-left: 20px;
  }
}

.V3-Homepage-Header-V1--intro {
  color: #6e6e6e;
  font-size: 18px;
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1--intro {
    grid-area: intro;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V1--medium {
    grid-area: medium;
  }
}

@media screen and (min-width: 960px) {
  .V3-Homepage-Header-V1--medium .medium-text-on-image {
    padding-bottom: 50px;
  }
}

.V3-Homepage-Header-V2 {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: minmax(515px, auto);
  margin-bottom: 50px;
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2 {
    grid-template: 20px/repeat(3, 1fr);
    grid-template-areas: "big big intro" "big big small1" "big big small2" "big big medium";
  }
}

@media screen and (min-width: 960px) {
  .V3-Homepage-Header-V2 {
    width: 100%;
    margin: 0 auto 90px auto;
    grid-template: minmax(20px, auto)/repeat(5, 1fr);
    grid-template-areas: "big big big intro  intro" "big big big medium medium" "big big big small1 small2";
  }
}

.V3-Homepage-Header-V2.V3-modifier {
  grid-template-rows: repeat(2, auto);
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2.V3-modifier {
    grid-template-areas: "big big small1" "big big small2" "big big medium";
  }
}

@media screen and (min-width: 960px) {
  .V3-Homepage-Header-V2.V3-modifier {
    grid-template-areas: "big big big medium medium" "big big big small1 small2";
  }
}

.V3-Homepage-Header-V2--big {
  margin: 0 -20px;
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2--big {
    grid-area: big;
    margin: 0 0 0 -20px;
  }
}

@media screen and (min-width: 1210px) {
  .V3-Homepage-Header-V2--big {
    margin-left: calc((100vw - 1170px) / 2 * -1);
  }
  .V3-Homepage-Header-V2--big .large-image-text-on-image {
    padding-left: calc((100vw - 1170px) / 2);
  }
}

@media screen and (min-width: 2000px) {
  .V3-Homepage-Header-V2--big {
    margin-left: 0;
  }
  .V3-Homepage-Header-V2--big .large-image-text-on-image {
    padding-left: 30px;
  }
}

.V3-modifier .V3-Homepage-Header-V2--big {
  margin: 0;
}

.V3-modifier .V3-Homepage-Header-V2--big .large-image-text-on-image {
  padding-left: 30px;
}

.V3-Homepage-Header-V2--intro {
  color: #6e6e6e;
  font-size: 18px;
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2--intro {
    grid-area: intro;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Homepage-Header-V2--medium {
    grid-area: medium;
  }
}

.V4-modifier.V3-Homepage-Header-V1 {
  grid-template-rows: minmax(100vw, auto);
  margin-left: -4px;
  margin-right: -4px;
  --paddingCompensation: 4px;
}

@media screen and (min-width: 640px) {
  .V4-modifier.V3-Homepage-Header-V1 {
    grid-gap: 1px;
    width: calc(100% + 24px);
    grid-template: repeat(3, 1fr)/repeat(3, 1fr);
    grid-template-areas: 'big big small1' 'big big small2' 'big big medium';
  }
}

@media screen and (min-width: 960px) {
  .V4-modifier.V3-Homepage-Header-V1 {
    margin: 0 -28px 90px;
    width: calc(100% + 48px);
    grid-template: repeat(2, 1fr)/repeat(5, 1fr);
    grid-template-areas: 'big big big small1 small2' 'big big big medium medium';
  }
}

@media screen and (min-width: 1200px) {
  .V4-modifier.V3-Homepage-Header-V1 {
    --paddingCompensation: 0;
    margin: 0 0 90px;
    width: 100%;
    min-height: 40vw;
  }
}

@media screen and (min-width: 2600px) {
  .V4-modifier.V3-Homepage-Header-V1 {
    min-height: 468px;
    margin: 0 auto 90px;
    max-width: 1170px;
  }
}

@media screen and (max-width: 639px) {
  .V4-modifier.V3-Homepage-Header-V1 {
    position: relative;
    padding: 0;
    padding-bottom: 20px;
  }
  .V4-modifier.V3-Homepage-Header-V1::before {
    content: '';
    position: absolute;
    background: #f6f6f6;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
  }
  .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image {
    flex-flow: row;
    justify-content: flex-start;
    padding: 0;
  }
  .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .back-picture {
    position: relative;
    margin-right: 25px;
    width: 100px;
    height: 100px;
  }
  .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .back-picture img {
    transform: none;
  }
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .back-picture {
    margin-left: 25px;
    margin-right: 0;
  }
  .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .box-article-content--category, .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .box-article-content--reading, .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .box-article-content--link {
    color: #6e6e6e;
  }
  .V4-modifier.V3-Homepage-Header-V1 .small-text-on-image .box-article-content--title {
    color: #393939;
  }
  .V4-modifier.V3-Homepage-Header-V1 .medium-text-on-image {
    min-height: 166px;
  }
}

.V4-modifier.V3-Homepage-Header-V1 .sf_colsIn > * {
  outline: none;
  border: 4px solid transparent;
}

.V4-modifier.V3-Homepage-Header-V1 .sf_colsIn > *:focus {
  border-color: #262626;
}

.V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big {
  margin: 0 -20px;
}

.V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big.sf_colsIn > * {
  border-width: 4px 0 4px 0;
}

@media screen and (min-width: 640px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big {
    margin-right: 0;
  }
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big {
    margin: 0 -20px;
    margin-left: 0;
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big.sf_colsIn > * {
    border-width: 4px 4px 4px 0;
  }
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big.sf_colsIn > * {
    border-width: 4px 4px 4px 4px;
  }
}

@media screen and (min-width: 960px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big {
    margin: 0;
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-bottom: 50px;
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .box-article-content--title {
    font-size: 42px;
    line-height: 46px;
  }
}

@media screen and (min-width: 1210px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-text-on-image {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 2599px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-left: calc((100vw - 1170px) / 4);
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .box-article-content--title {
    font-size: 44px;
    line-height: 48px;
  }
}

@media screen and (min-width: 1200px) {
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-left: 0;
    padding-right: calc((100vw - 1170px) / 4);
  }
}

@media screen and (min-width: 960px) and (max-width: 1265px) {
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-right: 50px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2599px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .box-article-content--title {
    font-size: 64px;
    line-height: 68px;
  }
  .full-width .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .box-article-content--title {
    font-size: 44px;
    line-height: 48px;
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .box-article-content--description {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 2600px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big .large-image-text-on-image {
    padding-left: 20px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2599px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium .box-article-content--title {
    font-size: 44px;
    line-height: 48px;
  }
  .full-width .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium .box-article-content--title {
    font-size: 28px;
    line-height: 34px;
  }
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium .box-article-content--description {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2599px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1 .box-article-content--title,
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small2 .box-article-content--title {
    font-size: 28px;
    line-height: 34px;
  }
}

@media screen and (min-width: 640px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium.sf_colsIn > *,
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1.sf_colsIn > *,
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small2.sf_colsIn > * {
    border-width: 4px 0 4px 4px;
  }
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium.sf_colsIn > *, [dir='rtl']
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1.sf_colsIn > *, [dir='rtl']
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small2.sf_colsIn > * {
    border-width: 4px;
    border-left-width: 0;
  }
}

@media screen and (min-width: 960px) {
  .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1.sf_colsIn > * {
    border-width: 4px 4px 4px 4px;
  }
  [dir='rtl'] .V4-modifier.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1.sf_colsIn > * {
    border-width: 4px;
  }
}

.V3-Article-collage-V1 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto 50px;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1 {
    grid-template: 1fr/repeat(3, 1fr);
    grid-template-areas: "big     big    small1" "big     big    small2" "medium  small3 small4";
  }
}

@media screen and (min-width: 960px) {
  .V3-Article-collage-V1 {
    margin-bottom: 90px;
    grid-template: 1fr 1fr/repeat(5, 1fr);
    grid-template-areas: "big big small1 medium medium" "big big small2 small3 small4";
  }
}

.V3-Article-collage-V1--big {
  order: -2;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--big {
    grid-area: big;
    order: 0;
  }
}

@media screen and (max-width: 639px) {
  .V3-Article-collage-V1--big .large-image-text-on-image-widget {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    min-height: 80vw;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--small3 {
    grid-area: small3;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--small4 {
    grid-area: small4;
  }
}

.V3-Article-collage-V1--medium {
  order: -1;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V1--medium {
    grid-area: medium;
    order: 0;
  }
}

.V3-Article-collage-V2 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto 50px;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2 {
    grid-template: 1fr/repeat(3, 1fr);
    grid-template-areas: "big     big    small1" "big     big    small2" "small3  small4  medium";
  }
}

@media screen and (min-width: 960px) {
  .V3-Article-collage-V2 {
    margin-bottom: 90px;
    grid-template: 1fr 1fr/repeat(5, 1fr);
    grid-template-areas: "big big small1 small3 small4" "big big small2 medium medium";
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--big {
    grid-area: big;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--small3 {
    grid-area: small3;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--small4 {
    grid-area: small4;
  }
}

.V3-Article-collage-V2--medium {
  display: none;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V2--medium {
    display: flex;
    grid-area: medium;
  }
}

.V3-Article-collage-V4 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto 50px;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4 {
    grid-template: 1fr/repeat(3, 1fr);
    grid-template-areas: "big big big" "small1 small2 small3" "small4 small5 small6";
  }
}

@media screen and (min-width: 960px) {
  .V3-Article-collage-V4 {
    margin-bottom: 90px;
    grid-template: 1fr 1fr/repeat(5, 1fr);
    grid-template-areas: "big big small1 small2 small3" "big big small4 small5 small6";
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--big {
    grid-area: big;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small3 {
    grid-area: small3;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small4 {
    grid-area: small4;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small5 {
    grid-area: small5;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V4--small6 {
    grid-area: small6;
  }
}

.V3-Article-collage-V5 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto 50px;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5 {
    grid-template: 1fr/repeat(3, 1fr);
    grid-template-areas: "small1  big     big" "small2  big     big" "small3  small4  medium";
  }
}

@media screen and (min-width: 960px) {
  .V3-Article-collage-V5 {
    margin-bottom: 90px;
    grid-template: 1fr 1fr/repeat(5, 1fr);
    grid-template-areas: "small1 medium medium big big" "small2 small3 small4 big big";
  }
}

.V3-Article-collage-V5--big {
  order: -2;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--big {
    order: 0;
    grid-area: big;
  }
}

@media screen and (max-width: 639px) {
  .V3-Article-collage-V5--big .large-image-text-on-image-widget {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    min-height: 80vw;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--small1 {
    grid-area: small1;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--small2 {
    grid-area: small2;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--small3 {
    grid-area: small3;
  }
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--small4 {
    grid-area: small4;
  }
}

.V3-Article-collage-V5--medium {
  order: -1;
}

@media screen and (min-width: 640px) {
  .V3-Article-collage-V5--medium {
    grid-area: medium;
    order: 0;
  }
}

@media screen and (min-width: 768px) {
  .lost-5-columns {
    display: flex;
    lost-utility: clearfix;
  }
  .lost-5-columns > div {
    display: flex;
    flex-flow: column wrap;
    lost-column: 1/5 5 20px flex;
  }
}

.layout-1-3-1 {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px;
}

.layout-1-3-1 > .sf_colsIn {
  width: 100%;
  margin-bottom: 50px;
}

.layout-1-3-1 > .sf_colsIn:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .layout-1-3-1 > .sf_colsIn {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 640px) {
  .layout-1-3-1 > .sf_colsIn {
    max-width: 690px;
  }
}

@media screen and (min-width: 1200px) {
  .layout-1-3-1 {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .layout-1-3-1 > .sf_colsIn {
    margin-bottom: 0;
  }
  .layout-1-3-1 > .sf_colsIn:nth-child(1), .layout-1-3-1 > .sf_colsIn:nth-child(3) {
    width: 18%;
  }
  .layout-1-3-1 > .sf_colsIn:nth-child(2) {
    width: 59%;
  }
}

.V3-3-centered {
  margin: 0 auto 50px;
}

.layout-4-1 {
  display: flex;
  flex-flow: column;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .layout-4-1 {
    margin-bottom: 90px;
  }
}

.layout-4-1 > .sf_colsIn {
  width: 100%;
}

.layout-4-1 > .sf_colsIn > a,
.layout-4-1 > .sf_colsIn > div,
.layout-4-1 > .sf_colsIn > section,
.layout-4-1 > .sf_colsIn > img {
  margin-bottom: 24px;
}

.layout-4-1 > .sf_colsIn > a:last-child,
.layout-4-1 > .sf_colsIn > div:last-child,
.layout-4-1 > .sf_colsIn > section:last-child,
.layout-4-1 > .sf_colsIn > img:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .layout-4-1 {
    flex-flow: row;
    justify-content: space-between;
  }
  .layout-4-1 > .sf_colsIn:first-child {
    width: 80%;
  }
  .layout-4-1 > .sf_colsIn:nth-child(2) {
    width: 18%;
  }
}

.layout-2-3 {
  display: flex;
  flex-flow: column;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .layout-2-3 {
    margin-bottom: 90px;
  }
}

.layout-2-3 > .sf_colsIn {
  width: 100%;
}

.layout-2-3 > .sf_colsIn:nth-child(1) {
  margin-bottom: 50px;
}

@media screen and (min-width: 960px) {
  .layout-2-3 > .sf_colsIn:nth-child(1) {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1200px) {
  .layout-2-3 > .sf_colsIn:nth-child(1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .layout-2-3 {
    flex-flow: row;
  }
  .layout-2-3 > .sf_colsIn:nth-child(1) {
    width: 40%;
    margin-bottom: 0;
  }
  .layout-2-3 > .sf_colsIn:nth-child(2) {
    width: 60%;
  }
}

.lost-centered-column {
  lost-utility: clearfix;
}

.lost-centered-column > div {
  max-width: 1024px;
  width: 100%;
  margin: auto;
}

.full-width {
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .full-width {
    margin-bottom: 90px;
  }
}

.full-width > .sf_colsIn {
  width: 100%;
}

.full-width-grey {
  display: flex;
  margin: 0 -20px 50px;
  padding-top: 50px;
  background-color: #f6f6f6;
}

@media screen and (min-width: 1200px) {
  .full-width-grey {
    margin: 0 0 90px 0;
  }
}

.full-width-grey > .sf_colsIn {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .full-width-grey > .sf_colsIn {
    padding: 0;
  }
}

.layout-3-columns {
  display: flex;
  flex-flow: column;
}

.layout-3-columns > .sf_colsIn {
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) {
  .layout-3-columns {
    flex-flow: row;
    justify-content: space-between;
  }
  .layout-3-columns > .sf_colsIn {
    width: calc(33% - 10px);
    margin-bottom: 0;
  }
}

.layout-4-columns {
  display: flex;
  flex-flow: column;
  padding-bottom: 50px;
}

.layout-4-columns > .sf_colsIn {
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) {
  .layout-4-columns {
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .layout-4-columns > .sf_colsIn {
    width: calc(50% - 14px);
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 960px) {
  .layout-4-columns {
    padding-bottom: 90px;
  }
  .layout-4-columns > .sf_colsIn {
    width: calc(25% - 14px);
    margin-bottom: 0;
  }
}

.layout-5-columns {
  --columns-count: 1;
  display: grid;
  grid-template: auto/repeat(var(--columns-count), 1fr);
  grid-gap: 20px;
}

@media screen and (min-width: 640px) {
  .layout-5-columns {
    --columns-count: 2;
  }
}

@media screen and (min-width: 960px) {
  .layout-5-columns {
    --columns-count: 3;
  }
}

@media screen and (min-width: 1200px) {
  .layout-5-columns {
    --columns-count: 5;
  }
}

.V3-Job-Search-Layout {
  margin: 0 auto 50px;
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 700px) {
  .V3-Job-Search-Layout {
    flex-flow: row;
  }
}

@media screen and (min-width: 960px) {
  .V3-Job-Search-Layout {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 640px) {
  .V3-Job-Search-Layout > .sf_colsIn {
    padding: 0 30px;
  }
}

@media screen and (min-width: 700px) {
  .V3-Job-Search-Layout > .sf_colsIn {
    width: 50%;
    padding: 0;
  }
}

.V3-Job-Search-Layout > .sf_colsIn:first-child {
  margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .V3-Job-Search-Layout > .sf_colsIn:first-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 700px) {
  .V3-Job-Search-Layout > .sf_colsIn:last-child {
    padding-left: 30px;
  }
}

@media screen and (min-width: 960px) {
  .V3-Job-Search-Layout > .sf_colsIn:last-child {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .V3-Job-Search-Layout > .sf_colsIn:last-child {
    padding-left: 140px;
  }
}

.V3-category-list-4-1 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 50px;
  flex-wrap: wrap;
}

@media screen and (min-width: 960px) {
  .V3-category-list-4-1 {
    margin-bottom: 90px;
  }
}

.V3-category-list-4-1 .col1 {
  width: 100%;
  margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
  .V3-category-list-4-1 .col1 {
    width: 80%;
  }
}

.V3-category-list-4-1 .col2 {
  width: 100%;
  display: grid;
  grid-gap: 24px;
}

.V3-category-list-4-1 .col2 .highlight {
  min-height: 300px;
}

@media screen and (min-width: 700px) {
  .V3-category-list-4-1 .col2 {
    grid-template-columns: 1fr 1fr;
  }
  .V3-category-list-4-1 .col2 .editors-pick-widget {
    grid-column: 1 / -1;
  }
  .V3-category-list-4-1 .col2 .highlight {
    min-height: 330px;
  }
}

@media screen and (min-width: 960px) {
  .V3-category-list-4-1 .col2 .highlight {
    min-height: 370px;
  }
}

@media screen and (min-width: 1200px) {
  .V3-category-list-4-1 .col2 {
    width: calc(20% - 24px);
    grid-template-columns: 1fr;
  }
  .V3-category-list-4-1 .col2 .highlight {
    min-height: 275px;
  }
}

.V3-Stick-element-3 {
  margin: 0 -20px 50px;
}

@media screen and (min-width: 960px) {
  .V3-Stick-element-3 {
    margin-bottom: 90px;
    display: grid;
    grid-template: 1fr/repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .V3-Stick-element-3 {
    margin-left: 0;
    margin-right: 0;
  }
}

.V3-Stick-element-3 > div {
  display: flex;
  height: 0;
  position: relative;
  padding-bottom: 100%;
}

@media screen and (min-width: 960px) {
  .V3-Stick-element-3 > div {
    padding-bottom: 120%;
  }
}

.V3-Stick-element-3 > div > a,
.V3-Stick-element-3 > div > section,
.V3-Stick-element-3 > div > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.V3-50-50 {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto 24px;
  flex-wrap: wrap;
}

.V3-50-50--space {
  width: 100%;
}

.V3-50-50--space:first-child {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .V3-50-50--space {
    width: calc(50% - 12px);
    margin-bottom: 0;
  }
}

.V3-50-50--space .latest-press-releases {
  width: auto;
  padding-left: 30px;
}

.V3-50-50--space > a,
.V3-50-50--space > div,
.V3-50-50--space > section,
.V3-50-50--space > img,
.V3-50-50--space > p {
  width: 100%;
}

.V3-50-50-blue {
  background: RGB(var(--color-primary));
  display: flex;
  padding: 90px 0;
  margin: -20px;
}

@media screen and (min-width: 1265px) {
  .V3-50-50-blue {
    margin: 0;
    padding: 95px 0 130px;
  }
}

.V3-50-50-blue--content {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 640px) {
  .V3-50-50-blue--content {
    justify-content: space-between;
    flex-flow: row;
  }
}

@media screen and (min-width: 1265px) {
  .V3-50-50-blue--content {
    padding: 0;
  }
}

@media screen and (max-width: 639px) {
  .V3-50-50-blue .V3-50-50--space:first-child {
    padding-bottom: 90px;
  }
}

.V3-awareness-content-banner {
  --side-padding: 20px;
  --top-padding: 0;
  --bottom-padding: 45px;
  --gradient-height: 170px;
  width: calc(100% + 2 * var(--side-padding));
  position: relative;
  margin: 0 calc(0px - var(--side-padding));
  padding: var(--top-padding) var(--side-padding) var(--bottom-padding);
}

@media screen and (min-width: 700px) {
  .V3-awareness-content-banner {
    --top-padding: 60px;
  }
}

@media screen and (min-width: 960px) {
  .V3-awareness-content-banner {
    --top-padding: 210px;
    --bottom-padding: 90px;
    --gradient-height: 330px;
  }
}

@media screen and (min-width: 1265px) {
  .V3-awareness-content-banner {
    --side-padding: 0;
  }
}

.V3-awareness-content-banner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: var(--gradient-height);
  background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
  z-index: 1;
  pointer-events: none;
}

.V3-awareness-content-banner--back-picture {
  position: absolute;
  top: 50px;
  right: var(--side-padding);
  width: 50%;
  height: 300px;
}

@media screen and (min-width: 700px) {
  .V3-awareness-content-banner--back-picture {
    height: calc(100% - var(--bottom-padding) - 50px);
    right: 48%;
    width: 25%;
  }
}

.V3-awareness-content-banner--back-picture .still-picture {
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  right: 0;
  object-fit: contain;
}

.V3-awareness-content-banner--title h1,
.V3-awareness-content-banner--title h2 {
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
}

@media screen and (min-width: 700px) {
  .V3-awareness-content-banner--title h1,
  .V3-awareness-content-banner--title h2 {
    font-size: 57px;
    line-height: 69px;
  }
}

.V3-awareness-content-banner--title [dir='rtl'] {
  width: auto;
  max-width: 400px;
  word-break: break-word;
}

.V3-awareness-content-banner--title [dir='rtl']:after {
  content: '\200E‎';
}

.V3-awareness-content-banner--title [dir='rtl']:before {
  content: '\200E‎';
}

.V3-awareness-content-banner--column {
  position: relative;
  width: 100%;
  z-index: 2;
}

.V3-awareness-content-banner--column:first-child {
  margin-bottom: 24px;
}

@media screen and (max-width: 699px) {
  .V3-awareness-content-banner--column:first-child {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 350px;
    width: 50%;
  }
}

@media screen and (min-width: 700px) {
  .V3-awareness-content-banner--column {
    width: calc(50% - 12px);
    margin-bottom: 0;
  }
}

.V3-awareness-content-banner--columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

.V3-30-60 {
  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .V3-30-60 {
    margin-bottom: 90px;
  }
}

.V3-30-60 > .sf_colsIn {
  width: 100%;
}

.V3-30-60 > .sf_colsIn > a,
.V3-30-60 > .sf_colsIn > div,
.V3-30-60 > .sf_colsIn > section,
.V3-30-60 > .sf_colsIn > img {
  margin-bottom: 24px;
}

.V3-30-60 > .sf_colsIn > a:nth-child(2),
.V3-30-60 > .sf_colsIn > div:nth-child(2),
.V3-30-60 > .sf_colsIn > section:nth-child(2),
.V3-30-60 > .sf_colsIn > img:nth-child(2) {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .V3-30-60 {
    flex-flow: row;
    justify-content: space-between;
  }
  .V3-30-60 > .sf_colsIn:first-child {
    width: 30%;
  }
  .V3-30-60 > .sf_colsIn:nth-child(2) {
    width: 60%;
  }
}

@media screen and (min-width: 640px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 {
    margin: 0 -20px 90px;
    width: calc(100% + 40px);
    grid-template-areas: "small1 big big" "small2 big big" "medium big big";
  }
}

@media screen and (min-width: 960px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 {
    grid-template-areas: "small1 small2 big big big" "medium medium big big big";
  }
}

@media screen and (min-width: 1200px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 {
    margin: 0 0 90px;
  }
}

@media screen and (min-width: 640px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big {
    margin: 0;
  }
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--big.sf_colsIn > * {
    border-width: 4px 0 4px 4px;
  }
}

@media screen and (min-width: 640px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--medium.sf_colsIn > *,
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small1.sf_colsIn > *,
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small2.sf_colsIn > * {
    border-width: 4px 4px 4px 0;
  }
}

@media screen and (min-width: 960px) {
  .V4-modifier.mirrored.V3-Homepage-Header-V1 .V3-Homepage-Header-V1--small2.sf_colsIn > * {
    border-width: 4px 4px 4px 4px;
  }
}

.layout-80-15 {
  display: flex;
  flex-flow: column;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .layout-80-15 {
    margin-bottom: 90px;
  }
}

.layout-80-15 > .sf_colsIn {
  width: 100%;
}

.layout-80-15 > .sf_colsIn:nth-child(2) {
  display: none;
}

.layout-80-15 > .sf_colsIn > a,
.layout-80-15 > .sf_colsIn > div,
.layout-80-15 > .sf_colsIn > section,
.layout-80-15 > .sf_colsIn > img {
  margin-bottom: 24px;
}

.layout-80-15 > .sf_colsIn > a:last-child,
.layout-80-15 > .sf_colsIn > div:last-child,
.layout-80-15 > .sf_colsIn > section:last-child,
.layout-80-15 > .sf_colsIn > img:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .layout-80-15 {
    flex-flow: row;
    justify-content: space-between;
  }
  .layout-80-15 > .sf_colsIn:first-child {
    width: 80%;
  }
  .layout-80-15 > .sf_colsIn:nth-child(2) {
    display: block;
    width: calc(0.14103 * 100%);
  }
}

.layout-60-15 {
  display: flex;
  flex-flow: column;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .layout-60-15 {
    margin-bottom: 90px;
  }
}

.layout-60-15 > .sf_colsIn {
  width: 100%;
}

.layout-60-15 > .sf_colsIn:nth-child(2) {
  display: none;
}

.layout-60-15 > .sf_colsIn > a,
.layout-60-15 > .sf_colsIn > div,
.layout-60-15 > .sf_colsIn > section,
.layout-60-15 > .sf_colsIn > img {
  margin-bottom: 24px;
}

.layout-60-15 > .sf_colsIn > a:last-child,
.layout-60-15 > .sf_colsIn > div:last-child,
.layout-60-15 > .sf_colsIn > section:last-child,
.layout-60-15 > .sf_colsIn > img:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .layout-60-15 {
    flex-flow: row;
    justify-content: space-between;
  }
  .layout-60-15 > .sf_colsIn:first-child {
    width: 60%;
  }
  .layout-60-15 > .sf_colsIn:nth-child(2) {
    display: block;
    width: 14.10256%;
  }
}

.V3-50-40 {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto 24px;
  flex-wrap: wrap;
}

.V3-50-40 .sf_colsIn {
  width: 100%;
}

.V3-50-40 .sf_colsIn:first-child,
.V3-50-40 .sf_colsIn section {
  margin-bottom: 60px;
}

.V3-50-40 .sf_colsIn section:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  .V3-50-40 .sf_colsIn {
    margin-bottom: 0;
  }
  .V3-50-40 .sf_colsIn:first-child {
    width: calc(50% - 20px);
  }
  .V3-50-40 .sf_colsIn:last-child {
    margin-top: 45px;
    width: calc(50% - 20px);
  }
  .V3-50-40 .sf_colsIn section {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 960px) {
  .V3-50-40 .sf_colsIn:first-child {
    width: calc(50% - 12px);
  }
  .V3-50-40 .sf_colsIn:last-child {
    width: calc(40% - 12px);
  }
}

.V3-Sustainability {
  position: relative;
}

.full-width-blue {
  --background-white: RGB(var(--color-primary));
  --background-grey: white;
  --background-primary: white;
  --text-white: RGB(var(--color-primary));
  --text-black: white;
  --text-lighter-black: white;
  --text-grey: rgba(255,255,255, 0.5);
  --text-primary: white;
  display: flex;
  margin: 0 -20px 50px;
  padding-top: 50px;
  background-color: RGB(var(--color-primary));
}

@media screen and (min-width: 1200px) {
  .full-width-blue {
    margin: 0 0 90px 0;
  }
}

.full-width-blue > .sf_colsIn {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1200px) {
  .full-width-blue > .sf_colsIn {
    padding: 0;
  }
}

.article-intro {
  position: relative;
  --content-padding: 0;
  --before-content-padding: 20px;
  display: flex;
  flex-flow: column;
  margin-bottom: 55px;
}

@media screen and (min-width: 700px) {
  .article-intro {
    margin-bottom: 10px;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 55px 0;
  }
  .article-intro.is-colored:not(.is-portrait) {
    position: relative;
    --to-side-length: calc(-50vw + 50%);
    --to-center-length: 40%;
    --left-positionning: var(--to-center-length);
    --right-positionning: var(--to-side-length);
  }
  .article-intro.is-colored:not(.is-portrait)::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background: #f6f6f6;
    left: var(--left-positionning);
    right: var(--right-positionning);
  }
  .article-intro.is-colored:not(.is-portrait).is-right-aligned::before {
    left: var(--right-positionning);
    right: var(--left-positionning);
  }
  [dir="rtl"] .article-intro.is-colored:not(.is-portrait) {
    --left-positionning: var(--to-side-length);
    --right-positionning: var(--to-center-length);
  }
  .article-intro.is-portrait {
    align-items: center;
  }
}

@media screen and (min-width: 960px) {
  .article-intro {
    --content-padding: 50px;
    --before-content-padding: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .article-intro {
    --content-padding: 120px;
    --before-content-padding: 90px;
  }
}

.article-intro--title {
  font-size: 28px;
  margin-bottom: 20px;
  position: absolute;
  color: white;
  bottom: calc(100% + 15px);
  width: calc(100% - 40px);
  pointer-events: none;
}

@media screen and (min-width: 700px) {
  .article-intro--title {
    position: static;
    color: #393939;
    font-weight: normal;
  }
}

.article-intro--description.wysiwyg {
  margin-bottom: 25px;
}

.article-intro--description.wysiwyg,
.article-intro--description.wysiwyg p {
  line-height: 24px;
  font-size: 16px;
}

.article-intro--content, .article-intro--picture {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 700px) {
  .article-intro--content, .article-intro--picture {
    width: calc(50% - 20px);
    margin: 0;
  }
}

.article-intro--content {
  padding: 20px 20px 0;
}

@media screen and (min-width: 700px) {
  .article-intro--content {
    padding-top: 0;
    padding-left: var(--content-padding);
    --padded: -40px;
    --unpadded: 0;
    --start: var(--padded);
    --end: var(--unpadded);
  }
  .is-portrait.is-colored .article-intro--content {
    padding: var(--before-content-padding);
  }
  .is-portrait.is-colored .article-intro--content::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: var(--end);
    left: var(--start);
    background: #f6f6f6;
  }
  [dir="rtl"] .is-portrait.is-colored .article-intro--content::before {
    left: var(--end);
    right: var(--start);
  }
  .is-right-aligned .article-intro--content {
    padding-left: 0;
    padding-right: var(--content-padding);
    --end: var(--padded);
    --start: var(--unpadded);
  }
}

.article-intro--picture {
  margin: 0 -20px;
}

.article-intro--picture::before {
  content: "";
  display: block;
  padding-bottom: 87%;
}

@media screen and (min-width: 640px) {
  .article-intro--picture::before {
    padding-bottom: 50%;
  }
}

@media screen and (min-width: 700px) {
  .article-intro--picture::before {
    margin: 0;
    padding-bottom: 60%;
  }
  .is-portrait .article-intro--picture::before {
    padding-bottom: 107%;
  }
}

.article-intro--picture::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to top, var(--gradient-color), transparent);
}

@media screen and (min-width: 700px) {
  .article-intro--picture {
    margin: 0;
  }
  .is-right-aligned .article-intro--picture {
    order: 1;
  }
  .article-intro--picture::after {
    display: none;
  }
}

.article-intro--picture img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.drop-down {
  display: none;
  flex-flow: column;
  margin-top: 2px;
  --drop-height: 0px;
  position: relative;
  cursor: pointer;
}

@media screen and (min-width: 1265px) {
  .drop-down {
    display: flex;
  }
}

.drop-down--button {
  display: flex;
  font-size: 12px;
  font-family: var(--main-font);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  align-items: center;
  padding: 0 22px;
  height: 55px;
  position: relative;
  z-index: 1010;
  margin-bottom: -18px;
  word-break: keep-all;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.drop-down--button:focus {
  outline: none;
}

.drop-down--dot {
  width: 11px;
  height: 11px;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  margin-right: 7px;
}

[dir="rtl"] .drop-down--dot {
  margin-right: 0;
  margin-left: 7px;
}

.drop-down--arrow {
  margin-left: 11px;
}

[dir="rtl"] .drop-down--arrow {
  margin-left: 0;
  margin-right: 11px;
}

.drop-down--arrow::before {
  content: "";
  transition: 0.2s all ease-in-out;
  transform: rotate(180deg);
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  font-size: 6px;
  display: block;
}

.drop-down--arrow.open::before {
  transform: rotate(0);
}

.drop-down--list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  transition: 0.2s box-shadow ease-in-out, 0.4s height ease-in-out;
  padding: 0 20px;
  overflow: hidden;
  height: calc(var(--drop-height) + 55px);
}

[dir="rtl"] .drop-down--list {
  text-align: right;
}

.drop-down--list li {
  list-style-type: none;
  display: block;
  width: 100%;
}

.drop-down--link {
  font-size: 12px;
  line-height: 22px;
  color: #262626;
  transition: 0.2s color ease-in-out;
  display: block;
  width: 100%;
}

.drop-down--link:hover, .drop-down--link:focus {
  color: var(--color-primary-hover);
  text-decoration: none;
  transition: none;
  outline: none;
}

.drop-down--content {
  opacity: 0;
  margin-top: 55px;
  margin-bottom: 0;
  flex: 1 0 auto;
}

.open .drop-down--content {
  opacity: 1;
}

.drop-down--list, .drop-down--content {
  display: flex;
  flex-flow: column;
}

.drop-down:hover .drop-down--list, .drop-down.focused .drop-down--list, .drop-down.open .drop-down--list {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.world-widget {
  position: relative;
  min-width: max-content;
}

@media screen and (min-width: 1265px) {
  .world-widget {
    margin-right: 10px;
  }
}

.world-widget--link-image {
  width: auto;
  height: auto;
  transition: 0.4s all ease-in-out;
  filter: invert(17%) sepia(83%) saturate(0%) hue-rotate(146deg) brightness(92%) contrast(87%);
  margin-left: 10px;
}

[dir='rtl'] .world-widget--link-image {
  margin-left: 0;
  margin-right: 10px;
}

.world-widget--link-container {
  min-width: max-content;
}

.world-widget--link-new {
  display: none;
  padding: 0;
  min-width: max-content;
  align-self: center;
  position: relative;
  text-transform: uppercase;
  color: #262626;
  background-color: transparent;
  border: none;
  font-size: 12px;
  max-height: 100%;
  transition: 0.2s all ease-in-out;
  margin-left: 10px;
}

@media screen and (min-width: 1265px) {
  .world-widget--link-new {
    margin-top: 15px;
    display: flex;
  }
}

s [dir='rtl'] .world-widget--link-new {
  margin-right: 10px;
  margin-left: 0;
}

.world-widget--link-new:after {
  content: ' ';
  display: block;
  height: 100%;
  width: 100%;
  transition: 0.2s all ease-in-out;
  border-bottom: 4px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 16px;
  cursor: pointer;
}

.world-widget--link-new:hover, .world-widget--link-new:focus, .world-widget--link-new.active {
  color: var(--color-primary-hover);
  outline: none;
}

.world-widget--link-new:hover:after, .world-widget--link-new:focus:after, .world-widget--link-new.active:after {
  border-color: RGB(var(--color-primary));
}

.world-widget--link-new:hover .world-widget--link-image, .world-widget--link-new:focus .world-widget--link-image, .world-widget--link-new.active .world-widget--link-image {
  transition: 0.4s all ease-in-out;
  filter: invert(36%) sepia(70%) saturate(6170%) hue-rotate(188deg) brightness(91%) contrast(101%);
}

button:focus + .world-widget--link-new::before {
  opacity: 0.3;
}

button:focus + .world-widget--link-new::after {
  color: RGB(var(--color-primary));
}

.world-widget--link-old {
  display: none;
  align-self: center;
  position: relative;
  max-height: 100%;
  color: #6e6e6e;
  transition: 0.2s all ease-in-out;
  margin-left: 10px;
}

@media screen and (min-width: 1265px) {
  .world-widget--link-old {
    display: flex;
  }
}

[dir='rtl'] .world-widget--link-old {
  margin-right: 10px;
  margin-left: 0;
}

.world-widget--link-old:hover, .world-widget--link-old:focus {
  color: var(--color-primary-hover);
  outline: none;
}

.world-widget--link-old::before {
  content: "";
  font-size: 30px;
  font-family: "icomoon";
  transition: 0.2s opacity ease-in-out;
}

.world-widget--link-old::after {
  content: "";
  font-family: "icomoon";
  font-size: 12px;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  transition: 0.2s color ease-in-out, 0.2s transform ease-in-out;
}

.open .world-widget--link-old::after {
  transform: translate(-50%, -50%) rotate(0deg);
}

button:focus + .world-widget--link-old::before {
  opacity: 0.3;
}

button:focus + .world-widget--link-old::after {
  color: RGB(var(--color-primary));
}

@media screen and (max-width: 1264px) {
  .world-widget--fold-button {
    display: none;
  }
}

.world-widget--drop {
  display: none;
}

@media screen and (min-width: 1265px) {
  .world-widget--drop {
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 0;
    top: 55px;
    min-width: 280px;
    background: white;
    z-index: 3000;
    transition: 0.6s box-shadow ease-in-out;
  }
  .world-widget--drop-arrow {
    position: absolute;
    top: -36px;
    right: 10px;
    width: 72px;
    height: 36px;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
  }
  .world-widget--drop-arrow::before {
    content: '';
    position: absolute;
    display: block;
    width: 22.62742px;
    height: 22.62742px;
    bottom: -11.31371px;
    left: 24.68629px;
    transform: translateY(100%) rotate(45deg);
  }
  [dir='rtl'] .world-widget--drop-arrow {
    left: 10px;
    right: unset;
  }
  .world-widget--drop-arrow::before {
    background: white;
    transition: 0.4s transform ease-in-out 0.1s;
  }
  .open .world-widget--drop-arrow::before {
    box-shadow: 0 0 20px rgba(57, 57, 57, 0.3);
    transform: rotate(45deg);
    transition: 0.4s transform ease-in-out;
  }
  [dir='rtl'] .world-widget--drop {
    left: 0;
    right: unset;
  }
  .world-widget--drop-container {
    height: 0;
    transition: 0.4s height ease-in-out;
    overflow: hidden;
  }
  .open .world-widget--drop {
    box-shadow: 0 0 20px rgba(57, 57, 57, 0.3);
    transition: 0.4s box-shadow ease-in-out;
  }
}

.world-widget--drop-old {
  right: -25px;
}

.world-widget .loading-overlay {
  z-index: 3001;
}

.world-widget .loading-overlay svg {
  max-width: 70px;
}

.world-widget .search-field-drop--field {
  width: calc(100% - 30px);
}

.mobile-nav {
  margin-left: 30px;
  align-self: center;
  display: flex;
}

@media screen and (min-width: 1265px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-nav--opener {
  position: fixed;
  height: 0;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 0.4s all ease-in-out;
  background: white;
}

.open .mobile-nav--opener {
  height: 100vh;
}

.mobile-nav--menu {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100vh;
  padding: 0 20px;
}

.mobile-nav--panel-title, .mobile-nav--biglink, .mobile-nav--link {
  color: #393939;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
}

[dir='rtl'] .mobile-nav--panel-title, [dir='rtl'] .mobile-nav--biglink, [dir='rtl'] .mobile-nav--link {
  text-align: right;
}

.mobile-nav--biglink, .mobile-nav--panel-title {
  font-weight: 700;
}

.mobile-nav--biglink.indented, .mobile-nav--panel-title.indented {
  margin-left: 20px;
}

.mobile-nav--panel-title {
  margin-left: 20px;
  color: black;
  position: relative;
  margin-bottom: 24px;
}

.mobile-nav--panel-title.spaced {
  margin-bottom: 40px;
}

.mobile-nav--panel-title:before {
  content: '';
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: RGB(var(--color-primary));
  display: block;
}

[dir='rtl'] .mobile-nav--panel-title:before {
  right: 125px;
}

.mobile-nav--panel-title.indented {
  margin-left: 40px;
}

.mobile-nav .column, .mobile-nav--panel, .mobile-nav--indent, .mobile-nav--bottom {
  display: flex;
  flex-flow: column;
}

.mobile-nav--link {
  background: none;
  border: none;
  padding: 0;
}

.mobile-nav--link.is-low {
  text-transform: none;
}

.mobile-nav--link.is-back::before, .mobile-nav--link.is-next::after {
  position: relative;
  bottom: 1.5px;
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  font-size: 12px;
}

[dir='rtl'] .mobile-nav--link.is-back::before, [dir='rtl'] .mobile-nav--link.is-next::after {
  display: inline-block;
  transform: rotate(180deg);
  margin-right: 12px;
  margin-left: 12px;
}

.mobile-nav--link.is-next {
  cursor: pointer;
}

.mobile-nav--link.is-next:after {
  content: "";
  margin-left: 12px;
}

.mobile-nav--link.is-back {
  font-size: 16px;
  text-transform: none;
  margin-bottom: 25px;
  cursor: pointer;
}

.mobile-nav--link.is-back:before {
  content: "";
  margin-right: 12px;
}

.mobile-nav--logo {
  margin: 11px 0 45px;
  width: 25%;
}

.mobile-nav--logo img {
  height: 36px;
}

.mobile-nav--panels {
  position: relative;
}

.mobile-nav--panels li {
  margin-bottom: 40px;
}

.mobile-nav--panel {
  transition: 0.4s transform ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 200px);
  transform: translateX(100vw);
  overflow-y: auto;
}

.mobile-nav--panel[data-name='start'] {
  overflow: auto;
  transform: translateX(-100vw);
}

.mobile-nav--panel[data-name='markets'] {
  overflow: visible;
}

.mobile-nav--panel[data-name='business'] {
  overflow-x: hidden;
}

.mobile-nav--panel.active {
  transform: translateX(0);
}

.mobile-nav--panel .market-search-menu--all-market {
  margin-left: -20px;
  background: RGB(var(--color-primary));
  height: 50px;
  width: calc(100% + 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
}

[dir='rtl'] .mobile-nav--panel .market-search-menu--all-market {
  margin-right: -20px;
  margin-left: 0;
}

.mobile-nav--panel .market-search-menu .search-field-drop {
  padding: 0;
  position: static;
  height: auto;
  width: auto;
  flex-grow: 1;
}

.mobile-nav--panel .market-search-menu .search-field-drop--field {
  background: #f6f6f6;
  margin: 0 -20px;
  width: calc(100% + 40px);
  padding: 30px 20px 30px 70px;
  height: 60px;
}

.mobile-nav--panel .market-search-menu .search-field-drop--field::before {
  left: 20px;
  top: 50%;
}

[dir='rtl'] .mobile-nav--panel .market-search-menu .search-field-drop--field::before {
  left: 0;
  right: 20px;
}

.mobile-nav--panel .market-search-menu .search-field-drop--drop {
  height: auto;
  max-height: calc(100% - 60px);
  margin: 0;
  top: auto;
  left: auto;
}

.mobile-nav--panel .market-search-menu .search-field-drop--suggestions {
  height: calc(100vh - 400px);
}

.mobile-nav--panel .market-search-menu .search-field-drop--suggestions li {
  margin: 0;
}

.mobile-nav--panel .market-search-menu .search-field-drop--suggestions a {
  padding-left: 50px;
  font-size: 18px;
}

.mobile-nav--panel .market-search-menu .search-field-drop .highlighted::before {
  left: 35px;
}

.mobile-nav--bottom {
  margin: 0 auto;
  width: 250px;
}

.mobile-nav--bottom small {
  color: #6e6e6e;
  font-size: 11px;
  margin: 21px auto;
}

.mobile-nav--indent {
  padding-left: 20px;
}

.mobile-nav--social {
  display: flex;
  justify-content: space-between;
}

.mobile-nav--burger {
  --transitionSpeed: 0.4s;
  border: none;
  background: none;
  padding: 0;
  position: relative;
  width: 24px;
  height: 16px;
  align-self: flex-start;
  z-index: 2000;
  cursor: pointer;
}

.mobile-nav--burger > span {
  position: absolute;
  background: #393939;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s transform ease-in-out, var(--transitionSpeed) background ease-in-out;
}

.mobile-nav--burger > span:nth-child(1) {
  top: 0;
}

.mobile-nav--burger > span:nth-child(2) {
  top: calc(50% - 1px);
}

.mobile-nav--burger > span:nth-child(3) {
  bottom: 0;
  transform: none;
  right: 0;
  width: 16px;
  left: auto;
}

.mobile-nav--burger:focus {
  --transitionSpeed: 0.2s;
  outline: none;
}

.mobile-nav--burger:focus > span {
  background: RGB(var(--color-primary));
}

.open .mobile-nav--burger, .mobile-nav--burger.close {
  height: 24px;
}

.open .mobile-nav--burger > span:nth-child(1), .mobile-nav--burger.close > span:nth-child(1) {
  top: calc(50% - 1px);
  transform: translateX(-50%) rotate(45deg);
}

.open .mobile-nav--burger > span:nth-child(2), .mobile-nav--burger.close > span:nth-child(2) {
  transform: translateX(-50%) rotate(-45deg);
}

.open .mobile-nav--burger > span:nth-child(3), .mobile-nav--burger.close > span:nth-child(3) {
  width: 0;
}

.mobile-nav .spacing {
  margin-top: 34px;
}

.back-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.back-picture .background-layer {
  width: 100%;
  height: 100%;
}

.back-picture img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.back-picture.has-gradient:after, .back-picture.is-dark:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.back-picture.has-gradient:after {
  top: 23px;
  height: calc(100% - 23px);
  background: linear-gradient(transparent, var(--gradient-color));
}

.back-picture.is-dark:after {
  top: 0;
  height: 100%;
  background: var(--gradient-color);
}

.has-back-hover .back-picture img {
  will-change: transform;
  transition: 0.6s transform ease-in-out;
  transform: scale(1);
}

.back-picture .has-back-hover:focus {
  outline: none;
}

.has-back-hover:hover .back-picture img,
.has-back-hover:focus .back-picture img {
  transform: scale(1.025);
}

.has-back-hover:focus .back-picture img {
  transition: 0.2s transform ease-in-out;
}

.box-article-content--title, .box-article-content--description {
  width: 80%;
  min-width: 170px;
}

.box-article-content--image-title {
  margin-bottom: 20px;
  width: auto;
  height: auto;
}

.box-article-content--title {
  margin: 0 0 15px;
  color: white;
  word-break: keep-all;
}

.box-article-content--title.no-margin {
  margin-bottom: 0;
}

.box-article-content--title.button-present {
  margin-bottom: 15px;
}

.box-article-content--title > a {
  font-size: inherit;
  line-height: inherit;
}

.box-article-content--category {
  color: white;
  margin-bottom: 4px;
  transition: 0.4s color ease-in-out;
  cursor: pointer;
}

.is-banner .box-article-content--category {
  color: white;
}

.box-article-content--description {
  color: white;
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 20px;
}

.box-article-content--description.is-big {
  font-size: 20px;
  line-height: 26px;
}

@media screen and (max-width: 959px) {
  .box-article-content--description {
    display: none;
  }
  .box-article-content--description.is-big {
    display: block;
  }
}

.box-article-content--reading {
  color: white;
  margin: 0 0 35px;
}

.box-article-content--reading.is-last {
  margin-bottom: 0;
}

.box-article-content--reading.top-margin {
  margin-top: 20px;
}

.box-article-content--link {
  display: block;
  color: white;
  font-size: 12px;
}

.box-article-content--link:hover:after,
a:hover .box-article-content--link:after, .box-article-content--link:focus:after,
a:focus .box-article-content--link:after {
  --arrow-spacing: 25px;
}

.box-article-content--link:hover.noHover:after,
a:hover .box-article-content--link.noHover:after, .box-article-content--link:focus.noHover:after,
a:focus .box-article-content--link.noHover:after {
  --arrow-spacing: 18px;
}

.box-article-content--title.text-black, .box-article-content--category.text-black, .box-article-content--description.text-black, .box-article-content--reading.text-black, .box-article-content--link.text-black {
  color: #262626;
}

.quote-author {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .quote-author {
    display: block;
    line-height: 16px;
    font-size: 12px;
  }
}

.quote-author--name {
  font-style: normal;
  color: #393939;
}

.quote-author--comma {
  display: none;
}

@media screen and (min-width: 960px) {
  .quote-author--comma {
    display: inline;
  }
}

.quote-author--position {
  font-style: italic;
  color: #6e6e6e;
}

@media screen and (min-width: 640px) {
  .quote-author.break-sooner {
    display: block;
    line-height: 16px;
    font-size: 12px;
  }
}

@media screen and (min-width: 640px) {
  .break-sooner .quote-author.break-sooner--comma {
    display: inline;
  }
}

.page-info-widget-content--titles {
  grid-area: titles;
  padding-bottom: 20px;
}

.page-info-widget-content--notes {
  grid-area: notes;
}

.page-info-widget-content--notes.no-margin .written-by {
  margin-bottom: 0;
}

.page-info-widget-content--title {
  margin-bottom: 20px;
  color: black;
}

.page-info-widget-content--description {
  grid-area: content;
  margin: 20px 0 40px;
  font-size: 18px;
  line-height: 30px;
}

.page-info-widget-content--description.no-margin {
  margin-bottom: 0;
}

.page-info-widget-content--category:focus {
  outline: none;
  color: #262626;
  font-weight: bold;
}

.page-info-widget-content--tags {
  display: flex;
  flex-wrap: wrap;
}

.page-info-widget-content--date {
  display: block;
  margin-bottom: 20px;
}

.page-info-widget-content--tag {
  background: rgba(var(--color-primary), 0.1);
  padding: 7px 15px;
  font-size: 14px;
  color: #262626;
  margin-right: 10px;
  transition: 0.4s background ease-in-out;
}

.page-info-widget-content--tag:hover, .page-info-widget-content--tag:focus {
  background: rgba(var(--color-primary), 0.4);
  color: inherit;
  outline: none;
}

.page-info-widget-content .no-margin {
  margin-bottom: 0;
}

.written-by {
  display: flex;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 25px;
}

.written-by.is-vertically-centered {
  align-items: center;
}

.written-by--avatar {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  /* Fix for flex layout stretch */
  min-width: 50px;
  min-height: 50px;
}

.written-by--written-by {
  color: #6e6e6e;
  font-size: 11px;
}

.written-by--name {
  font-weight: 700;
}

.unsmoke-bubble-wrapper {
  position: relative;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.unsmoke-bubble {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.unsmoke-bubble:before, .unsmoke-bubble:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  height: 20px;
  background: white;
}

.unsmoke-bubble:before {
  left: 0;
  width: 50%;
  max-width: calc(100% - 130px);
}

@media screen and (max-width: 639px) {
  .unsmoke-bubble.small-on-mobile:before, .unsmoke-bubble.small-on-mobile:after {
    height: 10px;
  }
}

@media screen and (max-width: 639px) {
  .unsmoke-bubble.small-on-mobile:before {
    max-width: none;
    width: 30%;
  }
}

.unsmoke-bubble:after {
  right: 0;
  width: 10px;
}

.unsmoke-bubble--left,
.unsmoke-bubble--right {
  position: absolute;
  bottom: 20px;
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 639px) {
  .small-on-mobile .unsmoke-bubble--left, .small-on-mobile
  .unsmoke-bubble--right {
    bottom: 10px;
  }
}

.unsmoke-bubble--left {
  left: 0;
}

.unsmoke-bubble--right {
  right: 0;
}

.unsmoke-bubble--bottom {
  position: absolute;
  bottom: 0;
  width: calc(50% - 10px);
  height: 20px;
  min-width: 120px;
  right: 10px;
}

@media screen and (max-width: 639px) {
  .small-on-mobile .unsmoke-bubble--bottom {
    bottom: 0;
    height: 10px;
    min-width: 0;
    width: 60%;
  }
}

.large-image-text-on-image {
  display: flex;
  flex-flow: column;
  position: relative;
  color: white;
  justify-content: flex-end;
  padding: calc(40px - var(--paddingCompensation));
  width: 100%;
}

.large-image-text-on-image:focus:not(:hover) {
  outline: 4px solid #262626;
}

.large-image-text-on-image--content {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 1;
  color: white;
}

.small-image-above-title {
  --color-black: 38, 38, 38;
  --color-grey: 110, 110, 110;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}

.small-image-above-title:focus-within {
  outline: 4px solid #262626;
}

.small-image-above-title.latest-news {
  opacity: 0;
  transition: 0.4s opacity ease-in-out;
}

.small-image-above-title.latest-news.active {
  opacity: 1;
}

.small-image-above-title--picture {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  height: auto;
  outline: none;
}

.small-image-above-title--picture .back-picture {
  outline: none;
}

.latest-news .small-image-above-title--picture {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 25px;
  pointer-events: none;
  box-shadow: 0 0 0 black;
  transition: 0.4s box-shadow ease-in-out;
}

.latest-news.active .small-image-above-title--picture {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}

.small-image-above-title--picture:before {
  content: '';
  width: 100%;
  padding-bottom: 59%;
  display: block;
}

.small-image-above-title--picture img {
  transition: 0.4s transform ease-in-out;
  width: auto;
  height: auto;
}

.small-image-above-title:focus-within .small-image-above-title--picture img {
  transform: scale(1.1);
}

.small-image-above-title--content {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-left: 10px;
  width: 100%;
}

.small-image-above-title--date {
  color: var(--text-grey);
  font-size: 11px;
  margin-bottom: 10px;
}

.small-image-above-title--category {
  color: var(--text-primary);
  padding-bottom: 5px;
}

.small-image-above-title--category:focus {
  font-weight: bold;
  outline: none;
  color: var(--text-black);
}

.small-image-above-title--title {
  color: var(--text-black);
  text-align: left;
  background: transparent;
  border: none;
  padding: 0 0 7px 0;
}

[dir="rtl"] .small-image-above-title--title {
  text-align: right;
}

.small-image-above-title--title:focus {
  color: var(--text-primary);
  outline: none;
  transition: none;
}

.small-image-above-title--description {
  font-size: 14px;
}

.small-image-above-title--video-description {
  color: var(--text-black);
  margin: 4px 0 15px;
  cursor: pointer;
}

.small-image-above-title--cta {
  padding-top: 10px;
  color: var(--text-grey);
}

.small-image-above-title--cta:focus {
  color: RGB(var(--color-primary));
  font-weight: bold;
  outline: none;
}

.small-image-above-title .small-style {
  color: var(--text-grey);
}

.small-image-above-title .arrowed-link {
  --spacing: 25px;
}

@media screen and (max-width: 639px) {
  .small-image-above-title.mobile-style {
    flex-flow: row;
  }
  .small-image-above-title.mobile-style .small-image-above-title--picture {
    width: 100px;
    min-width: 100px;
    margin-bottom: 0;
  }
  .small-image-above-title.mobile-style .small-image-above-title--picture:before {
    padding-bottom: 100%;
  }
  .small-image-above-title.mobile-style .small-image-above-title--content {
    padding-left: 25px;
  }
  [dir="rtl"] .small-image-above-title.mobile-style .small-image-above-title--content {
    padding-right: 25px;
  }
}

@keyframes video-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.breadcrumbs {
  margin: 20px auto;
  display: flex;
  position: relative;
  z-index: 100;
}

@media screen and (min-width: 700px) {
  .breadcrumbs {
    margin: 30px auto 55px;
  }
}

.breadcrumbs--link {
  color: #6e6e6e;
  font-size: 14px;
  transition: 0.4s transform ease-in-out;
}

.breadcrumbs--link:before {
  content: "";
  margin-right: 8px;
  font-size: 12px;
  color: RGB(var(--color-primary));
  font-family: "icomoon";
}

@media screen and (max-width: 700px) {
  [dir="rtl"] .breadcrumbs--link:before {
    transform: rotate(180deg);
    display: inline-block;
    margin-right: 0;
    margin-left: 8px;
  }
}

.breadcrumbs--link:focus {
  outline: none;
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

@media screen and (min-width: 700px) {
  .breadcrumbs--link {
    font-size: 11px;
    text-decoration: underline;
  }
  .breadcrumbs--link:after {
    content: "";
    display: inline-block;
    background: #6e6e6e;
    width: 3px;
    height: 3px;
    margin: 0 10px 2px;
    border-radius: 50%;
  }
  .breadcrumbs--link:hover {
    text-decoration: underline;
  }
  .breadcrumbs--link:before {
    display: none;
  }
}

.white .breadcrumbs--link {
  color: #fff;
}

.white .breadcrumbs--link:before {
  color: #fff;
}

.white .breadcrumbs--link:after {
  background: #fff;
}

.breadcrumbs--greyed {
  font-size: 11px;
  color: #6e6e6e;
  display: none;
}

@media screen and (min-width: 700px) {
  .breadcrumbs--greyed {
    display: block;
  }
}

.white .breadcrumbs--greyed {
  color: #fff;
  opacity: 0.8;
}

@media screen and (max-width: 699px) {
  .breadcrumbs--link:not(.is-mobile) {
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.pages-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px -9px 0;
  font-size: 11px;
}

@media screen and (min-width: 640px) {
  .pages-nav {
    margin: 10px 0 0;
  }
}

.pages-nav.no-display {
  display: none;
}

.pages-nav button {
  background: none;
  border: none;
  color: RGB(var(--color-primary));
  cursor: pointer;
}

.pages-nav--first:before {
  content: "";
  font-size: 10px;
  font-family: "icomoon";
}

@media screen and (min-width: 640px) {
  .pages-nav--first:before {
    margin-right: 20px;
  }
  [dir="rtl"] .pages-nav--first:before {
    margin-right: 0px;
    margin-left: 20px;
  }
}

.pages-nav--last {
  justify-content: flex-end;
}

.pages-nav--last:after {
  content: "";
  font-size: 10px;
  font-family: "icomoon";
}

@media screen and (min-width: 640px) {
  .pages-nav--last:after {
    margin-left: 20px;
  }
  [dir="rtl"] .pages-nav--last:after {
    margin-left: 0px;
    margin-right: 20px;
  }
}

.pages-nav--first, .pages-nav--last {
  transition: 0.2s color ease-in-out;
  display: inline-flex;
  align-items: center;
  padding: 14px 0;
  width: 25%;
  font-size: 0;
  color: RGB(var(--color-primary));
  word-break: break-word;
  hyphens: auto;
  --translation: 0px;
  --rotation: 0deg;
  --factor: 1;
}

.pages-nav--first:disabled, .pages-nav--first.active, .pages-nav--last:disabled, .pages-nav--last.active {
  color: #262626;
  pointer-events: none;
  outline: none;
}

@media screen and (min-width: 640px) {
  .pages-nav--first, .pages-nav--last {
    font-size: 11px;
  }
}

[dir="rtl"] .pages-nav--first, [dir="rtl"] .pages-nav--last {
  --rotation: 180deg;
  --factor: -1;
}

.pages-nav--first:before, .pages-nav--first:after, .pages-nav--last:before, .pages-nav--last:after {
  transform: translateX(calc(var(--translation) * var(--factor))) rotate(var(--rotation));
  transition: 0.4s transform ease-in-out;
}

.pages-nav--first:focus:not(:disabled), .pages-nav--last:focus:not(:disabled) {
  font-weight: bold;
  outline: none;
}

.pages-nav--first:focus:not(:disabled):before, .pages-nav--first:focus:not(:disabled):after, .pages-nav--last:focus:not(:disabled):before, .pages-nav--last:focus:not(:disabled):after {
  transition: 0.2s transform ease-in-out;
  font-weight: normal;
}

.pages-nav--first:focus:not(:disabled):before, .pages-nav--last:focus:not(:disabled):before {
  --translation: -50%;
}

.pages-nav--first:focus:not(:disabled):after, .pages-nav--last:focus:not(:disabled):after {
  --translation: 50%;
}

.pages-nav--numbers {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  width: 50%;
}

.pages-nav--numbers.display-none {
  display: none;
}

@media screen and (max-width: 639px) {
  .pages-nav--numbers.mobile-hide {
    display: none;
  }
}

.pages-nav--state {
  margin: auto;
}

@media screen and (min-width: 640px) {
  .pages-nav--state {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .pages-nav--state.half-size {
    display: block;
  }
}

.pages-nav--number {
  text-decoration: underline;
  transition: 0.2s color ease-in-out, 0.2s transform ease-in-out;
  padding: 14px 18px;
  color: RGB(var(--color-primary));
  font-size: 11px;
}

@media screen and (min-width: 640px) {
  .pages-nav--number {
    margin-bottom: 0;
  }
}

.pages-nav--number:disabled, .pages-nav--number.active {
  color: #262626;
  text-decoration: none;
  font-weight: 700;
  pointer-events: none;
}

.pages-nav--number.hidden {
  display: none;
}

.pages-nav--number:focus {
  outline: none;
}

.pages-nav--number:focus:not(:disabled) {
  font-weight: bold;
  transform: scale(1.1);
}

.pages-nav--number.dots-after {
  display: flex;
  margin-right: 6px;
  position: relative;
}

.pages-nav--number.dots-after:after, .pages-nav--number.dots-after::before {
  content: ".\00a0.\00a0.";
  position: absolute;
  color: #262626;
  font-weight: 400;
  text-decoration: none;
  pointer-events: none;
}

.pages-nav--number.dots-after::before {
  display: none;
  left: -9px;
}

.pages-nav--number.dots-after::after {
  right: -9px;
}

[dir="rtl"] .pages-nav--number.dots-after::before {
  display: inline;
}

[dir="rtl"] .pages-nav--number.dots-after::after {
  display: none;
}

.key-figure.tns-slider {
  display: flex;
}

@media screen and (min-width: 960px) {
  .key-figure {
    display: flex;
    justify-content: space-between;
  }
}

.key-figure--element {
  margin: 0 auto 60px;
}

@media screen and (min-width: 960px) {
  .key-figure--element {
    margin: 0 20px 0 0;
    max-width: 290px;
  }
}

.key-figure--element:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.key-figure img {
  height: 80px;
  width: auto;
  margin: 0 auto 20px;
  display: block;
  transition: 0.4s transform ease-in-out;
}

.key-figure--title {
  margin-bottom: 20px;
  font-size: 43px;
  text-align: center;
  color: RGB(var(--color-primary));
}

.key-figure--title span {
  font-size: 17px;
  margin-left: 5px;
  margin-top: 17px;
}

.key-figure a:focus {
  outline: none;
}

.key-figure a:focus img {
  transform: scale(1.1);
  transition: 0.2s transform ease-in-out;
}

.key-figure--description {
  text-align: center;
  color: #6e6e6e;
}

@media screen and (max-width: 960px) {
  .key-figure--next.carousel-highlight-nav--next, .key-figure--previous.carousel-highlight-nav--previous {
    display: none;
  }
}

.key-figure--next {
  margin-left: 25px;
}

.key-figure--previous {
  margin-right: 25px;
}

.key-figure--controls {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-results--top {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.search-results--count {
  color: #6e6e6e;
  font-size: 18px;
}

.search-results--orders {
  display: flex;
  transition: none;
}

@media screen and (max-width: 699px) {
  .search-results--orders {
    transform: translateX(-100vw);
    opacity: 0;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    flex-flow: column;
  }
}

.search-results--orders.active {
  opacity: 1;
  transform: translateX(0);
}

.search-results--orders.animated {
  transition: 0.6s opacity ease-in, 0.6s transform ease-in;
}

.search-results--orders.animated.active {
  transition: 0.6s opacity ease-out, 0.6s transform ease-out;
}

.search-results--order {
  font-size: 14px;
  margin-left: 20px;
  color: #6e6e6e;
}

.search-results--order.active {
  color: #393939;
  font-weight: 700;
}

.search-results--order:focus {
  outline: none;
  color: RGB(var(--color-primary));
  font-weight: bold;
}

@media screen and (max-width: 699px) {
  .search-results--order {
    margin: 20px 30px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
  }
  .search-results--order.active {
    position: relative;
  }
  .search-results--order.active:after {
    content: "";
    font-family: "icomoon";
    font-size: 11px;
    color: RGB(var(--color-primary));
    position: absolute;
    right: 20px;
    top: 4px;
  }
}

.search-results--sort-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  color: RGB(var(--color-primary));
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.search-results--sort-button::before {
  content: "";
  font-family: "icomoon";
  font-size: 28px;
  margin-right: 10px;
}

@media screen and (min-width: 700px) {
  .search-results--sort-button {
    display: none;
  }
}

.search-result {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: flex-start;
}

.search-result:last-of-type {
  border: none;
}

.search-result--picture {
  position: relative;
  width: 40px;
  min-width: 40px;
  margin-right: 15px;
}

@media screen and (min-width: 700px) {
  .search-result--picture {
    width: 215px;
    min-width: 215px;
    margin-right: 30px;
  }
}

.search-result--picture:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

@media screen and (min-width: 700px) {
  .search-result--picture:before {
    padding-bottom: 59%;
  }
}

.search-result--content {
  display: flex;
  flex-flow: column;
}

.search-result--category:focus, .search-result--title:focus {
  outline: none;
  color: RGB(var(--color-primary));
  font-weight: bold;
}

.search-result--category {
  margin-bottom: 2px;
}

.search-result--title {
  margin-bottom: 10px;
  color: #393939;
}

.search-result--description {
  margin-bottom: 10px;
}

.job-search--title {
  margin-bottom: 45px;
}

.job-search--description {
  font-size: 12px;
  color: #6e6e6e;
  margin-bottom: 25px;
}

.job-search--link {
  font-size: 12px;
}

.job-search .search-field {
  background: none;
}

.job-search .job-location {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}

.job-search .job-location a {
  margin-left: 20px;
}

[dir="rtl"] .job-search .job-location a {
  margin-left: 0;
  margin-right: 20px;
}

.job-search .list-container {
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  background-color: #fff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  max-height: 250px;
  overflow-y: auto;
}

.job-search .list-container.show {
  display: block;
}

.job-search .list-container li {
  font-size: 16px;
  color: #6e6e6e;
}

.job-search .list-container li button {
  border: none;
  font-size: 14px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 12px 20px;
  color: #262626;
}

.job-search .job-names {
  position: relative;
  margin-bottom: 30px;
  z-index: 2;
}

.job-search .job-names .list-container button {
  background-color: transparent;
}

.job-search .job-names .list-container button:hover, .job-search .job-names .list-container button:focus {
  background-color: RGB(var(--color-primary));
  color: #fff;
}

.job-search .job-names .is-coloured {
  background-color: #f6f6f6;
}

.job-search .check-drop-down {
  max-width: calc(100% - 155px);
  flex-grow: 1;
}

.job-search .check-drop-down .search-field {
  transition: 0.4s border-bottom ease-in-out, 0.4s padding-left ease-in-out;
}

.job-search .check-drop-down .check-drop-down--search {
  font-size: 18px;
  background: none;
  color: inherit;
  transition: 0.4s padding ease-in-out;
}

.job-search .check-drop-down .check-drop-down--search::placeholder {
  opacity: 1;
  color: #fff;
}

.job-search .check-drop-down:not(.open) .check-drop-down--search {
  padding-left: 0;
}

[dir="rtl"] .job-search .check-drop-down:not(.open) .check-drop-down--search {
  padding-right: 0;
  padding-left: 15px;
}

.job-search .check-drop-down.open .search-field {
  border-bottom: 1px solid transparent;
}

.logo-disc {
  --size: 20vw;
  border-radius: 50%;
  min-width: var(--size);
  width: var(--size);
  height: var(--size);
  border: 3px solid white;
  overflow: hidden;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

@media screen and (min-width: 380px) {
  .logo-disc {
    --size: 110px;
  }
}

.logo-disc img {
  width: 100%;
}

.video {
  transition: 0.4s opacity ease-in-out;
}

.video:not(.active) {
  opacity: 0.5;
  pointer-events: none;
}

.video .plyr__controls__item:not(.plyr__volume):not(.plyr__progress__container) {
  flex-grow: 0;
}

.video .plyr__progress__container {
  flex-grow: 1;
}

@media screen and (max-width: 640px) {
  .video .plyr__progress__container {
    flex: 1;
  }
}

.video .plyr--is-ios .plyr__volume {
  display: flex !important;
}

@media (pointer: coarse) and (orientation: landscape) {
  .video .plyr__video-wrapper.plyr__video-embed {
    padding-bottom: 35% !important;
  }
}

.video .plyr--full-ui ::-webkit-media-text-track-container {
  display: block;
}

.video-cover {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.video-cover .video-icon {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: flex-end;
  z-index: 10;
}

[dir='rtl'] .video-cover .video-icon {
  left: 0;
  right: 20px;
}

.video-cover .video-icon--disc {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  transition: 0.4s background ease-in-out;
  align-self: flex-end;
}

@media screen and (min-width: 640px) {
  .video-cover .video-icon--disc {
    width: 50px;
    height: 50px;
  }
}

.video-cover .video-icon--disc:after {
  content: "";
  display: block;
  font-family: "icomoon";
  color: white;
  font-size: 8px;
  transform: scale(1);
  transition: 0.4s font-size ease-in-out;
}

@media screen and (min-width: 640px) {
  .video-cover .video-icon--disc:after {
    font-size: 14px;
    margin-left: 1px;
  }
}

.video-cover .video-icon--labels {
  display: flex;
  flex-flow: column;
  align-self: center;
  margin-left: 20px;
  margin-right: 20px;
}

[dir='rtl'] .video-cover .video-icon--labels {
  margin-left: 0;
}

.video-cover .video-icon--title, .video-cover .video-icon--length {
  width: 100%;
  color: #fff;
  position: relative;
  z-index: 10;
}

.video-cover .video-icon--title {
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-cover .video-icon--length {
  font-size: 11px;
}

*:hover > .video-cover .video-icon--disc,
*:focus > .video-cover .video-icon--disc {
  background: var(--color-primary-hover);
}

*:hover > .video-cover .video-icon--disc::after,
*:focus > .video-cover .video-icon--disc::after {
  font-size: 20px;
}

.check-drop-down {
  position: relative;
}

@media screen and (min-width: 700px) {
  .check-drop-down.open {
    z-index: 1001;
  }
}

.check-drop-down h3 {
  margin: 30px 20px;
}

@media screen and (min-width: 700px) {
  .check-drop-down h3 {
    display: none;
  }
}

.check-drop-down--back {
  transition: 0.4s box-shadow ease-in-out;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100% - 1px);
}

.check-drop-down.open .check-drop-down--back {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.grey-back .check-drop-down--back {
  background: #f6f6f6;
  transition: 0.4s background ease-in-out;
}

.open .check-drop-down--back {
  background: white;
}

.check-drop-down--top {
  position: relative;
  height: 100%;
}

.check-drop-down--search {
  position: relative;
  padding: 15px 40px 15px 20px;
  font-size: 14px;
  border: none;
  margin: 0;
  background: transparent;
  z-index: 1;
  outline: none;
  pointer-events: none;
}

[dir="rtl"] .check-drop-down--search {
  padding: 15px 15px 20px 40px;
}

button.default:not(.alone) ~ .check-drop-down--search {
  width: calc(100% - 40px);
}

.open .check-drop-down--search {
  pointer-events: all;
}

@media screen and (max-width: 700px) {
  .check-drop-down--search {
    font-size: 16px;
  }
}

.check-drop-down--main-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 200px;
  text-align: left;
  padding-left: 0;
  color: #262626;
  z-index: 1;
  outline: none;
}

.check-drop-down--main-button:after {
  --rotation: 0;
  content: "";
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  right: 20px;
  color: RGB(var(--color-primary));
  font-size: 6px;
  transition: 0.4s;
  transform: translateY(-50%) rotate(var(--rotation));
}

[dir="rtl"] .check-drop-down--main-button:after {
  right: auto;
  left: 20px;
}

.check-drop-down--main-button:focus:after {
  color: white;
}

.check-drop-down.open .check-drop-down--main-button:after {
  --rotation: 0.5turn;
}

.check-drop-down--main-button.default {
  border: 1px solid #e5e5e5;
  padding: 10px 70px 10px 20px;
  transition: 0.4s border-color ease-in-out;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.check-drop-down.open .check-drop-down--main-button.default {
  border-color: transparent;
}

.check-drop-down--main-button.default:not(.alone) {
  position: absolute;
}

.check-drop-down:not(.open) .check-drop-down--main-button:focus:not(.search-field) {
  border-color: RGB(var(--color-primary));
  transition: none;
}

.check-drop-down--content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  z-index: 2000;
  background: white;
}

@media screen and (min-width: 700px) {
  .check-drop-down--content {
    max-height: 50vh;
    overflow-y: auto;
    width: 100%;
    margin-top: 39px;
    position: relative;
    height: auto;
  }
  .check-drop-down--content.more-margin {
    margin-top: 53px;
  }
}

.check-drop-down--content.init {
  height: 0;
}

.grey-back .check-drop-down--content {
  background: #f6f6f6;
  transition: 0.4s background ease-in-out;
}

.open .check-drop-down--content {
  background: white;
}

.check-drop-down--mobile-scrollable {
  overflow-y: scroll;
}

@media screen and (min-width: 700px) {
  .check-drop-down--mobile-scrollable {
    overflow: visible;
  }
}

.check-drop-down--list {
  width: 100%;
}

.check-drop-down--list li {
  font-size: 16px;
  color: #6e6e6e;
  display: flex;
}

.check-drop-down--list .no-display {
  display: none;
}

.check-drop-down--list button {
  text-align: left;
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  font-size: 18px;
  transition: 0.6s background ease-in-out, 0.4s opacity ease-in-out;
}

@media screen and (min-width: 700px) {
  .check-drop-down--list button {
    padding: 5px 20px;
    font-size: 14px;
  }
}

.check-drop-down--list button:hover, .check-drop-down--list button:focus {
  background: RGB(var(--color-primary));
  color: white;
  transition: 0.4s background ease-in-out, 0.2s opacity ease-in-out;
}

.check-drop-down--apply {
  width: 100%;
  padding: 20px 0;
  margin-top: auto;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  border: none;
  cursor: pointer;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out;
  height: 60px;
  background: RGB(var(--color-primary));
  color: white;
}

.check-drop-down--apply:hover, .check-drop-down--apply:focus {
  color: RGB(var(--color-primary));
  background: #f6f6f6;
}

@media screen and (min-width: 700px) {
  .check-drop-down--apply {
    padding: 12px 0;
    background: #f6f6f6;
    color: RGB(var(--color-primary));
  }
  .check-drop-down--apply:hover, .check-drop-down--apply:focus {
    color: white;
    background: RGB(var(--color-primary));
  }
}

.ie-warning {
  display: none;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 45px;
  background: #393939;
  justify-content: center;
  align-items: center;
}

.ie-warning--content {
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 14px;
  width: 70%;
}

.ie-warning a, .ie-warning--close-label {
  text-decoration: underline;
  color: white;
}

.ie-warning button {
  padding: 0;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
}

.ie-warning--close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
}

.ie-warning--close:before {
  content: "";
  font-family: "icomoon";
  font-size: 15px;
}

.highlight {
  position: relative;
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  justify-content: flex-end;
  min-height: 275px;
}

.highlight:focus:not(:hover) {
  outline: 4px solid #262626;
}

.highlight:first-child {
  flex-grow: 1;
}

.highlight--content {
  display: flex;
  flex-flow: column;
  padding: 25px;
  justify-content: flex-end;
  width: 100%;
}

.highlight--content .box-article-content--title {
  font-size: 16px;
  line-height: 20px;
}

@media screen and (min-width: 960px) {
  .highlight--content .box-article-content--title {
    font-size: 22px;
    line-height: 26px;
  }
}

.highlight--content.is-section {
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.highlight--content.is-section .box-article-content--title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 26px;
  max-width: 170px;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .highlight--content.is-section .box-article-content--title {
    font-size: 26px;
    line-height: 34px;
  }
}

.highlight--content .box-article-content--reading {
  margin-bottom: 0;
}

.mobile-square-style .highlight {
  min-height: 0;
}

@media screen and (min-width: 960px) {
  .mobile-square-style .highlight {
    min-height: 275px;
  }
}

.mobile-square-style .highlight--background {
  position: relative;
  min-width: 107px;
  height: 107px;
  margin-right: 20px;
}

@media screen and (min-width: 960px) {
  .mobile-square-style .highlight--background {
    flex-grow: 1;
    position: static;
    min-width: 0;
    min-height: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

[dir="rtl"] .mobile-square-style .highlight--background {
  margin-right: 0;
}

@media screen and (max-width: 959px) {
  [dir="rtl"] .mobile-square-style .highlight--background {
    margin-left: 20px;
  }
}

.mobile-square-style .highlight--content {
  padding: 0;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (min-width: 960px) {
  .mobile-square-style .highlight--content {
    padding: 20px 20px 40px;
    justify-content: flex-end;
  }
}

.mobile-square-style .highlight--content .box-article-content--title {
  text-align: left;
}

.mobile-square-style .highlight--content .box-article-content--link {
  text-align: left;
}

[dir="rtl"] .mobile-square-style .highlight--content .box-article-content--title {
  text-align: right;
}

[dir="rtl"] .mobile-square-style .highlight--content .box-article-content--link {
  text-align: right;
}

.mobile-square-style .highlight--content.is-section {
  align-items: flex-start;
}

@media screen and (min-width: 960px) {
  .mobile-square-style .highlight--content.is-section {
    align-items: center;
  }
  .mobile-square-style .highlight--content.is-section .box-article-content--title {
    text-align: center;
    font-size: 26px;
    line-height: 34px;
    align-items: center;
  }
  .mobile-square-style .highlight--content.is-section .box-article-content--title > a {
    text-align: inherit;
  }
}

.highlight-image {
  flex-grow: 1;
  min-height: 275px;
}

.highlight-image img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.topic-highlight {
  position: relative;
  background: black;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 120px 20px 20px;
  margin: 0 -20px 50px;
  width: calc(100% + 2 * 20px);
  overflow: hidden;
}

@media screen and (min-width: 700px) {
  .topic-highlight {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 170px 30px 50px;
  }
  .topic-highlight .box-article-content--description {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .topic-highlight {
    width: 100%;
    padding: 70px 40px 50px 60px;
    margin: 0 auto 90px;
  }
}

.topic-highlight--background {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  min-height: 300px;
  overflow: hidden;
  width: 100%;
}

.topic-highlight--background .back-picture.has-gradient:after {
  background: linear-gradient(transparent, var(--gradient-color));
  height: calc(100% - 20px);
}

.topic-highlight--video {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.topic-highlight--video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-highlight--video::after {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.topic-highlight--content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .topic-highlight--content {
    align-self: flex-end;
  }
}

.topic-highlight--link-container {
  position: static;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  outline: none;
}

.topic-highlight--highlights {
  display: flex;
  flex-flow: column;
  max-width: 445px;
  margin-top: 60px;
}

@media screen and (min-width: 960px) {
  .topic-highlight--highlights {
    flex-flow: row;
    align-items: flex-end;
  }
}

@media screen and (max-width: 699px) {
  .topic-highlight--highlights {
    width: calc(100% + 40px);
    max-width: none;
    background: #f6f6f6;
    margin: -20px;
    margin-top: 0;
    padding: 20px;
  }
  .topic-highlight--highlights .box-article-content--title, .topic-highlight--highlights .box-article-content--reading {
    color: #393939;
  }
  .topic-highlight--highlights .box-article-content--link, .topic-highlight--highlights .box-article-content--category {
    color: RGB(var(--color-primary));
  }
  .topic-highlight--highlights .highlight-container:last-child {
    margin-bottom: 0;
  }
}

.topic-highlight .highlight-container {
  margin-bottom: 30px;
}

@media screen and (min-width: 700px) {
  .topic-highlight .highlight-container {
    width: 300px;
  }
}

@media screen and (min-width: 960px) {
  .topic-highlight .highlight-container {
    min-height: 370px;
    display: flex;
    margin-right: 25px;
    margin-bottom: 0;
  }
  .topic-highlight .highlight-container:last-child {
    margin-right: 0;
  }
  [dir="rtl"] .topic-highlight .highlight-container {
    margin-left: 25px;
    margin-right: 0;
  }
}

.topic-highlight--download {
  min-height: auto;
  position: relative;
  display: flex;
  background-color: RGB(var(--color-primary));
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out;
  margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
  .topic-highlight--download {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.topic-highlight--download:last-child {
  margin-bottom: 0;
}

.topic-highlight--download:before {
  margin-right: 15px;
  content: "";
  font-family: "icomoon";
  font-size: 22px;
  color: #fff;
}

.topic-highlight--download:hover {
  background: var(--color-primary-hover);
}

.topic-highlight--download:hover .topic-highlight--download-icon {
  background: var(--color-primary-hover);
  color: #fff;
  border: 1px solid #fff;
}

@media screen and (max-width: 700px) {
  .topic-highlight--download.no-hover-mobile {
    background: RGB(var(--color-primary));
  }
  .topic-highlight--download.no-hover-mobile .topic-highlight--download-icon {
    background: #fff;
    color: RGB(var(--color-primary));
  }
}

.topic-highlight--download-link {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 20px;
}

.topic-highlight--download-link:after {
  content: ' ';
  position: absolute;
  font-weight: normal;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.topic-highlight--download-label {
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  letter-spacing: 2px;
  margin-right: 10px;
}

.topic-highlight--download-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  color: RGB(var(--color-primary));
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out, 0.4s border ease-in-out;
}

.topic-highlight--download-icon::before {
  content: "";
  font-family: "icomoon";
  font-size: 13px;
}

.search-field-drop {
  position: relative;
}

.search-field-drop--field {
  padding-bottom: 10px;
  border-bottom: 1px solid RGB(var(--color-primary));
  position: relative;
  width: 100%;
}

.search-field-drop--field input {
  outline: none;
  position: relative;
  z-index: 1;
  width: calc(100% - 30px);
  font-size: 18px;
  color: #262626;
  border: none;
  background: none;
  padding: 0;
}

.search-field-drop--field input::placeholder {
  opacity: 1;
  transition: 0.4s opacity ease-in-out;
}

.search-field-drop--field input:focus::placeholder {
  opacity: 0.3;
}

.search-field-drop--field button {
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  background: #959595;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  z-index: 2;
  transform: translateY(-50%);
}

.search-field-drop--field button:before {
  content: "";
  font-family: "icomoon";
  color: white;
}

@media screen and (min-width: 1265px) {
  .search-field-drop--field button {
    opacity: 1;
    font-size: 14px;
    background: none;
  }
  .search-field-drop--field button:before {
    content: "";
    font-family: "icomoon";
    color: #6e6e6e;
  }
}

.search-field-drop--close {
  display: none;
}

@media screen and (min-width: 1265px) {
  .search-field-drop--close {
    border: none;
    display: flex;
    background-color: transparent;
    position: absolute;
    right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  .search-field-drop--close:after {
    content: '\d7';
    color: #393939;
    font-size: 28px;
    margin-right: 10px;
  }
  .search-field-drop--close:hover {
    cursor: pointer;
  }
}

.search-field-drop--back-suggestion {
  position: absolute;
  top: 4px;
  left: 0;
  width: calc(100% - 30px);
  height: calc(100% - 10px);
  opacity: 0.3;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -4px;
}

.search-field-drop--back-suggestion .blank {
  color: transparent;
}

.search-field-drop--drop {
  height: 0;
  transition: 0.4s box-shadow ease-in-out;
  position: absolute;
  top: 100%;
  overflow: hidden;
  z-index: 1000;
  background: white;
  left: -20px;
  width: calc(100% + 40px);
}

@media screen and (min-width: 700px) {
  .search-field-drop--drop {
    left: 0;
    width: 100%;
  }
}

.open .search-field-drop--drop {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.search-field-drop--search {
  display: none;
}

@media screen and (max-width: 1265px) {
  .search-field-drop--search {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    border: none;
    cursor: pointer;
    transition: 0.4s background ease-in-out, 0.4s opacity ease-in-out, 0.4s color ease-in-out;
    width: calc(100% - 60px);
    height: 60px;
    background: RGB(var(--color-primary));
    color: white;
  }
  .search-field-drop--search.deactivate {
    pointer-events: none;
    opacity: 0.2;
  }
}

.search-field-drop--suggestions button {
  border: none;
  background: none;
  font-size: 16px;
  letter-spacing: 0.025em;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

@media screen and (min-width: 1265px) {
  .search-field-drop--suggestions button {
    padding: 10px 30px;
  }
}

.search-field-drop--suggestions button:hover,
.search-field-drop--suggestions .active button {
  color: RGB(var(--color-primary));
  background: #f6f6f6;
}

.glass-style .search-field-drop--field {
  margin: 0;
  height: 30px;
  border-left: 1px solid #6e6e6e;
  padding: 0;
  transition: 0.4s width ease-in-out, 0.2s border-color ease-in-out;
  outline: none;
}

[dir="rtl"] .glass-style .search-field-drop--field {
  border-left: 0;
  border-right: 1px solid #6e6e6e;
}

@media screen and (max-width: 1265px) {
  [dir="rtl"] .glass-style .search-field-drop--field {
    border-right: none;
  }
}

.glass-style .search-field-drop--field.dropped {
  border-color: transparent;
}

.glass-style .search-field-drop--field input::placeholder {
  opacity: 1;
}

.glass-style .search-field-drop--back-suggestion,
.glass-style .search-field-drop--field input {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin: 0;
}

.glass-style .search-field-drop--back-suggestion::placeholder,
.glass-style .search-field-drop--field input::placeholder {
  color: #6e6e6e;
}

@media screen and (min-width: 1265px) {
  .glass-style .search-field-drop--back-suggestion,
  .glass-style .search-field-drop--field input {
    top: -2px;
    left: 8px;
  }
  [dir="rtl"] .glass-style .search-field-drop--back-suggestion, [dir="rtl"]
  .glass-style .search-field-drop--field input {
    left: -8px;
  }
}

.glass-style .search-field-drop--back-suggestion {
  top: 0;
}

@media screen and (min-width: 1265px) {
  .glass-style .search-field-drop--back-suggestion {
    top: -2px;
  }
}

.glass-style .search-field-drop--drop button {
  font-size: 16px;
}

@media screen and (max-width: 1264px) {
  .glass-style {
    position: fixed;
    left: 0;
    background: white;
    transition: 0.4s transform ease-in-out, 0.4s opacity ease-in-out;
    height: 100vh;
    top: 0;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    z-index: -1;
  }
  .glass-style .search-field-drop--field {
    height: 60px;
    background: #f6f6f6;
    border: none;
    width: 100%;
    transition: 0.2s all ease-in-out;
  }
  .glass-style .search-field-drop--field:before {
    content: "";
    font-family: "icomoon";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    font-size: 18px;
    color: #393939;
  }
  [dir="rtl"] .glass-style .search-field-drop--field:before {
    right: 15px;
    left: unset;
  }
  .glass-style .search-field-drop--drop {
    position: static;
    width: 100%;
  }
  .glass-style .search-field-drop--drop button {
    padding: 20px 20px 20px 50px;
  }
  .glass-style .search-field-drop--drop li:first-child button {
    margin-top: 20px;
  }
  .glass-style .search-field-drop--back-suggestion,
  .glass-style input {
    left: 0;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  [dir='rtl'] .glass-style .search-field-drop--back-suggestion, [dir='rtl']
  .glass-style input {
    padding-right: 50px;
    padding-left: 0;
  }
}

.boxed-style {
  min-height: 45px;
}

.boxed-style .search-field-drop {
  width: 100%;
}

@media screen and (min-width: 1265px) {
  .boxed-style .search-field-drop {
    align-items: center;
    width: auto;
    display: flex;
    justify-content: center;
  }
}

.boxed-style .search--glass {
  margin-right: 10px !important;
}

.boxed-style .search-field-drop--field {
  flex: 1 0 100%;
  border: none;
}

.boxed-style .search-field-drop--field input {
  border-bottom: 1px solid #8c8c8c;
}

@media screen and (min-width: 1265px) {
  .boxed-style .search-field-drop--field input {
    width: 170px;
    height: 25px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1265px) {
  .boxed-style .search-field-drop--back-suggestion {
    max-width: 170px;
  }
}

@media screen and (max-width: 1265px) {
  [dir="rtl"] .mobile-nav--burger.close {
    margin-left: -24px;
  }
}

.scroll-up {
  position: fixed;
  right: 10px;
  bottom: 40px;
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  transform: translateY(100px);
  opacity: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  outline: none;
  transition: 0.8s opacity ease-in-out, 0.8s transform ease-in-out;
}

.scroll-up:before {
  content: "";
  font-family: "icomoon";
  font-size: 15px;
  color: RGB(var(--color-primary));
  transform: rotate(-90deg) translate(1px, -1px);
}

.scroll-up.active {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (min-width: 960px) {
  .scroll-up {
    display: none;
  }
}

.carousel-highlight {
  --color-black: 38, 38, 38;
  --color-grey: 110, 110, 110;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 auto 50px;
}

@media screen and (min-width: 960px) {
  .carousel-highlight {
    margin-bottom: 90px;
  }
}

.carousel-highlight--title {
  min-width: 120px;
  font-size: 30px;
  color: var(--text-black);
  font-weight: normal;
  margin-bottom: 35px;
}

@media screen and (min-width: 640px) {
  .carousel-highlight--title {
    width: calc(100% - 300px);
  }
}

.carousel-highlight--intro {
  margin-bottom: 35px;
}

@media screen and (min-width: 960px) {
  .carousel-highlight--intro {
    width: 66.66%;
  }
}

.carousel-highlight--intro .carousel-highlight--title {
  margin-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .carousel-highlight--intro + .carousel-highlight--nav {
    margin-left: auto;
  }
}

@media screen and (min-width: 960px) {
  .carousel-highlight--intro + .carousel-highlight--nav {
    margin-left: 0;
  }
}

@media screen and (max-width: 959px) {
  .carousel-highlight--intro {
    margin-bottom: 0;
  }
}

.carousel-highlight--description {
  color: var(--text-black);
  font-size: 16px;
  line-height: 26px;
}

.carousel-highlight--previous, .carousel-highlight--next {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.15em;
  cursor: pointer;
  transition: 0.2s color ease-in-out;
}

.carousel-highlight--previous:hover, .carousel-highlight--next:hover {
  color: RGB(var(--color-primary));
}

.carousel-highlight--previous.inactive, .carousel-highlight--next.inactive {
  color: var(--text-grey);
  pointer-events: none;
}

.carousel-highlight--previous:before {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  margin-right: 10px;
}

.carousel-highlight--previous:after {
  content: '';
  display: inline-block;
  height: 13px;
  width: 0;
  border-right: 1px solid #cccccc;
  margin: 0 30px;
}

.carousel-highlight--next:after {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
  margin-left: 10px;
}

.carousel-highlight--content {
  position: relative;
  width: min-content;
  display: flex;
  margin: 0 -20px;
  padding-left: 20px;
  --offset: 0;
  --direction-factor: 1;
}

@media screen and (min-width: 480px) {
  .carousel-highlight--content {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

.with-overflow .carousel-highlight--content {
  overflow: visible;
}

.carousel-highlight--content .small-image-above-title--description {
  display: none;
}

@media screen and (min-width: 960px) {
  .carousel-highlight--content .small-image-above-title--description {
    display: block;
  }
}

.carousel-highlight--content * {
  user-select: none;
}

.carousel-highlight--content.is-panning {
  cursor: grab;
}

.carousel-highlight--content.is-panning *,
.carousel-highlight--content.is-panning .faq-block {
  pointer-events: none;
}

.carousel-highlight--content > div {
  transform: translateX(calc(var(--offset) * var(--direction-factor) * 1px));
}

[dir="rtl"] .carousel-highlight--content {
  --direction-factor: -1;
}

.carousel-highlight--bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
}

.carousel-highlight--bottom-link {
  position: absolute;
  right: 0;
  text-align: right;
}

.carousel-highlight .small-image-above-title,
.carousel-highlight .faq-block {
  margin-right: 25px;
  min-width: 215px;
  outline: none;
}

[dir="rtl"] .carousel-highlight .small-image-above-title, [dir="rtl"]
.carousel-highlight .faq-block {
  margin-left: 25px;
  margin-right: 0;
}

.carousel-highlight .small-image-above-title > button,
.carousel-highlight .faq-block > button {
  background: none;
  border: none;
}

@media screen and (min-width: 480px) {
  .carousel-highlight .small-image-above-title,
  .carousel-highlight .faq-block {
    min-width: calc(50% - (25px / 2));
  }
}

@media screen and (min-width: 960px) {
  .carousel-highlight .small-image-above-title,
  .carousel-highlight .faq-block {
    min-width: calc(33.33% - (50px / 3));
  }
}

.carousel-highlight-nav {
  display: none;
  margin: 0 auto;
}

@media screen and (min-width: 640px) {
  .carousel-highlight-nav {
    display: flex;
    align-items: center;
  }
}

.carousel-highlight-nav--dots {
  display: flex;
  margin: 0 35px;
}

.carousel-highlight-nav--dot {
  margin: 0 5px;
  padding: 0;
  width: 7px;
  height: 7px;
  background: var(--text-grey);
  transition: 0.2s background ease-in-out, 0.2s transform ease-in-out;
  border: none;
  color: transparent;
  font-size: 0;
}

.carousel-highlight-nav--dot:disabled {
  transform: scale(1.43);
  background: var(--background-primary);
}

.carousel-highlight-nav--dot:focus {
  outline: 4px solid #262626;
  background: white;
}

@media screen and (min-width: 960px) {
  .carousel-highlight-nav--dot:last-child {
    display: none;
  }
}

.carousel-highlight-nav--previous, .carousel-highlight-nav--next {
  display: inline-flex;
  align-items: center;
  color: var(--text-black);
  font-size: 12px;
  text-transform: uppercase;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.15em;
  cursor: pointer;
  transition: 0.2s color ease-in-out;
}

.carousel-highlight-nav--previous:hover, .carousel-highlight-nav--previous:focus, .carousel-highlight-nav--next:hover, .carousel-highlight-nav--next:focus {
  color: var(--text-primary);
  font-weight: bold;
  outline: none;
}

.carousel-highlight-nav--previous:hover:before, .carousel-highlight-nav--previous:hover:after, .carousel-highlight-nav--previous:focus:before, .carousel-highlight-nav--previous:focus:after, .carousel-highlight-nav--next:hover:before, .carousel-highlight-nav--next:hover:after, .carousel-highlight-nav--next:focus:before, .carousel-highlight-nav--next:focus:after {
  transition: 0.2s color ease-in-out;
  color: var(--text-primary);
}

.carousel-highlight-nav--previous:disabled, .carousel-highlight-nav--next:disabled {
  pointer-events: none;
}

.carousel-highlight-nav--previous:disabled, .carousel-highlight-nav--previous:disabled::before, .carousel-highlight-nav--previous:disabled::after, .carousel-highlight-nav--next:disabled, .carousel-highlight-nav--next:disabled::before, .carousel-highlight-nav--next:disabled::after {
  color: var(--text-grey);
}

.carousel-highlight-nav--previous::before, .carousel-highlight-nav--previous::after, .carousel-highlight-nav--next::before, .carousel-highlight-nav--next::after {
  margin: 0 10px;
}

[dir="rtl"] .carousel-highlight-nav--previous::before, [dir="rtl"] .carousel-highlight-nav--previous::after, [dir="rtl"] .carousel-highlight-nav--next::before, [dir="rtl"] .carousel-highlight-nav--next::after {
  transform: rotate(180deg);
}

.carousel-highlight-nav--previous::before {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
}

.carousel-highlight-nav--next::after {
  content: "";
  font-family: "icomoon";
  font-size: 10px;
}

.job-list-pages table {
  width: 100%;
  table-layout: fixed;
}

.job-list-pages th {
  text-align: left;
}

.job-list-pages--top {
  display: flex;
  justify-content: space-between;
  background: RGB(var(--color-primary));
  padding: 25px 0 25px 20px;
}

.job-list-pages--column-name {
  color: white;
  font-size: 14px;
  width: 150px;
}

@media screen and (max-width: 699px) {
  .job-list-pages--column-name + .job-list-pages--column-name {
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.job-list-pages--column-name:nth-child(3) {
  width: 100px;
}

.job-list-pages--column-name:first-child {
  flex-grow: 1;
}

.job-list-pages--notes {
  margin-bottom: 3px;
}

.job-list-pages--notes span {
  margin-left: 5px;
}

.job-list-pages--page {
  display: flex;
  flex-flow: column;
  min-width: 100%;
}

.job-list-pages--pages {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.job-list-pages .pages-nav {
  padding: 0;
}

@media screen and (min-width: 640px) {
  .job-list-pages .pages-nav {
    padding: 0 20px;
  }
}

.job-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #cccccc;
}

@media screen and (min-width: 960px) {
  .job-row {
    flex-wrap: nowrap;
  }
}

.job-row h4,
.job-row p {
  transition: 0.2s color ease-in-out;
}

.job-row:hover h4,
.job-row:hover p {
  color: RGB(var(--color-primary));
  transition: none;
}

.job-row--title {
  margin-top: 20px;
  padding: 0 50px 0 20px;
  font-weight: normal;
  color: #262626;
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (min-width: 960px) {
  .job-row--title {
    width: auto;
    margin-bottom: 20px;
  }
}

.job-row--notes {
  display: flex;
  padding-left: 20px;
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .job-row--notes {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 960px) {
  .job-row--notes {
    padding: 0;
    margin: 0;
    width: auto;
  }
}

.job-row--notes p {
  width: 150px;
}

.job-row--notes p:nth-child(2) {
  width: 100px;
}

.basic-video {
  position: relative;
  display: grid;
  grid-template: 1fr / 1fr;
}

.basic-video--img-container,
.basic-video .video {
  grid-column: 1;
  grid-row: 1;
}

.basic-video--img-container {
  position: relative;
  display: block;
  width: 100%;
  z-index: 10;
}

.basic-video--img-container::before {
  content: '';
  display: block;
  margin-bottom: 56.25%;
}

@media (pointer: coarse) and (orientation: landscape) {
  .basic-video--img-container::before {
    margin-bottom: 35%;
  }
}

.basic-video--img-container img {
  position: absolute;
  top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.basic-video--download {
  position: absolute;
  top: 20px;
  right: 25px;
  display: flex;
  flex-flow: column;
  align-items: center;
  color: white;
  font-size: 11px;
  z-index: 10;
}

[dir='rtl'] .basic-video--download {
  right: unset;
  left: 25px;
}

.basic-video--download::before {
  content: "";
  font-family: "icomoon";
  font-size: 30px;
  margin-bottom: 4px;
}

.basic-video--start-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.basic-video-transcript {
  position: relative;
  display: flex;
  flex-flow: column;
}

.basic-video-transcript--button {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-primary-hover);
  background: #f0f0f0;
  border: none;
}

.basic-video-transcript--button::after {
  content: "";
  font-family: "icomoon";
  font-size: 8px;
  color: RGB(var(--color-primary));
  margin-left: 70px;
  transition: 0.4s transform ease-in-out;
}

[dir='rtl'] .basic-video-transcript--button::after {
  margin-left: 0;
  margin-right: 70px;
}

.basic-video-transcript--button.unfolded::after {
  transform: rotate(180deg);
  z-index: 100;
}

.modal .basic-video-transcript--button {
  background: RGB(var(--color-primary));
}

.modal .basic-video-transcript--button, .modal .basic-video-transcript--button::after {
  color: white;
}

.basic-video-transcript--container {
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-flow: column;
  background: #f6f6f6;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0;
  z-index: 1001;
  transition: 0.4s height ease-in-out, 0.4s box-shadow ease-in-out;
}

@media screen and (min-width: 700px) {
  .basic-video-transcript--container {
    max-height: 350px;
  }
}

.basic-video-transcript--container.unfolded {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
}

.modal .basic-video-transcript--container {
  background: RGB(var(--color-primary));
  position: relative;
  top: 0;
}

.basic-video-transcript--contained {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.basic-video-transcript--title, .basic-video-transcript--content {
  padding: 20px;
}

.modal .basic-video-transcript--title, .modal .basic-video-transcript--content {
  color: white;
  border: none;
}

.basic-video-transcript--title {
  font-size: 16px;
  border-bottom: 1px solid #cccccc;
}

.basic-video-transcript--content {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

.basic-video--error-msg {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  height: 50vh;
}

.country-info {
  margin: 0 auto;
  position: relative;
  transition: 0.4s opacity ease-in-out;
}

.country-info:not(.active) {
  opacity: 0.5;
  cursor: wait;
}

.country-info:not(.active) * {
  cursor: wait;
}

@media screen and (min-width: 960px) {
  .country-info {
    min-height: 631px;
  }
}

.country-info--split {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 1200px) {
  .country-info--split {
    flex-flow: row;
  }
}

.country-info--main {
  position: relative;
  z-index: 1;
  width: 100%;
}

.country-info--title {
  position: relative;
  z-index: 2;
  margin-bottom: 34px;
}

@media screen and (min-width: 700px) {
  .country-info--title {
    margin-bottom: 60px;
    max-width: none;
  }
}

.country-info--title.back {
  cursor: pointer;
}

.country-info--title.back:before {
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-right: 10px;
  vertical-align: middle;
  font-size: 14px;
  padding-bottom: 6px;
}

.market .country-info--title {
  font-weight: normal;
  max-width: 274px;
  font-size: 30px;
  line-height: 38px;
}

.country-info--content {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-left: 20px;
  transition: 0.4s opacity ease-in-out;
}

@media screen and (min-width: 700px) {
  .country-info--content {
    flex-flow: row;
    padding-left: 0px;
  }
}

.market .country-info--content {
  padding-left: 20px;
}

.country-info--content.inactive {
  opacity: 0.5;
}

.country-info--content.inactive li,
.country-info--content.inactive button {
  pointer-events: none;
}

.country-info--background {
  display: none;
  position: fixed;
  height: 100%;
  max-width: 1440px;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.country-info--background:before {
  content: '';
  display: block;
  padding-bottom: 44%;
}

@media screen and (min-width: 700px) {
  .country-info--background {
    display: block;
    width: 123%;
    height: auto;
  }
}

.country-info--background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.market .country-info--background {
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .market .country-info--background {
    top: -50px;
  }
}

@media screen and (min-width: 960px) {
  .market .country-info--background {
    top: -80px;
  }
}

@media screen and (min-width: 1200px) {
  .market .country-info--background {
    top: 0;
  }
}

.market .country-info--background img {
  object-fit: contain;
  width: auto;
  height: auto;
}

.country-info--continent {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 24px;
  line-height: 24px;
  color: black;
  border-bottom: 3px solid transparent;
  padding-bottom: 8px;
}

.country-info--continent:after {
  content: "";
  font-size: 10px;
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-left: 10px;
  line-height: 10px;
  margin-top: 5px;
}

@media screen and (min-width: 700px) {
  .country-info--continent:after {
    display: none;
  }
}

.country-info--continent.active, .country-info--continent:hover, .country-info--continent:focus {
  display: block;
  color: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
  outline: none;
}

.country-info--continent.active::after, .country-info--continent:hover::after, .country-info--continent:focus::after {
  display: none;
}

.country-info--continents {
  transition: 0.4s transform ease-in-out;
}

@media screen and (min-width: 700px) {
  .country-info--continents {
    width: 33%;
  }
}

@media screen and (min-width: 960px) {
  .country-info--continents {
    width: 30%;
  }
}

.country-info--continents.active {
  transform: translateX(-100vw);
}

@media screen and (min-width: 700px) {
  .country-info--continents.active {
    transform: none;
  }
}

.country-info--list {
  flex-grow: 1;
  position: absolute;
  top: -8px;
}

@media screen and (min-width: 700px) {
  .country-info--list {
    top: 0;
    position: relative;
  }
}

@media screen and (max-width: 699px) {
  .country-info--list {
    left: 20px;
    right: 0;
  }
}

.country-info .countries {
  position: absolute;
  left: 0;
  top: -2px;
  opacity: 0;
  width: 100%;
  padding-left: 16px;
  pointer-events: none;
  transition: 0.4s opacity ease-in-out, 0.4s transform ease-in-out;
  transform: translateX(100vw);
}

@media screen and (min-width: 700px) {
  .country-info .countries {
    transform: none;
    padding-left: 0;
    columns: 2;
  }
}

@media screen and (min-width: 960px) {
  .country-info .countries {
    columns: 3;
  }
}

.country-info .countries.active {
  pointer-events: auto;
  opacity: 1;
  z-index: 2;
  transform: translateX(0);
}

.country-info .countries--country {
  line-height: 34px;
}

.country-info .countries--country a,
.country-info .countries--country button {
  font-size: 16px;
  color: #393939;
  transition: 0.4s color ease-in-out;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  vertical-align: top;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
}

.country-info .countries--country a:hover, .country-info .countries--country a:focus,
.country-info .countries--country button:hover,
.country-info .countries--country button:focus {
  color: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
  outline: none;
}

.country-info .countries--country a.active,
.country-info .countries--country button.active {
  color: RGB(var(--color-primary));
  font-weight: bold;
  border-color: var(--color-primary-hover);
  pointer-events: none;
}

.country-info .countries--title {
  color: RGB(var(--color-primary));
}

@media screen and (min-width: 700px) {
  .country-info .countries--title {
    display: none;
  }
}

.market-pane {
  position: relative;
  z-index: 1;
  background: #f6f6f6;
  padding: 40px 30px;
  overflow: hidden;
  transition: 0.6s height ease-in-out;
  margin: 0 -20px;
}

@media screen and (min-width: 480px) {
  .market-pane {
    padding: 40px 50px;
  }
}

@media screen and (min-width: 1200px) {
  .market-pane {
    max-width: 450px;
    min-width: 450px;
    margin: 0;
  }
}

.market-pane.transition:before, .market-pane.transition:after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  width: 40px;
  height: 100%;
}

.market-pane.transition:before {
  left: 0;
  background: linear-gradient(to right, #f6f6f6, transparent);
}

.market-pane.transition:after {
  right: 0;
  background: linear-gradient(to left, #f6f6f6, transparent);
}

.market-pane--content {
  position: absolute;
  top: 40px;
  left: 30px;
  width: calc(100% - 60px);
  display: flex;
  flex-flow: column;
  transition: 0.6s transform ease-in-out;
}

@media screen and (min-width: 480px) {
  .market-pane--content {
    left: 50px;
    width: calc(100% - 100px);
  }
}

.market-pane--content.coming {
  transform: translateX(120%);
}

.market-pane--content.leaving {
  transform: translateX(-120%);
}

.market-pane--title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 9px;
}

.market-pane--title:nth-child(2) {
  margin-bottom: 20px;
}

.market-pane--phones {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}

.market-pane .link {
  display: flex;
  align-items: flex-start;
  color: #393939;
  margin-bottom: 20px;
  transition: 0.4s color ease-in-out;
}

.market-pane .link:hover {
  color: RGB(var(--color-primary));
}

.market-pane .link::before {
  content: "";
  font-size: 20px;
  color: RGB(var(--color-primary));
  font-family: "icomoon";
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .market-pane .link--number {
    word-break: break-all;
  }
}

.market-pane .link.fax::before {
  content: "";
  margin-left: -3px;
}

.market-pane .link.mail::before {
  content: "";
  font-size: 16px;
  padding-top: 3px;
}

.market-pane .link--note {
  font-size: 14px;
  line-height: 20px;
}

.market-pane--name {
  font-size: 16px;
  color: #393939;
  margin-bottom: 20px;
}

.market-pane--address {
  font-size: 14px;
  line-height: 28px;
  font-style: normal;
  color: #393939;
}

.market-pane--section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.market-pane--policy {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #393939;
}

.dropdown-menu-widget--world-dropdown .countries--country a {
  font-size: 14px;
}

@media screen and (min-width: 1265px) {
  .dropdown-menu-widget--world-dropdown .country-info {
    min-height: 570px;
    padding: 50px 0 30px 0;
  }
  .dropdown-menu-widget--world-dropdown .country-info--background {
    position: absolute;
    width: 115%;
    top: 55%;
    transform: translate(-50%, -55%);
  }
  .dropdown-menu-widget--world-dropdown .country-info--background:before {
    padding-bottom: 38%;
  }
  .dropdown-menu-widget--world-dropdown .country-info--background-img {
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all ease-in-out;
  }
  .dropdown-menu-widget--world-dropdown .country-info--continent {
    font-size: 22px;
  }
}

.faq-block {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: white;
  padding: 40px 30px 30px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  min-height: 180px;
  transition: 0.4s box-shadow ease-in-out;
  pointer-events: none;
  border: 1px solid transparent;
}

.faq-block:focus {
  outline: none;
  border: 1px solid RGB(var(--color-primary));
}

.faq-block--infos {
  display: flex;
  margin-bottom: 10px;
}

.faq-block--tag {
  margin-right: 5px;
  font-size: 11px;
  color: #262626;
  text-transform: uppercase;
}

.faq-block--date {
  font-size: 11px;
  color: #6e6e6e;
}

.faq-block--title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #262626;
}

.faq-block * {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.faq-block.active {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  pointer-events: all;
}

.faq-block.active * {
  opacity: 1;
  transition: 0.2s opacity ease-in-out 0.2s;
}

.conditions-of-use {
  position: relative;
  margin: 0 auto;
  padding-bottom: 60px;
}

.menu-partial {
  margin-top: 60px;
}

@media screen and (min-width: 960px) {
  .menu-partial {
    display: flex;
  }
}

@media screen and (min-width: 960px) {
  .menu-partial .left-menu--container {
    width: 30%;
    position: relative;
    max-width: 350px;
  }
}

.menu-partial .left-menu--items {
  display: none;
}

@media screen and (min-width: 960px) {
  .menu-partial .left-menu--items {
    display: block;
    width: 100%;
    max-width: 350px;
  }
}

.menu-partial .left-menu--items li {
  display: flex;
}

.menu-partial .left-menu--link {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: 200;
  color: #6e6e6e;
  padding-left: 80px;
  position: relative;
  transition: 0.4s color ease-in-out;
}

.menu-partial .left-menu--link:hover {
  color: var(--color-primary-hover);
  text-decoration: none;
}

.menu-partial .left-menu--link::before, .menu-partial .left-menu--link::after {
  content: "";
  position: absolute;
  right: calc(100% - 80px);
  top: 50%;
  display: block;
  height: 1px;
  background-color: RGB(var(--color-primary));
  width: 0;
  margin-right: 15px;
  transition: 0.6s width ease-in-out;
}

.menu-partial .left-menu--link::after {
  background-color: #ebebeb;
  transition: 0.2s width ease-in-out;
}

.menu-partial .left-menu--link::before {
  z-index: 1;
}

.menu-partial .left-menu--link:focus {
  outline: none;
  color: RGB(var(--color-primary));
  font-weight: normal;
}

.menu-partial .left-menu--link:focus::after {
  width: 65px;
  background: #262626;
}

.menu-partial .left-menu--link.current {
  color: RGB(var(--color-primary));
}

.menu-partial .left-menu--link.current::before, .menu-partial .left-menu--link.current::after {
  width: 65px;
}

@media screen and (min-width: 960px) {
  .menu-partial .right-section {
    flex-basis: 70%;
    padding-left: 150px;
  }
}

.menu-partial .right-section--heading {
  font-size: 20px;
  padding-bottom: 18px;
}

.menu-partial .right-section--detail {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 50px;
}

.faq {
  position: relative;
  margin-bottom: 60px;
}

.faq .wrapper {
  margin: 60px auto 30px;
}

.faq .wrapper--title {
  font-size: 30px;
  font-weight: normal;
  color: #262626;
}

.faq .wrapper--subtitle {
  margin-top: 10px;
  color: #6e6e6e;
}

@media screen and (min-width: 960px) {
  .faq .wrapper--subtitle {
    width: calc(40% - 50px);
  }
}

@media screen and (min-width: 960px) {
  .faq .main {
    display: flex;
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 960px) {
  .faq .main .category--container {
    max-width: 300px;
    width: 40%;
    position: relative;
  }
}

.faq .main .category--menu {
  display: none;
}

@media screen and (min-width: 960px) {
  .faq .main .category--menu {
    display: block;
    max-width: 300px;
  }
}

.faq .main .category--menu li {
  display: flex;
}

.faq .main .category--link {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 200;
  color: #6e6e6e;
  padding-left: 55px;
  position: relative;
  transition: 0.4s color ease-in-out;
}

.faq .main .category--link::before, .faq .main .category--link::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  height: 1px;
  background-color: RGB(var(--color-primary));
  width: 0;
  margin-right: 15px;
  transition: 0.6s width ease-in-out;
}

.faq .main .category--link::after {
  background-color: #ebebeb;
  transition: 0.2s width ease-in-out;
}

.faq .main .category--link::before {
  z-index: 1;
}

.faq .main .category--link:hover, .faq .main .category--link:focus {
  color: var(--color-primary-hover);
  text-decoration: none;
  outline: none;
}

.faq .main .category--link:hover::after, .faq .main .category--link:focus::after {
  width: 28px;
  background: #6e6e6e;
}

.faq .main .category--link.current {
  color: RGB(var(--color-primary));
}

.faq .main .category--link.current::before, .faq .main .category--link.current::after {
  width: 28px;
}

.faq .main .faq--content {
  flex-basis: 70%;
  margin-top: -30px;
}

.faq .main .faq--content .faq--category--heading {
  font-size: 28px;
  font-weight: 300;
  color: RGB(var(--color-primary));
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .faq .main .faq--content .faq--category:first-child .faq--category--heading {
    margin-top: 20px;
  }
}

.faq .main .faq--content .entry--question {
  position: relative;
  font-size: 20px;
  background-color: #f6f6f6;
}

.faq .main .faq--content .entry--unfold {
  padding: 20px 30px 20px 15px;
  border: 1px solid transparent;
  background: none;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.faq .main .faq--content .entry--unfold:focus {
  border: 1px solid RGB(var(--color-primary));
}

.faq .main .faq--content .entry--unfold:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: "";
  color: RGB(var(--color-primary));
  font-size: 6px;
  font-family: "icomoon";
  margin-left: 20px;
  font-style: normal;
  transition: 0.4s transform ease-in-out;
}

.faq .main .faq--content .entry--unfold[aria-expanded="true"]::after {
  transition: 0.2s all ease-in-out;
  transform: rotate(180deg);
}

.faq .main .faq--content .entry--container {
  height: 0;
  overflow: hidden;
  transition: 0.4s height ease-in-out;
  margin-bottom: 20px;
}

.faq .main .faq--content .entry--answer {
  padding: 20px 50px 20px 25px;
}

.faq .main .faq--content .entry--description {
  font-weight: 300;
  line-height: 24px;
  padding-bottom: 15px;
}

.faq .main .faq--content .entry--read-more {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

.faq .main .faq--content .entry--read-more:after {
  content: "";
  font-size: 7px;
  font-family: "icomoon";
  margin-left: 10px;
}

.tbe-journey {
  --iconBackColor: white;
  --iconColor: RGB(var(--color-primary));
  --iconBorderColor: #959595;
}

.tbe-journey--title {
  font-size: 30px;
  font-weight: 400;
}

.tbe-journey .left-menu--title {
  margin-bottom: 20px;
  color: RGB(var(--color-primary));
}

.tbe-journey .left-menu--items-inner {
  position: relative;
}

.tbe-journey .left-menu--items-inner .connecting-line {
  position: absolute;
  height: 100%;
  background-color: #959595;
  width: 1px;
  left: 20px;
  z-index: 1;
}

.tbe-journey .left-menu--row {
  margin-bottom: 30px;
  max-width: 215px;
}

.tbe-journey .left-menu--step-link {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #959595;
}

.tbe-journey .left-menu--step-link:focus {
  outline: none;
  --iconBackColor: #262626;
  --iconColor: white;
  --iconBorderColor: #262626;
}

.tbe-journey .left-menu--step-link:focus .left-menu--category-text {
  color: RGB(var(--color-primary));
  font-weight: bold;
}

.tbe-journey .left-menu--category-text {
  line-height: 1.8;
  color: #6e6e6e;
}

.tbe-journey .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid var(--iconBorderColor);
  background-color: var(--iconBackColor);
  border-radius: 50%;
  margin-right: 10px;
  transition: 0.4s background ease-in-out, 0.4s border-color ease-in-out;
}

.tbe-journey .icon:before {
  font-family: "icomoon";
  color: var(--iconColor);
  font-weight: normal;
  transition: 0.4s color ease-in-out;
}

.tbe-journey .current .icon,
.tbe-journey a:hover .icon,
.tbe-journey *:focus .icon {
  transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out;
}

.tbe-journey .current .icon:before,
.tbe-journey a:hover .icon:before,
.tbe-journey *:focus .icon:before {
  transition: 0.2s color ease-in-out;
}

.tbe-journey .current {
  --iconBackColor: RGB(var(--color-primary));
  --iconColor: white;
  --iconBorderColor: RGB(var(--color-primary));
}

.tbe-journey .ico-growing:before {
  content: "";
  font-size: 20px;
}

.tbe-journey .ico-processing:before {
  content: "";
  font-size: 22px;
}

.tbe-journey .ico-product:before {
  content: "";
  font-size: 23px;
}

.tbe-journey .ico-suppliers:before {
  content: "";
  font-size: 26px;
}

.tbe-journey .ico-distributive:before {
  content: "";
  font-size: 18px;
}

.tbe-journey .ico-taxes:before {
  content: "";
  font-size: 16px;
}

.tbe-journey .ico-illicit:before {
  content: "";
  font-size: 26px;
}

.tbe-journey .ico-consumers:before {
  content: "";
  font-size: 29px;
}

.tbe-journey .right-section--heading {
  display: flex;
  align-items: center;
}

.tbe-journey .right-section--description {
  padding: 30px 0 70px 0;
}

.tbe-journey .right-section--cols {
  display: flex;
  flex-direction: column;
}

.tbe-journey .right-section--cols .col {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tbe-journey .right-section--cols .col p {
  margin: 0 0 36px;
}

.tbe-journey .right-section--cols .col p:last-child {
  margin: 0;
}

.tbe-journey .right-section--cols .col img {
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .tbe-journey .right-section--cols .col {
    align-items: baseline;
  }
}

.tbe-journey .right-section--cols .col:first-child {
  margin: 0 0 20px 0;
}

@media screen and (min-width: 960px) {
  .tbe-journey .right-section--cols .col:first-child {
    margin: 0;
  }
}

.tbe-journey .right-section--cols.one-col .col {
  width: auto;
  margin: 0;
}

@media screen and (min-width: 960px) {
  .tbe-journey .right-section--cols {
    flex-direction: row;
  }
  .tbe-journey .right-section--cols .col {
    width: 50%;
  }
  .tbe-journey .right-section--cols .col:first-child {
    margin-right: 20px;
  }
}

.tbe-journey .right-section--rows .row {
  margin-top: 20px;
}

.tbe-journey .col-row {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #6e6e6e;
}

.tbe-journey .list-content {
  margin-bottom: 30px;
}

.tbe-journey .list-content:not(:last-child) {
  border-bottom: 1px solid #959595;
}

.tbe-journey .list-content .icon {
  margin-right: 20px;
}

.page-info-widget-image {
  display: grid;
  grid-template-areas: "picture" "content" "notes";
  margin: 0 auto 50px;
}

@media screen and (min-width: 640px) {
  .page-info-widget-image {
    grid-template-columns: 6fr 4fr;
    grid-column-gap: 60px;
    grid-template-areas: "picture picture" "content notes";
  }
}

@media screen and (min-width: 960px) {
  .page-info-widget-image {
    grid-template-columns: 4fr 6fr;
    grid-row-gap: 0;
    grid-template-areas: "titles picture" "content picture" "notes picture";
    margin-bottom: 90px;
  }
}

.page-info-widget-image--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-info-widget-image--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.page-info-widget-image--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.page-info-widget-image--close::after {
  content: "";
  font-family: "icomoon";
}

.page-info-widget-image.no-image {
  grid-template-columns: auto;
  grid-column-gap: 0;
}

@media screen and (max-width: 959px) {
  .page-info-widget-image.image-not-selected .page-info-widget-content--title {
    color: #000;
  }
  .page-info-widget-image.image-not-selected .page-info-widget-content--category {
    color: RGB(var(--color-primary));
  }
  .page-info-widget-image.image-not-selected .small-style {
    color: inherit;
  }
}

@media screen and (max-width: 959px) {
  .page-info-widget-image.image-selected .page-info-widget-content--title {
    color: #fff;
  }
}

.page-info-widget-image--picture {
  position: relative;
  grid-area: picture;
  min-height: 120vw;
  width: auto;
  height: auto;
}

@media screen and (min-width: 480px) {
  .page-info-widget-image--picture {
    min-height: 75vw;
  }
}

@media screen and (min-width: 640px) {
  .page-info-widget-image--picture {
    min-height: 50vw;
  }
}

@media screen and (min-width: 960px) {
  .page-info-widget-image--picture {
    min-height: 0;
  }
}

@media screen and (max-width: 959px) {
  .page-info-widget-image--picture::after {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    height: 100%;
    width: calc(100% + 2 * 20px);
    background: rgba(0, 0, 0, 0.44);
  }
  .page-info-widget-image--picture .still-picture {
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 2 * 20px);
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
}

.page-info-widget-image .page-info-widget-content--titles {
  grid-area: picture;
  position: relative;
  z-index: 3;
  align-self: end;
}

@media screen and (min-width: 480px) {
  .page-info-widget-image .page-info-widget-content--titles {
    max-width: 60%;
  }
}

@media screen and (min-width: 960px) {
  .page-info-widget-image .page-info-widget-content--titles {
    grid-area: titles;
    padding-top: 60px;
    max-width: none;
  }
}

.page-info-widget-image .page-info-widget-content--content {
  padding-top: 25px;
}

@media screen and (min-width: 640px) {
  .page-info-widget-image .page-info-widget-content--content,
  .page-info-widget-image .page-info-widget-content--notes {
    padding-top: 25px;
  }
}

@media screen and (min-width: 640px) and (min-width: 960px) {
  .page-info-widget-image .page-info-widget-content--content,
  .page-info-widget-image .page-info-widget-content--notes {
    padding-top: 0;
  }
}

@media screen and (max-width: 959px) {
  .page-info-widget-image .page-info-widget-content--category,
  .page-info-widget-image .page-info-widget-content--title,
  .page-info-widget-image .page-info-widget-content--reading,
  .page-info-widget-image .small-style {
    color: white;
  }
  .page-info-widget-image .page-info-widget-content--titles {
    padding-top: 80px;
  }
}

@media screen and (min-width: 640px) and (max-width: 960px) {
  .page-info-widget-image .page-info-widget-content--description {
    margin-bottom: 0;
  }
}

.page-info-widget-image .still-picture {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.gallery-item {
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .gallery-item {
    margin: 0;
  }
}

.gallery-item--picture {
  position: relative;
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 15px;
}

.gallery-item--picture::before {
  content: "";
  width: 100%;
  padding-bottom: 100%;
  display: block;
}

.gallery-item--picture img {
  transition: 0.4s transform ease-in-out;
}

.gallery-item--picture:focus {
  outline: 4px solid #262626;
}

.gallery-item--picture:focus img {
  transition: 0.2s transform ease-in-out;
  transform: scale(1.1);
}

.gallery-item--slideshow-label {
  position: absolute;
  bottom: 20px;
  left: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.015em;
  color: white;
  width: calc(100% - 60px);
}

.gallery-item--slideshow-label span {
  width: calc(100% - 40px);
}

.gallery-item--slideshow-label::before {
  content: "";
  font-family: "icomoon";
  font-size: 15px;
  color: white;
  display: flex;
  background: RGB(var(--color-primary));
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  font-weight: normal;
}

.gallery-item--infos {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  margin-bottom: 5px;
}

.gallery-item--date {
  text-transform: none;
  letter-spacing: normal;
  color: #6e6e6e;
}

.gallery-item--date::before {
  content: "";
  display: inline-flex;
  background: #6e6e6e;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 4px 4px 8px;
}

.gallery-item--title {
  font-size: 16px;
  margin-bottom: 25px;
}

.gallery-item--menu, .gallery-item--view-all {
  display: flex;
  align-items: center;
}

.gallery-item--view-all, .gallery-item--download {
  color: RGB(var(--color-primary));
  font-size: 12px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  background: none;
  padding: 0;
}

.gallery-item--view-all:focus, .gallery-item--download:focus {
  outline: none;
  color: #262626;
}

.gallery-item--view-all::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 15px;
  border-right: 1px solid #cccccc;
  margin: 0 25px;
}

.gallery-item--download::after {
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-left: 10px;
  font-weight: normal;
}

.gallery-item--slides {
  width: 100%;
  height: 60vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 960px) {
  .gallery-item--slides {
    width: 60vw;
  }
}

.gallery-item--slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background: black;
  transition: 0.6s margin-left ease-out;
}

.gallery-item--slide img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.gallery-item--download-button {
  position: absolute;
  bottom: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.gallery-item--download-button::before {
  content: "";
  font-family: "icomoon";
  color: white;
  font-size: 12px;
}

.gallery-item--modal-menu {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.gallery-item--previous, .gallery-item--next {
  color: white;
  font-size: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 480px) {
  .gallery-item--previous, .gallery-item--next {
    font-size: 11px;
  }
}

.gallery-item--previous::before {
  font-family: "icomoon";
  color: white;
  font-size: 12px;
  margin-right: 0;
  content: "";
}

@media screen and (min-width: 480px) {
  .gallery-item--previous::before {
    margin-right: 10px;
  }
}

.gallery-item--next::after {
  font-family: "icomoon";
  color: white;
  font-size: 12px;
  margin-left: 0;
  content: "";
}

@media screen and (min-width: 480px) {
  .gallery-item--next::after {
    margin-left: 10px;
  }
}

.gallery-item--dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gallery-item--dot {
  width: 4px;
  height: 4px;
  background: white;
  margin: 0 2px;
}

@media screen and (min-width: 480px) {
  .gallery-item--dot {
    width: 5px;
    height: 5px;
    margin: 0 4px;
  }
}

@media screen and (min-width: 640px) {
  .gallery-item--dot {
    width: 6px;
    height: 6px;
    margin: 0 7px;
  }
}

@media screen and (min-width: 1200px) {
  .gallery-item--dot {
    margin: 0 10px;
  }
}

.gallery-item--dot.active {
  width: 8px;
  height: 8px;
  background: RGB(var(--color-primary));
  margin: 0 2px;
  transition: 0.2s background ease-in-out, 0.2s width ease-in-out, 0.2s height ease-in-out, 0.2s margin ease-in-out;
}

@media screen and (min-width: 480px) {
  .gallery-item--dot.active {
    width: 9px;
    height: 9px;
    margin: 0 3px;
  }
}

@media screen and (min-width: 640px) {
  .gallery-item--dot.active {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1200px) {
  .gallery-item--dot.active {
    margin: 0 8px;
  }
}

.unfoldable-list {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 640px) {
  .unfoldable-list {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 25px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}

@media screen and (min-width: 960px) {
  .unfoldable-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.unfoldable-list--title {
  font-size: 30px;
  line-height: 40px;
  color: #262626;
  font-weight: normal;
}

.unfoldable-list--note {
  font-size: 16px;
  font-weight: 300;
  color: #6e6e6e;
  margin-bottom: 20px;
}

.unfoldable-list--more {
  background: #f6f6f6;
  border: none;
  color: RGB(var(--color-primary));
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  font-family: var(--main-font);
  cursor: pointer;
  transition: 0.4s color ease-in-out, 0.4s background ease-in-out, 0.4s opacity ease-in-out, 0.6s height ease-in-out;
  position: relative;
  z-index: 10;
}

.unfoldable-list--more:hover, .unfoldable-list--more:focus, .unfoldable-list--more.adding {
  background: RGB(var(--color-primary));
  color: white;
}

.unfoldable-list--more:focus {
  outline: none;
}

.unfoldable-list--more.adding {
  pointer-events: none;
}

.unfoldable-list--more.deactivate {
  opacity: 0;
  height: 0;
}

.unfoldable-list--more.no-display {
  display: none;
}

.unfoldable-list .large {
  grid-column-end: span 2;
}

@media screen and (min-width: 960px) {
  .unfoldable-list .large {
    grid-column-end: span 3;
  }
}

.unfoldable-list .large-image-text-on-image {
  padding-bottom: 35px;
  margin-left: -20px;
  width: calc(100% + (2 * 20px));
}

@media screen and (min-width: 700px) {
  .unfoldable-list .large-image-text-on-image {
    display: flex;
    grid-column-end: span 2;
  }
}

@media screen and (min-width: 960px) {
  .unfoldable-list .large-image-text-on-image {
    grid-column-end: span 2;
    grid-row-end: span 2;
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 639px) {
  .unfoldable-list .large-image-text-on-image {
    flex-flow: row;
    justify-content: flex-start;
    margin: 0 0 25px 0;
    padding: 0;
  }
  .unfoldable-list .large-image-text-on-image--content {
    position: relative;
    display: flex;
    flex-flow: column;
    padding-left: 25px;
  }
  .unfoldable-list .large-image-text-on-image .back-picture {
    position: relative;
    width: 100px;
    min-width: 100px;
    height: auto;
    max-height: 100px;
  }
  .unfoldable-list .large-image-text-on-image .back-picture:after {
    content: none;
  }
  .unfoldable-list .large-image-text-on-image .box-article-content--title {
    color: #262626;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0 0 7px 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .unfoldable-list .large-image-text-on-image .box-article-content--category {
    color: RGB(var(--color-primary));
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.15em;
    font-weight: normal;
  }
  .unfoldable-list .large-image-text-on-image .box-article-content--reading {
    color: #6e6e6e;
    margin: 0;
  }
  .unfoldable-list .large-image-text-on-image .box-article-content--link {
    color: #6e6e6e;
    padding-top: 10px;
  }
  .unfoldable-list .large-image-text-on-image .box-article-content--link:after {
    content: none;
  }
}

.unfoldable-list .small-image-above-title {
  margin-bottom: 25px;
}

@media screen and (min-width: 700px) {
  .unfoldable-list .small-image-above-title {
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .unfoldable-list.four-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .unfoldable-list.four-columns .large {
    grid-column-end: span 4;
  }
}

@media screen and (min-width: 1200px) {
  .unfoldable-list.four-columns .large-image-text-on-image {
    grid-column-end: span 3;
    grid-row-end: span 2;
  }
}

.tab-widget--title-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.tab-widget--title-wrapper .title {
  font-weight: 400;
}

.tab-widget--tabs {
  margin-bottom: 18px;
  border-bottom: 1px solid #cccccc;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: none;
}

.tab-widget--tabs::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 960px) {
  .tab-widget--tabs {
    display: flex;
  }
}

.tab-widget--tab {
  padding-bottom: 18px;
  border: none;
  outline: none;
  color: #6e6e6e;
  background: none;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  display: none;
}

@media screen and (min-width: 960px) {
  .tab-widget--tab {
    width: 100%;
    white-space: normal;
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .tab-widget--tab {
    width: auto;
    min-width: 30%;
  }
}

@media screen and (max-width: 480px) {
  .tab-widget--tab {
    min-width: 40%;
  }
}

.tab-widget--tab:disabled {
  border-bottom: 2px solid RGB(var(--color-primary));
  color: RGB(var(--color-primary));
  display: block;
  margin: auto;
}

@media screen and (min-width: 960px) {
  .tab-widget--tab:disabled {
    display: inline-block;
  }
}

.tab-widget--tab:focus {
  color: RGB(var(--color-primary));
}

.tab-widget--nav-dots {
  display: flex;
  margin: 0 35px;
  justify-content: center;
  padding: 0 0 15px;
}

@media screen and (min-width: 960px) {
  .tab-widget--nav-dots {
    display: none;
  }
}

.tab-widget--nav-dot {
  margin: 0 5px;
  padding: 0;
  width: 7px;
  height: 7px;
  background: var(--text-grey);
  transition: 0.2s background ease-in-out, 0.2s transform ease-in-out;
  border: none;
  color: transparent;
  font-size: 0;
}

.tab-widget--nav-dot:disabled {
  transform: scale(1.43);
  background: var(--background-primary);
}

.tab-widget--nav-dot:focus {
  outline: 4px solid #262626;
  background: white;
}

@media screen and (min-width: 960px) {
  .tab-widget--nav-dot:last-child {
    display: none;
  }
}

.tab-widget--nav-btn {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 11;
  padding: 0;
  background-color: transparent;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 10px;
  border: 0;
  color: RGB(var(--color-primary));
  border-radius: 100%;
  transform: translate(0, -50%);
  font-weight: bold;
}

.tab-widget--nav-btn::after {
  font-family: "icomoon";
  font-size: 13px;
}

.tab-widget--nav-btn--inverse {
  color: white;
}

@media screen and (min-width: 960px) {
  .tab-widget--nav-btn {
    display: none;
  }
}

.tab-widget--prev-btn {
  left: 15px;
}

.tab-widget--prev-btn::after {
  content: "";
}

.tab-widget--next-btn {
  right: 15px;
}

.tab-widget--next-btn::after {
  content: "";
}

.tab-widget--content {
  position: relative;
  background-color: #f6f6f6;
  width: 100%;
  min-width: 100%;
  transform: translateX(calc(var(--current-slide) * -100%));
  transition: 0.8s transform ease-in-out;
}

.tab-widget--content-inner {
  position: relative;
  padding: 20px 50px;
}

@media screen and (min-width: 960px) {
  .tab-widget--content-inner {
    padding: 70px 100px;
  }
}

.tab-widget--content-title {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 400;
}

.tab-widget--content-wrapper {
  position: relative;
  --current-slide: 0;
  display: flex;
  max-width: 100%;
  overflow: hidden;
}

.tab-widget--content-wrapper img {
  height: auto;
}

.tab-widget .arrow-link {
  display: block;
  margin: 20px 0;
  text-transform: uppercase;
  font-weight: 400;
  color: black;
}

@media screen and (min-width: 960px) {
  .tab-widget .arrow-link {
    margin-bottom: 0px;
  }
}

.tab-widget .tab-widget--content-inner.white-text,
.tab-widget .arrow-link.white-text {
  color: white;
}

.investor-events-overview--title {
  font-size: 30px;
  font-weight: 400;
}

.investor-events-overview--content {
  display: block;
  width: 100%;
}

.investor-events-overview--content:focus {
  outline: none;
}

.investor-events-overview--content:focus .investor-events-overview--content-title {
  color: RGB(var(--color-primary));
}

.investor-events-overview--content-date {
  color: #6e6e6e;
}

.investor-events-overview--content-title {
  color: black;
}

.investor-events-overview--no-events-found {
  margin-top: 40px;
  padding: 70px;
  text-align: center;
  font-size: 20px;
  color: #6e6e6e;
  background-color: #f6f6f6;
}

@media screen and (min-width: 960px) {
  .investor-events-overview--no-events-found {
    font-size: 28px;
  }
}

.investor-events-overview--page {
  width: 100%;
  min-width: 100%;
  display: none;
}

.changing-page .investor-events-overview--page {
  transform: translateX(calc(var(--offset) * -100% * var(--factor)));
}

.investor-events-overview--page.active {
  display: block;
}

.investor-events-overview--pages {
  --offset: 0;
  --factor: 1;
  display: flex;
  align-items: flex-start;
  transition: 0.4s height ease-in-out;
}

[dir="rtl"] .investor-events-overview--pages {
  --factor: -1;
}

.investor-events-overview--pages.changing-page {
  overflow: hidden;
}

.investor-events-overview--pages.changing-page .investor-events-overview--page {
  display: block;
}

.investor-events-overview .arrow-link {
  display: block;
  padding-top: 40px;
  text-align: right;
  font-size: 12px;
}

[dir="rtl"] .investor-events-overview .arrow-link {
  text-align: left;
}

[dir="rtl"] .investor-events-overview .arrow-link:after {
  transform: rotate(180deg);
  display: inline-block;
}

@media screen and (min-width: 640px) {
  .investor-events-overview:not(.is-upcoming) {
    max-width: calc(90% - 30px);
  }
}

@media screen and (min-width: 960px) {
  .investor-events-overview:not(.is-upcoming) {
    max-width: calc(90% - 110px);
  }
}

.investor-events-overview:not(.is-upcoming) .investor-events-overview--title {
  margin-bottom: 20px;
}

.investor-events-overview:not(.is-upcoming) .investor-events-overview--container {
  margin: 0 -20px;
  padding: 40px 50px 40px 20px;
  background-color: #f6f6f6;
}

@media screen and (min-width: 640px) {
  .investor-events-overview:not(.is-upcoming) .investor-events-overview--container {
    margin: 0;
  }
}

.investor-events-overview:not(.is-upcoming) .investor-events-overview--content {
  margin-bottom: 30px;
  line-height: 1.5rem;
}

.investor-events-overview:not(.is-upcoming) .investor-events-overview--content-date {
  font-size: 11px;
}

.investor-events-overview:not(.is-upcoming) .investor-events-overview--content-title {
  font-weight: 600;
}

.ir-app {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.ir-app--title {
  font-size: 30px;
  font-weight: normal;
  color: white;
  margin-bottom: 20px;
}

.ir-app--description {
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 40px;
}

.ir-app--links {
  display: flex;
  justify-content: flex-start;
}

.ir-app--link {
  outline: none;
  transition: 0.4s transform ease-in-out;
}

.ir-app--link img {
  width: 200px;
  height: auto;
}

.ir-app--link:first-child {
  margin-right: 30px;
}

.ir-app--link:focus {
  transition: 0.2s transform ease-in-out;
  transform: scale(1.1);
}

.ir-app.cta-mode {
  padding: 15px 20px;
  background: RGB(var(--color-primary));
}

.ir-app.cta-mode .ir-app--title {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}

.ir-app.cta-mode .ir-app--description {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.ir-app.cta-mode .ir-app--links {
  flex-flow: column;
}

.ir-app.cta-mode .ir-app--link {
  margin-right: 0;
  margin-bottom: 5px;
}

.ir-app.cta-mode.is-white {
  background: white;
  border: 1px solid #cccccc;
}

.ir-app.cta-mode.is-white .ir-app--title {
  color: #6e6e6e;
}

.ir-app.cta-mode.is-white .ir-app--description {
  color: #262626;
}

.investor-cta--title {
  font-size: 12px;
}

.investor-cta-newsletter .investor-cta--title {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 10px;
}

.investor-cta--description {
  font-size: 18px;
  font-weight: 600;
}

.investor-cta-newsletter .investor-cta--description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-right: 0;
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter .investor-cta--description {
    margin-right: inherit;
    margin-top: 0;
    padding-right: 30px;
  }
}

.investor-cta--cta-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 4px;
}

.investor-cta--cta-wrapper.align-to-end {
  justify-content: flex-end;
}

.investor-cta--label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.2px;
}

.investor-cta--arrowed-link:before, .investor-cta--download-link:before {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "icomoon";
  font-size: 14px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid RGB(var(--color-primary));
  transition: 0.2s all ease-in-out;
}

.investor-cta-widget-blue .investor-cta--arrowed-link:before, .investor-cta-widget-blue .investor-cta--download-link:before {
  border-color: transparent;
}

.investor-cta--arrowed-link:before {
  content: "";
}

.investor-cta--download-link:before {
  content: "";
}

a:focus .investor-cta .investor-cta--arrowed-link:before,
a:focus .investor-cta .investor-cta--download-link:before, .investor-cta:focus .investor-cta--arrowed-link:before,
.investor-cta:focus .investor-cta--download-link:before,
a:hover .investor-cta .investor-cta--arrowed-link:before,
a:hover .investor-cta .investor-cta--download-link:before, .investor-cta:hover .investor-cta--arrowed-link:before,
.investor-cta:hover .investor-cta--download-link:before {
  transform: scale(1.2);
}

.investor-cta-newsletter {
  display: block;
  padding: 40px 30px;
  margin-bottom: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  background-color: RGB(var(--color-primary));
  border: 1px solid RGB(var(--color-primary));
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter {
    padding: 50px 30px;
  }
}

.investor-cta-newsletter:focus {
  outline: none;
  border-color: white;
}

.investor-cta-newsletter .investor-cta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter .investor-cta {
    flex-direction: row;
    justify-content: space-between;
  }
}

.investor-cta-newsletter .investor-cta--title, .investor-cta-newsletter .investor-cta--description, .investor-cta-newsletter .investor-cta--label {
  color: white;
}

.investor-cta-newsletter .investor-cta--newsletter-button {
  margin-right: auto;
  background-color: white;
  color: #262626;
  text-transform: uppercase;
  align-self: center;
  padding: 10px 30px;
  margin-top: 70px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  border: none;
}

@media screen and (min-width: 700px) {
  .investor-cta-newsletter .investor-cta--newsletter-button {
    margin-right: inherit;
    margin-top: 0;
  }
}

.news-and-press-releases {
  padding-top: 80px;
}

.news-and-press-releases--title, .news-and-press-releases--article-count {
  margin-bottom: 25px;
}

.news-and-press-releases--title {
  font-weight: 500;
  font-size: 28px;
}

.news-and-press-releases--article-count {
  color: #6e6e6e;
  font-weight: 300;
}

.news-and-press-releases--lists-wrapper {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1200px) {
  .news-and-press-releases--lists-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.news-and-press-releases--lists-wrapper.one-column-layout {
  flex-direction: column;
}

.news-and-press-releases--lists-wrapper.one-column-layout .news-and-press-releases--lists {
  width: 100%;
}

@media screen and (min-width: 700px) {
  .news-and-press-releases--lists-wrapper.one-column-layout .news-and-press-releases--input-container:first-child {
    flex-basis: calc(40% - 10px);
  }
}

@media screen and (min-width: 700px) {
  .news-and-press-releases--lists-wrapper.one-column-layout .news-and-press-releases--input-container:nth-child(2),
  .news-and-press-releases--lists-wrapper.one-column-layout .news-and-press-releases--input-container:nth-child(3) {
    flex-basis: calc(30% - 10px);
    margin-top: 0;
  }
}

.news-and-press-releases--lists {
  margin-bottom: 20px;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .news-and-press-releases--lists {
    width: calc(50% - 14px);
  }
  .news-and-press-releases--lists.active {
    width: 100%;
  }
}

.news-and-press-releases--header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 70px;
  padding: 0 22px;
  color: white;
  background-color: RGB(var(--color-primary));
}

.news-and-press-releases .search-header {
  position: relative;
  margin-bottom: 30px;
  background-color: #f6f6f6;
  color: #000;
}

.news-and-press-releases .search-header .big-search-button:hover, .news-and-press-releases .search-header .big-search-button:focus {
  background-color: RGB(var(--color-primary));
}

.news-and-press-releases--icon-press:before, .news-and-press-releases--icon-news:before {
  font-family: "icomoon";
  margin-right: 10px;
  font-size: 25px;
}

[dir="rtl"] .news-and-press-releases--icon-press:before, [dir="rtl"] .news-and-press-releases--icon-news:before {
  margin-left: 10px;
}

.news-and-press-releases--icon-press:before {
  content: "";
}

.news-and-press-releases--icon-news:before {
  content: "";
}

.news-and-press-releases--title-container {
  width: 100%;
  display: flex;
  min-height: 70px;
  align-items: center;
}

.news-and-press-releases--form-container {
  position: relative;
  max-height: 0;
  transition: 0.4s all ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.news-and-press-releases--form-container.active {
  visibility: visible;
  height: auto;
  max-height: 300px;
  opacity: 1;
}

.news-and-press-releases--search-button {
  margin-left: auto;
  background: none;
  border: none;
  color: RGB(var(--color-primary));
  font-size: 14px;
}

.news-and-press-releases--search-button:after {
  content: " ";
  border: solid RGB(var(--color-primary));
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(-135deg);
  transition: 0.4s all ease-in-out;
}

[dir="rtl"] .news-and-press-releases--search-button {
  margin-left: 0;
  margin-right: auto;
}

[dir="rtl"] .news-and-press-releases--search-button:after {
  margin-left: 0;
  margin-right: 10px;
}

.news-and-press-releases--search-button.arrow-down:after {
  transform: rotate(45deg);
}

.news-and-press-releases--row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 0 15px 0;
}

.news-and-press-releases--input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: white;
  border: 1px solid #e5e5e5;
  font-size: 14px;
}

.news-and-press-releases--input:focus {
  outline: 4px solid #262626;
}

.news-and-press-releases--input::placeholder {
  color: #000;
  opacity: 0.7;
  font-size: 16px;
}

.news-and-press-releases--input-container:first-child, .news-and-press-releases--button-container {
  flex-basis: 100%;
  text-align: center;
}

.news-and-press-releases--input-container:nth-child(2), .news-and-press-releases--input-container:nth-child(3) {
  flex-basis: 100%;
  margin-top: 20px;
}

@media screen and (min-width: 700px) {
  .news-and-press-releases--input-container:nth-child(2), .news-and-press-releases--input-container:nth-child(3) {
    flex-basis: calc(50% - 10px);
    margin-top: 40px;
  }
}

.news-and-press-releases--clear-button {
  opacity: 0;
  visibility: hidden;
  color: RGB(var(--color-primary));
  border: none;
  background: none;
  padding: 0;
  margin: 10px 0 50px 0;
  align-self: center;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

@media screen and (min-width: 700px) {
  .news-and-press-releases--clear-button {
    margin: 30px 0 50px 0;
  }
}

.news-and-press-releases--clear-button.active {
  opacity: 1;
  visibility: visible;
}

.news-and-press-releases--calendar-container {
  position: relative;
}

.news-and-press-releases--calendar-container::before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: RGB(var(--color-primary));
  pointer-events: none;
}

[dir="rtl"] .news-and-press-releases--calendar-container::before {
  right: auto;
  left: 15px;
}

.news-and-press-releases--calendar-from, .news-and-press-releases--calendar-to {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  height: 0;
  transition: 0.2s all ease-in-out;
}

@media screen and (min-width: 700px) {
  .news-and-press-releases--calendar-from .pika-single, .news-and-press-releases--calendar-to .pika-single {
    position: absolute !important;
    width: 100%;
  }
}

.news-and-press-releases--calendar-from.active, .news-and-press-releases--calendar-to.active {
  z-index: 1;
  display: block;
  visibility: visible;
  opacity: 1;
}

.news-and-press-releases .pika-lendar {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  z-index: 2;
}

.news-and-press-releases .pika-title {
  display: flex;
  align-items: center;
  background: RGB(var(--color-primary));
  padding: 15px 30px 0;
  color: white;
}

.news-and-press-releases .pika-single thead {
  background: RGB(var(--color-primary));
}

.news-and-press-releases .pika-single abbr {
  color: white;
  opacity: 0.5;
}

.news-and-press-releases .pika-label, .news-and-press-releases .pika-select, .news-and-press-releases .pika-prev::before, .news-and-press-releases .pika-next::before {
  color: white;
}

.news-and-press-releases .pika-select option {
  color: RGB(var(--color-primary));
}

.news-and-press-releases .pika-single th abbr,
.news-and-press-releases .pika-single td button {
  width: 30px;
}

.speech-bubble {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 25px;
}

.speech-bubble svg {
  position: absolute;
  height: 25px;
  right: 25px;
  bottom: -25px;
  width: 45%;
}

.speech-bubble.mirrored svg {
  left: 25px;
  right: auto;
  transform: scaleX(-1);
}

.calendar-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.calendar-menu--menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 160px;
  bottom: 15px;
  right: 0;
  padding: 15px;
  margin-bottom: 25px;
  opacity: 0;
  background-color: white;
  pointer-events: none;
  transition: 0.4s opacity ease-in-out;
  z-index: 2;
}

.calendar-menu--menu.active {
  opacity: 1;
  pointer-events: all;
  box-shadow: 0 0 15px rgba(57, 57, 57, 0.3);
}

[dir="rtl"] .calendar-menu--menu {
  right: unset;
  left: 0;
}

.calendar-menu--arrow {
  position: absolute;
  bottom: -30px;
  right: 5px;
  width: 60px;
  height: 30px;
  overflow: hidden;
  z-index: 3;
  pointer-events: none;
}

.calendar-menu--arrow::before {
  content: '';
  position: absolute;
  display: block;
  width: 21.2132px;
  height: 21.2132px;
  top: -10.6066px;
  left: 19.3934px;
  transform: translateY(-100%) rotate(45deg);
}

.calendar-menu--arrow::before {
  background: white;
  transition: 0.4s transform ease-in-out, 0.4s opacity ease-in-out, 0.4s box-shadow ease-in-out;
  opacity: 0;
}

.active .calendar-menu--arrow::before {
  transform: rotate(45deg);
  box-shadow: 0 0 15px rgba(57, 57, 57, 0.3);
  opacity: 1;
}

.calendar-menu--top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.calendar-menu--close-button {
  float: right;
  padding: 0;
  background: none;
  border: none;
  margin-top: 4px;
  font-size: 8px;
  color: #cccccc;
  cursor: pointer;
}

.calendar-menu--close-button::after {
  content: "";
  font-family: "icomoon";
}

.calendar-menu--title {
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.calendar-menu--item {
  padding-bottom: 10px;
  font-size: 11px;
  color: #6e6e6e;
}

.calendar-menu--item::before {
  vertical-align: 20%;
  margin-right: 14px;
  content: "";
  font-family: "icomoon";
  font-size: 7px;
  color: #6e6e6e;
}

[dir="rtl"] .calendar-menu--item:before {
  transform: rotate(180deg);
  display: inline-block;
  margin-left: 14px;
}

.calendar-menu--item:hover {
  color: RGB(var(--color-primary));
}

.calendar-menu--button {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  border: 0;
  background: none;
  color: #6e6e6e;
}

.calendar-menu--button::before {
  margin-right: 10px;
  content: "";
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  font-weight: normal;
}

.calendar-menu--button:focus {
  outline: none;
  color: RGB(var(--color-primary));
}

.calendar-menu--button:focus::before {
  color: #262626;
}

.loading-overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: 0.4s opacity ease-in-out 0.2s;
  pointer-events: none;
  opacity: 0;
}

.loading-overlay svg {
  max-width: 90px;
  animation-name: video-rotate;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-bottom: 10px;
}

.loading-overlay circle {
  fill: none;
  stroke-width: 10px;
  transition: 0.4s stroke-dasharray ease-in-out;
}

.loading-overlay circle:nth-child(1) {
  stroke: #cccccc;
}

.loading-overlay circle:nth-child(2) {
  stroke: RGB(var(--color-primary));
  stroke-dasharray: 235px;
  stroke-dashoffset: 0;
}

.loading-overlay.active {
  opacity: 1;
  pointer-events: all;
  cursor: wait;
}

.sustainability-filter-result {
  position: relative;
  background: RGB(var(--color-primary));
  --aspect-ratio: 100%;
}

.sustainability-filter-result::before {
  content: '';
  display: block;
  padding-bottom: var(--aspect-ratio);
}

.sustainability-filter-result .still-picture {
  width: auto;
  height: auto;
}

@media screen and (min-width: 640px) {
  .sustainability-filter-result {
    --aspect-ratio: 100%;
  }
}

.sustainability-filter-result--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 40px 20px;
}

.sustainability-filter-result--title, .sustainability-filter-result--read-more {
  color: white;
}

.sustainability-filter-result--title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 30px;
}

@media screen and (min-width: 380px) {
  .sustainability-filter-result--title {
    -webkit-line-clamp: 3;
  }
}

@media screen and (min-width: 480px) {
  .sustainability-filter-result--title {
    -webkit-line-clamp: 4;
  }
}

.sustainability-filter-result--read-more::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sustainability-filter-result--read-more:focus {
  outline: none;
}

.sustainability-filter-result img {
  transition: 0.6s transform ease-in-out;
}

.sustainability-filter-result:hover img, .sustainability-filter-result:focus-within img {
  transition: 0.4s transform ease-in-out;
  transform: scale(1.05);
}

.sustainability-filter-result:focus-within {
  outline: 4px solid #262626;
}

.document-list {
  position: relative;
}

.document-list--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.document-list--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.document-list--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.document-list--close::after {
  content: "";
  font-family: "icomoon";
}

.document-list--top {
  background: #f6f6f6;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.document-list--title-container {
  align-self: center;
}

.document-list--notes {
  margin-bottom: 3px;
}

.document-list--notes span {
  margin-left: 5px;
}

.document-list--title {
  font-size: 20px;
}

.document-list--download {
  display: flex;
}

.document-list--download:focus {
  outline: none;
}

.document-list--download:focus .document-list--download-label {
  color: #262626;
  transition: 0.2s color ease-in-out;
}

.document-list--download:focus .document-list--download-button {
  background: #262626;
  transition: 0.2s color ease-in-out;
}

.document-list--download-label {
  align-self: center;
  text-transform: uppercase;
  margin-right: 18px;
  font-size: 12px;
  transition: 0.4s color ease-in-out;
}

.document-list--download-button {
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: RGB(var(--color-primary));
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s background ease-in-out, 0.4s color ease-in-out;
}

.document-list--download-button::before {
  content: "";
  font-family: "icomoon";
  font-size: 13px;
}

.document-list--download-button:hover {
  background: var(--color-primary-hover);
  color: white;
}

.document-list--pages {
  --offset: 0;
  --factor: 1;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

[dir='rtl'] .document-list--pages {
  --factor: -1;
}

.document-list--page {
  display: grid;
  align-items: center;
  min-width: 100%;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, auto) 1fr;
  transform: translateX(calc(var(--offset) * -100% * var(--factor)));
}

.document-list--page.key-document {
  grid-template-columns: repeat(2, auto) 1fr;
}

@media screen and (min-width: 640px) {
  .document-list--page.key-document {
    grid-column-gap: 40px;
    grid-template-columns: 1fr repeat(3, auto);
  }
}

.document-list--page.key-document .document-row a {
  margin-right: 7px;
}

[dir='rtl'] .document-list--page.key-document .document-row a {
  margin-right: 0;
  margin-left: 7px;
}

@media screen and (min-width: 640px) {
  .document-list--page.key-document .document-row a {
    margin-right: -19px;
  }
}

@media screen and (min-width: 640px) {
  .document-list--page {
    grid-column-gap: 40px;
    grid-template-columns: 1fr repeat(4, auto);
  }
}

@supports not (display: contents) {
  .document-list--page {
    display: flex;
    flex-flow: column;
  }
  .document-list--page.key-document .document-row a {
    margin-right: 30px;
  }
}

.document-list .pages-nav {
  padding: 0 30px;
}

@media screen and (min-width: 640px) {
  .document-list .pages-nav {
    padding: 0;
  }
}

@media screen and (min-width: 700px) {
  .document-list .pages-nav {
    padding: 0 30px;
  }
}

.document-row {
  display: contents;
}

.document-row--warning {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 6;
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.document-row--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.document-row--close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.document-row--close::after {
  content: "";
  font-family: "icomoon";
}

.document-row--border {
  border-bottom: 1px solid #cccccc;
  grid-column-start: span 4;
}

@media screen and (min-width: 640px) {
  .document-row--border {
    grid-column-start: span 5;
  }
}

.document-row--title {
  margin: 20px 0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  grid-column-start: span 4;
}

[dir='rtl'] .document-row--title:after {
  content: '\200E‎';
}

[dir='rtl'] .document-row--title:before {
  content: '\200E‎';
}

@media screen and (min-width: 640px) {
  .document-row--title {
    grid-column-start: auto;
    padding-right: 0;
  }
  [dir='rtl'] .document-row--title {
    padding-left: 0;
    padding-right: 20px;
  }
}

.document-row--notes {
  display: contents;
}

@media screen and (max-width: 639px) {
  .document-row--notes p {
    margin-bottom: 20px;
  }
  .document-row--notes p:nth-of-type(1) {
    padding-left: 20px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 639px) and (max-width: 640px) {
  [dir='rtl'] .document-row--notes p:nth-of-type(1) {
    margin-left: 0;
    margin-right: 30px;
  }
}

@media screen and (max-width: 639px) {
  .document-row--notes a {
    margin-bottom: 20px;
  }
}

.document-row a {
  font-family: "icomoon";
  font-size: 13px;
  color: RGB(var(--color-primary));
  margin-right: 30px;
  transition: 0.4s color ease-in-out, 0.4s transform ease-in-out;
  text-align: right;
}

.document-row a::before {
  content: "";
}

.document-row a:hover {
  color: var(--color-primary-hover);
}

.document-row a:focus {
  outline: none;
  color: #262626;
  transition: 0.2s color ease-in-out, 0.2s transform ease-in-out;
  transform: scale(1.1);
}

[dir='rtl'] .document-row a {
  margin-right: 0;
  margin-left: 30px;
}

@media screen and (max-width: 640px) {
  [dir='rtl'] .document-row a {
    text-align: left;
  }
}

@supports not (display: contents) {
  .document-row {
    width: 100%;
    border-bottom: 1px solid #cccccc;
  }
  @media screen and (max-width: 639px) {
    .document-row {
      flex-wrap: wrap;
    }
    .document-row--title {
      width: 100%;
    }
    .document-row a {
      margin-left: auto;
    }
    .document-row--notes {
      width: 100%;
    }
  }
  .document-row, .document-row--notes {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .document-row--border {
    display: none;
  }
  .document-row p,
  .document-row a {
    padding-left: 10px;
  }
}

.wheel-slice {
  --back-color: rgb(var(--color-primary));
  --frontColor: white;
  --shadow-opacity: 0.16;
  --rotation-speed: calc(0.4s * var(--rotation-speed-factor));
  --start-offset: 0.5;
  --slices-opening-angle: calc(360deg / var(--slices-count));
  --slice-opening-angle: var(--slices-opening-angle);
  --slice-position: var(--slice-index);
  --content-band-offset: 0.36;
  --content-offset: 0.34;
  --offset-speed: 0.4s;
  --opacity-offset: var(--slice-index);
  --slice-index-factor: 1;
  --rotation: calc(
		calc(
		var(--slice-index) * var(--slice-index-factor) - var(--start-offset) -
		calc(var(--current-slice))
		) * var(--slices-opening-angle) +
		calc(360deg * var(--additional-full-turn))
	);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(calc(100% - 7px) calc(100% - 7px), 0% calc(100% - 7px), 0% 0%, calc(100% - calc(100% * var(--angle-cos))) calc(100% - calc(100% * var(--angle-sin))), 100% 100%);
  opacity: 0;
  transform: rotate(var(--rotation)) translate(-50%, -50%);
  transition: var(--rotation-speed) transform ease-in-out, var(--rotation-speed) clip-path ease-in-out, var(--rotation-speed) opacity ease-in-out var(--opacity-delay-override, calc(var(--rotation-speed) / var(--slices-count) * var(--slice-index)));
  pointer-events: none;
}

.controlled .wheel-slice {
  --rotation-speed-factor: 0;
}

.wheel-slice.tier-two {
  --back-color: rgb(var(--color-primary), 0.75);
}

.wheel-slice.active {
  opacity: 1;
  pointer-events: all;
}

.wheel-slice.is-wide {
  clip-path: polygon(calc(100% - 7px) calc(100% - 7px), 0% calc(100% - 7px), 0% 0%, 100% 0%, calc(100% - calc(100% * var(--angle-cos))) calc(100% - calc(100% * var(--angle-sin))), 100% 100%);
}

.wheel-slice:not(.active) {
  --shadow-opacity: 0;
}

.wheel-slice:not(.active):not(.tier-group) {
  --slice-index: 0.00000001 !important;
  clip-path: polygon(100% 100%, 0% 100%, 0% 100%, 0% 100%, 100% 100%);
}

.wheel-slice:not(.active).section-slice {
  --additional-full-turn: 1;
}

.wheel-slice.is-full-width {
  clip-path: none;
  --rotation: -90deg;
}

.wheel-slice.is-full-width.active {
  --rotation: 270deg;
}

.wheel-slice--highlight, .wheel-slice::before, .wheel-slice::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid var(--back-color);
  border-radius: 50%;
  transform: translate(50%, 50%);
  pointer-events: none;
  transition: 0.4s border-color ease-in-out, var(--offset-speed) width ease-in-out, var(--offset-speed) height ease-in-out, 0.4s filter ease-in-out;
}

.wheel-slice::before {
  width: calc(var(--wheel-size) * 0.3);
  height: calc(var(--wheel-size) * 0.3);
  border-width: calc(var(--wheel-size) * 0.01);
  border-color: RGB(var(--color-primary));
}

.wheel-slice::after {
  width: calc(var(--wheel-size) * var(--content-band-offset));
  height: calc(var(--wheel-size) * var(--content-band-offset));
  border-width: calc(var(--wheel-size) * 0.32);
}

@media screen and (min-width: 960px) {
  .wheel-slice::after {
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, var(--shadow-opacity)));
  }
}

@media screen and (min-width: 960px) {
  .wheel-slice:hover, .wheel-slice:focus-within {
    --content-band-offset: 0.385;
    --content-offset: 0.36;
    --offset-speed: 0.2s;
  }
  .wheel-slice.isActiveSection {
    --content-band-offset: 0.415;
    --content-offset: 0.36;
    --offset-speed: 0.2s;
  }
  .wheel-slice.isActiveSection::before {
    transform: translate(46%, 46%) scale(1.08);
  }
}

.wheel-slice.tier-one::before, .wheel-slice.tier-two::before {
  display: none;
}

.wheel-slice.tier-group {
  --tier-band-size: calc(var(--wheel-size) * 0.22);
  --opacity-offset: 0;
  --opacity-delay-override: 0s;
}

.wheel-slice.tier-group::after {
  width: var(--tier-band-size);
  height: var(--tier-band-size);
  border-width: calc(var(--wheel-size) * 0.05);
  filter: none;
}

.wheel-slice.tier-group:not(.section-slice):before {
  display: none;
}

.wheel-slice.tier-group:not(.active) {
  --additional-full-turn: -1;
}

.wheel-slice svg {
  --tier-text-container-size: calc(var(--wheel-size) * 0.28);
  position: absolute;
  right: calc(var(--tier-text-container-size) * -0.5);
  bottom: calc(var(--tier-text-container-size) * -0.5);
  width: var(--tier-text-container-size);
  height: var(--tier-text-container-size);
  transform: rotate(calc(180deg * calc(var(--slice-width) / var(--slices-count))));
  z-index: 2;
  /* stylelint-disable */
  /* stylelint-enable */
}

.wheel-slice svg textPath {
  fill: white;
  font-size: 1px;
}

.wheel-slice.tier-two svg {
  --tier-text-container-size: calc(var(--wheel-size) * 0.32);
}

.wheel-slice::marker {
  display: none;
}

.wheel-slice--clickable {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: transparent;
  background: none;
  border: none;
  z-index: 2;
}

.wheel-slice--clickable:focus {
  outline: none;
}

.wheel-slice--content-positioner {
  --content-distance-radius: calc(var(--wheel-size) * var(--content-offset));
  position: absolute;
  bottom: 3.5px;
  right: 0;
  z-index: 1;
  pointer-events: none;
  transform: translate(50%, 50%) rotate(calc(-90deg + calc(var(--slice-opening-angle) / 2)));
  transition: 0.4s transform ease-in-out;
}

.wheel-slice--content-mover {
  transform: translateY(calc(0px - var(--content-distance-radius)));
  transition: var(--offset-speed) transform ease-in-out;
}

.wheel-slice--content {
  display: flex;
  flex-flow: column;
  align-items: center;
  transform: rotate(calc(0deg - var(--rotation))) rotate(calc(90deg - var(--slice-opening-angle) / 2));
  transition: var(--rotation-speed) transform ease-in-out;
}

.wheel-slice--icon {
  position: relative;
  width: 58px;
  height: 58px;
  margin-bottom: 15px;
  background: white;
  border-radius: 50%;
}

.wheel-slice--icon::before, .wheel-slice--icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
}

.wheel-slice--icon::before {
  content: '';
  display: block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1px solid RGB(var(--color-primary));
  transform: translate(-50%, -50%);
}

.wheel-slice--icon::after {
  font-size: 30px;
  font-family: "icomoon";
  transform: translate(-50%, -50%);
  color: RGB(var(--color-primary));
  border-color: RGB(var(--color-primary));
}

.color--innovating .wheel-slice--icon::after {
  content: "";
}

.color--operating .wheel-slice--icon::after {
  content: "";
  padding-bottom: 2px;
}

.color--caring .wheel-slice--icon::after {
  content: "";
}

.color--protecting .wheel-slice--icon::after {
  content: "";
}

.wheel-slice--title {
  width: calc(var(--wheel-size) * 0.22);
  font-size: 12px;
  font-weight: normal;
  color: var(--frontColor);
  text-transform: uppercase;
  text-align: center;
}

.modal.ir-email-modal-partial.waiting {
  cursor: wait;
}

@media screen and (min-width: 1600px) {
  .modal.ir-email-modal-partial.open {
    grid-template-columns: 1fr auto 1fr;
  }
}

@media screen and (min-width: 1600px) {
  .modal.ir-email-modal-partial--content {
    max-width: 40vw;
  }
}

.ir-email-modal {
  flex-grow: 1;
  width: 100%;
  background: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 30px 20px 25px;
}

@media screen and (min-width: 640px) {
  .ir-email-modal {
    padding: 45px 80px 60px;
  }
}

.ir-email-modal--title {
  color: #393939;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 15px;
}

.ir-email-modal--subtitle {
  color: #393939;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
  text-align: center;
}

@media screen and (min-width: 640px) {
  .ir-email-modal--subtitle {
    padding: 0 40px;
  }
}

.ir-email-modal iframe {
  width: 100%;
  height: 50vh;
}

.ir-email-modal--gigya {
  width: 100%;
  max-height: 50vh;
  overflow: auto;
}

.market-search-menu .search-field-drop {
  padding: 20px 20px 0;
}

[dir='rtl'] .market-search-menu .search-field-drop {
  text-align: right;
}

.market-search-menu .search-field-drop--field {
  height: 18px;
  margin-left: 25px;
  border-bottom: none;
}

.market-search-menu .search-field-drop--field input {
  top: 0;
}

[dir='rtl'] .market-search-menu .search-field-drop--field input {
  text-align: right;
}

.market-search-menu .search-field-drop--field::before {
  content: "";
  position: absolute;
  font-family: "icomoon";
  font-size: 16px;
  color: #6e6e6e;
  left: -30px;
  top: 10px;
  transform: translateY(-50%);
}

.market-search-menu .search-field-drop--back-suggestion {
  top: 0;
}

.market-search-menu .search-field-drop--drop {
  margin-top: 20px;
  position: relative;
  box-shadow: none;
  height: auto;
  overflow: visible;
}

.market-search-menu .search-field-drop--suggestions {
  height: 200px;
  overflow: auto;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid white;
}

.market-search-menu .search-field-drop--suggestions a {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: auto;
  padding: 11px 0 11px 50px;
  color: #393939;
  margin-bottom: 1px;
  transition: 0.2s color ease-in-out, 0.2s background ease-in-out;
}

[dir='rtl'] .market-search-menu .search-field-drop--suggestions a {
  padding: 11px 50px 11px 0;
}

.market-search-menu .search-field-drop--suggestions a.highlighted {
  font-weight: bold;
}

.market-search-menu .search-field-drop--suggestions a.highlighted::before {
  content: "";
  font-family: "icomoon";
  font-size: 15px;
  font-weight: normal;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.market-search-menu .search-field-drop--suggestions a:after {
  content: ' ';
  border-bottom: 2px solid transparent;
  width: calc(100% - 30px);
  bottom: 0;
  position: absolute;
  right: -12px;
}

.market-search-menu .search-field-drop--suggestions li {
  display: flex;
}

.market-search-menu .search-field-drop--suggestions li.active a,
.market-search-menu .search-field-drop--suggestions li a:hover,
.market-search-menu .search-field-drop--suggestions li a:focus {
  color: RGB(var(--color-primary));
  font-weight: bold;
  transition: none;
  outline: none;
}

.market-search-menu .search-field-drop--suggestions li.active a:after,
.market-search-menu .search-field-drop--suggestions li a:hover:after,
.market-search-menu .search-field-drop--suggestions li a:focus:after {
  border-color: #0074c0;
}

.market-search-menu--all-market-new {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background: RGB(var(--color-primary));
  color: white;
  border: none;
  transition: 0.4s color ease-in-out, 0.4s background ease-in-out;
}

.market-search-menu--all-market-old {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: RGB(var(--color-primary));
  background: #f6f6f6;
  transition: 0.4s color ease-in-out, 0.4s background ease-in-out;
}

.market-search-menu--all-market-old:hover {
  background: RGB(var(--color-primary));
  color: white;
  transition: 0.2s color ease-in-out, 0.2s background ease-in-out;
}

.related-articles-partial {
  --color-grey: 110, 110, 110;
}

.related-articles-partial.boxed-style {
  padding: 40px 0;
  border: 1px solid #cccccc;
  border-width: 1px 0 1px 0;
}

.related-articles-partial--title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: var(--text-grey);
  margin-bottom: 30px;
}

.related-articles-partial--content {
  --offset: 0;
  --direction-factor: 1;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(6, 1fr);
  width: min-content;
}

@media screen and (min-width: 480px) {
  .related-articles-partial--content {
    width: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 640px) {
  .related-articles-partial--content {
    grid-template-columns: repeat(3, 1fr);
  }
}

[dir="rtl"] .related-articles-partial--content {
  --direction-factor: -1;
}

.related-articles-partial--content .small-image-above-title {
  width: 215px;
  height: auto;
  transform: translateX(calc(var(--offset) * var(--direction-factor) * 1px));
}

@media screen and (min-width: 480px) {
  .related-articles-partial--content .small-image-above-title {
    width: auto;
    --direction-factor: 0;
  }
}

.document-download-partial {
  position: relative;
  background: RGB(var(--color-primary));
  padding: 25px 30px;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}

.document-download-partial--background {
  z-index: -1;
}

.document-download-partial--date {
  font-size: 11px;
  margin-bottom: 8px;
}

.grey .document-download-partial--date {
  color: #6e6e6e;
}

.black .document-download-partial--date {
  color: #262626;
}

.document-download-partial--title {
  color: white;
  margin-bottom: 20px;
}

.grey .document-download-partial--title {
  color: #262626;
}

.black .document-download-partial--title {
  color: #262626;
}

.document-download-partial--title-list {
  word-break: break-word;
}

.document-download-partial--no-download {
  font-size: 12px;
  color: white;
}

.grey .document-download-partial--no-download {
  color: #262626;
}

.black .document-download-partial--no-download {
  color: #262626;
}

.document-download-partial--dot-seperator {
  margin-left: 10px;
  position: relative;
}

.black .document-download-partial--dot-seperator {
  color: #262626;
}

.document-download-partial--dot-seperator:after {
  content: '\002E';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -3px;
}

.document-download-partial--download {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  --icon-spacing: 8px;
}

.document-download-partial--download:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.document-download-partial--download:before {
  font-family: "icomoon";
  color: RGB(var(--color-primary));
  margin-right: var(--icon-spacing);
  transition: 0.4s margin-right ease-in-out, 0.4s margin-left ease-in-out;
}

[dir='rtl'] .document-download-partial--download:before {
  margin-right: 0;
  margin-left: var(--icon-spacing);
}

.document-download-partial--download.blue:hover {
  color: white;
}

.document-download-partial--download.blue:before {
  content: "";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0;
  background: white;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
}

.document-download-partial--download.grey {
  color: #262626;
  margin-bottom: 8px;
}

.document-download-partial--download.grey:before {
  content: "";
  font-size: 30px;
}

.document-download-partial--download.black {
  color: #262626;
  margin-bottom: 8px;
}

.document-download-partial--download.black:after {
  content: "";
  font-size: 30px;
}

.document-download-partial--download-list {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.document-download-partial--download-list:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.document-download-partial--download-list::after {
  content: "";
  font-family: "icomoon";
  font-size: 28px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-left: 10px;
}

.document-download-partial--download-list.grey {
  color: #262626;
}

.document-download-partial--download-list.grey:after {
  color: #262626;
}

.black .document-download-partial--download-list {
  color: #262626;
}

.black .document-download-partial--download-list:after {
  color: #262626;
}

.document-download-partial:hover .document-download-partial--download {
  --icon-spacing: 20px;
}

.document-download-partial:not(.grey):hover {
  color: white;
}

.document-download-partial.grey {
  background: #f6f6f6;
}

.document-download-partial.no-background {
  background: none;
}

.document-download-partial--size {
  display: inline-block;
  font-size: 11px;
  margin-bottom: 15px;
}

.document-download-partial--size.grey {
  color: #262626;
}

.black .document-download-partial--size {
  color: #262626;
}

.document-download-partial--format {
  display: inline-block;
  margin-left: 15px;
  font-size: 11px;
}

.document-download-partial--format.grey {
  color: #262626;
}

.black .document-download-partial--format {
  color: #262626;
}

.document-download-partial--flex-end {
  justify-content: flex-end;
}

.cta-box-partial {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: left;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  background-color: RGB(var(--color-primary));
  color: white;
  border: 1px solid transparent;
}

.cta-box-partial--title {
  font-size: 12px;
}

.cta-box-partial--description {
  font-size: 18px;
  font-weight: 600;
}

.cta-box-partial--arrowed-link {
  text-align: right;
  margin: 12px 0 4px;
}

.cta-box-partial--arrowed-link:before {
  content: "";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 14px;
  font-family: "icomoon";
  transition: 0.2s all ease-in-out;
  color: RGB(var(--color-primary));
  background-color: white;
}

a:hover .cta-box-partial, .cta-box-partial:hover,
a:focus .cta-box-partial, .cta-box-partial:focus {
  outline: none;
  border-color: white;
}

a:hover .cta-box-partial .cta-box-partial--arrowed-link:before, .cta-box-partial:hover .cta-box-partial--arrowed-link:before,
a:focus .cta-box-partial .cta-box-partial--arrowed-link:before, .cta-box-partial:focus .cta-box-partial--arrowed-link:before {
  transform: scale(1.2);
}

.cta-box-partial.is-white {
  background-color: white;
  color: #262626;
  border: 1px solid #cccccc;
  box-shadow: none;
}

.cta-box-partial.is-white:focus {
  border-color: RGB(var(--color-primary));
  outline: none;
}

.cta-box-partial.is-white .cta-box-partial--title {
  color: #6e6e6e;
}

.cta-box-partial.is-white .cta-box-partial--arrowed-link:before {
  border: 2px solid RGB(var(--color-primary));
}

.news-and-press-item {
  padding: 25px 20px;
  border-bottom: 1px solid #cccccc;
}

@media screen and (min-width: 1200px) {
  .news-and-press-item {
    height: 130px;
  }
}

.news-and-press-item.height-auto {
  height: auto;
}

.news-and-press-item--head {
  display: flex;
  justify-content: space-between;
}

.news-and-press-item--category {
  text-transform: uppercase;
  color: #262626;
  align-self: center;
}

.news-and-press-item--date {
  text-transform: capitalize;
  font-weight: 400;
  color: #6e6e6e;
}

.news-and-press-item--title {
  font-size: 16px;
  width: 80%;
  color: #393939;
  font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .news-and-press-item--title {
    display: -webkit-box;
    overflow: hidden;
    line-height: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 60px;
  }
  .news-and-press-item--title.active {
    width: 90%;
  }
}

.news-and-press-item--title:focus {
  outline: none;
  color: RGB(var(--color-primary));
}

.news-and-press-item--top-link {
  font-size: 22px;
  transition: 0.4s all ease-in-out transform ease-in-out, 0.4s all ease-in-out color ease-in-out;
}

.news-and-press-item--top-link:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
}

[dir="rtl"] .news-and-press-item--top-link:before {
  transform: rotate(0.5turn);
}

.news-and-press-item--top-link.download:before {
  content: "";
  font-family: "icomoon";
}

.news-and-press-item--top-link:focus {
  outline: none;
  transform: scale(1.1);
  color: #262626;
  transition: 0.2s transform ease-in-out, 0.2s color ease-in-out;
}

@media screen and (max-width: 639px) {
  .event-highlight-partial[data-controller] {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
}

.event-highlight-partial--tabs {
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

.event-highlight-partial--tab {
  width: 100%;
  text-align: center;
}

.event-highlight-partial--tab-link {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  background: none;
  border: none;
  color: #6e6e6e;
  padding: 0;
  transition: 0.6s color ease-in-out;
}

@media screen and (min-width: 960px) {
  .event-highlight-partial--tab-link {
    font-size: 20px;
  }
}

.event-highlight-partial--tab-link:after {
  content: '';
  display: block;
  margin-top: 20px;
  width: 0;
  height: 5px;
  background: RGB(var(--color-primary));
  transition: 0.6s width ease-in-out;
}

.event-highlight-partial--tab-link:disabled {
  color: RGB(var(--color-primary));
  transition: 0.2s color ease-in-out;
}

.event-highlight-partial--tab-link:disabled:after {
  width: 100%;
  transition: 0.2s color ease-in-out;
}

.event-highlight-partial--tab-link:focus {
  color: RGB(var(--color-primary));
  outline: none;
}

.event-highlight-partial--container {
  position: relative;
  background-color: RGB(var(--color-primary));
  overflow: hidden;
  transition: 0.6s height ease-in-out;
}

.event-highlight-partial--content {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 35px;
  color: white;
  transform: translateX(-100%);
  transition: 0.6s transform ease-in-out;
}

.event-highlight-partial--content:last-child {
  position: absolute;
  top: 0;
  transform: translateX(100%);
}

.event-highlight-partial--content.active {
  transform: none;
}

.event-highlight-partial--content.no-upcoming {
  padding: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
}

@media screen and (min-width: 960px) {
  .event-highlight-partial--content.no-upcoming {
    padding: 120px;
  }
}

.event-highlight-partial--content .arrowed-link {
  margin-top: 25px;
  color: white;
}

.event-highlight-partial--content .arrowed-link:before {
  color: RGB(var(--color-primary));
  background-color: white;
}

.event-highlight-partial--content .arrowed-link:hover, .event-highlight-partial--content .arrowed-link:focus {
  color: white;
}

.event-highlight-partial--content .arrowed-link:hover:before, .event-highlight-partial--content .arrowed-link:focus:before {
  background-color: white;
}

.event-highlight-partial--content:hover .arrowed-link, .event-highlight-partial--content:focus .arrowed-link {
  color: white;
}

.event-highlight-partial--content:hover .arrowed-link:before, .event-highlight-partial--content:focus .arrowed-link:before {
  background-color: white;
}

.event-highlight-partial--row {
  display: flex;
  justify-content: space-between;
}

.event-highlight-partial--calendar-button {
  background: none;
  border: none;
  padding: 0;
  color: white;
  margin-bottom: 10px;
}

.event-highlight-partial--links {
  margin-top: 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.event-highlight-partial--link {
  display: block;
  width: 100%;
  text-align: right;
  transform: translateX(-100%);
  transition: 0.6s transform ease-in-out;
}

.event-highlight-partial--link:last-child {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.event-highlight-partial--link.active {
  transform: none;
}

@media screen and (max-width: 639px) {
  .event-highlight-partial--link {
    padding: 0 20px;
  }
}

.event-highlight-partial--inner {
  width: 100%;
  display: inline-block;
}

.event-highlight-partial--title {
  margin-bottom: 10px;
  color: white;
}

.event-highlight-partial--date {
  font-size: 18px;
  margin-bottom: 14px;
}

.event-highlight-partial--description {
  font-size: 16px;
}

@media screen and (min-width: 960px) {
  .event-highlight-partial--description {
    font-size: 28px;
  }
}

.event-highlight-partial--no-upcoming {
  font-size: 16px;
  color: #6e6e6e;
}

@media screen and (min-width: 960px) {
  .event-highlight-partial--no-upcoming {
    font-size: 28px;
  }
}

.event-highlight-partial--calendars {
  position: relative;
}

.event-highlight-partial .calendar-menu--menu {
  margin: 0;
  bottom: 0;
  z-index: 10;
}

.event-highlight-partial .arrow-link {
  display: block;
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
}

.age-gate-partial {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1001;
  left: 0;
  right: 0;
  background-color: RGB(var(--color-primary));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  overflow-y: auto;
  height: 100%;
}

@media screen and (min-width: 700px) {
  .age-gate-partial {
    align-items: center;
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .age-gate-partial--container-simple {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.age-gate-partial img {
  width: auto;
  height: auto;
}

@media screen and (max-width: 700px) {
  .age-gate-partial img {
    height: 80px;
    width: auto;
  }
}

.age-gate-partial--title, .age-gate-partial--title-error {
  color: #fff;
  max-width: 1000px;
  margin: 20px auto 0;
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .age-gate-partial--title, .age-gate-partial--title-error {
    font-size: 24px;
    margin-top: 10px;
  }
}

.age-gate-partial--description, .age-gate-partial--description-error, .age-gate-partial--disclaimer-confirmation {
  color: #fff;
  max-width: 1000px;
  margin: 20px auto 0;
}

@media screen and (max-width: 700px) {
  .age-gate-partial--description, .age-gate-partial--description-error, .age-gate-partial--disclaimer-confirmation {
    margin-top: 10px;
  }
}

.age-gate-partial--disclaimer-container {
  margin: 20px auto;
  line-height: 24px;
  color: #6e6e6e;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
  height: 200px;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .age-gate-partial--disclaimer-container {
    width: 700px;
  }
}

.age-gate-partial--disclaimer-container::-webkit-scrollbar-track {
  background-color: #fff;
  border-left: 1px solid #e8e8e8;
}

.age-gate-partial--disclaimer-container::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background: #fff;
}

.age-gate-partial--disclaimer-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c4c4c4;
}

.age-gate-partial--container, .age-gate-partial--error {
  text-align: center;
  width: 100%;
  padding: 0 10px;
  max-height: 100%;
  align-self: center;
}

@media screen and (min-width: 700px) {
  .age-gate-partial--container, .age-gate-partial--error {
    padding: 0 30px;
  }
}

.age-gate-partial--container .still-picture, .age-gate-partial--error .still-picture {
  width: auto;
  height: auto;
}

.age-gate-partial--buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media screen and (min-width: 700px) {
  .age-gate-partial--buttons {
    margin-top: 20px;
  }
}

.age-gate-partial--link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px solid #fff;
  background-color: transparent;
  width: 120px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: #fff;
  font-weight: bold;
}

@media screen and (min-width: 700px) {
  .age-gate-partial--link {
    width: 200px;
    height: 60px;
  }
}

.age-gate-partial--link:hover {
  cursor: pointer;
}

.age-gate-partial--link-decline-disclaimer {
  background-color: transparent;
  color: #fff;
}

.age-gate-partial--link-accept-disclaimer {
  margin-right: 20px;
  background-color: #fff;
  color: RGB(var(--color-primary));
}

.age-gate-partial--link-accept-simple, .age-gate-partial--link-decline-simple {
  display: inline-block;
  width: auto;
  margin-top: 20px;
  margin-right: 20px;
  color: #fff;
}

.age-gate-partial--link-accept-simple::before, .age-gate-partial--link-decline-simple::before {
  background: #fff;
  border: 1px solid transparent;
  color: RGB(var(--color-primary));
}

.age-gate-partial--link-accept-simple:hover, .age-gate-partial--link-accept-simple:focus, .age-gate-partial--link-decline-simple:hover, .age-gate-partial--link-decline-simple:focus {
  color: #fff;
}

.age-gate-partial--link-accept-simple:hover::before, .age-gate-partial--link-accept-simple:hover::after, .age-gate-partial--link-accept-simple:focus::before, .age-gate-partial--link-accept-simple:focus::after, .age-gate-partial--link-decline-simple:hover::before, .age-gate-partial--link-decline-simple:hover::after, .age-gate-partial--link-decline-simple:focus::before, .age-gate-partial--link-decline-simple:focus::after {
  color: #fff;
  background-color: RGB(var(--color-primary));
  border: 1px solid #fff;
  --spacing: 10px;
}

.age-gate-partial .hidden, .age-gate-partial.hidden {
  display: none;
}

.filters--container {
  position: relative;
}

@media screen and (min-width: 960px) {
  .filters--container {
    display: grid;
    grid-template-columns: 210px 3fr;
    grid-template-areas: 'sort sort sort' 'filters tableResults tableResults' 'filters  tableResults  tableResults';
    gap: 25px;
  }
}

.filters--title {
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: normal;
}

.filters--sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: sort;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
}

@media screen and (max-width: 960px) {
  .filters--sort {
    flex-flow: column;
    align-items: flex-start;
  }
}

.filters--filters {
  grid-area: filters;
}

.filters--views {
  grid-area: tableResults;
}

.filters--table, .filters--grid {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s all ease-in-out;
  display: none;
}

.filters--table.active, .filters--grid.active {
  opacity: 1;
  display: grid;
  visibility: visible;
}

.filters--grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  grid-auto-rows: 1fr;
}

@media screen and (max-width: 960px) {
  .filters--grid {
    grid-template-columns: 1fr;
  }
}

.filters--grid.hidden {
  opacity: 0;
  visibility: hidden;
}

.filters--grid-articles {
  grid-auto-rows: unset;
}

.filters--list-articles {
  grid-template-columns: 1fr;
}

.filters--list-articles .filters--article-container {
  flex-flow: row;
}

@media screen and (min-width: 960px) {
  .filters--list-articles .filters--article-container:not(:first-child) .filters--article-image {
    flex-basis: 30%;
  }
  .filters--list-articles .filters--article-container:not(:first-child) .filters--article-content {
    flex-basis: 70%;
    margin-left: 30px;
  }
}

@media screen and (max-width: 960px) {
  .filters--list-articles .filters--article-description {
    display: none;
  }
  .filters--list-articles .filters--article-image {
    flex-basis: 35%;
  }
  .filters--list-articles .filters--article-content {
    flex-basis: 65%;
  }
}

.filters--article-content {
  margin-left: 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.filters--article-container {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}

@media screen and (min-width: 960px) {
  .filters--article-container:first-child {
    grid-column: 1 / -1;
    flex-flow: row;
  }
  .filters--article-container:first-child .filters--article-content {
    margin-left: 35px;
    flex-basis: 50%;
  }
  .filters--article-container:first-child .filters--article-image {
    flex-basis: 50%;
  }
  .filters--article-container:first-child .filters--article-title {
    font-size: 28px;
    margin-bottom: 15px;
    line-height: 33px;
  }
  .filters--article-container:first-child .filters--article-link {
    margin-top: 20px;
  }
}

@media screen and (max-width: 960px) {
  .filters--article-container:first-child {
    position: relative;
    margin-top: 20px;
  }
  .filters--article-container:first-child:after {
    content: ' ';
    position: absolute;
    width: 100vw;
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
    bottom: -20px;
    background-color: #f6f6f6;
    z-index: -1;
  }
}

@media screen and (max-width: 960px) {
  .filters--article-container {
    margin-bottom: 20px;
  }
}

.filters--article-description {
  margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
  .filters--article-description {
    margin-bottom: 20px;
  }
}

.filters--article-image {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  outline: none;
}

.filters--article-image:after {
  content: '';
  width: 100%;
  padding-bottom: 59%;
  display: block;
}

@media screen and (max-width: 960px) {
  .filters--article-image {
    height: 100%;
  }
}

.filters--article-title {
  margin-bottom: 8px;
}

.filters--article-logo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filters--article-logo img {
  max-width: 33%;
  width: auto;
  height: auto;
  margin-right: 10px;
}

@media screen and (max-width: 960px) {
  .filters--article-logo img {
    max-width: 10%;
    margin-right: 7px;
  }
}

.filters--article-link {
  display: block;
  color: #262626;
  font-weight: bold;
  margin-top: auto;
}

.filters--article-category {
  position: relative;
  margin-right: 20px;
  color: RGB(var(--color-primary));
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.filters--article-category:after {
  content: '.';
  position: absolute;
  right: -14px;
  top: -8px;
  font-size: 16px;
}

@media screen and (max-width: 960px) {
  .filters--article-category:after {
    right: -9px;
    top: -14px;
    font-size: 22px;
  }
}

@media screen and (max-width: 960px) {
  .filters--article-category {
    font-size: 10px;
    margin-right: 11px;
  }
}

.filters--article-date {
  color: RGB(var(--color-primary));
  font-size: 11px;
  letter-spacing: 1px;
}

@media screen and (max-width: 960px) {
  .filters--article-date {
    font-size: 10px;
  }
}

.filters [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.filters [type='checkbox'] + label {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: #262626;
  cursor: pointer;
  padding: 15px 0;
  flex-grow: 1;
}

@media screen and (min-width: 960px) {
  .filters [type='checkbox'] + label {
    padding: 15px;
  }
}

.filters [type='checkbox'] + label:before {
  --size: 14px;
  content: '';
  outline: 2px solid transparent;
  display: block;
  border: 2px RGB(var(--color-primary)) solid;
  margin-right: var(--size);
  height: var(--size);
  width: var(--size);
  min-width: var(--size);
}

[dir='rtl'] .filters [type='checkbox'] + label:before {
  margin-right: 0;
  margin-left: var(--size);
}

.filters [type='checkbox'] + label:hover::before {
  background: white;
}

.filters [type='checkbox']:checked + label {
  font-weight: 700;
}

.filters [type='checkbox']:checked + label:before {
  background: RGB(var(--color-primary));
  border-color: #fff;
  outline-color: RGB(var(--color-primary));
}

.filters--filters-title, .filters--filters-apply, .filters--filters-mobile-btn, .filters--filters-title-mobile {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #f6f6f6;
  margin-bottom: 25px;
  border: none;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .filters--filters-title {
    display: none;
  }
}

.filters--filter-title {
  margin: 10px 0;
}

@media screen and (max-width: 960px) {
  .filters--filter-title {
    padding-left: 20px;
    margin-top: 18px;
  }
}

.filters--filters-container {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

@media screen and (max-width: 960px) {
  .filters--filters-container {
    padding-left: 20px;
  }
}

.filters--filters-container:last-child {
  border: none;
}

@media screen and (max-width: 960px) {
  .filters--filters-container:last-child {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 960px) {
  .filters--list {
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }
}

.filters--list::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filters .arrows {
  background-color: transparent;
  border: none;
  display: block;
  margin: 0 auto;
}

.filters .arrows:after {
  content: ' ';
  border: solid RGB(var(--color-primary));
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  background-color: transparent;
}

@media screen and (max-width: 960px) {
  .filters .arrows {
    display: none;
  }
}

.filters--up-arrow:after {
  transform: rotate(-135deg);
}

.filters--down-arrow:after {
  transform: rotate(45deg);
}

.filters--list-view {
  border: none;
  background-color: transparent;
  padding: 0 5px;
}

.filters--list-view svg rect,
.filters--list-view svg line,
.filters--list-view svg circle {
  transition: 0.2s all ease-in-out;
  color: RGB(var(--color-primary));
}

.filters--list-view svg circle,
.filters--list-view svg line {
  stroke: RGB(var(--color-primary));
}

.filters--list-view svg rect {
  fill: RGB(var(--color-primary));
}

.filters--list-view:hover svg rect, .filters--list-view.active svg rect {
  fill: #fff;
}

.filters--list-view:hover svg line, .filters--list-view.active svg line {
  stroke: #fff;
}

.filters--list-view:hover svg circle, .filters--list-view.active svg circle {
  fill: RGB(var(--color-primary));
}

.filters--grid-view--circle {
  stroke: RGB(var(--color-primary));
}

.filters--grid-view {
  border: none;
  background-color: transparent;
  padding: 0;
}

.filters--grid-view svg g,
.filters--grid-view svg rect {
  transition: 0.2s all ease-in-out;
}

.filters--grid-view svg rect {
  fill: RGB(var(--color-primary));
}

.filters--grid-view:hover svg g, .filters--grid-view.active svg g {
  fill: RGB(var(--color-primary));
}

.filters--grid-view:hover svg rect, .filters--grid-view.active svg rect {
  fill: #fff;
}

@media screen and (max-width: 960px) {
  .filters--grid-view {
    margin-left: auto;
    display: inline-block;
  }
}

.filters--newest, .filters--oldest {
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: #959595;
  font-weight: bold;
  transition: 0.4s all ease-in-out;
}

.filters--newest.active, .filters--newest:hover, .filters--oldest.active, .filters--oldest:hover {
  color: #393939;
}

.filters--newest.active, .filters--oldest.active {
  cursor: default;
}

.filters--sort-left, .filters--sort-right {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .filters--sort-left, .filters--sort-right {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 960px) {
  .filters--sort-right {
    justify-content: flex-end;
    width: 100%;
  }
}

.filters--sort-right-buttons {
  margin-right: auto;
}

@media screen and (max-width: 960px) {
  .filters--newest {
    padding: 0;
  }
}

.filters--oldest {
  margin-right: 20px;
}

.filters--results-found {
  color: #6e6e6e;
  margin-right: 25px;
}

.filters--clear-button {
  border: none;
  background-color: transparent;
  color: RGB(var(--color-primary));
  font-size: 14px;
  align-self: flex-end;
  display: none;
}

.filters--clear-button.active {
  display: block;
}

.filters table {
  width: 100%;
}

.filters table thead {
  background-color: #f6f6f6;
}

.filters--column-name {
  font-size: 14px;
}

.filters--table-cell-description {
  font-size: 16px;
  padding-left: 25px;
  max-width: 450px;
  word-break: break-word;
}

@media screen and (max-width: 960px) {
  .filters--table-cell-description {
    max-width: 210px;
    word-break: break-word;
  }
}

.filters--table-cell {
  font-size: 11px;
  color: #6e6e6e;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .filters--table-cell:nth-child(2), .filters--table-cell:nth-child(3), .filters--table-cell:nth-child(4) {
    display: none;
  }
}

.filters--table-top {
  height: 60px;
}

@media screen and (max-width: 960px) {
  .filters--table-top {
    display: none;
  }
}

.filters--column-name:first-child {
  padding-left: 35px;
}

.filters--table-row {
  height: 60px;
  border-bottom: 1px solid #cccccc;
}

.filters--download {
  display: inline-block;
}

.filters--download::after {
  content: "";
  font-family: "icomoon";
  font-size: 12px;
  color: RGB(var(--color-primary));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid RGB(var(--color-primary));
  background: #fff;
}

.filters--download:hover:after {
  color: #fff;
  background: RGB(var(--color-primary));
}

@media screen and (max-width: 960px) {
  .filters--filters-mobile {
    display: flex;
    flex-flow: column;
    position: fixed;
    top: 0;
    height: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    z-index: 2002;
    background: white;
    overflow-y: auto;
  }
}

@media screen and (max-width: 960px) {
  .filters--filters-mobile-scroll {
    height: 100%;
    overflow-y: auto;
  }
}

.filters--filters-mobile-header {
  position: relative;
  margin-bottom: 10px;
  display: none;
}

@media screen and (max-width: 960px) {
  .filters--filters-mobile-header {
    display: block;
  }
}

.filters--filters-mobile-close {
  border: none;
  display: flex;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}

.filters--filters-mobile-close:after {
  content: '\d7';
  color: #393939;
  font-size: 28px;
  margin-right: 10px;
}

.filters--filters-mobile-close:hover {
  cursor: pointer;
}

.filters--filters-title-mobile {
  margin-bottom: 0;
}

.filters--filters-mobile-btn {
  display: none;
}

@media screen and (max-width: 960px) {
  .filters--filters-mobile-btn {
    display: block;
    background-color: RGB(var(--color-primary));
    color: #fff;
  }
}

.filters--filters-apply {
  display: none;
}

@media screen and (max-width: 960px) {
  .filters--filters-apply {
    position: absolute;
    background-color: RGB(var(--color-primary));
    color: #fff;
    margin-bottom: 0;
    bottom: 0;
    display: block;
  }
}

.filters--column-sort {
  position: relative;
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #000;
}

.filters--column-sort:after {
  content: ' ';
  transition: 0.2s all ease-in-out;
  margin-left: 10px;
  border: solid #262626;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.filters--column-sort.desc:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.filters--image-sample {
  width: auto;
  height: auto;
  display: none;
}

.null-check--warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(248, 0, 0, 0.7);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
}

.null-check--text {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.null-check--close {
  position: absolute;
  right: 13px;
  top: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.null-check--close::after {
  content: "";
  font-family: "icomoon";
}

body.page-career .page-main {
  --color-primary: 31, 60, 132;
  --color-primary-hover: #172c61;
}

body.page-career .footer-layout {
  --color-primary: 0, 116, 192;
  --color-primary-hover: #0062a3;
}

body.page-career .plyr__control--overlaid {
  background: RGB(31, 60, 132);
}

body.page-career .plyr__control--overlaid:hover {
  background: #172c61;
}

body.page-career .plyr--full-ui input[type='range'] {
  color: RGB(31, 60, 132);
}

body.page-career .video-cover .circle:before {
  background: url("/assets-v3/images/icons/videoLoadCareer.svg");
}

.modal {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  grid-template: 1fr auto 1fr / 0 auto;
  grid-template-areas: '.    .    .' '. content .' '.    .    .';
}

@media screen and (min-width: 960px) {
  .modal {
    grid-template: 1fr auto 1fr/20% auto 20%;
    grid-template-areas: '.    .    .' '. content .' '.    .    .';
  }
}

.modal.no-display {
  display: none;
}

.modal.closing {
  cursor: wait;
}

.modal.closing * {
  pointer-events: none;
}

.modal--background {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background-color: rgba(0, 0, 0, 0.77);
  transition: 0.4s background ease-in-out;
  cursor: pointer;
}

.closing .modal--background {
  background: none;
}

.modal--content, .modal--slot {
  grid-area: content;
  flex-direction: column;
  position: relative;
  display: flex;
}

.modal--content {
  align-items: flex-end;
  overflow: hidden;
  height: 0;
  transition: 0.4s height ease-in-out;
  width: 100vw;
  padding: 0 20px;
}

@media screen and (min-width: 960px) {
  .modal--content {
    width: auto;
    padding: 0;
  }
}

.modal--slot {
  width: 100%;
}

.modal--top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 70px;
}

@media screen and (min-width: 960px) {
  .modal--top-bar {
    padding-top: 0;
  }
}

.modal--titles {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 10px;
}

.modal--title {
  font-size: 16px;
  color: white;
}

.modal--picture-title {
  font-size: 14px;
  color: white;
  margin: 6px 0;
}

.modal--close-button {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.closing .modal--close-button:before, .closing .modal--close-button:after {
  transform: translateY(45px);
}

.modal--close-button:before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGB(var(--color-primary));
  transition: 0.4s background-color ease-in-out, 0.4s transform ease-in-out;
  content: '';
}

.modal--close-button:after {
  content: "";
  font-family: "icomoon";
  color: white;
  font-size: 15px;
  display: block;
  z-index: 1;
  transition: 0.4s transform ease-in-out;
}

.modal--close-button:hover, .modal--close-button:focus {
  outline: none;
}

.modal--close-button:hover::before, .modal--close-button:focus::before {
  background-color: var(--color-primary-hover);
}

.modal--close-button:hover::after, .modal--close-button:focus::after {
  transform: rotate(180deg);
}

.modal--close-button-in-content {
  position: absolute;
  right: 0;
  top: 22px;
  z-index: 100;
}

.modal--picture-info {
  text-align: center;
  line-height: 16px;
  margin-top: 6px;
}

.modal--picture-description {
  color: #fff;
  font-size: 11px;
}

.unfoldable-menu {
  position: relative;
  display: flex;
  flex-flow: column;
  background: #ebebeb;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu {
    background: #f6f6f6;
    flex-flow: row;
  }
}

.unfoldable-menu > li {
  display: flex;
  flex-flow: column;
  position: relative;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu > li {
    flex-flow: row;
  }
}

.unfoldable-menu--unfold-button {
  background: none;
  border: none;
  padding: 0;
  width: 100%;
}

.unfoldable-menu--unfold-button:focus {
  outline: none;
}

.unfoldable-menu--unfold-button:focus p {
  color: RGB(var(--color-primary));
  font-weight: bold;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--unfold-button {
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}

.unfoldable-menu--dropper {
  display: none;
  align-items: center;
  padding: 0 20px;
  position: relative;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  transition: 0.4s box-shadow ease-in-out, 0.4s background ease-in-out;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--dropper {
    display: flex;
  }
}

.unfoldable-menu--dropper.active {
  z-index: 1001;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
  background: white;
}

.unfoldable-menu--dropper-mobile {
  width: 100%;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--dropper-mobile {
    display: none;
  }
}

.unfoldable-menu--dropper:focus {
  outline: none;
}

.unfoldable-menu--dropper:focus .unfoldable-menu--title {
  color: RGB(var(--color-primary));
}

.unfoldable-menu--dropper:focus .unfoldable-menu--title::after {
  color: #262626;
}

.unfoldable-menu--title {
  position: relative;
  font-weight: normal;
  display: flex;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  color: #262626;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
  transition: 0.4s border-color ease-in-out, 0.4s margin ease-in-out;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--title {
    justify-content: flex-start;
    font-size: 14px;
    letter-spacing: 0.04em;
    margin: 0;
    padding: 0;
    border: none;
  }
}

.unfoldable-menu--title.current {
  font-weight: bold;
}

.unfoldable-menu--title.droppable::after {
  content: "";
  font-family: "icomoon";
  font-size: 6px;
  transform: rotate(180deg);
  margin-left: 10px;
  display: block;
  transition: 0.4s transform ease-in-out;
}

button:focus + * .unfoldable-menu--title.droppable::after {
  color: RGB(var(--color-primary));
}

[dir="rtl"] .unfoldable-menu--title.droppable::after {
  margin-left: 0;
  margin-right: 10px;
}

.unfoldable-menu--container {
  overflow: hidden;
  height: 0;
  transition: 0.4s height ease-in-out;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--container {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 1001;
    transition: 0.4s box-shadow ease-in-out, 0.4s height ease-in-out;
    min-width: 100%;
  }
}

.unfoldable-menu--container.active {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--container.active {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.27);
    border: none;
    margin: 0;
  }
}

.unfoldable-menu--content {
  display: flex;
  flex-flow: column;
  padding: 10px 0;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--content {
    background: white;
    padding: 0;
  }
}

.unfoldable-menu--content > li {
  display: flex;
}

.unfoldable-menu--content > li a {
  width: 100%;
}

.unfoldable-menu--link {
  color: #262626;
  white-space: nowrap;
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.unfoldable-menu--link.current {
  font-weight: bold;
}

.unfoldable-menu--link:focus {
  color: RGB(var(--color-primary));
  font-weight: bold;
  outline: none;
}

@media screen and (min-width: 1265px) {
  .unfoldable-menu--link {
    margin: 0;
    padding: 10px 20px;
    font-size: 12px;
    transition: 0.2s color ease-in-out, 0.2s background ease-in-out;
  }
  .unfoldable-menu--link:hover {
    background: RGB(var(--color-primary));
    color: white;
  }
  .unfoldable-menu--link.mobile {
    display: none;
  }
}

.unfoldable-menu--unfold-button:focus + .unfoldable-menu--dropper .unfoldable-menu--title::after {
  color: RGB(var(--color-primary));
}

.unfoldable-menu--unfold-button:focus:not([aria-expanded="true"]) + .unfoldable-menu--dropper {
  border-bottom-color: RGB(var(--color-primary));
}

.active .unfoldable-menu--title {
  margin-bottom: -10px;
  border-color: transparent;
}

@media screen and (min-width: 1265px) {
  .active .unfoldable-menu--title {
    margin: 0;
  }
}

.active .unfoldable-menu--title::after {
  transform: rotate(0);
}

.control-bar {
  padding: 10px 0;
  --button-scale: 1;
  cursor: pointer;
}

.control-bar--content {
  position: relative;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.5px;
}

.control-bar--content::before, .control-bar--content::after {
  content: "";
  position: absolute;
}

.control-bar--content::before {
  top: 0;
  left: 0;
  width: calc(100% * var(--progress));
  height: 100%;
  border-radius: 1.5px;
  background: white;
}

.control-bar--content::after {
  top: 50%;
  width: 10px;
  height: 10px;
  left: calc(100% * var(--progress));
  transform: translate(-50%, -50%) scale(var(--button-scale));
  border-radius: 50%;
  background: white;
  transition: 0.2s transform ease-in-out;
}
