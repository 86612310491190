.article-links {
	margin-bottom: 50px;
	&--line {
		margin-bottom: 10px;
	}
	&--link {
		display: flex;
		align-items: baseline;
		font-size: 16px;
		line-height: 24px;
		&::before {
			content: "";
			display: block;
			width: 6px;
			min-width: 6px;
			height: 6px;
			background: $grey;
			border-radius: 50%;
			margin-right: 20px;
			margin-bottom: 2px;
		}
	}
}
