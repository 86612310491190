.social-media-links {
	&--title {
		margin-bottom: 20px;
		color: $darkGrey;
		@include sr-only-mobile();
	}
	&--content {
		display: flex;
		align-items: baseline;

		line-height: 16px;
	}
	&--link {
		margin: 0 20px;
		color: $colorPrimary;
		transition: $mediumDelay transform ease-in-out;
		position: relative;
		&:before {
			font-family: $iconFont;
			font-size: 16px;
			transition: $mediumDelay color ease-in-out;
		}
		&:hover {
			color: $colorPrimaryHover;
		}
		&:focus {
			outline: none;
			color: $textBlack;
			transform: scale(1.1);
			transition: $fastDelay transform ease-in-out;
			&:before {
				transition: $fastDelay color ease-in-out;
			}
		}
		&:first-child {
			margin-left: 0;
			[dir="rtl"] & {
				margin-left: 20px;
				margin-right: 0;
			}
		}
		&:last-child {
			margin-right: 0;
			[dir="rtl"] & {
				margin-right: 20px;
				margin-left: 0;
			}
		}
		svg {
			path {
				.color--sampoernaRed & {
					fill: $bean;
				}
			}
		}
	}
	&--facebook {
		bottom: 1px;
		&:before {
			content: $icon-facebook;
		}
	}
	&--linkedin {
		bottom: 1px;
		margin-bottom: 2px;
		&:before {
			content: $icon-linkedin;
		}
	}
	&--x {
		top: 4px;
	}
	&--youtube {
		&:before {
			content: $icon-youtube;
		}
	}
	&--glassdoor {
		&:before {
			content: $icon-glassdoor;
		}
	}
	&--instagram {
		top: 3px;
		&:before {
			content: $icon-instagram;
			font-size: 22px;
		}
	}
	&--vk {
		&:before {
			content: $icon-vk;
			font-size: 14px;
		}
	}
}

