.ir-email-alert {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	color: white;

	&.is-white {
		background-color: white;
		color: $textBlack;
		border: 1px solid $buttonGrey;
		box-shadow: none;
		padding: 15px;
		&:focus {
			border-color: $colorPrimary;
			outline: none;
		}
		.ir-email-alert--subscribe {
			color: $textBlack;
			&:before {
				border: 2px solid $colorPrimary;
			}
		}
	}

	@include breakMin($breakMedium) {
		padding-right: 100px;
	}

	&--title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 20px;
	}
	&--description {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 45px;
		@include breakMin($breakMedium) {
			margin-bottom: 80px;
			width: 65%;
		}
	}

	&--subscribe {
		&:hover,
		&:focus,
		& {
			color: white;
			background: none;
			padding: 0;
			border: none;

			&::before {
				background: white;
				color: $colorPrimary;
			}
		}
	}
}
