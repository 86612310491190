.modal {
	display: grid;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1002;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	grid-template: 1fr auto 1fr / 0 auto;
	grid-template-areas:
		'.    .    .'
		'. content .'
		'.    .    .';

	@include breakMin($breakMedium) {
		grid-template: 1fr auto 1fr/20% auto 20%;
		grid-template-areas:
			'.    .    .'
			'. content .'
			'.    .    .';
	}
	&.no-display {
		display: none;
	}
	&.closing {
		cursor: wait;
		* {
			pointer-events: none;
		}
	}

	&--background {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
		background-color: rgba(black, 0.77);
		transition: $mediumDelay background ease-in-out;
		cursor: pointer;
		.closing & {
			background: none;
		}
	}

	&--content,
	&--slot {
		grid-area: content;
		flex-direction: column;
		position: relative;
		display: flex;
	}
	&--content {
		align-items: flex-end;
		overflow: hidden;
		height: 0;
		transition: $mediumDelay height ease-in-out;
		width: 100vw;
		padding: 0 20px;

		@include breakMin($breakMedium) {
			width: auto;
			padding: 0;
		}
	}
	&--slot {
		width: 100%;
	}
	&--top-bar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-top: 70px;

		@include breakMin($breakMedium) {
			padding-top: 0;
		}
	}
	&--titles {
		display: flex;
		flex-flow: column;
		justify-content: center;
		padding-bottom: 10px;
	}
	&--title {
		font-size: 16px;
		color: white;
	}
	&--picture-title {
		font-size: 14px;
		color: white;
		margin: 6px 0;
	}
	&--close-button {
		position: relative;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		overflow: hidden;
		cursor: pointer;

		&:before,
		&:after {
			.closing & {
				transform: translateY(45px);
			}
		}
		&:before {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $colorPrimary;
			transition: $mediumDelay background-color ease-in-out,
				$mediumDelay transform ease-in-out;
			content: '';
		}
		&:after {
			content: $icon-cross;
			font-family: $iconFont;
			color: white;
			font-size: 15px;
			display: block;
			z-index: 1;
			transition: $mediumDelay transform ease-in-out;
		}

		&:hover,
		&:focus {
			outline: none;
			&::before {
				background-color: $colorPrimaryHover;
			}
			&::after {
				transform: rotate(180deg);
			}
		}
	}
	&--close-button-in-content {
		position: absolute;
		right: 0;
		top: 22px;
		z-index: 100;
	}
	&--picture-info {
		text-align: center;
		line-height: 16px;
		margin-top: 6px;
	}
	&--picture-description {
		color: #fff;
		font-size: 11px;
	}
}
