.leadership-widget {
	--column-end: calc(var(--columns) + 1);
	display: grid;
	gap: 15px 30px;
	grid-template-columns: repeat(var(--columns), 1fr);
	&.four-columns {
		--columns: 4;
	}
	&.three-columns {
		--columns: 3;
	}
	&.five-columns {
		--columns: 5;
	}
	@include breakMax($breakSmall) {
		&.four-columns,
		&.three-columns,
		&.five-columns {
			--columns: 1;
			padding: 0 40px;
			gap: 0;
		}
	}
	@include breakBetween($breakSmallBefore, $breakMedium) {
		&.four-columns,
		&.three-columns,
		&.five-columns {
			--columns: 3;
		}
	}
	&--box {
		display: flex;
		justify-content: center;
		flex-flow: column;
		align-items: center;
		border: 1px solid $lightGrey;
		transition: background-color, transform $mediumDelay ease-out;
		&.active {
			background-color: $lightGrey;
			transform: scale(1.1);
		}
		@include breakMax($breakSmall) {
			margin-bottom: 40px;
		}
		.still-picture {
			width: auto;
			height: auto;
		}
	}
	&--description-container {
		visibility: hidden;
		height: 0;
		opacity: 0;
		transition: all $mediumDelay ease-out;
		grid-column-start: 1;
		grid-column-end: var(--column-end);
		grid-row-start: var(--row);
		grid-row-end: var(--row);
		&.active {
			opacity: 1;
			visibility: visible;
			margin: 35px 0 20px 0;
			transition: all $slowerDelay ease-out;
		}
		&:not(.active) {
			height: 0 !important;
			transition: all $slowDelay ease-out;
		}
		@include breakMax($breakSmall) {
			display: none;
		}
	}
	&--description {
		padding: 50px 30px;
		background-color: $lightGrey;
		position: relative;
		@include breakMin($breakSmall) {
			padding: 60px;
		}
		&:focus {
			outline: none;
		}
	}
	&--modal-title,
	&--modal-job-title {
		display: block;
		@include breakMin($breakSmall) {
			display: none;
		}
	}
	&--modal-job-title {
		margin: 5px 0 10px;
	}
	.modal--close-button {
		position: absolute;
		top: -40px;
		right: 0;
		margin-top: 40px;
		margin-left: auto;
		&:before {
			background-color: #fff;
		}
		&:after {
			color: $colorPrimary;
		}
		&:hover,
		&:focus {
			outline: none;
			&::before {
				background-color: #fff;
			}
		}
	}
	&--description-close.modal--close-button {
		@include breakMax($breakSmall) {
			display: none;
		}
	}
	&--link {
		flex: 1 0 auto;
		background-color: $lightGrey;
		width: 100%;
		border: none;
		padding: 15px 0;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		margin-top: auto;
		transition: all $mediumDelay ease-out;
		color: $colorPrimary;
		&.active {
			background-color: $colorPrimary;
			color: #fff;
		}
		&:hover {
			cursor: pointer;
			&:after {
				margin-left: 18px;
			}
		}
	}
	&--details {
		padding: 10px;
		width: 100%;
		height: 100%;
		min-height: 100px;
	}
	&--image {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 150px;
		&:hover {
			cursor: pointer;
		}

		&:before {
			content: '';
			width: 100%;
			padding-bottom: 59%;
			display: block;
		}
		img {
			padding: 10px;
			width: auto;
			height: auto;
		}
	}
	&--title {
		margin: 10px 0 5px 15px;
		&:hover {
			cursor: pointer;
		}
	}
	&--job-title {
		font-size: 11px;
		text-transform: uppercase;
		margin: 0 0 20px 15px;
		&:hover {
			cursor: pointer;
		}
	}
	&--social-media {
		margin-bottom: 15px;
	}
	.social-media-links--link {
		margin: 0 0 0 15px;
		&:before {
			font-size: 14px;
		}
	}
	.wysiwyg,
	.kendo-content-block {
		font-size: 16px;
		line-height: 24px;
	}
}
.modal--content {
	padding-bottom: 70px;
}
.modal--close-button.modal--close-button-in-content {
	&:before {
		background-color: #fff;
	}
	&:after {
		color: $colorPrimary;
	}
	&:hover,
	&:focus {
		outline: none;
		&::before {
			background-color: #fff;
		}
	}
}
