.blog-carousel {
	--offset: 0;
	--direction-factor: 1;
	--mobile-half-pic-height: min(
		calc(0.3 * calc(100vw - 2 * #{$pagePadding})),
		15vh
	);
	display: flex;
	margin-bottom: 80px;
	[dir='rtl'] & {
		--direction-factor: -1;
	}

	&--wrapper {
		display: flex;
		flex-flow: column;
		margin: auto;
		width: 100%;
		@include breakMin($breakMedium) {
			max-width: 828px;
			width: 80%;
		}
	}
	&--top {
		display: flex;
		align-items: center;
		margin: 0 #{35px - $pagePadding} 35px;
		@include breakMin($breakMedium) {
			margin: 0 0 35px;
		}
		&-right {
			display: flex;
			flex-flow: column;
			flex-grow: 1;
			@include breakMin($breakMediumSmall) {
				flex-flow: row;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
	&--articles-link {
		display: flex;
		align-items: center;
		font-size: 13px;
		text-transform: uppercase;
		@include breakMin($breakMedium) {
			margin-left: auto;
			[dir='rtl'] & {
				margin-left: 0;
				margin-right: auto;
			}
		}
		&::after {
			content: $icon-longarrowright;
			font-family: $iconFont;
			font-size: 8px;
			margin-left: 10px;
		}
	}
	.logo-disc {
		margin-right: 20px;
	}
	&--title {
		font-size: 24px;
		font-weight: bold;
		color: $colorPrimary;
		margin-bottom: 20px;
		@include breakMin($breakMediumSmall) {
			font-size: 39px;
			margin-bottom: 0;
		}
	}
	&--posts-container {
		position: relative;
		margin-bottom: 50px;
		@include breakMax($breakMediumBefore) {
			margin: 0 -#{$pagePadding} 50px;
		}
	}
	&--previous,
	&--next {
		--position-offset: 35px;
		position: absolute;
		top: var(--mobile-half-pic-height);
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: white;
		border-radius: 50%;
		border: none;
		font-size: 0;
		box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
		transform: translate(-50%, -50%);
		z-index: 10;
		transition: $mediumDelay width ease-in-out,
			$mediumDelay height ease-in-out, $mediumDelay opacity ease-in-out;
		@include breakMin($breakMedium) {
			top: 50%;
			--position-offset: 0;
		}
		&::before {
			font-family: $iconFont;
			font-size: 8px;
			color: $colorPrimary;
			transition: $mediumDelay font-size ease-in-out;
		}
		&:disabled {
			width: 0;
			height: 0;
			opacity: 0;
			&::before {
				font-size: 0;
			}
		}
	}
	&--previous {
		left: var(--position-offset);
		box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.15);
		&::before {
			content: $icon-longarrowleft;
		}
	}
	&--next {
		right: var(--position-offset);
		transform: translate(50%, -50%);
		&::before {
			content: $icon-longarrowright;
		}
	}
	&--posts {
		position: relative;
		display: flex;
		overflow: visible;
		@include breakMin($breakMedium) {
			gap: 70px;
		}
	}
	&--post {
		position: relative;
		display: grid;
		min-width: 100%;
		transform: translateX(
			calc(var(--offset) * var(--direction-factor) * 1px)
		);
		opacity: 0.5;
		grid-template-areas:
			'.pic .'
			'.pic .'
			'. . .'
			'. title .'
			'. description .'
			'. . .';
		grid-template-columns: 35px auto 35px;
		grid-template-rows:
			var(--mobile-half-pic-height) var(--mobile-half-pic-height)
			40px min-content auto 50px;
		@include breakMin($breakMedium) {
			grid-template-areas:
				'. . . . .'
				'pic pic . title .'
				'pic pic . description .'
				'. . . . .';
			grid-template-columns: 150px 140px 65px auto 65px;
			grid-template-rows: 75px min-content auto 75px;
			&.no-title {
				grid-template-rows: 75px 0 auto 75px;
			}
		}
		transition: $mediumDelay opacity ease-in-out;
		&::before,
		&-image {
			position: relative;
			overflow: hidden;
		}
		&::before {
			content: '';
			background: $lightGrey;
			grid-area: 2 / 1 / 7 / 4;
			transition: $mediumDelay box-shadow ease-in-out;
			@include breakMin($breakMedium) {
				grid-area: 1 / 2 / 5 / 6;
				border-radius: 10px;
			}
		}
		&.active {
			opacity: 1;
			@include breakMin($breakMedium) {
				&::before {
					box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
				}
			}
		}
		&-image {
			grid-area: pic;
			border-radius: 10px;
			width: auto;
			height: auto;
			@include breakMin($breakMedium) {
				align-self: center;
			}
			.back-picture {
				position: relative;
				@include breakMin($breakMedium) {
					height: auto;
					&::before {
						content: '';
						display: block;
						padding-bottom: 66.66%;
					}
					img {
						position: absolute;
						top: 0;
					}
				}
			}
			.video-cover .video-icon {
				right: 20px;
				left: auto;
				width: auto;
				height: auto;
				@include breakMax($breakMediumBefore) {
					right: 50%;
					top: 50%;
					transform: translate(50%, -50%);
				}
				.video-icon--disc {
					width: 50px;
					height: 50px;
					&::after {
						font-size: 14px;
						margin-left: 1px;
					}
				}
			}
		}
		&-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			z-index: 2;
		}
		&-title {
			position: relative;
			z-index: 1;
			grid-area: title;
			font-size: 20px;
			line-height: 28px;
			&.has-after::after {
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: $colorPrimary;
				margin: 15px 0;
			}
		}
		&-description {
			position: relative;
			z-index: 1;
			grid-area: description;
			font-size: 14px;
			line-height: 21px;
		}
	}
	.carousel-highlight-nav {
		display: flex;
	}
	button.carousel-highlight-nav--dot {
		display: block;
		border-radius: 50%;
		&:not(:disabled) {
			background: $buttonGrey;
		}
	}
}
