.glossary {
	position: relative;

	.wrapper {
		margin: 0 auto;
	}

	.index {
		padding-top: 40px;
		background-color: $colorPrimary;
		color: white;
		position: absolute;
		width: 100vw;
		left: -20px;
		z-index: 2000;

		.wrapper {
			padding: 0 20px;
		}

		@include breakMin($breakLarge) {
			left: 0;

			.wrapper {
				padding: 0;
			}
		}

		&--title {
			font-size: 30px;
			font-weight: normal;
			margin-bottom: 10px;
		}

		.slider,
		&--letters {
			display: flex;
		}

		.slider-container {
			background-color: $colorPrimary;
			padding: 30px 0;
		}

		.slider {
			&--button {
				display: flex;
				justify-content: center;
				color: white;
				background: none;
				border: none;
				align-items: center;
				margin: 0;

				&:before {
					display: block;
					font-size: 20px;
					font-family: $iconFont;

					@include breakMin($breakNavigation) {
						font-size: 10px;
					}
				}
			}

			&--prev {
				padding-left: 0;

				&:before {
					content: $icon-arrowleft;
					padding-right: 10px;

					@include breakMin($breakNavigation) {
						content: $icon-longarrowleft;
					}
				}
			}

			&--next {
				padding-right: 0;

				&:before {
					content: $icon-arrowright;
					padding-left: 10px;

					@include breakMin($breakNavigation) {
						content: $icon-longarrowright;
					}
				}
			}
		}

		&--letters--container {
			overflow: hidden;
			padding-left: calc(50% - 60px);
			position: relative;

			&:before,
			&:after {
				content: '';
				display: block;
				width: 70px;
				height: 100%;
				pointer-events: none;
				position: absolute;
				top: 0;
				z-index: 1;

				@include breakMin($breakNavigation) {
					width: 190px;
				}
			}

			&:before {
				left: 0;
				background: rgb(0, 0, 0);
				background: linear-gradient(
					to left,
					rgba(0, 116, 192, 0),
					rgba(0, 116, 192, 1)
				);
			}

			&:after {
				right: 0;
				background: linear-gradient(
					to right,
					rgba(0, 116, 192, 0),
					rgba(0, 116, 192, 1)
				);
			}
		}

		&--letters {
			transition: transform 0.3s ease-in;

			li {
				display: flex;
				align-items: center;
			}

			button {
				background: none;
				border: 0;
				font-size: 24px;
				color: white;
				font-weight: 300;
				border-radius: 100%;
				width: 47px;
				height: 47px;
				padding: 0;
				transition: font-size 0.3s ease-in, color 0.3s ease-in;

				&.current {
					font-size: 38px;
					width: 60px;
					height: 60px;
					color: $colorPrimary;
					background-color: white;
					font-weight: normal;
				}
			}
		}
	}

	.results {
		padding-top: 300px;

		.result {
			padding-bottom: 30px;

			@include breakMin($breakNavigation) {
				display: flex;
				align-items: flex-start;
			}

			&--key {
				font-size: 38px;
				color: $colorPrimary;
				flex-basis: 215px;
				display: flex;
				align-items: center;
				margin: 0 0 30px 0;

				@include breakMin($breakNavigation) {
					min-width: 220px;
					margin: 15px 20px 30px 0;
				}

				&:after {
					content: '';
					display: block;
					width: 60px;
					background-color: $colorPrimary;
					height: 1px;
					margin-left: 10px;

					@include breakMin($breakNavigation) {
						width: 180px;
					}
				}
			}

			&--articles {
				flex-grow: 1;

				li {
					background-color: $lightGrey;
					padding: 25px 50px 25px 25px;
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				h3 {
					font-size: 20px;
					margin-bottom: 20px;
				}

				.wysiwyg {
					&,
					p {
						font-size: 16px;
						line-height: 24px;
						margin-bottom: 30px;
						display: inline-block;

						em,
						a {
							display: inline-block;
						}
					}
				}

				a {
					display: flex;
					align-items: center;
				}

				.read-more {
					display: flex;
					text-transform: uppercase;
					letter-spacing: 3px;
					font-size: 12px;

					&:after {
						content: $icon-longarrowright;
						font-size: 7px;
						font-family: $iconFont;
						margin-left: 10px;
					}
				}
			}
		}
	}
}
