.animations-widget {
	position: relative;

	&--content-center {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%);

		&-rive {
			margin: 100px;
			transform: none;
			text-align: center;
			font-weight: 700;
		}
	}

	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	&--rive {

		margin-left: -20px;
    	margin-right: -20px;

		@media screen and (min-width: 768px) {
			margin-left: 0;
    		margin-right: 0;
		}

		#riveCanvas {
			width: 100%;
			height: 100%;
			margin: auto;
			display: block;
			
			@media screen and (min-width: 768px) {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;	
			}

			@media screen and (max-width: $mobile-portrait-screen) {
				width: 100vw;
				height: 100%;
			}
		}

		&-fallback {
			display: none;
		}
	}

	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}

	&--close {
		position: absolute;
		right: 13px;
		top: 5px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;

		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
}

.sfPageEditor {
	.animations-widget--rive {
		width: 80vw;
		margin-left: calc(-1 * (80vw - 100% + 60px) / 2);
	}
}
