.content-block {
	position: relative;

	&--parallax {
		clip-path: inset(0);

		@include breakMax($breakMedium) {
			display: flex;
			flex-flow: column;
		}

		.back-picture {
			object-fit: cover;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			order: 1;

		}

		&-mobile {
			display: none;

			@include breakMax($breakMedium) {
				display: block;
				order: 0;
			}
		}

		&-desktop {
			@include breakMax($breakMedium) {
				display: none;
			}
		}
	}

	&>* {
		opacity: 1;
		transition: all 0.3s ease-in;
		visibility: visible;
	}

	&.hidden>* {
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease-in;
	}

	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 13px;
		top: 5px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
	.wysiwyg {
		position: relative;
	}
}
