@import "./xbrl";

.sec-filing {
	transition: $fastDelay opacity ease-in-out;
	&.deactivated {
		opacity: 0.5;
		pointer-events: none;
		transition: $slowDelay opacity ease-in-out;
	}
	&--title {
		margin-bottom: 30px;
	}
	&--menu {
		display: flex;
		flex-flow: column;
		margin-bottom: 15px;
		@include breakMin($breakMediumSmall) {
			flex-flow: row;
			margin-bottom: 30px;
		}
	}
	&--input-box,
	.check-drop-down {
		margin-bottom: 15px;
		@include breakMin($breakMediumSmall) {
			margin-bottom: 0;
			margin-right: 25px;
			&:last-child {
				margin: 0;
			}
		}
	}
	.check-drop-down {
		&--main-button {
			width: 100%;
		}
		&--content {
			justify-content: flex-start;
		}
	}
	&--input-box {
		position: relative;
		display: flex;
		height: 39px;
		flex-grow: 1;
		border: 1px solid $buttonGrey;
	}
	&--input {
		border: none;
		flex-grow: 1;
		padding-right: 35px;
		padding-left: 20px;
		&:focus {
			outline: none;
			&::placeholder {
				color: $colorPrimary;
			}
		}
	}
	&--search {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		background: none;
		border: none;
		padding: 0;
		&::before {
			content: $icon-search;
			font-family: $iconFont;
			color: $grey;
		}
		&:focus {
			outline: none;
			&::before {
				color: $colorPrimary;
			}
		}
	}
	&--content,
	&--table {
		width: 100%;
	}
	&--head,
	&--data {
		padding: 25px 40px;
		text-align: center;
		&.left-aligned {
			text-align: left;
		}
		&.date-content {
			background: $colorPrimary;
			color: white;
		}
		&.head-content {
			background: $lightGrey;
		}
		&:nth-child(2) {
			position: relative;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: calc(100% + 1px);
				width: 8px;
				background: white;
			}
		}
	}
	&--head {
		font-size: 14px;
		font-weight: bold;
		color: $darkGrey;
	}
	&--data {
		color: $grey;
		&.description {
			color: $darkGrey;
		}
		&:not(.date-content) {
			border-bottom: 1px solid $lightGrey;
		}
		// download column
		&:nth-child(5) {
			white-space: nowrap;
			font-size: 0; // remove indentation spaces
		}
	}
	&--download {
		margin-right: 8px;
		padding: 0;
		background: none;
		border: none;
		&:last-child,
		&.xbrl-file {
			margin: 0;
		}
		&::before {
			display: inline-block;
			font-family: $iconFont;
			font-size: 20px;
			color: $colorPrimary;
			transition: $mediumDelay transform ease-in-out;
		}
		&:focus {
			outline: none;
			&::before {
				color: $textBlack;
				transform: scale(1.1);
				transition: $fastDelay transform ease-in-out;
			}
		}
	}
	// mobile styles

	&--filing {
		margin-bottom: 20px;
		border-bottom: 1px solid $buttonGrey;
	}
	&--date {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $colorPrimary;
		height: 70px;
		padding: 0 55px;
		margin-bottom: 20px;
		&-content {
			font-size: 14px;
			font-weight: bold;
			color: white;
		}
	}
	&--description,
	&--notes,
	&--downloads {
		padding: 0 15px;
	}
	&--description {
		font-size: 16px;
		color: $darkGrey;
		margin-bottom: 15px;
	}
	&--notes {
		display: flex;
		margin-bottom: 30px;
	}
	&--note {
		color: $grey;
		margin-right: 70px;
	}
	&--downloads {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		&-title {
			font-size: 14px;
			color: $darkGrey;
		}
		& > * {
			margin-right: 25px;
		}
	}
}
