$qrCodeWidth: 150px;
$qrCodePadding: 30px;

.ir-event-detail {
	.breadcrumbs {
		margin: 0 0 55px;
	}
	&--top {
		--outerMargin: #{$pagePadding};
		background: $medlightGrey;
		margin: 0 calc(-1 * var(--outerMargin)) 60px;
		padding: 30px var(--outerMargin) 25px;
		@include breakMin($breakMediumSmall) {
			--outerMargin: calc(50vw - 50%);
			padding: 30px var(--outerMargin) 85px;
		}
	}
	&--masonry {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&--title {
		font-size: 30px;
		font-weight: normal;
		color: $darkGrey;
		margin-bottom: 40px;
	}
	&--document-block {
		background: white;
		padding: 30px 30px 45px;
		margin-bottom: 25px;
		width: 100%;
		@include breakMin($breakMediumSmall) {
			width: calc(50% - 12.5px);
		}
		&:last-child {
			margin: 0;
		}
		&.keys {
			@include breakMin($breakMediumSmall) {
				width: calc(40% - 12.5px);
				&:nth-last-child(2) {
					margin: 0;
				}
			}
		}
	}
	&--block-title {
		font-size: 24px;
		font-weight: normal;
		color: $darkGrey;
		margin-bottom: 40px;
	}
	&--documents li {
		margin-bottom: 30px;
		&:last-child {
			margin: 0;
		}
	}
	&--document {
		display: flex;
		align-items: center;
		font-size: 15px;
		color: $darkGrey;
		transition: $fastDelay color ease-in-out;
		&::before {
			font-family: $iconFont;
			color: $mediumGrey;
			font-size: 26px;
			margin-right: 10px;
			transition: $fastDelay color ease-in-out;
		}
		&.link::before {
			font-size: 20px;
		}
		&:hover,
		&:focus {
			outline: none;
			color: $textBlack;
			transition: none;
			&::before {
				color: $colorPrimary;
				transition: none;
			}
		}
	}

	&--cta-box {
		display: flex;
		flex-flow: column;
		color: white;
		padding: 20px $pagePadding 40px;
		background: $colorPrimary;
		margin: 0 -#{$pagePadding} 25px;
		@include breakMin($breakMediumSmall) {
			margin: 0;
			width: calc(60% - 12.5px);
			padding: 30px 30px 50px;
		}
		&:focus:not(:hover) {
			outline: $blockFocusOutline;
		}
	}
	&--hour {
		display: flex;
		align-items: center;
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 20px;
		&::before {
			content: $icon-lightClock;
			font-family: $iconFont;
			font-size: 28px;
			margin-right: 10px;
		}
	}
	&--date {
		font-size: 18px;
		margin-bottom: 15px;
	}
	&--cta-title {
		font-size: 28px;
		color: white;
		margin-bottom: 20px;
	}
	&--cta-link,
	&--cta-box:focus &--cta-link,
	&--cta-box:hover &--cta-link {
		margin-top: 50px;
		color: white;
		@include breakMin($breakMediumSmall) {
			margin-top: auto;
		}
		&::before {
			background: white;
			color: $colorPrimary;
		}
	}
	&--bottom-row {
		display: flex;
		flex-flow: column;
		align-items: flex;
		margin-bottom: 40px;
		@include breakMin($breakMediumSmall) {
			flex-flow: row;
			margin-bottom: 65px;
		}
	}
	&--note {
		flex-grow: 1;
		@include breakMin($breakMediumSmall) {
			padding-right: 50px;
			order: -1;
		}
		@include breakMin($breakLarge) {
			padding-right: 180px;
		}
	}
	&--note-title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 20px;
	}
	&--note-content {
		font-size: 18px;
		line-height: 28px;
		color: $darkGrey;
	}
	&--qr-code {
		position: relative;
		padding: $qrCodePadding;
		box-shadow: 0 10px 20px rgba(black, 0.1);
		align-self: flex-start;
		margin: 0 auto 70px;
		min-width: #{$qrCodeWidth + $qrCodePadding * 2};
		height: #{$qrCodeWidth + $qrCodePadding * 2};
		@include breakMin($breakMediumSmall) {
			margin: 0;
		}
		@include breakMin($breakMedium) {
			margin-right: 80px;
		}
		img {
			min-width: $qrCodeWidth;
			image-rendering: pixelated;
			image-rendering: crisp-edges;
			width: auto;
			height: auto;
		}
	}
	&--footnote {
		font-size: 14px;
		font-weight: lighter;
		color: $darkGrey;
	}
}
