.ir-app {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	&--title {
		font-size: 30px;
		font-weight: normal;
		color: white;
		margin-bottom: 20px;
	}
	&--description {
		font-size: 16px;
		line-height: 24px;
		color: white;
		margin-bottom: 40px;
	}
	&--links {
		display: flex;
		justify-content: flex-start;
	}
	&--link {
		outline: none;
		img {
			width: 200px;
			height: auto;
		}
		transition: $mediumDelay transform ease-in-out;
		&:first-child {
			margin-right: 30px;
		}
		&:focus {
			transition: $fastDelay transform ease-in-out;
			transform: scale(1.1);
		}
	}
	&.cta-mode {
		padding: 15px 20px;
		background: $colorPrimary;
		.ir-app {
			&--title {
				font-size: 12px;
				font-weight: bold;
				margin: 0;
			}
			&--description {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 15px;
			}
			&--links {
				flex-flow: column;
			}
			&--link {
				margin-right: 0;
				margin-bottom: 5px;
			}
		}
		&.is-white {
			background: white;
			border: 1px solid $buttonGrey;
			.ir-app {
				&--title {
					color: $grey;
				}
				&--description {
					color: $textBlack;
				}
			}
		}
	}
}
