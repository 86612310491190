// this is an extension of V3-Homepage-Header-V1
.V4-modifier.mirrored.V3-Homepage-Header-V1 {
	@include breakMin($breakSmall) {
		margin: 0 #{-$pagePadding} $subWidgetSpacing;
		width: calc(100% + #{2 * $pagePadding});
		grid-template-areas:
			"small1 big big"
			"small2 big big"
			"medium big big";
	}
	@include breakMin($breakMedium) {
		grid-template-areas:
			"small1 small2 big big big"
			"medium medium big big big";
	}
	@include breakMin($breakLarge) {
		margin: 0 0 $subWidgetSpacing;
	}
	.V3-Homepage-Header-V1--big {
		@include breakMin($breakSmall) {
			margin: 0;
			&.sf_colsIn > * {
				border-width: $outlineWidth 0 $outlineWidth $outlineWidth;
			}
		}
	}
	.V3-Homepage-Header-V1--medium,
	.V3-Homepage-Header-V1--small1,
	.V3-Homepage-Header-V1--small2 {
		@include breakMin($breakSmall) {
			&.sf_colsIn > * {
				border-width: $outlineWidth $outlineWidth $outlineWidth 0;
			}
		}
	}
	.V3-Homepage-Header-V1--small2 {
		@include breakMin($breakMedium) {
			&.sf_colsIn > * {
				border-width: $outlineWidth $outlineWidth $outlineWidth $outlineWidth;
			}
		}
	}
}
