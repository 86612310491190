.table-widget-ir {
	$self: &;
	&--title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 30px;
	}
	&--description {
		font-size: 16px;
		line-height: 24px;
		color: $darkGrey;
		margin-bottom: 80px;
		max-width: 80%;
	}
	&--container {
		overflow-x: auto;
		padding-bottom: 25px;
	}
	&--table {
		min-width: 100%;
	}
	&--header-cell,
	&--cell {
		padding: 25px 35px;
		text-align: center;
		@include breakMin($breakLarge) {
			padding: 25px 60px;
		}
	}
	.footnoted {
		cursor: pointer;
	}
	.date-content &--cell {
		color: white;
	}
	&--header-cell.date-content {
		color: white;
		background: $colorPrimary;
		white-space: nowrap;
	}
	th:nth-child(2),
	td:nth-child(2) {
		position: relative;
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: calc(100% + 1px);
			width: 8px;
			background: white;
		}
	}
	&--header-cell {
		font-size: 14px;
		font-weight: bold;
		color: $darkGrey;
		background: $lightGrey;
	}
	tbody {
		td,
		th {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				border-bottom: 1px solid $lightGrey;
			}
			&.date-content::before {
				background: $colorPrimary;
				border-color: $colorPrimary;
			}
		}
		tr:hover,
		tr.highlighted {
			td:not(.date-content)::before {
				background: $lightGrey;
			}
		}
	}
	&--cell {
		color: $grey;
		position: relative;
	}
	tfoot td {
		border: none;
		padding: 0;
	}
	&--more-button {
		position: relative;
		padding: 25px 40px;
		text-align: center;
		width: 100%;
		background: white;
		border: 1px solid $colorPrimary;
		overflow: hidden;
		cursor: pointer;
		outline: none;
	}
	&--button-label {
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 0.15;
		color: $colorPrimary;
		white-space: nowrap;
		transform: translateY(-50px);
		transition: $mediumDelay transform ease-in-out;
		&:after {
			content: $icon-down;
			display: inline-block;
			font-family: $iconFont;
			font-size: 8px;
			margin-left: 10px;
			transform: translateX(0);
			transition: $fastDelay transform ease-in-out;
		}
		button:hover &,
		button:focus & {
			&:after {
				transform: translateX(5px);
			}
		}
		&.active {
			transform: none;
		}
		&:last-child {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, calc(-50% + 50px));
			&:after {
				transform: scaleY(-1);
			}
			&:hover,
			button:focus & {
				&:after {
					transform: translateX(5px) scaleY(-1);
				}
			}
			&.active {
				transform: translate(-50%, -50%);
			}
		}
	}
	&--collapsing-rows {
		tr {
			--row-height: 0;
		}
		#{$self}--cell-container {
			overflow: hidden;
			height: var(--row-height);
			transition: $mediumDelay height ease-in-out;
		}
		&:not(.open) #{$self}--cell {
			color: transparent;
			border-color: transparent;
			transition: $mediumDelay color ease-in-out, $mediumDelay border-color ease-in-out;
		}
		&.open #{$self}--cell {
			transition: $mediumDelay color ease-in-out $fast, $mediumDelay border-color ease-in-out $fast;
		}
	}

	&--footnotes {
		margin-top: 20px;
	}
	&--footnote {
		display: block;
		font-size: 14px;
		font-weight: lighter;
		color: $darkGrey;
		cursor: pointer;
		padding: 0;
		background: none;
		border: none;
		outline: none;
		text-align: left;
		&:hover,
		&:focus,
		&.highlighted {
			color: $colorPrimary;
		}
		a {
			text-decoration: underline;
		}
	}
	&--sup {
		vertical-align: super;
    	font-size: smaller;
	}
	&--file {
		position: relative;
		text-align: center;
		z-index: 100;
		&::before {
			content: $icon-pdffile;
			font-family: $iconFont;
			font-size: 20px;
			color: $colorPrimary;
		}
		&:focus {
			outline: none;
			&::before {
				font-size: 25px;
				color: $textBlack;
			}
		}
	}
}
