.V3-category-list-4-1 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto $subWidgetSpacingMobile;
  flex-wrap: wrap;
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }
  .col1 {
    width: 100%;
    margin-bottom: 30px;

    @include breakMin($breakLarge) {
      width: 80%;
    }
  }

  .col2 {
    width: 100%;
    display: grid;
    grid-gap: 24px;

    .highlight {
      min-height: 300px;
    }

    @include breakMin($breakMediumSmall) {
      grid-template-columns: 1fr 1fr;

      .editors-pick-widget {
        grid-column: 1 / -1;
      }

      .highlight {
        min-height: 330px;
      }
    }

    @include breakMin($breakMedium) {
      .highlight {
        min-height: 370px;
      }
    }

    @include breakMin($breakLarge) {
      width: calc(20% - 24px);
      grid-template-columns: 1fr;

      .highlight {
        min-height: 275px;
      }
    }
  }
}
