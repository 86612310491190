.control-bar {
	padding: 10px 0;
	--button-scale: 1;
	cursor: pointer;
	&--content {
		$borderRadius: 1.5px;
		position: relative;
		height: 3px;
		background: rgba(white, 0.5);
		border-radius: $borderRadius;

		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			top: 0;
			left: 0;
			width: calc(100% * var(--progress));
			height: 100%;
			border-radius: $borderRadius;
			background: white;
		}
		&::after {
			top: 50%;
			width: 10px;
			height: 10px;
			left: calc(100% * var(--progress));
			transform: translate(-50%, -50%) scale(var(--button-scale));
			border-radius: 50%;
			background: white;
			transition: $fastDelay transform ease-in-out;
		}
	}
}
