.upcoming-events-widget {
	.investor-events-overview {
		&--item {
			padding: 30px 0px;
			border-bottom: 1px solid $buttonGrey;
		}
		&--content {
			line-height: 2rem;
		}
		&--content-date {
			font-size: 18px;
			&::before {
				margin-right: 10px;
				content: $icon-calendar;
				font-family: $iconFont;
				font-size: 20px;
			}
		}
		&--content-title {
			margin-top: 10px;
			font-size: 28px;
			@include breakMin($breakMedium) {
				margin-left: 34px;
			}
		}
		&--calendar-container {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: 20px;
		}
		&--menu {
			width: 160px;
			position: absolute;
			bottom: 15px;
			right: 0;
			padding: 15px;
			margin-bottom: 25px;
			display: none;
			opacity: 0;
			background-color: white;
			animation: fade-in $slowDelay;
			box-shadow: $menuShadow;
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				right: 25%;
				border-width: 15px;
				border-style: solid;
				border-color: white transparent transparent transparent;
			}
			&.active {
				display: flex;
				flex-direction: column;
				opacity: 1;
			}
		}
		&--menu-title {
			margin-bottom: 10px;
			font-size: 14px;
			.close-btn {
				float: right;
				margin-top: 4px;
				font-size: 8px;
				color: $buttonGrey;
				cursor: pointer;
				&::after {
					content: $icon-cross;
					font-family: $iconFont;
				}
			}
		}
		&--menu-item {
			padding-bottom: 10px;
			font-size: 11px;
			color: $grey;
			&::before {
				vertical-align: 20%;
				margin-right: 14px;
				content: $icon-play;
				font-family: $iconFont;
				font-size: 7px;
				color: $grey;
			}
			&:hover {
				color: $colorPrimary;
			}
		}
		&--calendar {
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 600;
			letter-spacing: 2px;
			border: 0;
			outline: 0;
			background: none;
			color: $grey;
			&::before {
				margin-right: 10px;
				content: $icon-plus;
				font-family: $iconFont;
				color: $colorPrimary;
			}
		}
	}
}
