.xbrl {
	position: relative;
	&--modal {
		z-index: 10;
		position: absolute;
		top: calc(-100% - 20px);
		left: $pagePadding;
		background: white;
		box-shadow: $menuShadow;
		width: calc(100vw - #{2 * $pagePadding});
		overflow: hidden;
		height: 0;
		transition: $mediumDelay height ease-in-out;
		@include breakMin($breakMediumSmall) {
			top: -20px;
			left: calc(100% + 10px);
			width: auto;
		}
		@include breakMin($breakMedium) {
			right: calc(100% + 10px);
			left: auto;
			width: auto;
		}
		.up-modal & {
			bottom: -20px;
			top: auto;
		}
	}
	&--content {
		padding: 25px 0 10px;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
	}
	&--title {
		font-size: 14px;
		margin-bottom: 10px;
		margin-left: 30px;
		color: $darkGrey;
	}
	&--file {
		display: flex;
		width: 100%;
		font-size: 12px;
		text-transform: uppercase;
		text-align: left;
		line-height: 18px;
		color: $textBlack;
		padding: 10px 30px;
		transition: $mediumDelay background ease-in-out, $mediumDelay color ease-in-out;
		&::before {
			content: $icon-file;
			font-family: $iconFont;
			font-size: 20px;
			color: $colorPrimary;
			margin-right: 15px;
			transition: $mediumDelay color ease-in-out;
		}
		&:hover,
		&:focus {
			outline: none;
			background: $colorPrimary;
			color: white;
			transition: $fastDelay background ease-in-out, $fastDelay color ease-in-out;
			&::before {
				transition: $fastDelay color ease-in-out;
				color: white;
			}
		}
	}
}
