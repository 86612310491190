.key-figures-icon {
	text-align: center;
	position: relative;
	&.no-slider {
		.key-figure--controls {
			display: none;
		}
		.key-figure--element {
			margin: 0 20px 0 0;
			@include breakMax($breakMedium) {
				margin: 20px 0;
			}
		}
	}
	.tns-nav {
		display: flex;
		button {
			border: none;
			margin: 0 5px;
			width: 7px;
			height: 7px;
			padding: 0;
			background-color: $textGrey;
			&.tns-nav-active {
				background-color: $colorPrimary;
				transform: scale(1.43);
			}
		}
	}
	.tns-inner {
		@include breakMax($breakMedium) {
			overflow: visible;
		}
	}

	h3 {
		font-size: 30px;
	}
	img {
		height: 80px;
		width: auto;
		margin: 0 auto 20px;
		display: block;
	}
	.key-figure--element {
		margin: 0;
		@include breakMax($breakMedium) {
			max-width: 100%;
		}

		&-container {
			height: 100%;
			transition: $mediumDelay border ease-in-out;
			border: 1px solid #d7d7d7;
			padding: 15px 10px;
			display: block;
			&:not(.no-link):hover {
				border: 1px solid $colorPrimary;
				.key-figure--description {
					color: $colorPrimary;
				}
			}
		}
	}
	.key-figure--description {
		font-size: 15px;
		transition: $mediumSpeed;
	}
}
