.market-nav {
	display: flex;
	justify-content: space-between;
	height: 60px;
	margin: 0 #{-$pagePadding};
	padding: 0 $pagePadding;
	width: calc(100% + #{2 * $pagePadding});
	@include breakMin($breakNavigation) {
		height: 50px;
	}

	&--goto {
		flex-wrap: nowrap;
		align-items: center;
		display: flex;
		text-transform: uppercase;
		font-size: 16px;
		color: $colorPrimary;

		@include breakMin($breakNavigation) {
			flex-wrap: nowrap;
		}
	}

	&--logo {
		margin: 0 auto 0 40px;
		min-width: max-content;
		display: flex;
		align-items: center;
		[dir='rtl'] & {
			margin: 0 40px 0 auto;
		}

		img {
			height: 36px;
			width: auto;

			@include breakMin($breakNavigation) {
				height: auto;
				max-height: 60px;
			}
		}

		.desktop {
			display: none;
		}

		@include breakMin($breakNavigation) {
			.desktop {
				display: block;
			}
		}

		@include breakMin($breakNavigation) {
			.mobile {
				display: none;
			}
		}
	}

	&--content {
		display: flex;
		justify-content: space-between;
	}

	&--menus-container {
		display: flex;
		margin-left: 25px;
		flex-grow: 1;
		transition: $mediumDelay height ease-in-out;
		@include breakMax($breakNavigation) {
			position: absolute;
			top: 45px;
			left: 0;
			width: 100vw;
			height: 0;
			overflow: hidden;
			margin: 0;
		}
	}
	&--menus {
		display: flex;
		flex-flow: column;
		width: 100%;
		background: white;
		padding: 30px $pagePadding;
		@include breakMin($breakNavigation) {
			flex-flow: row;
			width: auto;
			align-self: stretch;
			background: none;
			padding: 0;
		}
	}
	.unfoldable-menu {
		background: white;
		&--dropper {
			background: white;
		}
		&--title.droppable::after {
			color: $colorPrimary;
		}
		&--container {
			top: 50px;
		}
	}

	&--right {
		display: flex;
	}

	.drop-down {
		display: flex;
		align-items: flex-start;
		margin-right: 5px;
		margin-top: 10px;
		@include breakMin($breakNavigation) {
			margin-top: 2px;
		}

		&--current {
			@include breakMin($breakNavigation) {
				padding: 14px 22px;
			}
		}

		&--dot {
			display: none;
		}

		li {
			&:first-child {
				margin-top: 10px;
			}
		}
	}

	&--burger {
		border: none;
		background: none;
		padding: 0;
		position: relative;
		align-self: center;
		width: 24px;
		height: 16px;
		z-index: 2000;
		cursor: pointer;
		[dir='rtl'] & {
			margin-left: 30px;
		}

		& > span {
			position: absolute;
			background: $darkGrey;
			width: 100%;
			height: 2px;
			left: 50%;
			transform: translateX(-50%);
			transition: $fastDelay transform ease-in-out,
				$mediumDelay background ease-in-out;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: calc(50% - 1px);
			}

			&:nth-child(3) {
				bottom: 0;
				transform: none;
				right: 0;
				width: 16px;
				left: auto;
			}
		}

		&:focus {
			outline: none;

			& > span {
				background: $colorPrimary;
			}
		}

		.open &,
		&.close {
			height: 24px;

			& > span {
				&:nth-child(1) {
					top: calc(50% - 1px);
					transform: translateX(-50%) rotate(45deg);
				}

				&:nth-child(2) {
					transform: translateX(-50%) rotate(-45deg);
				}

				&:nth-child(3) {
					width: 0;
				}
			}
		}
		@include breakMin($breakNavigation) {
			display: none;
		}
	}
}
