.video {
	transition: $mediumDelay opacity ease-in-out;
	&:not(.active) {
		opacity: 0.5;
		pointer-events: none;
	}
	.plyr__controls__item:not(.plyr__volume):not(.plyr__progress__container) {
		flex-grow: 0;
	}
	.plyr__progress__container {
		flex-grow: 1;
		@include breakMax($breakSmall) {
			flex: 1;
		}
	}

	.plyr--is-ios {
		.plyr__volume {
			display: flex !important;
		}
	}

	.plyr__video-wrapper.plyr__video-embed {
		@include landscapeOrientation() {
			padding-bottom: 35% !important;
		}
	}
	.plyr--full-ui ::-webkit-media-text-track-container {
		display: block;
	}
}
