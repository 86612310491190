.layout-4-columns {
	display: flex;
	flex-flow: column;
	padding-bottom: $subWidgetSpacingMobile;
	> .sf_colsIn {
		width: 100%;
		margin-bottom: 40px;
	}
	@include breakMin($breakMediumSmall) {
		flex-flow: row;
		justify-content: space-between;
		flex-wrap: wrap;
		> .sf_colsIn {
			width: calc(50% - 14px);
			margin-bottom: 20px;
		}
	}
	@include breakMin($breakMedium) {
		padding-bottom: $subWidgetSpacing;
		> .sf_colsIn {
			width: calc(25% - 14px);
			margin-bottom: 0;
		}
	}
}
