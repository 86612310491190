.news-and-press-item {
	padding: 25px 20px;
	border-bottom: 1px solid $buttonGrey;
	@include breakMin($breakLarge) {
		height: 130px;
	}
	&.height-auto {
		height: auto;
	}
	&--head {
		display: flex;
		justify-content: space-between;
	}
	&--category {
		text-transform: uppercase;
		color: $textBlack;
		align-self: center;
	}
	&--date {
		text-transform: capitalize;
		font-weight: 400;
		color: $grey;
	}
	&--title {
		font-size: 16px;
		width: 80%;
		color: $darkGrey;
		font-weight: bold;
		@include breakMin($breakLarge) {
			display: -webkit-box;
			overflow: hidden;
			line-height: 20px;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			max-height: 60px;
			&.active {
				width: 90%;
			}
		}
		&:focus {
			outline: none;
			color: $colorPrimary;
		}
	}
	&--top-link {
		font-size: 22px;
		transition: $mediumSpeed transform ease-in-out, $mediumSpeed color ease-in-out;
		&:before {
			content: $icon-circlelink;
			font-family: $iconFont;
			display: inline-block;
			[dir="rtl"] & {
				transform: rotate(0.5turn);
			}
		}
		&.download {
			&:before {
				content: $icon-circledownload;
				font-family: $iconFont;
			}
		}
		&:focus {
			outline: none;
			transform: scale(1.1);
			color: $textBlack;
			transition: $fastDelay transform ease-in-out, $fastDelay color ease-in-out;
		}
	}
}
