.V3-Job-Search-Layout {
  margin: 0 auto $subWidgetSpacingMobile;
  display: flex;
  flex-flow: column;
  @include breakMin($breakMediumSmall) {
    flex-flow: row;
  }
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }
  > .sf_colsIn {
    @include breakMin($breakSmall) {
      padding: 0 30px;
    }
    @include breakMin($breakMediumSmall) {
      width: 50%;
      padding: 0;
    }
    &:first-child {
      margin-bottom: 20px;
      @include breakMin($breakMediumSmall) {
        margin-bottom: 0;
      }
    }
    &:last-child {
      @include breakMin($breakMediumSmall) {
        padding-left: 30px;
      }
      @include breakMin($breakMedium) {
        padding-left: 60px;
      }
      @include breakMin($breakLarge) {
        padding-left: 140px;
      }
    }
  }
}
