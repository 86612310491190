.color {
	&--innovating {
		--color-primary: #{$cobaltBlue};
	}
	&--operating {
		--color-primary: #{$eggplantViolet};
	}
	&--caring {
		--color-primary: #{$navyBlue};
	}
	&--protecting {
		--color-primary: #{$pineGreen};
	}
	&--pmiBlue {
		--color-primary: #{$lochmaraBlue};
		--color-primary-hover: #{$endeavourBlue};
	}
	&--careersBlue {
		--color-primary: #{$bayOfManyBlue};
		--color-primary-hover: #{$biscayBlue};
	}
	&--sampoernaRed {
		--color-primary: #{$milanoRed};
		--color-secondary: #{$sampoernaGold};
		--color-primary-hover: #{$bean};
	}
	&--unsmokeYellow {
		--color-primary: #{$schoolBusYellowRGB};
		--color-primary-hover: black;
	}
	&--tarLightPink {
		--color-primary: #{$tarLightPink};
		--color-primary-hover: #{$pinkHover};
	}
	&--tarPink {
		--color-primary: #{$tarPink};
		--color-primary-hover: #{$pinkHover};
	}
	&--nicotineGreen {
		--color-primary: #{$nicotineGreen};
		--color-primary-hover: #{$greenHover};
	}
	&--tarGrey {
		--color-primary: #{$tarGrey};
		--color-primary-hover: #{$greyHover};
	}
	&--combustionGrey {
		--color-primary: #{$combustionGrey};
		--color-primary-hover: #{$greyHover};
	}
	&--nicotineLightGreen {
		--color-primary: #{$nicotineLightGreen};
		--color-primary-hover: #{$lightGreenHover};
	}
	&--combustionGold {
		--color-primary: #{$combustionGold};
		--color-primary-hover: #{$goldHover};
	}
	&--combustionKhaki {
		--color-primary: #{$combustionKhaki};
		--color-primary-hover: #{$khakiHover};
	}
	&--combustionCararra {
		--color-primary: #{$combustionCararra};
		--color-primary-hover: #{$cararraHover};
	}
	&--combustionLightGreen {
		--color-primary: #{$combustionLightGreen};
		--color-primary-hover: #{$combustionLightGreenHover};
	}
	&--combustionOrange {
		--color-primary: #{$combustionOrange};
		--color-primary-hover: #{$orangeHover};
	}
	&--tarBlack {
		--color-primary: #{$tarBlack};
		--color-primary-hover: #{$blackHover};
	}
	&--tarDarkGrey {
		--color-primary: #{$tarDarkGrey};
		--color-primary-hover: #{$darkGreyHover};
	}
	&--pmiWhite {
		--color-primary: #{$pmiWhite};
	}
	&--pmiGrey {
		--color-primary: #{$pmiGrey};
	}
	&--christineOrange {
		--color-primary: #{$christineOrange};
		--color-primary-hover: #{$christineOrangeHover};
	}
	&--social {
		--color-primary: #{$socialPink};
	}
	&--socialLight {
		--color-primary: #{$socialPinkLight};
	}
	&--socialLightSecondary  {
		--color-primary: #{$socialPinkLightSecondary};
	}
	&--environment {
		--color-primary: #{$environmentGreen};
	}
	&--environmentLight {
		--color-primary: #{$environmentGreenLight};
	}
	&--environmentLightSecondary {
		--color-primary: #{$environmentGreenLightSecondary};
	}
	&--governance {
		--color-primary: #{$governanceBlue};
	}
	&--transparent {
		--color-primary: #{$transparent};
	}
}

[class*='color--'] {
	--background-primary: rgb(var(--color-primary));
	--text-primary: rgb(var(--color-primary));
}

[class*='bg--'] {
	--background-primary: rgb(var(--color-primary));
	background-color: var(--background-primary);
}
