$mobileImageHeight: 350px;
.V3-awareness-content-banner {
	--side-padding: #{$pagePadding};
	--top-padding: 0;
	--bottom-padding: 45px;
	--gradient-height: 170px;
	width: calc(100% + 2 * var(--side-padding));
	position: relative;
	margin: 0 calc(0px - var(--side-padding));
	padding: var(--top-padding) var(--side-padding) var(--bottom-padding);
	@include breakMin($breakMediumSmall) {
		--top-padding: 60px;
	}
	@include breakMin($breakMedium) {
		--top-padding: 210px;
		--bottom-padding: 90px;
		--gradient-height: 330px;
	}
	@include breakMin($breakNavigation) {
		--side-padding: 0;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: var(--gradient-height);
		background: linear-gradient($gradientBlack, transparent);
		z-index: 1;
		pointer-events: none;
	}
	&--back-picture {
		position: absolute;
		top: 50px;
		right: var(--side-padding);
		width: 50%;
		height: #{$mobileImageHeight - 50px};
		@include breakMin($breakMediumSmall) {
			height: calc(100% - var(--bottom-padding) - 50px);
			right: 48%;
			width: 25%;
		}
		.still-picture {
			position: absolute;
			height: 100%;
			width: auto;
			top: 0;
			right: 0;
			object-fit: contain;
		}
	}
	&--title {
		h1,
		h2 {
			font-size: 28px;
			line-height: 34px;
			text-transform: uppercase;
			@include breakMin($breakMediumSmall) {
				font-size: 57px;
				line-height: 69px;
			}
		}
		[dir='rtl'] {
			width: auto;
			max-width: 400px;
			word-break: break-word;
			//Solves special characters alignment issue
			&:after {
				content: '\200E‎';
			}
			&:before {
				content: '\200E‎';
			}
		}
	}
	&--column {
		position: relative;
		width: 100%;
		z-index: 2;
		&:first-child {
			margin-bottom: 24px;
			@include breakMax($breakMediumSmallBefore) {
				display: flex;
				flex-flow: column;
				justify-content: center;
				min-height: $mobileImageHeight;
				width: 50%;
			}
		}
		@include breakMin($breakMediumSmall) {
			width: calc(50% - 12px);
			margin-bottom: 0;
		}
	}
	&--columns {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 auto;
	}
}
