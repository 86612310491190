.carousel-highlight-nav {
	display: none;
	margin: 0 auto;
	@include breakMin($breakSmall) {
		display: flex;
		align-items: center;
	}
	&--dots {
		display: flex;
		margin: 0 35px;
	}
	&--dot {
		margin: 0 5px;
		padding: 0;
		width: 7px;
		height: 7px;
		background: $textGrey;
		transition: $fastDelay background ease-in-out,
			$fastDelay transform ease-in-out;
		border: none;
		color: transparent;
		font-size: 0;
		&:disabled {
			transform: scale(1.43);
			background: $backgroundPrimary;
		}
		&:focus {
			outline: $blockFocusOutline;
			background: white;
		}
		@include breakMin($breakMedium) {
			&:last-child {
				display: none;
			}
		}
	}
	&--previous,
	&--next {
		display: inline-flex;
		align-items: center;
		color: $textColor;
		font-size: 12px;
		text-transform: uppercase;
		background: none;
		border: none;
		padding: 0;
		letter-spacing: 0.15em;
		cursor: pointer;
		transition: $fastDelay color ease-in-out;

		&:hover,
		&:focus {
			color: $textPrimary;
			font-weight: bold;
			outline: none;
			&:before,
			&:after {
				transition: $fastDelay color ease-in-out;
				color: $textPrimary;
			}
		}

		&:disabled {
			pointer-events: none;
			&, &::before, &::after{
				color: $textGrey;
			}
		}
		&::before,
		&::after {
			margin: 0 10px;
			[dir="rtl"] & {
				transform: rotate(180deg);
			}
		}
	}
	&--previous::before {
		content: $icon-longarrowleft;
		font-family: $iconFont;
		font-size: 10px;
	}

	&--next::after {
		content: $icon-longarrowright;
		font-family: $iconFont;
		font-size: 10px;
	}
}
