$jobBannerMinHeight: 740px;

.job-banner {
	--input-color: white;
	@include breakMin($breakMedium) {
		margin: 0 -20px 55px;
	}

	@include breakMin($breakLarge) {
		margin: 0;
	}

	// Breadcrumbs overrides
	.breadcrumbs {
		width: 100%;
		margin-bottom: 70px;

		@include breakMin($breakMediumSmall) {
			margin-top: 10px;
		}
	}

	// Job search overrides
	.job-search--title {
		margin-bottom: 30px;
		font-size: 28px;
		font-weight: 700;
		position: relative;
		color: #fff;
	}

	.search-field {
		background: none;

		&,
		&::placeholder,
		input,
		input::placeholder {
			color: var(--input-color);
			transition: $mediumDelay color ease-in-out;
		}
	}
	.check-drop-down.open {
		--input-color: $darkGrey;
	}

	.check-drop-down--back {
		background: none;
	}

	.arrowed-link {
		position: relative;
		color: white;
	}
}

.job-banner--inner {
	position: relative;
	display: flex;
	flex-flow: column;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -#{$pagePadding} $subWidgetSpacingMobile;
	padding: 0 20px 20px 20px;
	width: calc(100% + 2 * #{$pagePadding});
	min-height: $jobBannerMinHeight;
	max-width: none;

	@include breakMin($breakMediumSmall) {
		flex-direction: row;
		justify-content: flex-end;
		padding: 0 20px 60px 20px;
	}

	@include breakMin($breakMedium) {
		margin: 0 auto $subWidgetSpacing;
		padding: 0 40px 50px 60px;
		width: 100%;
	}

	@include breakMin($breakLarge) {
		padding: 0 calc((100% - 1170px) / 2) 60px calc((100% - 1170px) / 2);
	}
}

.job-banner--background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: #{$jobBannerMinHeight};
	overflow: hidden;

	@include breakMin($breakMediumSmall) {
		min-height: 0;
	}

	.back-picture.has-gradient:after {
		height: calc(100% - 20px);
		background: linear-gradient(transparent, $gradientColor);

		@include breakMin($breakMediumSmall) {
			display: none;
		}
	}
}

.job-banner--content {
	@include breakMin($breakMediumSmall) {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	@include breakMin($breakMedium) {
		width: 40%;
	}

	@include breakMin($breakLarge) {
		width: 35%;
	}

	.still-picture {
		position: relative;
		margin: -20px 0 30px 0;
		width: auto;
		height: auto;

		@include breakMin($breakMediumSmall) {
			align-self: flex-end;
		}
	}
}
