.sustainability {
	$self: &;
	position: relative;
	display: flex;
	padding: 40px;
	height: 100%;
	min-height: 200px;
	&.no-image {
		background-color: $lightGrey;
	}
	.text-black {
		color: black;
	}
	&--content {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		z-index: 1;
		color: white;
		> a {
			color: inherit;
		}
	}
	&--title {
		font-weight: 400;
	}
	&--link {
		margin-top: 40px;
		font-size: 12px;
		text-transform: uppercase;
		#{$self}:hover &:after,
		#{$self}:focus &:after {
			margin-left: 25px;
		}
	}
	&:focus {
		outline: $blockFocusOutline;
	}
}
