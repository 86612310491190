.faq-block {
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	background: white;
	padding: 40px 30px 30px;
	box-shadow: 0 0 0 rgba(black, 0.16);
	min-height: 180px;
	transition: $mediumDelay box-shadow ease-in-out;
	pointer-events: none;
	border: 1px solid transparent;
	&:focus {
		outline: none;
		border: 1px solid $colorPrimary;
	}
	&--infos {
		display: flex;
		margin-bottom: 10px;
	}
	&--tag {
		margin-right: 5px;
		font-size: 11px;
		color: $textBlack;
		text-transform: uppercase;
	}
	&--date {
		font-size: 11px;
		color: $grey;
	}
	&--title {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
		color: $textBlack;
	}
	* {
		opacity: 0;
		transition: $fastDelay opacity ease-in-out;
	}
	&.active {
		box-shadow: 0 0 30px rgba(black, 0.16);
		pointer-events: all;
		* {
			opacity: 1;
			transition: $fastDelay opacity ease-in-out $fastDelay;
		}
	}
}
