.image-fade {
	margin: 0 -20px;
	position: relative;

	&--container {
		position: relative;
		text-align: center;
	}

	&--item {
		height: 100vh;
		width: 100%;
	}

	&--wrapper {
		height: 100vh;
		width: 100%;
		position: relative;
		overflow: hidden;

	}
}
