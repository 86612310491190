.footer-layout {
	position: relative;
	z-index: $z-scroll-up-fix;
	background: $lightGrey;
	padding: 40px $pagePadding 50px;
	margin: 0 -#{$pagePadding};
	@include breakMin($breakSmall) {
		padding: 80px 0 50px;
	}
	@include breakMin($breakLarge) {
		margin: 0;
	}
	@supports (-webkit-touch-callout: none) {
		.shorthand-embed-body & {
			z-index: $z-social-ios-fix;
		}
	}
	.footer-grid {
		display: grid;
		grid-gap: 40px;
		grid-template-areas:
			"social"
			"small1"
			"small2"
			"small3"
			"small4"
			"nav";
		@include breakMin($breakXtraSmall) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				"social social"
				"small1 small2"
				"small3 small4 "
				"nav nav";
		}
		@include breakMin($breakSmall) {
			padding-left: 20px;
			padding-right: 20px;
			grid-template-columns: repeat(3, 1fr);
			grid-template-areas:
				"small1 small2 small3"
				"small4 social social"
				"nav nav nav";
		}
		@include breakMin($breakMedium) {
			grid-template-columns: repeat(4, 1fr);
			grid-template-areas:
				"social social social social"
				"small1 small2 small3 small4"
				"nav    nav    nav nav";
		}
		@include breakMin($breakLarge) {
			margin: 0 auto;
			grid-row-gap: 70px;
			grid-template-columns: repeat(4, 1fr) auto;
			grid-template-areas:
				"small1 small2 small3 small4 social"
				"nav    nav    nav    nav        nav";
			padding: 0;
		}
	}

	&--small1 {
		grid-area: small1;
	}

	&--small2 {
		grid-area: small2;
	}

	&--small3 {
		grid-area: small3;
	}

	&--small4 {
		grid-area: small4;
	}

	&--social {
		grid-area: social;
	}

	&--nav {
		grid-area: nav;
	}
	.sf_colsIn > div,
	.sf_colsIn > section,
	.sf_colsIn > a {
		margin-top: 40px;
		&:first-child {
			margin-top: 0;
		}
	}
	.social-media-links--content {
		justify-content: space-between;
		@include breakMin($breakXtraSmall) {
			justify-content: flex-start;
		}
	}
}
