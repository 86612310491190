.contact-highlight {
	padding: 30px 30px 50px;
	background: $lightGrey;
	display: flex;
	flex-flow: column;
	width: 100%;
	@include breakMin($breakMedium) {
		width: auto;
		min-width: 340px;
	}
	&--title {
		font-size: 30px;
		font-weight: normal;
		color: $darkGrey;
		margin-bottom: 30px;
	}
	&--subtitle {
		color: $grey;
		margin: 10px 0;
		font-size: 16px;
	}
	&--phone,
	&--email {
		font-size: 14px;
		color: $textBlack;
		&:before {
			color: $colorPrimary;
			margin-right: 10px;
			font-size: 18px;
			font-family: $iconFont;
		}
	}
	&--phone::before {
		content: $icon-phone;
		font-size: 20px;
	}
	&--email::before {
		content: $icon-mail;
		font-size: 15px;
	}
	&--description {
		margin-top: 5px;
		margin-left: 15px;
		font-size: 12px;
		color: $grey;
	}
}
