.tbe-tags {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	&--tag {
		background: $colorPrimary;
		border: none;
		color: white;
		display: flex;
		align-items: center;
		font-size: 11px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		padding: 5px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
			font-size: 6px;
			margin-left: 5px;
		}
	}
}
