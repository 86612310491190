.wheel-slice {
	--back-color: rgb(var(--color-primary));
	--frontColor: white;
	--shadow-opacity: 0.16;
	--rotation-speed: calc(#{$notchRotationSpeed} * var(--rotation-speed-factor));
	--start-offset: 0.5;
	--slices-opening-angle: calc(360deg / var(--slices-count));
	--slice-opening-angle: var(--slices-opening-angle);
	--slice-position: var(--slice-index);
	--content-band-offset: 0.36;
	--content-offset: 0.34;
	--offset-speed: #{$mediumDelay};
	--opacity-offset: var(--slice-index);
	--slice-index-factor: 1;
	--rotation: calc(
		calc(
		var(--slice-index) * var(--slice-index-factor) - var(--start-offset) -
		calc(var(--current-slice))
		) * var(--slices-opening-angle) +
		calc(360deg * var(--additional-full-turn))
	);

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	$low: calc(100% - #{$slicesGap});
	$xEnd: calc(100% - calc(100% * var(--angle-cos)));
	$yEnd: calc(100% - calc(100% * var(--angle-sin)));
	clip-path: polygon($low $low, 0% $low, 0% 0%, $xEnd $yEnd, 100% 100%);

	opacity: 0;
	transform: rotate(var(--rotation)) translate(-50%, -50%);
	$animation-delay: var(
			--opacity-delay-override,
			calc(var(--rotation-speed) / var(--slices-count) * var(--slice-index))
	);
	transition: var(--rotation-speed) transform ease-in-out,
	var(--rotation-speed) clip-path ease-in-out,
	var(--rotation-speed) opacity ease-in-out $animation-delay;
	pointer-events: none;
	.controlled & {
		--rotation-speed-factor: 0;
	}
	&.tier-two {
		--back-color: rgb(var(--color-primary), 0.75);
	}
	&.active {
		opacity: 1;
		pointer-events: all;
	}
	&.is-wide {
		clip-path: polygon(
				$low $low,
				0% $low,
				0% 0%,
				100% 0%,
				$xEnd $yEnd,
				100% 100%
		);
	}
	&:not(.active) {
		--shadow-opacity: 0;
		&:not(.tier-group) {
			--slice-index: 0.00000001 !important;
			clip-path: polygon(100% 100%, 0% 100%, 0% 100%, 0% 100%, 100% 100%);
		}
		&.section-slice {
			--additional-full-turn: 1;
		}
	}
	&.is-full-width{
		clip-path: none;
		--rotation: -90deg;
		&.active{
			--rotation: 270deg;
		}
	}
	&--highlight,
	&::before,
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		border: 2px solid var(--back-color);
		border-radius: 50%;
		transform: translate(50%, 50%);
		pointer-events: none;
		transition: $notchRotationSpeed border-color ease-in-out,
		var(--offset-speed) width ease-in-out,
		var(--offset-speed) height ease-in-out,
		$notchRotationSpeed filter ease-in-out;
	}
	&::before {
		width: calc(var(--wheel-size) * 0.3);
		height: calc(var(--wheel-size) * 0.3);
		border-width: calc(var(--wheel-size) * 0.01);
		border-color: $colorPrimary;
	}
	&::after {
		width: calc(var(--wheel-size) * var(--content-band-offset));
		height: calc(var(--wheel-size) * var(--content-band-offset));
		border-width: calc(var(--wheel-size) * 0.32);
		@include breakMin($breakMedium) {
			filter: drop-shadow(0 0 15px rgba(0, 0, 0, var(--shadow-opacity)));
		}
	}

	@include breakMin($breakMedium) {
		&:hover, &:focus-within {
			--content-band-offset: 0.385;
			--content-offset: 0.36;
			--offset-speed: #{$fastDelay};
		}
		&.isActiveSection {
			--content-band-offset: 0.415;
			--content-offset: 0.36;
			--offset-speed: #{$fastDelay};

			&::before {
				transform: translate(46%, 46%) scale(1.08);
			}
		}
	}
	&.tier-one,
	&.tier-two {
		&::before {
			display: none;
		}
	}
	&.tier-group {
		--tier-band-size: calc(var(--wheel-size) * 0.22);
		--opacity-offset: 0;
		--opacity-delay-override: 0s;
		&::after {
			width: var(--tier-band-size);
			height: var(--tier-band-size);
			border-width: calc(var(--wheel-size) * 0.05);
			filter: none;
		}
		&:not(.section-slice) {
			&:before {
				display: none;
			}
		}
		&:not(.active) {
			--additional-full-turn: -1;
		}
	}
	svg {
		--tier-text-container-size: calc(var(--wheel-size) * 0.28);
		position: absolute;
		right: calc(var(--tier-text-container-size) * -0.5);
		bottom: calc(var(--tier-text-container-size) * -0.5);
		width: var(--tier-text-container-size);
		height: var(--tier-text-container-size);
		transform: rotate(
				calc(180deg * calc(var(--slice-width) / var(--slices-count)))
		);
		z-index: 2;
		/* stylelint-disable */
		textPath {
			fill: white;
			font-size: 1px;
		}
		/* stylelint-enable */
	}
	&.tier-two svg {
		--tier-text-container-size: calc(var(--wheel-size) * 0.32);
	}
	&::marker {
		display: none;
	}
	&--clickable {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		color: transparent;
		background: none;
		border: none;
		z-index: 2;
		&:focus {
			outline: none;
		}
	}
	&--content-positioner {
		--content-distance-radius: calc(var(--wheel-size) * var(--content-offset));
		position: absolute;
		bottom: #{$slicesGap / 2};
		right: 0;
		z-index: 1;
		pointer-events: none;
		transform: translate(50%, 50%)
		rotate(calc(-90deg + calc(var(--slice-opening-angle) / 2)));
		transition: $notchRotationSpeed transform ease-in-out;
	}
	&--content-mover {
		transform: translateY(calc(0px - var(--content-distance-radius)));
		transition: var(--offset-speed) transform ease-in-out;
	}
	&--content {
		display: flex;
		flex-flow: column;
		align-items: center;
		transform: rotate(calc(0deg - var(--rotation)))
		rotate(calc(90deg - var(--slice-opening-angle) / 2));
		transition: var(--rotation-speed) transform ease-in-out;
	}
	&--icon {
		$innerSize: 47px;
		position: relative;
		width: 58px;
		height: 58px;
		margin-bottom: 15px;
		background: white;
		border-radius: 50%;
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&::before {
			content: '';
			display: block;
			width: $innerSize;
			height: $innerSize;
			border-radius: 50%;
			border: 1px solid $colorPrimary;
			transform: translate(-50%, -50%);
		}
		&::after {
			font-size: 30px;
			font-family: $iconFont;
			transform: translate(-50%, -50%);
			color: $colorPrimary;
			border-color: $colorPrimary;

			.color--innovating & {
				content: $icon-sustain-innovating;
			}
			.color--operating & {
				content: $icon-sustain-operating;
				padding-bottom: 2px;
			}
			.color--caring & {
				content: $icon-sustain-caring;
			}
			.color--protecting & {
				content: $icon-sustain-protecting;
			}
		}
	}
	&--title {
		width: calc(var(--wheel-size) * 0.22);
		font-size: 12px;
		font-weight: normal;
		color: var(--frontColor);
		text-transform: uppercase;
		text-align: center;
	}
}
