$shadowBlur: 15px;
$arrowHeight: 15px;
$arrowMargin: 20px;

$shadow: 0 0 #{$shadowBlur} rgba($darkGrey, 0.3);
.calendar-menu {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 20px;

	&--menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		min-width: 160px;
		bottom: 15px;
		right: 0;
		padding: 15px;
		margin-bottom: 25px;
		opacity: 0;
		background-color: white;
		pointer-events: none;
		transition: $mediumDelay opacity ease-in-out;
		z-index: 2;
		&.active {
			opacity: 1;
			pointer-events: all;
			box-shadow: $shadow;
		}
		[dir="rtl"] & {
			right: unset;
			left: 0;
		}
	}
	&--arrow {
		@include shadowedArrow($shadowBlur, $arrowHeight, $arrowMargin, bottom, right);
		z-index: 3;
		pointer-events: none;
		&::before {
			background: white;
			transition: $mediumDelay transform ease-in-out, $mediumDelay opacity ease-in-out,
				$mediumDelay box-shadow ease-in-out;
			opacity: 0;
		}
		.active &::before {
			transform: rotate(45deg);
			box-shadow: $shadow;
			opacity: 1;
		}
	}
	&--top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&--close-button {
		float: right;
		padding: 0;
		background: none;
		border: none;
		margin-top: 4px;
		font-size: 8px;
		color: $buttonGrey;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
	&--title {
		margin-bottom: 10px;
		margin-right: 10px;
		font-size: 14px;
	}

	&--item {
		padding-bottom: 10px;
		font-size: 11px;
		color: $grey;
		&::before {
			vertical-align: 20%;
			margin-right: 14px;
			content: $icon-play;
			font-family: $iconFont;
			font-size: 7px;
			color: $grey;
		}
		[dir="rtl"] & {
			&:before {
				transform: rotate(180deg);
				display: inline-block;
				margin-left: 14px;
			}
		}
		&:hover {
			color: $colorPrimary;
		}
	}

	&--button {
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		letter-spacing: 2px;
		border: 0;
		background: none;
		color: $grey;
		&::before {
			margin-right: 10px;
			content: $icon-plus;
			font-family: $iconFont;
			color: $colorPrimary;
			font-weight: normal;
		}
		&:focus {
			outline: none;
			color: $colorPrimary;
			&::before {
				color: $textBlack;
			}
		}
	}
}
