.show-more {
	p {
		line-height: 30px;
		font-size: 18px;
	}

	&--intro {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30px;
			opacity: 0;
			transition: $mediumDelay opacity ease-in-out;
			background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
		}
		&.gradient:after {
			opacity: 1;
		}
	}

	&--container {
		height: 0;
		overflow: hidden;
		transition: $mediumDelay height ease-in-out;
	}

	&--button {
		margin-top: 10px;
		display: flex;
		background: none;
		border: none;
		padding: 0;
		font-size: 12px;
		letter-spacing: 0.15em;
		opacity: 1;
		transition: $slowDelay opacity ease-in-out;

		&.hide {
			opacity: 0;
			cursor: default;
			transition: $mediumDelay opacity ease-in-out;
		}

		&:after {
			font-family: $iconFont;
			content: $icon-down;
			color: $colorPrimary;
			font-size: 5px;
			margin-left: 14px;
		}
		&:focus {
			outline: none;
			font-weight: bold;
			color: $textBlack;
		}

		&.open:after {
			transform: rotate(-180deg);
			transition: $mediumDelay;
		}
	}

	&--less-button {
		display: flex;
		margin-top: 10px;
		border: none;
		padding: 0;
		font-size: 12px;
		letter-spacing: 0.15em;
		background: none;
		visibility: hidden;
		opacity: 0;
		transition: $mediumDelay opacity ease-in-out;

		&.show {
			visibility: visible;
			opacity: 1;
		}

		&.hide {
			opacity: 0;
		}

		&:after {
			font-family: $iconFont;
			content: $icon-down;
			transform: rotate(-180deg);
			color: $colorPrimary;
			font-size: 5px;
			margin-left: 14px;
		}
	}
}
