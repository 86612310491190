.logo-disc {
	--size: 20vw;
	border-radius: 50%;
	min-width: var(--size);
	width: var(--size);
	height: var(--size);
	border: 3px solid white;
	overflow: hidden;
	box-shadow: $menuShadow;
	@include breakMin($breakXXtraSmall) {
		--size: 110px;
	}
	img {
		width: 100%;
	}
}
