.search-results {
	&--top {
		width: 100%;
		border-bottom: 1px solid $strokeGrey;
		padding-bottom: 5px;
		margin-bottom: 40px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	&--count {
		color: $grey;
		font-size: 18px;
	}
	&--orders {
		display: flex;
		transition: none;
		@include breakMax($breakMediumSmallBefore) {
			transform: translateX(-100vw);
			opacity: 0;
			z-index: 2000;
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: white;
			flex-flow: column;
		}
		&.active {
			opacity: 1;
			transform: translateX(0);
		}
		&.animated {
			transition: $slowDelay opacity ease-in, $slowDelay transform ease-in;
			&.active {
				transition: $slowDelay opacity ease-out, $slowDelay transform ease-out;
			}
		}
	}
	&--order {
		font-size: 14px;
		margin-left: 20px;
		color: $grey;
		&.active {
			color: $darkGrey;
			font-weight: 700;
		}
		&:focus {
			outline: none;
			color: $colorPrimary;
			font-weight: bold;
		}
		@include breakMax($breakMediumSmallBefore) {
			margin: 20px 30px 0;
			padding-bottom: 20px;
			border-bottom: 1px solid $strokeGrey;
			&.active {
				position: relative;
				&:after {
					content: $icon-vsign;
					font-family: $iconFont;
					font-size: 11px;
					color: $colorPrimary;
					position: absolute;
					right: 20px;
					top: 4px;
				}
			}
		}
	}
	&--sort-button {
		display: flex;
		align-items: center;
		font-size: 14px;
		letter-spacing: 0.15rem;
		text-transform: uppercase;
		color: $colorPrimary;
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;
		&::before {
			content: $icon-sort;
			font-family: $iconFont;
			font-size: 28px;
			margin-right: 10px;
		}
		@include breakMin($breakMediumSmall) {
			display: none;
		}
	}
}
.search-result {
	margin-bottom: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid $strokeGrey;
	display: flex;
	align-items: flex-start;
	&:last-of-type {
		border: none;
	}
	&--picture {
		position: relative;
		width: 40px;
		min-width: 40px;
		margin-right: 15px;
		@include breakMin($breakMediumSmall) {
			width: 215px;
			min-width: 215px;
			margin-right: 30px;
		}
		&:before {
			content: "";
			display: block;
			padding-bottom: 100%;
			@include breakMin($breakMediumSmall) {
				padding-bottom: 59%;
			}
		}
	}
	&--content {
		display: flex;
		flex-flow: column;
	}
	&--category,
	&--title {
		&:focus {
			outline: none;
			color: $colorPrimary;
			font-weight: bold;
		}
	}
	&--category {
		margin-bottom: 2px;
	}
	&--title {
		margin-bottom: 10px;
		color: $darkGrey;
	}
	&--description {
		margin-bottom: 10px;
	}
}
