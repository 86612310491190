.investor-highlight {
	max-width: 580px;
	margin: 0 auto;
	@include breakMin($breakLarge) {
		padding: 100px 45px 0 0;
	}
	.title3 {
		margin-bottom: 20px;
		font-weight: 400;
		display: block;
		@include breakMin($breakMediumSmall) {
			margin-bottom: 25px;
		}
	}

	&--illu {
		width: 60px;
		display: block;
		margin-bottom: 20px;
		@include breakMin($breakMediumSmall) {
			margin-bottom: 35px;
		}
	}

	&--infos {
		margin-bottom: 30px;
		font-size: 14px;
		line-height: 20px;
		max-width: 370px;
		@include breakMin($breakMediumSmall) {
			margin-bottom: 60px;
		}
	}

	&--results {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&--share {
		font-size: 20px;
		font-weight: normal;
		small {
			display: block;
			font-size: 12px;
		}
	}

	&--values {
		display: flex;
		flex-flow: column;
	}

	&--price {
		font-size: 40px;
		display: flex;
		align-items: flex-start;
		align-self: flex-end;
		color: $darkGrey;
		span {
			font-size: 14px;
		}
	}

	&--value {
		&.fail {
			color: $red;
			&:before {
				content: '\e80b';
				@include share-price-indicator;
				bottom: 2px;
				margin-right: 10px;
			}
		}

		&.succeed {
			color: $green;
			&:before {
				content: '\e80a';
				@include share-price-indicator;
				margin-right: 7px;
			}
		}
	}

	a {
		padding-top: 10px;
		display: block;
		border-top: 1px solid $colorPrimary;
		font-size: 12px;
		color: $colorPrimary;
		&::after {
			font-size: 11px;
		}
	}
}
