.small-image-above-title {
	--color-black: #{$rgbBlack};
	--color-grey: #{$rgbGrey};
	display: flex;
	align-items: flex-start;
	flex-flow: column;
	&:focus-within {
		outline: $blockFocusOutline;
	}
	&.latest-news {
		opacity: 0;
		transition: $mediumDelay opacity ease-in-out;
		&.active {
			opacity: 1;
		}
	}

	&--picture {
		position: relative;
		margin-bottom: 12px;
		width: 100%;
		height: auto;
		outline: none;
		.back-picture {
			outline: none;
		}
		.latest-news & {
			border-radius: 4px;
			overflow: hidden;
			margin-bottom: 25px;
			pointer-events: none;
			box-shadow: 0 0 0 black;
			transition: $mediumDelay box-shadow ease-in-out;
		}
		.latest-news.active & {
			box-shadow: 0 5px 30px rgba(black, 0.15);
			pointer-events: all;
		}

		&:before {
			content: '';
			width: 100%;
			padding-bottom: 59%;
			display: block;
		}
		img {
			transition: $mediumDelay transform ease-in-out;
			width: auto;
			height: auto;
		}
	}
	&:focus-within &--picture img {
		transform: scale(1.1);
	}
	&--content {
		// to prevent some weird unexplainable clipping
		position: relative;
		display: flex;
		flex-flow: column;
		padding-left: 10px;
		width: 100%;
	}
	&--date {
		color: $textGrey;
		font-size: 11px;
		margin-bottom: 10px;
	}
	&--category {
		color: $textPrimary;
		padding-bottom: 5px;
		&:focus {
			font-weight: bold;
			outline: none;
			color: $textColor;
		}
	}

	&--title {
		color: $textColor;
		text-align: left;
		background: transparent;
		border: none;
		padding: 0 0 7px 0;
		[dir="rtl"] & {
			text-align: right;
		}
		&:focus {
			color: $textPrimary;
			outline: none;
			transition: none;
		}
	}

	&--description {
		font-size: 14px;
	}

	&--video-description {
		color: $textColor;
		margin: 4px 0 15px;
		cursor: pointer;
	}
	&--cta {
		padding-top: 10px;
		color: $textGrey;
		&:focus {
			color: $colorPrimary;
			font-weight: bold;
			outline: none;
		}
	}

	.small-style {
		color: $textGrey;
	}

	.arrowed-link {
		--spacing: 25px;
	}

	&.mobile-style {
		@include breakMax($breakSmallBefore) {
			flex-flow: row;

			.small-image-above-title {
				&--picture {
					width: 100px;
					min-width: 100px;
					margin-bottom: 0;

					&:before {
						padding-bottom: 100%;
					}
				}
				&--content {
					padding-left: 25px;
					[dir="rtl"] & {
						padding-right: 25px;
					}
				}

			}
		}
	}
}

@keyframes video-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
