.conditions-of-use {
	position: relative;
	margin: 0 auto;
	padding-bottom: 60px;
}

.menu-partial {
	margin-top: 60px;
	@include breakMin($breakMedium) {
		display: flex;
	}

	.left-menu {
		&--container {
			@include breakMin($breakMedium) {
				width: 30%;
				position: relative;
				max-width: 350px;
			}
		}

		&--items {
			display: none;

			@include breakMin($breakMedium) {
				display: block;
				width: 100%;
				max-width: 350px;
			}
			li {
				display: flex;
			}
		}
		&--link {
			font-size: 16px;
			margin-bottom: 20px;
			line-height: 20px;
			font-weight: 200;
			color: $grey;
			padding-left: 80px;
			position: relative;
			transition: $mediumDelay color ease-in-out;

			&:hover {
				color: $colorPrimaryHover;
				text-decoration: none;
			}

			&::before,
			&::after {
				content: "";
				position: absolute;
				right: calc(100% - 80px);
				top: 50%;
				display: block;
				height: 1px;
				background-color: $colorPrimary;
				width: 0;
				margin-right: 15px;
				transition: $slowDelay width ease-in-out;
			}
			&::after {
				background-color: $medlightGrey;
				transition: $fastDelay width ease-in-out;
			}
			&::before {
				z-index: 1;
			}
			&:focus {
				outline: none;
				color: $colorPrimary;
				font-weight: normal;
				&::after {
					width: 65px;
					background: $textBlack;
				}
			}
			&.current {
				color: $colorPrimary;
				&::before,
				&::after {
					width: 65px;
				}
			}
		}
	}

	.right-section {
		@include breakMin($breakMedium) {
			flex-basis: 70%;
			padding-left: 150px;
		}

		&--heading {
			font-size: 20px;
			padding-bottom: 18px;
		}

		&--detail {
			font-size: 18px;
			line-height: 30px;
			font-weight: 300;
			margin-bottom: 50px;
		}
	}
}
