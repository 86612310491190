@import "../../../../../node_modules/tiny-slider/src/tiny-slider.scss";

.quarterly-reports {
	&--title {
		margin-bottom: 40px;
		font-size: 30px;
		font-weight: normal;
	}
	&--year {
		margin-bottom: 10px;
		font-size: 34px;
		font-weight: lighter;
		text-align: right;
	}
	&--cards {
		user-select: none;
	}
	&--tabs {
		display: flex;
		justify-content: space-between;
	}
	&--tab {
		width: 100%;
		text-align: center;
		font-size: 11px;
		transition: background-color $fastDelay;
		&.open {
			background-color: $lightGrey;
			.quarterly-reports--tab-link {
				color: $textBlack;
			}
		}
		&.close {
			pointer-events: none;
			.quarterly-reports--tab-link {
				color: $grey;
			}
		}
	}
	&--tab-link {
		display: block;
		padding: 18px 34px;
		border: 1px solid transparent;
		border-width: 1px 1px 0 1px;
		&:focus {
			outline: none;
			border-color: $colorPrimary;
			font-weight: bold;
		}
	}
	&--documents {
		display: none;
		padding: 45px 60px 25px 30px;
		background-color: $lightGrey;
		&.open {
			display: block;
		}
	}
	&--controls {
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		outline: none;
		.next,
		.previous {
			color: $colorPrimary;
			background: none;
			border: none;
			&:disabled {
				color: $grey;
				pointer-events: none;
			}
			display: none;
			@include breakMin($breakLarge) {
				display: block;
			}
		}
		.next {
			&:before {
				margin-right: 10px;
				content: $icon-longarrowleft;
				font-family: $iconFont;
			}
		}
		.previous {
			&:after {
				margin-left: 10px;
				content: $icon-longarrowright;
				font-family: $iconFont;
			}
		}
	}
	.document-links {
		display: flex;
		padding-bottom: 20px;
		color: $textBlack;
		&:before {
			margin-right: 10px;
			content: $icon-pdffile;
			font-family: $iconFont;
			font-size: 20px;
		}
		&:focus {
			color: $colorPrimary;
			outline: none;
		}
	}
	.tns-nav {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include breakMin($breakLarge) {
			position: absolute;
			left: 50%;
			margin-left: -50px;
		}
		button {
			border: none;
			margin-right: 10px;
			padding: 5px;
			width: 7px;
			height: 7px;
			background-color: $lightGrey;
			@include breakMin($breakLarge) {
				padding: 4px;
			}
			&.tns-nav-active {
				padding: 6px;
				width: 10px;
				height: 10px;
				background-color: $colorPrimary;
				@include breakMin($breakLarge) {
					padding: 5px;
				}
			}
		}
	}
}
