.pop-up-widget {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2002;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	&.hidden {
		display: none;
	}
	@include breakMin($breakMedium) {
		align-items: center;
	}
	&.no-display {
		display: none;
	}
	&.closing {
		cursor: wait;
		* {
			pointer-events: none;
		}
	}

	&--background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(black, 0.77);
		transition: $mediumDelay background ease-in-out;
	}
	&--content {
		display: flex;
		flex-direction: column;
		position: relative;
		background-color: #fff;
		max-width: 800px;
		@include breakMax($breakMedium) {
			width: 100vw;
		}
	}
	&--close {
		margin-left: auto;
	}
	&--top {
		position: absolute;
		left: 0;
		right: 0;
		top: -45px;
	}
	&--slot {
		padding-top: 30px;
		text-align: center;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 110px);
		border-top: 13px solid $colorPrimary;
		border-bottom: 13px solid $colorPrimary;
		@include breakMax($breakMedium) {
			height: calc(100vh - 45px);
		}
		scrollbar-color: $colorPrimary $lightGrey;
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			background: $lightGrey;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $colorPrimary;
		}
		&::-webkit-scrollbar-track {
			background-color: $lightGrey;
		}
	}

	&--subtitle {
		padding: 0 10px;
		@include breakMin($breakMedium) {
			padding: 0 100px;
		}
	}
	&--title {
		padding: 0 10px;
		margin: 20px 0;
		@include breakMin($breakMedium) {
			padding: 0 100px;
		}
	}
	&--image img {
		width: auto;
		height: auto;
	}
}

.investor-cta-newsletter {
	position: relative;
	border: none !important;
	.investor-cta {
		flex-direction: column !important;
		align-items: flex-start;
		position: relative;
		z-index: 1;
		margin: 16px auto 8px 0;
		&--newsletter-button {
			margin-top: 16px !important;
			color: RGB(var(--color-primary)) !important;
			@include breakMax($breakMediumSmall) {
				margin: 8px auto 100px 0 !important;
			}
		}
	}
}
