$desktopThreshold: $breakMediumSmall;
$desktopThresholdBefore: $desktopThreshold - 1px;
$bar-radius: 4px;
.audio-widget {
	--progress: 0;
	display: flex;
	flex-flow: column;
	max-width: 685px;
	margin: 0 auto;
	box-shadow: 0 -5px 30px rgba(black, 0.16);
	* {
		color: white;
		/*  stylelint-disable selector-pseudo-class-no-unknown */
		&:where(button) {
			/*  stylelint-enable selector-pseudo-class-no-unknown */
			border: none;
			background: none;
			padding: 0;
		}
	}

	// unfoldable part

	&--unfoldable {
		// 48px is note height plus padding bottom
		margin-bottom: -#{$bar-radius + 48px};
		border-radius: $bar-radius $bar-radius 0 0;
		background: $colorPrimary;
		overflow: hidden;
		transition: $slowerDelay height ease-in-out,
			$slowerDelay margin ease-in-out;
		.activated & {
			margin-bottom: -#{$bar-radius};
		}
		@include breakMin($desktopThreshold) {
			height: 0;
			margin-bottom: -#{$bar-radius};
		}
		&-content {
			padding: 20px 20px 35px;
			@include breakMin($desktopThreshold) {
				padding: 20px 30px 45px;
			}
		}
		&-top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
		}
		&-title {
			max-width: 66.67%;
			font-size: 18px;
			@include breakMin($desktopThreshold) {
				font-size: 22px;
			}
		}
	}
	&--note {
		display: flex;
		font-size: 11px;
		@include breakMin($desktopThreshold) {
			opacity: 0;
			.activated & {
				opacity: 1;
			}
		}
	}
	&--type {
		text-transform: uppercase;
		&::after {
			content: '';
			display: inline-block;
			width: 3px;
			height: 3px;
			background: white;
			border-radius: 50%;
			margin: 0 4px 2px;
		}
	}
	&--title {
		font-size: 18px;
		.activated & {
			opacity: 0;
			transform: translateX(-100%);
		}
		transition: $slowerDelay opacity ease-in-out,
			$slowerDelay transform ease-in-out;
		&-container {
			display: none;
			@include breakMin($desktopThreshold) {
				display: block;
			}
			max-width: 45%;
			.activated & {
				pointer-events: none;
			}
			transition: $slowerDelay width ease-in-out;
		}
	}
	&--download {
		opacity: 0;
		transition: $slowerDelay opacity ease-in-out;
		margin-left: 40px;
		.activated & {
			opacity: 1;
		}
		@include breakMin($desktopThreshold) {
			opacity: 1;
		}
		&::before {
			content: $icon-download;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			font-size: 14px;
			font-family: $iconFont;
			border: 2px solid white;
			transition: $mediumDelay color ease-in-out,
				$mediumDelay background ease-in-out;
		}
		&:hover::before {
			background: white;
			color: $colorPrimary;
		}
	}

	// main bar part

	&--bar {
		position: relative;
		display: flex;
		align-items: center;
		height: 80px;
		padding: 20px 20px 35px;
		border-radius: $bar-radius;
		background: $colorPrimary;
		z-index: 1;
		transition: $slowerDelay border-radius ease-in-out,
			$slowerDelay border ease-in-out;
		.activated & {
			border-radius: 0 0 $bar-radius $bar-radius;
			border-top: 1px solid rgba(white, 0.2);
		}
	}
	&--play {
		--delay: #{$fastDelay};
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 40px;
		width: 40px;
		height: 40px;
		margin-right: 10px;
		border: 2px solid white;
		border-radius: 50%;
		background: white;
		transition: var(--delay) background ease-in-out;
		&::before,
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			font-family: $iconFont;
			font-size: 14px;
			transition: var(--delay) opacity ease-in-out,
				var(--delay) color ease-in-out;
		}
		&::before {
			content: $icon-play;
			color: $colorPrimary;
			transform: translateX(2px);
			opacity: 1;
		}
		&::after {
			content: $icon-pause;
			opacity: 0;
		}
		&:hover {
			background: $colorPrimary;
			&::before {
				color: white;
			}
			&::after {
				color: $colorPrimary;
			}
		}
		.is-playing & {
			--delay: #{$mediumDelay};
			background: transparent;
			&:hover {
				background: white;
			}
			&::before {
				opacity: 0;
			}
			&::after {
				opacity: 1;
			}
		}
	}
	&--progress {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-grow: 1;
		padding-left: 15px;
		@include breakMin($desktopThreshold) {
			flex-wrap: none;
			margin-left: 10px;
		}
		.control-bar {
			flex-grow: 1;
			width: 100%;
			@include breakMin($desktopThreshold) {
				width: auto;
			}
			--button-scale: 0;
		}
		&:hover .control-bar {
			--button-scale: 1;
		}
	}
	&--current,
	&--total {
		font-size: 11px;
	}
	&--current {
		margin-right: 10px;
		@include breakMax($desktopThresholdBefore) {
			order: 1;
		}
	}
	&--total {
		margin-left: 10px;
		@include breakMax($desktopThresholdBefore) {
			order: 2;
		}
	}

	&--volume {
		display: flex;

		&-bar {
			flex-grow: 1;
		}
		&-container {
			display: none;
			@include breakMin($desktopThreshold) {
				display: block;
			}
			width: 0;
			overflow: hidden;
			transition: $slowerDelay width ease-in-out,
				$slowerDelay margin ease-in-out,
				$slowerDelay padding ease-in-out;
			.activated & {
				width: 80px;
				margin-right: -15px;
				padding-right: 15px;
				@include breakMin($breakMediumBefore) {
					width: 150px;
				}
			}
		}
		&-button {
			background-color: transparent;
			border: none;
			position: relative;
			width: 20px;
			margin-right: 10px;
			text-align: left;
			&-icon {
				&::before {
					content: $icon-speaker;
					font-family: $iconFont;
					font-size: 18px;
				}
			}
			&::before,
			&::after {
				position: absolute;
				left: 16px;
				top: 50%;
				font-family: $iconFont;
				font-size: 16px;
				transform: translateY(-50%);
			}
			&::before {
				content: $icon-vol-min;
				opacity: calc(var(--progress) * 2);
			}
			&::after {
				content: $icon-vol-max;
				opacity: calc(var(--progress) * 2 - 1);
			}
			.is-muted & {
				&::before {
					content: $icon-mini-cross;
					font-size: 6px;
					opacity: 1;
				}
				&::after {
					opacity: 0;
				}
			}
		}
		&.is-muted .control-bar {
			--button-scale: 0;
		}
	}
}
