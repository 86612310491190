.main-nav {
	&--left {
		flex-wrap: nowrap;
		align-items: center;
		display: flex;
	}

	&--copy {
		text-transform: uppercase;
		padding: 10px 10px 10px 0;
		font-size: 16px;
		color: $colorPrimary;

		@include breakMin($breakNavigation) {
			margin-top: 3px;
		}
	}

	&--logo {
		margin-right: 28px;
		min-width: max-content;
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 28px;
		}
		img {
			height: 36px;
			width: auto;
			transition: $mediumDelay transform ease-in-out;

			@include breakMin($breakNavigation) {
				height: auto;
				width: auto;
				max-height: 60px;
			}
		}
		&:focus {
			outline: none;
			img {
				transform: scale(1.1);
				transition: $fastDelay transform ease-in-out;
			}
		}

		.desktop {
			display: none;
		}

		@include breakMin($breakNavigation) {
			.desktop {
				display: block;
			}
		}

		@include breakMin($breakNavigation) {
			.mobile {
				display: none;
			}
		}
	}

	&--note {
		font-size: 12px;
		color: $grey;
		font-weight: normal;
	}

	&--center {
		flex-flow: column;
		display: none;

		@include breakMin($breakNavigation) {
			display: flex;
		}
	}

	&--nav {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
	}

	&--element {
		list-style-type: none;
		width: 33%;

		@include breakMin($breakNavigation) {
			margin-right: 20px;
			width: auto;
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 20px;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	&--link,
	&--current {
		font-size: 12px;
		letter-spacing: 0.04em;
		color: $textBlack;
		text-transform: uppercase;
		transition: $fast;
		&:focus {
			color: $colorPrimary;
			font-weight: bold;
			outline: none;
		}
	}

	&--link {
		&:hover {
			color: $colorPrimaryHover;
			text-decoration: none;
		}
	}

	&--current {
		font-weight: 700;
		.color--sampoernaRed & {
			color: $colorPrimary;
		}
	}

	&--right {
		display: flex;
		align-items: flex-end;
		flex-wrap: nowrap;
		min-width: max-content;
		margin-left: auto;

		[dir='rtl'] & {
			margin-left: 0;
			margin-right: auto;
		}
	}
}
