.dropdown-menu-widget {
	&--left {
		flex-wrap: nowrap;
		align-items: center;
		display: flex;
	}

	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10000;
	}

	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}

	&--close {
		position: absolute;
		right: 18px;
		top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;

		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}

	&--copy {
		text-transform: uppercase;
		padding: 10px 10px 10px 0;
		font-size: 16px;
		color: $colorPrimary;

		@include breakMin($breakNavigation) {
			margin-top: 3px;
		}
	}

	&--logo {
		width: auto;
		height: auto;
		margin-right: 28px;
		min-width: max-content;

		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 28px;
		}

		img {
			height: 36px;
			transition: $mediumDelay transform ease-in-out;

			@include breakMin($breakNavigation) {
				height: auto;
				z-index: 2001;
				max-height: 60px;
			}
		}

		&:focus {
			outline: none;

			img {
				transform: scale(1.1);
				transition: $fastDelay transform ease-in-out;
			}
		}

		.desktop {
			display: none;
		}

		@include breakMin($breakNavigation) {
			.desktop {
				display: block;
			}
		}

		@include breakMin($breakNavigation) {
			.mobile {
				display: none;
			}
		}
	}

	&--note {
		font-size: 12px;
		color: $grey;
		font-weight: normal;

		@include breakMin($breakNavigation) {
			min-height: 15px;
		}
	}

	&--center {
		flex-flow: column;
		display: none;
		position: relative;

		@include breakMin($breakNavigation) {
			display: flex;
		}
	}

	&--nav {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
	}

	&--element {
		list-style-type: none;
		width: 33%;

		@include breakMin($breakNavigation) {
			margin-right: 20px;
			width: auto;

			[dir='rtl'] & {
				margin-right: 0;
				margin-left: 20px;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	&--link,
	&--current {
		font-size: 12px;
		letter-spacing: 0.04em;
		color: $textBlack;
		text-transform: uppercase;
		transition: $fast;
		border: none;
		background-color: transparent;

		&:focus {
			outline: none;
		}

		&:hover {
			cursor: default;
		}
	}

	&--link {
		@include breakMin($breakNavigation) {
			transition: $mediumSpeed;
			padding-left: 0;
			position: relative;

			&:after {
				content: ' ';
				display: block;
				width: 0;
				transition: $fast;
				border-bottom: 4px solid $colorPrimary;
				height: 100%;
				position: absolute;
				left: 0;
				top: 18px;
				right: 0;
				cursor: pointer;
			}

			&:hover,
			&.active,
			&.current {
				color: $colorPrimaryHover;
				cursor: pointer;

				&:after {
					cursor: default;
					width: 100%;
				}
			}
		}
	}

	&--button {
		cursor: default;

		&:hover,
		&.active {
			color: $textBlack;
			cursor: default;
		}
	}

	&--current {
		.color--sampoernaRed & {
			color: $colorPrimary;
		}
	}

	&--right {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		min-width: max-content;
		margin-left: auto;
		align-self: stretch;

		[dir='rtl'] & {
			margin-left: 0;
			margin-right: auto;
		}

		.search {
			@include breakMin($breakNavigation) {
				margin-top: 15px;
			}
		}

		.market-search-menu .search-field-drop--field {
			border-left: none;

			input {
				border-bottom: 1px solid #8c8c8c;

				@include breakMin($breakNavigation) {
					padding-bottom: 10px;
					height: 25px;
				}
			}
		}
	}

	&--submenu {
		opacity: 0;
		visibility: hidden;
		background: $lightGrey;
		position: absolute;
		top: calc(100% + 20px);
		width: 100vw;
		left: -2px;
		padding-left: 2px;
		right: 0;
		height: 0;
		z-index: -1;
		transition: $mediumSpeed;

		&:before {
			content: ' ';
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			height: 35px;
			top: -20px;
		}

		&::after {
			height: 100%;
			width: 100vw;
			top: 0;
			left: 0;
			background-color: $lightGrey;
			content: '';
			position: absolute;
			transform: translate(-100%,0);
		}

		&.active {
			opacity: 1;
			visibility: visible;
			z-index: 2001;

			.dropdown-menu-widget--submenu-link {
				opacity: 1;
				visibility: visible;
				z-index: 2001;
			}
		}
	}

	&--submenu-container {
		display: flex;
		gap: 80px;
		margin-top: 30px;

		@include breakBetween($breakNavigation, $breakXtraLarge) {
			max-width: 960px;
		}
	}

	&--submenu-link {
		display: inline-block;
		color: #000000;
		font-weight: bold;
		margin-bottom: 30px;
		font-size: 12px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		transition: $mediumSpeed;
		opacity: 0;
		visibility: hidden;

		&::after {
			transition: $mediumSpeed;
			color: transparent;
			content: $icon-tailarrowright;
			font-family: $iconFont;
			margin-left: 5px;
			font-size: 9px;
		}

		&:hover,
		&:focus {
			color: $colorPrimary;

			&:after {
				color: $colorPrimary;
			}
		}
	}

	&--submenu-list {
		display: flex;
		flex-flow: column;
	}

	&--world-dropdown {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		height: 0;
		background-color: $lightGrey;
		transition: $mediumSpeed;

		&.active {
			opacity: 1;
			visibility: visible;
			z-index: $z-nav;

			.country-info--background-img {
				opacity: 1;
				visibility: visible;
				transition: $evenSlowerDelay all ease-in-out;
			}
		}
	}
}

.dropdown-menu-widget--right .mobile-nav {
	&--menu {
		padding: 0;
	}

	&--panel-title,
	&--biglink,
	&--link {
		background: transparent;
		border: none;
		font-weight: normal;
	}

	&--link-button {
		position: relative;
		width: 100px;
		float: right;
	}

	&--panel-title {
		position: relative;
		color: $colorPrimary;
		border-bottom: 3px solid $colorPrimary;
		width: fit-content;
		font-weight: 700;
		padding-bottom: 15px;
		margin: 0;

		&:before {
			content: none;
		}
	}

	&--panel:not(.mobile-nav--panel[data-name='markets']) {
		height: 100vh;
	}

	&--panel:not(.mobile-nav--submenu-panel) {
		padding: 30px 30px 80px 30px;
	}

	&--link {
		position: relative;
		width: 100%;
		font-weight: normal;

		&.is-next {
			cursor: pointer;

			&:after {
				right: 0;
				position: absolute;
			}
		}

		&.is-back {
			transition: $mediumSpeed;
			width: fit-content;

			&.hidden {
				opacity: 0;
				visibility: hidden;
			}

			font-size: 16px;
			text-transform: none;
			margin-bottom: 25px;
			cursor: pointer;
			margin-left: 20px;
			top: 15px;

			&:before {
				content: $icon-arrowleft;
				margin-right: 12px;
				position: relative;
			}
		}
	}

	&--panels {
		margin-top: 10px;
		border-bottom: 1px solid $grey93;
	}

	&--panel {
		height: calc(100vh - 40px);

		.market-search-menu {
			&--all-market {
				display: none;

				@include breakMin($breakNavigation) {
					margin-left: -$pagePadding;
					background: $colorPrimary;
					height: 50px;
					width: calc(100% + #{2 * $pagePadding});
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					color: white;
					text-transform: uppercase;

					[dir='rtl'] & {
						margin-right: -$pagePadding;
						margin-left: 0;
					}
				}
			}

			.search-field-drop--suggestions {
				height: calc(100vh - 200px);
			}
		}
	}

	&--submenu-list {
		height: 100vh;
		background-color: $lightGrey;
		padding: 30px 0 50px 30px;
		overflow-y: auto;
	}

	&--submenu-panel-title {
		color: $colorPrimary;
		border-bottom: 3px solid $colorPrimary;
		width: fit-content;
		margin-left: 30px;
		padding: 15px 0 15px 0;
		font-weight: 700;
	}

	&--link-markets {
		display: flex;
		align-items: center;

		&:before {
			content: url('/assets-v3/images/world.svg');
			font-size: 25px;
			margin-right: 15px;
		}

		&:after {
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.has-sub {
	.dropdown-menu-widget--submenu {
		top: calc(100% + 22px);
	}
}
