.marketpage-news-detail-articles-vertical {
	.related-articles-partial {
		&--title {
			padding-left: 10px;
			margin-bottom: 18px;
		}
		&--content {
			@include breakMin($breakSmall) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
	@include breakMin($breakSmall) {
		display: flex;
	}
}
