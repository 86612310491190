$slicesGap: 7px;
$notchRotationSpeed: $mediumDelay;
$sliceActivationSpeed: 0s;

.interactive-wheel {
	--rotation-speed-factor: 3;
	--additional-full-turn: 0;
	--container-margin: 0px;
	--wheel-size: 450px;
	--button-size: calc(var(--wheel-size) * 0.2 - 5px);
	background: $lightGrey;
	padding: 30px $pagePadding 50px;
	margin: 0 #{-$pagePadding};
	border-top: 10px solid $colorPrimary;
	&--row {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		margin: 0 auto;
		@include breakMin($breakMedium) {
			--container-margin: 40px;
			flex-flow: row;
			&.no-breadcrumbs {
				margin-top: 100px;
			}
		}
	}
	&--left {
		display: flex;
		flex-flow: column;
		align-items: center;
		flex-grow: 1;
		margin-bottom: 60px;
		@include breakMin($breakSmall) {
			width: 80%;
			align-items: flex-start;
		}
		@include breakMin($breakMedium) {
			max-width: 500px;
			margin-bottom: 0;
		}
	}
	&--title,
	&--description {
		text-align: center;
		@include breakMin($breakSmall) {
			text-align: left;
		}
	}
	&--title {
		font-size: 48px;
		margin-bottom: 25px;
		color: $darkGrey;
	}

	&--description {
		font-size: 20px;
		line-height: 26px;
		color: $darkGrey;
	}
	&--right {
		position: relative;
		height: var(--wheel-size);
		width: 100%;
	}
	&--wheel {
		position: absolute;
		height: var(--wheel-size);
		width: var(--wheel-size);
		margin-left: auto;

		@include breakMin($breakMedium) {
			position: relative;
			align-self: center;
			transform: none;
		}
		&-set {
			position: absolute;
			right: 0;
			&::before {
				$height: 15px;
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: calc(var(--wheel-size) * 0.66);
				height: $height;
				background: $medlightGrey;
				border-radius: 50%;
			}
			@include breakMax($breakSmallBefore) {
				height: var(--wheel-size);
				transform: translateX(
					calc(
						var(--wheel-size) / 2 - var(--button-size) / 2 +
							var(--container-margin)
					)
				);
			}
			@include breakMax($breakMediumBefore) {
				&:before {
					bottom: -#{$height + 15px};
				}
			}
			@include breakMin($breakSmall) {
				right: 50%;
				transform: translateX(50%);
			}
			@include breakMin($breakMediumBefore) {
				top: -100px;
			}
		}
		&-container {
			position: relative;
			margin: calc(0 - var(--container-margin));
			margin-left: auto;
			padding: var(--container-margin);
			min-width: calc(var(--wheel-size) + 2 * var(--container-margin));
			min-height: calc(var(--wheel-size) + 2 * var(--container-margin));
			overflow: hidden;
			border-radius: 50%;
			// just making center of the wheel unclickable
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: var(--button-size);
				height: var(--button-size);
				background: none;
				border-radius: 50%;
				z-index: 2;
			}
		}
	}
	&--touch {
		position: absolute;
		top: 50%;
		left: -50vw;
		width: 100vw;
		height: 100%;
		max-height: 50vh;
		transform: translateY(-50%);
	}

	&--rotation {
		position: absolute;
		top: 50%;
		left: #{-20px - 35px};
		transform: translateY(-50%);
		height: calc(var(--wheel-size) * 0.25);
		@include breakMin($breakSmall) {
			left: #{-50px - 35px};
		}
		&-back {
			fill: none;
			stroke: #ffffff;
			stroke-width: 30;
			stroke-linecap: round;
		}
		&-arrow {
			fill: none;
			stroke: $textBlack;
			stroke-width: 1.3;
			stroke-miterlimit: 4;
			transform: translateY(0);
			transition: $slowerDelay transform ease-in-out;
			&-top.active {
				transform: translateY(-5px);
			}
			&-bottom.active {
				transform: translateY(5px);
			}
		}
		@include breakMin($breakMedium) {
			display: none;
		}
	}
	&--back-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: var(--button-size);
		height: var(--button-size);
		font-size: 0;
		background: none;
		border: 2px solid transparent;
		padding: 0;
		border-radius: 50%;
		overflow: hidden;
		z-index: 3;
		&::before,
		&::after {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: $slowDelay transform ease-in-out,
				$slowDelay color ease-in-out, $slowDelay background ease-in-out,
				$slowDelay border-color ease-in-out;
		}
		&:focus {
			&::before,
			&::after {
				transition: $fastDelay transform ease-in-out,
					$fastDelay color ease-in-out,
					$fastDelay background ease-in-out,
					$fastDelay border-color ease-in-out;
			}
		}
		&::before {
			$innerSize: 55px;
			content: '';
			width: $innerSize;
			height: $innerSize;
			border: 1px solid $colorPrimary;
			font-size: 35px;
			font-family: $iconFont;
			border-radius: 50%;
			top: calc(50% - 5px);
			transform: translate(-50%, -50%);
			color: $colorPrimary;
		}
		&:focus::before {
			transform: translate(-50%, -60%);
		}
		&.color--innovating::before {
			content: $icon-sustain-innovating;
		}
		&.color--operating::before {
			content: $icon-sustain-operating;
			padding-bottom: 1px;
		}
		&.color--caring::before {
			content: $icon-sustain-caring;
		}
		&.color--protecting::before {
			content: $icon-sustain-protecting;
		}
		&::after {
			content: $icon-back-arrow;

			width: 32px;
			height: 32px;
			font-family: $iconFont;
			font-size: 12px;
			color: white;
			background: $colorPrimary;
			border: 3px solid white;
			border-radius: 50%;
			top: calc(50% + 25px);
		}
		&:focus::after {
			transform: translate(-50%, -40%);
		}
		&:disabled {
			pointer-events: none;
			outline: none;
			&::before {
				transform: translate(-50%, -200%);
			}
			&::after {
				transform: translate(-50%, 100%);
			}
		}
		&:focus:not(:disabled) {
			outline: none;
		}
	}
}
