.tbe-drop {
	position: relative;
	&--field {
		position: relative;
		padding: 0 0 10px;
		border: none;
		border-bottom: 1px solid $colorPrimary;
		width: 100%;
		background: none;
		text-align: left;
		min-height: 33px;
		&.has-arrow {
			&:after {
				content: $icon-droparrow;
				font-family: $iconFont;
				font-size: 6px;
				color: $colorPrimary;
				pointer-events: none;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%) rotate(180deg);
				transition: $fastDelay transform ease-in-out;
			}
			&.open:after {
				transform: translateY(-50%);
			}
		}
	}
	&--title {
		color: $textBlack;
		font-size: 18px;
		padding-right: 25px;
		.smaller & {
			font-size: 14px;
		}
	}
	&--input,
	&--back-suggestion {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0;
		font-size: 16px;
	}
	&--input {
		z-index: 1;
		border: none;
		background: none;
		&::placeholder {
			font-size: 16px;
			color: $mediumGrey;
		}
	}
	&--back-suggestion {
		display: flex;
		align-items: center;
		opacity: 0.3;
		span {
			opacity: 0;
		}
	}
	&--container {
		position: absolute;
		z-index: 2;
		top: calc(100% - 1px);
		left: 0;
		width: 100%;
		overflow: hidden;
		transition: $fastDelay height ease-in-out, $fastDelay background ease-in-out,
			$fastDelay box-shadow ease-in-out;
		height: 0;
		&.open {
			background: white;
			box-shadow: $menuShadow;
		}
	}
	&--choice {
		border: none;
		background: none;
		font-size: 16px;
		letter-spacing: 0.025em;
		padding: 10px $pagePadding;
		text-align: left;
		width: 100%;
		cursor: pointer;
		&:hover,
		&:focus {
			color: $colorPrimary;
			background: $lightGrey;
			outline: none;
		}
	}
}
