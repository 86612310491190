.blue-circles {
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding-bottom: 40px;
	&--title {
		font-size: 30px;
		line-height: 40px;
		font-weight: normal;
		text-align: center;
		margin-bottom: 45px;
	}
	.social-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@supports (-webkit-touch-callout: none) {
			.shorthand-embed-body & {
				position: relative;
				z-index: $z-social-ios-fix;
			}
		}
		&--element {
			& button,
			& a {
				width: 55px;
				height: 55px;
				background: $colorPrimary;
				border-radius: 50%;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0;
				color: transparent;
				transition: $mediumDelay background ease-in-out;
				&:before {
					font-family: $iconFont;
					color: white;
					font-size: 16px;
				}
				&:hover {
					background: $colorPrimaryHover;
				}
				&.facebook:before {
					font-size: 20px;
					content: $icon-facebook;
				}
				&.twitter {
					&:before {
						content: '';
					}
					svg {
						fill: white;
					}
				}
				&.linkedin:before {
					font-size: 18px;
					content: $icon-linkedin;
				}
				&.vk:before {
					font-size: 14px;
					content: $icon-vk;
				}
				&.instagram:before {
					font-size: 22px;
					content: $icon-instagram;
				}
				&.mail:before {
					content: $icon-mailFilled;
				}
			}
		}
		&.not-working .social-list {
			justify-content: center;
			&-element {
				display: none;
			}
		}
	}
}
