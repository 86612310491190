.age-gate-partial {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 1001;
	left: 0;
	right: 0;
	background-color: $colorPrimary;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 20px;
	overflow-y: auto;
	height: 100%;
	@include breakMin($breakMediumSmall) {
		align-items: center;
		padding-top: 0;
	}
	&--container-simple {
		@include breakMax($breakMediumSmall) {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	img {
		width: auto;
		height: auto;
		@include breakMax($breakMediumSmall) {
			height: 80px;
			width: auto;
		}
	}
	&--title,
	&--title-error {
		color: #fff;
		max-width: 1000px;
		margin: 20px auto 0;
		font-size: 36px;
		line-height: 40px;
		font-weight: bold;
		@include breakMax($breakMediumSmall) {
			font-size: 24px;
			margin-top: 10px;
		}
	}
	&--description,
	&--description-error,
	&--disclaimer-confirmation {
		color: #fff;
		max-width: 1000px;
		margin: 20px auto 0;
		@include breakMax($breakMediumSmall) {
			margin-top: 10px;
		}
	}
	&--disclaimer-container {
		margin: 20px auto;
		line-height: 24px;
		color: $grey;
		text-align: left;
		padding: 20px;
		background-color: #fff;
		overflow-y: scroll;
		height: 200px;
		width: 100%;
		@include breakMin($breakMediumSmall) {
			width: 700px;
		}
		&::-webkit-scrollbar-track {
			background-color: #fff;
			border-left: 1px solid #e8e8e8;
		}
		&::-webkit-scrollbar {
			width: 8px;
			height: 6px;
			background: #fff;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: #c4c4c4;
		}
	}
	&--container,
	&--error {
		text-align: center;
		width: 100%;
		padding: 0 10px;
		max-height: 100%;
		align-self: center;
		@include breakMin($breakMediumSmall) {
			padding: 0 30px;
		}
		.still-picture {
			width: auto;
			height: auto;
		}
	}
	&--buttons {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		@include breakMin($breakMediumSmall) {
			margin-top: 20px;
		}
	}
	&--link {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		border: 1px solid #fff;
		background-color: transparent;
		width: 120px;
		text-transform: uppercase;
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.15em;
		color: #fff;
		font-weight: bold;
		@include breakMin($breakMediumSmall) {
			width: 200px;
			height: 60px;
		}
		&:hover {
			cursor: pointer;
		}
	}
	&--link-decline-disclaimer {
		background-color: transparent;
		color: #fff;
	}
	&--link-accept-disclaimer {
		margin-right: 20px;
		background-color: #fff;
		color: $colorPrimary;
	}
	&--link-accept-simple,
	&--link-decline-simple {
		display: inline-block;
		width: auto;
		margin-top: 20px;
		margin-right: 20px;
		color: #fff;

		&::before {
			background: #fff;
			border: 1px solid transparent;
			color: $colorPrimary;
		}
		&:hover,
		&:focus {
			color: #fff;
			&::before,
			&::after {
				color: #fff;
				background-color: $colorPrimary;
				border: 1px solid #fff;
				--spacing: 10px;
			}
		}
	}
	.hidden,
	&.hidden {
		display: none;
	}
}
