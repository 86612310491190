.V3-50-40 {
	display: flex;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 auto 24px;
	flex-wrap: wrap;

	.sf_colsIn {
		width: 100%;

		&:first-child,
		section {
			margin-bottom: 60px;
		}

		section {
			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakMin($breakSmall) {
			&:first-child {
				width: calc(50% - 20px);
			}
			&:last-child {
				margin-top: 45px;
				width: calc(50% - 20px);
			}
			margin-bottom: 0;
			section {
				margin-bottom: 25px;
			}
		}

		@include breakMin($breakMedium) {
			&:first-child {
				width: calc(50% - 12px);
			}
			&:last-child {
				width: calc(40% - 12px);
			}
		}
	}
}
