.sustainability-filter-result {
	position: relative;
	background: $colorPrimary;
	--aspect-ratio: 100%;
	&::before {
		content: '';
		display: block;
		padding-bottom: var(--aspect-ratio);
	}
	.still-picture {
		width: auto;
		height: auto;
	}
	@include breakMin($breakSmall) {
		--aspect-ratio: 100%;
	}

	&--container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		padding: 40px 20px;
	}
	&--title,
	&--read-more {
		color: white;
	}
	&--title {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 28px;
		line-height: 34px;
		margin-bottom: 30px;
		@include breakMin($breakXXtraSmall) {
			-webkit-line-clamp: 3;
		}
		@include breakMin($breakXtraSmall) {
			-webkit-line-clamp: 4;
		}
	}
	&--read-more {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		&:focus {
			outline: none;
		}
	}
	img {
		transition: $slowDelay transform ease-in-out;
	}
	&:hover,
	&:focus-within {
		img {
			transition: $mediumDelay transform ease-in-out;
			transform: scale(1.05);
		}
	}
	&:focus-within {
		outline: $blockFocusOutline;
	}
}
