// imported by js single point of change
// while developping, update js file to see those values change js side
// cause scss aint js and therefore won't trigger gulp's webpack watcher.

$timelineGap: 15;
$timelineGapMobile: 10;
$timelineTopMargin: 60; // gap between main description and the steps in px
$timelineTopMarginMobile: 40;

.timeline-widget {
	overflow: hidden;
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
	@include breakMax($breakMediumBefore) {
		margin: 0 calc(-50vw + 50%);
		width: 100vw;
	}
	&--title,
	&--description {
		max-width: calc(100% - #{2 * $pagePadding});
		@include breakMin($breakMedium) {
			max-width: 690px;
		}
	}
	&--title {
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 25px;
		color: black;
		text-align: center;
	}
	&--description {
		color: $darkGrey;
		text-align: center;
	}
	&--steps {
		width: 100%;
		border-bottom: 1px solid $colorPrimary;
		margin-top: 185px;
		display: flex;
		margin-bottom: 46px;
	}
	.step {
		margin: 0 100%;
		position: relative;
		transition: $slowDelay margin ease-out;
		cursor: pointer;
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0px;
			left: 0;
			width: 7px;
			height: 7px;
			transform: translate(-50%, -50%);
			background: $colorPrimary;
			border-radius: 50%;
			transition: $mediumDelay width ease-in-out,
				$mediumDelay height ease-in-out;
		}
		&--content {
			position: absolute;
			display: flex;
			flex-flow: column;
			align-items: center;
			left: 0;
			transform: translateX(-50%);
			bottom: -13px;
			padding-bottom: 30px;
		}
		&--title,
		&--subtitle {
			text-align: center;
			width: 70vw;
			color: $mediumGrey;
			transition: $mediumDelay color ease-in-out;
			margin-bottom: 10px;
			@include breakMin($breakMedium) {
				width: 30vw;
			}
			@supports (width: max-content) {
				width: max-content;
				max-width: 70vw;
				@include breakMin($breakMedium) {
					max-width: 30vw;
				}
			}
		}
		&--title {
			font-size: 34px;
			font-weight: 300;
			&.bottom-margin {
				margin-bottom: 37px;
			}
		}
		&--subtitle {
			font-size: 14px;
		}
		&.active {
			margin: 0 100%;
			cursor: normal;
			pointer-events: none;
			&::before {
				width: 25px;
				height: 25px;
			}
			.step--title {
				color: $colorPrimary;
			}
			.step--subtitle {
				color: $textBlack;
			}
		}
		&:first-child {
			&.active {
				margin-left: 50%;
			}
		}
	}
	&--bottom {
		display: flex;
		width: 100%;
	}
	&--carousel {
		position: relative;
		width: calc(100% - #{2 * $pagePadding});
		margin: 0 auto;
		@include breakMin($breakMedium) {
			width: 60%;
		}
	}
	&--content {
		display: flex;
		width: 100%;
		align-items: center;
	}
	&--step-description {
		transition: $slowDelay margin ease-out;
		width: 100%;
		min-width: 100%;
		text-align: center;
		font-weight: 300;
		margin-left: 0;
		margin-right: 100%;
		font-size: 16px;
		line-height: 24px;
	}
	&--previous,
	&--next {
		display: none;
	}
	@include breakMin($breakMedium) {
		&--previous,
		&--next {
			display: block;
			width: 20%;
			cursor: pointer;
			border: none;
			color: $darkGrey;
			transition: $mediumDelay padding ease-in-out;
			position: relative;
			z-index: 1;
			&::before {
				font-family: $iconFont;
				font-size: 13px;
				transition: $mediumDelay color ease-in-out;
			}
			&:hover,
			&:focus {
				color: $colorPrimary;
				outline: none;
			}
			&.inactive {
				cursor: normal;
				pointer-events: none;
				color: $buttonGrey;
			}
		}
		&--previous {
			background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
			padding-right: 40px;
			&:hover {
				padding-right: 60px;
			}
			&::before {
				content: $icon-longarrowleft;
			}
			&.inactive {
				padding-right: 40px;
			}
		}
		&--next {
			background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
			padding-left: 40px;
			&:hover {
				padding-left: 60px;
			}
			&::before {
				content: $icon-longarrowright;
			}
			&.inactive {
				padding-left: 40px;
			}
		}
	}
	&--dots {
		display: flex;
		margin-top: 40px;
		height: 10px;
		align-items: center;
		@include breakMin($breakMedium) {
			display: none;
		}
	}
	.carousel-dot {
		background: $colorPrimary;
	}
}
