.full-width-blue {
	--background-white: #{$colorPrimary};
	--background-grey: white;
	--background-primary: white;
	--text-white: #{$colorPrimary};
	--text-black: white;
	--text-lighter-black: white;
	--text-grey: rgba(255,255,255, 0.5);
	--text-primary: white;

	display: flex;
	margin: 0 #{-$pagePadding} $subWidgetSpacingMobile;
	padding-top: 50px;
	background-color: $colorPrimary;
	@include breakMin($breakLarge) {
		margin: 0 0 $subWidgetSpacing 0;
	}
	> .sf_colsIn {
		width: 100%;
		max-width: $wrapperMax;
		margin: 0 auto;
		padding: 0 $pagePadding;
		@include breakMin($breakLarge) {
			padding: 0;
		}
	}
}
