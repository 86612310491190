.video-full-width {
	position: relative;
	margin: 0 -#{$pagePadding};
	min-height: 700px;

	@include breakMin($breakMediumSmall) {
		min-height: 0;
	}

	&--background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.back-picture.has-gradient:after {
			background: linear-gradient(transparent, $gradientColor);
		}
	}

	&--inner {
		margin: 0 auto;

		@include breakMin($breakMediumSmall) {
			width: calc(100% - 80px);
		}
	}

	&--content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		min-height: 700px;
		padding: 0 50px;

		@include breakMin($breakMediumSmall) {
			flex-direction: column;
			align-items: flex-start;
			min-height: 0;
			width: 100%;
			padding: 250px 0 0 0;
		}

		.still-picture {
			position: relative;
			margin-bottom: 50px;

			@include breakMin($breakMediumSmall) {
				align-self: flex-end;
				margin: -60px 0 60px 0;
			}
		}
	}

	&--title {
		position: relative;
		margin-bottom: 20px;
		font-size: 28px;
		font-weight: 700;
		color: #fff;
		text-align: center;

		@include breakMin($breakMedium) {
			width: 50%;
			text-align: left;
		}
	}

	&--subtitle {
		position: relative;
		font-size: 14px;
		color: #fff;
		margin-bottom: 30px;
	}

	&--cta {
		position: relative;
		border: none;
		background-color: $colorPrimary;
		padding: 20px;
		margin-bottom: 20px;
		font-size: 14px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: #fff;
		transition: $mediumDelay background-color ease-in-out;

		&:after {
			font-family: $iconFont;
			content: $icon-play;
			padding-left: 10px;
			font-size: 12px;
		}

		&:hover {
			background-color: $colorPrimaryHover;
			padding-left: 20px;
		}

		@include breakMin($breakMediumSmall) {
			margin-bottom: 0;
		}
	}
	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		left: 95%;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
}
