.job-list {
	&--title {
		font-size: 30px;
		line-height: 48px;
		font-weight: normal;
		margin-bottom: 30px;
	}
	&--content {
		margin: 0 auto;
	}
	&--form {
		position: relative;
		padding: 40px 0 75px;
		background: $lightGrey;
		margin: 0 -#{$pagePadding};
		@include breakMin($breakLarge) {
			margin: 0;
		}
	}
	&--form &--content {
		padding: 0 $pagePadding;
		@include breakMin($breakLarge) {
			padding: 0;
		}
	}
	&--list {
		padding: 70px 0 110px;
	}
	&--search-row,
	&--tags-row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}
	&--search-row {
		margin-bottom: 25px;
		flex-wrap: wrap;
		@include breakMin($breakLarge) {
			flex-wrap: nowrap;
		}
	}
	&--tags-row {
		align-items: flex-end;
	}
	&--tags {
		display: flex;
		flex-wrap: wrap;
	}
	&--clear {
		color: $colorPrimary;
		border: none;
		background: none;
		padding: 0;
		align-self: center;
		font-size: 14px;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.search-field-drop {
		width: 100%;
		margin-bottom: 40px;
		@include breakMin($breakMediumSmall) {
			width: calc(50% - 12px);
			margin-bottom: 12px;
		}
		@include breakMin($breakLarge) {
			flex-grow: 1;
			width: auto;
		}
		&--field {
			border: none;
			padding: 0;
			width: 100%;
			background: white;
			button {
				display: none;
			}
		}
		&--drop {
			left: 1px;
			width: calc(100% - 2px);
			z-index: 10;
		}
		&--back-suggestion,
		input {
			font-size: 14px;
			height: 100%;
			padding: 14px 20px;
			border: 1px solid $strokeGrey;
			width: 100%;
			transition: $fastDelay border-color ease-in-out;
			@include breakMax($breakMediumSmall) {
				font-size: 16px;
			}
		}
		&--back-suggestion {
			top: 0;
			margin: auto;
			display: flex;
			align-items: center;
		}
		&.open input {
			border-bottom: 1px solid transparent;
		}
	}
	.check-drop-down {
		margin-bottom: 12px;
		width: 100%;
		@include breakMin($breakMediumSmall) {
			width: calc(50% - 12px);
		}
		@include breakMin($breakLarge) {
			margin-left: 25px;
			width: auto;
		}
		& > button {
			width: 100%;
		}
		input {
			color: $textBlack;
			&::placeholder {
				font-size: 14px;
				opacity: 1;
				color: $textBlack;
			}
			&:focus::placeholder {
				opacity: 0.3;
			}
		}
	}
}
