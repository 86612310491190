.gallery-item {
	display: flex;
	flex-flow: column;
	margin-bottom: 30px;
	@include breakMin($breakSmall) {
		margin: 0;
	}
	&--picture {
		position: relative;
		width: 100%;
		border: none;
		background: none;
		padding: 0;
		margin-bottom: 15px;
		&::before {
			content: "";
			width: 100%;
			padding-bottom: 100%;
			display: block;
		}
		img {
			transition: $mediumDelay transform ease-in-out;
		}
		&:focus {
			outline: $blockFocusOutline;
			img {
				transition: $fastDelay transform ease-in-out;
				transform: scale(1.1);
			}
		}
	}
	&--slideshow-label {
		position: absolute;
		bottom: 20px;
		left: 30px;
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.015em;
		color: white;
		width: calc(100% - 60px);
		span {
			width: calc(100% - 40px);
		}
		&::before {
			content: $icon-gallery;
			font-family: $iconFont;
			font-size: 15px;
			color: white;
			display: flex;
			background: $colorPrimary;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			margin-right: 10px;
			font-weight: normal;
		}
	}
	&--infos {
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 0.015em;
		margin-bottom: 5px;
	}
	&--date {
		text-transform: none;
		letter-spacing: normal;
		color: $grey;
		&::before {
			content: "";
			display: inline-flex;
			background: $grey;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			margin: 0 4px 4px 8px;
		}
	}
	&--title {
		font-size: 16px;
		margin-bottom: 25px;
	}
	&--menu,
	&--view-all {
		display: flex;
		align-items: center;
	}
	&--view-all,
	&--download {
		color: $colorPrimary;
		font-size: 12px;
		letter-spacing: 0.015em;
		text-transform: uppercase;
		font-weight: bold;
		border: none;
		background: none;
		padding: 0;
		&:focus {
			outline: none;
			color: $textBlack;
		}
	}
	&--view-all {
		&::after {
			content: "";
			display: inline-block;
			width: 0;
			height: 15px;
			border-right: 1px solid $buttonGrey;
			margin: 0 25px;
		}
	}
	&--download {
		&::after {
			content: $icon-download;
			font-family: $iconFont;
			color: $colorPrimary;
			margin-left: 10px;
			font-weight: normal;
		}
	}
	&--slides {
		width: 100%;
		height: 60vh;
		@include breakMin($breakMedium) {
			width: 60vw;
		}
		display: flex;
		position: relative;
		overflow: hidden;
	}
	&--slide {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-width: 100%;
		height: 100%;
		background: black;
		transition: $slowDelay margin-left ease-out;
		img {
			max-height: 100%;
			max-width: 100%;
			height: auto;
			width: auto;
		}
	}
	&--download-button {
		position: absolute;
		bottom: 15px;
		right: 10px;
		width: 30px;
		height: 30px;
		background: $colorPrimary;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10000;
		&::before {
			content: $icon-download;
			font-family: $iconFont;
			color: white;
			font-size: 12px;
		}
	}
	&--modal-menu {
		padding: 30px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	&--previous,
	&--next {
		color: white;
		font-size: 0;
		background: none;
		border: none;
		display: flex;
		align-items: center;

		@include breakMin($breakXtraSmall) {
			font-size: 11px;
		}
	}
	&--previous {
		&::before {
			font-family: $iconFont;
			color: white;
			font-size: 12px;
			margin-right: 0;
			content: $icon-longarrowleft;

			@include breakMin($breakXtraSmall) {
				margin-right: 10px;
			}
		}
	}
	&--next {
		&::after {
			font-family: $iconFont;
			color: white;
			font-size: 12px;
			margin-left: 0;
			content: $icon-longarrowright;

			@include breakMin($breakXtraSmall) {
				margin-left: 10px;
			}
		}
	}
	&--dots {
		display: flex;
		align-items: center;
		justify-content: center;
    	width: 100%;
	}
	&--dot {
		width: 4px;
		height: 4px;
		background: white;
		margin: 0 2px;

		@include breakMin($breakXtraSmall) {
			width: 5px;
			height: 5px;
			margin: 0 4px;
		}

		@include breakMin($breakSmall) {
			width: 6px;
			height: 6px;
			margin: 0 7px;
		}

		@include breakMin($breakLarge) {
			margin: 0 10px;
		}

		&.active {
			width: 8px;
			height: 8px;
			background: $colorPrimary;
			margin: 0 2px;
			transition: $fastDelay background ease-in-out, $fastDelay width ease-in-out, $fastDelay height ease-in-out,
				$fastDelay margin ease-in-out;

			@include breakMin($breakXtraSmall) {
				width: 9px;
				height: 9px;
				margin: 0 3px;
			}

			@include breakMin($breakSmall) {
				width: 10px;
				height: 10px;
				margin: 0 5px;
			}

			@include breakMin($breakLarge) {
				margin: 0 8px;
			}
		}
	}
}
