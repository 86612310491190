.footer-list {
	display: flex;
	flex-flow: column;

	&--title {
		margin-bottom: 10px;
		color: $darkGrey;
	}

	&--link {
		font-size: 14px;
		line-height: 24px;
		color: $grey;
		transition: $fast;

		&:hover,
		&:focus {
			outline: none;
			color: $colorPrimaryHover;
			font-weight: bold;
		}
	}
}

.footer-link {
	font-size: 14px;
	line-height: 24px;
	color: $grey;
	transition: $fast;

	&:hover,
	&:focus {
		outline: none;
		color: $colorPrimaryHover;
		font-weight: bold;
	}
}
