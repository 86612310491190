@mixin breakMin($break) {
	@media screen and (min-width: $break) {
		@content;
	}
}

@mixin breakMax($break) {
	@media screen and (max-width: $break) {
		@content;
	}
}

@mixin breakBetween($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

@mixin landscapeOrientation() {
	@media (pointer: coarse) and (orientation: landscape) {
		@content;
	}
}

@mixin sr-only {
	position: absolute !important;
	margin: -1px !important;
	border: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	overflow: hidden !important;
	white-space: nowrap !important;
}

@mixin sr-only-mobile {
	@include breakMax($breakMediumSmallBefore) {
		@include sr-only();
	}
}

// generate an arrow that is shadowable as used in the world partial
// (check to experiment with it's values)
// the default state is hidden inside, to unfold it trigger the translate: rotate(45deg) solely
// arguments:
// shadowblur - thickness of the shadow
// arrowHeight - length of the arrow on it's axis
// arrowMargin - margin from one side perpendicular to the main axis.
// direction - main direction where the arrow is pointing
// marginFrom - direction from where the arrow has its margin from.

@mixin shadowedArrow(
	$shadowBlur,
	$arrowHeight,
	$arrowMargin,
	$direction,
	$marginFrom
) {
	$longitudinalAxis: bottom;
	$transversalAxis: left;
	$translate: translateY(100%) rotate(45deg);
	@if ($direction == bottom) {
		$longitudinalAxis: top;
		$transversalAxis: left;
		$translate: translateY(-100%) rotate(45deg);
	}
	@if ($direction == left) {
		$longitudinalAxis: right;
		$transversalAxis: bottom;
		$translate: translateX(100%) rotate(45deg);
	}
	@if ($direction == right) {
		$longitudinalAxis: left;
		$transversalAxis: top;
		$translate: translateX(-100%) rotate(45deg);
	}

	$arrowWidth: $arrowHeight * 2;
	$containerHeight: $arrowHeight + $shadowBlur;
	$containerWidth: $arrowWidth + $shadowBlur * 2;

	$widthField: if($direction == top or $direction == bottom, width, height);
	$heightField: if($direction == top or $direction == bottom, height, width);

	position: absolute;
	#{$direction}: -#{$containerHeight};
	#{$marginFrom}: #{$arrowMargin - $shadowBlur};
	#{$widthField}: $containerWidth;
	#{$heightField}: $containerHeight;
	overflow: hidden;

	&::before {
		$squareWidth: $arrowHeight * $twoSqrt;
		content: '';
		position: absolute;
		display: block;
		#{$widthField}: $squareWidth;
		#{$heightField}: $squareWidth;
		#{$longitudinalAxis}: -#{$squareWidth / 2};
		#{$transversalAxis}: #{$containerWidth / 2 - $squareWidth / 2};
		transform: $translate;
	}
}

@mixin stretch($pseudo: after) {
	&:#{$pseudo} {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

@mixin share-price-indicator {
	display: inline-block;
	width: 15px;
	height: 15px;
	font-family: $iconFont;
	font-size: 12px;
	position: relative;
	transform: scaleX(-1);
}
