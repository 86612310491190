.tbe-journey {
	--iconBackColor: white;
	--iconColor: #{$colorPrimary};
	--iconBorderColor: #{$mediumGrey};

	&--title {
		font-size: 30px;
		font-weight: 400;
	}

	.left-menu {
		&--title {
			margin-bottom: 20px;
			color: $colorPrimary;
		}

		&--items-inner {
			position: relative;
			.connecting-line {
				position: absolute;
				height: 100%;
				background-color: $mediumGrey;
				width: 1px;
				left: 20px;
				z-index: 1;
			}
		}

		&--row {
			margin-bottom: 30px;
			max-width: 215px;
		}

		&--step-link {
			z-index: 2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			color: $mediumGrey;
			&:focus {
				outline: none;
				--iconBackColor: #{$textBlack};
				--iconColor: white;
				--iconBorderColor: #{$textBlack};
				.left-menu--category-text {
					color: $colorPrimary;
					font-weight: bold;
				}
			}
		}

		&--category-text {
			line-height: 1.8;
			color: $grey;
		}
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		min-width: 40px;
		height: 40px;
		border: 1px solid var(--iconBorderColor);
		background-color: var(--iconBackColor);
		border-radius: 50%;
		margin-right: 10px;
		transition: $mediumDelay background ease-in-out, $mediumDelay border-color ease-in-out;
		&:before {
			font-family: $iconFont;
			color: var(--iconColor);
			font-weight: normal;
			transition: $mediumDelay color ease-in-out;
		}
	}
	.current .icon,
	a:hover .icon,
	*:focus .icon {
		transition: $fastDelay background ease-in-out, $fastDelay border-color ease-in-out;
		&:before {
			transition: $fastDelay color ease-in-out;
		}
	}
	.current {
		--iconBackColor: #{$colorPrimary};
		--iconColor: white;
		--iconBorderColor: #{$colorPrimary};
	}
	.ico-growing:before {
		content: $icon-growing;
		font-size: 20px;
	}

	.ico-processing:before {
		content: $icon-processing;
		font-size: 22px;
	}

	.ico-product:before {
		content: $icon-product;
		font-size: 23px;
	}

	.ico-suppliers:before {
		content: $icon-suppliers;
		font-size: 26px;
	}

	.ico-distributive:before {
		content: $icon-distributive;
		font-size: 18px;
	}

	.ico-taxes:before {
		content: $icon-taxes1;
		font-size: 16px;
	}
	.ico-illicit:before {
		content: $icon-taxes;
		font-size: 26px;
	}

	.ico-consumers:before {
		content: $icon-consumers;
		font-size: 29px;
	}

	.right-section {
		&--heading {
			display: flex;
			align-items: center;
		}

		&--description {
			padding: 30px 0 70px 0;
		}

		&--cols {
			display: flex;
			flex-direction: column;

			.col {
				width: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				p {
					margin: 0 0 36px;
					&:last-child {
						margin: 0;
					}
				}
				// to center images inside column
				img {
					display: block;
					margin: 0 auto;
				}

				@include breakMin($breakMedium) {
					align-items: baseline;
				}

				&:first-child {
					margin: 0 0 20px 0;
					@include breakMin($breakMedium) {
						margin: 0;
					}
				}
			}

			&.one-col {
				.col {
					width: auto;
					margin: 0;
				}
			}

			@include breakMin($breakMedium) {
				flex-direction: row;
				.col {
					width: 50%;
					&:first-child {
						margin-right: 20px;
					}
				}
			}
		}

		&--rows {
			.row {
				margin-top: 20px;
			}
		}
	}

	.col-row {
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: $grey;
	}

	.list-content {
		margin-bottom: 30px;
		&:not(:last-child) {
			border-bottom: 1px solid $mediumGrey;
		}
		.icon {
			margin-right: 20px;
		}
	}
}
