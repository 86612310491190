.market-search-menu {
	.search-field-drop {
		padding: 20px 20px 0;
		[dir='rtl'] & {
			text-align: right;
		}
		&--field {
			height: 18px;
			margin-left: 25px;
			border-bottom: none;
			input {
				top: 0;
				[dir='rtl'] & {
					text-align: right;
				}
			}
			&::before {
				content: $icon-search;
				position: absolute;
				font-family: $iconFont;
				font-size: 16px;
				color: $grey;
				left: -30px;
				top: 10px;
				transform: translateY(-50%);
			}
		}
		&--back-suggestion {
			top: 0;
		}
		&--drop {
			margin-top: 20px;
			position: relative;
			box-shadow: none;
			height: auto;
			overflow: visible;
		}
		&--suggestions {
			height: 200px;
			overflow: auto;
			margin-left: -20px;
			margin-right: -20px;
			border-top: 1px solid $lightGrey;
			border-bottom: 1px solid white;
			a {
				position: relative;
				font-size: 14px;
				display: inline-block;
				width: auto;
				padding: 11px 0 11px 50px;
				color: $darkGrey;
				margin-bottom: 1px;
				transition: $fastDelay color ease-in-out,
					$fastDelay background ease-in-out;
				[dir='rtl'] & {
					padding: 11px 50px 11px 0;
				}
				&.highlighted {
					font-weight: bold;
					&::before {
						content: $icon-filledPosition;
						font-family: $iconFont;
						font-size: 15px;
						font-weight: normal;
						position: absolute;
						left: 25px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&:after {
					content: ' ';
					border-bottom: 2px solid transparent;
					width: calc(100% - 30px);
					bottom: 0;
					position: absolute;
					right: -12px;
				}
			}
			li {
				display: flex;
				&.active a,
				a:hover,
				a:focus {
					color: $colorPrimary;
					font-weight: bold;
					transition: none;
					outline: none;
					&:after {
						border-color: #0074c0;
					}
				}
			}
		}
	}
	&--all-market-new {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 100%;
		font-size: 12px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		background: $colorPrimary;
		color: white;
		border: none;
		transition: $mediumDelay color ease-in-out,
			$mediumDelay background ease-in-out;
	}
	&--all-market-old {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		width: 100%;
		font-size: 12px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: $colorPrimary;
		background: $lightGrey;
		transition: $mediumDelay color ease-in-out,
			$mediumDelay background ease-in-out;
		&:hover {
			background: $colorPrimary;
			color: white;
			transition: $fastDelay color ease-in-out,
				$fastDelay background ease-in-out;
		}
	}
}
