.market-redirect-widget {
	position: fixed;
	z-index: $z-market-redirect;
	top: 0;
	left: 0;
	width: 100%;
	height: $market-redirect-height;
	background-color: $colorPrimary;
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);

	@include breakMin($breakMedium) {
		height: $market-redirect-height-medium;
	}

	@include breakMin($breakLarge) {
		margin: 0;
		height: $market-redirect-height-large;
	}

	@include breakMin($breakNavigation) {
		transition: $mediumDelay transform ease-in-out, $mediumDelay opacity ease-in-out;
	}

	&.closed,
	body.scrolled & {
		@include breakMin($breakNavigation) {
			transform: translateY(-100%);
			opacity: 0;
		}
	}

	&.closed {
		display: none;
	}

	&--image-container {
		height: 100%;
		overflow: hidden;
	}

	&--image {
		width: 100%;
		height: auto;
		opacity: 0.2;
		@include breakMin($breakMedium) {
			object-fit: cover;
			height: 300px;
		}
	}

	&--content {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-flow: column;
		padding: 20px;
		color: white;

		@include breakMin($breakMedium) {
			margin: 0 auto;
			flex-flow: row;
			justify-content: space-around;
		}

		@include breakMin($breakLarge) {
			justify-content: space-between;
		}
	}

	&--description-container {
		display: flex;
		align-items: center;
		@include breakMin($breakMedium) {
			width: 50%;
		}
		.close-btn {
			border: none;
			font-size: 16px;
			color: white;
			background: none;
			margin-right: 30px;
			@include breakMin($breakLarge) {
				margin-right: 90px;
			}
			cursor: pointer;
			&::before {
				content: $icon-cross;
				font-family: $iconFont;
			}
		}
	}

	&--description {
		font-size: 14px;
	}

	&--country-select {
		display: flex;
		justify-content: space-evenly;
		margin-top: 15px;
		@include breakMin($breakMedium) {
			width: 40%;
			margin-top: 5px;
			justify-content: stretch;
		}

		.arrowed-link {
			display: flex;
			align-items: center;
			color: white;
			font-weight: 600;
			--spacing: 28px;
			&::before {
				color: $colorPrimary;
				background-color: white;
			}
		}
	}

	.country-dropdown {
		position: relative;
		background: none;
		width: calc(50% - 30px);
		max-width: 276px;
		@include breakMin($breakMedium) {
			margin-right: 30px;
		}
		&--button {
			display: flex;
			background: none;
			color: white;
			&.search-field {
				border-bottom: 1px solid white;
				font-size: 16px;
			}
		}

		&--list-container {
			position: absolute;
			z-index: 2;
			top: -10px;
			left: 0;
			width: 100%;
			box-shadow: $menuShadow;
			overflow: hidden;
			// Anim props
			max-height: 0;
			transition: $mediumDelay max-height ease-in-out;

			&.open {
				// Default max-height, overridden by JS with dynamic value
				// representing actual list height
				max-height: 200px;
			}
		}

		&--list {
			padding: 10px 0;
			background-color: white;
		}

		&--link {
			display: flex;
			color: $textBlack;
			padding: 10px 0;
			transition: none;
			@include breakMin($breakMedium) {
				align-items: center;
			}

			.country-dropdown--flag {
				margin-left: 10px;
			}

			&:hover {
				color: white;
				background-color: $colorPrimary;
			}
		}

		&--flag {
			flex: 0 0 18px;
			height: 18px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 10px;
		}

		.down-arrow,
		.up-arrow {
			&::after {
				display: inline-block;
				content: $icon-droparrow;
				font-family: $iconFont;
				font-size: 8px;
				transform: rotate(180deg);
			}
		}
		.down-arrow {
			margin-left: auto;
		}
		.up-arrow {
			&::after {
				position: absolute;
				top: 25px;
				right: 10px;
				color: $textBlack;
				transform: none;
			}
		}
	}
}
