.search-results-page {
	margin: 40px 0 $subWidgetSpacingMobile;
	@include breakMin($breakMedium) {
		margin-bottom: $subWidgetSpacing;
	}
	&--filters,
	&--tags {
		display: flex;
		align-items: baseline;
		width: 100%;
	}
	&--filters {
		margin-bottom: 20px;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;
		@include breakMin($breakMediumSmall) {
			flex-direction: row;
			justify-content: flex-start;
		}
	}
	&--tags {
		flex-wrap: wrap;
	}
	&--row {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 10px;
	}
	&--clear {
		display: none;
		background: none;
		border: none;
		padding: 0;
		color: $colorPrimary;
		padding-bottom: 20px;
		white-space: nowrap;
		cursor: pointer;
		&.visible {
			display: block;
		}
		&:focus {
			outline: none;
			color: $textBlack;
			font-weight: bold;
		}
	}
	.search-field-drop {
		width: 100%;
		margin-bottom: 30px;
		&--field {
			border-width: 2px;
			button:focus {
				outline: none;
				@include breakMax($breakNavigationBefore) {
					background-color: $colorPrimary;
				}
				@include breakMin($breakNavigation) {
					&::before {
						color: $colorPrimary;
					}
				}
			}
		}
		&--back-suggestion,
		input {
			color: $darkGrey;
			font-size: 24px;
			@include breakMin($breakSmall) {
				font-size: 28px;
			}
		}
	}
	@include breakMax($breakMediumSmallBefore) {
		.check-drop-down {
			& > button {
				font-size: 0;
				margin-right: 10px;
				border: none;
				padding: 0;

				&:after {
					display: none;
				}
				&:before {
					content: $icon-filter;
					font-family: $iconFont;
					color: $colorPrimary;
					font-size: 29px;
				}
			}
		}
	}
	&--filter-title {
		@include breakMax($breakMediumSmall) {
			order: 1;
		}
		display: block;
		color: $darkGrey;
		margin-left: 0;
		margin-right: 20px;
		font-weight: 700;
		letter-spacing: 0;
		text-transform: none;
		pointer-events: none;
		cursor: default;
	}
	&--filter-button {
		display: none;
	}
	&--tag {
		button {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 13px 18px;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			font-size: 11px;
			background: $lightGrey;
			margin-right: 20px;
			margin-bottom: 20px;
			border: 2px solid transparent;
			&:after {
				display: flex;
				background: none;
				border: none;
				padding: 0;
				margin-left: 10px;
				content: $icon-cross;
				font-family: $iconFont;
				font-size: 7px;
				color: $grey;
			}
			&:focus:not(:hover) {
				border-color: $colorPrimary;
				&:after {
					color: $colorPrimary;
				}
			}
		}
	}
}
