.investor-cta-newsletter {
	display: block;
	padding: 40px 30px;
	margin-bottom: 16px;
	box-shadow: $menuShadow;
	background-color: $colorPrimary;
	border: 1px solid $colorPrimary;

	@include breakMin($breakMediumSmall) {
		padding: 50px 30px;
	}

	&:focus {
		outline: none;
		border-color: white;
	}
	.investor-cta {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include breakMin($breakMediumSmall) {
			flex-direction: row;
			justify-content: space-between;
		}

		&--title,
		&--description,
		&--label {
			color: white;
		}
		&--newsletter-button {
			margin-right: auto;
			background-color: white;
			color: $textBlack;
			text-transform: uppercase;
			align-self: center;
			padding: 10px 30px;
			margin-top: 70px;
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;

			@include breakMin($breakMediumSmall) {
				margin-right: inherit;
				margin-top: 0;
			}
		}
	}
}
