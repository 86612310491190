.anchor {
  position: relative;
  visibility: hidden;
  top: -60px;
  @include breakMin($breakNavigation) {
    top: -100px;
  }
  .has-sub + * + .page-main & {
    top: -120px;
    @include breakMin($breakNavigation) {
      top: -180px;
    }
  }
}
