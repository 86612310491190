@import './components/MainPane.scss';
@import './components/Drop.scss';
@import './components/SelectableList.scss';
@import './components/App.scss';
@import './components/Loading.scss';
@import './components/filters/index.scss';
@import './components/filters/Tags.scss';
@import './components/filters/Phases.scss';
@import './components/filters/Variables.scss';
@import './components/dataView/index.scss';
@import './components/dataView/Notes.scss';
@import './components/dataView/Filters.scss';
@import './components/dataView/Content.scss';
@import './components/dataView/Download.scss';

.tobacco-economics {
	&--loading {
		animation-name: loading-effect;
		animation-duration: $evenSlowerDelay;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		&::after {
			content: '\00a0\00a0\00a0';
			animation-name: loading-dots;
			animation-duration: $evenSlowerDelay;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			margin-left: 5px;
		}
	}
}
@keyframes loading-effect {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}
@keyframes loading-dots {
	33% {
		content: '.\00a0\00a0';
	}
	66% {
		content: '\00a0.\00a0';
	}
	100% {
		content: '\00a0\00a0.';
	}
}
