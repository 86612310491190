.filters {
	&--container {
		position: relative;
		@include breakMin($breakMedium) {
			display: grid;
			grid-template-columns: 210px 3fr;
			grid-template-areas:
				'sort sort sort'
				'filters tableResults tableResults'
				'filters  tableResults  tableResults';
			gap: 25px;
		}
	}
	&--title {
		margin-bottom: 25px;
		font-size: 30px;
		font-weight: normal;
	}
	&--sort {
		display: flex;
		justify-content: space-between;
		align-items: center;
		grid-area: sort;
		border-bottom: 1px solid $strokeGrey;
		padding-bottom: 5px;
		@include breakMax($breakMedium) {
			flex-flow: column;
			align-items: flex-start;
		}
	}
	&--filters {
		grid-area: filters;
		@include breakMax($breakMedium) {
			// display: none;
		}
	}
	&--views {
		grid-area: tableResults;
	}
	&--table,
	&--grid {
		opacity: 0;
		visibility: hidden;
		transition: $mediumSpeed;
		display: none;
		&.active {
			opacity: 1;
			display: grid;
			visibility: visible;
		}
	}
	&--grid {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 20px;
		grid-auto-rows: 1fr;
		@include breakMax($breakMedium) {
			grid-template-columns: 1fr;
		}
		&.hidden {
			opacity: 0;
			visibility: hidden;
		}
	}
	&--grid-articles {
		grid-auto-rows: unset;
	}
	&--list-articles {
		grid-template-columns: 1fr;

		.filters--article-container {
			flex-flow: row;
			@include breakMin($breakMedium) {
				&:not(:first-child) {
					.filters--article-image {
						flex-basis: 30%;
					}
					.filters--article-content {
						flex-basis: 70%;
						margin-left: 30px;
					}
				}
			}
		}
		@include breakMax($breakMedium) {
			.filters--article-description {
				display: none;
			}
			.filters--article-image {
				flex-basis: 35%;
			}
			.filters--article-content {
				flex-basis: 65%;
			}
		}
	}
	&--article-content {
		margin-left: 10px;
		display: flex;
		flex-flow: column;
		flex-grow: 1;
	}
	&--article-container {
		display: flex;
		align-items: flex-start;
		flex-flow: column;
		&:first-child {
			@include breakMin($breakMedium) {
				grid-column: 1 / -1;
				flex-flow: row;
				.filters--article-content {
					margin-left: 35px;
					flex-basis: 50%;
				}
				.filters--article-image {
					flex-basis: 50%;
				}
				.filters--article-title {
					font-size: 28px;
					margin-bottom: 15px;
					line-height: 33px;
				}
				.filters--article-link {
					margin-top: 20px;
				}
			}
			@include breakMax($breakMedium) {
				position: relative;
				margin-top: 20px;
				&:after {
					content: ' ';
					position: absolute;
					width: 100vw;
					height: calc(100% + 40px);
					left: -20px;
					top: -20px;
					bottom: -20px;
					background-color: $lightGrey;
					z-index: -1;
				}
			}
		}
		@include breakMax($breakMedium) {
			margin-bottom: 20px;
		}
	}
	&--article-description {
		margin-bottom: 10px;
		@include breakMax($breakMedium) {
			margin-bottom: 20px;
		}
	}
	&--article-image {
		position: relative;
		margin-bottom: 12px;
		width: 100%;
		outline: none;
		&:after {
			content: '';
			width: 100%;
			padding-bottom: 59%;
			display: block;
		}
		@include breakMax($breakMedium) {
			height: 100%;
		}
	}
	&--article-title {
		margin-bottom: 8px;
	}
	&--article-logo {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		img {
			max-width: 33%;
			width: auto;
			height: auto;
			margin-right: 10px;
			@include breakMax($breakMedium) {
				max-width: 10%;
				margin-right: 7px;
			}
		}
	}
	&--article-link {
		display: block;
		color: $textBlack;
		font-weight: bold;
		margin-top: auto;
	}
	&--article-category {
		position: relative;
		margin-right: 20px;
		color: $colorPrimary;
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 2px;
		&:after {
			content: '.';
			position: absolute;
			right: -14px;
			top: -8px;
			font-size: 16px;
			@include breakMax($breakMedium) {
				right: -9px;
				top: -14px;
				font-size: 22px;
			}
		}
		@include breakMax($breakMedium) {
			font-size: 10px;
			margin-right: 11px;
		}
	}
	&--article-date {
		color: $colorPrimary;
		font-size: 11px;
		letter-spacing: 1px;
		@include breakMax($breakMedium) {
			font-size: 10px;
		}
	}
	[type='checkbox'] {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		& + label {
			display: flex;
			align-items: center;
			position: relative;
			font-size: 14px;
			color: $textBlack;
			cursor: pointer;
			padding: 15px 0;
			flex-grow: 1;
			@include breakMin($breakMedium) {
				padding: 15px;
			}

			&:before {
				--size: 14px;
				content: '';
				outline: 2px solid transparent;
				display: block;
				border: 2px $colorPrimary solid;
				margin-right: var(--size);
				height: var(--size);
				width: var(--size);
				min-width: var(--size);
				[dir='rtl'] & {
					margin-right: 0;
					margin-left: var(--size);
				}
			}
			&:hover::before {
				background: white;
			}
		}
		&:checked + label {
			font-weight: 700;
			&:before {
				background: $colorPrimary;
				border-color: #fff;
				outline-color: $colorPrimary;
			}
		}
	}
	&--filters-title,
	&--filters-apply,
	&--filters-mobile-btn,
	&--filters-title-mobile {
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 1px;
		background-color: $lightGrey;
		margin-bottom: 25px;
		border: none;
		width: 100%;
	}
	&--filters-title {
		@include breakMax($breakMedium) {
			display: none;
		}
	}
	&--filter-title {
		margin: 10px 0;
		@include breakMax($breakMedium) {
			padding-left: 20px;
			margin-top: 18px;
		}
	}
	&--filters-container {
		border-bottom: 1px solid $strokeGrey;
		padding-bottom: 20px;
		@include breakMax($breakMedium) {
			padding-left: 20px;
		}
	}
	&--filters-container:last-child {
		border: none;
		@include breakMax($breakMedium) {
			padding-bottom: 60px;
		}
	}
	&--list {
		@include breakMin($breakMedium) {
			overflow-y: scroll;
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
	}
	&--list::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
	.arrows {
		background-color: transparent;
		border: none;
		display: block;
		margin: 0 auto;
		&:after {
			content: ' ';
			border: solid $colorPrimary;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			background-color: transparent;
		}
		@include breakMax($breakMedium) {
			display: none;
		}
	}
	&--up-arrow:after {
		transform: rotate(-135deg);
	}
	&--down-arrow:after {
		transform: rotate(45deg);
	}
	&--list-view {
		border: none;
		background-color: transparent;
		padding: 0 5px;
		svg {
			rect,
			line,
			circle {
				transition: $fast;
				color: $colorPrimary;
			}
			circle,
			line {
				stroke: $colorPrimary;
			}
			rect {
				fill: $colorPrimary;
			}
		}
		&:hover,
		&.active {
			svg {
				rect {
					fill: #fff;
				}
				line {
					stroke: #fff;
				}
				circle {
					fill: $colorPrimary;
				}
			}
		}
	}
	&--grid-view--circle {
		stroke: $colorPrimary;
	}
	&--grid-view {
		border: none;
		background-color: transparent;
		padding: 0;
		svg {
			g,
			rect {
				transition: $fast;
			}
			rect {
				fill: $colorPrimary;
			}
		}
		&:hover,
		&.active {
			svg {
				g {
					fill: $colorPrimary;
				}
				rect {
					fill: #fff;
				}
			}
		}
		@include breakMax($breakMedium) {
			margin-left: auto;
			display: inline-block;
		}
	}
	&--newest,
	&--oldest {
		border: none;
		background-color: transparent;
		font-size: 14px;
		color: $mediumGrey;
		font-weight: bold;
		transition: $mediumSpeed;
		&.active,
		&:hover {
			color: $darkGrey;
		}
		&.active {
			cursor: default;
		}
	}
	&--sort-left,
	&--sort-right {
		display: flex;
		align-items: center;
		@include breakMax($breakMedium) {
			margin-bottom: 20px;
		}
	}
	&--sort-right {
		@include breakMax($breakMedium) {
			justify-content: flex-end;
			width: 100%;
		}
	}
	&--sort-right-buttons {
		margin-right: auto;
	}
	&--newest {
		@include breakMax($breakMedium) {
			padding: 0;
		}
	}
	&--oldest {
		margin-right: 20px;
	}
	&--results-found {
		color: $grey;
		margin-right: 25px;
	}
	&--clear-button {
		border: none;
		background-color: transparent;
		color: $colorPrimary;
		font-size: 14px;
		align-self: flex-end;
		display: none;
		&.active {
			display: block;
		}
	}
	table {
		width: 100%;
		thead {
			background-color: $lightGrey;
		}
	}
	&--column-name {
		font-size: 14px;
	}
	&--table-cell-description {
		font-size: 16px;
		padding-left: 25px;
		max-width: 450px;
		word-break: break-word;
		@include breakMax($breakMedium) {
			max-width: 210px;
			word-break: break-word;
		}
	}
	&--table-cell {
		font-size: 11px;
		color: $grey;
		text-align: center;
	}
	@include breakMax($breakMedium) {
		&--table-cell:nth-child(2),
		&--table-cell:nth-child(3),
		&--table-cell:nth-child(4) {
			display: none;
		}
	}
	&--table-top {
		height: 60px;
		@include breakMax($breakMedium) {
			display: none;
		}
	}
	&--column-name:first-child {
		padding-left: 35px;
	}
	&--table-row {
		height: 60px;
		border-bottom: 1px solid $buttonGrey;
	}
	&--download {
		display: inline-block;
		&::after {
			content: $icon-download;
			font-family: $iconFont;
			font-size: 12px;
			color: $colorPrimary;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 25px;
			min-width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 1px solid $colorPrimary;
			background: #fff;
		}
		&:hover:after {
			color: #fff;
			background: $colorPrimary;
		}
	}
	&--filters-mobile {
		@include breakMax($breakMedium) {
			display: flex;
			flex-flow: column;
			position: fixed;
			top: 0;
			height: 0;
			left: 0;
			width: 100vw;
			overflow: hidden;
			z-index: 2002;
			background: white;
			overflow-y: auto;
		}
	}
	&--filters-mobile-scroll {
		@include breakMax($breakMedium) {
			height: 100%;
			overflow-y: auto;
		}
	}
	&--filters-mobile-header {
		position: relative;
		margin-bottom: 10px;
		display: none;
		@include breakMax($breakMedium) {
			display: block;
		}
	}
	&--filters-mobile-close {
		border: none;
		display: flex;
		background-color: transparent;
		position: absolute;
		right: 0;
		top: 50%;
		z-index: 1;
		transform: translateY(-50%);
		&:after {
			content: '\d7';
			color: $darkGrey;
			font-size: 28px;
			margin-right: 10px;
		}
		&:hover {
			cursor: pointer;
		}
	}
	&--filters-title-mobile {
		margin-bottom: 0;
	}
	&--filters-mobile-btn {
		display: none;
		@include breakMax($breakMedium) {
			display: block;
			background-color: $colorPrimary;
			color: #fff;
		}
	}
	&--filters-apply {
		display: none;
		@include breakMax($breakMedium) {
			position: absolute;
			background-color: $colorPrimary;
			color: #fff;
			margin-bottom: 0;
			bottom: 0;
			display: block;
		}
	}
	&--column-sort {
		position: relative;
		background-color: transparent;
		border: none;
		font-weight: bold;
		color: #000;

		&:after {
			content: ' ';
			transition: $fast;
			margin-left: 10px;
			border: solid $textBlack;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
		&.desc:after {
			transform: rotate(-135deg);
			-webkit-transform: rotate(-135deg);
		}
	}
	&--image-sample {
		width: auto;
		height: auto;
		display: none;
	}
}
