.job-list-pages {
	table {
		width: 100%;
		table-layout: fixed;
	}
	th {
		text-align: left;
	}
	&--top {
		display: flex;
		justify-content: space-between;
		background: $colorPrimary;
		padding: 25px 0 25px 20px;
	}
	&--column-name {
		color: white;
		font-size: 14px;
		width: 150px;

		& + & {
			@include sr-only-mobile();
		}

		&:nth-child(3) {
			width: 100px;
		}

		&:first-child {
			flex-grow: 1;
		}
	}
	&--notes {
		margin-bottom: 3px;
		span {
			margin-left: 5px;
		}
	}
	&--page {
		display: flex;
		flex-flow: column;
		min-width: 100%;
	}
	&--pages {
		display: flex;
		align-items: flex-start;
		overflow: hidden;
	}
	.pages-nav {
		padding: 0;
		@include breakMin($breakSmall) {
			padding: 0 20px;
		}
	}
}
.job-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	border-bottom: 1px solid $buttonGrey;
	@include breakMin($breakMedium) {
		flex-wrap: nowrap;
	}
	h4,
	p {
		transition: $fastDelay color ease-in-out;
	}
	&:hover {
		h4,
		p {
			color: $colorPrimary;
			transition: none; // instant color with gradient decay
		}
	}
	&--title {
		margin-top: 20px;
		padding: 0 50px 0 20px;
		font-weight: normal;
		color: $textBlack;
		width: 100%;
		margin-bottom: 10px;
		@include breakMin($breakMedium) {
			width: auto;
			margin-bottom: 20px;
		}
	}
	&--notes {
		display: flex;
		padding-left: 20px;
		margin-bottom: 20px;
		width: 100%;
		@include breakMin($breakMediumSmall) {
			justify-content: flex-end;
		}
		@include breakMin($breakMedium) {
			padding: 0;
			margin: 0;
			width: auto;
		}
		p {
			width: 150px;
			&:nth-child(2) {
				width: 100px;
			}
		}
	}
}
