.V3-30-60 {
    display: flex;
    flex-flow: column;
    flex-direction: column-reverse;
    margin: 0 auto $subWidgetSpacingMobile;
    @include breakMin($breakMedium) {
      margin-bottom: $subWidgetSpacing;
    }
  
    > .sf_colsIn {
      width: 100%;
  
      > a,
      > div,
      > section,
      > img {
        margin-bottom: 24px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }
  
    @include breakMin($breakLarge) {
      flex-flow: row;
      justify-content: space-between;
  
      > .sf_colsIn:first-child {
        width: 30%;
      }
  
      > .sf_colsIn:nth-child(2) {
        width: 60%;
      }
    }
  }
  