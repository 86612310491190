.V3-Homepage-Header-V1 {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: minmax(515px, auto);
  margin-bottom: $subWidgetSpacingMobile;

  @include breakMin($breakSmall) {
    grid-template: 20px / repeat(3, 1fr);
    grid-template-areas:
      "big big intro"
      "big big small1"
      "big big small2"
      "big big medium";
  }

  @include breakMin($breakMedium) {
    width: 100%;
    margin: 0 auto $subWidgetSpacing auto;
    grid-template: minmax(20px, auto) / repeat(5, 1fr);
    grid-template-areas:
      "big big big intro  intro"
      "big big big small1 small2"
      "big big big medium medium";
  }

  &--big {
    @include breakMin($breakSmall) {
      grid-area: big;
      margin: 0 0 0 -20px;
    }

    @include breakMin($breakMedium) {
      // TODO: fix next line
      //margin-left: calc((100vw - 40) / 2 * -1);

      .large-image-text-on-image {
        // TODO: fix next line
        //padding-left: calc((100vw - 40) / 2);
        padding-bottom: 50px;
      }
    }

    @include breakMin($breakContent) {
      margin-left: calc((100vw - #{$wrapperMax}) / 2 * -1);

      .large-image-text-on-image {
        padding-left: calc((100vw - #{$wrapperMax}) / 2);
        padding-bottom: 50px;
      }
    }

    @include breakMin($breakSuperLarge) {
      margin-left: 0;

      .large-image-text-on-image {
        padding-left: 20px;
      }
    }
  }

  &--intro {
    color: $grey;
    font-size: 18px;

    @include breakMin($breakSmall) {
      grid-area: intro;
    }
  }

  &--small1 {
    @include breakMin($breakSmall) {
      grid-area: small1;
    }
  }

  &--small2 {
    @include breakMin($breakSmall) {
      grid-area: small2;
    }
  }

  &--medium {
    @include breakMin($breakSmall) {
      grid-area: medium;
    }

    @include breakMin($breakMedium) {
      .medium-text-on-image {
        padding-bottom: 50px;
      }
    }
  }
}