.tbe-data-view {
	&--note {
		width: calc(50% - 15px);
		margin-top: 30px;
		@include breakMin($breakLarge) {
			margin-right: 45px;
			width: auto;
		}
		span {
			color: $colorPrimary;
		}
	}
	&--notes {
		display: flex;
	}
}
