.file-icon {
	&::before {
		content: $icon-file;
	}
	&.doc::before {
		content: $icon-word;
	}
	&.pdf::before {
		content: $icon-pdffile;
	}
	&.xls::before {
		content: $icon-excel;
	}
	&.xbrl-file::before {
		content: $icon-xbrl;
	}
	&.link::before {
		content: $icon-outLink;
	}
}
