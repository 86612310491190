.content-button {
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 60px 25px 65px;
	text-align: center;
	&.is-left-aligned {
		text-align: left;
		align-items: flex-start;
	}
	&.is-right-aligned {
		text-align: right;
		align-items: flex-end;
	}
	&:focus-within {
		outline: $blockFocusOutline;
	}
	&.gray-background {
		background-color: $lightGrey;
	}
	&--title {
		margin-bottom: 20px;
		&.wysiwyg {
			font-size: 20px;
			font-weight: bold;
		}
	}
	&--description {
		margin-bottom: 40px;
		&.wysiwyg {
			font-size: 20px;
			font-weight: normal;
		}
	}
	&--cta {
		&.color--unsmokeYellow {
			color: black;
			&:hover,
			&:focus {
				color: white;
			}
		}
		&.color--tarLightPink,
		&.color--tarPink,
		&.color--combustionCararra,
		&.color--combustionGold {
			color: black;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 250px;
		padding: 25px 65px;
		text-transform: uppercase;
		font-size: 12px;
		background-color: $colorPrimary;
		color: white;
		transition: $mediumDelay background-color ease-in-out;
		&:focus,
		&:hover {
			outline: none;
			background-color: $colorPrimaryHover;
		}
	}
}
