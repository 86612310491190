.ir-share-calculator {
	display: flex;
	width: 100%;
	position: relative;
	flex-flow: column;

	@include breakMin($breakMedium) {
		flex-flow: row;
	}

	$self: &;

	.pika-single {
		position: absolute;

		@include breakMin($breakXtraSmall) {
			width: auto;
		}
	}

	&--calendar {
		position: absolute;
		top: -92px;
		left: $pagePadding;
		z-index: 3;
		height: 0;
		width: calc(100% - #{2 * $pagePadding});

		@include breakMin($breakXtraSmall) {
			top: 35px;
			right: 35px;
			left: auto;
			width: auto;
		}
	}

	.loading-overlay {
		left: -$pagePadding;
		width: calc(100% + #{2 * $pagePadding});

		@include breakMin($breakSmall) {
			left: 0;
			width: 100%;
		}
	}

	&--form {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		background: $colorPrimary;
		padding: 35px $pagePadding;
		margin: 0 -#{$pagePadding};

		#{$self}--title {
			color: white;
		}

		@include breakMin($breakSmall) {
			padding: 50px 70px;
			margin: 0;
		}

		@include breakMin($breakMedium) {
			width: 60%;
		}

		@include breakMin($breakLarge) {
			padding-right: 215px;
		}
	}

	&--title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 25px;
	}

	&--date {
		color: white;
		margin-bottom: 60px;
		width: 100%;
		z-index: 2;
	}

	&--field {
		color: white;
		padding: 0 20px 15px;
		border: none;
		border-bottom: 1px solid white;
		background: none;
		width: 100%;
		appearance: textfield;
		outline: none;

		&::placeholder {
			color: white;
			opacity: 0.57;
		}

		&:focus::placeholder {
			opacity: 1;
		}

		&-date {
			margin-top: 30px;
		}
	}

	&--shares {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		width: 100%;
		flex-flow: column;

		@include breakMin($breakSmall) {
			flex-flow: row;
		}
	}

	&--share {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: white;
		cursor: pointer;
		margin-bottom: 15px;

		@include breakMin($breakSmall) {
			margin-bottom: 0;
		}
	}

	&--share-radio {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
	}

	&--radio {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		width: 16px;
		height: 16px;
		border: 1px solid white;
		border-radius: 50%;

		input:checked + &::before {
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: white;
		}
	}

	&--results {
		position: relative;
		flex-grow: 1;
		background: $lightGrey;
		padding: 35px $pagePadding;
		margin: 0 -#{$pagePadding};

		@include breakMin($breakSmall) {
			padding: 50px 70px 50px;
			margin: 0;
		}

		@include breakMin($breakMedium) {
			position: static;
		}

		#{$self}--title {
			color: $colorPrimary;
		}
	}

	&--result {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		color: $darkGrey;
	}

	&--cta {
		color: white;
		background: none;
		padding: 0;
		border: none;
		margin-top: 50px;
		opacity: 0.5;
		pointer-events: none;
		cursor: pointer;
		outline: none;

		&,
		&:hover,
		&:focus {
			color: white;

			&::before {
				background-color: white;
				color: $colorPrimary;
			}
		}

		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}
}
