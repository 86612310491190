.sub-nav {
	position: relative;
	display: flex;
	background: $lightGrey;
	height: 60px;
	margin: 0 #{-$pagePadding};
	padding: 0 $pagePadding;
	width: calc(100% + #{2 * $pagePadding});
	@include breakMin($breakNavigation) {
		margin: 20px 0 0;
		padding: 0;
		width: 100%;
	}

	&--content {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
	}
	&--title,
	&--unfold {
		font-size: 20px;
		font-weight: normal;
		display: flex;

		align-self: stretch;
		align-items: center;
		width: 100%;

		background: none;
		border: none;
		padding: 0;
	}
	&--title {
		display: none;
		@include breakMin($breakNavigation) {
			display: flex;
		}
	}
	&--unfold {
		[dir='rtl'] & {
			&:after {
				margin-right: 20px;
				margin-left: 0;
			}
		}
		&::after {
			content: $icon-arrowright;
			font-family: $iconFont;
			font-size: 10px;
			color: $colorPrimary;
			transform: rotate(90deg);
			margin-left: 20px;
			display: block;
			transition: $mediumDelay transform ease-in-out;
		}
		&:focus {
			color: $colorPrimary;
			font-weight: bold;
			outline: none;
		}
		@include breakMin($breakNavigation) {
			display: none;
		}
	}
	&--menus-container {
		display: flex;
		transition: $slowDelay height ease-in-out;
		@include breakMax($breakNavigationBefore) {
			position: absolute;
			top: 60px;
			left: 0;
			width: 100%;
			height: 0;
			overflow: hidden;
		}
	}
	&--menus {
		display: flex;
		flex-flow: column;
		width: 100%;
		background: $medlightGrey;
		padding: 30px $pagePadding;
		@include breakMin($breakNavigation) {
			flex-flow: row;
			width: auto;
			align-self: stretch;
			background: none;
			padding: 0;
		}

		@include breakMax($breakNavigation) {
			height: 100%;
			overflow-y: auto;
			display: block;
			padding-bottom: 100px;
		}
		
	}
}
