$filterSpacing: 25px;
.sustainability-filter {
	display: flex;
	flex-flow: column;
	--loading-height: 520px;
	&--filtering {
		--page-padding-compensation: #{$pagePadding};
		position: relative;
		display: flex;
		background-color: $lightGrey;
		padding: 45px var(--page-padding-compensation) 100px;
		margin: 0 calc(0px - var(--page-padding-compensation));
		@include breakMin($breakLarge) {
			--page-padding-compensation: 0;
		}
	}
	&--wrapping {
		margin: 0 auto;
	}
	&--title,
	&--results-title {
		color: $darkGrey;
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 40px;
	}
	&--filters {
		display: flex;
		flex-wrap: wrap;
		--filter-columns-count: 1;
		@include breakMin($breakSmall) {
			--filter-columns-count: 2;
		}
		@include breakMin($breakMediumSmall) {
			--filter-columns-count: 3;
		}
		@include breakMin($breakMedium) {
			--filter-columns-count: 4;
		}
		@include breakMin($breakLarge) {
			--filter-columns-count: 5;
		}
	}
	&--filter {
		margin-right: $filterSpacing;
		margin-bottom: $filterSpacing;
		width: calc(
			100% / var(--filter-columns-count) - #{$filterSpacing} * calc(
					var(--filter-columns-count) - 1
				) / var(--filter-columns-count)
		);
		@include breakMax($breakSmallBefore) {
			margin-right: 0;
		}

		@include breakBetween($breakSmall, $breakMediumSmallBefore) {
			&:nth-child(2n) {
				margin: 0;
			}
		}
		@include breakBetween($breakMediumSmall, $breakMediumBefore) {
			&:nth-child(3n) {
				margin: 0;
			}
		}
		@include breakBetween($breakMedium, $breakLargeBefore) {
			&:nth-child(4n) {
				margin: 0;
			}
		}
		@include breakMin($breakLarge) {
			&:nth-child(5n) {
				margin: 0;
			}
		}
	}
	&--tags {
		display: flex;
		flex-flow: wrap;
		align-items: baseline;
	}
	.tag-element {
		margin: 0 20px 20px 0;
	}
	&--clear-button button {
		color: $colorPrimary;
		border: none;
		background: none;
		padding: 0;
		align-self: center;
		font-size: 14px;
		margin-bottom: 20px;
		cursor: pointer;
	}
	&--container {
		position: relative;
		transition: $slowDelay height ease-in-out;
		.transitionning & {
			overflow: hidden;
		}
	}
	&--results {
		margin: 0 auto;
		padding: 70px 0 80px;
		--results-columns-count: 1;
		@include breakMin($breakSmall) {
			--results-columns-count: 2;
		}
		@include breakMin($breakMedium) {
			--results-columns-count: 3;
			.two-results-layout {
				--results-columns-count: 2;
			}
		}
		&-title {
			margin-bottom: 30px;
		}
		&-container {
			display: grid;
			grid-template: auto / repeat(var(--results-columns-count), 1fr);
			grid-gap: 5px;
			@include breakMax($breakSmallBefore) {
				column-gap: 0;
			}
		}
	}
}
