.page-info-widget-default {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto $subWidgetSpacingMobile;
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }
  &--content {
    margin: 0 auto;
  }
}
