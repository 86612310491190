.V3-Article-collage-V4 {
  display: grid;
  grid-gap: 20px;
  margin: 0 auto $subWidgetSpacingMobile;
  @include breakMin($breakSmall) {
    grid-template: 1fr / repeat(3, 1fr);
    grid-template-areas:
      "big big big"
      "small1 small2 small3"
      "small4 small5 small6";
  }
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
    grid-template: 1fr 1fr / repeat(5, 1fr);
    grid-template-areas:
      "big big small1 small2 small3"
      "big big small4 small5 small6";
  }
  &--big {
    @include breakMin($breakSmall) {
      grid-area: big;
    }
  }
  &--small1 {
    @include breakMin($breakSmall) {
      grid-area: small1;
    }
  }
  &--small2 {
    @include breakMin($breakSmall) {
      grid-area: small2;
    }
  }
  &--small3 {
    @include breakMin($breakSmall) {
      grid-area: small3;
    }
  }
  &--small4 {
    @include breakMin($breakSmall) {
      grid-area: small4;
    }
  }
  &--small5 {
    @include breakMin($breakSmall) {
      grid-area: small5;
    }
  }
  &--small6 {
    @include breakMin($breakSmall) {
      grid-area: small6;
    }
  }
}
