@keyframes fade {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	50% {
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}

.interactive-map {
	position: relative;
	overflow: hidden;
	animation: fade 1.5s;
	svg.earth {
		background-color: $lightGrey;
		transition: $mediumSpeed;
		@include breakMax($breakMediumBefore) {
			height: 400px;
		}
	}
	.hide {
		display: none;
		visibility: hidden;
		width: 0;
		height: 0;
		animation: fade 1s;
		opacity: 0;
		transition: $fast;
	}
	g path {
		fill: $lighterGrey;
	}
	.active-country {
		path {
			fill: $colorPrimary;
			transition: $mediumSpeed;
			opacity: 0.5;
		}
		&:hover,
		&.active {
			cursor: pointer;
			path {
				opacity: 1;
			}
		}
	}
	&--content {
		position: absolute;
		top: 0;
		right: -13px;
		overflow: hidden;
		transition: $mediumSpeed;
		@include breakMin($breakMediumBefore) {
			max-width: 550px;
		}
		input {
			border: none;
			border-left: 1px solid;
			border-radius: 0;
			padding-left: 5px;
			max-width: 0;
			visibility: hidden;
			transition: $mediumSpeed;
			opacity: 0;
			&:focus {
				outline: none;
			}
		}
		&.show {
			background-color: #fff;
			right: 0;
			transition: $mediumSpeed;
			input,
			.interactive-map--search-drop,
			.interactive-map--mobile-close {
				max-width: 550px;
				visibility: visible;
				opacity: 1;
				@include breakMax($breakMediumBefore) {
					max-width: 100%;
				}
			}
		}
		@include breakMax($breakMediumBefore) {
			transform: none;
			left: 0;
		}
	}
	&--office-body {
		margin-bottom: 40px;
	}
	&--office-title {
		margin-bottom: 20px;
	}
	&--search {
		height: auto;
		margin: 0;
		width: auto;
		@include breakMax($breakMediumBefore) {
			height: 100%;
		}
		&-content {
			overflow: hidden;
		}
		&-button {
			width: 45px;
			height: 40px;
			background-color: #fff;
		}
		&-field {
			border-bottom: 1px solid $lighterGrey;
			@include breakMax($breakMediumBefore) {
				border: none;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
		&-drop {
			height: 0;
			overflow: hidden;
			max-height: 100%;
			overflow-y: scroll;
			max-width: 0;
			opacity: 0;
			visibility: hidden;
			transition: $mediumSpeed;
			@include breakMax($breakMediumBefore) {
				border-top: 1px solid $lighterGrey;
			}
		}
		&-list {
			padding-bottom: 45px;
			button {
				border: none;
				background: none;
				font-size: 16px;
				letter-spacing: 0.025em;
				padding: 10px $pagePadding;
				text-align: left;
				width: 100%;
				cursor: pointer;
				&:hover {
					color: $colorPrimary;
					background: $lightGrey;
				}
				@include breakMin($breakNavigation) {
					padding: 10px 30px;
				}
			}
		}
	}
	&--office-video {
		margin: 30px 0;
		.basic-video--download {
			display: none;
		}
	}
	&--office-country {
		display: flex;
		border-bottom: 1px solid $lighterGrey;
		padding: 10px 20px 10px 40px;
		align-items: center;
		@include breakMax($breakMediumBefore) {
			padding: 10px 20px;
		}
	}
	&--office-cta {
		display: inline-block;
		margin-top: 30px;
	}

	.mobile-nav--link.is-back {
		margin: 0;
		display: flex;
		align-items: center;
		&::before {
			color: $darkGrey;
			margin-top: 5px;
		}
	}
	&--office-info {
		padding: 20px 40px;
		@include breakMax($breakMediumBefore) {
			padding: 20px;
		}
	}
	&--office-container {
		width: 550px;
		transition: $mediumSpeed;
		@include breakMax($breakMediumBefore) {
			overflow-y: scroll;
			&.hide {
				display: block;
				visibility: hidden;
				opacity: 0;
				height: 0;
			}
			transition: $mediumSpeed;
			background-color: #fff;
			width: 100%;
			position: fixed;
			top: 60px;
			z-index: 1001;
			bottom: 0;
			height: 100vh;
			left: 0;
			right: 0;
		}
	}
	&--icons {
		position: absolute;
		bottom: 10%;
		display: flex;
		flex-flow: column;
		margin-left: 10px;
		button {
			position: relative;
			border: none;
			background-color: #fff;
			box-shadow: 0 0 30px rgba(black, 0.16);
			width: 25px;
			height: 25px;
		}
		&-zoom-out {
			&:after {
				content: ' ';
				width: 10px;
				height: 2px;
				background-color: $doveGrey;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&-center {
			margin-bottom: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			&::after {
				content: url('/assets-v3/images/icons/centerIcon.svg');
			}
		}
		&-zoom-in {
			&:after {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				content: $icon-plus;
				color: $doveGrey;
				font-size: 12px;
				font-family: $iconFont;
			}
		}
	}
	&--office {
		background: $lightGrey;
		padding: 20px;
		width: 90%;
		margin: 30px auto;
		@include breakMax($breakMediumBefore) {
			width: 100%;
		}
	}
	&--mobile-close {
		display: none;
		@include breakMax($breakMediumBefore) {
			display: inline-block;
			visibility: hidden;
			opacity: 0;
			margin-left: auto;
			height: 30px;
			border: none;
			background: none;
			padding: 0;
			position: relative;
			width: 24px;

			align-self: flex-start;
			& > span {
				position: absolute;
				background: $darkGrey;
				width: 90%;
				height: 2px;

				transform: translateX(-50%);
				&:nth-child(1) {
					transform: translateX(-50%) rotate(45deg);
				}

				&:nth-child(2) {
					transform: translateX(-50%) rotate(-45deg);
				}
			}
		}
	}
	.search--glass:hover,
	.search--glass:focus {
		color: $darkGrey;
	}
	.document-download-partial--download {
		word-break: break-word;
	}
}
