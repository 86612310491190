.loading-overlay {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(white, 0.6);
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	transition: $mediumDelay opacity ease-in-out $fastDelay;
	pointer-events: none;
	opacity: 0;
	svg {
		max-width: 90px;

		animation-name: video-rotate;
		animation-duration: $slowDelay;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		margin-bottom: 10px;
	}
	circle {
		fill: none;
		stroke-width: 10px;
		transition: $mediumDelay stroke-dasharray ease-in-out;
		&:nth-child(1) {
			stroke: $buttonGrey;
		}
		&:nth-child(2) {
			stroke: $colorPrimary;
			stroke-dasharray: 235px;
			stroke-dashoffset: 0;
		}
	}
	&.active {
		opacity: 1;
		pointer-events: all;
		cursor: wait;
	}
}
