.medium-text-on-image {
	display: flex;
	flex-flow: column;
	position: relative;
	justify-content: flex-end;
	padding: 20px 40px 20px 20px;
	@include breakMin($breakMedium) {
		padding: 40px;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0;
		z-index: 1;
		transition: $mediumDelay opacity ease-in-out;
	}
	&:hover,
	&:focus {
		&:before {
			opacity: 0.2;
		}
		&:after {
			right: 10px;
		}
	}
	&:focus:not(:hover) {
		outline: $blockFocusOutline;
	}

	&--content {
		z-index: 2;
	}
	.box-article-content {
		&--description {
			max-width: 80%;
			display: none;
			@include breakMin($breakMedium) {
				display: block;
			}
		}
		&--link {
			margin-top: 10px;
		}
	}
}
