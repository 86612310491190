$fieldsSpacing: 20px;

.ir-search {
	position: relative;
	display: flex;
	flex-flow: column;
	z-index: 1;
	background-color: $lightGrey;
	color: $darkGrey;
	margin: 0 #{-$pagePadding} 50px;
	padding: 0 0 60px;

	@include breakMin($breakLarge) {
		padding-bottom: 90px;
		margin: 0 0 60px 0;
	}

	&--container {
		padding: 0 35px;
		margin: 0 auto;

		@include breakMin($breakMedium) {
			padding: 20px;
		}

		@include breakMin($breakLarge) {
			padding: 0;
		}
	}

	&--title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 40px;

		@include breakMin($breakMedium) {
			margin-bottom: 65px;
		}
	}

	&--form {
		display: flex;
		flex-flow: column;

		@include breakMin($breakMedium) {
			flex-flow: row;
		}
	}
	&--row {
		display: flex;
		align-items: center;
		width: 100%;
		flex-flow: column;
		@include breakMin($breakMediumSmall) {
			flex-flow: row wrap;
		}
	}

	&--input {
		display: flex;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 0 20px;
		background: white;
		border: 1px solid $strokeGrey;
		font-size: 14px;
		&:focus {
			outline: $blockFocusOutline;
		}
	}
	&--input-container {
		position: relative;
		width: 100%;
	}
	&--value-container {
		margin-bottom: 20px;
		width: 100%;
		@include breakMin($breakMediumSmall) {
			width: calc(50% - #{$fieldsSpacing / 2});
			&:nth-child(2n + 1) {
				margin-right: $fieldsSpacing;
			}
		}
		@include breakMin($breakMedium) {
			width: auto;
			flex-grow: 1;
			margin-right: $fieldsSpacing;
			margin-bottom: 0;
		}
	}

	&--calendar-container {
		position: relative;
		@include breakMin($breakMedium) {
			max-width: 300px;
		}
		&:after {
			content: $icon-calendar;
			font-family: $iconFont;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			color: $colorPrimary;
			pointer-events: none;
		}
	}

	&--input-from,
	&--input-to {
		cursor: pointer;
	}

	&--calendar-from,
	&--calendar-to {
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		height: 0;
		@include breakMin($breakMediumSmall) {
			.pika-single {
				position: absolute !important;
				width: 100%;
			}
		}

		// Class added via JS to solve Chrome issue
		&.active {
			// Fixes hovering calendar dates
			z-index: 1;
		}
	}

	&--clear-all {
		padding: 0;
		border: none;
		background: none;
		cursor: pointer;
		font-size: 14px;
		white-space: nowrap;
		color: $colorPrimary;

		&:hover {
			text-decoration: underline;
		}

		&:focus {
			outline: $blockFocusOutline;
		}
	}

	.breadcrumbs {
		&--link,
		&--greyed {
			&::after {
				opacity: 0.57;
			}

			&.is-mobile {
				&:before {
					content: $icon-arrowleft;
					font-family: $iconFont;
					margin-left: -10px;
				}
			}
		}

		&--greyed {
			font-weight: 700;
		}
	}
	.pika {
		&-lendar {
			box-shadow: $menuShadow;
		}
		&-title {
			display: flex;
			align-items: center;
			background: $colorPrimary;
			padding: 15px 30px 0;
			color: white;
		}
		&-single {
			thead {
				background: $colorPrimary;
			}
			abbr {
				color: white;
				opacity: 0.5;
			}
		}
		&-label,
		&-select,
		&-prev::before,
		&-next::before {
			color: white;
		}
	}
}
