.unsmoke-small-text-on-image {
	position: relative;
	display: grid;
	grid-template-areas:
		'bubble content'
		'. content';
	grid-template-columns: 100px 1fr;
	grid-template-rows: 100px auto;
	grid-gap: 0 25px;

	@include breakMin($breakSmall) {
		grid-template-areas: 'bubble';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: end;
	}

	.unsmoke-bubble-wrapper {
		grid-area: bubble;
	}

	.small-text-on-image--content {
		grid-area: content;
		position: static;

		@include breakMin($breakSmall) {
			grid-area: bubble;
			padding: 20px 40px;
		}

		@include breakMax($breakSmall) {
			width: auto;
		}
	}

	.box-article-content--title {
		color: $textBlack;
		min-width: 0;
		width: auto;

		@include breakMin($breakSmall) {
			color: white;
		}
	}

	.box-article-content--reading {
		color: $grey;

		@include breakMin($breakSmall) {
			color: white;
		}
	}

	.box-article-content--link {
		@include breakMax($breakSmall) {
			color: var(--text-black);
		}

		@include breakBetween($breakSmall, $breakSmall) {
			color: white;
		}
	}
}
