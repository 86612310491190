.large-image-text-on-image {
	display: flex;
	flex-flow: column;
	position: relative;
	color: white;
	justify-content: flex-end;
	padding: calc(40px - var(--paddingCompensation));
	width: 100%;
	&:focus:not(:hover) {
		outline: $blockFocusOutline;
	}
	&--content {
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		z-index: 1;
		color: white;
	}
}
