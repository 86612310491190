.market-contact {
	display: flex;
	flex-flow: column;
	margin: 0 -20px;
	padding: 60px 50px 50px;
	background: $lightGrey;
	@include breakMin($breakMediumSmall) {
		margin: 0;
		width: 50%;
	}

	&--office {
		display: flex;
		flex-flow: column;

		& + & {
			margin-top: 40px;
		}
	}

	&--title {
		font-size: 20px;
		margin: 0 0 20px;
	}

	&--address,
	&--email,
	&--telephone1,
	&--telephone2,
	&--fax {
		font-size: 14px;
		line-height: 28px;
		color: $textBlack;
		&:before {
			color: $colorPrimary;
			margin-right: 15px;
			font-size: 18px;
			line-height: 28px;
			font-family: $iconFont;
			[dir="rtl"] & {
				display: inline-block;
				margin-left: 15px;
				margin-right: 0;
			}
		}
	}

	&--email::before {
		content: $icon-mail;
		font-size: 15px;
	}
	&--telephone1::before {
		content: $icon-phone;
		font-size: 18px;
	}
	&--telephone2::before {
		content: $icon-phone;
		font-size: 18px;
	}
	&--fax::before {
		content: $icon-fax;
		font-size: 18px;
	}
}
