.tab-widget {
	&--title-wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		.title {
			font-weight: 400;
		}
	}
	&--tabs {
		margin-bottom: 18px;
		border-bottom: 1px solid $buttonGrey;
		overflow: auto;
		white-space: nowrap;
		// hiding scrollbar for tabs
		// firefox
		scrollbar-width: none;
		// chrome
		&::-webkit-scrollbar {
			display: none;
		}
		@include breakMin($breakMedium) {
			display: flex;
		}
	}
	&--tab {
		padding-bottom: 18px;
		border: none;
		outline: none;
		color: $grey;
		background: none;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 0;
		display: none;
		@include breakMin($breakMedium) {
			width: 100%;
			white-space: normal;
			display: block;
		}
		@include breakMax($breakMedium) {
			width: auto;
			min-width: 30%;
		}
		@include breakMax($breakXtraSmall) {
			min-width: 40%;
		}
		&:disabled {
			border-bottom: 2px solid $colorPrimary;
			color: $colorPrimary;
			display: block;
			margin: auto;
			@include breakMin($breakMedium) {
				display: inline-block;
			}
			}
		&:focus {
			color: $colorPrimary;
		}
	}
	&--nav-dots {
		display: flex;
		margin: 0 35px;
		justify-content: center;
		padding: 0 0 15px;
		@include breakMin($breakMedium) {
			display: none;
		}
	}
	&--nav-dot {
		margin: 0 5px;
		padding: 0;
		width: 7px;
		height: 7px;
		background: $textGrey;
		transition: $fastDelay background ease-in-out,
		$fastDelay transform ease-in-out;
		border: none;
		color: transparent;
		font-size: 0;
		&:disabled {
			transform: scale(1.43);
			background: $backgroundPrimary;
		}
		&:focus {
			outline: $blockFocusOutline;
			background: white;
		}
		@include breakMin($breakMedium) {
			&:last-child {
				display: none;
			}
		}
	}
	&--nav-btn {
		display: block;
		position: absolute;
		top: 50%;
		z-index: 11;
		padding: 0;
		background-color: transparent;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 10px;
		border: 0;
		color: $colorPrimary;
		border-radius: 100%;
		transform: translate(0, -50%);
		font-weight: bold;
		&::after {
			font-family: $iconFont;
			font-size: 13px;
		}
		&--inverse {
			color: white;
		}
		@include breakMin($breakMedium) {
			display: none;
		}
	}
	&--prev-btn {
		left: 15px;
		&::after {
			content: $icon-arrowleft;
		}
	}
	&--next-btn {
		right: 15px;
		&::after {
			content: $icon-arrowright;
		}
	}
	&--content {
		position: relative;
		background-color: $lightGrey;
		width: 100%;
		min-width: 100%;
		transform: translateX(calc(var(--current-slide) * -100%));
		transition: $slowerDelay transform ease-in-out;
		&-inner {
			position: relative;
			padding: 20px 50px;
			@include breakMin($breakMedium) {
				padding: 70px 100px;
			}
		}
		&-title {
			margin-bottom: 20px;
			font-size: 32px;
			font-weight: 400;
		}
		&-wrapper {
			position: relative;
			--current-slide: 0;
			display: flex;
			max-width: 100%;
			overflow: hidden;
			img {
				height: auto;
			}
		}
	}
	.arrow-link {
		display: block;
		margin: 20px 0;
		@include breakMin($breakMedium) {
			margin-bottom: 0px;
		}
		text-transform: uppercase;
		font-weight: 400;
		color: black;
	}
	.tab-widget--content-inner,
	.arrow-link {
		&.white-text {
			color: white;
		}
	}
}
