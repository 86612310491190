.top-employer {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 250px;
	padding: 20px 20px 30px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
	border-radius: 4px;

	&--title {
		margin-top: 25px;
		text-align: center;
		font-size: 50px;
		font-weight: 400;
		color: $colorPrimary;
	}

	&--subtitle {
		font-size: 16px;
		text-align: center;
		color: $colorPrimary;
	}
}
