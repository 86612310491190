.V3-50-50-blue {
	background: $colorPrimary;
	display: flex;
	padding: 90px 0;
	margin: 0 -$pagePadding;
	@include breakMin($breakNavigation) {
		margin: 0;
		padding: 95px 0 130px;
	}
	&--content {
		display: flex;
		flex-flow: column;
		margin: 0 auto;
		padding: 0 $pagePadding;
		@include breakMin($breakSmall) {
			justify-content: space-between;
			flex-flow: row;
		}
		@include breakMin($breakNavigation) {
			padding: 0;
		}
	}
	@include breakMax($breakSmallBefore) {
		.V3-50-50--space:first-child {
			padding-bottom: 90px;
		}
	}
}
