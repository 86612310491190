.ie-warning {
  display: none;
  position: absolute;
  bottom: -45px;
  left: 0;
  width: 100%;
  height: 45px;
  background: $darkGrey;
  justify-content: center;
  align-items: center;

  &--content {
    position: relative;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 14px;
    width: 70%;
  }

  a,
  &--close-label {
    text-decoration: underline;
    color: white;
  }

  button {
    padding: 0;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
  }

  &--close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;

    &:before {
      content: $icon-cross;
      font-family: $iconFont;
      font-size: 15px;
    }
  }
}