.carousel-highlight {
	--color-black: #{$rgbBlack};
	--color-grey: #{$rgbGrey};
	$self: &;

	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	margin: 0 auto $subWidgetSpacingMobile;

	@include breakMin($breakMedium) {
		margin-bottom: $subWidgetSpacing;
	}

	&--title {
		min-width: 120px;
		font-size: 30px;
		color: $textColor;
		font-weight: normal;
		margin-bottom: 35px;

		@include breakMin($breakSmall) {
			width: calc(100% - 300px);
		}
	}

	&--intro {
		margin-bottom: 35px;
		@include breakMin($breakMedium) {
			width: 66.66%;
		}
		#{$self}--title {
			margin-bottom: 20px;
		}

		& + #{$self}--nav {
			@include breakMin($breakSmall) {
				margin-left: auto;
			}
			@include breakMin($breakMedium) {
				margin-left: 0;
			}
		}
		@include breakMax($breakMediumBefore) {
			margin-bottom: 0;
		}
	}
	&--description {
		color: $textColor;
		font-size: 16px;
		line-height: 26px;
	}
	&--previous,
	&--next {
		display: inline-flex;
		align-items: center;
		font-size: 12px;
		text-transform: uppercase;
		background: none;
		border: none;
		padding: 0;
		letter-spacing: 0.15em;
		cursor: pointer;
		transition: $fastDelay color ease-in-out;

		&:hover {
			color: $colorPrimary;
		}

		&.inactive {
			color: $textGrey;
			pointer-events: none;
		}
	}

	&--previous {
		&:before {
			content: $icon-longarrowleft;
			font-family: $iconFont;
			font-size: 10px;
			margin-right: 10px;
		}

		&:after {
			content: '';
			display: inline-block;
			height: 13px;
			width: 0;
			border-right: 1px solid $buttonGrey;
			margin: 0 30px;
		}
	}

	&--next {
		&:after {
			content: $icon-longarrowright;
			font-family: $iconFont;
			font-size: 10px;
			margin-left: 10px;
		}
	}

	&--content {
		position: relative;
		width: min-content;
		display: flex;
		margin: 0 -#{$pagePadding};
		padding-left: #{$pagePadding};
		--offset: 0;
		--direction-factor: 1;

		@include breakMin($breakXtraSmall) {
			width: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
		}
		.with-overflow & {
			overflow: visible;
		}
		.small-image-above-title--description {
			display: none;
			@include breakMin($breakMedium) {
				display: block;
			}
		}
		// to prevent drag and click on links when panning on desktop
		* {
			user-select: none;
		}
		&.is-panning {
			cursor: grab;
			*,
			.faq-block {
				pointer-events: none;
			}
		}
		& > div {
			transform: translateX(calc(var(--offset) * var(--direction-factor) * 1px));
		}
		[dir="rtl"] & {
			--direction-factor: -1;
		}
	}
	&--bottom {
		position: relative;
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
		width: 100%;
	}
	&--bottom-link {
		position: absolute;
		right: 0;
		text-align: right;
	}

	.small-image-above-title,
	.faq-block {
		margin-right: 25px;
		min-width: 215px;
		outline: none;

		[dir="rtl"] & {
			margin-left: 25px;
			margin-right: 0;
		}

		> button {
			background: none;
			border: none;
		}

		@include breakMin($breakXtraSmall) {
			min-width: calc(50% - (25px / 2));
		}

		@include breakMin($breakMedium) {
			min-width: calc(33.33% - (50px / 3));
		}
	}
}
