.scroll-up {
	position: fixed;
	right: 10px;
	bottom: 40px;
	width: 50px;
	height: 50px;
	background: white;
	border-radius: 50%;
	transform: translateY(100px);
	opacity: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	box-shadow: $menuShadow;
	outline: none;
	transition: $slowerDelay opacity ease-in-out,
		$slowerDelay transform ease-in-out;
	&:before {
		content: $icon-tailarrowright;
		font-family: $iconFont;
		font-size: 15px;
		color: $colorPrimary;
		transform: rotate(-90deg) translate(1px, -1px);
	}
	&.active {
		transform: translateY(0);
		opacity: 1;
	}
	@include breakMin($breakMedium) {
		display: none;
	}
}
