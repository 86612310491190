// hardcoded sqrt(2) until our version of sass supports it natively
$twoSqrt: 1.414213562373095;

//
// Typography
//
// Font
$fallbackFont: 'Helvetica Neue, Helvetica, Arial, sans-serif';

@import './variables-asian';
$latoFont: 'Lato', unquote($fallbackFont);
$robotoFont: 'Roboto', unquote($fallbackFont);
$rubikFont: 'Rubik', unquote($fallbackFont);
$sarabunFont: 'Sarabun', unquote($fallbackFont);

$iconFont: 'icomoon';
$mainFont: var(--main-font);

//
// Colors:
// This one will be changed by the theme
//
// Tool to name hex colors: http://chir.ag/projects/name-that-color
// RGB to HEX converter: https://www.rapidtables.com/convert/color/hex-to-rgb.html or simply google

// Pure values to be used with RGB
$bayOfManyBlue: 31, 60, 132;
$navyBlue: 0, 116, 208;
$cobaltBlue: 0, 61, 165;
$eggplantViolet: 165, 24, 144;
$lochmaraBlue: 0, 116, 192;
$pineGreen: 2, 131, 110;
$schoolBusYellowRGB: 248, 217, 0;
$milanoRed: 191, 13, 62;
$sampoernaGold: 174, 139, 57;

$tarLightPink: 246, 240, 234;
$tarPink: 242, 233, 226;
$nicotineGreen: 77, 143, 47;
$combustionGrey: 124, 121, 118;
$nicotineLightGreen: 38, 171, 128;
$combustionGold: 239, 222, 220;
$combustionKhaki: 195, 159, 136;
$combustionCararra: 233, 232, 225;
$combustionLightGreen: 38, 163, 155;
$combustionOrange: 246, 169, 27;
$tarBlack: 210, 121, 64;
$tarDarkGrey: 84, 84, 82;
$tarGrey: 124, 120, 118;
$pmiWhite: 255, 255, 255;
$pmiGrey: 246, 246, 246;
$rgbGrey: 110, 110, 110;
$rgbBlack: 38, 38, 38;
$christineOrange: 235, 105, 11;
$socialPink: 179, 37, 114;
$socialPinkLight: 209, 124, 171;
$socialPinkLightSecondary: 217 138 168;
$environmentGreen: 2 131 110;
$environmentGreenLight: 103 181 168;
$environmentGreenLightSecondary: 126 166 159; 
$governanceBlue: 0 77 113;
$transparent: 255, 0, 0, 0;

$pinkHover: #d0cac4;
$greenHover: #276909;
$greyHover: #565350;
$lightGreenHover: #00855a;
$goldHover: #c9b8b6;
$khakiHover: #9d7962;
$cararraHover: #c3c2bb;
$combustionLightGreenHover: #007d75;
$orangeHover: #d08300;
$blackHover: #ac531a;
$darkGreyHover: #2e2e2c;
$christineOrangeHover: #d15d09;

$biscayBlue: #172c61;
$endeavourBlue: #0062a3;
$gradientLightBlue: #1d9bf0;
$lightBlue: #93c7e3;
$lightGrey: #f6f6f6;
$lighterGrey: #dddddd;
$lightGreyDarker: #f0f0f0;
$medlightGrey: #ebebeb;
$mediumGrey: #959595;
$grey93: #ededed;
$grey: #6e6e6e;
$buttonGrey: #cccccc;
$darkGrey: #393939;
$mailGrey: #535353;
$doveGrey: #646464;
$textBlack: #262626;
$red: #dd1414;
$green: #0c8925;
$lightGreen: #12B76A;
$lightBorder: #efefef;
$strokeGrey: #e5e5e5;
$blueFacebook: #0074c2;
$blueTwitter: #1ea1f2;
$blueLinkedin: #0077b5;
$blueVK: #5182b9;
$pinkInstagram: #e13866;
$lightOrange: #f8694d;
$schoolBusYellow: #ffdc00;
$bean: #801027;

$gradientBlack: rgba(0, 0, 0, 0.8);
$gradientColor: var(--gradient-color);

$colorPrimaryValue: var(--color-primary);
$colorSecondaryValue: var(--color-secondary, var(--color-primary));
// RGB in uppercase to avoid SASS processing
$colorPrimary: RGB($colorPrimaryValue);
$colorSecondary: RGB($colorSecondaryValue);
$colorPrimaryHover: var(--color-primary-hover);

// contextual colors
$backgroundWhite: var(--background-white);
$backgroundGrey: var(--background-grey);
$backgroundPrimary: var(--background-primary);
$textWhite: var(--text-white);
$textColor: var(--text-black);
$textLighterBlack: var(--text-lighter-black);
$textGrey: var(--text-grey);
$textPrimary: var(--text-primary);

// delays

$fasterDelay: 0.1s;
$fastDelay: 0.2s;
$mediumDelay: 0.4s;
$slowDelay: 0.6s;
$slowerDelay: 0.8s;
$evenSlowerDelay: 1s;

// transitions

$fast: $fastDelay all ease-in-out;
$mediumSpeed: $mediumDelay all ease-in-out;
$slow: $slowDelay all ease-in-out;

// shadows

$menuShadow: 0 8px 10px rgba(black, 0.27);
$menuArrowsShadow: 0 0 10px rgba(black, 0.27);

// outlines
$outlineColor: $textBlack;
$outlineWidth: 4px;
$blockFocusOutline: $outlineWidth solid $outlineColor;

// layouts and widgets spacings
$subWidgetSpacingMobile: 50px;
$subWidgetSpacing: 90px;

// breakpoints

$breakXXtraSmall: 380px;
$breakXtraSmall: 480px;
$breakSmall: 640px;
$breakSmallBefore: $breakSmall - 1px;
$breakMediumSmall: 700px;
$breakMediumSmallBefore: $breakMediumSmall - 1px;
$breakMedium: 960px;
$breakMediumBefore: $breakMedium - 1px;
$breakLarge: 1200px;
$breakLargeBefore: $breakLarge - 1px;
$breakContent: 1210px;
$breakContentBefore: $breakContent - 1px;
$breakNavigation: 1265px;
$breakNavigationBefore: $breakNavigation - 1px;
$breakXtraLarge: 1600px;
$breakXtraLargeBefore: $breakXtraLarge - 1px;
$breakSuperLarge: 2000px;
$breakSuperLargeBefore: $breakSuperLarge - 1px;
$breakMegaLarge: 2600px;
$breakMegaLargeBefore: $breakMegaLarge - 1px;

// lengths

$wrapperMax: 1170px;
$smallWrapperMax: 690px;
$pagePadding: 20px;

// Z-index
$z-skiplink: 9999;
$z-world-loading: 3001;
$z-world: 3000;
$z-market-redirect: 1001;
$z-nav: 1002;
$z-social-ios-fix: 100;
$z-scroll-up-fix: 1000;
$z-social-share: 10;

// lost stuffs
$mobile-portrait-screen: 480px !default;
$lost-medium-screen: 768px !default;
$lost-large-screen: 992px !default;
$lost-big-screen: 1200px !default;

$lost-grid-layout-gutter: 20px !default;

$lost-grid-layout-3columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-4columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-5columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-6columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-39columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-48columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-66columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-84columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-93columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-363columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-23232columns-gutter: $lost-grid-layout-gutter !default;
$lost-grid-layout-flexrow-gutter: $lost-grid-layout-gutter !default;

$lost-grid-layout-centered-column-max-width: 1024px !default;

$lost-grid-layout-container-width: 1190px !default;
$lost-grid-layout-container-padding: 20px !default;
$lost-grid-layout-container-padding-medium-screen: 40px !default;
$lost-grid-layout-container-padding-big-screen: 60px !default;

// Shared across widgets/components
@import './variables-market-redirect';

//
// CSS variables
//
:root {
	--color-primary: #{$lochmaraBlue};
	--color-primary-hover: #{$endeavourBlue};
	--gradient-color: #{$gradientBlack};
	--main-font: #{$latoFont};
	--paddingCompensation: 0px;

	// contextuals colors
	--background-white: white;
	--background-grey: #{$grey};
	--background-primary: #{$colorPrimary};
	--text-white: white;
	--text-black: #{$textBlack};
	--text-lighter-black: #{$darkGrey};
	--text-grey: #{$grey};
	--text-primary: #{$colorPrimary};
}

// TODO: remove once BE adds lang attribute to <html>
.font-secondary {
	--main-font: #{$robotoFont};
}
