.ir-search-results {
    &--item {
        border-bottom: 1px solid $buttonGrey;
        margin-bottom: 30px;
        padding-bottom: 30px;

        .no-results-found {
            font-size: 18px;
        }
    }

    &--date-container {
        margin-bottom: 15px;
    }

    &--date {
        font-size: 11px;
        color: $grey;
    }

    &--title {
        font-size: 16px;
        color: $textBlack;
    }
}