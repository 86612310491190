.key-figure {
	$self: &;
	&.tns-slider {
		display: flex;
	}
	@include breakMin($breakMedium) {
		display: flex;
		justify-content: space-between;
	}

	&--element {
		margin: 0 auto 60px;
		@include breakMin($breakMedium) {
			margin: 0 20px 0 0;
			max-width: 290px;
		}

		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}
	}

	img {
		height: 80px;
		width: auto;
		margin: 0 auto 20px;
		display: block;
		transition: $mediumDelay transform ease-in-out;
	}

	&--title {
		margin-bottom: 20px;
		font-size: 43px;
		text-align: center;
		color: $colorPrimary;

		span {
			font-size: 17px;
			margin-left: 5px;
			margin-top: 17px;
		}
	}
	a:focus {
		outline: none;

		img {
			transform: scale(1.1);
			transition: $fastDelay transform ease-in-out;
		}
	}

	&--description {
		text-align: center;
		color: $grey;
	}
	&--next.carousel-highlight-nav--next,
	&--previous.carousel-highlight-nav--previous {
		@include breakMax($breakMedium) {
			display: none;
		}
	}
	&--next {
		margin-left: 25px;
	}
	&--previous {
		margin-right: 25px;
	}
	&--controls {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
