.tbe-filters {
	&--section-title {
		margin-bottom: 15px;
	}
	&--section {
		margin-bottom: 45px;
		opacity: 0.3;
		pointer-events: none;
		transition: $fastDelay opacity ease-in-out;
		&.active {
			opacity: 1;
			pointer-events: all;
		}
		.tbe-drop--field,
		.tbe-variables {
			margin-bottom: 20px;
		}
	}
	&--button {
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		padding: 10px 25px;
		background: white;
		border: 1px solid $buttonGrey;
	}
}
