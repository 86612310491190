.layout-5-columns {
	--columns-count: 1;
	display: grid;
	grid-template: auto / repeat(var(--columns-count), 1fr);
	grid-gap: 20px;
    @include breakMin($breakSmall) {
		--columns-count: 2;
    }
	@include breakMin($breakMedium) {
		--columns-count: 3;
	}
	@include breakMin($breakLarge) {
		--columns-count: 5;
	}
}
