.scrollbar-style {
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background: $lightGrey;
	}
	&::-webkit-scrollbar-thumb {
		background: $buttonGrey;
	}
}
