// Typography

@import './fonts';
@import './fonts-icomoon';
@import './colors';
@import './icons';
@import './typography';
@import './utils';
@import './layout';
@import './keyframes';
@import './elements';
@import './tag-element';
@import './checkbox';
@import './forms';
@import './scrollbars';
@import './file-icon';
@import './skiplink';
@import './_wysiwyg.scss';
@import './bigSearchButton';

//
* {
	box-sizing: border-box;
}

body {
	color: $textBlack;
	font-family: $mainFont;
	-webkit-text-size-adjust: 100%; // prevents IOS from changing font size
	&.no-scroll {
		overflow-y: hidden;
		touch-action: none;
		-ms-touch-action: none;
		@media (hover: none) and (pointer: coarse) {
			overflow: hidden;
		}
	}
	//requries scroll event
	&.no-scroll-ios {
		position: fixed;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
	}
}

button {
	cursor: pointer;
}

button,
input {
	font-family: $mainFont;
}

a {
	color: $colorPrimary;
}

// override ui kit mess
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $mainFont;
}

table caption {
	@include sr-only();
}

.customRtlIcon {
	position: relative;
}
.customRtlIcon:before {
	content: 'RTL';
	display: block;
	position: absolute;
	padding: 2px 4.6px;
	left: 26px;
	bottom: 22px;
	background: #ff404e;
	color: #fff;
	font-size: 8px;
}
