// dropdown shadow and arrow settings
$shadowBlur: 20px;
$arrowHeight: 16px;
$arrowMargin: 30px;

$shadow: 0 0 #{$shadowBlur} rgba($darkGrey, 0.3);
.world-widget {
	position: relative;
	min-width: max-content;
	@include breakMin($breakNavigation) {
		margin-right: 10px;
	}
	$self: &;
	&--link-image {
		width: auto;
		height: auto;
		transition: $mediumSpeed;
		filter: invert(17%) sepia(83%) saturate(0%) hue-rotate(146deg)
			brightness(92%) contrast(87%);
		margin-left: 10px;
		[dir='rtl'] & {
			margin-left: 0;
			margin-right: 10px;
		}
	}
	&--link-container {
		min-width: max-content;
	}
	&--link-new {
		display: none;
		padding: 0;
		min-width: max-content;
		align-self: center;
		position: relative;
		text-transform: uppercase;
		color: $textBlack;
		background-color: transparent;
		border: none;
		@include breakMin($breakNavigation) {
			margin-top: 15px;
			display: flex;
		}
		font-size: 12px;
		max-height: 100%;
		transition: $fast;
		margin-left: 10px;
		s [dir='rtl'] & {
			margin-right: 10px;
			margin-left: 0;
		}
		&:after {
			content: ' ';
			display: block;
			height: 100%;
			width: 100%;
			transition: 0.2s all ease-in-out;
			border-bottom: 4px solid transparent;
			position: absolute;
			left: 0;
			right: 0;
			top: 16px;
			cursor: pointer;
		}
		&:hover,
		&:focus,
		&.active {
			&:after {
				border-color: $colorPrimary;
			}
			color: $colorPrimaryHover;
			outline: none;
			.world-widget--link-image {
				transition: $mediumSpeed;
				filter: invert(36%) sepia(70%) saturate(6170%)
					hue-rotate(188deg) brightness(91%) contrast(101%);
			}
		}
		button:focus + & {
			&::before {
				opacity: 0.3;
			}
			&::after {
				color: $colorPrimary;
			}
		}
	}
	&--link-old {
		display: none;
		align-self: center;
		position: relative;

		@include breakMin($breakNavigation) {
			display: flex;
		}

		max-height: 100%;
		color: $grey;
		transition: $fast;
		margin-left: 10px;

		[dir='rtl'] & {
			margin-right: 10px;
			margin-left: 0;
		}

		&:hover,
		&:focus {
			color: $colorPrimaryHover;
			outline: none;
		}

		&::before {
			content: $icon-world;
			font-size: 30px;
			font-family: $iconFont;
			transition: $fastDelay opacity ease-in-out;
		}
		&::after {
			content: $icon-droparrow;
			font-family: $iconFont;
			font-size: 12px;
			color: transparent;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(180deg);
			transition: $fastDelay color ease-in-out,
				$fastDelay transform ease-in-out;
			.open & {
				transform: translate(-50%, -50%) rotate(0deg);
			}
		}
		button:focus + & {
			&::before {
				opacity: 0.3;
			}
			&::after {
				color: $colorPrimary;
			}
		}
	}
	&--fold-button {
		@include breakMax($breakNavigationBefore) {
			// avoid focus on mobile
			display: none;
		}
	}
	&--drop {
		display: none;
		@include breakMin($breakNavigation) {
			display: flex;
			flex-flow: column;
			position: absolute;
			right: 0;
			top: 55px;
			min-width: 280px;
			background: white;
			z-index: $z-world;
			transition: $slowDelay box-shadow ease-in-out;
			$drop: &;
			&-arrow {
				@include shadowedArrow(
					$shadowBlur,
					$arrowHeight,
					$arrowMargin,
					top,
					right
				);
				z-index: 1;
				pointer-events: none;
				[dir='rtl'] & {
					left: 10px;
					right: unset;
				}
				&::before {
					background: white;
					transition: $mediumDelay transform ease-in-out $fasterDelay;
				}
				.open & {
					&::before {
						box-shadow: $shadow;
						transform: rotate(45deg);
						transition: $mediumDelay transform ease-in-out;
					}
				}
			}
			[dir='rtl'] & {
				left: 0;
				right: unset;
			}
			&-container {
				height: 0;
				transition: $mediumDelay height ease-in-out;
				overflow: hidden;
			}

			.open & {
				box-shadow: $shadow;
				transition: $mediumDelay box-shadow ease-in-out;
			}
		}
	}
	&--drop-old {
		right: -25px;
	}
	.loading-overlay {
		z-index: $z-world-loading;
		svg {
			max-width: 70px;
		}
	}
	.search-field-drop--field {
		width: calc(100% - 30px);
	}
}
