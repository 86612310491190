.quote-author {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-flow: column;
  align-items: center;
  @include breakMin($breakMedium) {
    display: block;
    line-height: 16px;
    font-size: 12px;
  }
  &--name {
    font-style: normal;
    color: $darkGrey;
  }
  &--comma {
    display: none;
    @include breakMin($breakMedium) {
      display: inline;
    }
  }
  &--position {
    font-style: italic;
    color: $grey;
  }
  &.break-sooner {
    @include breakMin($breakSmall) {
      display: block;
      line-height: 16px;
      font-size: 12px;
    }
    .break-sooner &--comma {
      @include breakMin($breakSmall) {
        display: inline;
      }
    }
  }
}
