.ir-dividend {
	&--title {
		font-size: 30px;
		margin-bottom: 35px;
		font-weight: normal;
	}
	iframe {
		width: 100%;
		// arbitrary height to fit the content
		// (mignt need to change depending of the iframe)
		height: 530px;
	}
}
