@mixin wysiwygTypography() {
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
	color: $darkGrey;
}

.wysiwyg,
.kendo-content-block {
	@include wysiwygTypography();

	.wysiwyg-border {
		padding: 0 6px;
		border: 2px solid RGB(var(--color-primary));
	}

	a {
		font-weight: bold;
		text-decoration: underline;
	}

	li {
		margin-bottom: 24px;
		padding-left: 30px;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
		}
	}

	ul > li::before {
		content: '\2022';
		color: $colorPrimary;
		top: 2px;
	}

	ol > li {
		counter-increment: (list);

		&::before {
			content: counter(list) '.';
			font-size: 12px;
			font-weight: bold;
			top: 4px;
		}
	}

	p,
	h2,
	h3 {
		line-height: 24px;
	}

	p {
		@include wysiwygTypography();
		margin: 0 0 36px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2,
	h3 {
		margin: 0 0 18px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 16px;
	}

	table {
		max-width: 100%;

		td {
			border: 1px black solid;
			padding: 10px;
			text-align: center;

			&:first-child {
				text-align: left;
			}
		}

		&.no-border {
			td {
				border-color: transparent;
			}
		}
	}

	sup {
		font-size: 8px;
	}
}
.kendo-content-block {
	li {
		font-size: 18px;
		line-height: 30px;
	}
	ol > li:before {
		top: 3px;
	}
}

.wysiwyg.table-responsive {
	@media (max-width: 768px) {
		table {
			width: 100%;
			p {
				font-size: 12px;
				line-height: 1.2;
				border-collapse: separate;
			}

			td {
				padding: 0 4px;
			}
		}
	}
}
