.big-search-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	height: 0;
	transform: translate(-50%, 50%);
	padding: 0;
	font-size: 0;
	background: $colorPrimary;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: transparent;
	border: none;
	pointer-events: none;
	border-radius: 50%;
	cursor: pointer;
	transition: $mediumDelay height ease-in-out, $fastDelay font-size ease-in-out, $fastDelay color ease-in-out,
		$mediumDelay border-radius ease-in-out, $mediumDelay padding ease-in-out;
	&:before {
		content: $icon-search;
		font-family: $iconFont;
		margin: 0;
		display: flex;
		transition: $fastDelay margin ease-in-out, $mediumDelay opacity ease-in-out, $mediumDelay font-size ease-in-out;
		font-size: 0;
		opacity: 0;
	}
	&.active {
		height: 60px;
		font-size: 14px;
		border-radius: 30px;
		padding: 0 50px;
		pointer-events: all;
		color: white;
		transition: $fastDelay height ease-in-out, $slowDelay font-size ease-in-out, $slowDelay color ease-in-out,
			$fastDelay border-radius ease-in-out, $fastDelay padding ease-in-out;
		&:before {
			margin-top: 3px;
			margin-right: 20px;
			font-size: 20px;
			opacity: 1;
			transition: $mediumDelay margin ease-in-out, $fastDelay opacity ease-in-out,
				$fastDelay font-size ease-in-out;
			[dir="rtl"] & {
				margin-left: 20px;
				margin-right: 0;
			}
		}
	}
	&:hover,
	&:focus {
		background: $colorPrimaryHover;
		outline: none;
		&:before {
			margin-right: 30px;
		}
	}
}
