.latest-press-releases {
	display: flex;
	flex-flow: column;
	background: $lightGrey;
	margin: 0 -#{$pagePadding};
	padding: 30px #{$pagePadding} 0;
	@include breakMin($breakLarge) {
		margin-right: calc((-100vw + #{$wrapperMax}) / 2);
		margin-left: 0;
		padding: 85px calc((100vw - #{$wrapperMax}) / 2) 0 0;
	}
	overflow: hidden;

	&--content {
		max-width: 580px;
		width: 100%;
		margin: 0 auto;
		@include breakMin($breakLarge) {
			width: 100%;
			margin: 0 0 0 auto;
		}
	}
	&--head {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 40px;
		flex-flow: column;
		@include breakMin($breakLarge) {
			flex-flow: row;
		}
	}
	&--title {
		font-size: 18px;
		line-height: 27px;
		font-weight: 400;
		color: $grey;
	}
	&--releases {
		display: flex;
		flex-flow: column;
	}
	&--link {
		font-size: 12px;
	}
}
.news-item {
	background: white;
	border-radius: 4px;
	border: 1px solid $lightBorder;
	margin-bottom: -8px;
	padding: 20px 35px 28px;
	width: 100%;
	cursor: pointer;
	transition: $mediumDelay box-shadow ease-in-out,
		$mediumDelay padding ease-in-out, $mediumDelay margin ease-in-out,
		$mediumDelay width ease-in-out;

	&--head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
	}
	&--date {
		color: $grey;
		text-transform: none;
		font-weight: 400;
	}
	&--category {
		color: $textBlack;
	}
	&--top-link {
		display: inline-block;
		color: $mediumGrey;
		font-size: 23px;
		opacity: 0;
		pointer-events: none;
		transition: $mediumDelay color ease-in-out, $mediumDelay opacity ease-in-out,
			$mediumDelay transform ease-in-out;
		&:before {
			content: $icon-circlelink;
			font-family: $iconFont;
		}
		&.download:before {
			content: $icon-circledownload;
		}
		.active & {
			color: $colorPrimary;
			transform: scale(1.3) translateX(-2px);
			opacity: 1;
			pointer-events: all;
		}
	}
	&--title {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		margin-bottom: 25px;
		transition: $mediumDelay color ease-in-out;
		color: $darkGrey;
		.news-item:hover & {
			color: $textBlack;
		}
	}
	&--description {
		color: $grey;
	}
	&--format {
		padding-top: 40px;
		color: $grey;
	}
	&.active {
		position: relative;
		z-index: 1;
		margin: -8px -5px;
		width: calc(100% + 10px);
		box-shadow: 0 -5px 30px rgba(black, 0.16);
		padding: 20px 40px 28px;
	}
}
