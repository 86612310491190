.speech-bubble {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	border-radius: 25px;
	svg {
		position: absolute;
		height: 25px;
		right: 25px;
		bottom: -25px;
		width: 45%;
	}

	&.mirrored {
		svg {
			left: 25px;
			right: auto;
			transform: scaleX(-1);
		}
	}
}
