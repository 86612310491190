.news-and-press-releases {
	$self: &;
	padding-top: 80px;
	&--title,
	&--article-count {
		margin-bottom: 25px;
	}
	&--title {
		font-weight: 500;
		font-size: 28px;
	}
	&--article-count {
		color: $grey;
		font-weight: 300;
	}
	&--lists-wrapper {
		display: flex;
		flex-direction: column;
		@include breakMin($breakLarge) {
			flex-direction: row;
			justify-content: space-between;
		}
		&.one-column-layout {
			#{$self}--lists {
				width: 100%;
			}
			flex-direction: column;
			#{$self}--input-container:first-child {
				@include breakMin($breakMediumSmall) {
					flex-basis: calc(40% - 10px);
				}
			}
			#{$self}--input-container:nth-child(2),
			#{$self}--input-container:nth-child(3) {
				@include breakMin($breakMediumSmall) {
					flex-basis: calc(30% - 10px);
					margin-top: 0;
				}
			}
		}
	}
	&--lists {
		margin-bottom: 20px;
		position: relative;
		@include breakMin($breakLarge) {
			width: calc(50% - 14px);
			&.active {
				width: 100%;
			}
		}
	}
	&--header {
		display: flex;
		justify-content: center;
		flex-direction: column;
		min-height: 70px;
		padding: 0 22px;
		color: white;
		background-color: $colorPrimary;
	}
	.search-header {
		position: relative;
		margin-bottom: 30px;
		background-color: $lightGrey;
		color: #000;
		.big-search-button {
			&:hover,
			&:focus {
				background-color: $colorPrimary;
			}
		}
	}
	&--icon-press,
	&--icon-news {
		&:before {
			font-family: $iconFont;
			margin-right: 10px;
			font-size: 25px;
			[dir="rtl"] & {
				margin-left: 10px;
			}
		}
	}
	&--icon-press {
		&:before {
			content: $icon-press;
		}
	}
	&--icon-news {
		&:before {
			content: $icon-news;
		}
	}
	&--title-container {
		width: 100%;
		display: flex;
		min-height: 70px;
		align-items: center;
	}
	&--form-container {
		position: relative;
		max-height: 0;
		transition: $mediumSpeed;
		opacity: 0;
		visibility: hidden;
		&.active {
			visibility: visible;
			height: auto;
			max-height: 300px;
			opacity: 1;
		}
	}
	&--search-button {
		margin-left: auto;
		background: none;
		border: none;
		color: $colorPrimary;
		font-size: 14px;
		&:after {
			content: " ";
			border: solid $colorPrimary;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			margin-left: 10px;
			transform: rotate(-135deg);
			transition: $mediumSpeed;
		}
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: auto;
			&:after {
				margin-left: 0;
				margin-right: 10px;
			}
		}

		&.arrow-down:after {
			transform: rotate(45deg);
		}
	}
	&--row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 40px 0 15px 0;
	}
	&--input {
		display: flex;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 0 20px;
		background: white;
		border: 1px solid $strokeGrey;
		font-size: 14px;
		&:focus {
			outline: $blockFocusOutline;
		}
		&::placeholder {
			color: #000;
			opacity: 0.7;
			font-size: 16px;
		}
	}
	&--input-container:first-child,
	&--button-container {
		flex-basis: 100%;
		text-align: center;
	}
	&--input-container:nth-child(2),
	&--input-container:nth-child(3) {
		flex-basis: 100%;
		margin-top: 20px;
		@include breakMin($breakMediumSmall) {
			flex-basis: calc(50% - 10px);
			margin-top: 40px;
		}
	}
	&--clear-button {
		opacity: 0;
		visibility: hidden;
		color: $colorPrimary;
		border: none;
		background: none;
		padding: 0;
		margin: 10px 0 50px 0;
		align-self: center;
		font-size: 14px;
		cursor: pointer;
		transition: $fast;
		@include breakMin($breakMediumSmall) {
			margin: 30px 0 50px 0;
		}
		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
	&--calendar-container {
		position: relative;
		&::before {
			content: $icon-calendar;
			font-family: $iconFont;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			color: $colorPrimary;
			pointer-events: none;
			[dir="rtl"] & {
				right: auto;
				left: 15px;
			}
		}
	}
	&--calendar-from,
	&--calendar-to {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		height: 0;
		transition: $fast;
		@include breakMin($breakMediumSmall) {
			.pika-single {
				position: absolute !important;
				width: 100%;
			}
		}
		// Class added via JS to solve Chrome issue
		&.active {
			// Fixes hovering calendar dates
			z-index: 1;
			display: block;
			visibility: visible;
			opacity: 1;
		}
	}
	.pika {
		&-lendar {
			box-shadow: $menuShadow;
			z-index: 2;
		}
		&-title {
			display: flex;
			align-items: center;
			background: $colorPrimary;
			padding: 15px 30px 0;
			color: white;
		}
		&-single {
			thead {
				background: $colorPrimary;
			}
			abbr {
				color: white;
				opacity: 0.5;
			}
		}
		&-label,
		&-select,
		&-prev::before,
		&-next::before {
			color: white;
		}
		&-select {
			option {
				color: $colorPrimary;
			}
		}
	}
	.pika-single th abbr,
	.pika-single td button {
		width: 30px;
	}
}
