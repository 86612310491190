.checkbox {
	[type='checkbox'] {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		&:focus + label {
			background: $colorPrimary;
			color: white;
			&:before {
				border-color: white;
			}
		}
		&:checked:focus + label {
			&:after {
				background-color: white;
			}
		}
		& + label {
			display: flex;
			align-items: center;
			position: relative;
			font-size: 14px;
			color: $textBlack;
			cursor: pointer;
			padding: 15px 30px;
			flex-grow: 1;
			@include breakMin($breakMediumSmall) {
				padding: 12px 20px;
			}

			&:before {
				--size: 20px;
				content: '';
				display: block;
				border: 2px $colorPrimary solid;
				margin-right: var(--size);
				height: var(--size);
				width: var(--size);
				min-width: var(--size);
				[dir='rtl'] & {
					margin-right: 0;
					margin-left: var(--size);
				}
				@include breakMin($breakMediumSmall) {
					--size: 10px;
				}
			}
			&:after {
				--size: 12px;
				content: '';
				display: block;
				width: var(--size);
				height: var(--size);
				position: absolute;
				left: 36px;
				@include breakMin($breakMediumSmall) {
					--size: 6px;
					left: 24px;
				}
			}
			&:hover::before {
				border-color: white;
			}
		}
		&:checked + label {
			font-weight: 700;
			&:after {
				background: $colorPrimary;
			}
		}
		& + label:hover {
			background: $colorPrimary;
			color: white;
		}
		&:checked + label:hover {
			&:before {
				background: $colorPrimary;
			}
			&:after {
				background-color: white;
			}
		}
	}
}
