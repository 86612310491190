.unsmoke-bubble-wrapper {
  position: relative;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.unsmoke-bubble {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 20px;
    background: white;
  }

  &:before {
    left: 0;
    width: 50%;
    max-width: calc(100% - 130px);
  }

  &.small-on-mobile {
    &:before,
    &:after {
      @include breakMax($breakSmallBefore) {
        height: 10px;
      }
    }

    &:before {
      @include breakMax($breakSmallBefore) {
        max-width: none;
        width: 30%;
      }
    }
  }

  &:after {
    right: 0;
    width: 10px;
  }
}

.unsmoke-bubble--left,
.unsmoke-bubble--right{
  position: absolute;
  bottom: 20px;
  width: 10px;
  height: 10px;

  .small-on-mobile & {
    @include breakMax($breakSmallBefore) {
      bottom: 10px;
    }
  }
}

.unsmoke-bubble--left {
  left: 0;
}

.unsmoke-bubble--right {
  right: 0;
}

.unsmoke-bubble--bottom {
  position: absolute;
  bottom: 0;
  width: calc(50% - 10px);
  height: 20px;
  min-width: 120px;
  right: 10px;

  .small-on-mobile & {
    @include breakMax($breakSmallBefore) {
      bottom: 0;
      height: 10px;
      min-width: 0;
      width: 60%;
    }
  }
}