.videos-highlight {
    position: relative;
    
    &--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10000;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 18px;
    	top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
}
