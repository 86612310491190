.V3-Article-collage-V1 {
	display: grid;
	grid-gap: 20px;
	margin: 0 auto $subWidgetSpacingMobile;
	@include breakMin($breakSmall) {
		grid-template: 1fr / repeat(3, 1fr);
		grid-template-areas:
			"big     big    small1"
			"big     big    small2"
			"medium  small3 small4";
	}
	@include breakMin($breakMedium) {
		margin-bottom: $subWidgetSpacing;
		grid-template: 1fr 1fr / repeat(5, 1fr);
		grid-template-areas:
			"big big small1 medium medium"
			"big big small2 small3 small4";
	}
	&--big {
		order: -2;
		@include breakMin($breakSmall) {
			grid-area: big;
			order: 0;
		}
		@include breakMax($breakSmallBefore) {
			.large-image-text-on-image-widget {
				margin-left: -$pagePadding;
				margin-right: -$pagePadding;
				width: calc(100% + #{2 * $pagePadding});
				min-height: 80vw;
			}
		}
	}
	&--small1 {
		@include breakMin($breakSmall) {
			grid-area: small1;
		}
	}
	&--small2 {
		@include breakMin($breakSmall) {
			grid-area: small2;
		}
	}
	&--small3 {
		@include breakMin($breakSmall) {
			grid-area: small3;
		}
	}
	&--small4 {
		@include breakMin($breakSmall) {
			grid-area: small4;
		}
	}
	&--medium {
		order: -1;
		@include breakMin($breakSmall) {
			grid-area: medium;
			order: 0;
		}
	}
}
