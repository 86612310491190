.check-drop-down {
	position: relative;
	&.open {
		@include breakMin($breakMediumSmall) {
			z-index: 1001;
		}
	}
	h3 {
		margin: 30px 20px;
		@include breakMin($breakMediumSmall) {
			display: none;
		}
	}
	&--back {
		transition: $mediumDelay box-shadow ease-in-out;
		background: white;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-height: calc(100% - 1px);
		.check-drop-down.open & {
			box-shadow: $menuShadow;
		}
		.grey-back & {
			background: $lightGrey;
			transition: $mediumDelay background ease-in-out;
		}
		.open & {
			background: white;
		}
	}
	&--top {
		position: relative;
		height: 100%;
	}

	&--search {
		position: relative;
		padding: 15px 40px 15px 20px;
		font-size: 14px;
		border: none;
		margin: 0;
		background: transparent;
		z-index: 1;
		outline: none;
		// only be focusable when open
		pointer-events: none;
		[dir="rtl"] & {
			padding: 15px 15px 20px 40px;
		}
		// limit to leave room to the button to be clickable
		button.default:not(.alone) ~ & {
			width: calc(100% - 40px);
		}
		// only be focusable when open
		.open & {
			pointer-events: all;
		}
		@include breakMax($breakMediumSmall) {
			font-size: 16px;
		}
	}
	&--main-button {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		min-width: 200px;
		text-align: left;
		padding-left: 0;
		color: $textBlack;
		z-index: 1;
		outline: none;
		&:after {
			--rotation: 0;
			content: $icon-down;
			font-family: $iconFont;
			position: absolute;
			top: 50%;
			right: 20px;
			color: $colorPrimary;
			font-size: 6px;
			transition: $mediumDelay;
			transform: translateY(-50%) rotate(var(--rotation));
			[dir="rtl"] & {
				right: auto;
				left: 20px;
			}
		}
		&:focus {
			&:after {
				color: white;
			}
		}

		.check-drop-down.open & {
			&:after {
				--rotation: 0.5turn;
			}
		}
		&.default {
			border: 1px solid $strokeGrey;
			padding: 10px 70px 10px 20px;
			transition: $mediumDelay border-color ease-in-out;
			font-size: 14px;
			cursor: pointer;
			.check-drop-down.open & {
				border-color: transparent;
			}
			position: relative;
			&:not(.alone) {
				position: absolute;
			}
		}
		.check-drop-down:not(.open) &:focus:not(.search-field) {
			border-color: $colorPrimary;
			transition: none;
		}
	}
	&--content {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		overflow: hidden;
		z-index: 2000;
		background: white;
		@include breakMin($breakMediumSmall) {
			max-height: 50vh; // [ALIGN WITH] js max height
			overflow-y: auto;
			width: 100%;
			margin-top: 39px;
			position: relative;
			height: auto;
			&.more-margin {
				margin-top: 53px;
			}
		}
		&.init {
			height: 0;
		}
		.grey-back & {
			background: $lightGrey;
			transition: $mediumDelay background ease-in-out;
		}
		.open & {
			background: white;
		}
	}

	&--mobile-scrollable {
		overflow-y: scroll;
		@include breakMin($breakMediumSmall) {
			overflow: visible;
		}
	}
	&--list {
		width: 100%;
		li {
			font-size: 16px;
			color: $grey;
			display: flex;
		}
		.no-display {
			display: none;
		}
		button {
			text-align: left;
			background: none;
			border: none;
			padding: 10px 20px;
			width: 100%;
			font-size: 18px;
			@include breakMin($breakMediumSmall) {
				padding: 5px 20px;
				font-size: 14px;
			}
			transition: $slowDelay background ease-in-out, $mediumDelay opacity ease-in-out;
			&:hover,
			&:focus {
				background: $colorPrimary;
				color: white;
				transition: $mediumDelay background ease-in-out, $fastDelay opacity ease-in-out;
			}
		}
	}
	&--apply {
		width: 100%;
		padding: 20px 0;
		margin-top: auto;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		border: none;
		cursor: pointer;
		transition: $mediumDelay background ease-in-out, $mediumDelay color ease-in-out;
		height: 60px;
		background: $colorPrimary;
		color: white;
		&:hover,
		&:focus {
			color: $colorPrimary;
			background: $lightGrey;
		}
		@include breakMin($breakMediumSmall) {
			padding: 12px 0;
			background: $lightGrey;
			color: $colorPrimary;
			&:hover,
			&:focus {
				color: white;
				background: $colorPrimary;
			}
		}
	}
}
