// this doesn't use any css of pikaday
// Be careful about the possible lack of support of certain features
.pika-single {
	display: block;
	transition: $fastDelay opacity ease-in-out;

	&.is-hidden {
		opacity: 0;
		pointer-events: none;
	}

	.pika {
		&-lendar {
			display: flex;
			flex-flow: column;
		}

		&-title {
			display: flex;
			align-items: center;
			background: $lightGrey;
			padding: 15px 30px 0;
		}

		&-prev,
		&-next {
			flex-grow: 1;
			background: none;
			border: none;
			font-size: 0;
			text-align: right;
			padding: 0;
			--start: 0;
			--end: 0.5turn;
			[dir="rtl"] & {
				--start: 0.5turn;
				--end: 0;
			}
			&::before {
				content: $icon-tailarrowright;
				font-family: $iconFont;
				color: $colorPrimary;
				font-size: 10px;
				display: block;
				transform: rotate(var(--start));
			}

			&.is-disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}

		&-prev {
			order: -1;

			&::before {
				transform: rotate(var(--end));
			}
		}

		&-next {
			order: 1;
		}

		&-label {
			display: flex;
			color: $darkGrey;

			// year label
			&:nth-of-type(2) {
				font-size: 0;
				position: relative;
				margin-left: 10px;

				&::before {
					content: "";
					display: block;
					position: absolute;
					border-bottom: 1px solid $colorPrimary;
					bottom: -10px;
					width: 100%;
				}

				&::after {
					content: $icon-down;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					font-family: $iconFont;
					color: $colorPrimary;
					margin-left: 15px;
					font-size: 6px;
					z-index: 100;
					pointer-events: none;
				}
			}
		}

		&-select {
			background: none;
			appearance: none;
			border: none;
			font-size: 16px;
			color: $darkGrey;
			font-family: inherit;
			padding-right: 20px;
			cursor: pointer;
		}

		option {
			cursor: pointer;
		}

		&-select-month {
			display: none;
		}
	}

	table {
		background: white;
		width: 100%;
	}

	tbody {
		padding: 8px 20px 15px;
		background: white;
	}

	thead {
		padding: 15px 20px 8px;
		background: $lightGrey;
	}

	th abbr,
	td button {
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: normal;
		font-style: normal;
		color: $darkGrey;
		padding: 0;
		width: 34px;
		height: 34px;
		background: none;
		text-decoration: none;
	}

	td button {
		&:hover {
			background: $colorPrimary;
			color: white;
		}
	}

	td.is-selected button {
		background: $colorPrimary;
		color: white;
	}

	td.is-disabled button {
		color: $mediumGrey;
		cursor: default;

		&:hover {
			background: none;
			color: $mediumGrey;
		}
	}
	th {
		padding: 15px 0 8px;
	}
	th,
	td {
		--start: 20px;
		--end: 0;
		[dir="rtl"] & {
			--end: 20px;
			--start: 0;
		}
		&:first-child {
			padding-left: var(--start);
			padding-right: var(--end);
		}

		&:last-child {
			padding-right: var(--start);
			padding-left: var(--end);
		}
	}
	tr {
		&:last-child td {
			padding-bottom: 15px;
		}

		&:first-child td {
			padding-top: 8px;
		}
	}
}
