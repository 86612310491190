.small-text-on-image {
	display: flex;
	flex-flow: column;
	position: relative;
	justify-content: flex-end;
	padding: 40px;

	&--content {
		z-index: 2;
		@include breakMax($breakSmall) {
			width: calc(100% - 125px);
		}
	}
	.box-article-content--link {
		margin-top: 10px;
	}
}
