.error-404 {
	padding: 40px $pagePadding 50px;
	background: linear-gradient(110deg, $colorPrimary 40%, $gradientLightBlue);
	min-height: calc(100vh - 400px);
	display: flex;
	margin: 0 #{-$pagePadding};
	@include breakMin($breakLarge) {
		margin: 0;
		padding: 45px 0 100px;
	}
	&--main {
		margin: auto;
		@include breakMin($breakMedium) {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
	&--content {
		display: flex;
		flex-flow: column;
		color: white;
		margin-bottom: 40px;
		@include breakMin($breakMedium) {
			max-width: 450px;
			margin-bottom: 0;
		}
	}
	&--title {
		font-size: 38px;
		line-height: 46px;
		margin-bottom: 15px;
		strong {
			font-size: 64px;
		}
	}
	&--subtitle {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 35px;
		max-width: 305px;
	}
	&--homepage {
		color: white;
		font-size: 12px;
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		&:after {
			content: $icon-tailarrowright;
			font-family: $iconFont;
			margin-left: 20px;
			font-size: 9px;
			transition: $mediumDelay margin ease-in-out;
		}
		&:hover:after {
			margin-left: 25px;
		}
	}
	&--search-block {
		display: flex;
		flex-flow: column;
		@include breakMin($breakSmall) {
			flex-flow: row;
		}
		@include breakMin($breakMedium) {
			flex-flow: column;
		}
	}
	&--submit {
		background: none;
		border: none;
		font-size: 12px;
		color: white;
		display: flex;
		align-items: center;
		padding: 0;
		&::before {
			content: $icon-tailarrowright;
			font-family: $iconFont;
			color: $colorPrimary;
			font-size: 10px;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
			border-radius: 50%;
			margin-right: 15px;
			border: 1px solid white;
			transition: $mediumDelay background ease-in-out,
				$mediumDelay color ease-in-out;
		}
		&:hover::before {
			background: $colorPrimary;
			color: white;
		}
	}
	&--right {
		@include breakMin($breakMedium) {
			width: 450px;
		}
	}
	&--suggested {
		color: white;
		font-size: 18px;
		font-weight: normal;
		margin-bottom: 20px;
	}
	&--highlights {
		display: flex;
		flex-flow: column;
		@include breakMin($breakMedium) {
			flex-flow: row;
			justify-content: space-between;
		}
	}
	.highlight {
		margin-bottom: 20px;
		@include breakMin($breakMedium) {
			max-width: 213px;
			height: 310px;
			margin-bottom: 0;
		}
		.box-article-content--title {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.search-field-drop {
		flex-grow: 1;
		margin-right: 0;
		@include breakMin($breakSmall) {
			margin-right: 80px;
		}
		@include breakMin($breakMedium) {
			margin: 0;
		}
		&--field {
			border-color: white;
			margin-bottom: 35px;
			@include breakMin($breakSmall) {
				margin-bottom: 0;
			}
			@include breakMin($breakMedium) {
				margin-bottom: 35px;
			}
			input::placeholder {
				color: white;
			}
		}
		&--field input,
		&--back-suggestion {
			color: white;
			font-size: 14px;
		}
		&--back-suggestion {
			padding-top: 2px;
		}
		&--drop {
			@include breakMin($breakMedium) {
				top: calc(100% - 35px);
			}
		}
	}
}
