.tbe-data-view {
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	width: 100%;
	@include breakMin($breakMedium) {
		width: calc(100% - 335px);
	}
	&--title {
		font-size: 11px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		font-weight: normal;
	}
	&--row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
		width: 100%;
		@include breakMin($breakLarge) {
			margin: 0;
			width: auto;
		}
	}
	&--top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 50px;
		width: 100%;
		flex-flow: column;
		&.deactivated {
			opacity: 0.3;
			pointer-events: none;
		}
		@include breakMin($breakLarge) {
			flex-flow: row;
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	&--download {
		display: flex;
		align-items: center;
		padding: 0 20px;
		margin-bottom: 2px;
		background-color: $lightGrey;
		height: 60px;
		@include breakMax($breakMedium) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}
