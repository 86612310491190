.tbe-main {
	display: flex;
	flex-flow: column;
	width: 100%;
	@include breakMin($breakMedium) {
		flex-flow: row;
	}
	&--filters {
		width: 100%;
		margin-right: 50px;
		@include breakMin($breakMedium) {
			width: 275px;
			min-width: 275px;
		}
	}
}
