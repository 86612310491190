.country-info {
	margin: 0 auto;
	position: relative;
	transition: $mediumDelay opacity ease-in-out;
	&:not(.active) {
		opacity: 0.5;
		cursor: wait;
		* {
			cursor: wait;
		}
	}
	@include breakMin($breakMedium) {
		min-height: 631px;
	}
	&--split {
		display: flex;
		flex-flow: column;
		@include breakMin($breakLarge) {
			flex-flow: row;
		}
	}
	&--main {
		position: relative;
		z-index: 1;
		width: 100%;
	}
	&--title {
		position: relative;
		z-index: 2; // not to be overlapped by the map
		margin-bottom: 34px;

		@include breakMin($breakMediumSmall) {
			margin-bottom: 60px;
			max-width: none;
		}
		&.back {
			cursor: pointer;
			&:before {
				content: $icon-arrowleft;
				font-family: $iconFont;
				color: $colorPrimary;
				margin-right: 10px;
				vertical-align: middle;
				font-size: 14px;
				padding-bottom: 6px;
			}
		}
		.market & {
			font-weight: normal;
			max-width: 274px;
			font-size: 30px;
			line-height: 38px;
		}
	}
	&--content {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		padding-left: 20px;
		transition: $mediumDelay opacity ease-in-out;
		@include breakMin($breakMediumSmall) {
			flex-flow: row;
			padding-left: 0px;
		}
		.market & {
			padding-left: 20px;
		}
		&.inactive {
			opacity: 0.5;
			li,
			button {
				pointer-events: none;
			}
		}
	}
	&--background {
		display: none;
		position: fixed;
		height: 100%;
		max-width: 1440px;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		&:before {
			content: '';
			display: block;
			padding-bottom: 44%;
		}
		@include breakMin($breakMediumSmall) {
			display: block;
			width: 123%;
			height: auto;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.market & {
			position: absolute;
			top: 0;
			left: 0;
			transform: none;
			width: 100%;
			@include breakMin($breakMediumSmall) {
				top: -50px;
			}
			@include breakMin($breakMedium) {
				top: -80px;
			}
			@include breakMin($breakLarge) {
				top: 0;
			}
			img {
				object-fit: contain;
				width: auto;
				height: auto;
			}
		}
	}
	&--continent {
		display: flex;
		align-items: center;
		margin-bottom: 34px;
		cursor: pointer;
		background-color: transparent;
		border: none;
		font-size: 24px;
		line-height: 24px;
		color: black;
		border-bottom: 3px solid transparent;
		padding-bottom: 8px;
		&:after {
			content: $icon-arrowright;
			font-size: 10px;
			font-family: $iconFont;
			color: $colorPrimary;
			margin-left: 10px;
			line-height: 10px;
			margin-top: 5px;
			@include breakMin($breakMediumSmall) {
				display: none;
			}
		}
		&.active,
		&:hover,
		&:focus {
			display: block;
			color: $colorPrimaryHover;
			border-color: $colorPrimaryHover;
			outline: none;
			&::after {
				display: none;
			}
		}
	}
	&--continents {
		transition: $mediumDelay transform ease-in-out;
		@include breakMin($breakMediumSmall) {
			width: 33%;
		}
		@include breakMin($breakMedium) {
			width: 30%;
		}
		&.active {
			transform: translateX(-100vw);
			@include breakMin($breakMediumSmall) {
				transform: none;
			}
		}
	}
	&--list {
		flex-grow: 1;
		position: absolute;
		top: -8px;
		@include breakMin($breakMediumSmall) {
			top: 0;
			position: relative;
		}
		@include breakMax($breakMediumSmallBefore) {
			left: 20px;
			right: 0;
		}
	}
	.countries {
		position: absolute;
		left: 0;
		top: -2px; // align with the basis of the continent
		opacity: 0;
		width: 100%;
		padding-left: 16px;
		pointer-events: none;
		transition: $mediumDelay opacity ease-in-out,
			$mediumDelay transform ease-in-out;
		transform: translateX(100vw);

		@include breakMin($breakMediumSmall) {
			transform: none;
			padding-left: 0;
			columns: 2;
		}
		@include breakMin($breakMedium) {
			columns: 3;
		}
		&.active {
			pointer-events: auto;
			opacity: 1;
			z-index: 2;
			transform: translateX(0);
		}
		&--country {
			line-height: 34px;
			a,
			button {
				font-size: 16px;
				color: $darkGrey;
				transition: $mediumDelay color ease-in-out;
				background: none;
				border: none;
				padding: 0;
				text-align: left;
				vertical-align: top;
				border-bottom: 2px solid transparent;
				padding-bottom: 5px;
				&:hover,
				&:focus {
					color: $colorPrimaryHover;
					border-color: $colorPrimaryHover;
					outline: none;
				}
				&.active {
					color: $colorPrimary;
					font-weight: bold;
					border-color: $colorPrimaryHover;
					pointer-events: none;
				}
			}
		}
		&--title {
			color: $colorPrimary;
			@include breakMin($breakMediumSmall) {
				display: none;
			}
		}
	}
}
.market-pane {
	position: relative;
	z-index: 1;
	background: $lightGrey;
	padding: 40px 30px;
	overflow: hidden;
	transition: $slowDelay height ease-in-out;
	margin: 0 -#{$pagePadding};
	@include breakMin($breakXtraSmall) {
		padding: 40px 50px;
	}
	@include breakMin($breakLarge) {
		max-width: 450px;
		min-width: 450px;
		margin: 0;
	}
	&.transition {
		&:before,
		&:after {
			content: '';
			position: absolute;
			z-index: 2;
			top: 0;
			width: 40px;
			height: 100%;
		}
		&:before {
			left: 0;
			background: linear-gradient(to right, $lightGrey, transparent);
		}
		&:after {
			right: 0;
			background: linear-gradient(to left, $lightGrey, transparent);
		}
	}
	&--content {
		position: absolute;
		top: 40px;
		left: 30px;
		width: calc(100% - 60px);
		display: flex;
		flex-flow: column;
		@include breakMin($breakXtraSmall) {
			left: 50px;
			width: calc(100% - 100px);
		}
		transition: $slowDelay transform ease-in-out;
		&.coming {
			transform: translateX(120%);
		}
		&.leaving {
			transform: translateX(-120%);
		}
	}
	&--title {
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 9px;
		&:nth-child(2) {
			margin-bottom: 20px;
		}
	}
	&--phones {
		display: flex;
		flex-flow: column;
		margin-bottom: 20px;
	}
	.link {
		display: flex;
		align-items: flex-start;
		color: $darkGrey;
		margin-bottom: 20px;
		transition: $mediumDelay color ease-in-out;
		&:hover {
			color: $colorPrimary;
		}
		&::before {
			content: $icon-phone;
			font-size: 20px;
			color: $colorPrimary;
			font-family: $iconFont;
			margin-right: 10px;
		}
		@include breakMax($breakMediumSmall) {
			&--number {
				word-break: break-all;
			}
		}
		&.fax::before {
			content: $icon-fax;
			margin-left: -3px;
		}
		&.mail::before {
			content: $icon-mail;
			font-size: 16px;
			padding-top: 3px;
		}
		&--note {
			font-size: 14px;
			line-height: 20px;
		}
	}
	&--name {
		font-size: 16px;
		color: $darkGrey;
		margin-bottom: 20px;
	}
	&--address {
		font-size: 14px;
		line-height: 28px;
		font-style: normal;
		color: $darkGrey;
	}
	&--section {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $buttonGrey;
	}
	&--policy {
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
		color: $darkGrey;
	}
}
.dropdown-menu-widget--world-dropdown {
	.countries--country a {
		font-size: 14px;
	}
	.country-info {
		@include breakMin($breakNavigation) {
			min-height: 570px;
			padding: 50px 0 30px 0;
			&--background {
				position: absolute;
				width: 115%;
				top: 55%;
				transform: translate(-50%, -55%);
				&:before {
					padding-bottom: 38%;
				}
			}
			&--background-img {
				opacity: 0;
				visibility: hidden;
				transition: $fast;
			}
			&--continent {
				font-size: 22px;
			}
		}
	}
}
