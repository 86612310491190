[data-sf-role='form-container'] {
	form {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		max-width: $smallWrapperMax;
		margin: 0 auto;
		width: 100%;
		> div {
			margin-bottom: 50px;
			&:first-child {
				width: 100%;
			}
		}
	}
	[for*='Dropdown'] {
		position: absolute !important;
		left: -300vw !important;
	}
	input,
	select {
		border: none;
		border-bottom: 1px solid $colorPrimary;
		padding: 0 0 8px;
		font-size: 18px;
		width: 100%;
		color: $textBlack;
	}
	textarea {
		border: 1px solid $colorPrimary;
		padding: 15px 10px;
		min-width: 100%;
		max-width: 100%;
		min-height: 225px;
		font-family: $mainFont;
		font-size: 16px;
	}
	input,
	select,
	textarea {
		outline: none;
		transition: $fastDelay border-color ease-in-out;
		&:focus {
			border-color: black;
		}
	}
	option {
		padding: 0 8px;
		appearance: none;
	}
	.sf-fieldWrp {
		display: flex;
		flex-flow: column;
		position: relative;
		width: 100%;
		@include breakMin($breakSmall) {
			width: calc(50% - 25px);
		}
		&.input-full-width {
			width: 100%;
		}
		label {
			position: absolute;
			bottom: 9px;
			left: 8px;
			pointer-events: none;
			top: 0;
			font-size: 19px;
			transition: $mediumDelay opacity ease-in-out,
				$mediumDelay transform ease-in-out;
			&.inactive {
				opacity: 0;
				transform: translateY(-50px);
			}
		}
	}
	[data-sf-role='invalid-captcha-input'][style='visibility: hidden;'] {
		display: none;
	}
	[data-sf-role='captcha-refresh-button'] {
		margin: 20px 0 10px;
	}
	[data-sf-role='dropdown-list-field-container'] {
		position: relative;
		&:after {
			content: $icon-down;
			font-family: $iconFont;
			font-size: 7px;
			color: $colorPrimary;
			background: white;
			position: absolute;
			top: 0;
			right: 0;
			height: calc(100% - 1px);
			padding: 0 8px;
			display: flex;
			align-items: center;
			pointer-events: none;
		}
	}
	[data-sf-role='paragraph-text-field-container'] {
		width: 100%;
		label {
			top: 15px;
			bottom: auto;
			left: 10px;
		}
	}
	.requiredLegend {
		text-align: right;
		font-size: 12px;
		color: $mediumGrey;
	}
	button {
		color: white;
		font-size: 12px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		background: $colorPrimary;
		padding: 25px 90px;
		border: none;
		&:after {
			content: $icon-tailarrowright;
			font-family: $iconFont;
			margin-left: 15px;
			font-size: 9px;
		}
	}
	.input-full-width {
		display: flex;
		justify-content: center;
		@include breakMin($breakSmall) {
			justify-content: flex-end;
		}
	}
	@include breakMax($breakSmallBefore) {
		.g-recaptcha {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
