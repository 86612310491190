.latest-article-pop-up {
	min-width: 350px;
	position: fixed;
	bottom: 0;
	margin-bottom: 10px;
	background-color: $colorPrimary;
	right: 0;
	z-index: 1001;
	padding: 15px;
	width: 300px;
	opacity: 1;
	visibility: visible;
	transition: all 0.3s ease-in-out;

	&.gray {
		background-color: $medlightGrey;

		.latest-article-pop-up--title-wrapper--title {
			color: $textBlack;
		}

		&--category {
			color: $mediumGrey;
		}

		.modal--close-button {
			align-items: self-start;

			&:before {
				background-color: $medlightGrey;
			}
			&:after {
				color: $colorPrimary;
			}
			&:hover::before {
				background-color: $medlightGrey;
			}
			&:focus-visible {
				border: 1.5px solid $textBlack;
				border-radius: 4px;

				&::before {
					background-color: transparent;
				}
			}
		}
	}

	&.hidden,
	&.closed {
		visibility: hidden;
		opacity: 0;
		z-index: 0;
	}

	.modal--close-button {
		&:after {
			font-size: 20px;
		}
	}

	&--category {
		color: #fff;

		.gray & {
			color: $mediumGrey;
		}
	}

	&--description {
		margin: 10px 0;
		font-size: 20px;
		font-weight: 700;
	}

	.modal--close-button:hover::before,
	.modal--close-button:focus::before {
		background-color: $colorPrimary;
	}

	.latest-article-pop-up--title-wrapper {
		display: flex;

		&--title {
			color: #fff;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 5px;

			.gray & {
				color: $textBlack;
			}
		}

		&--close {
			width: 24px;
			height: 24px;
			top: 4px;
			right: 5px;
			margin-left: auto;
		}
	}

	.latest-article-pop-up--content {
		margin-top: 3rem;
		background: #fff;
		padding: 10px;
		line-height: 24px;

		&--reading-time {
			color: $mediumGrey;
		}

		&-cta {
			text-align: right;
			text-transform: uppercase;

			&--label {
				position: relative;
				top: 3px;
				right: 5px;
				letter-spacing: 0.15em;
				font-size: 15px;
				font-weight: 700;
			}
		}
	}
}
