.long-quote {
	display: flex;
	flex-flow: column;
	justify-content: center;
	@include breakMin($breakMediumSmall) {
		flex-flow: row;
	}
	&--image {
		border-radius: 50%;
		width: 210px;
		min-width: 210px;
		height: 210px;
		margin-bottom: 25px;
		align-self: center;
		overflow: hidden;
		position: relative;
		display: none;
		&.is-mobile-show {
			display: block;
		}
		@include breakMin($breakMediumSmall) {
			display: block;
			margin: 0 100px 0 0;
		}
	}
	&--content {
		&:before {
			content: $icon-quote;
			font-family: $iconFont;
			font-size: 20px;
			display: block;
			font-style: normal;
			margin-bottom: 25px;
		}
		padding-bottom: 65px;
		@include breakMin($breakMediumSmall) {
			padding: 70px 0;
		}
	}
	&--quote {
		font-size: 24px;
		line-height: 34px;
		margin-bottom: 30px;
	}
	.quote-author {
		display: block;
		font-size: 12px;
	}
	&.right-aligned {
		@include breakMin($breakMediumSmall) {
			flex-flow: row-reverse;
			.long-quote--image {
				margin: 0 0 0 100px;
			}
		}
	}
	&.grey-background {
		background-color: $lightGrey;
	}
}
