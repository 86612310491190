.page-info-widget-image {
	display: grid;
	grid-template-areas:
		"picture"
		"content"
		"notes";
	margin: 0 auto $subWidgetSpacingMobile;
	@include breakMin($breakSmall) {
		grid-template-columns: 6fr 4fr;
		grid-column-gap: 60px;
		grid-template-areas:
			"picture picture"
			"content notes";
	}
	@include breakMin($breakMedium) {
		grid-template-columns: 4fr 6fr;
		grid-row-gap: 0;
		grid-template-areas:
			"titles picture"
			"content picture"
			"notes picture";
		margin-bottom: $subWidgetSpacing;
	}
	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 18px;
    	top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
	&.no-image {
		grid-template-columns: auto;
		grid-column-gap: 0;
	}
	&.image-not-selected {
		@include breakMax($breakMediumBefore) {
			.page-info-widget-content--title {
				color: #000;
			}
			.page-info-widget-content--category {
				color: RGB(var(--color-primary));
			}
			.small-style {
				color: inherit;
			}
		}
	}
	&.image-selected {
		@include breakMax($breakMediumBefore) {
			.page-info-widget-content--title {
				color: #fff;
			}
		}
	}
	&--picture {
		position: relative;
		grid-area: picture;
		min-height: 120vw;
		width: auto;
		height: auto;
		@include breakMin($breakXtraSmall) {
			min-height: 75vw;
		}
		@include breakMin($breakSmall) {
			min-height: 50vw;
		}
		@include breakMin($breakMedium) {
			min-height: 0;
		}
		@include breakMax($breakMediumBefore) {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: -#{$pagePadding};
				height: 100%;
				width: calc(100% + 2 * #{$pagePadding});
				background: rgba(black, 0.44);
			}
			.still-picture {
				position: absolute;
				top: 0;
				left: -#{$pagePadding};
				width: calc(100% + 2 * #{$pagePadding});
				height: 100%;
				max-width: none;
				object-fit: cover;
			}
		}
	}
	.page-info-widget-content--titles {
		grid-area: picture;
		position: relative;
		z-index: 3;
		align-self: end;
		@include breakMin($breakXtraSmall) {
			max-width: 60%;
		}
		@include breakMin($breakMedium) {
			grid-area: titles;
			padding-top: 60px;
			max-width: none;
		}
	}
	.page-info-widget-content--content {
		padding-top: 25px;
	}
	@include breakMin($breakSmall) {
		.page-info-widget-content--content,
		.page-info-widget-content--notes {
			padding-top: 25px;
			@include breakMin($breakMedium) {
				padding-top: 0;
			}
		}
	}
	@include breakMax($breakMediumBefore) {
		.page-info-widget-content--category,
		.page-info-widget-content--title,
		.page-info-widget-content--reading,
		.small-style {
			color: white;
		}
		.page-info-widget-content--titles {
			padding-top: 80px;
		}
	}
	@include breakBetween($breakSmall, $breakMedium) {
		.page-info-widget-content--description {
			margin-bottom: 0;
		}
	}
	.still-picture {
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
}
