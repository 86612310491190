.mobile-nav {
	@include breakMin($breakNavigation) {
		display: none;
	}

	margin-left: 30px;
	align-self: center; // for chrome
	display: flex; // for firefox

	&--opener {
		position: fixed;
		height: 0;
		width: 100vw;
		top: 0;
		left: 0;
		overflow: hidden;
		transition: $mediumSpeed;
		background: white;

		.open & {
			height: 100vh;
		}
	}

	&--menu {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		height: 100vh;
		padding: 0 $pagePadding;
	}

	&--panel-title,
	&--biglink,
	&--link {
		color: $darkGrey;
		display: block;
		font-size: 16px;
		text-transform: uppercase;
		text-align: left;
		[dir='rtl'] & {
			text-align: right;
		}
	}

	&--biglink,
	&--panel-title {
		font-weight: 700;
		&.indented {
			margin-left: 20px;
		}
	}
	&--panel-title {
		margin-left: 20px;
		color: black;
		position: relative;
		margin-bottom: 24px;
		&.spaced {
			margin-bottom: 40px;
		}

		&:before {
			content: '';
			position: absolute;
			right: -17px;
			top: 50%;
			transform: translateY(-50%);
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: $colorPrimary;
			[dir='rtl'] & {
				right: 125px;
			}
			display: block;
		}
		&.indented {
			margin-left: 40px;
		}
	}

	.column,
	&--panel,
	&--indent,
	&--bottom {
		display: flex;
		flex-flow: column;
	}

	&--link {
		background: none;
		border: none;
		padding: 0;

		&.is-low {
			text-transform: none;
		}

		&.is-back::before,
		&.is-next::after {
			position: relative;
			bottom: 1.5px;
			font-family: $iconFont;
			color: $colorPrimary;
			font-size: 12px;
			[dir='rtl'] & {
				display: inline-block;
				transform: rotate(180deg);
				margin-right: 12px;
				margin-left: 12px;
			}
		}
		&.is-next {
			cursor: pointer;

			&:after {
				content: $icon-arrowright;
				margin-left: 12px;
			}
		}

		&.is-back {
			font-size: 16px;
			text-transform: none;
			margin-bottom: 25px;
			cursor: pointer;

			&:before {
				content: $icon-arrowleft;
				margin-right: 12px;
			}
		}
	}

	&--logo {
		margin: 11px 0 45px;
		width: 25%;
		img {
			height: 36px;
		}
	}

	&--panels {
		position: relative;

		li {
			margin-bottom: 40px;
		}
	}

	&--panel {
		transition: $mediumDelay transform ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100vh - 200px);
		transform: translateX(100vw);
		overflow-y: auto;
		&[data-name='start'] {
			overflow: auto;
			transform: translateX(-100vw);
		}
		&[data-name='markets'] {
			overflow: visible;
		}
		&[data-name='business'] {
			overflow-x: hidden;
		}
		&.active {
			transform: translateX(0);
		}
		.market-search-menu {
			&--all-market {
				margin-left: -$pagePadding;
				background: $colorPrimary;
				height: 50px;
				width: calc(100% + #{2 * $pagePadding});
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: white;
				text-transform: uppercase;
				[dir='rtl'] & {
					margin-right: -$pagePadding;
					margin-left: 0;
				}
			}
			.search-field-drop {
				padding: 0;
				position: static;
				height: auto;
				width: auto;
				flex-grow: 1;
				&--field {
					background: $lightGrey;
					margin: 0 -#{$pagePadding};
					width: calc(100% + #{2 * $pagePadding});
					padding: 30px 20px 30px 70px;
					height: 60px;
					&::before {
						left: 20px;
						top: 50%;
						[dir='rtl'] & {
							left: 0;
							right: 20px;
						}
					}
				}
				&--drop {
					height: auto;
					max-height: calc(100% - 60px);
					margin: 0;
					top: auto;
					left: auto;
				}
				&--suggestions {
					height: calc(100vh - 400px);
					li {
						margin: 0;
					}
					a {
						padding-left: 50px;
						font-size: 18px;
					}
				}
				.highlighted::before {
					left: 35px;
				}
			}
		}
	}

	&--bottom {
		margin: 0 auto;
		width: 250px;

		small {
			color: $grey;

			font-size: 11px;
			margin: 21px auto;
		}
	}

	&--indent {
		padding-left: 20px;
	}

	&--social {
		display: flex;
		justify-content: space-between;
	}

	// burger

	&--burger {
		--transitionSpeed: #{$mediumDelay};
		border: none;
		background: none;
		padding: 0;
		position: relative;
		width: 24px;
		height: 16px;
		align-self: flex-start;
		z-index: 2000;
		cursor: pointer;

		& > span {
			position: absolute;
			background: $darkGrey;
			width: 100%;
			height: 2px;
			left: 50%;
			transform: translateX(-50%);
			transition: $fastDelay transform ease-in-out,
				var(--transitionSpeed) background ease-in-out;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: calc(50% - 1px);
			}

			&:nth-child(3) {
				bottom: 0;
				transform: none;
				right: 0;
				width: 16px;
				left: auto;
			}
		}

		&:focus {
			--transitionSpeed: #{$fastDelay};
			outline: none;

			& > span {
				background: $colorPrimary;
			}
		}

		.open &,
		&.close {
			height: 24px;

			& > span {
				&:nth-child(1) {
					top: calc(50% - 1px);
					transform: translateX(-50%) rotate(45deg);
				}

				&:nth-child(2) {
					transform: translateX(-50%) rotate(-45deg);
				}

				&:nth-child(3) {
					width: 0;
				}
			}
		}
	}
	.spacing {
		margin-top: 34px;
	}
}
