.tbe-phases {
	position: relative;
	&--header,
	&--dropped {
		position: relative;
		display: flex;
		align-items: center;
		height: 60px;
		width: 100%;
		border: none;
	}
	&--header {
		background: $colorPrimary;
		color: white;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		padding-left: 25px;
		padding-right: 10px;
		&:before {
			content: $icon-down;
			font-family: $iconFont;
			color: white;
			font-size: 6px;
			position: absolute;
			right: 25px;
			top: 50%;
			transform: translateY(-50%) rotate(0);
			transition: $fastDelay transform ease-in-out;
		}
		&.open:before {
			transform: translateY(-50%) rotate(180deg);
		}
		&-wrapper {
			width: 85%;
			display: flex;
			text-align: left;
			align-items: center;
		}
		&-text,
		&-icon {
			display: inline-block;
		}
		&-text {
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-icon:after {
			font-family: $iconFont;
			margin-left: 10px;
			font-weight: normal;
			color: white;
		}
	}
	&--container {
		top: 100%;
	}
	&--row {
		border-bottom: 1px solid $buttonGrey;
		&:last-child {
			border: none;
		}
	}
	&--dropped {
		padding-left: 60px;
		padding-right: 25px;
		background: white;
		font-size: 12px;
		line-height: 20px;
		color: black;
		text-align: left;
		&:after {
			position: absolute;
			left: 20px;
			top: 50%;
			transform: translate(-50%, -50%);
			font-family: $iconFont;
			margin-left: 10px;
			color: $colorPrimary;
		}
		&:hover,
		&:focus {
			background: $lightGrey;
			outline: none;
		}
	}
	.ico-all:after {
		content: $icon-selectAll;
		font-size: 20px;
	}
	.ico-growing:after {
		content: $icon-growing;
		font-size: 20px;
	}
	.ico-processing:after {
		content: $icon-processing;
		font-size: 29px;
	}
	.ico-product:after {
		content: $icon-product;
		font-size: 27px;
		margin-right: 9px;
	}
	.ico-suppliers:after {
		content: $icon-suppliers;
		font-size: 27px;
		margin-right: 3px;
	}
	.ico-distributive:after {
		content: $icon-distributive;
		font-size: 18px;
	}
	.ico-taxes:after {
		content: $icon-taxes1;
		font-size: 18px;
	}
	.ico-illicit:after {
		content: $icon-taxes;
		font-size: 26px;
	}
	.ico-consumers:after {
		content: $icon-consumers;
		font-size: 29px;
		padding-bottom: 2px;
	}
}
