.footer-nav {
	width: 100%;
	color: $grey;
	display: grid;
	grid-gap: 40px;
	padding-top: 40px;
	border-top: 1px solid $buttonGrey;
	@include breakMin($breakContent) {
		grid-template-columns: 2.5fr 1fr;
	}
	&--content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		@include breakMin($breakSmall) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include breakMin($breakMedium) {
			display: flex;
		}

		a {
			--side-margin: 0;
			display: block;
			width: 100%;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			font-size: 12px;
			color: $darkGrey;
			transition: $fast;
			margin-right: var(--side-margin);
			[dir="rtl"] & {
				margin-left: var(--side-margin);
				margin-right: 0;
			}
			&:hover,
			&:focus {
				color: $colorPrimaryHover;
				outline: none;
			}
			&:focus {
				font-weight: bold;
			}
			@include breakMin($breakMedium) {
				--side-margin: 40px;
			}
			@include breakMin($breakLarge) {
				--side-margin: 60px;
			}
		}
	}
	&--copyright {
		font-size: 11px;
		@include breakMin($breakContent) {
			text-align: right;
			[dir="rtl"] & {
				text-align: left;
			}
		}
		@include breakMin($breakLarge) {
			margin: 0;
		}
	}
}
