.initiative {
	position: relative;
	padding: 20px 20px 40px 15%;
	display: flex;

	&:focus {
		outline: none;
	}
	&--logo {
		margin-bottom: 20px;
		max-width: 200px;
		width: auto;
		height: auto;
	}

	.initiative-infos-container {
		display: flex;
		align-items: flex-end;
		overflow: hidden;
		flex-grow: 1;
	}

	&--infos {
		position: relative;
		color: #fff;

		@include breakMin($breakLarge) {
			max-width: 65%;
		}

		&.beginning {
			opacity: 0;
		}

		.title3 {
			font-size: 28px;
			font-weight: 500;
		}

		&--copy {
			font-size: 14px;
			margin-bottom: 30px;
		}
	}

	&--projet {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 11px;
		letter-spacing: 3px;
		margin-bottom: 10px;
	}

	.title3 {
		color: #fff;
		margin-bottom: 20px;
	}
}
.initiative .arrowed-link,
.initiative:hover .arrowed-link,
.initiative:focus .arrowed-link {
	color: white;
	&::before {
		background: white;
		color: $colorPrimary;
		--spacing: 10px;
	}
}
