.contact-details {
	--picture-aspect-ratio: 133.33%;
	&.with-details{
		--picture-aspect-ratio: 100%;
	}
	&--photo {
		position: relative;
		width: 100%;
		img {
			width: auto;
			height: auto;
		}
		&::before{
			content: '';
			display: block;
			padding-bottom: var(--picture-aspect-ratio);
		}
	}

	&--name,
	&--position {
		.with-details & {
			padding-left: 0;
		}
		@include breakMin($breakMediumSmall) {
			padding-left: 20px;
		}
	}
	&--name {
		margin-top: 15px;
		font-size: 28px;
		.with-details & {
			margin-top: 25px;
			font-size: 20px;
		}
	}
	&--position {
		max-width: 255px;
		margin-top: 8px;
		font-size: 14px;
		line-height: 24px;
		.with-details & {
			margin-top: 14px;
		}
	}
	&--email,
	&--telephone {
		display: block;
		padding: 15px 0;
		border-top: 1px solid $buttonGrey;
		font-size: 14px;
		color: $textBlack;
		&:before {
			font-family: $iconFont;
			color: $colorPrimary;
		}
	}
	&--email {
		&:before {
			content: $icon-mail;
			margin-right: 15px;
		}
	}
	&--telephone {
		&:before {
			content: $icon-phone;
			margin: 0 15px 0 3px;
			font-size: 18px;
		}
	}
}
