.V3-Stick-element-3 {
	margin: 0 #{-$pagePadding} $subWidgetSpacingMobile;

	@include breakMin($breakMedium) {
		margin-bottom: $subWidgetSpacing;
		display: grid;
		grid-template: 1fr / repeat(3, 1fr);
	}

	@include breakMin($breakLarge) {
		margin-left: 0;
		margin-right: 0;
	}

	> div {
		display: flex;
		height: 0;
		position: relative;
		padding-bottom: 100%;

		@include breakMin($breakMedium) {
			padding-bottom: 120%;
		}

		> a,
		> section,
		> div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
