.layout-3-columns {
	display: flex;
	flex-flow: column;
	> .sf_colsIn {
		width: 100%;
		margin-bottom: 40px;
	}
	@include breakMin($breakMediumSmall) {
		flex-flow: row;
		justify-content: space-between;
		> .sf_colsIn {
			width: calc(33% - 10px);
			margin-bottom: 0;
		}
	}
}
