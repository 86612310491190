// VARIABLES
$headerWidth: 275px;

.social-highlight {
	position: relative;
	background: black;
	margin: 0 -20px 100px;

	@include breakMin($breakLarge) {
		margin-left: 0;
		margin-right: 0;
	}

	&--inner {
		margin: 0 auto;

		@include breakMin($breakMedium) {
			width: calc(100% - 40px);
		}

		@include breakMin($breakLarge) {
			width: auto;
		}
	}

	&--background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			width: auto;
			height: auto;
		}
	}

	.social-highlight-title {
		position: relative;
		padding: 70px 0 20px 20px;
		font-size: 30px;
		color: white;

		@include breakMin($breakMedium) {
			padding: 100px 0 40px 0;
		}
	}
}

.social-card-container {
	position: relative;
	margin: 0 20px;
	padding-bottom: 90px;

	@include breakMin($breakSmall) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@include breakMin($breakMedium) {
		flex-wrap: nowrap;
		margin: 0;
		padding-bottom: 150px;
	}
}

.social-card {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	background: white;

	@include breakMin($breakSmall) {
		flex-basis: 48%;
		height: 330px;
	}

	@include breakMin($breakMedium) {
		position: relative;
		margin-bottom: 0;
		flex-basis: 22%;
		height: 380px;

		&:hover {
			cursor: default;

			.social-card--next,
			.social-card--nav {
				pointer-events: auto;
				opacity: 1;
			}
		}
	}

	@include breakMin($breakLarge) {
		height: 360px;
	}

	&--next {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 52px;
		min-width: 52px;
		height: 52px;
		transform: translate(50%, -50%);
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 50%;
		background: $colorPrimary;

		@include breakMin($breakMedium) {
			display: flex;
			opacity: 0;
			transition: $mediumSpeed;
			pointer-events: none;
		}

		&--text {
			text-transform: uppercase;
			font-size: 11px;
			color: white;
			letter-spacing: 0.15em;
		}
	}

	&--header {
		&--link {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			padding: 16px 20px 0;
		}

		.pmi-logo {
			width: 38px;
		}

		.social-media-title {
			font-size: 12px;
			line-height: 16px;
			padding-bottom: 2px;
			flex-basis: percentage(80px / $headerWidth);
			color: $textBlack;
		}

		.post-time {
			font-size: 12px;
			padding-bottom: 2px;
			line-height: 16px;
			color: $grey;
			flex-basis: percentage(100px / $headerWidth);
		}

		.social-icon {
			font-family: $iconFont;
			font-size: 13px;
			align-self: center;
		}

		.social-icon--twitter {
			&:before {
				content: $icon-twitter;
			}
		}

		.social-icon--facebook {
			&:before {
				font-size: 15px;
				content: $icon-facebook;
			}
		}

		.social-icon--linkedin {
			&:before {
				content: $icon-linkedin;
			}
		}

		.social-icon--instagram {
			&:before {
				font-size: 20px;
				content: $icon-instagram;
			}
		}
	}

	&--body {
		margin-top: 16px;
		font-size: 14px;
		line-height: 24px;

		img {
			width: 100%;

			@include breakMin($breakSmall) {
				max-height: 130px;
				object-fit: cover;
			}
		}

		p {
			margin: 14px 20px 0 20px;
		}
	}

	&--slide {
		display: none;

		&.active {
			display: block;
		}
	}

	&--footer {
		display: flex;
		margin-top: auto;
		padding: 20px;

		&--link {
			display: flex;

			& + .social-card--footer--link {
				margin-left: auto;

				@include breakMin($breakLarge) {
					margin-left: 70px;
				}
			}
		}

		.footer-icon {
			width: 20%;
			fill: $colorPrimary;
		}

		.footer-text {
			padding-left: 10px;
			color: black;
			font-size: 11px;
			text-transform: uppercase;
			letter-spacing: 0.15em;
		}
	}

	&--nav {
		display: none;
		position: absolute;
		bottom: -42px;
		justify-content: center;
		align-items: center;
		padding: 20px 0 10px;
		width: 100%;

		@include breakMin($breakMedium) {
			display: flex;
			opacity: 0;
			transition: $mediumSpeed;
			pointer-events: none;
		}

		&--item {
			width: 8px;
			height: 8px;
			border: none;
			margin: 0 5px;
			padding: 0;
			background-color: white;

			&.active {
				width: 12px;
				height: 12px;
				pointer-events: none;
				background-color: $colorPrimary;
			}
		}
	}
}
