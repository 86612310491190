.press-release-details-widget {
	display: flex;
	flex-flow: column;
	@include breakMin($breakSmall) {
		display: block;
	}
	&--date {
		font-size: 10px;
		margin-bottom: 5px;
		color: $grey;
	}
	&--title {
		font-size: 40px;
	}
	&--button {
		display: inline-block;
		margin-top: 50px;
		padding: 20px 40px;
		text-transform: uppercase;
		text-align: center;
		border: 1px solid $colorPrimary;
		background-color: $colorPrimary;
		color: white;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 0.15rem;
	}
	&--download {
		&:before {
			content: $icon-download;
			font-family: $iconFont;
		}
	}
	&--description {
		margin-top: 40px;
	}
}
