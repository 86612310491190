.search {
	margin-left: 30px;
	height: 100%;
	position: relative;
	align-self: center;
	color: $grey;
	transition: $mediumDelay margin ease-in-out, $mediumDelay width ease-in-out;
	width: 100%;

	@include breakMax($breakLarge) {
		[dir='rtl'] & {
			margin-left: 0;
		}
	}

	@include breakMin($breakNavigation) {
		margin-left: 40px;
	}

	&--glass {
		--transitionSpeed: #{$mediumDelay};
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;
		width: 20px;
		color: $darkGrey;
		transition: var(--transitionSpeed) color ease-in-out,
			var(--transitionSpeed) opacity ease-in-out,
			var(--transitionSpeed) width ease-in-out;
		overflow: hidden;
		z-index: 2;
		&:hover,
		&:focus {
			color: $colorPrimary;
			outline: none;
			--transitionSpeed: #{$fastDelay};
		}

		&:before {
			content: $icon-search;
			font-family: $iconFont;
			font-size: 20px;

			@include breakMin($breakNavigation) {
				font-size: 16px;
			}
		}
	}
	&--form {
		display: flex;
		align-items: center;
		height: 100%;
		z-index: 2000;
		background: white;
		border-radius: 15px;

		@include breakMin($breakNavigation) {
			position: absolute;
			right: 0;
			top: 50%;
			top: 0;
			padding: 20px 0 40px 10px;
			transform: none;
		}
		[dir='rtl'] & {
			left: -40px;
			right: unset;
		}
	}
	.search-field-drop {
		transform: translateX(-100vw);
		opacity: 0;
		&--field {
			width: 0;
			height: 18px;
			border-width: 0;
		}
	}

	&.open {
		width: 0;
		@include breakMax($breakNavigation) {
			margin: 0;

			.search--glass {
				width: 0;
				opacity: 0;
			}
			.search-field-drop {
				z-index: 1;
				width: 100vw;
			}
		}

		@include breakMin($breakNavigation) {
			&--form {
				padding: 20px 20px;
			}

			.search--glass {
				margin: 0 20px;
			}

			.search-field-drop--field {
				border-width: 0 0 0 1px;
				width: 270px;
				height: 30px;

				[dir="rtl"] & {
					border-width: 0 1px 0 0;
				}
			}
		}

		.search-field-drop {
			transform: translateX(0);
			opacity: 1;

			@include breakMax($breakNavigation) {
				&--drop {
					box-shadow: none;
				}
			}
		}
		.boxed-style {
			background-color: $lightGrey;
			align-items: center;
			@include breakMin($breakNavigation) {
				.search-field-drop--field {
					height: 18px;
					width: 220px;
				}
			}
		}
		.search-field-drop--close {
			transition: $mediumSpeed;
			visibility: visible;
			opacity: 1;
		}
	}
}
.boxed-style {
	border-radius: 0;
	padding: 0;
	transition: $mediumDelay background-color ease-in-out;
}
