.skiplink {
	position: absolute;
    margin: -1px;
    border: 0;
	height: 100%;
	white-space: nowrap;
	overflow: hidden;
	padding: 15px;
	top: -100%;
	left: 0;
	right: 0;
	transition: top .5s ease-in-out;
	color: $colorPrimary;
	background-color: white;
	z-index: $z-skiplink;
	text-transform: uppercase;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	
	&:focus {
		text-decoration: none;
		top: 0;
		color: $colorPrimary;
		overflow: visible;
		outline: transparent;
	}
}