.blog-intro {
	display: flex;
	flex-flow: column;
	margin: 0 -#{$pagePadding} 45px;
	width: calc(100% + #{2 * $pagePadding});
	@include breakMin($breakMediumSmall) {
		flex-flow: row;
		margin: 0 auto 80px;
		width: 100%;
	}
	&--content {
		position: relative;
		display: flex;
		flex-flow: column;
		justify-content: center;
		background: $lightGrey;
		padding: #{$pagePadding} 50px 90px #{$pagePadding};
		@include breakMin($breakMediumSmall) {
			flex-basis: 50%;
			padding: 55px 70px 90px 45px;
		}
		@include breakMin($breakMedium) {
			flex-basis: 40%;
		}
	}
	&--title {
		font-size: 24px;
		color: $textColor;
		margin-bottom: 15px;
		@include breakMin($breakMediumSmall) {
			font-size: 27px;
		}
	}
	&--notes {
		display: flex;
		align-items: baseline;
		color: $colorPrimary;
	}
	&--date {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.15em;
	}

	&--time-read {
		font-size: 10px;
	}
	.logo-disc {
		width: auto;
		height: auto;
		position: absolute;
		left: $pagePadding;
		bottom: 0;
		transform: translateY(50%);
		z-index: 1;
		@include breakMin($breakMediumSmall) {
			top: 45px;
			right: 0;
			left: auto;
			bottom: auto;
			transform: translateX(50%);
		}
	}
	&--picture {
		width: auto;
		height: auto;
		position: relative;
		flex-grow: 1;
		&::before {
			content: '';
			display: block;
			padding-bottom: 60%;
		}
	}
}
