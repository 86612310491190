.key-figures-number {
	.key-figure--title {
		height: 150px;
		width: 150px;
		margin: 0 auto 20px;
		color: #fff;
		background-color: $colorPrimary;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
	}
	a:focus .key-figure--title {
		background-color: $textBlack;
	}
}
