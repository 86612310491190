.large-image-quote-widget {
  background: $lightGrey;
  width: 100vw;
  position: relative;
  margin: 0 -#{$pagePadding} 60px;
  @include breakMin($breakSmall) {
    padding-left: 50%;
    min-height: 450px;
  }
  @include breakMin($breakLarge) {
    margin: 0 0 60px;
  }

  &--picture {
    position: absolute;
    bottom: 0;
    right: -40px;
    width: 110%;
    height: 510px;
    @include breakMin($breakSmall) {
      right: calc(50% + 40px);
      height: calc(100% + 50px);
      width: 50%;
    }

    img {
      display: block;
	  width: 100%;
	  height: 100%;
      object-fit: contain;
      object-position: bottom center;
      @include breakMin($breakSmall) {
        object-position: bottom right;
      }
    }
  }
  &--text {
    padding: 50px 0 510px;
    text-align: center;
    width: calc(100% - #{2 * $pagePadding});
    margin: 0 auto 20px;
    @include breakMin($breakSmall) {
      width: calc(100% - #{4 * $pagePadding});
      text-align: left;
      padding: 100px 0;
    }
    &:before {
      content: $icon-quote;
      display: block;
      font-size: 20px;
      margin-bottom: 20px;
      font-family: $iconFont;
      color: $buttonGrey;
      font-style: normal;
    }
  }
  &--quote {
    display: block;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    @include breakMin($breakSmall) {
      max-width: calc(100% - 40px);
    }
    @include breakMin($breakMedium) {
      max-width: 450px;
    }
  }
}
