.breadcrumbs {
	margin: 20px auto;
	display: flex;
	position: relative;
	z-index: 100;
	@include breakMin($breakMediumSmall) {
		margin: 30px auto 55px;
	}
	&--link {
		color: $grey;
		font-size: 14px;
		transition: $mediumDelay transform ease-in-out;
		&:before {
			content: $icon-arrowleft;
			margin-right: 8px;
			font-size: 12px;
			color: $colorPrimary;
			font-family: $iconFont;
		}
		@include breakMax($breakMediumSmall) {
			[dir="rtl"] & {
				&:before {
					transform: rotate(180deg);
					display: inline-block;
					margin-right: 0;
					margin-left: 8px;
				}
			}
		}

		&:focus {
			outline: none;
			transform: scale(1.1);
			transition: $fastDelay transform ease-in-out;
		}

		@include breakMin($breakMediumSmall) {
			font-size: 11px;
			text-decoration: underline;
			&:after {
				content: "";
				display: inline-block;
				background: $grey;
				width: 3px;
				height: 3px;
				margin: 0 10px 2px;
				border-radius: 50%;
			}
			&:hover {
				text-decoration: underline;
			}
			&:before {
				display: none;
			}
		}
		.white & {
			color: #fff;
			&:before {
				color: #fff;
			}

			&:after {
				background: #fff;
			}
		}
	}
	&--greyed {
		font-size: 11px;
		color: $grey;
		display: none;
		@include breakMin($breakMediumSmall) {
			display: block;
		}
		.white & {
			color: #fff;
			opacity: 0.8;
		}
	}
	&--link:not(.is-mobile) {
		@include sr-only-mobile();
	}
}
