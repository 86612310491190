.picture-quote-widget {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 23px;
  margin: 0 auto $subWidgetSpacingMobile;
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }
  @include breakMin($breakMediumSmall) {
    flex-flow: row;
    padding: 0 50px;
  }
  &--text {
    display: flex;
    flex-flow: column;
  }
  &--avatar {
    width: 110px;
    min-width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-bottom: 22px;
    overflow: hidden;
    display: none;
    position: relative;
    &.is-mobile-show {
      display: block;
    }
    @include breakMin($breakMediumSmall) {
      display: block;
      margin: 43px 40px 0 0;
      &.is-right {
        margin: 43px 0 0 40px;
        order: 1;
      }
    }
  }
  &--quote {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 24px;
    &:before {
      content: $icon-quote;
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
      font-family: $iconFont;
    }
  }
  .quote-author {
    align-items: flex-start;
  }
}
