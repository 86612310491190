.press-release-contact-highlight {
	background-color: $lightGrey;
	padding: 30px 60px 45px 35px;
	&--title {
		font-size: 25px;
	}
}

.contact-item {
	margin-top: 20px;
	&--subtitle {
		margin-bottom: 10px;
		line-height: 1.5rem;
		font-size: 15px;
	}
	&--row {
		display: flex;
		align-items: flex-end;
		margin-bottom: 10px;
	}
	&--phone-icon,
	&--email-icon {
		&:before {
			font-family: $iconFont;
			color: $colorPrimary;
		}
	}
	&--phone-icon {
		margin-right: 13px;
		font-size: 20px;
		width: auto;
		height: auto;
		&:before {
			content: $icon-phone;
		}
	}
	&--email-icon {
		margin-right: 10px;
		font-size: 14px;
		&:before {
			content: $icon-mail;
		}
	}
	&--phone-number,
	&--email {
		color: $grey;
		font-size: 18px;
	}
}
