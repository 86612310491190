.newsroom {
	&--player {
		width: 100%;
		height: 100%;
	}

	&--container {
		height: 100vh;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3000;
	}

	&--share-overlay {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: none;
		z-index: 2999;

		&.active {
			display: block;
		}
	}

	&--share {
		position: fixed;
		z-index: 3002;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
		display: flex;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
		height: 100%;
		align-items: center;
		opacity: 0;
		visibility: hidden;
		transition: $fast;

		&.active {
			opacity: 1;
			visibility: visible;
		}
	}

	&--share-buttons {
		height: 50px;

		button {
			border: none;
			cursor: pointer;
			background-color: transparent;
			padding: 0;
			font-size: 0;
			color: transparent;

			@include breakMin($breakLarge) {
				&:hover:before {
					color: #fff;
					background-color: $colorPrimary;
				}
			}

			&:before {
				width: 50px;
				height: 50px;
				display: flex;
				margin-right: 10px;
				border-radius: 100%;
				font-family: $iconFont;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				transition: background-color 0.3s ease-out, color 0.3s ease-out;
				color: #fff;
				background-color: $colorPrimary;

				@include breakMin($breakLarge) {
					background-color: $lightGrey;
					color: $grey;
					margin-bottom: 20px;
				}
			}

		}
	}

	&--share-copy-container {
		position: relative;
		display: inline-block;

		p {
			position: absolute;
			top: 0;
			left: 60px;
			background: $grey;
			padding: 10px;
			opacity: 0;
			color: #fff;
			transform: translateY(20px);
			transition: opacity 0.3s ease-out, transform 0.3s ease-out;
			border-radius: 10px;
			width: auto;
			white-space: nowrap;
			pointer-events: none;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 100%;
				width: 0;
				height: 0;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				border-right: 8px solid $grey;
				transform: translateY(-50%);
			}
		}

		button:hover+p {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&--share-facebook:before {
		content: $icon-facebook;
		font-size: 16px;
	}

	&--share-copy:before {
		content: $icon-link;
	}

	&--share-twitter:before {
		content: $icon-twitter;
	}

	&--share-linkedin:before {
		content: $icon-linkedin;
	}
}

/*
Styles for the newsroom share element on screens with a minimum aspect ratio of 31:40:
	Calculates the height and width based on dynamic values and aspect ratio
	Uses the custom variable --i-amphtml-story-desktop-one-panel-height to determine the height
	Sets a fixed top position and border radius 
*/
@media (min-aspect-ratio: 31 / 40) {
	.newsroom--share {
		--i-amphtml-story-desktop-one-panel-height: calc(calc(100vh) - (max(74px, 8.25vh) * 2));
		width: calc(var(--i-amphtml-story-desktop-one-panel-height) * (69 / 116));
		height: calc(var(--i-amphtml-story-desktop-one-panel-height) + 2px);
		top: 75px;
		border-radius: 20px;
	}
}
