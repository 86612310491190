.tag-element {
	position: relative;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	font-size: 11px;
	background: $lightGrey;
	margin-right: 20px;
	margin-bottom: 20px;
	border: 1px solid $buttonGrey;

	button {
		display: flex;
		background: none;
		border: none;
		padding: 0;
		margin-left: 10px;
		cursor: pointer;
		&:before {
			content: $icon-cross;
			font-family: $iconFont;
			font-size: 7px;
			color: $grey;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		&:focus {
			outline: none;
			&::after {
				outline: $blockFocusOutline;
			}
		}
	}
}
