.tbe-variables {
	overflow-y: scroll;
	width: 100%;
	max-height: 250px;
	[type='checkbox'] + label {
		padding: 10px;
		align-items: flex-start;
		@include breakMin($breakMediumSmall) {
			font-size: 12px;
		}
	}
}
