.page-info-widget-content {
	&--titles {
		grid-area: titles;
		padding-bottom: 20px;
	}
	&--notes {
		grid-area: notes;
		&.no-margin .written-by {
			margin-bottom: 0;
		}
	}

	&--title {
		margin-bottom: 20px;
		color: black;
	}
	&--description {
		grid-area: content;
		margin: 20px 0 40px;
		font-size: 18px;
		line-height: 30px;
		&.no-margin {
			margin-bottom: 0;
		}
	}
	&--category:focus {
		outline: none;
		color: $textBlack;
		font-weight: bold;
	}
	&--tags {
		display: flex;
		flex-wrap: wrap;
	}
	&--date {
		display: block;
		margin-bottom: 20px;
	}
	&--tag {
		background: rgba($colorPrimaryValue, 0.1);
		padding: 7px 15px;
		font-size: 14px;
		color: $textBlack;
		margin-right: 10px;
		transition: $mediumDelay background ease-in-out;
		&:hover,
		&:focus {
			background: rgba($colorPrimaryValue, 0.4);
			color: inherit;
			outline: none;
		}
	}
	.no-margin {
		margin-bottom: 0;
	}
}
