.pages-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 10px -9px 0;
	font-size: 11px;
	@include breakMin($breakSmall) {
		margin: 10px 0 0;
	}
	&.no-display {
		display: none;
	}
	button {
		background: none;
		border: none;
		color: $colorPrimary;
		cursor: pointer;
	}
	&--first {
		&:before {
			content: $icon-longarrowleft;
			font-size: 10px;
			font-family: $iconFont;
			@include breakMin($breakSmall) {
				margin-right: 20px;
				[dir="rtl"] & {
					margin-right: 0px;
					margin-left: 20px;
				}
			}
		}
	}
	&--last {
		justify-content: flex-end;

		&:after {
			content: $icon-longarrowright;
			font-size: 10px;
			font-family: $iconFont;
			@include breakMin($breakSmall) {
				margin-left: 20px;
				[dir="rtl"] & {
					margin-left: 0px;
					margin-right: 20px;
				}
			}
		}
	}
	&--first,
	&--last {
		transition: $fastDelay color ease-in-out;
		display: inline-flex;
		align-items: center;
		padding: 14px 0;
		width: 25%;
		font-size: 0;
		color: $colorPrimary;
		word-break: break-word;
		hyphens: auto;
		&:disabled,
		&.active {
			color: $textBlack;
			pointer-events: none;
			outline: none;
		}
		@include breakMin($breakSmall) {
			font-size: 11px;
		}
		--translation: 0px;
		--rotation: 0deg;
		--factor: 1;
		[dir="rtl"] & {
			--rotation: 180deg;
			--factor: -1;
		}
		&:before,
		&:after {
			transform: translateX(calc(var(--translation) * var(--factor))) rotate(var(--rotation));
			transition: $mediumDelay transform ease-in-out;
		}

		&:focus:not(:disabled) {
			font-weight: bold;
			outline: none;
			&:before,
			&:after {
				transition: $fastDelay transform ease-in-out;
				font-weight: normal;
			}
			&:before {
				--translation: -50%;
			}
			&:after {
				--translation: 50%;
			}
		}
	}
	&--numbers {
		margin: 0 auto;
		justify-content: center;
		display: flex;
		width: 50%;
		&.display-none {
			display: none;
		}
		&.mobile-hide {
			@include breakMax($breakSmallBefore) {
				display: none;
			}
		}
	}
	&--state {
		margin: auto;
		@include breakMin($breakSmall) {
			display: none;
		}
		&.half-size {
			@include breakMin($breakSmall) {
				display: block;
			}
		}
	}
	&--number {
		text-decoration: underline;
		transition: $fastDelay color ease-in-out, $fastDelay transform ease-in-out;
		padding: 14px 18px;
		color: $colorPrimary;
		font-size: 11px;
		@include breakMin($breakSmall) {
			margin-bottom: 0;
		}
		&:disabled,
		&.active {
			color: $textBlack;
			text-decoration: none;
			font-weight: 700;
			pointer-events: none;
		}
		&.hidden {
			display: none;
		}
		&:focus {
			outline: none;
			&:not(:disabled) {
				font-weight: bold;
				transform: scale(1.1);
			}
		}
		&.dots-after {
			display: flex;
			margin-right: 6px;
			position: relative;
			&:after,
			&::before {
				content: ".\00a0.\00a0.";
				position: absolute;
				color: $textBlack;
				font-weight: 400;
				text-decoration: none;
				pointer-events: none;
			}
			&::before {
				display: none;
				left: -9px;
			}
			&::after {
				right: -9px;
			}
			[dir="rtl"] & {
				&::before {
					display: inline;
				}
				&::after {
					display: none;
				}
			}
		}
	}
}
