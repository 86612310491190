.banner-widget {
	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	}

	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}

	&--close {
		position: absolute;
		right: 13px;
		top: 5px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;

		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}

	position: relative;

	@include breakMin($breakMedium) {
		margin: 0 #{-$pagePadding} 55px;
	}

	@include breakMin($breakLarge) {
		margin: 0;
	}

	.topic-highlight {
		max-width: none;
		flex-wrap: wrap;
		padding: 0 $pagePadding $pagePadding $pagePadding;

		@include breakMin($breakMediumSmall) {
			padding: 30px $pagePadding 60px $pagePadding;
		}

		@include breakMin($breakLarge) {
			padding: 0 calc((100vw - 1170px) / 2) 60px calc((100vw - 1170px) / 2);
		}

		&--highlights {
			margin-top: 0;
			@include breakMin($breakLarge) {
				margin-left: auto;
				[dir="rtl"] & {
					margin-left: 0;
					margin-right: auto;
				}
			}
		}

		&--content {
			align-self: center;
			padding-right: 20px;
			flex-grow: 0;
			@include breakMin($breakSmall) {
				align-self: flex-start;
				padding-right: 20px;
				[dir="rtl"] & {
					padding-left: 20px;
					padding-right: 0;
				}
			}
			@include breakMin($breakMediumSmall) {
				align-self: center;
				width: 50%;
				flex-grow: 1;
			}
			@include breakMin($breakMedium) {
				flex-grow: 0;
			}

			@include breakMin($breakMedium) {
				.title2 {
					font-size: 48px;
					line-height: 52px;
				}
			}

			.box-article-content {
				&--category,
				&--reading,
				&--link {
					display: none;
				}

				&--title {
					margin-bottom: 20px;
				}
				&--description {
					font-size: 20px;
					line-height: 26px;
					display: block;
					margin-bottom: 40px;

					@include breakMin($breakMediumSmall) {
						margin-bottom: 0;
					}
				}
				&--description,
				&--title {
					@include breakMin($breakMedium) {
						width: 100%;
					}
				}
			}
		}

		&--background {
			@include breakMin($breakMedium) {
				height: 100%;

				.back-picture.has-gradient {
					&:after {
						height: 100%;
						top: 0;
						background: linear-gradient(to left, transparent, $gradientColor);
						
						html[dir='rtl'] & {
							background: linear-gradient(to right, transparent, $gradientColor);
						}
					}
				}
			}
		}

		&.fix-height {
			@include breakMin($breakMedium) {
				min-height: 600px;
				align-items: flex-start;
				justify-content: flex-start;
				.box-article-content {
					&--title,
					&--description {
						width: 100%;
					}
				}
			}
		}

		&--logo-container {
			position: relative;
			align-self: center;
			margin: -30px 0 10px;
			@include breakMin($breakMedium) {
				align-self: flex-start;
			}
			@include breakMin($breakMediumSmall) {
				margin: 0;
				margin-left: auto;
				align-self: flex-end;
			}
		}
	}

	nav {
		width: 100%;
		margin-bottom: 70px;

		p {
			color: #fff;
		}
	}
}
