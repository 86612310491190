.job-search {
	&--title {
		margin-bottom: 45px;
	}
	&--description {
		font-size: 12px;
		color: $grey;
		margin-bottom: 25px;
	}
	&--link {
		font-size: 12px;
	}
	.search-field {
		background: none;
	}
	.job-location {
		display: flex;
		align-items: center;
		margin-bottom: 45px;
		a {
			margin-left: 20px;
			[dir="rtl"] & {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}
	.list-container {
		display: none;
		position: absolute;
		top: calc(100% - 1px);
		width: 100%;
		background-color: #fff;
		box-shadow: $menuShadow;
		max-height: 250px;
		overflow-y: auto;
		&.show {
			display: block;
		}
		li {
			font-size: 16px;
			color: $grey;

			button {
				border: none;
				font-size: 14px;
				width: 100%;
				text-align: left;
				cursor: pointer;
				padding: 12px 20px;
				color: $textBlack;
			}
		}
	}
	.job-names {
		position: relative;
		margin-bottom: 30px;
		z-index: 2;
		.list-container button {
			background-color: transparent;
			&:hover,
			&:focus {
				background-color: $colorPrimary;
				color: #fff;
			}
		}
		.is-coloured {
			background-color: $lightGrey;
		}
	}
	.check-drop-down {
		max-width: calc(100% - 155px);
		flex-grow: 1;
		.search-field {
			transition: $mediumDelay border-bottom ease-in-out, $mediumDelay padding-left ease-in-out;
		}
		.check-drop-down--search {
			font-size: 18px;
			background: none;
			color: inherit;
			transition: $mediumDelay padding ease-in-out;
			&::placeholder {
				opacity: 1;
				color: #fff;
			}
		}
		&:not(.open) .check-drop-down--search {
			padding-left: 0;
			[dir="rtl"] & {
				padding-right: 0;
				padding-left: 15px;
			}
		}
		&.open {
			.search-field {
				border-bottom: 1px solid transparent;
			}
		}
	}
}
