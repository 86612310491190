.modal.ir-email-modal-partial {
	&.waiting {
		cursor: wait;
	}

	&.open {
		@include breakMin($breakXtraLarge) {
			grid-template-columns: 1fr auto 1fr;
		}
	}

	&--content {
		@include breakMin($breakXtraLarge) {
			max-width: 40vw;
		}
	}
}

.ir-email-modal {
	flex-grow: 1;
	width: 100%;
	background: white;
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 30px 20px 25px;

	@include breakMin($breakSmall) {
		padding: 45px 80px 60px;
	}

	&--title {
		color: $darkGrey;
		font-size: 30px;
		font-weight: normal;
		margin-bottom: 15px;
	}

	&--subtitle {
		color: $darkGrey;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 50px;
		text-align: center;

		@include breakMin($breakSmall) {
			padding: 0 40px;
		}
	}
	iframe {
		width: 100%;
		height: 50vh;
	}
}

.ir-email-modal--gigya {
	width: 100%;
	max-height: 50vh;
	overflow: auto;
}
