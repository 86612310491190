.written-by {
  display: flex;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 25px;
  &.is-vertically-centered {
    align-items: center;
  }
  &--avatar {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    /* Fix for flex layout stretch */
    min-width: 50px;
    min-height: 50px;
  }
  &--written-by {
    color: $grey;
    font-size: 11px;
  }
  &--name {
    font-weight: 700;
  }
}
