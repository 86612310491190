.sustainability-qa {
	display: flex;
	min-height: 250px;
	height: 100%;
	&:focus {
		outline: none;
	}
	&--block {
		color: #fff;
		position: relative;
		width: 100%;
		padding: 40px;
	}
	&--title {
		font-size: 28px;
		margin-bottom: 16px;
		text-transform: uppercase;
		@include breakMax($breakMediumBefore) {
			word-break: break-word;
		}
	}
	&--description {
		flex-grow: 1;
		min-height: 100px;
		margin-bottom: 20px;
	}
	&--background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: $colorPrimary;
	}
	&--cta:not(.text-black) {
		color: #fff;
		&:before {
			background: #fff;
			color: $colorPrimary;
		}
	}
	&--cta.text-black {
		color: $textBlack;
		&:before {
			background: $textBlack;
			color: $colorPrimary;
		}
	}
	&--cta {
		margin-top: auto;
	}
	&--content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.text-black {
		color: $textBlack;
	}
	&--overlay::-webkit-scrollbar-track {
		background-color: $lightGrey;
	}
	&--overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1001;
		display: flex;
		flex-direction: column;
		visibility: hidden;
		opacity: 0;
		height: 0;
		overflow: hidden;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
			rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		background-color: $lightGrey;
		border-top: 9px solid $colorPrimary;
		border-bottom: 9px solid $colorPrimary;
		transition: $mediumDelay ease-out;
		padding: 60px 5%;
		&-content {
			overflow: auto;
			padding-right: 12px;
			@include breakMin($breakMediumBefore) {
				scrollbar-color: $colorPrimary $lightGrey;
				scrollbar-width: thin;
				&::-webkit-scrollbar {
					width: 6px;
					height: 6px;
					background: $lightGrey;
				}
				&::-webkit-scrollbar-thumb {
					background-color: $colorPrimary;
				}
			}
		}
		&.active {
			visibility: visible;
			opacity: 1;
		}
		&-close {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 30px;
			padding: 0px 10px;
			background-color: $colorPrimary;
			border: none;
			&::after {
				content: '\d7';
				color: #fff;
			}
			&:hover {
				cursor: pointer;
			}
		}
		&-close-wrapper {
			position: absolute;
			right: 0;
			top: 0;
		}
		&-title {
			margin-bottom: 20px;
		}
		&-description {
			margin-bottom: 40px;
			@include breakMin($breakSmall) {
				margin-bottom: 80px;
			}
		}
		&-answer {
			margin: 40px 0;
		}
	}
}
