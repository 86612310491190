
:lang(cs),
:lang(hu),
:lang(kk),
:lang(lt),
:lang(lv),
:lang(ro),
:lang(ru),
:lang(sk),
:lang(sl),
:lang(sr),
:lang(tr),
:lang(uk),
:lang(vi){
	--main-font: #{$robotoFont};
}

:lang(iw){
	--main-font: #{$rubikFont};
}

:lang(ja){
	--main-font: #{$japaneseFont};
}

:lang(ko){
	--main-font: #{$koreanFont};
}

:lang(th){
	--main-font: #{$sarabunFont};
}

:lang(zh){
	--main-font: #{$chineseFont};
}



@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Lato Light Italic'), local('Lato-LightItalic'), url('fonts/lato-v16-latin-300italic.woff2') format('woff2'), url('fonts/lato-v16-300italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Lato Light'), local('Lato-Light'), url('fonts/lato-v16-latin-300normal.woff2') format('woff2'), url('fonts/lato-v16-300normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Lato Regular Italic'), local('Lato-RegularItalic'), url('fonts/lato-v16-latin-400italic.woff2') format('woff2'), url('fonts/lato-v16-400italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Lato Regular'), local('Lato-Regular'), url('fonts/lato-v16-latin-400normal.woff2') format('woff2'), url('fonts/lato-v16-400normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url('fonts/lato-v16-latin-700italic.woff2') format('woff2'), url('fonts/lato-v16-700italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Lato Bold'), local('Lato-Bold'), url('fonts/lato-v16-latin-700normal.woff2') format('woff2'), url('fonts/lato-v16-700normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Lato Light Italic'), local('Lato-LightItalic'), url('fonts/lato-v16-latin-ext-300italic.woff2') format('woff2'), url('fonts/lato-v16-300italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Lato Light'), local('Lato-Light'), url('fonts/lato-v16-latin-ext-300normal.woff2') format('woff2'), url('fonts/lato-v16-300normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Lato Regular Italic'), local('Lato-RegularItalic'), url('fonts/lato-v16-latin-ext-400italic.woff2') format('woff2'), url('fonts/lato-v16-400italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Lato Regular'), local('Lato-Regular'), url('fonts/lato-v16-latin-ext-400normal.woff2') format('woff2'), url('fonts/lato-v16-400normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url('fonts/lato-v16-latin-ext-700italic.woff2') format('woff2'), url('fonts/lato-v16-700italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Lato Bold'), local('Lato-Bold'), url('fonts/lato-v16-latin-ext-700normal.woff2') format('woff2'), url('fonts/lato-v16-700normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-cyrillic-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-cyrillic-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-cyrillic-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-cyrillic-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-cyrillic-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-cyrillic-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-cyrillic-ext-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-cyrillic-ext-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-cyrillic-ext-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-cyrillic-ext-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-cyrillic-ext-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-cyrillic-ext-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-greek-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-greek-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-greek-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-greek-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-greek-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-greek-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-greekExt-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-greekExt-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-greekExt-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-greekExt-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-greekExt-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-greekExt-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-vietnamese-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-vietnamese-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-vietnamese-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-vietnamese-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-vietnamese-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-vietnamese-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-latin-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-latin-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-latin-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-latin-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-latin-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-latin-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/roboto-v20-latin-ext-300italic.woff2') format('woff2'), url('fonts/roboto-v20-300italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Roboto Light'), local('Roboto-Light'), url('fonts/roboto-v20-latin-ext-300normal.woff2') format('woff2'), url('fonts/roboto-v20-300normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular Italic'), local('Roboto-RegularItalic'), url('fonts/roboto-v20-latin-ext-400italic.woff2') format('woff2'), url('fonts/roboto-v20-400italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Roboto Regular'), local('Roboto-Regular'), url('fonts/roboto-v20-latin-ext-400normal.woff2') format('woff2'), url('fonts/roboto-v20-400normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/roboto-v20-latin-ext-700italic.woff2') format('woff2'), url('fonts/roboto-v20-700italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/roboto-v20-latin-ext-700normal.woff2') format('woff2'), url('fonts/roboto-v20-700normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('fonts/rubik-v10-cyrillic-300italic.woff2') format('woff2'), url('fonts/rubik-v10-300italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light'), local('Rubik-Light'), url('fonts/rubik-v10-cyrillic-300normal.woff2') format('woff2'), url('fonts/rubik-v10-300normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular Italic'), local('Rubik-RegularItalic'), url('fonts/rubik-v10-cyrillic-400italic.woff2') format('woff2'), url('fonts/rubik-v10-400italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular'), local('Rubik-Regular'), url('fonts/rubik-v10-cyrillic-400normal.woff2') format('woff2'), url('fonts/rubik-v10-400normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('fonts/rubik-v10-cyrillic-700italic.woff2') format('woff2'), url('fonts/rubik-v10-700italic.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold'), local('Rubik-Bold'), url('fonts/rubik-v10-cyrillic-700normal.woff2') format('woff2'), url('fonts/rubik-v10-700normal.woff') format('woff');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('fonts/rubik-v10-cyrillic-ext-300italic.woff2') format('woff2'), url('fonts/rubik-v10-300italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light'), local('Rubik-Light'), url('fonts/rubik-v10-cyrillic-ext-300normal.woff2') format('woff2'), url('fonts/rubik-v10-300normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular Italic'), local('Rubik-RegularItalic'), url('fonts/rubik-v10-cyrillic-ext-400italic.woff2') format('woff2'), url('fonts/rubik-v10-400italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular'), local('Rubik-Regular'), url('fonts/rubik-v10-cyrillic-ext-400normal.woff2') format('woff2'), url('fonts/rubik-v10-400normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('fonts/rubik-v10-cyrillic-ext-700italic.woff2') format('woff2'), url('fonts/rubik-v10-700italic.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold'), local('Rubik-Bold'), url('fonts/rubik-v10-cyrillic-ext-700normal.woff2') format('woff2'), url('fonts/rubik-v10-700normal.woff') format('woff');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('fonts/rubik-v10-hebrew-300italic.woff2') format('woff2'), url('fonts/rubik-v10-300italic.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light'), local('Rubik-Light'), url('fonts/rubik-v10-hebrew-300normal.woff2') format('woff2'), url('fonts/rubik-v10-300normal.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular Italic'), local('Rubik-RegularItalic'), url('fonts/rubik-v10-hebrew-400italic.woff2') format('woff2'), url('fonts/rubik-v10-400italic.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular'), local('Rubik-Regular'), url('fonts/rubik-v10-hebrew-400normal.woff2') format('woff2'), url('fonts/rubik-v10-400normal.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('fonts/rubik-v10-hebrew-700italic.woff2') format('woff2'), url('fonts/rubik-v10-700italic.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold'), local('Rubik-Bold'), url('fonts/rubik-v10-hebrew-700normal.woff2') format('woff2'), url('fonts/rubik-v10-700normal.woff') format('woff');
	unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('fonts/rubik-v10-latin-300italic.woff2') format('woff2'), url('fonts/rubik-v10-300italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light'), local('Rubik-Light'), url('fonts/rubik-v10-latin-300normal.woff2') format('woff2'), url('fonts/rubik-v10-300normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular Italic'), local('Rubik-RegularItalic'), url('fonts/rubik-v10-latin-400italic.woff2') format('woff2'), url('fonts/rubik-v10-400italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular'), local('Rubik-Regular'), url('fonts/rubik-v10-latin-400normal.woff2') format('woff2'), url('fonts/rubik-v10-400normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('fonts/rubik-v10-latin-700italic.woff2') format('woff2'), url('fonts/rubik-v10-700italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold'), local('Rubik-Bold'), url('fonts/rubik-v10-latin-700normal.woff2') format('woff2'), url('fonts/rubik-v10-700normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('fonts/rubik-v10-latin-ext-300italic.woff2') format('woff2'), url('fonts/rubik-v10-300italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Rubik Light'), local('Rubik-Light'), url('fonts/rubik-v10-latin-ext-300normal.woff2') format('woff2'), url('fonts/rubik-v10-300normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular Italic'), local('Rubik-RegularItalic'), url('fonts/rubik-v10-latin-ext-400italic.woff2') format('woff2'), url('fonts/rubik-v10-400italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Rubik Regular'), local('Rubik-Regular'), url('fonts/rubik-v10-latin-ext-400normal.woff2') format('woff2'), url('fonts/rubik-v10-400normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('fonts/rubik-v10-latin-ext-700italic.woff2') format('woff2'), url('fonts/rubik-v10-700italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Rubik Bold'), local('Rubik-Bold'), url('fonts/rubik-v10-latin-ext-700normal.woff2') format('woff2'), url('fonts/rubik-v10-700normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light Italic'), local('Sarabun-LightItalic'), url('fonts/sarabun-v7-latin-300italic.woff2') format('woff2'), url('fonts/sarabun-v7-300italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light'), local('Sarabun-Light'), url('fonts/sarabun-v7-latin-300normal.woff2') format('woff2'), url('fonts/sarabun-v7-300normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular Italic'), local('Sarabun-RegularItalic'), url('fonts/sarabun-v7-latin-400italic.woff2') format('woff2'), url('fonts/sarabun-v7-400italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular'), local('Sarabun-Regular'), url('fonts/sarabun-v7-latin-400normal.woff2') format('woff2'), url('fonts/sarabun-v7-400normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold Italic'), local('Sarabun-BoldItalic'), url('fonts/sarabun-v7-latin-700italic.woff2') format('woff2'), url('fonts/sarabun-v7-700italic.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold'), local('Sarabun-Bold'), url('fonts/sarabun-v7-latin-700normal.woff2') format('woff2'), url('fonts/sarabun-v7-700normal.woff') format('woff');
	unicode-range:  U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light Italic'), local('Sarabun-LightItalic'), url('fonts/sarabun-v7-latin-ext-300italic.woff2') format('woff2'), url('fonts/sarabun-v7-300italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light'), local('Sarabun-Light'), url('fonts/sarabun-v7-latin-ext-300normal.woff2') format('woff2'), url('fonts/sarabun-v7-300normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular Italic'), local('Sarabun-RegularItalic'), url('fonts/sarabun-v7-latin-ext-400italic.woff2') format('woff2'), url('fonts/sarabun-v7-400italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular'), local('Sarabun-Regular'), url('fonts/sarabun-v7-latin-ext-400normal.woff2') format('woff2'), url('fonts/sarabun-v7-400normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold Italic'), local('Sarabun-BoldItalic'), url('fonts/sarabun-v7-latin-ext-700italic.woff2') format('woff2'), url('fonts/sarabun-v7-700italic.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold'), local('Sarabun-Bold'), url('fonts/sarabun-v7-latin-ext-700normal.woff2') format('woff2'), url('fonts/sarabun-v7-700normal.woff') format('woff');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light Italic'), local('Sarabun-LightItalic'), url('fonts/sarabun-v7-vietnamese-300italic.woff2') format('woff2'), url('fonts/sarabun-v7-300italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light'), local('Sarabun-Light'), url('fonts/sarabun-v7-vietnamese-300normal.woff2') format('woff2'), url('fonts/sarabun-v7-300normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular Italic'), local('Sarabun-RegularItalic'), url('fonts/sarabun-v7-vietnamese-400italic.woff2') format('woff2'), url('fonts/sarabun-v7-400italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular'), local('Sarabun-Regular'), url('fonts/sarabun-v7-vietnamese-400normal.woff2') format('woff2'), url('fonts/sarabun-v7-400normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold Italic'), local('Sarabun-BoldItalic'), url('fonts/sarabun-v7-vietnamese-700italic.woff2') format('woff2'), url('fonts/sarabun-v7-700italic.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold'), local('Sarabun-Bold'), url('fonts/sarabun-v7-vietnamese-700normal.woff2') format('woff2'), url('fonts/sarabun-v7-700normal.woff') format('woff');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light Italic'), local('Sarabun-LightItalic'), url('fonts/sarabun-v7-thai-300italic.woff2') format('woff2'), url('fonts/sarabun-v7-300italic.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 300;
	font-display: swap;				
	src: local('Sarabun Light'), local('Sarabun-Light'), url('fonts/sarabun-v7-thai-300normal.woff2') format('woff2'), url('fonts/sarabun-v7-300normal.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular Italic'), local('Sarabun-RegularItalic'), url('fonts/sarabun-v7-thai-400italic.woff2') format('woff2'), url('fonts/sarabun-v7-400italic.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 400;
	font-display: swap;				
	src: local('Sarabun Regular'), local('Sarabun-Regular'), url('fonts/sarabun-v7-thai-400normal.woff2') format('woff2'), url('fonts/sarabun-v7-400normal.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
	font-family: 'Sarabun';
	font-style: italic;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold Italic'), local('Sarabun-BoldItalic'), url('fonts/sarabun-v7-thai-700italic.woff2') format('woff2'), url('fonts/sarabun-v7-700italic.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
	font-family: 'Sarabun';
	font-style: normal;
	font-weight: 700;
	font-display: swap;				
	src: local('Sarabun Bold'), local('Sarabun-Bold'), url('fonts/sarabun-v7-thai-700normal.woff2') format('woff2'), url('fonts/sarabun-v7-700normal.woff') format('woff');
	unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}