.article-carousel {
	--color-black: #{$rgbBlack};
	--white: #{$pmiWhite};
	.small-image-above-title {
		&--content {
			flex-grow: 1;
		}
		&--description {
			padding-bottom: 25px;
			color: $textColor;
		}
		&--link {
			flex-grow: 1;
		}
		.arrowed-link {
			margin-top: auto;
			color: $textColor;
			&:before {
				color: $textWhite;
				background: $textPrimary;
			}
		}
	}
}
