$ingredientsMenuMargin: 30px;

.ingredient-finder {
	display: flex;
	flex-flow: column;
	align-items: center;
	margin-bottom: 80px;
	&--title {
		font-size: 30px;
		line-height: 36px;
		padding-bottom: 25px;
		text-align: center;
	}
	&--subtitle {
		font-size: 18px;
		line-height: 30px;
		color: $darkGrey;
		padding-bottom: 65px;
		text-align: center;
	}
	&--disclaimer {
		font-size: 12px;
		line-height: 18px;
		font-weight: 300;
		color: $grey;
		text-align: center;
		p {
			margin-bottom: 18px;
		}
	}
	&--menu {
		position: relative;
		margin-bottom: 65px;
		opacity: 0.3;
		transition: $fastDelay opacity ease-in-out;
		pointer-events: none;
		min-width: 100%;
		@include breakMin($breakXtraSmall) {
			min-width: 340px;
		}
		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}
	&--drop-button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $colorPrimary;
		height: 75px;
		padding-left: 40px;
		padding-right: 35px;
		border: none;
		color: white;
		font-size: 18px;
		width: 100%;
		&:after {
			content: $icon-down;
			font-family: $iconFont;
			font-size: 10px;
			padding-top: 3px;
			margin-left: 20px;
			transition: $mediumDelay transform ease-in-out;
			.open & {
				transform: rotate(180deg);
			}
		}
	}
	&--list {
		margin: $ingredientsMenuMargin 0;
	}
	&--choice {
		font-size: 18px;
		background: none;
		border: none;
		padding: 10px 40px;
		width: 100%;
		text-align: left;
		&:hover {
			background: $colorPrimary;
			color: white;
		}
	}
	&--dropdown {
		z-index: 100;
		position: absolute;
		top: 75px;
		height: 0;
		width: 100%;
		overflow: auto;
		background: $lightGrey;
		transition: $mediumDelay height ease-in-out;
	}
	&--ingredients {
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-flow: column;
		@include breakMin($breakSmall) {
			flex-flow: row;
		}
	}
	&--container {
		height: 0;
		overflow: hidden;
		width: 100%;
		transition: $mediumDelay height ease-in-out;
	}
	&--section {
		display: flex;
		flex-flow: column;
		width: 100%;
		@include breakMin($breakSmall) {
			width: calc(50% - 12px);
		}
		&-title {
			font-size: 20px;
			font-weight: normal;
			margin-bottom: 20px;
		}
	}
	&--documents {
		background: $lightGrey;
		padding: 25px 20px;
		flex-grow: 1;
		margin-bottom: 30px;
		@include breakMin($breakSmall) {
			margin-bottom: 80px;
		}
		li {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&--document {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: $darkGrey;
		&:before {
			font-family: $iconFont;
			font-size: 28px;
			color: $colorPrimary;
			margin-right: 15px;
		}
		&.pdf:before {
			content: $icon-pdffile;
		}
		&.xls:before {
			content: $icon-excel;
		}
		&.doc:before {
			content: $icon-word;
		}
	}
	&--document-title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&--scheme {
		position: relative;
		margin-bottom: 80px;
		display: none;

		@include breakMin($breakMedium) {
			display: block;
		}

		p {
			font-size: 14px;
			color: $colorPrimary;
		}
	}
	&--combustible {
		img {
			width: 604.5px;
			height: auto;
		}
		.label {
			&--monogram-inks {
				position: absolute;
				top: -11px;
				left: 285px;
				transform: translateX(-100%);
			}
			&--slide-seam-adhesives {
				position: absolute;
				top: -4px;
				left: 612px;
				min-width: 120px;
			}
			&--cigarette-papers {
				position: absolute;
				top: 147px;
				left: 572px;
				min-width: 200px;
			}
			&--ingredients-added {
				position: absolute;
				top: 190px;
				left: 455px;
			}
			&--tipping-papers {
				position: absolute;
				top: 208px;
				left: 206px;
			}
			&--filter {
				position: absolute;
				top: 143px;
				left: -35px;
			}
			&--filter-adhesives {
				position: absolute;
				top: 97px;
				left: -9px;
				max-width: 120px;
				text-align: right;
				transform: translateX(-100%);
			}
			&--filter-wrap {
				position: absolute;
				top: 21px;
				left: 122px;
				transform: translateX(-100%);
			}
		}
	}
	&--rrp {
		img {
			width: 795px;
			max-width: 795px;
			height: auto;
		}
		.label {
			&--heatstick-paper {
				position: absolute;
				top: 38px;
				left: 88px;
				transform: translateX(-100%);
			}
			&--hollow-tube {
				position: absolute;
				top: 25px;
				left: 300px;
				transform: translateX(-100%);
			}
			&--pla-crimped-filter {
				position: absolute;
				top: -11px;
				left: 489px;
				transform: translateX(-100%);
			}
			&--pla-crimped-filter-bis {
				position: absolute;
				top: 285px;
				left: 595px;
			}
			&--tipping-paper {
				position: absolute;
				top: -21px;
				left: 538px;
			}
			&--filter {
				position: absolute;
				top: 88px;
				left: 766px;
			}
			&--filter-plug-wrapper {
				position: absolute;
				top: 172px;
				left: 798px;
				min-width: 120px;
			}
			&--hat-plug-wrapper {
				position: absolute;
				top: 321px;
				left: 317px;
			}
			&--tobacco-plug-wrapper {
				position: absolute;
				top: 368px;
				left: 177px;
			}
			&--ingredients-added {
				position: absolute;
				top: 185px;
				left: 11px;
				max-width: 80px;

				transform: translateX(-100%);
				text-align: right;
				@include breakMin(1050px) {
					top: 167px;
					left: -6px;
					max-width: 120px;
				}
			}
		}
	}
}
