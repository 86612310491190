.faq {
	position: relative;
	margin-bottom: 60px;
	.wrapper {
		margin: 60px auto 30px;
		&--title {
			font-size: 30px;
			font-weight: normal;
			color: $textBlack;
		}

		&--subtitle {
			margin-top: 10px;
			color: $grey;
			@include breakMin($breakMedium) {
				width: calc(40% - 50px);
			}
		}
	}

	.main {
		@include breakMin($breakMedium) {
			display: flex;
			margin-bottom: 100px;
		}
		.category {
			&--container {
				@include breakMin($breakMedium) {
					max-width: 300px;
					width: 40%;
					position: relative;
				}
			}

			&--menu {
				display: none;

				@include breakMin($breakMedium) {
					display: block;
					max-width: 300px;
				}
				li {
					display: flex;
				}
			}
			&--link {
				font-size: 16px;
				margin-bottom: 20px;
				font-weight: 200;
				color: $grey;
				padding-left: 55px;
				position: relative;
				transition: $mediumDelay color ease-in-out;

				&::before,
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					display: block;
					height: 1px;
					background-color: $colorPrimary;
					width: 0;
					margin-right: 15px;
					transition: $slowDelay width ease-in-out;
				}
				&::after {
					background-color: $medlightGrey;
					transition: $fastDelay width ease-in-out;
				}
				&::before {
					z-index: 1;
				}
				&:hover,
				&:focus {
					color: $colorPrimaryHover;
					text-decoration: none;
					outline: none;
					&::after {
						width: 28px;
						background: $grey;
					}
				}
				&.current {
					color: $colorPrimary;
					&::before,
					&::after {
						width: 28px;
					}
				}
			}
		}

		.faq--content {
			flex-basis: 70%;
			margin-top: -30px;

			.faq--category {
				&--heading {
					font-size: 28px;
					font-weight: 300;
					color: $colorPrimary;
					margin-top: 50px;
					margin-bottom: 30px;
				}
				@include breakMin($breakMedium) {
					&:first-child {
						.faq--category--heading {
							margin-top: 20px;
						}
					}
				}
			}

			.entry {
				&--question {
					position: relative;
					font-size: 20px;
					background-color: $lightGrey;
				}
				&--unfold {
					padding: 20px 30px 20px 15px;
					border: 1px solid transparent;
					background: none;					
					outline: none;
					width: 100%;
					text-align: left;
					font-size: inherit;
    				font-weight: inherit;
					cursor: pointer;
					&:focus {
						border: 1px solid $colorPrimary;
					}
					&:after {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 15px;
						content: $icon-down;
						color: $colorPrimary;
						font-size: 6px;
						font-family: $iconFont;
						margin-left: 20px;
						font-style: normal;
						transition: $mediumDelay transform ease-in-out;
					}
					&[aria-expanded="true"]::after {
						transition: $fast;
						transform: rotate(180deg);
					}
				}
				&--container {
					height: 0;
					overflow: hidden;
					transition: $mediumDelay height ease-in-out;
					margin-bottom: 20px;
				}
				&--answer {
					padding: 20px 50px 20px 25px;
				}
				&--description {
					font-weight: 300;
					line-height: 24px;
					padding-bottom: 15px;
				}

				&--read-more {
					text-transform: uppercase;
					font-size: 12px;
					letter-spacing: 2px;

					&:after {
						content: $icon-longarrowright;
						font-size: 7px;
						font-family: $iconFont;
						margin-left: 10px;
					}
				}
			}
		}
	}
}