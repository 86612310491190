.topic-highlight {
	position: relative;
	background: black;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	padding: 120px $pagePadding $pagePadding;
	margin: 0 -#{$pagePadding} $subWidgetSpacingMobile;
	width: calc(100% + 2 * #{$pagePadding});
	overflow: hidden;

	@include breakMin($breakMediumSmall) {
		flex-flow: row;
		justify-content: space-between;
		align-items: flex-end;
		padding: 170px 30px 50px;

		.box-article-content--description {
			display: block;
		}
	}

	@include breakMin($breakMedium) {
		width: 100%;
		padding: 70px 40px 50px 60px;
		margin: 0 auto $subWidgetSpacing;
	}

	&--background {
		top: 0;
		left: 0;
		position: absolute;
		height: 100%;
		min-height: 300px;
		overflow: hidden;
		width: 100%;

		.back-picture.has-gradient:after {
			background: linear-gradient(transparent, $gradientColor);
			height: calc(100% - 20px);
		}
	}

	&--video {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			background-color: rgba(0,0,0,.15);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
		}
	}

	&--content {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		flex-grow: 1;
		margin-bottom: 30px;

		@include breakMin($breakMedium) {
			align-self: flex-end;
		}
	}

	&--link-container {
		position: static;
		display: flex;
		flex-grow: 1;
		align-self: stretch;
		outline: none;
	}

	&--highlights {
		display: flex;
		flex-flow: column;
		max-width: 445px;
		margin-top: 60px;

		@include breakMin($breakMedium) {
			flex-flow: row;
			align-items: flex-end;
		}

		@include breakMax($breakMediumSmallBefore) {
			width: calc(100% + #{2 * $pagePadding});
			max-width: none;
			background: $lightGrey;
			margin: -$pagePadding;
			margin-top: 0;
			padding: $pagePadding;

			.box-article-content {

				&--title,
				&--reading {
					color: $darkGrey;
				}

				&--link,
				&--category {
					color: $colorPrimary;
				}
			}

			.highlight-container:last-child {
				margin-bottom: 0;
			}
		}
	}

	.highlight-container {
		margin-bottom: 30px;

		@include breakMin($breakMediumSmall) {
			width: 300px;
		}

		@include breakMin($breakMedium) {
			min-height: 370px;
			display: flex;
			margin-right: 25px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}

			[dir="rtl"] & {
				margin-left: 25px;
				margin-right: 0;
			}
		}
	}

	&--download {
		@include breakMin($breakMediumSmall) {
			margin-top: 20px;
			margin-bottom: 0;
		}

		min-height: auto;
		position: relative;
		display: flex;
		background-color: $colorPrimary;
		padding: 10px 20px;
		justify-content: center;
		align-items: center;
		transition: $mediumDelay background ease-in-out,
		$mediumDelay color ease-in-out;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			margin-right: 15px;
			content: $icon-pdffile;
			font-family: $iconFont;
			font-size: 22px;
			color: #fff;
		}

		&:hover {
			background: $colorPrimaryHover;

			.topic-highlight--download-icon {
				background: $colorPrimaryHover;
				color: #fff;
				border: 1px solid #fff;
			}
		}

		//To prevent persistent hover state on mobile after clicking the link
		@include breakMax($breakMediumSmall) {
			&.no-hover-mobile {
				background: $colorPrimary;

				.topic-highlight--download-icon {
					background: #fff;
					color: $colorPrimary;
				}
			}
		}


	}

	&--download-link {
		font-size: 14px;
		font-weight: bold;
		color: #fff;
		margin-right: 20px;

		&:after {
			content: ' ';
			position: absolute;
			font-weight: normal;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		}
	}

	&--download-label {
		text-transform: uppercase;
		font-size: 12px;
		color: #fff;
		letter-spacing: 2px;
		margin-right: 10px;
	}

	&--download-icon {
		width: 30px;
		min-width: 30px;
		height: 30px;
		background: #fff;
		border-radius: 50%;
		color: $colorPrimary;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid transparent;
		transition: $mediumDelay background ease-in-out,
			$mediumDelay color ease-in-out, $mediumDelay border ease-in-out;

		&::before {
			content: $icon-download;
			font-family: $iconFont;
			font-size: 13px;
		}
	}

}
