.tobacco-economics--download {	
	padding-left: 30px;
	padding-right: 30px;
	height: 60px;
	background: $buttonGrey;
	color: white;
	border: none;
	font-size: 14px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-top: 15px;
	transition: $mediumDelay background ease-in-out;
	font-weight: bold;
	cursor: default;
	&.active {
		background: $colorPrimary;
		cursor: pointer;
		&:hover {
			background: $colorPrimaryHover;
		}
	}
	&-all {
		margin-left: auto;
		color: $colorPrimary;
		font-size: 12px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		font-weight: normal;
		line-height: 15px;
		text-align: center;
		&::after {
			content: $icon-download;
			background-color: $colorPrimary;
			color: $lightGrey;
			font-family: $iconFont;
			font-size: 12px;
			letter-spacing: normal;
			font-weight: normal;
			margin-left: 15px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			@include breakMax($breakXtraSmall) {
				margin: 5px 5px 0 0;
			}
		}
	}
}
