$icon-facebook: "\e800";
$icon-glassdoor: "\e801";
$icon-linkedin: "\e802";
$icon-twitter: "\e803";
$icon-youtube: "\e804";
$icon-tags: "\e805";
$icon-tailarrowright: "\e806";
$icon-longarrowright: "\e807";
$icon-longarrowleft: "\e808";
$icon-link: "\e809";
$icon-cross: "\e80c";
$icon-arrowleft: "\e80d";
$icon-arrowright: "\e80e";
$icon-down: "\e80f";
$icon-download: "\e810";
$icon-droparrow: "\e811";
$icon-search: "\e813";
$icon-share: "\e814";
$icon-plus: "\e815";
$icon-graphup: "\e80a";
$icon-graphdown: "\e80b";
$icon-circledownload: "\e812";
$icon-circlelink: "\e816";
$icon-play: "\e817";
$icon-share-filled: "\e821";
$icon-sort: "\e818";
$icon-filter: "\e819";
$icon-vsign: "\e81a";
$icon-pdffile: "\e81b";
$icon-calendar: "\e81c";
$icon-phone: "\e81d";
$icon-mail: "\e81e";
$icon-excel: "\e81f";
$icon-word: "\e820";
$icon-world: "\e822";
$icon-quote: "\e823";
$icon-clock: "\e902";
$icon-position: "\e903";
$icon-suitcase: "\e904";
$icon-label: "\e905";
$icon-mailFilled: "\e906";
$icon-fax: "\e907";
$icon-growing: "\e908";
$icon-processing: "\e909";
$icon-product: "\e90a";
$icon-suppliers: "\e90b";
$icon-distributive: "\e90c";
$icon-taxes: "\e90d";
$icon-consumers: "\e90e";
$icon-instagram: "\e90f";
$icon-selectAll: "\e910";
$icon-press: "\e900";
$icon-news: "\e901";
$icon-gallery: "\e911";
$icon-xbrl: "\e912";
$icon-file: "\e913";
$icon-lightClock: "\e914";
$icon-filledPosition: "\e915";
$icon-outLink: "\e916";
$icon-vk: "\e917";
$icon-taxes1: "\e918";
$icon-sustain-innovating: "\e919";
$icon-sustain-operating: "\e91a";
$icon-sustain-caring: "\e91b";
$icon-sustain-protecting: "\e91c";
$icon-back-arrow: "\e91d";
$icon-pause: "\e91e";
$icon-speaker: "\e920";
$icon-mini-cross: "\e91f";
$icon-vol-min: "\e921";
$icon-vol-max: "\e922";
