.nav-layout {
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 2001;
	padding: 0 $pagePadding;
	background: white;
	width: 100%;
	top: 0;
	left: 0;
	height: auto;
	flex-flow: column;
	&.has-sub {
		justify-content: space-between;
		padding-bottom: 0;
	}

	&.has-market-redirect {
		transform: translateY($market-redirect-height);

		@include breakMin($breakMedium) {
			transform: translateY($market-redirect-height-medium);
		}

		@include breakMin($breakLarge) {
			transform: translateY($market-redirect-height-large);
		}
	}

	@include breakMin($breakNavigation) {
		padding: 20px 0 10px 0;
		transition: $slowDelay transform ease-in-out,
			$slowDelay opacity ease-in-out;

		body.scrolled & {
			transform: translateY(-100%);
			opacity: 0;
		}
	}

	&--container {
		align-items: center;
		position: static;
		margin: 8px auto;
		display: flex;
		width: 100%;
		z-index: 1100;

		@include breakMin($breakNavigation) {
			width: calc(100% - 40px);
		}
	}

	&--grow {
		display: flex;
		flex-grow: 1;

		@include breakMin($breakNavigation) {
			align-items: flex-start;
		}
	}

	&--content {
		position: relative;
		display: flex;
		align-self: stretch;
	}
	&--sub-nav {
		width: 100%;
	}
}
