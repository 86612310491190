.highlight {
	position: relative;
	display: flex;
	flex-flow: row;
	flex-grow: 1;
	justify-content: flex-end;
	min-height: 275px;

	&:focus:not(:hover) {
		outline: $blockFocusOutline;
	}

	&:first-child {
		flex-grow: 1;
	}

	&--content {
		display: flex;
		flex-flow: column;
		padding: 25px;
		justify-content: flex-end;
		width: 100%;

		.box-article-content--title {
			font-size: 16px;
			line-height: 20px;

			@include breakMin($breakMedium) {
				font-size: 22px;
				line-height: 26px;
			}
		}

		&.is-section {
			justify-content: flex-end;
			align-items: center;
			text-align: center;

			.box-article-content--title {
				font-size: 20px;
				line-height: 24px;
				margin-bottom: 26px;
				max-width: 170px;
				align-items: center;
				@include breakMin($breakMedium) {
					font-size: 26px;
					line-height: 34px;
				}
			}
		}

		.box-article-content--reading {
			margin-bottom: 0;
		}
	}
	.mobile-square-style & {
		min-height: 0;

		@include breakMin($breakMedium) {
			min-height: 275px;
		}

		&--background {
			position: relative;
			min-width: 107px;
			height: 107px;
			margin-right: 20px;
			@include breakMin($breakMedium) {
				flex-grow: 1;
				position: static;
				min-width: 0;
				min-height: 0;
				margin-right: 0;
				margin-left: 0;
			}
			[dir="rtl"] & {
				margin-right: 0;
				@include breakMax($breakMediumBefore) {
					margin-left: 20px;
				}
			}
		}

		&--content {
			padding: 0;
			justify-content: center;
			align-items: flex-start;

			@include breakMin($breakMedium) {
				padding: 20px 20px 40px;
				justify-content: flex-end;
			}

			.box-article-content--title {
				text-align: left;
			}

			.box-article-content--link {
				text-align: left;
			}

			[dir="rtl"]  & {
				.box-article-content--title {
					text-align: right;
				}

				.box-article-content--link {
					text-align: right;
				}
			}

			&.is-section {
				align-items: flex-start;

				@include breakMin($breakMedium) {
					align-items: center;

					.box-article-content--title {
						text-align: center;
						font-size: 26px;
						line-height: 34px;
						align-items: center;
						> a {
							text-align: inherit;
						}
					}
				}
			}
		}
	}
}
