$buttonHeight: 55px;

.drop-down {
	display: none;
	flex-flow: column;
	margin-top: 2px;

	--drop-height: 0px;

	@include breakMin($breakNavigation) {
		display: flex;
	}

	position: relative;
	cursor: pointer;

	&--button {
		display: flex;
		font-size: 12px;
		font-family: $mainFont;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		align-items: center;
		padding: 0 22px;
		height: $buttonHeight;
		position: relative;
		z-index: 1010;
		margin-bottom: -18px;
		word-break: keep-all;

		border: none;
		background: none;
		cursor: pointer;
		transition: $fast;

		&:focus {
			outline: none;
		}
	}

	&--dot {
		width: 11px;
		height: 11px;
		background: $colorPrimary;
		border-radius: 50%;
		margin-right: 7px;
		[dir="rtl"] & {
			margin-right: 0;
			margin-left: 7px;
		}
	}

	&--arrow {
		margin-left: 11px;

		[dir="rtl"] & {
			margin-left: 0;
			margin-right: 11px;
		}
		&::before {
			content: $icon-droparrow;
			transition: $fast;
			transform: rotate(180deg);
			font-family: $iconFont;
			color: $colorPrimary;
			font-size: 6px;
			display: block;
		}

		&.open {
			&::before {
				transform: rotate(0);
			}
		}
	}

	&--list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: white;
		transition: $fastDelay box-shadow ease-in-out, $mediumDelay height ease-in-out;
		padding: 0 20px;
		overflow: hidden;
		height: calc(var(--drop-height) + #{$buttonHeight});

		[dir="rtl"] & {
			text-align: right;
		}
		li {
			list-style-type: none;
			display: block;
			width: 100%;
		}
	}
	&--link {
		font-size: 12px;
		line-height: 22px;
		color: $textBlack;
		transition: $fastDelay color ease-in-out;
		display: block;
		width: 100%;

		&:hover,
		&:focus {
			color: $colorPrimaryHover;
			text-decoration: none;
			transition: none;
			outline: none;
		}
	}

	&--content {
		opacity: 0;
		margin-top: 55px;
		margin-bottom: 0;
		flex: 1 0 auto;
		.open & {
			opacity: 1;
		}
	}

	&--list,
	&--content {
		display: flex;
		flex-flow: column;
	}

	// hovered focused and open
	&:hover,
	&.focused,
	&.open {
		.drop-down--list {
			box-shadow: $menuShadow;
		}
	}
}
