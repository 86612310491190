.lost-5-columns {
  @media screen and (min-width: $lost-medium-screen) {
    display: flex;
    lost-utility: clearfix;
    > div {
      display: flex;
      flex-flow: column wrap;
      lost-column: 1/5 5 $lost-grid-layout-4columns-gutter flex;
    }
  }
}
