.mobile-modal-title {
	width: 100%;
	padding: 25px 0;
	background: $lightGrey;
	text-align: center;
	position: relative;
	font-size: 14px;
	text-transform: uppercase;
	color: $darkGrey;
	letter-spacing: 0.15rem;
	border: none;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		background: $darkGrey;
		width: 2px;
		height: 22px;
		position: absolute;
		top: 50%;
		right: 30px;
	}

	&::before {
		transform: translateY(-50%) rotate(45deg);
	}

	&::after {
		transform: translateY(-50%) rotate(-45deg);
	}

	@include breakMin($breakMediumSmall) {
		display: none;
	}
}

.carousel-dot {
	width: 6px;
	height: 6px;
	opacity: 0.5;
	margin: 0 5px;
	transition: $fastDelay opacity ease-in-out, $fastDelay width ease-in-out,
		$fastDelay height ease-in-out, $fastDelay margin ease-in-out;

	&.active {
		opacity: 1;
		width: 10px;
		height: 10px;
	}
}

.basic-layout {
	.sf_colsIn {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.cta {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 20px 30px;
	font-size: 12px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	text-align: center;
	border: 1px solid $colorPrimary;
	margin-bottom: 20px;
	background: $colorPrimary;
	color: white;
	transition: $mediumDelay color ease-in-out,
		$mediumDelay background ease-in-out;
	&::after {
		content: $icon-tailarrowright;
		font-family: $iconFont;
		margin-left: 5px;
		font-size: 10px;
		display: block;
		transition: $mediumDelay transform ease-in-out;
	}
	&:hover {
		color: white;
		background: $colorPrimaryHover;
		&::after {
			transform: translateX(5px);
		}
	}
}
