.download-pop-up {
	position: fixed;
	bottom: 0;
	margin-bottom: 10px;
	background-color: $colorPrimary;
	right: 0;
	z-index: 1001;
	padding: 15px;
	width: 300px;
	transition: all 0.3s ease-in-out;

	&.gray {
		background-color: $medlightGrey;

		.modal--close-button {
			&:before {
				background-color: $medlightGrey;
			}
			&:after {
				color: $textBlack;
			}
			&:hover::before {
				background-color: $medlightGrey;
			}
			&:focus-visible {
				border: 1.5px solid $textBlack;
				border-radius: 4px;

				&::before {
					background-color: transparent;
				}
			}
		}
	}

	&.hidden,
	&.closed {
		visibility: hidden;
		opacity: 0;
		z-index: 0;
	}

	&--title {
		color: #fff;
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 10px;

		.gray & {
			color: $textBlack;
		}
	}

	&--subtitle {
		color: #fff;
		font-size: 14px;
		margin-bottom: 10px;

		.gray & {
			color: $mediumGrey;
		}
	}

	&--download {
		color: $colorPrimary;
		background-color: #fff;
		display: flex;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		font-size: 12px;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 55px;

		.gray & {
			background-color: $colorPrimary;
			color: #fff;

			&:after {
				border: 2px solid #fff;
				background-color: #fff;
				color: $colorPrimary;
			}

			&:focus-visible {
				outline-color: $textBlack;
			}
		}

		&:focus {
			outline: auto;
		}

		&:after {
			content: $icon-download;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $iconFont;
			font-size: 14px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 2px solid $colorPrimary;
			background-color: $colorPrimary;
			transition: $fastDelay all ease-in-out;
			color: #fff;
			margin-left: 15px;
			padding-left: 1px;
		}
	}

	&--container {
		display: flex;
		flex-flow: column;
		position: relative;
	}

	.modal--close-button {
		align-self: flex-end;
		height: 20px;
		width: 20px;
		position: absolute;
		right: -2px;
		top: 0;

		&:focus-visible {
			border: 1.5px solid #fff;
			border-radius: 4px;

			&::before {
				background-color: $colorPrimary;
			}
		}

		&:hover::before {
			background-color: $colorPrimary;
		}
	}
}
