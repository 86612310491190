.tbe-selectables {
	overflow-y: scroll;
	width: 100%;
	max-height: 250px;

	&--element {
		text-align: left;
		background: $lightGrey;
		width: 100%;
		padding: 10px 40px 10px 20px;
		margin-bottom: 1px;
		border: none;
		&.checked {
			position: relative;
			background: $colorPrimary;
			color: white;
			&:after {
				content: $icon-cross;
				font-family: $iconFont;
				font-size: 10px;
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
			}
		}
	}
}
