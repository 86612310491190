.investor-cta-widget {
	display: block;
	padding: 15px;
	margin-bottom: 16px;
	border: 1px solid $buttonGrey;
	&:focus {
		border-color: $colorPrimary;
		outline: none;
	}
	.investor-cta {
		&--title {
			color: $grey;
		}
		&--description {
			color: $textBlack;
		}
	}
}
