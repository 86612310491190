.editors-pick-widget {
	&--title {
		margin-bottom: 17px;
	}
	.category-title {
		margin-bottom: 6px;
	}
	&--content {
		background: $lightGrey;
		margin: 0 -#{$pagePadding};
		padding: 30px #{$pagePadding};

		@include breakMin($breakSmall) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 25px;
		}

		@include breakMin($breakMediumSmall) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakMin($breakLarge) {
			display: block;
			padding: 40px 20px 50px;
			margin: 0;
		}
	}
}

.pick-widget {
	display: flex;
	flex-flow: column;
	margin-bottom: 30px;
	.category-title:focus {
		outline: none;
		color: $textBlack;
		font-weight: bold;
	}
	&--title {
		display: block;
		color: $textBlack;
		margin-bottom: 15px;
		&:focus {
			outline: none;
			color: $colorPrimary;
		}
	}
}
