.basic-page-info-widget {
	&--block {
		margin-top: 10px;
	}
	&--table {
		width: 100%;
	}
	&--header {
		border-bottom: 1px solid $mediumGrey;
		background-color: $colorPrimary;
	}
	&--column {
		color: #fff;
		padding: 10px;
	}
	&--row {
		border-bottom: 1px solid $mediumGrey;
	}
	&--cell {
		padding: 10px;
		font-size: 14px;
	}
}
