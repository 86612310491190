.layout-1-3-1 {
	// allows sticky positionning relative to the layout
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;
	margin: 0 auto;
	margin-bottom: $subWidgetSpacingMobile;

	> .sf_colsIn {
		width: 100%;
		margin-bottom: $subWidgetSpacingMobile;

		&:last-child {
			margin-bottom: 0;
		}
		@include breakMin($breakLarge) {
			margin-bottom: 0;
		}
		@include breakMin($breakSmall) {
			max-width: $smallWrapperMax;
		}
	}
	@include breakMin($breakLarge) {
		flex-flow: row;
		justify-content: space-between;
		align-items: flex-start;
		> .sf_colsIn {
			margin-bottom: 0;
			&:nth-child(1),
			&:nth-child(3) {
				width: 18%;
			}
			&:nth-child(2) {
				width: 59%;
			}
		}
	}
}
