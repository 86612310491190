.document-download-partial {
	position: relative;
	background: $colorPrimary;
	padding: 25px 30px;
	color: white;
	display: flex;
	justify-content: space-between;
	flex-flow: column;
	&--background {
		z-index: -1;
	}
	&--date {
		font-size: 11px;
		margin-bottom: 8px;
		.grey & {
			color: $grey;
		}
		.black & {
			color: $textBlack;
		}
	}
	&--title {
		color: white;
		margin-bottom: 20px;
		.grey & {
			color: $textBlack;
		}
		.black & {
			color: $textBlack;
		}
	}
	&--title-list {
		word-break: break-word;
	}
	&--no-download {
		font-size: 12px;
		color: white;
		.grey & {
			color: $textBlack;
		}
		.black & {
			color: $textBlack;
		}
	}
	&--dot-seperator {
		margin-left: 10px;
		position: relative;
		.black & {
			color: $textBlack;
		}
		&:after {
			content: '\002E';
			width: 100%;
			height: 100%;
			position: absolute;
			top: -3px;
		}
	}
	&--download {
		color: white;
		font-size: 12px;
		display: flex;
		align-items: center;
		--icon-spacing: 8px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&:before {
			font-family: $iconFont;
			color: $colorPrimary;
			margin-right: var(--icon-spacing);
			transition: $mediumDelay margin-right ease-in-out,
				$mediumDelay margin-left ease-in-out;
			[dir='rtl'] & {
				margin-right: 0;
				margin-left: var(--icon-spacing);
			}
		}
		&.blue {
			&:hover {
				color: white;
			}
			&:before {
				content: $icon-download;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				letter-spacing: 0;
				background: white;
				min-width: 30px;
				height: 30px;
				border-radius: 50%;
			}
		}
		&.grey {
			color: $textBlack;
			margin-bottom: 8px;
			&:before {
				content: $icon-circledownload;
				font-size: 30px;
			}
		}
		&.black {
			color: $textBlack;
			margin-bottom: 8px;
			&:after {
				content: $icon-circledownload;
				font-size: 30px;
			}
		}
	}
	&--download-list {
		color: white;
		font-size: 12px;
		display: flex;
		align-items: center;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&::after {
			content: $icon-circledownload;
			font-family: $iconFont;
			font-size: 28px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			margin-left: 10px;
		}
		&.grey {
			color: $textBlack;
			&:after {
				color: $textBlack;
			}
		}
		.black & {
			color: $textBlack;
			&:after {
				color: $textBlack;
			}
		}
	}
	&:hover {
		.document-download-partial--download {
			--icon-spacing: 20px;
		}
	}
	&:not(.grey):hover {
		color: white;
	}
	&.grey {
		background: $lightGrey;
	}
	&.no-background {
		background: none;
	}
	&--size {
		display: inline-block;
		font-size: 11px;
		margin-bottom: 15px;
		&.grey {
			color: $textBlack;
		}
		.black & {
			color: $textBlack;
		}
	}
	&--format {
		display: inline-block;
		margin-left: 15px;
		font-size: 11px;
		&.grey {
			color: $textBlack;
		}
		.black & {
			color: $textBlack;
		}
	}
}
.document-download-partial--flex-end {
	justify-content: flex-end;
}
