.social-share {
	z-index: $z-social-share;
	width: 200px;

	&--title {
		font-size: 20px;
		margin-bottom: 20px;
	}

	&.not-working {
		.social-list-element--addthis {
			display: none;
		}
	}

	.social-list {
		display: flex;
		max-width: 500px;
		justify-content: space-between;

		@include breakMin($breakLarge) {
			display: block;
		}
	}

	.social-list--element {
		&:last-child {
			margin-bottom: 0;
		}

		a,
		button {
			border: none;
			display: flex;
			align-items: center;
			cursor: pointer;
			background-color: transparent;
			padding: 0;
			font-size: 0;
			color: transparent;

			@include breakMin($breakLarge) {
				&.twitter-btn:hover svg {
					fill: white;
				}
				&:hover:before {
					color: #fff;
					background-color: $colorPrimary;
				}
			}

			&:before {
				width: 40px;
				height: 40px;
				display: flex;
				margin-right: 10px;
				border-radius: 100%;
				font-family: $iconFont;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				transition: background-color 0.3s ease-out, color 0.3s ease-out;
				color: #fff;
				background-color: $colorPrimary;

				@include breakMin($breakLarge) {
					background-color: $lightGrey;
					color: $grey;
					margin-bottom: 20px;
				}
			}

			&.twitter {
				position: relative;

				&::before {
					content: '';
				}

				svg {
					width: 18px;
					position: absolute;
					fill: white;
					left: 11px;
					right: 0;

					:root[dir='rtl'] &,
					.rdRtl & {
						left: 0;
						right: 21px;
					}

					@include breakMin($breakLarge) {
						top: 11px;
						fill: $grey;
					}
				}
			}

			&.facebook:before {
				content: $icon-facebook;
				font-size: 16px;
			}

			&.linkedin:before {
				content: $icon-linkedin;
			}
			&.vk:before {
				content: $icon-vk;
				font-size: 10px;
			}
			&.instagram:before {
				font-size: 22px;
				content: $icon-instagram;
			}
			&.link:before {
				content: $icon-link;
			}
			&.mail {
				display: inline-flex;
				&:before {
					content: $icon-mailFilled;
				}
			}
		}

		&.copyLink {
			position: relative;

			input {
				opacity: 0;
				height: 0;
				border: 0;
				padding: 0;
				position: absolute;
			}

			p {
				position: absolute;
				top: 0;
				left: 60px;
				background: $grey;
				padding: 10px;
				opacity: 0;
				color: #fff;
				transform: translateY(20px);
				transition: opacity 0.3s ease-out, transform 0.3s ease-out;
				border-radius: 10px;
				width: auto;
				white-space: nowrap;
				pointer-events: none;

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 100%;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-right: 8px solid $grey;
					transform: translateY(-50%);
				}
			}

			button:hover + p {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
