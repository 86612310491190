.cta-box-partial {
	position: relative;
	width: 100%;
	padding: 15px;
	text-align: left;
	box-shadow: $menuShadow;
	background-color: $colorPrimary;
	color: white;
	border: 1px solid transparent;

	&--title {
		font-size: 12px;
	}

	&--description {
		font-size: 18px;
		font-weight: 600;
	}

	&--arrowed-link {
		text-align: right;
		margin: 12px 0 4px;

		&:before {
			content: $icon-tailarrowright;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			font-size: 14px;
			font-family: $iconFont;
			transition: $fastDelay all ease-in-out;
			color: $colorPrimary;
			background-color: white;
		}
	}

	a:hover &,
	&:hover,
	a:focus &,
	&:focus {
		outline: none;
		border-color: white;
		.cta-box-partial--arrowed-link {
			&:before {
				transform: scale(1.2);
			}
		}
	}

	&.is-white {
		background-color: white;
		color: $textBlack;
		border: 1px solid $buttonGrey;
		box-shadow: none;
		&:focus {
			border-color: $colorPrimary;
			outline: none;
		}
		.cta-box-partial--title {
			color: $grey;
		}
		.cta-box-partial--arrowed-link:before {
			border: 2px solid $colorPrimary;
		}
	}
}
