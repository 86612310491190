.related-articles-partial {
	--color-grey: #{$rgbGrey};

	&.boxed-style {
		padding: 40px 0;
		border: 1px solid $buttonGrey;
		border-width: 1px 0 1px 0;
	}
	&--title {
		font-size: 18px;
		line-height: 22px;
		font-weight: 400;
		color: $textGrey;
		margin-bottom: 30px;
	}
	&--content {
		--offset: 0;
		--direction-factor: 1;
		display: grid;
		grid-gap: 25px;
		grid-template-columns: repeat(6, 1fr);
		width: min-content;
		@include breakMin($breakXtraSmall) {
			width: auto;
			grid-template-columns: repeat(2, 1fr);
		}
		@include breakMin($breakSmall) {
			grid-template-columns: repeat(3, 1fr);
		}
		[dir="rtl"] &{
			--direction-factor: -1;
		}
		.small-image-above-title {
			width: 215px;
			height: auto;
			transform: translateX(calc(var(--offset) * var(--direction-factor) * 1px));
			@include breakMin($breakXtraSmall) {
				width: auto;
				--direction-factor: 0;
			}
		}
	}
}
