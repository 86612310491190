.investor-cta-widget-blue {
	display: block;
	padding: 15px;
	margin-bottom: 16px;
	box-shadow: $menuShadow;
	background-color: $colorPrimary;
	border: 1px solid $colorPrimary;
	&:focus {
		outline: none;
		border-color: white;
	}
	.investor-cta {
		&--title,
		&--description,
		&--label {
			color: white;
		}
	}
}
