$careerColorValue: $bayOfManyBlue;
$careerColor: RGB($careerColorValue);

body.page-career {
	.page-main {
		--color-primary: #{$careerColorValue};
		--color-primary-hover: #{$biscayBlue};
	}

	.footer-layout {
		--color-primary: #{$lochmaraBlue};
		--color-primary-hover: #{$endeavourBlue};
	}

	.plyr__control--overlaid {
		background: $careerColor;
	}

	.plyr__control--overlaid:hover {
		background: $biscayBlue;
	}

	.plyr--full-ui input[type='range'] {
		color: $careerColor;
	}

	.video-cover {
		.circle:before {
			background: url('/assets-v3/images/icons/videoLoadCareer.svg');
		}
	}
}
