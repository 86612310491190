$gap: 35px;

.social-media-highlights {
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 40px $pagePadding 60px;
	margin: 0 #{-$pagePadding} $subWidgetSpacingMobile;
	@include breakMin($breakLarge) {
		margin: 0 0 $subWidgetSpacing;
	}
	& > * {
		position: relative;
		z-index: 1;
	}
	&--title {
		font-size: 30px;
		font-weight: normal;
		color: white;
		margin-bottom: $gap;
		&.is-black {
			color: $darkGrey;
		}
	}
	&--tiles {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include breakMin($breakMediumSmall) {
			max-width: $breakMediumSmall;
		}
		@include breakMin($breakLarge) {
			max-width: $wrapperMax;
		}
	}
	&--tile {
		display: flex;
		flex-flow: column;
		width: 100%;
		background: white;
		margin-bottom: $gap;
		&:focus-within {
			outline: $blockFocusOutline;
		}
		& > * {
			padding: 20px;
		}
		&:last-child {
			margin-right: 0;
		}
		@include breakMin($breakMediumSmall) {
			width: calc(50% - #{$gap * 1 / 2});
			margin-right: $gap;
			margin-bottom: 0;
		}
		@include breakBetween($breakMediumSmall, $breakLargeBefore) {
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:nth-child(3),
			&:nth-child(4) {
				margin-top: $gap;
			}
		}
		@include breakMin($breakLarge) {
			width: calc(25% - #{$gap * 3 / 4});
			margin-top: 0;
		}
	}
	&--tile-head {
		position: relative;
		display: flex;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid $lightGrey;
		&::after {
			content: $icon-twitter;
			font-family: $iconFont;
			font-size: 14px;
			color: $colorPrimary;
			margin-left: auto;
		}
	}
	&--logo {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 37px;
		height: 37px;
		border-radius: 50%;
		border: 1px solid $colorPrimary;
		margin-right: 7px;
		img {
			width: 23px;
			height: auto;
		}
	}
	&--brand {
		font-size: 12px;
		color: $darkGrey;
		margin-right: 20px;
	}
	&--time {
		font-size: 12px;
		color: $grey;
		margin-left: 10px;
	}
	&--link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
	&--tile-picture {
		position: relative;
		width: auto;
		height: 100px;
		background: var(--back-picture) center;
		background-size: cover;
	}
	&--tile-content {
		color: $grey;
		padding-bottom: 0;
		a:focus {
			outline: $blockFocusOutline;
		}
	}
	&--tile-footer {
		display: flex;
		margin-top: auto;
	}
	&--follow,
	&--share {
		display: flex;
		align-items: center;
		width: 50%;
		font-size: 11px;
		text-transform: uppercase;
		color: $darkGrey;
		&::before {
			font-size: 12px;
			font-family: $iconFont;
			color: $colorPrimary;
			margin-right: 10px;
			transition: $mediumDelay transform ease-in-out;
		}
		&:focus,
		&:hover {
			color: $colorPrimary;
			&::before {
				transform: rotate(360deg);
			}
		}
		&:focus {
			outline: $blockFocusOutline;
		}
	}
	&--follow::before {
		content: $icon-plus;
	}
	&--share::before {
		content: $icon-share;
		font-size: 14px;
	}
}
