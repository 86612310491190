.box-article-content {
	&--title,
	&--description {
		width: 80%;
		min-width: 170px;
	}
	&--image-title {
		margin-bottom: 20px;
		width: auto;
		height: auto;
	}
	&--title {
		margin: 0 0 15px;
		color: white;
		word-break: keep-all;
		&.no-margin {
			margin-bottom: 0;
		}
		&.button-present {
			margin-bottom: 15px;
		}
		> a {
			font-size: inherit;
			line-height: inherit;
		}
	}
	&--category {
		color: white;
		margin-bottom: 4px;
		transition: $mediumDelay color ease-in-out;
		cursor: pointer;
		.is-banner & {
			color: white;
		}
	}
	&--description {
		color: white;
		margin: 0 0 16px;
		font-size: 14px;
		line-height: 20px;
		&.is-big {
			font-size: 20px;
			line-height: 26px;
		}
		@include breakMax($breakMediumBefore) {
			display: none;
			&.is-big {
				display: block;
			}
		}
	}
	&--reading {
		color: white;
		margin: 0 0 35px;
		&.is-last {
			margin-bottom: 0;
		}
		&.top-margin {
			margin-top: 20px;
		}
	}
	&--link {
		display: block;
		color: white;
		font-size: 12px;
	}
	&--link:hover,
	a:hover &--link,
	&--link:focus,
	a:focus &--link {
		&:after {
			--arrow-spacing: 25px;
		}
		&.noHover {
			&:after {
				--arrow-spacing: 18px;
			}
		}
	}

	&--title,
	&--category,
	&--description,
	&--reading,
	&--link {
		&.text-black {
			color: $textBlack;
		}
	}
}
