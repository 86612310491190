.specific-highlight {
	position: relative;
	min-height: 250px;
	margin-bottom: 24px;
	background-color: $colorPrimary;
	display: flex;
	@include breakMin($breakMedium) {
		min-width: 50%;
	}

	&--background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--content {
		position: relative;
		padding: 40px 100px 0 40px;
		color: white;
	}

	&--event-type {
		font-size: 11px;
		font-weight: normal;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	&--description {
		font-weight: normal;
		margin-bottom: 25px;
	}

	&--title {
		font-size: 28px;
		color: white;
		line-height: 34px;
		font-weight: normal;
	}

	&--link {
		color: inherit;
	}
}
