.V3-50-50 {
	display: flex;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 auto 24px;
	flex-wrap: wrap;

	&--space {
		width: 100%;

		&:first-child {
			margin-bottom: 24px;
		}

		@include breakMin($breakSmall) {
			width: calc(50% - 12px);
			margin-bottom: 0;
		}

		.latest-press-releases {
			width: auto;
			padding-left: 30px;
		}

		> a,
		> div,
		> section,
		> img,
		> p {
			width: 100%;
		}
	}
}
