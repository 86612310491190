.layout-60-15 {
    display: flex;
    flex-flow: column;
    margin: 0 auto $subWidgetSpacingMobile;

    @include breakMin($breakMedium) {
        margin-bottom: $subWidgetSpacing;
    }

    >.sf_colsIn {
        width: 100%;

        &:nth-child(2) {
            display: none;
        }

        >a,
        >div,
        >section,
        >img {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include breakMin($breakLarge) {
        flex-flow: row;
        justify-content: space-between;

        >.sf_colsIn:first-child {
            width: 60%;
        }

        >.sf_colsIn:nth-child(2) {
            display: block;
            width: percentage(165px / $wrapperMax);
        }
    }
}