.social-share-collapse {
	width: 200px;
	position: relative;

	&.not-working {
		.social-list-element--addthis {
			display: none;
		}
	}

	.share-button {
		text-transform: uppercase;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
		font-size: 16px;
		letter-spacing: 0.15em;
		cursor: pointer;
		width: 100%;
		margin-bottom: 20px;

		&:before {
			content: $icon-share-filled;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-family: $iconFont;
			color: $mediumGrey;
			width: 40px;
			height: 40px;
			background-color: $lightGrey;
			border-radius: 100%;
			margin-right: 10px;
		}
	}

	.social-list {
		background-color: #fff;
		box-shadow: $menuShadow;
		padding: 20px;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.6s, visibility 0.6s;
		position: absolute;
		left: 0;
		top: calc(100% + 20px);
		z-index: 99;

		&.show {
			visibility: visible;
			opacity: 1;
		}
	}

	.social-list--element {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		a,
		button {
			border: none;
			display: flex;
			align-items: center;
			cursor: pointer;
			font-size: 16px;
			color: $colorPrimary;
			background-color: transparent;
			padding: 0;

			&:before {
				width: 33px;
				height: 33px;
				display: flex;
				background: red;
				color: #fff;
				margin-right: 15px;
				border-radius: 100%;
				font-family: $iconFont;
				align-items: center;
				justify-content: center;
				font-size: 14px;
			}

			&.facebook:before {
				content: $icon-facebook;
				background-color: $blueFacebook;
			}

			&.twitter {
				position: relative;

				svg {
					fill: white;
					position: absolute;
					left: 7px;
					right: 0;

					:root[dir='rtl'] &,
					.rdRtl & {
						left: 0;
						right: 21px;
					}
				}
				&:before {
					content: '';
					background-color: $blueTwitter;
				}
			}

			&.linkedin:before {
				content: $icon-linkedin;
				background-color: $blueLinkedin;
			}
			&.vk:before {
				content: $icon-vk;
				background-color: $blueVK;
			}

			&.instagram:before {
				content: $icon-instagram;
				background-color: $pinkInstagram;
				font-size: 22px;
			}
			&.link:before {
				content: $icon-link;
				background-color: $lightOrange;
			}
			&.mail:before {
				content: $icon-mailFilled;
				background-color: $mailGrey;
				font-size: 12px;
			}
		}

		&.copyLink {
			position: relative;

			input {
				opacity: 0;
				height: 0;
				border: 0;
				position: absolute;
				top: -10px;
			}

			p {
				position: absolute;
				top: 0;
				left: 100px;
				background: $grey;
				padding: 10px;
				opacity: 0;
				color: #fff;
				transform: translateY(20px);
				transition: opacity 0.3s ease-out, transform 0.3s ease-out;
				border-radius: 10px;
				width: max-content;

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 100%;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-right: 8px solid $grey;
					transform: translateY(-50%);
				}
			}

			button:hover + p {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
