.article-intro {
	position: relative;
	--content-padding: 0;
	--before-content-padding: 20px;
	display: flex;
	flex-flow: column;
	margin-bottom: 55px;
	@include breakMin($breakMediumSmall) {
		margin-bottom: 10px;
		flex-flow: row;
		justify-content: space-between;
		align-items: flex-start;
		padding: 55px 0;
		&.is-colored:not(.is-portrait) {
			position: relative;
			--to-side-length: calc(-50vw + 50%);
			--to-center-length: 40%;
			--left-positionning: var(--to-center-length);
			--right-positionning: var(--to-side-length);
			&::before {
				content: "";
				position: absolute;
				top: 0;
				height: 100%;
				background: $lightGrey;
				left: var(--left-positionning);
				right: var(--right-positionning);
			}
			&.is-right-aligned::before {
				left: var(--right-positionning);
				right: var(--left-positionning);
			}
			[dir="rtl"] & {
				--left-positionning: var(--to-side-length);
				--right-positionning: var(--to-center-length);
			}
		}
		&.is-portrait {
			align-items: center;
		}
	}
	@include breakMin($breakMedium) {
		--content-padding: 50px;
		--before-content-padding: 50px;
	}
	@include breakMin($breakLarge) {
		--content-padding: 120px;
		--before-content-padding: 90px;
	}
	&--title {
		font-size: 28px;
		margin-bottom: 20px;
		position: absolute;
		color: white;
		bottom: calc(100% + 15px);
		width: calc(100% - #{2 * $pagePadding});
		// avoid blocking click on link on mobile
		pointer-events: none;
		@include breakMin($breakMediumSmall) {
			position: static;
			color: $darkGrey;
			font-weight: normal;
		}
	}
	&--description.wysiwyg {
		margin-bottom: 25px;
		&,
		p {
			line-height: 24px;
			font-size: 16px;
		}
	}
	&--content,
	&--picture {
		position: relative;
		z-index: 1;
		@include breakMin($breakMediumSmall) {
			width: calc(50% - 20px);
			margin: 0;
		}
	}
	&--content {
		padding: 20px $pagePadding 0;
		@include breakMin($breakMediumSmall) {
			padding-top: 0;
			padding-left: var(--content-padding);
			--padded: -40px;
			--unpadded: 0;
			--start: var(--padded);
			--end: var(--unpadded);
			.is-portrait.is-colored & {
				padding: var(--before-content-padding);
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: 0;
					bottom: 0;
					right: var(--end);
					left: var(--start);
					background: $lightGrey;
					[dir="rtl"] & {
						left: var(--end);
						right: var(--start);
					}
				}
			}
			.is-right-aligned & {
				padding-left: 0;
				padding-right: var(--content-padding);
				--end: var(--padded);
				--start: var(--unpadded);
			}
		}
	}
	&--picture {
		margin: 0 -#{$pagePadding};
		&::before {
			content: "";
			display: block;
			padding-bottom: 87%;
			@include breakMin($breakSmall) {
				padding-bottom: 50%;
			}
			@include breakMin($breakMediumSmall) {
				margin: 0;
				padding-bottom: 60%;
				.is-portrait & {
					padding-bottom: 107%;
				}
			}
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60%;
			background: linear-gradient(to top, $gradientColor, transparent);
		}
		@include breakMin($breakMediumSmall) {
			margin: 0;
			.is-right-aligned & {
				order: 1;
			}
			&::after {
				display: none;
			}
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}
}
