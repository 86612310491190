.section-highlights {
	background: $colorSecondary;
	padding: 30px #{2 * $pagePadding} 40px;
	margin: 0 -#{$pagePadding};

	@include breakMin($breakSmall) {
		background: none;
		margin: 0 auto $subWidgetSpacingMobile;
		padding: 0;
		width: 100%;
	}

	@include breakMin($breakMedium) {
		margin-bottom: $subWidgetSpacing;
	}

	.wrapper {
		@include breakMin($breakSmall) {
			margin: 0 auto;
		}
	}

	&--title {
		color: white;
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 15px;

		@include breakMin($breakSmall) {
			color: $darkGrey;
			margin-bottom: 10px;
			font-weight: normal;
		}
	}

	&--description {
		color: white;
		font-size: 18px;
		line-height: 30px;
		margin-bottom: 30px;

		@include breakMin($breakSmall) {
			color: $darkGrey;
			margin-bottom: 50px;
		}
	}

	&--title,
	&--description {
		@include breakMin($breakSmall) {
			max-width: 70%;
		}

		@include breakMin($breakLarge) {
			max-width: 50%;
		}
	}

	&--carrousel {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: center;

		@include breakMin($breakSmall) {
			width: 100%;
			display: grid;
			grid-column-gap: 20px;
			grid-row-gap: 20px;
			grid-template-rows: auto;
			grid-auto-flow: row;
			align-items: stretch;

			&.has-one {
				grid-template-columns: repeat(1, 1fr);

				.section-highlight--content {
					max-width: 60%;
				}
			}

			&.has-two {
				grid-template-columns: repeat(2, 1fr);
			}

			&.has-three {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, auto);

				.section-highlight:first-of-type {
					grid-area: 1 / 1 / 1 / span 2;

					.section-highlight--content {
						max-width: 80%;
					}
				}

				@include breakMin($breakMedium) {
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: auto;

					.section-highlight:first-of-type {
						grid-area: auto;

						.section-highlight--content {
							max-width: none;
						}
					}
				}
			}
		}
	}

	&--dots {
		padding-top: 25px;
		display: flex;
		align-items: center;

		@include breakMin($breakSmall) {
			display: none;
		}
	}

	.carousel-dot {
		background: white;
	}

	&--counter {
		position: absolute;
		top: 7px;
		right: 8px;
		font-size: 12px;
		color: white;
		z-index: 10;

		@include breakMin($breakSmall) {
			display: none;
		}
	}

	&--picture-slot {
		position: relative;
		width: 100%;
		z-index: 3;
		box-shadow: 0px 3px 16px rgba(black, 0.5);
		background: black;

		&:before {
			content: '';
			padding-bottom: 60%;
			display: block;
		}

		@include breakMin($breakSmall) {
			display: none;
		}
	}

	&--content-slot {
		background: white;
		margin: -30px 0 0;
		width: calc(100% + 40px);

		@include breakMin($breakSmall) {
			display: none;
		}
	}
}

.section-highlight {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	overflow: hidden;
	padding: 0 #{$pagePadding};
	margin: 0 -#{$pagePadding};
	width: calc(100% + 40px);
	height: calc(100% + 40px);

	@include breakMin($breakSmall) {
		width: auto;
		height: auto;
		min-height: 380px;
		position: relative;
		opacity: 1;
		padding: 0;
		margin: 0;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		&:focus:not(:hover) {
			outline: $blockFocusOutline;
		}
	}

	&.not-current {
		pointer-events: none;
	}

	&.current {
		pointer-events: auto;
	}

	&--picture {
		position: relative;
		width: 100%;
		height: auto;
		opacity: 0;

		@include breakMin($breakSmall) {
			position: absolute;
			height: 100%;
			z-index: 0;
			box-shadow: none;
			opacity: 1;
		}

		&:before {
			content: '';
			padding-bottom: 60%;
			display: block;
		}
	}

	&--content {
		padding: 55px 30px 25px;
		margin: -30px -20px 0;
		opacity: 0;

		@include breakMin($breakSmall) {
			padding: 25px 30px;
			margin: 0;
			z-index: 1;
			background: none;
			opacity: 1;
		}
	}

	&--title {
		margin-bottom: 15px;

		@include breakMin($breakSmall) {
			color: white;
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 20px;
		}
	}

	&--description {
		color: $darkGrey;
		margin-bottom: 25px;
		font-size: 14px;
		line-height: 20px;

		@include breakMin($breakSmall) {
			color: white;
			margin-bottom: 40px;
		}
	}

	@include breakMin($breakSmall) {
		&--link {
			color: white;
			@include stretch();

			&::before {
				background: white;
				color: $colorPrimary;
			}

			&:hover,
			&:focus {
				color: white;

				&::before,
				&::after {
					color: white !important;
				}
			}
		}
	}

	.is-dark:after {
		display: none;

		@include breakMin($breakSmall) {
			display: block;
		}
	}
}
