.board-of-directors {
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include breakMin($breakMediumSmall) {
        &.is-image-left-aligned {
            flex-flow: row;
            .board-of-directors--picture {
                @include breakMin($breakMediumSmall) {
                    margin-left: 0;
                    margin-right: 40px;
                }
                @include breakMin($breakLarge) {
                    margin-right: 80px;
                }
            }
            .board-of-directors--content {
                @include breakMin($breakMedium) {
                    margin-left: 0;
                }
            }
        }
        flex-flow: row-reverse;
    }
    &.is-colored {
        background-color: $lightGrey;
    }
    &--picture {
        margin: -50px -20px 0;
        min-height: 250px;
        min-width: 250px;
		width: auto;
		height: auto;
        img {
            width: 100%;
        }
        @include breakMin($breakMediumSmall) {
            margin: 0 40px;
            align-self: center;
            max-height: 316px;
            max-width: 316px;
            img {
                border-radius: 50%;
            }
            @include breakMin($breakLarge) {
                margin: 0 80px;
            }
        }
    }
    &--content {
        padding: 40px 5px;
        @include breakMin($breakMediumSmall) {
            width: 60%;
            padding-left: 0;
            padding-right: 0;
        }
        @include breakMin($breakMedium) {
            margin-left: 40px;
        }
    }
    &--name {
        font-size: 28px;
        font-weight: normal;
        margin-bottom: 8px;
    }
    &--title {
        font-size: 11px;
        font-weight: medium;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
    &--description {
        font-size: 18px;
        font-weight: lighter;
        line-height: 30px;
    }
}
