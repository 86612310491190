.tbe-data-filters {
	display: flex;
	justify-content: space-between;

	&--filter {
		width: calc(50% - 15px);
		@include breakMin($breakLarge) {
			display: flex;
			flex-flow: column;
			align-items: center;
			width: 200px;
			margin-right: 40px;
			&:nth-child(2) {
				width: 100px;
				margin-right: 0;
			}
			.tbe-drop {
				width: 100%;
			}
		}
		.tbe-data-view--title {
			margin-bottom: 11px;
		}
	}
}
