.ir-share-price-overview {
	// Because of old lint version we don't support @container properties - if we update with new one app will break since lot of features aren't supported by new stylelint
	/* stylelint-disable */
	container-type: inline-size;
	/* stylelint-enable */

	&--inner {
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 20px 30px;
		border: 1px solid $buttonGrey;
		
		/* stylelint-disable */
		@container (max-width: 768px) {
			flex-direction: column;
		}

		@container (max-width: 500px) {
			padding: 15px;
		}
		/* stylelint-enable */
	}
	&--price-container {
		width: 50%;
		padding-top: 5px;
		padding-right: 50px;
		padding-bottom: 5px;
		border-right: 1px solid $buttonGrey;
		display: flex;
		justify-content: space-between;

		/* stylelint-disable */
		@container (max-width: 960px) {
			padding-right: 30px;
		}

		@container (max-width: 768px) {
			width: 100%;
			border-right: medium none;
			border-bottom: 1px solid $buttonGrey;
			padding-right: 0;
			padding-bottom: 20px;
		}

		@container (max-width: 500px) {
			flex-direction: column;
			border-bottom: medium none;
			padding: 0;

			> div:last-child {
				display: flex;
				flex-direction: column;
				align-self: center;
				margin: 15px 0 0;
			}

		}
		/* stylelint-enable */
	}
	&--price {
		position: relative;
		font-size: 64px;
		line-height: 74px;
		font-weight: 700;
		padding-right: 25px;

		.full-width-blue & {
			color: white;
		}

		sup {
			position: absolute;
			font-size: 21px;
			line-height: 28px;
			font-weight: 700;
			margin-left: 5px;

			.full-width-blue & {
				color: white;
			}
		}
	}
	&--change {
		font-size: 16px;
		font-weight: 400;
    	line-height: 24px;
		color: white;
		padding: 2px 8px 4px 16px;
		border-radius: 6px;
		text-align: center;

		&.failed {
			background-color: $red;

			.full-width-blue & {
				background-color: white;
				color: $red;
			}

			&:before {
				content: $icon-graphdown;
				@include share-price-indicator;
				bottom: 2px;
				margin-right: 10px;
			}
		}

		&.succeed {
			background-color: $lightGreen;

			.full-width-blue & {
				background-color: white;
				color: $lightGreen;
			}
			&:before {
				content: $icon-graphup;
				@include share-price-indicator;
				margin-right: 7px;
			}
		}
	}
	&--states {
		display: flex;
		justify-content: space-between;

		/* stylelint-disable */
		@container (max-width: 768px) {
			margin-bottom: 10px;
		}

		@container (max-width: 500px) {
			flex-wrap: wrap;
			margin-bottom: 10px;
		}
		/* stylelint-enable */
	}
	&--state {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: left;

		.full-width-blue & {
			color: white;
		}

		span:first-child {
			font-size: 18px;
			line-height: 28px;
			font-weight: 300;
		}

		span:last-child {
			font-size: 30px;
			line-height: 40px;
			font-weight: 700;

			/* stylelint-disable */
			@container (max-width: 1000px) {
				font-size: 26px;
				line-height: 36px;
			}

			@container (max-width: 900px){
				font-size: 20px;
        		line-height: 30px;
			}

			@container (max-width: 760px) {
				font-size: 26px;
				line-height: 36px;
			}

			@container (max-width: 500px) {
				line-height: 40px;
			}
			/* stylelint-enable */
		}

		/* stylelint-disable */
		@container (max-width: 500px) {
			width: 50%;
			margin-bottom: 10px;
		}
		/* stylelint-enable */
	}
	&--share-container {
		display: flex;
		width: 50%;
		padding-top: 5px;
    	padding-left: 40px;
		padding-bottom: 5px;
		flex-direction: column;
		justify-content: space-between;
		text-align: right;
		img {
			margin-bottom: 20px;
			width: auto;
			height: auto;
		}

		/* stylelint-disable */
		@container (max-width: 960px) {
			padding-left: 30px;
		}

		@container (max-width: 768px) {
			width: 100%;
			padding: 20px 0 0;
		}
		/* stylelint-enable */
	}
	&--title {
		font-size: 30px;
		line-height: 40px;
		font-weight: 600;

		.full-width-blue & {
			color: white;
		}
	}
	&--common-stock {
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
		margin-bottom: 10px;

		.full-width-blue & {
			color: white;
		}
	}
	&--infos {
		text-align: left;
		font-size: 14px;
		line-height: 24px;
		font-weight: 400;

		.full-width-blue & {
			color: white;
		}

		.ir-share-price-overview--price-container & {
			font-size: 20px;
			line-height: 30px;

			/* stylelint-disable */
			@container (max-width: 500px) {
				font-size: 18px;
				line-height: 28px;
			}
			/* stylelint-enable */
		}

		/* stylelint-disable */
		@container (max-width: 500px) {
			align-self: center;
		}
		/* stylelint-enable */
	}
	&--cta {
		display: block;
		margin-top: 15px;
		text-align: right;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1.5px;

		.full-width-blue & {
			margin-bottom: 15px;
			color: white;
		}
	}
}
