.no-display {
	display: none;
}

.sr-only {
	@include sr-only();
}

.sr-only-mobile {
	@include sr-only-mobile();
}

.fade-in {
	animation-duration: $evenSlowerDelay;
	animation-timing-function: ease-in-out;
	animation-name: fade-in;
}

.is-background {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.is-hidden-accessible {
	border: 0;
	padding: 0;
	margin: 0;
	position: absolute !important;
	height: 1px; 
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%); 
	white-space: nowrap; 
}

.is-invisible {
	visibility: hidden;
}

.is-content {
	position: relative;
	z-index: 1;
}

.still-picture {
	user-select: none;
}

.overlay-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	padding: 0;
	background: none;
	color: transparent;
	cursor: pointer;
}

.stretch-link-after {
	@include stretch(after);
}

.stretch-link-before {
	@include stretch(before);
}

.download-disabled {
	pointer-events: none;
	&:after,
	&:before {
		animation: fadeIn 2s;
		animation-duration: var(--animation-duration);
	}
}

@keyframes fadeIn {
	0% { opacity: 0.1; }
	100% { opacity: 1; }
}