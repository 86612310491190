.investor-news {
	position: relative;
	display: flex;
	margin: 0 -#{$pagePadding} $subWidgetSpacing;
	padding: 0 $pagePadding;
	background-color: $lightGrey;
	&--content {
		display: flex;
		flex-flow: column;
		margin: 0 auto;

		@include breakMin($breakMedium) {
			width: 100%;
		}
	}
	&--title {
		font-size: 30px;
	}
	.carousel-highlight {
		margin-bottom: 50px;
	}
	.faq-block--title {
		font-size: 14px;
		line-height: 18px;
		@include breakMin($breakMediumSmall) {
			font-size: 16px;
		}
	}
}
