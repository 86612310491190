.event-highlight-partial {
	// make the selector heavier
	&[data-controller] {
		@include breakMax($breakSmallBefore) {
			margin-left: -$pagePadding;
			margin-right: -$pagePadding;
			width: calc(100% + #{2 * $pagePadding});
		}
	}
	&--tabs {
		display: flex;
		border-bottom: 1px solid $buttonGrey;
		margin-bottom: 20px;
	}
	&--tab {
		width: 100%;
		text-align: center;
	}
	&--tab-link {
		display: inline-block;
		width: 100%;
		font-size: 18px;
		background: none;
		border: none;
		color: $grey;
		padding: 0;
		transition: $slowDelay color ease-in-out;
		@include breakMin($breakMedium) {
			font-size: 20px;
		}
		&:after {
			content: '';
			display: block;
			margin-top: 20px;
			width: 0;
			height: 5px;
			background: $colorPrimary;
			transition: $slowDelay width ease-in-out;
		}
		&:disabled {
			color: $colorPrimary;
			transition: $fastDelay color ease-in-out;
			&:after {
				width: 100%;
				transition: $fastDelay color ease-in-out;
			}
		}
		&:focus {
			color: $colorPrimary;
			outline: none;
		}
	}
	&--container {
		position: relative;
		background-color: $colorPrimary;
		overflow: hidden;
		transition: $slowDelay height ease-in-out;
	}
	&--content {
		display: flex;
		flex-flow: column;
		width: 100%;
		padding: 35px;
		color: white;
		transform: translateX(-100%);
		transition: $slowDelay transform ease-in-out;
		&:last-child {
			position: absolute;
			top: 0;
			transform: translateX(100%);
		}
		&.active {
			transform: none;
		}
		&.no-upcoming {
			padding: 60px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $lightGrey;
			@include breakMin($breakMedium) {
				padding: 120px;
			}
		}
		.arrowed-link {
			margin-top: 25px;
			color: white;
			&:before {
				color: $colorPrimary;
				background-color: white;
			}
			&:hover,
			&:focus {
				color: white;
				&:before {
					background-color: white;
				}
			}
		}
		&:hover,
		&:focus {
			.arrowed-link {
				color: white;
				&:before {
					background-color: white;
				}
			}
		}
	}
	&--row {
		display: flex;
		justify-content: space-between;
	}
	&--calendar-button {
		background: none;
		border: none;
		padding: 0;
		color: white;
		margin-bottom: 10px;
	}
	&--links {
		margin-top: 15px;
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	&--link {
		display: block;
		width: 100%;
		text-align: right;
		transform: translateX(-100%);
		transition: $slowDelay transform ease-in-out;
		&:last-child {
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			transform: translateX(100%);
		}
		&.active {
			transform: none;
		}
		@include breakMax($breakSmallBefore) {
			padding: 0 $pagePadding;
		}
	}
	&--inner {
		width: 100%;
		display: inline-block;
	}
	&--title {
		margin-bottom: 10px;
		color: white;
	}
	&--date {
		font-size: 18px;
		margin-bottom: 14px;
	}
	&--description {
		font-size: 16px;
		@include breakMin($breakMedium) {
			font-size: 28px;
		}
	}
	&--no-upcoming {
		font-size: 16px;
		@include breakMin($breakMedium) {
			font-size: 28px;
		}
		color: $grey;
	}
	&--calendars {
		position: relative;
	}
	.calendar-menu--menu {
		margin: 0;
		bottom: 0;
		z-index: 10;
	}
	.arrow-link {
		display: block;
		text-align: right;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1.5px;
	}
}
