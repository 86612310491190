.document-list {
	position: relative;
	$self: &;
	&--warning {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 18px;
		top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
	&--top {
		background: $lightGrey;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	&--title-container {
		align-self: center;
	}

	&--notes {
		margin-bottom: 3px;

		span {
			margin-left: 5px;
		}
	}

	&--title {
		font-size: 20px;
	}

	&--download {
		display: flex;
		&:focus {
			outline: none;
			#{$self}--download-label {
				color: $textBlack;
				transition: $fastDelay color ease-in-out;
			}
			#{$self}--download-button {
				background: $textBlack;
				transition: $fastDelay color ease-in-out;
			}
		}
	}

	&--download-label {
		align-self: center;
		text-transform: uppercase;
		margin-right: 18px;
		font-size: 12px;
		transition: $mediumDelay color ease-in-out;
	}

	&--download-button {
		width: 30px;
		min-width: 30px;
		height: 30px;
		background: $colorPrimary;
		border-radius: 50%;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: $mediumDelay background ease-in-out,
			$mediumDelay color ease-in-out;

		&::before {
			content: $icon-download;
			font-family: $iconFont;
			font-size: 13px;
		}

		&:hover {
			background: $colorPrimaryHover;
			color: white;
		}
	}

	&--pages {
		--offset: 0;
		--factor: 1;
		display: flex;
		align-items: flex-start;
		overflow: hidden;
		[dir='rtl'] & {
			--factor: -1;
		}
	}

	&--page {
		display: grid;
		align-items: center;
		min-width: 100%;
		grid-column-gap: 25px;
		grid-template-columns: repeat(3, auto) 1fr;
		transform: translateX(calc(var(--offset) * -100% * var(--factor)));

		&.key-document {
			grid-template-columns: repeat(2, auto) 1fr;

			@include breakMin($breakSmall) {
				grid-column-gap: 40px;
				grid-template-columns: 1fr repeat(3, auto);
			}

			.document-row {
				a {
					margin-right: 7px;
					[dir='rtl'] & {
						margin-right: 0;
						margin-left: 7px;
					}

					@include breakMin($breakSmall) {
						margin-right: -19px;
					}
				}
			}
		}

		@include breakMin($breakSmall) {
			grid-column-gap: 40px;
			grid-template-columns: 1fr repeat(4, auto);
		}

		@supports not (display: contents) {
			display: flex;
			flex-flow: column;

			&.key-document {
				.document-row {
					a {
						margin-right: 30px;
					}
				}
			}
		}
	}

	.pages-nav {
		padding: 0 30px;

		@include breakMin($breakSmall) {
			padding: 0;
		}

		@include breakMin($breakMediumSmall) {
			padding: 0 30px;
		}
	}
}

.document-row {
	display: contents;
	&--warning {
		position: relative;
		// position: absolute;
		// top: 50%;
		// left: 50%;
		grid-column-start: 1;
		grid-column-end: 6;
		// transform: translate(-50%);
		background: rgba(248, 0, 0, 0.7);
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	}
	&--text {
		font-size: 20px;
		color: #fff;
		font-weight: bold;
	}
	&--close {
		position: absolute;
		right: 18px;
		top: 18px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		&::after {
			content: $icon-cross;
			font-family: $iconFont;
		}
	}
	&--border {
		border-bottom: 1px solid $buttonGrey;
		grid-column-start: span 4;

		@include breakMin($breakSmall) {
			grid-column-start: span 5;
		}
	}

	&--title {
		margin: 20px 0;
		padding: 0 20px;
		font-size: 16px;
		font-weight: 400;
		color: $textBlack;
		grid-column-start: span 4;
		//Fixes RTL issue with numbers
		[dir='rtl'] & {
			&:after {
				content: '\200E‎';
			}
			&:before {
				content: '\200E‎';
			}
		}
		@include breakMin($breakSmall) {
			grid-column-start: auto;
			padding-right: 0;
			[dir='rtl'] & {
				padding-left: 0;
				padding-right: 20px;
			}
		}
	}

	&--notes {
		display: contents;

		@include breakMax($breakSmallBefore) {
			p {
				margin-bottom: 20px;

				&:nth-of-type(1) {
					padding-left: 20px;
					padding-left: 30px;
					[dir='rtl'] & {
						@include breakMax($breakSmall) {
							margin-left: 0;
							margin-right: 30px;
						}
					}
				}
			}

			a {
				margin-bottom: 20px;
			}
		}
	}

	a {
		font-family: $iconFont;
		font-size: 13px;
		color: $colorPrimary;
		margin-right: 30px;
		transition: $mediumDelay color ease-in-out,
			$mediumDelay transform ease-in-out;
		text-align: right;

		&::before {
			content: $icon-download;
		}

		&:hover {
			color: $colorPrimaryHover;
		}
		&:focus {
			outline: none;
			color: $textBlack;
			transition: $fastDelay color ease-in-out,
				$fastDelay transform ease-in-out;
			transform: scale(1.1);
		}
		[dir='rtl'] & {
			margin-right: 0;
			margin-left: 30px;
			@include breakMax($breakSmall) {
				text-align: left;
			}
		}
	}

	@supports not (display: contents) {
		width: 100%;
		border-bottom: 1px solid $buttonGrey;

		@include breakMax($breakSmallBefore) {
			flex-wrap: wrap;

			&--title {
				width: 100%;
			}

			a {
				margin-left: auto;
			}

			&--notes {
				width: 100%;
			}
		}

		&,
		&--notes {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&--border {
			display: none;
		}

		p,
		a {
			padding-left: 10px;
		}
	}
}
