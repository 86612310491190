.title1,
.title2,
.title3,
.title4,
.title5,
.title6,
.title7,
.title8 {
	font-weight: 700;
	font-family: $mainFont;
}

.title1 {
	font-size: 38px;
	line-height: 46px;

	@include breakMin($breakMediumSmall) {
		font-size: 48px;
		line-height: 52px;
	}
}

.title2 {
	font-size: 30px;
	line-height: 38px;

	@include breakMin($breakMediumSmall) {
		font-size: 36px;
		line-height: 40px;
	}
}

.title3 {
	font-size: 30px;
	line-height: 34px;

	@include breakMin($breakMediumSmall) {
		line-height: 38px;
	}
}

.title4 {
	font-size: 20px;
	line-height: 24px;

	@include breakMin($breakMediumSmall) {
		font-size: 28px;
		line-height: 34px;
	}
}

.title5 {
	font-size: 18px;
	line-height: 22px;

	@include breakMin($breakMediumSmall) {
		font-size: 22px;
		line-height: 26px;
	}
}

.title6 {
	font-size: 16px;
	line-height: 20px;

	@include breakMin($breakMediumSmall) {
		font-size: 20px;
		line-height: 24px;
	}
}

.title7 {
	font-size: 18px;
	line-height: 22px;
}

.title8 {
	font-size: 16px;
	line-height: 20px;
}

.paragraph1 {
	font-size: 24px;
	line-height: 30px;

	@include breakMin($breakMediumSmall) {
		line-height: 34px;
		font-weight: 700;
	}
}

.paragraph2 {
	font-size: 18px;
	line-height: 30px;
}

.paragraph3 {
	font-size: 16px;
	line-height: 24px;
}

.paragraph4 {
	font-size: 14px;
	line-height: 24px;
}

.paragraph5 {
	font-size: 12px;
	line-height: auto;

	@include breakMin($breakMediumSmall) {
		font-size: 11px;
		line-height: 14px;
	}
}

.category-title {
	text-transform: uppercase;
	font-size: 11px;
	line-height: 14px;
	letter-spacing: 0.15em;
	color: $colorPrimary;
}

.arrow-link {
	[dir="rtl"] & {
		display: flex;
	}
	&:after {
		--arrow-spacing: 18px;
		content: $icon-tailarrowright;
		font-family: $iconFont;
		margin-left: var(--arrow-spacing);
		font-size: 9px;
		transition: $mediumDelay margin-left ease-in-out, $mediumDelay margin-right ease-in-out;
		[dir="rtl"] & {
			margin-right: var(--arrow-spacing);
			margin-left: 0;
			transform: rotate(180deg);
		}
	}
	&:focus {
		outline: none;
	}
	&:hover:after,
	&:focus:after {
		--arrow-spacing: 25px;
	}
}

.small-style {
	font-size: 11px;
	line-height: 14px;
	color: $grey;
}

.above-widget-title {
	font-size: 30px;
	line-height: 40px;
	color: $textBlack;
	margin-bottom: 32px;
	font-weight: 400;

	@include breakMin($breakMedium) {
		margin-bottom: -8px;
	}
}

.big-description-style {
	font-size: 20px;
	line-height: 26px;
	color: $darkGrey;
}

.dot-before {
	&:before {
		content: "";
		width: 3px;
		height: 3px;
		display: inline-block;
		background: $mediumGrey;
		border-radius: 50%;
		margin: 0 6px 2px 6px;
	}
}

.arrowed-link {
	--spacing: 10px;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	color: $textBlack;
	cursor: pointer;

	&:before {
		content: $icon-tailarrowright;
		font-family: $iconFont;
		font-size: 10px;
		letter-spacing: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: white;
		background: $colorPrimary;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		margin-right: var(--spacing);
		transition: $mediumDelay background ease-in-out, $mediumDelay margin-right ease-in-out,
			$mediumDelay margin-left ease-in-out;
		[dir="rtl"] & {
			margin-left: var(--spacing);
			margin-right: 0;
			transform: rotate(0.5turn);
		}
	}

	&:hover,
	&:focus,
	a:hover &,
	a:focus & {
		outline: none;
		color: $textBlack;

		&:before {
			background: $colorPrimaryHover;
			--spacing: 25px;
		}
	}
}

.search-field {
	border: none;
	border-bottom: 1px solid $colorPrimary;
	font-size: 18px;
	width: 100%;
	padding-bottom: 10px;
	text-align: left;
	position: relative;
	z-index: 1;
	cursor: pointer;
	outline: none;
	[dir="rtl"] & {
		text-align: right;
	}
	&:focus {
		border-color: $textBlack;
	}
	&::placeholder {
		opacity: 1;
	}
}

a {
	transition: $mediumDelay color ease-in-out;

	&:hover,
	&:focus {
		transition: $fastDelay color ease-in-out;
		text-decoration: none;
	}
}
