.page-main {
	$page-main-margin-top: 50px;

	margin-top: var(--page-main-margin-top-nav);
	padding: 0 $pagePadding;
	overflow: hidden;

	@include breakMin($breakLarge) {
		padding: 0;
	}

	@include breakMin($breakNavigation) {
		body.scrolled & {
			margin-top: 0;
		}
	}
	.shorthand-embed-body & {
		overflow: visible;
	}
	&.initialized {
		transition: $slowDelay margin-top ease-in-out;
	}
}
.wrapper {
	max-width: $wrapperMax;
	width: 100%;
}

.small-wrapper {
	max-width: $smallWrapperMax;
	width: 100%;
}
.partial-container {
	display: flex;
	flex-grow: 1;

	> a,
	> div,
	> section {
		flex-grow: 1;
		width: 100%;
	}
}
.column-content-slot {
	> a,
	> div,
	> section,
	> blockquote,
	> img {
		margin-bottom: $subWidgetSpacingMobile;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.widget-container {
	display: flex;

	> div,
	> section,
	> a {
		flex-grow: 1;
	}
}
.square-layout {
	position: relative;
	padding-bottom: 100%;

	> a,
	> section,
	> div {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}
