.layout-2-3 {
  display: flex;
  flex-flow: column;
  margin: 0 auto $subWidgetSpacingMobile;
  @include breakMin($breakMedium) {
    margin-bottom: $subWidgetSpacing;
  }

  > .sf_colsIn {
    width: 100%;
    &:nth-child(1) {
      margin-bottom: $subWidgetSpacingMobile;
      @include breakMin($breakMedium) {
        margin-bottom: $subWidgetSpacing;
      }
      @include breakMin($breakLarge) {
        margin-bottom: 0;
      }
    }
  }
  @include breakMin($breakLarge) {
    flex-flow: row;
    > .sf_colsIn:nth-child(1) {
      width: 40%;
      margin-bottom: 0;
    }
    > .sf_colsIn:nth-child(2) {
      width: 60%;
    }
  }
}
