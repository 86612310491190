.back-picture {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.background-layer {
		width: 100%;
		height: 100%;
	}
	img {
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
	&.has-gradient,
	&.is-dark {
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
		}
	}
	&.has-gradient:after {
		top: 23px;
		height: calc(100% - 23px);
		background: linear-gradient(transparent, $gradientColor);
	}
	&.is-dark:after {
		top: 0;
		height: 100%;
		background: $gradientColor;
	}
	.has-back-hover & img {
		will-change: transform;
		transition: $slowDelay transform ease-in-out;
		transform: scale(1);
	}
	.has-back-hover:focus {
		outline: none;
	}
	.has-back-hover:hover & img,
	.has-back-hover:focus & img {
		transform: scale(1.025);
	}
	.has-back-hover:focus & img {
		transition: $fastDelay transform ease-in-out;
	}
}
