.basic-video {
	position: relative;
	display: grid;
	grid-template: 1fr / 1fr;

	&--img-container,
	.video {
		grid-column: 1;
		grid-row: 1;
	}

	&--img-container {
		position: relative;
		display: block;
		width: 100%;
		z-index: 10;
		&::before {
			content: '';
			display: block;
			margin-bottom: 56.25%;
			@include landscapeOrientation() {
				margin-bottom: 35%;
			}
		}
		img {
			position: absolute;
			top: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&--download {
		position: absolute;
		top: 20px;
		right: 25px;
		display: flex;
		flex-flow: column;
		align-items: center;
		color: white;
		font-size: 11px;
		z-index: 10;
		[dir='rtl'] & {
			right: unset;
			left: 25px;
		}

		&::before {
			content: $icon-circledownload;
			font-family: $iconFont;
			font-size: 30px;
			margin-bottom: 4px;
		}
	}
	&--start-button {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		z-index: 1;
	}

	&-transcript {
		position: relative;
		display: flex;
		flex-flow: column;

		&--button {
			align-self: flex-end;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 20px;
			height: 40px;
			font-size: 12px;
			text-transform: uppercase;
			color: $colorPrimaryHover;
			background: $lightGreyDarker;
			border: none;
			&::after {
				content: $icon-down;
				font-family: $iconFont;
				font-size: 8px;
				color: $colorPrimary;
				margin-left: 70px;
				transition: $mediumDelay transform ease-in-out;
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: 70px;
				}
			}
			&.unfolded::after {
				transform: rotate(180deg);
				z-index: 100;
			}
			.modal & {
				background: $colorPrimary;
				&,
				&::after {
					color: white;
				}
			}
		}
		&--container {
			position: absolute;
			top: 40px;
			width: 100%;
			max-height: 300px;
			display: flex;
			flex-flow: column;
			background: $lightGrey;
			overflow-x: hidden;
			overflow-y: auto;
			height: 0;
			z-index: 1001;
			transition: $mediumDelay height ease-in-out,
				$mediumDelay box-shadow ease-in-out;
			@include breakMin($breakMediumSmall) {
				max-height: 350px;
			}
			&.unfolded {
				box-shadow: $menuShadow;
			}
			.modal & {
				background: $colorPrimary;
				position: relative;
				top: 0;
			}
		}
		&--contained {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
		&--title,
		&--content {
			padding: 20px;
			.modal & {
				color: white;
				border: none;
			}
		}
		&--title {
			font-size: 16px;
			border-bottom: 1px solid $buttonGrey;
		}
		&--content {
			font-size: 14px;
			line-height: 20px;
			font-weight: 300;
		}
	}
	&--error-msg {
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 26px;
		height: 50vh;
	}
}
