.tbe-data-view-table {
	overflow: auto;
	display: flex;
	flex-flow: column;
	margin: 0 -#{$pagePadding};
	max-height: 70vh;
	@include breakMin($breakMedium) {
		margin: 0;
		max-height: 600px;
	}
	&.deactivated {
		overflow: hidden;
		pointer-events: none;
		align-items: flex-start;
	}
	&--message {
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		&.grey-back {
			background: $lightGrey;
		}
		p {
			width: percentage(436 / 838);
			text-align: center;
			color: $grey;
			font-size: 18px;
			line-height: 22px;
			strong {
				color: $textBlack;
				font-weight: normal;
			}
		}
	}
	&--column {
		display: flex;
		align-items: center;
		font-size: 11px;
		text-transform: uppercase;
		font-weight: normal;
		letter-spacing: 0.15em;
		color: $darkGrey;
		padding: 20px;
		white-space: nowrap;
		background: none;
		border: none;
		height: 60px;
		.deactivated & {
			color: $mediumGrey;
		}
		&.ascending:after,
		&.descending:after {
			content: $icon-down;
			font-family: $iconFont;
			font-size: 6px;
			margin-left: 10px;
			transition: $fastDelay transform ease-in-out;
		}
		&.descending:after {
			transform: rotate(180deg);
		}
	}
	th {
		position: sticky;
		top: 0;
		background: $lightGrey;
		height: 70px;
	}
	td {
		font-size: 11px;
		color: $darkGrey;
		padding: 20px;
	}
	.right-aligned {
		text-align: right;
	}
	tbody tr {
		border-bottom: 1px solid $buttonGrey;
		transition: $fastDelay background ease-in-out;
		&:hover {
			background: rgba($lightGrey, 0.4);
			transition: none;
		}
		&:last-child {
			border: none;
		}
	}
}
