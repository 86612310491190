.error-500-nav {
	height: 100px;
	background: white;
	width: 100%;
	display: flex;
	align-items: center;

	&--logo {
		margin-left: 25px;
		height: 50px;
	}
}
.error-500 {
	min-height: calc(100vh - 100px);
	background: linear-gradient(110deg, $colorPrimary 40%, $gradientLightBlue);
	color: white;
	padding: 100px 0 0 20px;
	@include breakMin($breakLarge) {
		padding: 150px 0 0 100px;
	}

	&--title {
		font-size: 38px;
		line-height: 46px;
		margin-bottom: 10px;
	}

	&--subtitle {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 35px;
	}
}
