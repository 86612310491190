.speech-bubble-widget {
	width: 100%;
	@include breakMin($breakSmall) {
		padding: 0 45px;
	}
	.speech-bubble {
		&:first-child {
			background-color: $doveGrey;
			fill: $doveGrey;
			bottom: 10px;
		}
		&:nth-child(2) {
			background-color: $doveGrey;
			fill: $doveGrey;
			top: auto;
			height: calc(80% - 25px);
			opacity: 0.3;
		}
	}
	&:not(.mirrored) {
		.speech-bubble {
			&:first-child {
				right: 35px;
			}
			&:nth-child(2) {
				left: 100px;
			}
		}
	}
	&.mirrored {
		.speech-bubble {
			&:first-child {
				left: 35px;
			}
			&:nth-child(2) {
				right: 100px;
			}
		}
	}
	&--content {
		position: relative;
		padding: 25px 75px 70px 40px;
		height: 100%;
		.mirrored & {
			padding-right: 40px;
			padding-left: 75px;
		}
	}
	&--title,
	&--description {
		position: relative;
	}
	&--title {
		letter-spacing: -0.06rem;
		text-transform: uppercase;
		font-size: 24px;
		color: $schoolBusYellow;
	}
	&--description {
		margin-top: 16px;
		font-size: 30px;
		font-weight: 600;
		color: white;
	}
	&--footnote {
		display: block;
		width: calc(80% - 30px);
		margin: 30px auto 0 auto;
		color: $grey;
	}
}
