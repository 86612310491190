.job-details {
	margin-bottom: 40px;
	@include breakMin($breakMedium) {
		margin-bottom: 160px;
	}
	&--grid {
		display: flex;
		margin: 0 auto;
		flex-flow: column;
		@include breakMin($breakMedium) {
			display: grid;
			grid-template: auto auto / 340px 1fr;
			grid-template-areas:
				'details content'
				'jobs content';
			grid-row-gap: 60px;
			grid-column-gap: 150px;
		}
	}
	&--apply,
	&--share {
		display: flex;
		margin: 10px 0 0;
		min-width: 280px;
		width: 100%;
		@include breakMin($breakXtraSmall) {
			width: 280px;
		}
	}
	/* stylelint-disable no-descending-specificity  */
	&--apply {
		background: $colorPrimary;
		color: $lightGrey;
	}
	&--share {
		background: $lightGrey;
		color: $colorPrimary;
		&::before {
			content: $icon-mail;
			font-family: $iconFont;
			margin-right: 5px;
			font-size: 14px;
		}
	}
	/* stylelint-enable */
	&--content {
		grid-area: content;
		white-space: pre-line;
		line-height: 24px;
	}
	.breadcrumbs {
		color: white;
		margin: 0;
	}
	.banner {
		position: relative;
		display: flex;
		margin: 0 -#{$pagePadding};
		padding: 30px $pagePadding;
		min-height: 315px;
		@include breakMin($breakMedium) {
			margin-bottom: 60px;
		}
		@include breakMin($breakNavigation) {
			margin: 0 0 60px 0;
			padding: 30px;
		}

		&--content {
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			z-index: 1;
			position: relative;
			margin: 0 auto;
		}
		&--description {
			color: white;
		}
		&--title {
			word-break: break-word;
			color: white;
			max-width: 460px;
		}

		.back-picture {
			width: auto;
			height: auto;
		}
	}

	&--left-side {
		margin: 0 -#{$pagePadding};
		padding: 30px $pagePadding;
		width: calc(100% + #{2 * $pagePadding});
		@include breakMin($breakMedium) {
			padding: 30px;
			margin: 0 50px 0 0;
			width: auto;
		}
		@include breakMin($breakLarge) {
			margin-right: 150px;
		}
	}

	.details {
		grid-area: details;
		display: flex;
		flex-flow: column;
		background: $lightGrey;
		padding: 30px $pagePadding;
		margin-left: -$pagePadding;
		margin-right: -$pagePadding;
		@include breakMin($breakMedium) {
			margin: 0;
			padding: 30px;
			width: auto;
		}
		&--content {
			display: flex;
			flex-flow: row wrap;
			@include breakMin($breakMedium) {
				flex-flow: column;
			}
		}
		.job-details--apply,
		.job-details--share {
			@include breakMin($breakXtraSmall) {
				margin-right: 20px;
				display: inline-flex;
			}
			@include breakMin($breakMedium) {
				margin-right: 0;
			}
		}
		&--buttons {
			display: flex;
			flex-flow: row wrap;
			@include breakMin($breakMedium) {
				flex-flow: column;
			}
		}
		&--topic {
			margin-bottom: 30px;
			width: 100%;
			@include breakMin($breakXtraSmall) {
				width: 50%;
			}
			@include breakMin($breakSmall) {
				width: 33%;
			}
			@include breakMin($breakMedium) {
				width: 100%;
			}
		}
		&--title {
			font-size: 30px;
			margin-bottom: 30px;
			font-weight: normal;
			width: 100%;
		}
		&--label {
			font-size: 16px;
			color: $grey;
			font-weight: bold;
			margin-bottom: 5px;
		}
		&--note {
			font-size: 14px;
			display: flex;
			align-items: center;
			&::before {
				font-family: $iconFont;
				margin-right: 4px;
				font-size: 18px;
				color: $colorPrimary;
			}
		}
		&--clockIcon::before {
			content: $icon-clock;
		}
		&--positionIcon::before {
			content: $icon-position;
		}
		&--suitcaseIcon::before {
			content: $icon-suitcase;
		}
		&--labelIcon::before {
			content: $icon-label;
		}
	}

	.relevant-jobs {
		grid-area: jobs;
		display: flex;
		flex-flow: column;
		background: white;
		&--title {
			font-size: 30px;
			font-weight: normal;
			padding-left: 0;
			margin-bottom: 30px;
			@include breakMin($breakMedium) {
				padding-left: 30px;
			}
		}

		&--heading {
			width: 0 auto;
			background-color: $colorPrimary;
			padding: 20px 0 20px 30px;
			color: white;
			font-size: 14px;
			@include breakMin($breakMedium) {
				width: auto;
			}
		}

		&--entry {
			color: inherit;
			margin-top: 20px;
			padding-left: 30px;

			&::after {
				content: '';
				display: block;
				border-bottom: 1px solid $buttonGrey;
				margin-top: 20px;
			}
		}

		&--job-title {
			font-size: 16px;
		}

		&--details > li {
			display: inline;
			color: $grey;
			font-size: 11px;
			padding-right: 25px;
		}
	}
}
